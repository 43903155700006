import { getFilteredTruthyFieldsObj } from '../../../../shared/utils/sortingAndGroupingUtil';
import { BILLING_PERIOD_TYPE } from '../../../constants/billingPeriodType';
import { CreateEntitySubscriptionInfo } from '../../../interfaces/entitySubscription';
import { CreateEntitySubscriptionFormValues } from '../CreateEntitySubscriptionModal.settings';

export const getSubscriptionInfoFromFormValue = ({
  subscriptionPrice,
  ...data
}: CreateEntitySubscriptionFormValues): CreateEntitySubscriptionInfo => {
  const paymentInfo = {
    ...subscriptionPrice,
    ...data,
    billingPeriodType: BILLING_PERIOD_TYPE.MONTHLY,
  };

  const filteredPaymentInfo = getFilteredTruthyFieldsObj(paymentInfo);

  return filteredPaymentInfo as CreateEntitySubscriptionInfo;
};
