import React from 'react';

import classes from './MealHeadRow.module.scss';
import { GridTableRow } from '../../../../shared/components/table-components';
import { NUTRIENTS_ENUM } from '../../../../shared/constants/NUTRIENTS_ENUM';
import { getEnumArray } from '../../../../shared/utils/jsUtils';

const MealHeadRow = () => {
  const nutrients = getEnumArray(NUTRIENTS_ENUM, [NUTRIENTS_ENUM.WATER]);

  return (
    <GridTableRow variant="subRow" className={classes.headRow}>
      <span>Product</span>
      <span>Qty</span>
      <span>Measurement</span>
      {nutrients.map((nutrient) => (
        <span key={nutrient}>{nutrient}</span>
      ))}
    </GridTableRow>
  );
};

export { MealHeadRow };
