import { UseControllerProps } from 'react-hook-form';

export const getRequiredRules = (
  selectAllAvailable: boolean | null,
  fieldLabel: string,
): UseControllerProps['rules'] => {
  if (selectAllAvailable) {
    return undefined;
  }

  return { required: `${fieldLabel} is required` };
};
