import React, { useEffect } from 'react';

import { angularize } from 'react-in-angularjs';
import { Container } from '@mui/material';
import { observer } from 'mobx-react';

import { SystemProviders } from '../../../shared/providers';
import { foodScoreStore } from '../../stores/foodScoreStore';
import { PageTitle } from '../../../shared/components/ui-components';
import { TAB_VALUES } from '../../constants/TAB_VALUES';
import { LbTabPanel, LbTabs, LbTabsList } from '../../../shared/components/tabs';
import { FinalTable } from './containers/FinalTable/FinalTable';
import { WorkingTable } from './containers/WorkingTable/WorkingTable';

const { WORKING_TABLE_TAB, FINAL_TABLE_TAB } = TAB_VALUES;

const NgzFoodScore = observer(() => {
  const {
    getFinalTableData,
    getFoodScoreTypes,
    activeTab,
    setActiveTab,
    finalTableTotal,
    workingTableTotal,
  } = foodScoreStore;

  useEffect(() => {
    getFinalTableData();
    getFoodScoreTypes();
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const tabsConfigs = [
    {
      label: `Working Table (${workingTableTotal})`,
      value: WORKING_TABLE_TAB,
      panel: <WorkingTable />,
    },
    {
      label: `Final Table (${finalTableTotal})`,
      value: FINAL_TABLE_TAB,
      panel: <FinalTable />,
    },
  ];

  return (
    <SystemProviders>
      <Container>
        <LbTabs value={activeTab} onChange={handleTabChange}>
          <PageTitle
            title="Workspace"
            tabs={<LbTabsList tabsConfig={tabsConfigs} variant="underline" />}
          />
          <LbTabPanel tabsConfig={tabsConfigs} />
        </LbTabs>
      </Container>
    </SystemProviders>
  );
});

angularize(NgzFoodScore, 'ngzFoodScore', angular.module('app.foodScore'), {});

export { NgzFoodScore };
