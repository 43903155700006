const autoNgTemplateLoaderTemplate1 = require('./view-video.template.html');

'use strict';

angular
    .module('app')
    .directive('viewVideo', viewVideo);

viewVideo.$inject = [];

function viewVideo() {
    return {
        restrict: 'E',
        scope: {
            title: '@',
            videos: '='
        },
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: 'ViewVideoController',
        controllerAs: 'vm'
    };
}
