import React from 'react';
import PropTypes from 'prop-types';

import { GridTableRow } from '../../../../../../shared/components/table-components';
import { SortButtons } from '../../../../../../shared/components/buttons';

const HeadRow = ({ sortMethods }) => {
  const { handleSort, getIsSortedBy } = sortMethods;

  return (
    <GridTableRow variant="header">
      <span>#</span>
      <SortButtons fieldName="entityName" onSort={handleSort} getIsActive={getIsSortedBy}>
        Entity Name
      </SortButtons>
      <span>Inbody Key (API Key)</span>
      <span>Webhooks ID</span>
      <span>Description</span>
    </GridTableRow>
  );
};

HeadRow.propTypes = {
  sortMethods: PropTypes.object,
};

export { HeadRow };
