const autoNgTemplateLoaderTemplate1 = require('./entity-business-info.template.html');

'use strict';

angular
  .module('app')
  .directive('entityBusinessInfo', entityBusinessInfo);

entityBusinessInfo.$inject = [];

function entityBusinessInfo() {
  return {
    restrict: 'E',
    scope: {
      createEntity: '=',
      spinner: '=',
      next: '&onNext',
      entityId: '<?'

    },
    templateUrl: autoNgTemplateLoaderTemplate1,
    controller: 'EntityBusinessInfoController',
    controllerAs: 'vm'
  };
}
