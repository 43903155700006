import React from 'react';

import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react';
import { Button } from '@mui/material';

import { SelectedInfoRow } from '../../../../shared/components/table-components';
import { GeneralFilters } from './GeneralFilters/GeneralFilters';
import { scholasticCustomersListStore } from '../../../stores/ScholasticCustomersListStore';
import { ScholasticCommunicationFilterFormValue } from '../../../interfaces/scholastic-list/ScholasticCommunicationFilters';
import { ReassignCustomersButton } from './ReassignCustomersButton/ReassignCustomersButton';
import { loggedInUserDataStore } from '../../../../shared/stores/loggedInUserDataStore';
import { useAutoSubmitForm } from '../../../../shared/hooks/useAutoSubmitForm';
import { filterDefaultValues } from './FilterRow.settings';

const FilterRow = observer(() => {
  const { isEntity } = loggedInUserDataStore;
  const { downloadReportForSelected, sendMessageToSelected, filterMethod, selectMethod } =
    scholasticCustomersListStore;
  const { applyFilters, filterData } = filterMethod;
  const { getSelectedItemCount } = selectMethod;
  const { control, handleSubmit, reset, watch } =
    useForm<ScholasticCommunicationFilterFormValue>({ defaultValues: filterData });

  useAutoSubmitForm(handleSubmit(applyFilters), watch, [
    'generalFilter.fullName',
    'generalFilter.entityName',
    'generalFilter.coachName',
  ]);

  const handleReset = () => {
    reset(filterDefaultValues);
  };

  return (
    <>
      <GeneralFilters control={control} onReset={handleReset} />
      <SelectedInfoRow selectedItemCount={getSelectedItemCount()} isSubGridRow>
        <Button size="small" variant="contained" onClick={sendMessageToSelected}>
          Contact Selected
        </Button>
        {isEntity && <ReassignCustomersButton />}
        <Button
          size="small"
          variant="outlined"
          className="wightBg"
          onClick={downloadReportForSelected}
        >
          Export in CSV
        </Button>
      </SelectedInfoRow>
    </>
  );
});

export { FilterRow };
