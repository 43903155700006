'use strict';

angular.module('app').controller('PaginationController', PaginationController);

PaginationController.$inject = ['$scope', '$rootScope'];

function PaginationController($scope, $rootScope) {
    var vm = this;
    vm.currentPage = 1;
    vm.pagesCount = 0;
    vm.smallPaginationIndexes = [1, 2, 3, 4, 5];
    vm.startPaginationIndexes = [2, 3, 4];
    vm.middlePaginationIndexes = [-1, 0, 1];
    vm.endPaginationIndexes = [3, 2, 1];
    vm.isShowFirstPages = true;
    vm.isShowLastPages = false;

    vm.changePage = changePage;
    vm.setIsShowFirstPages = setIsShowFirstPages;
    vm.setIsShowLastPages = setIsShowLastPages;
    vm.setCurrentPage = setCurrentPage;
    vm.setPaginationProps = setPaginationProps;
    $scope.pagination.reset = reset;

    reset($scope.pagesCount);

    $rootScope.$on('changePage', function (event, opt) {
        $rootScope.currentPage = parseInt(opt.page) + 1;
    });

    function changePage(page) {
        $scope.onChange({page: page - 1});

        vm.setPaginationProps(page);
    }

    function reset(pagesCount) {
        vm.pagesCount = pagesCount;

        vm.setPaginationProps($rootScope.currentPage);
        $rootScope.currentPage = null;
    }

    function setPaginationProps (currentPage) {
        vm.setCurrentPage(currentPage);
        vm.setIsShowFirstPages();
        vm.setIsShowLastPages();
    }

    function setCurrentPage (currentPage) {
        if(currentPage){
            vm.currentPage = currentPage;
        } else {
            vm.currentPage = 1;
        }
    }

    function setIsShowFirstPages () {
        vm.isShowFirstPages = vm.currentPage < 4;
    }

    function setIsShowLastPages () {
        vm.isShowLastPages = vm.pagesCount - vm.currentPage < 3;
    }
} 
