'use strict';

angular.module('app')
    .controller('supplementSetupStep2Controller', supplementSetupStep2Controller);

supplementSetupStep2Controller.$inject = [
    '$scope',
    '$location',
    '$routeParams',
    'ModalService',
    'CoachService',
    'PaymentService',
    'GeneralService',
    'TermsAndConditionService',
    'utilService'
];

function supplementSetupStep2Controller($scope, $location, $routeParams, ModalService, CoachService, PaymentService,
                                        GeneralService, TermsAndConditionService, utilService) {
    const vm = this;

    vm.isReadTerms = false;
    vm.checkingReadTerms = false;
    vm.resendingTerms = false;
    vm.supplementUser = $scope.user || {coach: {}};
    vm.spinner = {active: false};
    vm.currentSubscriptionType = { name: 'supplement', value: 'SUPPLEMENT' };
    vm.paymentFlowType = {};
    vm.isStripeUsed = false;

    vm.init = init;
    vm.getMaxValue = getMaxValue;
    vm.selectSubscription = selectSubscription;
    vm.showDialog = showDialog;
    vm.getAvailableSubscriptions = getAvailableSubscriptions;
    vm.getEntityInfo = getEntityInfo;
    vm.getStripePublicKey = getStripePublicKey;
    vm.paySubscriptionByUser = paySubscriptionByUser;
    vm.checkIsReadTerms = checkIsReadTerms;
    vm.resendTerms = resendTerms;
    vm.pay = pay;
    vm.payNewFlow = payNewFlow;
    vm.handleSuccessPayment = handleSuccessPayment;
    vm.createManualTransaction = createManualTransaction;
    vm.payOldFlow = payOldFlow;
    vm.checkSubscriptions = checkSubscriptions;

    vm.init();

    function init() {
        // set value for vm.paymentFlowType and vm.isStripeUsed, need for correct work runFuncDependOnFlow
        PaymentService.getPaymentFlowTypeAndIsStripeUsed(vm);
        vm.getAvailableSubscriptions();
        vm.getStripePublicKey();
    }

    function checkSubscriptions() {
        return vm.gymDetails.subscriptions.filter(function (value) {
            return value.active && value.subscriptionType === vm.currentSubscriptionType.value;
        }).length > 0;
    }

    function checkIsReadTerms() {
        vm.checkingReadTerms = true;
        TermsAndConditionService.checkIfTermsAndConditionAreReadBySupplementUser(vm.supplementUser.id)
            .then(function (result) {
                vm.checkingReadTerms = false;
                if (result.status === 200) {
                    vm.isReadTerms = result.data.read;

                    if (!vm.isReadTerms && $location.path() === '/supplement-setup') {
                        setTimeout(function () {
                            vm.checkIsReadTerms()
                        }, 5000);
                    }
                }
            })
    }

    function resendTerms() {
        vm.resendingTerms = true;
        TermsAndConditionService.resendTermsAndConditionAreReadToSupplementUser(vm.supplementUser.id)
            .then(function (result) {
                vm.resendingTerms = false;
                if (result.status === 200) {
                    var sent = result.data.sent;
                    var message = sent.sms && sent.email ?
                        'You successfully sent email and sms with Terms & Conditions agreement' :
                        'You successfully sent ' + (!sent.sms ? 'email' : 'sms') + ' with Terms & Conditions agreement';
                    ModalService.alert(message, 'Success');
                }
            })
    }

    function getAvailableSubscriptions() {
        CoachService.getCoachAccountSettings(vm.supplementUser.coach.id).then(function (res) {
            if (res.status === 200) {
                vm.currentCoach = res.data;
                vm.getEntityInfo();
            }
        });
    }

    function getEntityInfo() {
        vm.spinner.active = true;
        CoachService.getCurrentEntityInfo().then(function (res) {
            vm.spinner.active = false;
            if (res.status === 200) {
                for (var key in res.data.subscriptions) {
                    var subs = res.data.subscriptions[key];

                    subs.minSetupValue = vm.getMaxValue(subs.minChargeSetup, subs.setUpEntityPrice, vm.currentCoach.additional.setupDiscountPercent) / 100;
                    subs.minInitValue = vm.getMaxValue(subs.minChargeInit, subs.initInvoiceEntityPrice, vm.currentCoach.additional.membershipDiscountPercent) / 100;
                    subs.minRenewalValue = vm.getMaxValue(subs.minChargeRenew, subs.renewFeeEntityPrice, vm.currentCoach.additional.renewalDiscountPercent) / 100;

                    subs.setUpEntityPrice = subs.setUpEntityPrice / 100;
                    subs.setUpEkinPrice = subs.setUpEkinPrice / 100;
                    subs.initInvoiceEntityPrice = subs.initInvoiceEntityPrice / 100;
                    subs.initInvoiceEkinPrice = subs.initInvoiceEkinPrice / 100;
                    subs.renewFeeEntityPrice = subs.renewFeeEntityPrice / 100;
                    subs.renewFeeEkinPrice = subs.renewFeeEkinPrice / 100;
                    subs.minChargeSetup = subs.minChargeSetup / 100;
                    subs.minChargeInit = subs.minChargeInit / 100;
                    subs.minChargeRenew = subs.minChargeRenew / 100;
                }

                vm.gymDetails = res.data;
                vm.initialSubscriptionsDetails = angular.copy(vm.gymDetails.subscriptions);
                vm.checkIsReadTerms();
            }
        });
    }

    function getStripePublicKey() {
        GeneralService.getStripePublicKey().then(function (res) {
            if (res.status === 200) {
                var key = res.data.key;
                if (key) {
                    vm.handler = window.StripeCheckout.configure({
                        key: key,
                        image: 'https://content.lifebase.solutions/static-content/logo.png',
                        locale: 'auto',
                        token: vm.paySubscriptionByUser
                    });

                }
            }
        });
    }

    function paySubscriptionByUser(token) {
        vm.spinner.active = true;
        PaymentService.paySubscriptionByUser(vm.supplementUser.id, {
            setup_price: vm.setUpEntityPrice,
            init_invoice_price: vm.initInvoiceEntityPrice,
            renew_fee_price: vm.renewFeeEntityPrice,
            token: token.id,
            subscription: vm.selectedSubscription.id
        }).then(function (res) {
            vm.spinner.active = false;
            switch (res.status) {
                case 200:
                    utilService.goTo('/supplement-customers-list');
                    break;
                case 402:
                    ModalService.confirm('Error Message', vm.pay, null, res.data.message, null, 'Enter card details');
                    break;
            }
        });
    }

    function pay() {
        PaymentService.runFuncDependOnFlow(payNewFlow, payOldFlow);
    }

    function payNewFlow() {
        if (vm.isStripeUsed) {
            const paymentRequestBody = {
                subscriptionId: vm.selectedSubscription.id,
                customerId: vm.supplementUser.id,
                initialPrice: vm.selectedSubscription.initInvoiceEntityPrice,
                renewalPrice: vm.selectedSubscription.renewFeeEntityPrice,
            };
            ModalService.showStripe(null, vm.handleSuccessPayment, paymentRequestBody);
        } else {
            vm.createManualTransaction();
        }
    }

    function handleSuccessPayment() {
        utilService.goTo('/supplement-customers-list', { replaceHistory: true });
    }

    function createManualTransaction() {
        vm.spinner.active = true;
        PaymentService.createManualTransaction(vm.selectedSubscription.id, vm.supplementUser.id)
          .then(res => {
              if (res.status === 200) {
                  vm.handleSuccessPayment();
              }
          })
          .finally(() => vm.spinner.active = false);
    }

    function payOldFlow() {
        if (vm.handler && vm.selectedSubscription) {
            vm.setUpEntityPrice = Math.round(vm.selectedSubscription.setUpEntityPrice * 100);
            vm.initInvoiceEntityPrice = Math.round(vm.selectedSubscription.initInvoiceEntityPrice * 100);
            vm.renewFeeEntityPrice = Math.round(vm.selectedSubscription.renewFeeEntityPrice * 100);

            vm.handler.open({
                email: vm.supplementUser.email,
                name: vm.selectedSubscription.title,
                description: vm.selectedSubscription.info,
                amount: vm.setUpEntityPrice + vm.initInvoiceEntityPrice,
                currency: 'usd'
            });
        }
    }

    function showDialog(subscription_name, description) {
        ModalService.getSubscriptionInfo({title: subscription_name, info: description});
    }

    function selectSubscription(index) {
        vm.selectedSubscriptionIndex = index;

        for (var i = 0; i < vm.gymDetails.subscriptions.length; i++) {
            var currentSubscription = vm.gymDetails.subscriptions[i];
            if (i !== index) {
                currentSubscription.setUpEntityPrice = vm.initialSubscriptionsDetails[i].setUpEntityPrice;
                currentSubscription.initInvoiceEntityPrice = vm.initialSubscriptionsDetails[i].initInvoiceEntityPrice;
                currentSubscription.renewFeeEntityPrice = vm.initialSubscriptionsDetails[i].renewFeeEntityPrice;
            }
        }
    }

    function getMaxValue(minFee, fee, percent) {
        var feeWithPercent = fee * percent / 100;

        if (minFee > fee - feeWithPercent) return Math.ceil(minFee);
        else return Math.ceil(fee - feeWithPercent);
    }

}
