import { useEffect, useState } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';
import { useUpdateEffect } from 'react-use';

import { ReportSetting } from '../../../../../../../interfaces/ReportSetting';
import { reportService } from '../../../../../../../services/reportService';
import { ReportSelectorParams } from '../../../../../../../interfaces/ReportSelectorParams';
import {
  addAllOption,
  transformToOptions,
} from '../../../../../../../../shared/utils/arrayUtils';
import { Option } from '../../../../../../../../general-types';
import { ReportSelectorOption } from '../../../../../../../interfaces/ReportSelectorOption';
import { reportStore } from '../../../../../../../stores/reportStore';

type UseReportSelectorProps = {
  selectorSettings: ReportSetting;
  parentSelectorSettings?: ReportSetting;
};

export const useReportSelector = ({
  selectorSettings,
  parentSelectorSettings,
}: UseReportSelectorProps) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [isLoading, setLoading] = useState(false);

  const { control, resetField } = useFormContext();

  const { selectorType, selectAllAvailable, parameterKey } = selectorSettings;

  const parentValue = useWatch({
    name: parentSelectorSettings?.parameterKey ?? '',
    defaultValue: null,
    control,
  });

  const getOptions = async () => {
    const selectorParams: ReportSelectorParams = {
      selectorType,
      reportType: reportStore.selectedReport?.reportType!,
      parentSelectorType: parentSelectorSettings?.selectorType,
      parentResourceId: parentValue as number,
    };

    try {
      setLoading(true);
      const { data } = await reportService.getReportSelectorOptions(selectorParams);
      processOptions(data);
    } catch (error) {
      reportStore.closeDownloadModal();
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const processOptions = (selectorOption: ReportSelectorOption[]) => {
    const optionsList = transformToOptions({
      list: selectorOption,
      labelKey: 'label',
      valueKey: 'id',
    });

    if (selectAllAvailable) {
      const withAll = addAllOption(optionsList, true);
      setOptions(withAll);
    } else {
      setOptions(optionsList);
    }
  };

  const processInitOption = (isGetOption: boolean) => {
    if (isGetOption) {
      getOptions();
    } else {
      processOptions([]);
    }
  };

  useUpdateEffect(() => {
    resetField(parameterKey!);

    processInitOption(!!parentValue);
  }, [parentValue]);

  useEffect(() => {
    processInitOption(!parentSelectorSettings?.parameterKey);
  }, []);

  const defaultValue = selectAllAvailable ? '' : null;
  const isDisabled = selectAllAvailable ? options.length <= 1 : !options.length;

  return {
    options,
    defaultValue,
    isLoading,
    isDisabled,
  };
};
