import { ValueOfKey } from '../../utils-types';

export const NUTRIENTS_ENUM = {
  PROTEINS: 'proteins',
  CARBS: 'carbs',
  FATS: 'fats',
  SUGAR: 'sugar',
  FIBER: 'fiber',
  VEGGIES: 'veggies',
  SODIUM: 'sodium',
  CALORIES: 'calories',
  WATER: 'water',
} as const;

export type NutrientType = ValueOfKey<typeof NUTRIENTS_ENUM>;
