import React from 'react';
import PropTypes from 'prop-types';

import classes from './GoalTitleCell.module.scss';

const GoalTitleCell = ({ goalTitle }) => {
  return (
    <div className={classes.cellBox}>
      <span>{goalTitle}</span>
      <span>Actual / Goal</span>
    </div>
  );
};

GoalTitleCell.propTypes = {
  goalTitle: PropTypes.string,
};

export { GoalTitleCell };
