import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { InputAdornment } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import classes from './InitialOrderStepFormRow.module.scss';
import {
  LbDatePicker,
  LbInput,
} from '../../../../../../shared/components/form-components';

const InitialOrderStepFormRow = ({
  supplementName,
  brand,
  nameIndex,
  amountUnit,
  fullPackageSize,
  supplementId,
  isAutoFocus,
}) => {
  const { control, register } = useFormContext();

  const onlyFutureDate = (value) =>
    moment(value) < moment() || 'You can order only in the future';
  return (
    <div className={classes.formRow}>
      <div className={classes.supBox}>
        <p>{supplementName}</p>
        {brand && <span>{brand}</span>}
      </div>
      <LbInput
        control={control}
        name={`${nameIndex}fullPackageSize`}
        defaultValue={fullPackageSize}
        disabled
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="end">{amountUnit}</InputAdornment>,
        }}
      />
      <LbInput
        control={control}
        name={`${nameIndex}orderAmount`}
        defaultValue={1}
        autoFocus={isAutoFocus}
        selectValueOnFocus
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="end">bottles</InputAdornment>,
        }}
      />
      <LbDatePicker
        control={control}
        name={`${nameIndex}orderDate`}
        disablePast
        defaultToday
        rules={{ validate: onlyFutureDate }}
      />
      <input {...register(`${nameIndex}supplementID`, { value: supplementId })} hidden />
    </div>
  );
};

InitialOrderStepFormRow.propTypes = {
  supplementName: PropTypes.string,
  brand: PropTypes.string,
  nameIndex: PropTypes.string.isRequired,
  amountUnit: PropTypes.string,
  fullPackageSize: PropTypes.number,
  supplementId: PropTypes.number,
  isAutoFocus: PropTypes.bool,
};

export { InitialOrderStepFormRow };
