import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';
import { observer } from 'mobx-react';

import classes from './HeadersRow.module.scss';
import { userProfileService } from '../../../../../../services/userProfileService';
import { getRouteParam } from '../../../../../../../shared/utils/angularUtils';
import { openConfirmationModal } from '../../../../../../../shared/features/confirmation';

const { changeUserActiveStatus } = userProfileService;

const HeadersRow = observer(({ setIsUserActive, isUserActive, userName }) => {
  const customerId = getRouteParam('id');

  const toggleCustomerStatus = async () => {
    await changeUserActiveStatus(customerId, !isUserActive);
    setIsUserActive(!isUserActive);
  };

  const handleDeactivate = () => {
    openConfirmationModal({
      title: `Do you want to deactivate ${userName} as a user ?`,
      confirmBtnConfig: {
        onClick: toggleCustomerStatus,
      },
    });
  };

  const ActivateBtn = (
    <Button variant="outlined" onClick={toggleCustomerStatus}>
      Activate user
    </Button>
  );

  const DeactivateBtn = (
    <Button variant="outlined" color="error" onClick={handleDeactivate}>
      Deactivate user
    </Button>
  );

  return (
    <div className={classes.rowBox}>{isUserActive ? DeactivateBtn : ActivateBtn}</div>
  );
});

HeadersRow.propTypes = {
  setIsUserActive: PropTypes.func,
  isUserActive: PropTypes.bool,
  userName: PropTypes.string,
};

export { HeadersRow };
