'use strict';

(function () {
  angular
    .module('app.preCheckInProceed', [])
    .config(registrationChildConfig);

  registrationChildConfig.$inject = ['$routeProvider'];

  function registrationChildConfig($routeProvider) {
    $routeProvider.when('/proceed-pre-check-in-registration/:token', {
      template: '<ngz-proceed-registration />',
    });
  }
})();
