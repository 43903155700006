import React from 'react';

import classNames from 'classnames';
import { LinearProgress, LinearProgressProps } from '@mui/material';

import classes from './LbLinearProgress.module.scss';

type LbLinearProgressProps = {
  value: number;
  color: LinearProgressProps['color'];
  className?: string;
};

const LbLinearProgress = ({ value, color, className }: LbLinearProgressProps) => {
  const progressClasses = classNames(classes.linearProgress, className);

  return (
    <LinearProgress
      variant="determinate"
      value={value > 100 ? 100 : value} // fix overflow
      color={color}
      className={progressClasses}
    />
  );
};

export { LbLinearProgress, LbLinearProgressProps };
