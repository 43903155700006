import React, { useEffect, useRef } from 'react';

import { angularize } from 'react-in-angularjs';

import classes from './NgzResources.module.scss';
import { SystemProviders } from '../../../shared/providers';
import { Spinner } from '../../../shared/components/Spinner/Spinner';
import { useModalToggle } from '../../../shared/hooks/useModalToggle';

const NgzResources = () => {
  const [isShowSpinner, toggleIsShowSpinner] = useModalToggle(true);
  const iframeRef = useRef(null);

  useEffect(() => {
    iframeRef.current.onload = toggleIsShowSpinner;
  }, []);

  return (
    <SystemProviders>
      <div>
        <iframe
          ref={iframeRef}
          src="https://belitenutrition.com/coach-resources/"
          title="Resources"
          className={classes.iframeContent}
        />
        <Spinner isLoading={isShowSpinner} isGlobal />
      </div>
    </SystemProviders>
  );
};

angularize(
  NgzResources,
  'ngzResources',
  angular.module('app.guidesAndAdditionalInfo'),
  {},
);

export default NgzResources;
