const autoNgTemplateLoaderTemplate1 = require('./sales-representatives.html');

'use strict';

angular
    .module('app')
    .directive('salesRepresentatives', salesRepresentatives);

salesRepresentatives.$inject = [];

function salesRepresentatives() {
    return {
        restrict: 'E',
        scope: {
            spinner: '=',
        },
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: 'SalesRepresentativesController',
        controllerAs: 'vm'
    };
}
