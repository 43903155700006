import React from 'react';

import { angularize } from 'react-in-angularjs';
import { useDropzone } from 'react-dropzone';

import classes from './NgzInsuranceCertificatesTab.module.scss';
import { useInsuranceCertificatesTabData } from './hooks/useInsuranceCertificatesTabData';
import { InsuranceCertificate } from './components/InsuranceCertificate/InsuranceCertificate';
import { SystemProviders } from '../../../../../shared/providers';
import { DropzoneOpenCard } from './components/DropzoneOpenCard/DropzoneOpenCard';
import { InsuranceCertificatesDropzoneView } from './components/InsuranceCertificatesDropzoneView/InsuranceCertificatesDropzoneView';
import { useModalToggle } from '../../../../../shared/hooks/useModalToggle';
import { LbModal } from '../../../../../shared/components/modals/LbModal/LbModal';
import { getBytesFromMb } from '../../../../../shared/utils/mathUtils';

const maxFileSizeMb = 10;
const maxSize = getBytesFromMb(maxFileSizeMb);

const NgzInsuranceCertificatesTab = () => {
  const [isShowErrorModal, toggleErrorModal] = useModalToggle();

  const {
    insuranceCertificates,
    uploadInsuranceCertificate,
    deleteInsuranceCertificate,
  } = useInsuranceCertificatesTabData();

  const { open, getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: true,
    multiple: false,
    noKeyboard: true,
    maxSize,
    onDrop: uploadInsuranceCertificate,
    onDropRejected: toggleErrorModal,
  });

  return (
    <SystemProviders>
      <div
        {...getRootProps({
          className: classes.insuranceCertificates,
          'data-test-id': 'dropzone',
        })}
      >
        <DropzoneOpenCard onClick={open} getDropzoneInputProps={getInputProps} />
        {insuranceCertificates.map((certificate) => (
          <InsuranceCertificate
            key={certificate.id}
            certificateInfo={certificate}
            onDelete={deleteInsuranceCertificate}
          />
        ))}
        {isDragActive && <InsuranceCertificatesDropzoneView />}
      </div>
      <LbModal
        open={isShowErrorModal}
        title="Warning"
        text={`File size exceeds ${maxFileSizeMb} MB.`}
        primaryBtnConfig={{ text: 'Ok', onClick: toggleErrorModal }}
      />
    </SystemProviders>
  );
};

angularize(
  NgzInsuranceCertificatesTab,
  'ngzInsuranceCertificatesTab',
  angular.module('app'),
  {},
);

export { NgzInsuranceCertificatesTab };
