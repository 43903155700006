'use strict';

angular.module('app').controller('CustomerSetupStep1Controller', CustomerSetupStep1Controller);

CustomerSetupStep1Controller.$inject = [
    '$scope',
    '$rootScope',
    '$location',
    '$routeParams',
    'constants',
    'CustomerService',
    'CustomerGroupsService',
    'DateTimeUtil',
    'ModalService',
    'CoachService',
    'CustomersFilterService',
    'EntityService',
    'localStorageService',
    'utilService',
];

function CustomerSetupStep1Controller($scope, $rootScope, $location, $routeParams, constants, CustomerService,
                                      CustomerGroupsService, DateTimeUtil, ModalService, CoachService,
                                      CustomersFilterService, EntityService, localStorageService,
                                      utilService) {
    var vm = this;
    vm.createUser = $scope.createUser;
    vm.userGroup = $scope.userGroup;
    vm.inbodyDataList = $scope.inbodyDataList;
    vm.currentEntity = $scope.currentEntity;

    vm.groupsList = [];
    vm.states = constants.states;
    vm.sports = constants.sports;
    vm.inbodyTypes = constants.inbodyTypes;
    vm.bodyFatMethods = constants.bodyFatMethods;
    vm.ENTITY_TYPES = constants.enums.entityTypes;
    vm.positions = EntityService.getPositionGroups(vm.createUser.customer.teamSport);
    vm.genders = ['Male', 'Female'];
    vm.loadingInbodyData = false;
    vm.inbodyRetrieveError = null;
    vm.warningMessage = 'Enter Inbody ID of the member \nto prefill user information';
    vm.scanDateIsOpen = false;
    vm.dobIsOpen = false;
    vm.currentDate = DateTimeUtil.now();
    vm.minDate = DateTimeUtil.minusYears(vm.currentDate, 100);
    vm.isSetData = false;
    vm.minUserAge = 12;
    vm.userTypes = [];
    vm.enums = constants.enums;
    vm.storageKeys = constants.enums.storageKeys;

    vm.init = init;
    vm.initForPreCheckIn = initForPreCheckIn;
    vm.setPreCheckInUserData = setPreCheckInUserData;
    vm.processFullName = processFullName;
    vm.setFirstAndLastName = setFirstAndLastName;
    vm.changeSportPositions = changeSportPositions;
    vm.getGroupsList = getGroupsList;
    vm.setScanDate = setScanDate;
    vm.setDOB = setDOB;
    vm.initUserAndCustomer = initUserAndCustomer;
    vm.checkEmailUnique = checkEmailUnique;
    vm.getInbodyUserData = getInbodyUserData;
    vm.displayViewScanDataDialog = displayViewScanDataDialog;
    vm.complete = complete;
    vm.fillTextbox = fillTextbox;
    vm.parseName = parseName;
    vm.calculateBodyFatAndMass = calculateBodyFatAndMass;
    vm.calculateTotalBodyWatter = calculateTotalBodyWatter;
    vm.isScanDateEmpty = isScanDateEmpty;
    vm.goToNextStep = goToNextStep;
    vm.setNewInbodyType = setNewInbodyType;

    String.prototype.capitalize = function () {
        return this.charAt(0).toUpperCase() + this.slice(1);
    };

    vm.init();

    function init() {
        vm.initForPreCheckIn();
        vm.initUserAndCustomer();
    }

    function initForPreCheckIn() {
        const preCheckInUserData = localStorageService.get(vm.storageKeys.PRE_CHECK_IN_USER_DATA);

        if (preCheckInUserData) {
            vm.setPreCheckInUserData(preCheckInUserData);
        }

        localStorageService.remove(vm.storageKeys.PRE_CHECK_IN_USER_DATA);
    }

    function setPreCheckInUserData(preCheckInUserData) {
        vm.processFullName(preCheckInUserData.fullName);
        const phone = preCheckInUserData.phone;
        vm.createUser.user.email = preCheckInUserData.kidEmail;
        vm.createUser.customer.dob = preCheckInUserData.dob;
        vm.createUser.customer.cellPhone = phone;
        vm.createUser.user.billingEmail = preCheckInUserData.parentEmail;
        vm.createUser.customer.inbodyId = utilService.getNumbersFromString(phone);
        vm.createUser.paymentMethodId = preCheckInUserData.paymentMethodID;
        vm.createUser.isPreCheckInCustomer = true;
    }

    function processFullName(fullName) {
        const names = fullName.split(' ');

        if (names.length === 2) {
            vm.setFirstAndLastName(names);
        }
    }

    function setFirstAndLastName(names) {
        vm.createUser.user.firstName = names[0];
        vm.createUser.user.lastName = names[1];
    }

    function setNewInbodyType() {
        var value = vm.createUser.customer.inbodyType === vm.inbodyTypes.NON_INBODY.value ? null : 0;
        vm.inbodyDataList.bodyFatMass =  value;
        vm.inbodyDataList.leanBodyMass = value;
        vm.inbodyDataList.totalBodyWatter = value;
        vm.inbodyDataList.ecwtbw = value;

        if (vm.createUser.customer.inbodyType !== vm.inbodyTypes.NON_INBODY.value) {
            vm.calculateBodyFatAndMass();
            vm.calculateTotalBodyWatter();
        }
    }

    function changeSportPositions() {
        vm.positions = EntityService.getPositionGroups(vm.createUser.customer.teamSport);
        vm.createUser.customer.teamPosition = vm.positions.find(function (position) {
            return position === vm.createUser.customer.teamPosition;
        });
    }

    function getGroupsList() {
        CustomersFilterService.loadGroups().then(function (result) {
            if (result.status === 200) {
                vm.groupsList = result.data.groups;
            }
        });
    }

    function setScanDate() {
        vm.scanDate = DateTimeUtil.formatDate(vm.inbodyDataList.scanDate, 'YYYY-MM-DD');
        $scope.$evalAsync();
    }

    function setDOB(form) {
        if (vm.createUser.customer.dob) {
            vm.customerDOB = DateTimeUtil.formatDate(vm.createUser.customer.dob, 'MM.DD.YYYY');
            DateTimeUtil.validateDOB(form, vm.createUser.customer.dob);
        }
        $scope.$evalAsync();
    }

    function initUserAndCustomer() {
        if (vm.currentEntity.allowNonInbodyUsers)  vm.userTypes.push(constants.inbodyTypes.NON_INBODY);
        for (var type in vm.currentEntity.supportedInbodyTypes) {
            if (vm.currentEntity.supportedInbodyTypes[type]) {
                vm.userTypes.push(constants.inbodyTypes[type.toUpperCase()]);
            }
        }

        vm.getGroupsList();
        vm.isInbodyDataAvailable = !CustomerService.checkInbodyData(vm.inbodyDataList);
        vm.customerDOB = DateTimeUtil.formatDate(vm.createUser.customer.dob, 'MM.DD.YYYY');

        if (vm.inbodyDataList.scanTime && vm.inbodyDataList.scanDate) {
            vm.inbodyDataList.scanTime = new Date(vm.inbodyDataList.scanTime);
            vm.scanDate = DateTimeUtil.formatDate(vm.inbodyDataList.scanDate, 'YYYY-MM-DD');
        }
    }

    function checkEmailUnique(form) {
        if (vm.createUser.user.email) {
            CoachService.checkUserEmail(vm.createUser.user.email).then(function (res) {
                form["email"].$setValidity("duplicate", res.status === 404);
            });
        }
    }

    function calculateBodyFatAndMass() {
        vm.inbodyDataList.bodyFatMass = (vm.inbodyDataList.weight && vm.inbodyDataList.percentBodyFat) ?
            +((vm.inbodyDataList.weight * (vm.inbodyDataList.percentBodyFat / 100)).toFixed(1)) : null;
        vm.inbodyDataList.leanBodyMass = (vm.inbodyDataList.weight && vm.inbodyDataList.bodyFatMass) ?
            +((vm.inbodyDataList.weight - vm.inbodyDataList.bodyFatMass).toFixed(1)) : null;
    }

    function calculateTotalBodyWatter() {
        vm.inbodyDataList.totalBodyWatter = (vm.inbodyDataList.inWatter && vm.inbodyDataList.exWatter) ?
            +((vm.inbodyDataList.inWatter + vm.inbodyDataList.exWatter).toFixed(1)) : null;
        vm.inbodyDataList.ecwtbw = (vm.inbodyDataList.exWatter && vm.inbodyDataList.totalBodyWatter) ?
            +((vm.inbodyDataList.exWatter / vm.inbodyDataList.totalBodyWatter).toFixed(3)) : null;
    }

    function getInbodyUserData(form) {
        if (vm.createUser.customer.inbodyId) {
            vm.loadingInbodyData = true;
            vm.inbodyTokenReady = false;

            CustomerService.getInbodyData(vm.createUser.customer.inbodyId).then(function (results) {
                if (results.status === 200) {
                    vm.loadingInbodyData = false;
                    vm.isInbodyDataAvailable = true;

                    vm.createUser.user.firstName = vm.parseName(0, results.data.user.firstName);
                    vm.createUser.user.lastName = vm.parseName(1, results.data.user.firstName);
                    vm.createUser.user.email = results.data.user.email;
                    vm.createUser.user.billingEmail = results.data.user.billingEmail;
                    vm.createUser.customer.cellPhone = results.data.customer.cellPhone;
                    vm.createUser.customer.cellPhoneOpt = results.data.customer.cellPhoneOpt;
                    vm.createUser.customer.gender = results.data.customer.gender.capitalize();
                    vm.createUser.customer.address1 = results.data.customer.address1;
                    vm.createUser.customer.address2 = results.data.customer.address2;
                    vm.createUser.customer.city = results.data.customer.city;
                    vm.createUser.customer.zipCode = results.data.customer.zipCode;
                    vm.createUser.customer.height = results.data.customer.height;
                    vm.createUser.customer.weight = results.data.customer.weight;
                    vm.createUser.customer.bodyFat = results.data.customer.bodyFat;
                    if (results.data.customer.dob) {
                        vm.createUser.customer.dob = DateTimeUtil.parseDate(results.data.customer.dob, '/');
                        vm.customerDOB = DateTimeUtil.formatDate(vm.createUser.customer.dob, 'MM.DD.YYYY');
                    }
                    vm.createUser.prefillReading = results.data.prefillReading;

                    form.inbodyToken.$setValidity('unknown', true);
                    document.getElementById("email").focus();
                } else {
                    vm.loadingInbodyData = false;
                    vm.isInbodyDataAvailable = false;
                    vm.createUser.prefillReading = null;
                }
            });
        }
    }

    function complete(string) {
        var output = [];
        angular.forEach(vm.groupsList, function (group) {
            if (group.name.toLowerCase().indexOf(string.toLowerCase()) > -1 && group.active) {
                output.push(group);
            }
        });
        vm.filterGroups = output;
    }

    function fillTextbox(group) {
        vm.userGroup.id = group.id;
        vm.userGroup.name = group.name;
        vm.userGroup.amountOfCustomers = group.amountOfCustomers;
        vm.userGroup.active = group.active;
        vm.filterGroups = null;
    }

    function parseName(idx, name) {
        if (name) {
            var nameArray = name.split(' ');
            return (nameArray.length > idx) ? nameArray[idx] : name;
        }
        return "";
    }

    function isScanDateEmpty() {
        return !vm.createUser.prefillReading || Object.keys(vm.createUser.prefillReading).length === 0;
    }

    function displayViewScanDataDialog() {
        ModalService.getScanData(vm.createUser.prefillReading);
    }

    function goToNextStep() {
        if (vm.createUser.customer.inbodyType === vm.inbodyTypes.NON_INBODY.value)
            vm.createUser.customer.inbodyId = '';

        if (!vm.isInbodyDataAvailable || vm.createUser.customer.inbodyType === vm.inbodyTypes.NON_INBODY.value) {
            vm.createUser.customer.height = vm.inbodyDataList.height;
            vm.createUser.customer.weight = vm.inbodyDataList.weight;
            vm.createUser.customer.bodyFat = vm.inbodyDataList.percentBodyFat;
        } else {
            vm.inbodyDataList = {
                weight: null,
                height: null,
                bodyFatMass: null,
                skeletalMuscleMass: null,
                dryLeanMass: null,
                inWatter: null,
                exWatter: null,
                ecwtbw: null,
                basalMetabolicRate: null,
                scanDate: null,
                scanTime: null,
                percentBodyFat: 0,
                leanBodyMass: 0,
                totalBodyWatter: 0,
                bodyMassIndex: 0
            };
        }

        vm.userGroup.active === false ?
            ModalService.alert('Group ' + vm.userGroup.name + ' is not active.', 'Warning') : $scope.next();
    }
}
