import React from 'react';

import classes from './RevenuePerformanceSummary.module.scss';
import { InfoBox } from '../../../../shared/components/ui-components';
import { TotalAmountChart } from './TotalAmountChart/TotalAmountChart';
import { TotalAmountGraph } from './TotalAmountGraph/TotalAmountGraph';
import { RevenueDateRangeFilterValues } from '../DetailedRevenue.settings';

type RevenuePerformanceSummaryProps = {
  entityId: number;
  dateRange: RevenueDateRangeFilterValues;
};

const RevenuePerformanceSummary = ({
  entityId,
  dateRange,
}: RevenuePerformanceSummaryProps) => {
  return (
    <InfoBox title="Revenue Performance Summary">
      <div className={classes.box}>
        <TotalAmountChart entityId={entityId} dateRange={dateRange} />
        <TotalAmountGraph entityId={entityId} dateRange={dateRange} />
      </div>
    </InfoBox>
  );
};

export { RevenuePerformanceSummary };
