import React from 'react';

import { observer } from 'mobx-react';

import classes from './AnswerInfoRow.module.scss';
import { TitleWithDescription } from '../../../../../../shared/components/text-formation-components';
import { QuestionType } from '../../../../../constants/question';
import { questionnaireReplyStore } from '../../../../../stores/questionnaireReplyStore';

type AnswerInfoRowProps = {
  title: string;
  questionType: QuestionType;
};

const AnswerInfoRow = observer(({ title, questionType }: AnswerInfoRowProps) => {
  const { getQuestionReplyByType } = questionnaireReplyStore;

  const { answer } = getQuestionReplyByType(questionType) || {};

  return (
    <div className={classes.answerInfoRow}>
      <div className={classes.infoTitle}>{title}</div>
      <TitleWithDescription
        title={answer?.name}
        description={answer?.description}
        titleFont="inherit"
      />
    </div>
  );
});

export { AnswerInfoRow };
