import React from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { observer } from 'mobx-react';

import { ButtonsRow } from '../../components/ButtonsRow/ButtonsRow';
import { setupEntityStore } from '../../../../stores/setupEntityStore';
import { entityService } from '../../../../services/entityService';
import {
  EntityBusinessInfoFromValue,
  EntityBusinessInfoInputs,
} from '../../../../components/EntityBusinessInfoInputs/EntityBusinessInfoInputs';

const BusinessInfoStep = observer(() => {
  const methods = useForm<EntityBusinessInfoFromValue>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const { handleSubmit } = methods;
  const { stepperMethods, setEntityId, setEntityType } = setupEntityStore;
  const { goToNextStep } = stepperMethods;

  const handleFormSubmit = handleSubmit(async ({ businessInfo }) => {
    const { entityID } = await entityService.createEntity({
      ...businessInfo,
      paymentFlowType: 'NEW_USER_FLOW_2024',
    });

    setEntityType(businessInfo.entityType);
    setEntityId(entityID);
    goToNextStep();
  });

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={handleFormSubmit}>
        <EntityBusinessInfoInputs mode="CREATE" />
        <ButtonsRow />
      </form>
    </FormProvider>
  );
});

export { BusinessInfoStep };
