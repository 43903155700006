import React from 'react';
import PropTypes from 'prop-types';

import classes from './CalendarColumn.module.scss';
import { DescriptionRow } from './DescriptionRow/DescriptionRow';
import { ScoreBox } from './ScoreBox/ScoreBox';
import { Calendar } from './Calendar/Calendar';

const CalendarColumn = ({
  color,
  date,
  setDate,
  descriptionText,
  onMonthChange,
  successfulDays,
  scoreInfo,
  minDate,
}) => {
  return (
    <div className={classes.calendarRow}>
      <Calendar
        color={color}
        date={date}
        setDate={setDate}
        onMonthChange={onMonthChange}
        successfulDays={successfulDays}
        minDate={minDate}
      />
      <div className={classes.infoBox}>
        <ScoreBox scoreInfo={scoreInfo} />
        <DescriptionRow color={color} descriptionText={descriptionText} />
      </div>
    </div>
  );
};

CalendarColumn.propTypes = {
  color: PropTypes.oneOf(['green', 'blue']).isRequired,
  date: PropTypes.any.isRequired,
  setDate: PropTypes.func.isRequired,
  descriptionText: PropTypes.string,
  onMonthChange: PropTypes.func,
  successfulDays: PropTypes.arrayOf(PropTypes.string),
  scoreInfo: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      score: PropTypes.string,
    }),
  ),
  minDate: PropTypes.object,
};

export { CalendarColumn };
