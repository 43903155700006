import { SpringPagination } from '../../shared/interfaces/SpringPagination';
import { apiService } from '../../shared/services/apiService';
import { EntityRowInfo } from '../interfaces/entity-list/EntityRowInfo';
import { EntityListFilters } from '../interfaces/entity-list/EntityListFilters';
import { EntityProfileInfo } from '../interfaces/EntityProfileInfo';
import { getFilteredTruthyFieldsObj } from '../../shared/utils/sortingAndGroupingUtil';

type GetEntityListResponse = SpringPagination<EntityRowInfo[]>;
type CreateEntityResponse = { entityID: number };

export const entityService = {
  async getEntityList(params: EntityListFilters) {
    const filteredParams = getFilteredTruthyFieldsObj(params);

    const resp = await apiService.post<GetEntityListResponse>(
      '/api/data-filters/communication/entities',
      filteredParams,
    );

    return resp.data;
  },

  async createEntity(entityInfo: EntityProfileInfo) {
    const resp = await apiService.post<CreateEntityResponse>(
      '/api/super/v3/entity',
      entityInfo,
    );

    return resp.data;
  },

  async getEntityBusinessInfo(entityId: number | string) {
    const resp = await apiService.get<EntityProfileInfo>(
      `/api/admin/v3/entity/${entityId}`,
    );

    return resp.data;
  },

  async updateEntityBusinessInfo(
    entityId: number | string,
    entityInfo: Partial<EntityProfileInfo>,
  ) {
    const resp = await apiService.put<EntityProfileInfo>(
      `/api/admin/v3/entity/${entityId}`,
      entityInfo,
    );

    return resp.data;
  },
};
