import React from 'react';

import { observer } from 'mobx-react';

import { CheckoutForm } from './CheckoutForm/CheckoutForm';
import { checkoutStore } from '../../../stores/checkoutStore';
import { StripeElementsProvider } from '../../../../shared/components/stripe';

const PaymentBlock = observer(() => {
  const { checkoutInfo } = checkoutStore;

  if (!checkoutInfo) {
    return null;
  }

  const { paymentIntent } = checkoutInfo;

  return (
    <StripeElementsProvider
      publishableKey={paymentIntent.publishableKey}
      connectedAccountId={paymentIntent.connectedAccountId}
      elementsOptions={{ clientSecret: paymentIntent.clientSecret }}
    >
      <CheckoutForm />
    </StripeElementsProvider>
  );
});

export { PaymentBlock };
