import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { GridTableRow } from '../../../../../../shared/components/table-components';
import { EditableView } from './RowView/EditableView/EditableView';
import { NormalView } from './RowView/NormalView/NormalView';

const InbodyKeysRow = ({ inbodyKeyInfo, index }) => {
  const [isEditable, setIsEditable] = useState(false);

  const rowViewDefaultProps = {
    setIsEditable,
    inbodyKeyInfo,
    index,
  };

  return (
    <GridTableRow variant="row">
      {isEditable ? (
        <EditableView {...rowViewDefaultProps} />
      ) : (
        <NormalView {...rowViewDefaultProps} />
      )}
    </GridTableRow>
  );
};

InbodyKeysRow.propTypes = {
  inbodyKeyInfo: PropTypes.shape({
    entityName: PropTypes.string,
    inbodyKey: PropTypes.string,
    accountName: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.number,
    entityId: PropTypes.number,
  }).isRequired,
  index: PropTypes.number.isRequired,
};

export { InbodyKeysRow };
