import React from 'react';
import PropTypes from 'prop-types';

import { useFormContext } from 'react-hook-form';
import { Button, Chip } from '@mui/material';

import classes from './ResultFilesRow.module.scss';
import { fileService } from '../../../../../shared/services/fileService';
import { customerTestsService } from '../../../../services/customerTestsService';

const ResultFilesRow = ({ nameIndex, updateField }) => {
  const { watch } = useFormContext();

  const files = watch(`${nameIndex}uploadedTestFiles`);

  const handleUpload = async (event) => {
    const selectedFile = event.target.files[0];

    const newFile = await customerTestsService.uploadTestFile(selectedFile);

    updateField({
      uploadedTestFiles: [...files, newFile],
    });
  };

  const handleDeleteFile = async (index) => {
    const fileId = files[index].id;
    await fileService.deleteFile(fileId);

    const newFiles = [...files];
    newFiles.splice(index, 1);
    updateField({ uploadedTestFiles: newFiles });
  };

  return (
    <div className={classes.fileBox}>
      {files?.length > 0 &&
        files.map((item, index) => (
          <Chip
            label={item.originFileName}
            key={item.id}
            onDelete={() => handleDeleteFile(index)}
            variant="file"
          />
        ))}
      <label htmlFor={`${nameIndex}testFile`}>
        <Button variant="outlined" className={classes.uploadBtn} component="span">
          <input
            onChange={handleUpload}
            id={`${nameIndex}testFile`}
            accept="application/pdf"
            type="file"
          />
          Upload PDF
        </Button>
      </label>
    </div>
  );
};

ResultFilesRow.propTypes = {
  nameIndex: PropTypes.string.isRequired,
  updateField: PropTypes.func.isRequired,
};

export { ResultFilesRow };
