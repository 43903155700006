import React from 'react';
import PropTypes from 'prop-types';

import classes from './ScoreBlock.module.scss';
import { StarText } from '../../../../../shared/components/ui-components';

const ScoreBlock = ({ score }) => {
  return (
    <div className={classes.scoreBlock}>
      <span>Compliance score:</span>
      <StarText>{score}</StarText>
    </div>
  );
};

export { ScoreBlock };

ScoreBlock.propTypes = {
  score: PropTypes.number.isRequired,
};
