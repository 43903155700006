import React from 'react';

import classNames from 'classnames';

import classes from './DiscountChip.module.scss';

type DiscountChipProps = {
  discountPercent?: number | null;
  show: boolean;
  className?: string;
};

const DiscountChip = ({ discountPercent, show, className }: DiscountChipProps) => {
  if (!show || !discountPercent) {
    return null;
  }

  const chipClasses = classNames(classes.discountChip, className);

  return <p className={chipClasses}>-{discountPercent}% Off</p>;
};

export { DiscountChip, DiscountChipProps };
