'use strict';

angular.module('app').factory('CustomersFilterService', CustomersFilterService);

CustomersFilterService.$inject = ['ApiService', 'utilService'];

function CustomersFilterService(ApiService, utilService) {
    return {
        loadGroups: function () {
            return ApiService.get('api/coach/groups/get-all-customer-groups').then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getCoachesForReassigning: function (entityId, complianceOnly) {
            var url = utilService.getLoggedInUserData().role === 'ADMIN' ? 'api/admin/coaches-short-list' : 'api/super/coaches-short-list/' + entityId;
            const params = { complianceOnly };

            return ApiService.get(url, params).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        reassignCoachForCustomers: function (id, data) {
            return ApiService.put('api/admin/assign/coach/' + id, data).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        }
    };
}
