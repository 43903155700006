import React from 'react';

import { Button } from '@mui/material';

import classes from './ButtonRow.module.scss';
import { YesNoBtnRow, YesNoBtnRowProps } from '../../../../shared/components/buttons';

type ButtonRowProps = Omit<YesNoBtnRowProps, 'children' | 'size'>;

const ButtonRow = ({
  yesText = 'Next Step',
  noText = 'Back',
  noHandler,
  ...props
}: ButtonRowProps) => {
  return (
    <YesNoBtnRow {...props} yesText={yesText} className={classes.buttonRow}>
      {!!noHandler && (
        <Button variant="outlined" onClick={noHandler}>
          {noText}
        </Button>
      )}
    </YesNoBtnRow>
  );
};

export { ButtonRow };
