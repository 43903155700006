const autoNgTemplateLoaderTemplate1 = require('./new-members-added.template.html');

'use strict';

angular
    .module('app')
    .directive('newMembersAdded', membersAdded);

membersAdded.$inject = [];

function membersAdded() {
    return {
        restrict: 'E',
        scope: {},
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: 'MembersAddedController',
        controllerAs: 'vm'
    };
}
