import { DependencyList } from 'react';

import { usePrevious, useUpdateEffect } from 'react-use';
import { isEqual, isNull } from 'lodash';

import { taxStore } from '../stores/taxStore';

// Required use observer
export const useEntityTaxUpdate = (effect: () => void, deps: DependencyList = []) => {
  const { entityTaxRate } = taxStore;
  const prevEntityTaxRateInfo = usePrevious(entityTaxRate);

  useUpdateEffect(() => {
    const isInitial = isNull(prevEntityTaxRateInfo);
    const isReset = isNull(entityTaxRate);
    const isChanged = !isEqual(prevEntityTaxRateInfo, entityTaxRate);

    if (!isInitial && !isReset && isChanged) {
      effect();
    }
  }, [entityTaxRate, ...deps]);
};
