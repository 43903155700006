import React from 'react';

import { Button } from '@mui/material';
import classNames from 'classnames';

import classes from './NavLink.module.scss';
import { useIsActivePage } from '../../../../hooks/useIsActivePage';

type NavLinkProps = {
  path: string;
  label: string;
};

const NavLink = ({ path, label }: NavLinkProps) => {
  const isActive = useIsActivePage({ url: path });

  const linkClasses = classNames(classes.link, { [classes.active]: isActive });

  return (
    <Button variant="unstyled" href={`#!${path}`} className={linkClasses}>
      {label}
    </Button>
  );
};

export { NavLink };
