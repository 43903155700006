import React, { useEffect } from 'react';

import { angularize } from 'react-in-angularjs';
import { Pagination } from '@mui/material';
import { observer } from 'mobx-react';

import classes from './NgzActiveCalendarUsers.module.scss';
import { SystemProviders } from '../../../shared/providers';
import { TabTitleRow } from '../../pages/customers-dashboard/components/TabTitleRow/TabTitleRow';
import { FiltersRow } from './FiltersRow/FiltersRow';
import { CustomerRow } from './CustomerRow/CustomerRow';
import { loggedInUserDataStore } from '../../../shared/stores/loggedInUserDataStore';
import { OptionRow } from './OptionRow/OptionRow';
import { activeUsersCommunicationStore } from '../../stores/activeUsersCommunicationStore';
import { EmptyList } from '../../../shared/components/ui-components';

const NgzActiveCalendarUsers = observer(() => {
  const {
    customers,
    pagesCount,
    currentPage,
    isInitialList,
    checkedCustomerIds,
    getCustomers,
    pageChangeHandler,
    setDefaultValues,
  } = activeUsersCommunicationStore;

  const { isCoach } = loggedInUserDataStore;

  useEffect(() => {
    getCustomers();

    return setDefaultValues;
  }, []);

  const isShowPlaceholder = !customers.length;

  const containerClasses = !isCoach ? classes.container : null;

  return (
    <SystemProviders>
      <div className={containerClasses}>
        <TabTitleRow />
        <OptionRow />
        <FiltersRow />
        {customers.map((customer) => (
          <CustomerRow
            key={customer.id}
            customer={customer}
            isChecked={checkedCustomerIds.includes(customer.id)}
          />
        ))}
        {pagesCount && (
          <Pagination
            count={pagesCount}
            onChange={pageChangeHandler}
            page={currentPage}
          />
        )}
        <EmptyList
          show={isShowPlaceholder}
          isSearch={isInitialList}
          description="There are no customers"
        />
      </div>
    </SystemProviders>
  );
});

angularize(
  NgzActiveCalendarUsers,
  'ngzActiveCalendarUsers',
  angular.module('app.active-calendar'),
  {},
);

export default NgzActiveCalendarUsers;
