import { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import { orderSupplementsService } from '../../../../services/orderService';

export const useInventoryAdjustmentTab = () => {
  const [customers, setCustomers] = useState([]);
  const [isInitialList, setIsInitialList] = useState(false);
  const [pagesCount, setPagesCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [params, setParams] = useState({ page: 0 });

  const { control, setValue, handleSubmit } = useForm({ mode: 'onBlur' });

  useEffect(() => {
    getData();
  }, [params]);

  const getData = async () => {
    const resp = await orderSupplementsService.getUsersInventory(params);

    if (!isInitialList) {
      setIsInitialList(!!resp.content.length);
    }

    setPagesCount(resp.totalPages);
    setCustomers(resp.content);
  };

  const onSubmit = (filteredParams) => {
    filteredParams.page = 0;

    setParams(filteredParams);
    setCurrentPage(1);
  };

  const changeOrderHandler = async (field, order) => {
    setValue('sortingField', field);
    setValue('direction', order);
    handleSubmit(onSubmit)();
  };

  const pageChangeHandler = (event, value) => {
    const page = value - 1;

    setParams({ ...params, page });
    setCurrentPage(value);
  };

  return {
    customers,
    pagesCount,
    currentPage,
    control,
    isInitialList,
    onSubmit,
    changeOrderHandler,
    pageChangeHandler,
    handleSubmit,
  };
};
