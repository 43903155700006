import React from 'react';
import PropTypes from 'prop-types';

import { TableCell } from '../../../../components/TableCell/TableCell';

const Cell = ({ text, adornment, className, position = 'center' }) => {
  return (
    <TableCell className={className} position={position}>
      <p>{text}</p>
      <span>{adornment}</span>
    </TableCell>
  );
};

Cell.propTypes = {
  position: PropTypes.oneOf(['start', 'center', 'end', 'between']),
  text: PropTypes.node,
  adornment: PropTypes.node,
  className: PropTypes.string,
};

export { Cell };
