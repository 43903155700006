import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { InputAdornment } from '@mui/material';
import { useUnmount } from 'react-use';

import classes from './PaymentHistoryList.module.scss';
import {
  EmptyList,
  InfoBox,
  LbPagination,
} from '../../../../shared/components/ui-components';
import { LbHorizontalScrollContainer } from '../../../../shared/components/LbHorizontalScrollContainer/LbHorizontalScrollContainer';
import { HeaderRow } from './HeaderRow/HeaderRow';
import { paymentHistoryListStore } from '../../../stores/paymentHistoryListStore';
import { PaymentRecordRow } from './PaymentRecordRow/PaymentRecordRow';
import { LbInput } from '../../../../shared/components/form-components';
import { useAutoSubmitForm } from '../../../../shared/hooks/useAutoSubmitForm';
import { PaymentHistoryFilterFormValues } from '../../../interfaces/paymentHistoryList';
import { RevenueDateRangeFilterValues } from '../DetailedRevenue.settings';

type PaymentHistoryListProps = {
  entityId: number;
  dateRange: RevenueDateRangeFilterValues;
};

type PaymentHistoryListFromValues = {
  customerNameOrEmail: string;
};

const PaymentHistoryList = observer(
  ({ entityId, dateRange }: PaymentHistoryListProps) => {
    const { control, handleSubmit, watch, getValues } =
      useForm<PaymentHistoryListFromValues>();

    const { paymentsList, filterMethods, getPaymentsList, setDefaultValues } =
      paymentHistoryListStore;

    const getFilterData = (
      formData: PaymentHistoryListFromValues,
    ): PaymentHistoryFilterFormValues => ({
      generalFilter: {
        ...dateRange,
        ...formData,
      },
    });

    const handleFormSubmit = handleSubmit((formData) => {
      const filterData = getFilterData(formData);

      filterMethods.applyFilters(filterData);
    });

    useAutoSubmitForm(handleFormSubmit, watch, ['customerNameOrEmail']);

    useEffect(() => {
      filterMethods.resetFilterData({ generalFilter: { ...dateRange } }); // fix show correct empty list placeholder
      const formData = getValues();
      const filterData = getFilterData(formData);

      getPaymentsList(entityId, filterData);
    }, [entityId, dateRange]);

    useUnmount(() => {
      setDefaultValues();
    });

    return (
      <InfoBox
        title="Payment History"
        icon={
          <LbInput
            control={control}
            name="customerNameOrEmail"
            variant="outlined"
            size="small"
            placeholder="Search by name or email"
            className={classes.searchInput}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchRoundedIcon fontSize="large" color="tertiary" />
                </InputAdornment>
              ),
            }}
          />
        }
        customContentPadding
      >
        <LbHorizontalScrollContainer variant="inBox">
          <div className={classes.paymentHistoryTable}>
            <HeaderRow />
            {paymentsList.map((paymentRecord) => (
              <PaymentRecordRow
                key={paymentRecord.payment.id}
                paymentRecord={paymentRecord}
              />
            ))}
          </div>
          <EmptyList
            show={filterMethods.isShowPlaceholder}
            isSearch={filterMethods.isSearchUsed}
            description="There are no payments"
            className={classes.staticOnScroll}
          />
        </LbHorizontalScrollContainer>
        <LbPagination
          {...filterMethods.getPaginationProps()}
          elementsPerPage={filterMethods.elementsPerPage}
          totalElements={filterMethods.totalElements}
          variant="inBox"
        />
      </InfoBox>
    );
  },
);

export { PaymentHistoryList };
