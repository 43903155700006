import { downloadFile } from './downloadFile';

export const downloadBinaryFile = (
  data: BlobPart,
  fileType: BlobPropertyBag['type'],
  fileName: string,
) => {
  const blob = new Blob([data], { type: fileType });

  const url = URL.createObjectURL(blob);

  downloadFile(url, fileName);

  URL.revokeObjectURL(url);
};
