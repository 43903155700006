import { useState } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';
import { useUpdateEffect } from 'react-use';

import { Option } from '../../../../../../../../../general-types';
import { CustomFilterFormValues } from '../../../AddCustomFilterForm.settings';
import { sportPositionOptions } from '../../../../../../../../constants/user-info/sport';

const getSportPositionOptions = (sport: string | null): Option[] => {
  if (sport) {
    return sportPositionOptions[sport] ?? [];
  } else {
    return [];
  }
};

export const usePositionOptions = () => {
  const { control, setValue } = useFormContext<CustomFilterFormValues>();

  const sportValue = useWatch({ name: 'filters.SCHOOL.sport', control });

  const [positionOptions, setPositionsOptions] = useState<Option[]>(
    getSportPositionOptions(sportValue),
  );

  useUpdateEffect(() => {
    setValue('filters.SCHOOL.position', '');

    const positions = getSportPositionOptions(sportValue);

    setPositionsOptions(positions);
  }, [sportValue]);

  return positionOptions;
};
