import React from 'react';

import classes from './SubscriptionDetailsModal.module.scss';
import { LbModal } from '../../../../../../shared/components/modals';
import { PromoCodeSubscription } from '../../../../../interfaces/promoCodeInfo';
import {
  PromoCodeApplyType,
  PromoCodeApplyTypeTitles,
} from '../../../../../constants/promoCodes';

type LbModalProps = {
  isOpen: boolean;
  promoCodeName: string;
  subscriptionSetupList: PromoCodeSubscription[] | null;
  applyType: PromoCodeApplyType;
  onClose: () => void;
};

const SubscriptionDetailsModal = ({
  isOpen,
  promoCodeName,
  subscriptionSetupList,
  applyType,
  onClose,
}: LbModalProps) => {
  return (
    <LbModal
      open={isOpen}
      onClose={onClose}
      secondaryBtnConfig={{
        text: 'Close',
        onClick: onClose,
      }}
      title={`Selected Subscriptions of ${promoCodeName}`}
    >
      <div className={classes.container}>
        <table className={classes.table}>
          <tbody>
            <tr>
              <th>Subscription Type</th>
              <td>{PromoCodeApplyTypeTitles[applyType]}</td>
            </tr>
            <tr>
              <th>Applied Subscriptions</th>
              <td>
                {subscriptionSetupList?.length
                  ? subscriptionSetupList.map((subscription, index) => (
                      <p key={subscription.id}>
                        {subscription.name}
                        {index === subscriptionSetupList.length - 1 ? '.' : ','}
                      </p>
                    ))
                  : 'All Subscriptions'}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </LbModal>
  );
};

export { SubscriptionDetailsModal };
