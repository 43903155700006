import React from 'react';

import { observer } from 'mobx-react';

import { useModalToggle } from '../../../../../../shared/hooks/useModalToggle';
import { AddTestBtn } from '../../../../../components/AddTestBtn/AddTestBtn';
import { AddTestModals } from '../../../../../components/AddTestModals/AddTestModals';
import { customerMedicalTestsStore } from '../../../../../stores/customerMedicalTestsStore';
import { medicalTestsStore } from '../../../../../stores/medicalTestsStore';
import { TabTitleRow } from '../../../components/TabTitleRow/TabTitleRow';
import { MedicalTestForm } from '../MedicalTestForm/MedicalTestForm';

const AddTestBlock = observer(() => {
  const [isAddTestModalOpen, addTestModalHandler] = useModalToggle();

  const {
    medicalTestForm,
    medicalTests,
    isFormOpen,
    addNewTestForm,
    sendTest,
    cleanMedicalTestForm,
  } = customerMedicalTestsStore;

  const { isTestsDisabled } = medicalTestsStore;

  const addTestHandler = ({ id, testName }) => {
    addNewTestForm({ id, testName });
    addTestModalHandler();
  };

  const onSubmit = async (testData) => {
    sendTest(testData);
    cleanMedicalTestForm();
  };

  const isAddTestBtnDisabled = isTestsDisabled || isFormOpen;

  return (
    <>
      <TabTitleRow title="Lab Tests">
        <AddTestBtn onClick={addTestModalHandler} disabled={isAddTestBtnDisabled} />
      </TabTitleRow>
      {medicalTestForm && (
        <MedicalTestForm
          defaultValues={medicalTestForm}
          onCancel={cleanMedicalTestForm}
          onSubmit={onSubmit}
        />
      )}
      <AddTestModals
        open={isAddTestModalOpen}
        addTestHandler={addTestHandler}
        selectedTests={medicalTests}
        cancelHandler={addTestModalHandler}
      />
    </>
  );
});

export { AddTestBlock };
