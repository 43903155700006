import React from 'react';
import PropTypes from 'prop-types';

import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';

import classes from './UnderlineRow.module.scss';

const UnderlineRow = ({ isShowMoreBtn, isShowMore, showMoreHandler }) => {
  const ShowMoreBtn = (
    <>
      <span>See more</span>
      <ArrowDropDownRoundedIcon fontSize="large" />
    </>
  );

  const ShowLessBtn = (
    <>
      <span>See less</span>
      <ArrowDropUpRoundedIcon fontSize="large" />
    </>
  );

  return (
    <div className={classes.underlineRow}>
      <span className={classes.line} />
      {isShowMoreBtn && (
        <div className={classes.btn} onClick={showMoreHandler}>
          {isShowMore ? ShowLessBtn : ShowMoreBtn}
        </div>
      )}
    </div>
  );
};

UnderlineRow.propTypes = {
  isShowMoreBtn: PropTypes.bool,
  isShowMore: PropTypes.bool,
  showMoreHandler: PropTypes.func,
};

export { UnderlineRow };
