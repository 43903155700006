import React from 'react';

import { AdapterMoment as DateAdapter } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { Moment } from 'moment';

import classes from './Calendar.module.scss';
import { RenderDay, RenderDayProps } from './RenderDay/RenderDay';

type CalendarProps = {
  color: 'green' | 'blue';
  date: Moment;
  setDate: (value: Moment | null) => void;
  onMonthChange?: (month: Moment) => void;
  successfulDays?: string[];
  minDate?: Moment;
} & RenderDayProps;

const Calendar = ({
  color,
  date,
  setDate,
  onMonthChange,
  successfulDays,
  minDate,
}: CalendarProps) => {
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <StaticDatePicker
        className={classes.calendar}
        displayStaticWrapperAs="desktop"
        value={date}
        views={['day']}
        disableFuture
        minDate={minDate}
        onMonthChange={onMonthChange}
        onChange={(newValue) => setDate(newValue)}
        slotProps={{
          day: { successfulDays, color } as any,
        }}
        slots={{
          day: RenderDay as any,
        }}
      />
    </LocalizationProvider>
  );
};

export { Calendar };
