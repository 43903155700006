import React from 'react';

import { Paper } from '@mui/material';

import classes from './WorkoutMeals.module.scss';
import { MealHeaderRow, MealRow } from '../meal-table-components';

// TODO refactor to use almost the same component in workout settings
const WorkoutMeals = () => {
  return (
    <Paper variant="roundedBox" className={classes.mealTable}>
      <MealHeaderRow />
      <MealRow meal="preWorkout" mealFormKey="preWorkout" />
      <MealRow meal="postWorkout" mealFormKey="postWorkout" />
    </Paper>
  );
};

export { WorkoutMeals };
