import { MED_TEST_RESULT_STATUSES_KEYS } from './MED_TEST_RESULT_STATUSES_KEYS';

const { APPOINTMENT_COMPLETED, TEST_COMPLETED, ROF } = MED_TEST_RESULT_STATUSES_KEYS;

export const MED_TEST_RESULT_STATUSES_LABELS = {
  [APPOINTMENT_COMPLETED]: {
    true: 'Appointment Confirmed',
    false: 'Appointment Non-Confirmed',
  },
  [TEST_COMPLETED]: {
    true: 'Test Completed',
    false: 'Test Uncompleted',
  },
  [ROF]: {
    true: 'ROF Completed',
    false: 'ROF Uncompleted',
  },
};
