import { MenuProps } from '@mui/material';

export const lbSelectMenuStyles: MenuProps['sx'] = {
  '& .MuiMenu-paper': {
    borderRadius: '7px',
    padding: '8px',
    boxShadow: 'var(--panel-shadow)',

    '& .MuiList-root': {
      maxHeight: '283px',
      overflowY: 'auto',
      padding: '0 8px 0 0',
    },
    '& .MuiList-root::-webkit-scrollbar': {
      width: '4px',
    },
    '& .MuiList-root::-webkit-scrollbar-thumb': {
      backgroundColor: 'var(--natural-300)',
      borderRadius: '4px',
    },
    '& .MuiMenuItem-root': {
      font: 'var(--body-14-medium)',
      padding: '10px',
    },
    '& .MuiMenuItem-root:hover': {
      backgroundColor: 'var(--warning-50)',
      borderRadius: '4px',
    },
    '& .Mui-selected.MuiMenuItem-root': {
      color: 'var(--primary-500)',
      backgroundColor: 'inherit',
    },
    '& .Mui-selected.MuiMenuItem-root:hover': {
      backgroundColor: 'var(--warning-50)',
    },
  },
};
