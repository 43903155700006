import React from 'react';

import classes from './PageTitle.module.scss';

const PageTitle = () => {
  return (
    <div className={classes.titleRow}>
      <h2 className={classes.title}>
        Double-check all information and pay for the subscription chosen by the coach
      </h2>
      <p className={classes.description}>Proceed to the checkout with Stripe</p>
    </div>
  );
};

export { PageTitle };
