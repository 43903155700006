import React from 'react';
import { useToggle } from 'react-use';
import { Button } from '@mui/material';

import { pluralize } from '../../../../../../shared/utils/stringUtils/pluralize/pluralize';
import { PromoCodeSubscription } from '../../../../../interfaces/promoCodeInfo';
import { SubscriptionDetailsModal } from '../SubscriptionDetailsModal/SubscriptionDetailsModal';
import { PromoCodeApplyType } from '../../../../../constants/promoCodes';

type SubscriptionTypeCellProps = {
  promoCodeName: string;
  subscriptionSetupList: PromoCodeSubscription[] | null;
  applyType: PromoCodeApplyType;
};

const SubscriptionTypeCell = ({
  promoCodeName,
  subscriptionSetupList,
  applyType,
}: SubscriptionTypeCellProps) => {
  const [isModalOpen, toggleModalOpen] = useToggle(false);

  return (
    <div>
      <Button variant="link" onClick={toggleModalOpen}>
        {subscriptionSetupList?.length
          ? pluralize(subscriptionSetupList.length, 'Subscription')
          : 'All Subscriptions'}
      </Button>
      <SubscriptionDetailsModal
        promoCodeName={promoCodeName}
        subscriptionSetupList={subscriptionSetupList}
        applyType={applyType}
        isOpen={isModalOpen}
        onClose={toggleModalOpen}
      />
    </div>
  );
};

export { SubscriptionTypeCell };
