import React, { MouseEventHandler } from 'react';

import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { Button, ButtonProps } from '@mui/material';
import classNames from 'classnames';

import classes from './ApplyAndRestBtn.module.scss';

type ApplyAndRestBtnProps = {
  onSubmit: MouseEventHandler<HTMLButtonElement>;
  onReset: MouseEventHandler<HTMLButtonElement>;
  className?: string;
} & Pick<ButtonProps, 'size'>;

// TODO update size to 40
const ApplyAndRestBtn = ({
  onSubmit,
  onReset,
  className,
  size = 'small',
}: ApplyAndRestBtnProps) => {
  const btnBoxClasses = classNames(classes.btnBox, className);

  return (
    <div className={btnBoxClasses}>
      <Button
        variant="outlined"
        size={size}
        type="submit"
        onClick={onSubmit}
        className={classes.applyBtn}
      >
        Apply
      </Button>
      <Button
        variant="outlined"
        size={size}
        type="reset"
        className="onlyIcon"
        onClick={onReset}
      >
        <DeleteOutlineRoundedIcon fontSize="medium" />
      </Button>
    </div>
  );
};

export { ApplyAndRestBtn };
