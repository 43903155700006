import { Appearance } from '@stripe/stripe-js';

import { palette } from '../../../theme/palette';

export const stripeElementsAppearance: Appearance = {
  variables: {
    fontFamily: 'Lato, sans-serif',
    colorPrimary: palette.primary['500'],
    fontSizeBase: '16px',
    borderRadius: '5px',
    colorText: palette.mainBlack,
    colorTextPlaceholder: palette.natural['600'],
  },
};
