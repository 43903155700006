import React from 'react';

import { Link } from '@mui/material';
import classes from './Footer.module.scss';
import {
  stripePrivacyPolicyLink,
  stripeTermsOfUseLink,
} from '../../../../constants/externalLinks';

const Footer = () => {
  return (
    <div className={classes.container}>
      <p>Powered by Stripe</p>
      <div className={classes.privacyLinks}>
        <Link className={classes.link} href={stripeTermsOfUseLink}>
          Terms
        </Link>
        <p>·</p>
        <Link className={classes.link} href={stripePrivacyPolicyLink}>
          Policy
        </Link>
      </div>
    </div>
  );
};

export { Footer };
