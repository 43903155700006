import { inventoryStatus } from '../../../../../constants/inventoryStatus';

export const defaultInputProps = {
  variant: 'outlined',
};

export const statusOption = [
  { value: '', label: 'All' },
  { value: inventoryStatus.LOW_INVENTORY, label: 'Low Inventory' },
  { value: inventoryStatus.SUFFICIENT_INVENTORY, label: 'Sufficient Inventory' },
  { value: inventoryStatus.PENDING, label: 'Pending' },
];
