import moment from 'moment';

export const validateAge = (value, minAge = 12) => {
  const isDateLessMinAge = moment(value) < moment().subtract(minAge, 'years');

  const errorMessage = `The user must be ${minAge} years old at least`;

  return isDateLessMinAge ? true : errorMessage;
};

export const validateMinAge = (value) => validateAge(value);
