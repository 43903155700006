import { getAvailableModules } from '../../../modules/shared/utils/paidModulesUtils';

(function () {
  'use strict';

  angular.module('app').controller('EntityInbodyController', EntityInbodyController);

  EntityInbodyController.$inject = ['$scope', 'constants', '$location', 'utilService', 'EntityService', 'ModalService'];

  function EntityInbodyController($scope, constants, $location, utilService, EntityService, ModalService) {
    const vm = this;
    vm.spinner = false;
    vm.entity = $scope.createEntity;
    vm.inbodyDetails = {
      allowNonInbodyUsers: false,
      entryFields: {
        weight: true,
        gender: true,
        height: true,
        percent_body_fat: true,
        poundOfFat: true,
        lbm: true,
        navel: false,
        thigh: false,
        bicep: false,
        chest: false,
        hip: false,
        icw: false,
        ecw: false,
        tbw: false,
        dlm: false,
        smm: false,
        bmr: false,
        neck: false,
        phaseAngle: false,
        measureUnit: 'INCHES'
      },
      supportedInbodyTypes: {
        inbody_270: false,
        inbody_370: false,
        inbody_380H: false,
        inbody_570: false,
        inbody_580H: false,
        inbody_770: false,
        inbody_970: false
      }
    };
    vm.inbodyTypes = constants.inbodyTypes;
    vm.dataEntryFields = angular.copy(constants.inbodyData);
    vm.measureUnits = constants.measureUnits;
    vm.ENTITY_TYPES = constants.enums.entityTypes;
    vm.isSuperAdmin = utilService.getIsSuperAdmin();
    vm.availablePaidServiceTypes = [];
    vm.isActiveCalendarAvailable = false;
    vm.entityType = '';

    vm.initOnEntitySetup = initOnEntitySetup;
    vm.setIsActiveCalendarAvailable = setIsActiveCalendarAvailable;
    vm.getInbodyDetails = getInbodyDetails;
    vm.getIsInbodyTypeCardValid = getIsInbodyTypeCardValid;
    vm.getIsInbodySelected = getIsInbodySelected;
    vm.onOptionalCheckboxChange = onOptionalCheckboxChange;
    vm.onSkip = onSkip;
    vm.onSubmit = onSubmit;
    vm.saveInbodyDetails = saveInbodyDetails;
    vm.saveSerialNumbers = saveSerialNumbers;
    vm.setDefaultSelectedInbody = setDefaultSelectedInbody;
    vm.setDefaultDataEntryFields = setDefaultDataEntryFields;
    vm.configDataEntryFieldsForScholasticEntity = configDataEntryFieldsForScholasticEntity;

    vm.$onInit = () => {
      if ($location.url().includes('entity-profile')) {
        vm.isEntityProfile = true;
        vm.getInbodyDetails();
        vm.entityType = $scope.entityType;
      } else {
        vm.initOnEntitySetup();
      }
    }

    function getInbodyDetails() {
      vm.spinner = true;
      EntityService.getInbodyDetails($scope.entityId)
        .then(res => {
          if (res.status === 200) {
            Object.assign(vm.inbodyDetails, res.data);
          }
        })
        .finally(() => vm.spinner = false);
    }

    function initOnEntitySetup() {
      vm.entityType = vm.entity.entityType;
      vm.setDefaultSelectedInbody();
      vm.setDefaultDataEntryFields();
      vm.setIsActiveCalendarAvailable();
    }
    
    function setDefaultSelectedInbody() {
      const entityTypeWithDefaultSelectNonInbody = [vm.ENTITY_TYPES.SCHOLASTIC];
      const isNonInbodyDefaultSelected = entityTypeWithDefaultSelectNonInbody.includes(vm.entityType);

      vm.inbodyDetails.allowNonInbodyUsers = isNonInbodyDefaultSelected;
    }

    function setDefaultDataEntryFields() {
      switch (vm.entityType) {
        case vm.ENTITY_TYPES.SCHOLASTIC:
          vm.configDataEntryFieldsForScholasticEntity();
          break;
        default:
          return null;
      }
    }

    function configDataEntryFieldsForScholasticEntity() {
      const unselectedField = ['percent_body_fat', 'poundOfFat', 'lbm'];
      
      unselectedField.forEach(field => {
        vm.inbodyDetails.entryFields[field] = false;
        vm.dataEntryFields[field].required = false;
      });
    }

    async function setIsActiveCalendarAvailable() {
      const availableModules = await getAvailableModules(vm.entityType);
      vm.isActiveCalendarAvailable = availableModules.ACTIVE_CALENDAR
      vm.entity.isActiveCalendarAvailable = vm.isActiveCalendarAvailable;
      $scope.$apply();
    }
    
    function getIsInbodyTypeCardValid() {
      if (vm.inbodyDetails.allowNonInbodyUsers) {
        return true;
      } else {
        return vm.getIsInbodySelected();
      }
    }

    function getIsInbodySelected() {
      return Object.keys(vm.inbodyDetails.supportedInbodyTypes)
        .filter(key => key !== 'serialNumber')
        .some(key => vm.inbodyDetails.supportedInbodyTypes[key]);
    }

    function onOptionalCheckboxChange() {
      if (!vm.inbodyDetails.entryFields.icw || !vm.inbodyDetails.entryFields.ecw) {
        vm.inbodyDetails.entryFields.tbw = false;
      }
    }

    function onSkip() {
      ModalService.confirm('Nutrition module will be disabled', vm.onSubmit);
    }

    function onSubmit() {
      if (vm.isEntityProfile) {
        vm.saveInbodyDetails();
      } else {
        vm.entity = Object.assign(vm.entity, vm.inbodyDetails);
        $scope.next();
      }
    }

    function saveInbodyDetails() {
      vm.spinner = true;
      EntityService.saveInbodyDetails($scope.entityId, vm.inbodyDetails)
        .then(res => {
          if (res.status === 200) {
            ModalService.savedChanges();
          }
        })
        .finally(() => vm.spinner = false);
    }

    function saveSerialNumbers() {
      const body = [vm.inbodyDetails.supportedInbodyTypes.serialNumber];
      vm.spinner = true;
      EntityService.saveInbodySerialNumbers($scope.entityId, body)
        .then(res => {
          if (res.status === 200) {
            ModalService.savedChanges();
          }
        })
        .finally(() => vm.spinner = false);
    }
  }

})();
