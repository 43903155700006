import { AxiosRequestConfig } from 'axios';

import { apiService } from '../../shared/services/apiService';
import { fileService } from '../../shared/services/fileService';
import { uploadFileTypes } from '../../shared/constants/uploadFileTypes';
import { LoadedFile } from '../../shared/interfaces/LoadedFile';

type GetRegistrationLinkResponse = {
  url: string;
};

type RequestConfig = Pick<AxiosRequestConfig, 'isRunGlobalSpinner'>;

export const entityCustomerSetupService = {
  async getRegistrationLink(entityId: number) {
    const resp = await apiService.get<GetRegistrationLinkResponse>(
      `api/entity/${entityId}/settings/customer-setup/link`,
    );

    return resp.data;
  },

  async getRegistrationQrCode(entityId: number) {
    const resp = await apiService.get<LoadedFile>(
      `api/entity/${entityId}/settings/customer-setup/qr-code`,
    );

    return resp.data;
  },

  async getCompanyLogo(entityId: number, config?: RequestConfig) {
    const resp = await apiService.get<LoadedFile | undefined>(
      `api/entity/${entityId}/settings/logo`,
      config,
    );

    return resp.data;
  },

  async uploadCompanyLogo(entityId: number, file: File) {
    const { id: logoId } = await fileService.uploadFile(
      uploadFileTypes.ENTITY_LOGO,
      file,
    );

    return this.setCompanyLogo(entityId, logoId);
  },

  async setCompanyLogo(entityId: number, logoId: number) {
    const params = { logoId };

    const resp = await apiService.put<LoadedFile>(
      `api/entity/${entityId}/settings/logo`,
      null,
      { params },
    );

    return resp.data;
  },

  async deleteCompanyLogo(entityId: number) {
    const resp = await apiService.delete(`api/entity/${entityId}/settings/logo`);

    return resp.data;
  },
};
