// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../assets/images/material-icons/certification-assigned.svg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<md-dialog layout-padding=\"\" class=\"certification-assigned\"> <md-dialog-content> <img class=\"certification-assigned__image\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"> <h2 class=\"certification-assigned__title\">Certification assigned!</h2> <p class=\"certification-assigned__text\">Credentials to the Moodle system course were successfully sent to coach e-mail. </p> </md-dialog-content> <md-dialog-actions layout=\"row\" layout-align=\"center center\"> <button class=\"outline\" ng-click=\"cancel()\">Ok</button> </md-dialog-actions> </md-dialog> ";
// Exports
var _module_exports = code;;
var path = 'app/modals/certification-assigned.modal.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;