import React from 'react';
import { useFormContext } from 'react-hook-form';

import { LbDatePicker } from '../../../../../../../shared/components/form-components';
import { ReportSetting } from '../../../../../../interfaces/ReportSetting';
import { getRequiredRules } from '../../utils/getRequiredRules';

type ReportDatePickerProps = {
  setting: ReportSetting;
};

const ReportDatePicker = ({ setting }: ReportDatePickerProps) => {
  const { control } = useFormContext();
  const { selectorLabel, parameterKey, selectAllAvailable } = setting;

  const rules = getRequiredRules(selectAllAvailable, selectorLabel!);

  return (
    <LbDatePicker
      control={control}
      variant="outlined"
      size="small"
      name={parameterKey!}
      label={selectorLabel}
      rules={rules}
    />
  );
};

export { ReportDatePicker };
