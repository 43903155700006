import React from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { observer } from 'mobx-react';

import { MenuOptions } from '../../../../general-types';
import { LbMenu, LbMenuProps } from '../LbMenu/LbMenu';

type OptionsButtonVariant = 'icon' | 'outlined';

type OptionsMenuProps = {
  options: MenuOptions[];
  variant?: OptionsButtonVariant;
};

// If need more control on button rewrite used buttonProps
const OptionsMenu = observer(({ options, variant = 'outlined' }: OptionsMenuProps) => {
  const variantProps: Record<OptionsButtonVariant, LbMenuProps['buttonProps']> = {
    icon: { variant: 'text', color: 'secondary' },
    outlined: { variant: 'outlined' },
  };

  return (
    <LbMenu
      buttonProps={{ className: 'onlyIcon', ...variantProps[variant] }}
      options={options}
    >
      <MoreVertIcon fontSize="medium" />
    </LbMenu>
  );
});

export { OptionsMenu };
