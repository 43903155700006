'use strict';

(function () {
    angular
        .module('app', [
            'app.config',
            'app.addFood',
            'app.addVideo',
            'app.foods',
            'app.leaderboard',
            'app.supplementList',
            'app.pinCode',
            'app.entityStats',
            'app.entityDashboard',
            'app.salesDashboard',
            'app.salesDeposits',
            'app.salesIncomeTransactions',
            'app.termsAndConditions',
            'app.membersAdded',
            'app.revenueReport',
            'app.superDashboard',
            'app.recommendedSteps',
            'app.coachList',
            'app.coachEdit',
            'app.coachView',
            'app.coachSetup',
            'app.coachDashboard',
            'app.coachProfile',
            'app.operatorSetup',
            'app.operatorProfile',
            'app.sharing',
            'app.customersDashboard',
            'app.userProfile',
            'app.customerProfile',
            'app.scoreTable',
            'app.entityProfile',
            'app.customerSetup',
            'app.preApprovalSetup',
            'app.preCheckIn',
            'app.preCheckInProceed',
            'app.entitySetup',
            'app.supplementSetup',
            'app.supplementProfile',
            'app.certificates',
            'app.nutrition',
            'app.addSalesRep',
            'app.coachNotes',
            'app.recipes',
            'app.addRecipe',
            'app.childEntityList',
            'app.active-calendar',
            'app.releases',
            'app.foodScore',
            'app.reports',
            'app.guidesAndAdditionalInfo',
            'app.signInAuth0',
            'app.scholastic',
            'app.entityManagement',
            'app.customerManagement',
            'app.authManagement',
            'app.inbody',
            'app.payment',
        ]);
})();
