import React, { ReactNode } from 'react';

import { IconButton, IconProps, Tooltip, TooltipProps } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import classNames from 'classnames';
import { observer } from 'mobx-react';

import classes from './SortButtons.module.scss';
import { SortDirection } from '../../../../general-types';

type SortButtonsProps<TFieldName extends string> = {
  children: ReactNode;
  fieldName: TFieldName;
  onSort: (fieldName: TFieldName, direction: SortDirection) => void;
  getIsActive: (fieldName: TFieldName, direction: SortDirection) => boolean;
  tooltipTitle?: {
    asc: ReactNode;
    desc: ReactNode;
  };
  disabled?: boolean;
  className?: string;
};

const SortButtons = observer(
  <TFieldName extends string>({
    children,
    fieldName,
    onSort,
    getIsActive,
    tooltipTitle,
    disabled,
    className,
  }: SortButtonsProps<TFieldName>) => {
    const sortButtonClasses = classNames(classes.sortButtons, className);

    const tooltipProps: Partial<TooltipProps> = { enterDelay: 500, enterNextDelay: 500 };

    const getColor = (direction: SortDirection): IconProps['color'] =>
      getIsActive(fieldName, direction) ? 'primary' : 'inherit';

    const ascColor = getColor('ASC');
    const descColor = getColor('DESC');

    return (
      <div className={sortButtonClasses}>
        {children}
        <div className={classes.arrowBox}>
          <Tooltip {...tooltipProps} title={tooltipTitle?.asc}>
            <IconButton onClick={() => onSort(fieldName, 'ASC')} disabled={disabled}>
              <KeyboardArrowUpRoundedIcon fontSize="medium" color={ascColor} />
            </IconButton>
          </Tooltip>
          <Tooltip {...tooltipProps} title={tooltipTitle?.desc} placement="bottom">
            <IconButton onClick={() => onSort(fieldName, 'DESC')} disabled={disabled}>
              <KeyboardArrowDownRoundedIcon fontSize="medium" color={descColor} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    );
  },
);

export { SortButtons, SortButtonsProps };
