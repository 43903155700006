export const getConfirmationModalRoot = () => {
  const modalRootId = 'confirmation-modal-root';

  const existModalRoot = document.getElementById(modalRootId);

  if (existModalRoot) {
    return existModalRoot;
  }

  const newModalRoot = document.createElement('div');
  newModalRoot.setAttribute('id', modalRootId);
  document.body.appendChild(newModalRoot);

  return newModalRoot;
};
