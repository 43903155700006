import React from 'react';
import PropTypes from 'prop-types';

import { Button, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import classes from './CardContainer.module.scss';

const CardContainer = ({ modalToggleHandler, isCardAdded }) => {
  const cardContainer = (
    <div className={classes.container}>
      <div>
        <Typography>Please add your credit card info.</Typography>
        <Typography>We do not store your confidential information.</Typography>
      </div>
      <Button variant="contained" size="medium" onClick={modalToggleHandler}>
        Add credit card info
      </Button>
    </div>
  );

  const onUpdateCardContainer = (
    <div className={classes.container}>
      <div className={classes.updateInfoContainer}>
        <CheckCircleIcon className={classes.icon} fontSize="small" />
        <Typography className={classes.active}>
          Card information has been added.
        </Typography>
      </div>
      <Button variant="outlined" size="medium" onClick={modalToggleHandler}>
        Update
      </Button>
    </div>
  );

  return isCardAdded ? onUpdateCardContainer : cardContainer;
};

CardContainer.propTypes = {
  modalToggleHandler: PropTypes.func,
  isCardAdded: PropTypes.bool,
};

export { CardContainer };
