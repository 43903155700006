'use strict';

import { getLoginRequiredResolveObj } from '../shared/utils/angularUtils';

export const reportsModule = angular
  .module('app.entityManagement', [])
  .config(reportsConfig);

reportsConfig.$inject = ['$routeProvider'];

function reportsConfig($routeProvider) {
  $routeProvider
    .when('/entities-list', {
    template: '<ngz-entity-list></ngz-entity-list>',
    resolve: getLoginRequiredResolveObj(),
    })
    .when('/entity-setup-flow-2024', {
      template: '<ngz-entity-flow-2024-setup></ngz-entity-flow-2024-setup>',
      resolve: getLoginRequiredResolveObj(),
    })
  
}