import React from 'react';

import { Button } from '@mui/material';
import { useForm } from 'react-hook-form';

import classes from './FeeModuleWidget.module.scss';
import { InfoBox, LbAlert } from '../../../shared/components/ui-components';
import { EntityType } from '../../../shared/constants/user-info/ENTITY_TYPES';
import { useFeeFormModules } from './hooks/useFeeFormModules';
import { FeeModulesCheckboxGroup } from './FeeModulesCheckboxGroup/FeeModulesCheckboxGroup';
import { Mode } from '../../../general-types';
import { FeeModuleSelectFormValues } from './FeeModuleWidget.settings';

type FeeModuleWidgetProps = {
  entityType: EntityType;
  entityId: number;
  onSuccessSubmit?: () => void;
  className?: string;
};

const FeeModuleWidget = ({
  entityType,
  entityId,
  onSuccessSubmit = () => {},
  className,
}: FeeModuleWidgetProps) => {
  const { feeFormModules, feeModuleValues, isCreated, createPaymentBundle } =
    useFeeFormModules(entityType, entityId);

  const { handleSubmit, control } = useForm<FeeModuleSelectFormValues>({
    values: feeModuleValues,
  });

  const handleFormSubmit = handleSubmit(async ({ feeModules }) => {
    await createPaymentBundle(feeModules);
    onSuccessSubmit();
  });

  const mode: Mode = isCreated ? 'VIEW' : 'EDIT';
  const isShowSaveButton = isCreated === false;

  const SaveButton = (
    <Button variant="contained" size="small" onClick={handleFormSubmit}>
      Save
    </Button>
  );

  return (
    <InfoBox
      title="Module & users configuration"
      icon={isShowSaveButton && SaveButton}
      className={className}
    >
      <form className={classes.box}>
        <div className={classes.moduleBox}>
          <FeeModulesCheckboxGroup
            control={control}
            modules={feeFormModules}
            moduleType="OPTIONAL_MODULES"
            mode={mode}
          />
          <FeeModulesCheckboxGroup
            control={control}
            modules={feeFormModules}
            moduleType="MONTHLY_FEES"
            mode={mode}
          />
        </div>
        <LbAlert size="small" severity="info">
          Disabling modules could limit access to certain client data
        </LbAlert>
      </form>
    </InfoBox>
  );
};

export { FeeModuleWidget };
