import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { REPORT_SELECTORS_TYPES } from '../../../../../constants/REPORT_SELECTORS_TYPES';
import { Selector } from '../Selector/Selector';

const SelectorsGroup = ({ selectors, parentSelectorSettings, reportType }) => {
  return selectors.map(({ subSettings, ...selectorSettings }) => {
    if (selectorSettings.selectorType === REPORT_SELECTORS_TYPES.NO_SELECTOR) {
      return null;
    }

    return (
      <Fragment key={selectorSettings.selectorType}>
        <Selector
          selectorSettings={selectorSettings}
          parentSelectorSettings={parentSelectorSettings}
          reportType={reportType}
        />
        {subSettings && (
          <SelectorsGroup
            selectors={subSettings}
            parentSelectorSettings={selectorSettings}
            reportType={reportType}
          />
        )}
      </Fragment>
    );
  });
};

SelectorsGroup.propTypes = {
  selectors: PropTypes.array,
  parentSelectorSettings: PropTypes.object,
  reportType: PropTypes.string,
};

export { SelectorsGroup };
