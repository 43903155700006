import React from 'react';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import BlockIcon from '@mui/icons-material/Block';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import LoopRoundedIcon from '@mui/icons-material/LoopRounded';

import { Status } from '../../../../../shared/components/ui-components';
import { ScholasticCustomersStatusType } from '../../../../constants/scholasticCustomersStatus';

type StatusCellProps = {
  status: ScholasticCustomersStatusType;
};

const StatusCell = ({ status }: StatusCellProps) => {
  const dataTestId = 'status-cell';

  switch (status) {
    case 'ACTIVE':
      return (
        <Status
          icon={<CheckCircleRoundedIcon fontSize="large" color="success" />}
          dataTestId={dataTestId}
        >
          Active
        </Status>
      );
    case 'EXPIRED':
      return (
        <Status
          icon={<AccessTimeRoundedIcon fontSize="large" color="error" />}
          dataTestId={dataTestId}
        >
          Expired
        </Status>
      );
    case 'INCOMPLETE':
      return (
        <Status
          icon={<BlockIcon fontSize="large" color="error" />}
          dataTestId={dataTestId}
        >
          Incomplete
        </Status>
      );
    case 'PENDING':
      return (
        <Status
          icon={<LoopRoundedIcon fontSize="large" color="primary" />}
          dataTestId={dataTestId}
        >
          Pending
        </Status>
      );
    default:
      return <div data-test-id={dataTestId}>No Info</div>;
  }
};

export { StatusCell };
