import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Button } from '@mui/material';

import classes from './ScoreCell.module.scss';
import { getScoreStatus } from './settings/getScoreStatus';
import { complianceScoreModalStore } from '../../../stores/complianceScoreModalStore';
import { getRoundedNumberOrDash } from '../../../../shared/utils/mathUtils';

const ScoreCell = ({ score, mealDayId, meal, isInteractive, customerId }) => {
  const { openModal } = complianceScoreModalStore;
  const scoreStatus = getScoreStatus(score);
  const scoreClasses = classNames({ [classes[scoreStatus]]: isInteractive });
  const isDisabled = !score || !isInteractive;

  const handleModalOpening = () => {
    openModal(customerId, mealDayId, meal);
  };

  return (
    <div className={scoreClasses}>
      <Button
        variant="link"
        disableRipple
        onClick={handleModalOpening}
        disabled={isDisabled}
      >
        {getRoundedNumberOrDash(score)}
      </Button>
    </div>
  );
};

ScoreCell.propTypes = {
  score: PropTypes.number,
  mealDayId: PropTypes.number,
  meal: PropTypes.string,
  isInteractive: PropTypes.bool,
  customerId: PropTypes.number,
};

export { ScoreCell };
