import { makeAutoObservable } from 'mobx';
import { cloneDeep, set } from 'lodash';

import { resetAllStore } from '../../../shared/stores/resetAllStore';
import { Coach } from '../../interfaces/coachList/CoachListInfo';
import {
  CoachListFilterFormValues,
  CoachListFilters,
} from '../../interfaces/coachList/CoachListFilters';
import { FilterStore } from '../../../shared/util-stores';
import { coachService } from '../../services/coachService';
import { paginationSmallListLimit } from '../../../shared/constants/systemConstants';
import { personalInfoStore } from './personalInfoStore';

const defaultFilterData: CoachListFilterFormValues = {
  coachName: '',
  entityName: '',
  coachType: '',
  paymentFlowType: '',
  entityId: '',
  zipCode: '',
};

class CoachStore {
  isKnownCoach: boolean | null = null;
  selectedCoach: Coach | null = null;
  // find way to update default filter set entityid data
  coachList: Coach[] = [];
  filterMethods: FilterStore<CoachListFilterFormValues>;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    this.filterMethods = new FilterStore<CoachListFilterFormValues>(
      this.handleGetCoachList,
      {
        elementsPerPage: paginationSmallListLimit,
        defaultFilterData,
      },
    );
  }

  setDefaultValues() {
    this.isKnownCoach = null;
    this.selectedCoach = null;
    this.coachList = [];
    this.filterMethods.setDefaultValues();
  }

  private async handleGetCoachList(filterData: CoachListFilters) {
    const newFilterData = cloneDeep(filterData);
    set(newFilterData, 'filters.zipCode', personalInfoStore.personalInfo?.zipCode);

    const data = await coachService.getCoachList(newFilterData);

    this.setCoachList(data.content);

    return data;
  }

  async getCoachList() {
    return this.filterMethods.getData();
  }

  private setCoachList(coachList: Coach[]) {
    this.coachList = coachList;
  }

  setIsKnownCoach(isKnownCoach: boolean) {
    if (this.isKnownCoach !== isKnownCoach) {
      const coachType = isKnownCoach ? 'OFFLINE' : '';
      this.filterMethods.resetFilterData({ ...defaultFilterData, coachType });
      this.filterMethods.setDefaultValues();
      this.selectedCoach = null;
      this.setCoachList([]);
    }

    this.isKnownCoach = isKnownCoach;
  }

  setSelectedCoach(selectedCoach: Coach) {
    this.selectedCoach = selectedCoach;
  }
}

const coachStore = new CoachStore();
resetAllStore.addResetMethod(coachStore.setDefaultValues);

export { coachStore, CoachStore };
