(function () {
    'use strict';

    angular
        .module('app')
        .controller('SalesRepresentativesController', SalesRepresentativesController);

    SalesRepresentativesController.$inject = [
        '$routeParams',
        'SalesRepsService',
        'DateTimeUtil',
        'ModalService'
    ];

    function SalesRepresentativesController($routeParams, SalesRepsService, DateTimeUtil, ModalService) {
        const vm = this;

        vm.spinner = {active: false};
        vm.salesReps = [{}];
        vm.entitySalesRepList = [{editable: true}];
        vm.initialEntitySalesRepList = null;
        vm.minDate = DateTimeUtil.minusYears(vm.currentDate, 100);
        vm.maxDate = new Date();

        vm.querySearch = querySearch;
        vm.initSalesReps = initSalesReps;
        vm.getAllSalesReps = getAllSalesReps;
        vm.getEntitySalesRepList = getEntitySalesRepList;
        vm.setSalesRep = setSalesRep;
        vm.formatDate = formatDate;
        vm.addNewSalesRep = addNewSalesRep;
        vm.onDelete = onDelete;
        vm.deleteSalesRep = deleteSalesRep;
        vm.updateInitialRepsList = updateInitialRepsList;
        vm.cancelEditing = cancelEditing;

        function querySearch(query) {
            return query ? vm.salesReps.filter(function (rep) {
                return rep.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
            }) : vm.salesReps;
        }

        function initSalesReps() {
            vm.getAllSalesReps();
            vm.getEntitySalesRepList($routeParams.id);
        }

        function updateInitialRepsList(index) {
            if (index >= 0) {
                vm.initialEntitySalesRepList[index] = angular.copy(vm.entitySalesRepList[index]);
            } else {
                vm.initialEntitySalesRepList = angular.copy(vm.entitySalesRepList);
            }
        }

        function getAllSalesReps() {
            vm.spinner.active = true;
            SalesRepsService.getSalesReps().then(function (response) {
                vm.spinner.active = false;
                if (response.status === 200) {
                    if (response.data.salesReps.length) {
                        vm.salesReps = response.data.salesReps;
                    }
                }
            });
        }

        function getEntitySalesRepList() {
            vm.spinner.active = true;
            SalesRepsService.getEntitySalesRepList($routeParams.id).then(function (response) {
                vm.spinner.active = false;
                if (response.status === 200 && response.data.length) {
                    vm.entitySalesRepList = response.data;
                    vm.updateInitialRepsList();
                }
            });
        }

        function setSalesRep(index) {
            const rep = {
                salesRepId: vm.entitySalesRepList[index].salesRep.id,
                totalMonthFee: vm.entitySalesRepList[index].totalMonthFee,
                usersFee: vm.entitySalesRepList[index].usersFee,
                assignedAt: vm.formatDate(vm.entitySalesRepList[index].assignedAt, 'YYYY-MM-DD')
            };

            vm.spinner.active = true;
            SalesRepsService.setEntitySalesRep($routeParams.id, rep).then(function (response) {
                vm.spinner.active = false;
                if (response.status === 200) {
                    vm.entitySalesRepList[index] = Object.assign(response.data, {editable: false});
                    vm.initialEntitySalesRepList = angular.copy(vm.entitySalesRepList);
                }
            });
        }

        function formatDate(date, format) {
            return DateTimeUtil.convertDateFormat(date, moment.ISO_8601, format);
        }

        function addNewSalesRep() {
            vm.entitySalesRepList.push({editable: true});
        }

        function onDelete(index) {
            if (vm.initialEntitySalesRepList?.length && vm.initialEntitySalesRepList[index]?.salesRep?.id) {
                ModalService.confirm('Are you sure you want to delete this sales representative from this entity?', vm.deleteSalesRep, index, null, 'Cancel', 'Delete')
            } else {
                vm.entitySalesRepList.splice(index, 1);
            }
        }

        function deleteSalesRep(index) {
            const entityId = $routeParams.id;
            const salesRepId = vm.entitySalesRepList[index].salesRep.id;
            vm.spinner.active = true;
            SalesRepsService.deleteEntitySalesRep(entityId, salesRepId).then((resp) => {
                vm.spinner.active = false;
                if (resp.status === 200) {
                    if (vm.entitySalesRepList.length === 1) {
                        vm.entitySalesRepList[0] = {salesRep: null, editable: true};
                    } else {
                        vm.entitySalesRepList.splice(index, 1);
                    }
                    vm.initialEntitySalesRepList = angular.copy(vm.entitySalesRepList);
                }
            });
        }

        function cancelEditing(index) {
            if (vm.initialEntitySalesRepList[index]?.salesRep?.id) {
                vm.entitySalesRepList[index] = angular.copy(vm.initialEntitySalesRepList[index]);
            } else {
                vm.entitySalesRepList[index] = {editable: false};
            }
        }
    }
})();
