import { apiService } from '../../shared/services/apiService';
import { EntityInbodyData } from '../interfaces/entityInbodyData';

export const inbodyDataService = {
  async getEntityInbodyData(entityId: number) {
    const resp = await apiService.get<EntityInbodyData>(
      `/api/entity/${entityId}/inbody-data`,
    );

    return resp.data;
  },

  async setupEntityInbodyData(entityId: number, inbodyData: Partial<EntityInbodyData>) {
    const resp = await apiService.put<EntityInbodyData>(
      `/api/super/entity/${entityId}/inbody-data`,
      inbodyData,
    );

    return resp.data;
  },
};
