import { makeAutoObservable } from 'mobx';
import { FILE_EXTENSION } from '../../shared/constants/file/FILE_EXTENSIONS';

import { resetAllStore } from '../../shared/stores/resetAllStore';
import { salesReportService } from '../services/salesReportService';

class SalesReportStore {
  constructor() {
    this.setDefaultValues();
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.reports = [];
    this.isShowDownloadModal = false;
    this.selectedReport = {};
    this.fileExtension = null;
  }

  async getReports() {
    const { reports } = await salesReportService.getReports();

    this.setReports(reports);
  }

  setReports(reports) {
    this.reports = reports;
  }

  openDownloadModal(selectedReport, fileExtension) {
    this.setIsShowDownloadModal(true);
    this.setSelectedReport(selectedReport);
    this.setFileExtension(fileExtension);
  }

  setIsShowDownloadModal(isShowDownloadModal) {
    this.isShowDownloadModal = isShowDownloadModal;
  }

  setSelectedReport(selectedReport) {
    this.selectedReport = selectedReport;
  }

  setFileExtension(fileExtension) {
    this.fileExtension = fileExtension;
  }

  closeDownloadModal() {
    this.setIsShowDownloadModal(false);
    this.setSelectedReport({});
    this.setFileExtension(null);
  }

  downloadReport(params) {
    const { reportType } = this.selectedReport;

    if (this.fileExtension === FILE_EXTENSION.CSV) {
      salesReportService.downloadReportCSV(reportType, params);
    } else {
      salesReportService.downloadReportPDF(reportType, params);
    }

    this.closeDownloadModal();
  }
}

const salesReportStore = new SalesReportStore();

resetAllStore.addResetMethod(salesReportStore.setDefaultValues);

export { SalesReportStore, salesReportStore };
