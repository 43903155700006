import React from 'react';

import { observer } from 'mobx-react';

import classes from './SendingMessageModal.module.scss';
import { LbModal } from '../../../../shared/components/modals/LbModal/LbModal';
import { ReactComponent as SendMessageIcon } from '../../../../shared/assets/image/sendMessage.svg';
import { communicationModalStore } from '../../../stores/communicationModalStore';

const SendingMessageModal = observer(() => {
  const { isSendingMessage, customersCount } = communicationModalStore;

  return (
    <LbModal
      title={`${customersCount} messages being sent..`}
      text="Please wait until messages are sent"
      open={isSendingMessage}
    >
      <div className={classes.iconBox}>
        <SendMessageIcon />
      </div>
    </LbModal>
  );
});

export { SendingMessageModal };
