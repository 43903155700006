import { apiService } from '../../shared/services/apiService';

export const insuranceCertificatesService = {
  async getInsuranceCertificates(entityId) {
    const resp = await apiService.get(
      `/api/admin/entity/${entityId}/additional-insurances`,
    );

    return resp.data;
  },

  async uploadInsuranceCertificate(entityId, data) {
    const resp = await apiService.post(
      `/api/admin/entity/${entityId}/additional-insurances`,
      data,
    );

    return resp.data;
  },

  async deleteInsuranceCertificate(entityId, insuranceId) {
    const resp = await apiService.delete(
      `/api/admin/entity/${entityId}/additional-insurances/${insuranceId}`,
    );

    return resp.data;
  },
};
