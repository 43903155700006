import { Option } from '../../general-types';
import { ValueOfKey } from '../../utils-types';

export const BUSINESS_TYPE = {
  INDIVIDUAL: 'INDIVIDUAL',
  COMPANY: 'COMPANY',
} as const;

export type BusinessType = ValueOfKey<typeof BUSINESS_TYPE>;

export const businessTypeOptions: Option<BusinessType>[] = [
  { label: 'Individual', value: 'INDIVIDUAL' },
  { label: 'Company', value: 'COMPANY' },
];
