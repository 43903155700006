import { useLayoutEffect, useState } from 'react';
import { getService } from 'react-in-angularjs';

import { getIsActivePageByUrl } from '../utils/angularUtils';

type UseIsActivePageProps = {
  url?: string;
  urls?: string[];
};

// TODO update this hook to use one props string or Arrey<string>
/**
 * null - initial state used to prevent flickering
 */
export const useIsActivePage = ({ url, urls }: UseIsActivePageProps) => {
  const [isActive, setIsActive] = useState<Boolean | null>(null);
  const $rootScope = getService('$rootScope');

  const getIsCurrentPage = () => {
    let isCurrentPage = false;

    if (url) {
      isCurrentPage = getIsActivePageByUrl(url);
    } else if (urls) {
      isCurrentPage = urls.some(getIsActivePageByUrl);
    }

    setIsActive(isCurrentPage);
  };

  $rootScope.$on('$locationChangeSuccess', getIsCurrentPage);

  useLayoutEffect(() => {
    getIsCurrentPage();
  }, []);

  return isActive;
};
