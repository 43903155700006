import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
import { angularize } from 'react-in-angularjs';
import { enqueueSnackbar } from 'notistack';

import { SystemProviders } from '../../providers';
import { apiStatusStore } from '../../stores/apiStatusStore';
import { Spinner } from '../../components/Spinner/Spinner';

const NgzApiStatus = observer(() => {
  const { isLoadingGlobal, errorMessage, cleanErrorMessage } = apiStatusStore;

  useEffect(() => {
    if (errorMessage) {
      enqueueSnackbar(errorMessage, { variant: 'error', onClose: cleanErrorMessage });
    }
  }, [errorMessage]);

  return (
    <SystemProviders>
      <Spinner isLoading={isLoadingGlobal} isGlobal />
    </SystemProviders>
  );
});

angularize(NgzApiStatus, 'ngzApiStatus', angular.module('app'), {});

export { NgzApiStatus };
