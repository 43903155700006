import { useEffect, useState } from 'react';

import { enqueueSnackbar } from 'notistack';

import { entityLoginIdService } from '../../../../../../../services/entityLoginIdService';

type UseEntityLoginIdReturn = {
  loginId: string | null;
  updateLoginId: (loginId: string) => Promise<void>;
};

export const useEntityLoginId = (entityId: number): UseEntityLoginIdReturn => {
  const [loginId, setLoginId] = useState<string | null>(null);

  const getLoginId = async () => {
    const { loginId: entityLoginId } =
      await entityLoginIdService.getEntityLoginId(entityId);

    setLoginId(entityLoginId);
  };

  useEffect(() => {
    getLoginId();
  }, []);

  const updateLoginId = async (newLoginId: string) => {
    const { loginId: entityLoginId } = await entityLoginIdService.updateEntityLoginId(
      entityId,
      newLoginId,
    );

    enqueueSnackbar('Login ID has been successfully changed.', { variant: 'success' });
    setLoginId(entityLoginId);
  };

  return { loginId, updateLoginId };
};
