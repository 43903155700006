import React from 'react';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import BlockIcon from '@mui/icons-material/Block';

import { Status } from '../../../../../shared/components/ui-components';
import { EntityStatus } from '../../../../constants/entityStatus';

type StatusInfoProps = {
  status: EntityStatus;
};

const StatusInfo = ({ status }: StatusInfoProps) => {
  switch (status) {
    case 'ACTIVE':
      return (
        <Status icon={<CheckCircleRoundedIcon fontSize="small" color="success" />}>
          Active
        </Status>
      );
    case 'INACTIVE':
      return (
        <Status icon={<CancelRoundedIcon fontSize="small" color="error" />}>
          Inactive
        </Status>
      );
    case 'INCOMPLETE':
      return (
        <Status icon={<BlockIcon fontSize="small" color="error" />}>Incomplete</Status>
      );
    default:
      return <div>No Info</div>;
  }
};

export { StatusInfo };
