import React from 'react';

import { InputAdornment } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';

import classes from './MealRow.module.scss';
import { Nutrient } from '../../../../../../../shared/components/ui-components';
import {
  LbInput,
  ReadOnlyInput,
} from '../../../../../../../shared/components/form-components';
import {
  GridTableCell,
  GridTableRow,
} from '../../../../../../../shared/components/table-components';
import { NUTRIENTS_ENUM } from '../../../../../../../shared/constants/NUTRIENTS_ENUM';
import { calculateMealCalories } from '../../../../../../../shared/utils/mealUtils';
import { WorkoutMealsFormValues } from '../WorkoutMeals.settings';

type MealRowProps = {
  title: string;
  mealFromKey: 'preWorkout' | 'postWorkout'; // create a type for this
};

const nutrientsList = [
  NUTRIENTS_ENUM.PROTEINS,
  NUTRIENTS_ENUM.CARBS,
  NUTRIENTS_ENUM.FATS,
] as const;

const MealRow = ({ title, mealFromKey }: MealRowProps) => {
  const { control } = useFormContext<WorkoutMealsFormValues>();
  const nutrientsInputsSetting = nutrientsList.map(
    (nutrient) =>
      ({
        name: `workoutMeals.${mealFromKey}.${nutrient}`,
        nutrient,
      }) as const,
  );

  const [proteins, carbs, fats] = useWatch({
    control,
    name: nutrientsInputsSetting.map((nutrient) => nutrient.name),
  });

  const calories = calculateMealCalories({ proteins, carbs, fats });

  return (
    <GridTableRow variant="unstyled" className={classes.row}>
      <GridTableCell variant="title">{title}</GridTableCell>
      {nutrientsInputsSetting.map(({ name, nutrient }) => (
        <GridTableCell className={classes.cell} key={nutrient}>
          <LbInput
            control={control}
            name={name}
            size="small"
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Nutrient nutrient={nutrient} />
                </InputAdornment>
              ),
            }}
          />
        </GridTableCell>
      ))}
      <GridTableCell>
        <Nutrient nutrient="calories">
          <ReadOnlyInput
            control={control}
            name={`workoutMeals.${mealFromKey}.calories`}
            value={calories}
          />
        </Nutrient>
      </GridTableCell>
    </GridTableRow>
  );
};

export { MealRow };
