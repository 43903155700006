(function () {
    'use strict';

    angular.module('app.addFood')
        .controller('AddFoodController', AddFoodController);

    AddFoodController.$inject = [
        'utilService',
        '$routeParams',
        'FoodService',
        'ModalService',
        'constants',
        'localStorageService',
        'utilService'
    ];

    function AddFoodController(utilService, $routeParams, FoodService, ModalService,
                               constants, localStorageService) {
        var vm = this;

        vm.brands = [];
        vm.selectedTypeOfFood = $routeParams.type;
        vm.isBarcodeRequired = isBarcodeRequired;
        vm.measurements = ['IU\'s', 'mcg', 'mg'];
        vm.newFood = {
            name_only: '',
            brand: null,
            recommendedServingSize: 1,
            measurement_amt: 1,
            recommended: false,
            amountUnit: undefined,
            barcode: "",
            tags: [],
            microNutrients: [],
            proteins: 0,
            carbs: 0,
            fats: 0,
            sugar: 0,
            fiber: 0,
            veggies: 0,
            sodium: 0
        };
        vm.copyEditedFood = JSON.parse(JSON.stringify(vm.newFood));
        vm.savedFood = angular.copy(JSON.parse(localStorage.getItem('selected-food')));
        vm.calories = 0;
        vm.disableButtonSave = false;
        vm.micronutrients = [];
        vm.units = [];
        vm.allTags = [];
        vm.selectedMeasurement = null;
        vm.spinner = {active: false};
        vm.foodId = $routeParams.id;
        vm.isSupplement = vm.selectedTypeOfFood === constants.foodTypes.supplement;
        vm.isBrandListOpened = false;
        vm.nutrients = ['proteins', 'carbs', 'fats', 'sugar', 'fiber', 'veggies', 'sodium'];

        vm.init = init;
        vm.getAllBrands = getAllBrands;
        vm.getAllMeasurements = getAllMeasurements;
        vm.getAllMicroNutrients = getAllMicroNutrients;
        vm.getAllTags = getAllTags;
        vm.initSupplement = initSupplement;
        vm.setTagsForSupplement = setTagsForSupplement;
        vm.getSupplementTag = getSupplementTag;
        vm.setToggleForAC = setToggleForAC;
        vm.calculateCalories = calculateCalories;
        vm.changeAmount = changeAmount;
        vm.changeAmountForEditedFood = changeAmountForEditedFood;
        vm.changeFoodTags = changeFoodTags;
        vm.fieldOnBlur = fieldOnBlur;
        vm.fieldOnFocus = fieldOnFocus;
        vm.removeOneFood = removeOneFood;
        vm.removeNutrientByIndex = removeNutrientByIndex;
        vm.loadTags = loadTags;
        vm.addNutrientFromList = addNutrientFromList;
        vm.showAddMicroNutrientDialog = showAddMicroNutrientDialog;
        vm.saveFood = saveFood;
        vm.updateMicroNutrient = updateMicroNutrient;
        vm.updateTags = updateTags;
        vm.updateRecommended = updateRecommended;
        vm.getOneFood = getOneFood;
        vm.loadMircoNutrientsByUnit = loadMircoNutrientsByUnit;
        vm.isNonActiveSpinner = isNonActiveSpinner;
        vm.initMeasuremetsList = initMeasuremetsList;
        vm.findMeasurement = findMeasurement;
        vm.showDeleteFoodDialog = showDeleteFoodDialog;
        vm.setTotalPerContainer = setTotalPerContainer;
        vm.getFoodOrSupplementText = getFoodOrSupplementText;
        vm.openBrandList = openBrandList;
        vm.closeBrandList = closeBrandList;
        vm.selectBrand = selectBrand;
        vm.getIsNutritionIxNutrientsChange = getIsNutritionIxNutrientsChange;
        vm.getNutritionIxFoodId = getNutritionIxFoodId;
        vm.getIsMicroNutrientsAdded = getIsMicroNutrientsAdded;
        vm.getIsNutrientChange = getIsNutrientChange;
        vm.getRoundedValue = getRoundedValue;
        vm.getTagIds = getTagIds;
        vm.getFoodData = getFoodData;
        vm.setDefaultFieldsToFood = setDefaultFieldsToFood;

        vm.init();

        async function init() {
            vm.getAllMeasurements();
            vm.getAllMicroNutrients();
            await vm.getAllTags();
            vm.initSupplement();
        }

        function isBarcodeRequired() {
            return vm.selectedTypeOfFood === 'barcode';
        }

        function getAllBrands() {
            FoodService.getBrands().then(function (res) {
                if (res.status === 200) {
                    vm.brands = res.data;
                }
            });
        }

        function initSupplement() {
            const isNotEditFood = !vm.foodId;

            vm.setToggleForAC();

            if (vm.isSupplement && isNotEditFood) {
                vm.setTagsForSupplement();
            }
        }

        function setToggleForAC() {
            const enabledModules = localStorageService.get(constants.enums.storageKeys.ENABLED_MODULES);
            vm.newFood.activeCalendar = enabledModules.ACTIVE_CALENDAR;
        }

        function getAllMicroNutrients() {
            FoodService.getAllMicroNutrients().then(function (res) {
                if (res.status === 200) {
                    vm.micronutrients = res.data;
                }
            });
        }

        function getAllMeasurements() {
            FoodService.getAllMeasurements().then(function (res) {
                if (res.status === 200) {
                    vm.units = res.data.measurements;
                    vm.newFood.amountUnit = vm.units[0];
                }
            });
        }

        async function getAllTags() {
            await FoodService.getAllTags().then((res) => {
                if (res.status === 200) {
                    vm.allTags = res.data;

                    if (vm.foodId) {
                        vm.getOneFood(vm.foodId);
                    } else if (vm.savedFood) {
                        vm.newFood = angular.copy(vm.savedFood);
                        localStorage.removeItem('selected-food');
                    }
                }
            });
        }

        function setTagsForSupplement() {
            const supplementTag = vm.getSupplementTag(vm.allTags);
            const isSupplementTagNotSelected = !vm.getSupplementTag(vm.newFood.tags);

            if (supplementTag && isSupplementTagNotSelected) {
                vm.newFood.tags.push(supplementTag);           
            }
        }

        function getSupplementTag (tags) {
            return tags.find((tag) => {
                return tag.name.toLowerCase() === constants.foodTypes.supplement;
            });
        }

        function isNonActiveSpinner() {
            const isEditedFormLoaded = vm.foodId && vm.newFood.id;
            const isTemplateFormLoaded = vm.savedFood && vm.newFood.isTemplate || vm.newFood.my_net_diary_bean_id;
            const isEmptyFormLoaded = !vm.foodId && !vm.savedFood;

            return isEditedFormLoaded || isTemplateFormLoaded || isEmptyFormLoaded;
        }

        function loadMircoNutrientsByUnit() {
            vm.newFood.amountUnit = vm.selectedMeasurement.unit;
            vm.changeAmount(vm.newFood.recommendedServingSize, vm.selectedMeasurement);
        }

        function calculateCalories(food) {
            vm.calories = vm.getRoundedValue(4 * food.proteins + 4 * food.carbs + 9 * food.fats);
        }

        function changeAmount(amount, nutrientsList) {
            vm.nutrients.forEach((nutrient) => {
                vm.newFood[nutrient] = vm.getRoundedValue(nutrientsList[nutrient] * amount);
            });

            if (nutrientsList.microNutrients) nutrientsList.microNutrients.map(function (val, i) {
                vm.newFood.microNutrients[i].amount = vm.getRoundedValue(val.amount * amount);
            });

            vm.calculateCalories(vm.newFood);
        }

        function changeAmountForEditedFood(amount, form) {
            if (vm.newFood.id > 0 || vm.newFood.my_net_diary_bean_id) {
                vm.changeAmount(amount, vm.newFood.my_net_diary_bean_id ? vm.selectedMeasurement : vm.copyEditedFood);
                form.$setSubmitted();
            }
        }

        function changeFoodTags(food) {
            food.tags = food.tags.filter(function (item) {
                return item.id;
            });
        }

        function fieldOnBlur(value, name) {
            if (!value) {
                vm.newFood[name] = 0;
            }
        }

        function fieldOnFocus(value, name) {
            if (!value) {
                vm.newFood[name] = null
            }
        }

        function showDeleteFoodDialog(id) {
            const type = vm.getFoodOrSupplementText().toLowerCase();

            ModalService.confirm(
                `Are you sure you want to delete this ${type}?`,
                vm.removeOneFood, id, null, 'Cancel', 'Delete'
            );
        }

        function removeOneFood(id) {
            vm.spinner.active = true;
            FoodService.removeOneFood(id).then(function (res) {
                vm.spinner.active = false;
                if (res.status === 200) {
                    ModalService.savedChanges();
                    utilService.goTo('/foods/' + vm.selectedTypeOfFood);
                }
            });
        }

        function removeNutrientByIndex(index) {
            vm.newFood.microNutrients.splice(index, 1);
        }

        function loadTags(query) {
            var tags = vm.allTags;
            vm.newFood.tags.map(function (item) {
                switch (item.name) {
                    case 'Protein':
                    case 'Carbohydrate':
                    case 'Fat':
                    case 'Other':
                    case 'Condiments':
                        tags = tags.filter(function (item) {
                            return item.name !== 'Protein' && item.name !== 'Carbohydrate' && item.name !== 'Fat' && item.name !== 'Other' && item.name !== 'Condiments';
                        });
                        break;
                }
            });

            return tags;
        }

        function addNutrientFromList() {
            const isMicroNutrientsNotExist = !Array.isArray(vm.newFood.microNutrients);

            if (isMicroNutrientsNotExist) {
                vm.newFood.microNutrients = [];
            }

            vm.newNutrient.id = vm.selectedNutrient.id;
            vm.newNutrient.name = vm.selectedNutrient.name;
            vm.newFood.microNutrients.push(vm.newNutrient);
            vm.micronutrients = vm.micronutrients.filter(function (obj) {
                return obj.id !== vm.selectedNutrient.id;
            });
        }

        function showAddMicroNutrientDialog() {
            vm.selectedNutrient = vm.micronutrients[0];

            vm.newNutrient = {
                measurement: vm.measurements[0]
            };

            ModalService.addMicroNutrient(vm);
        }
        
        // TODO: update test
        function saveFood() {
            const tagIds =  vm.getTagIds();
            const foodData = vm.getFoodData();

            if (!tagIds.length && vm.newFood.recommended) {
                ModalService.alert('Cannot set recommended for food that doesn\'t have any tags.', 'Error');
            } else {
                vm.disableButtonSave = true;
                FoodService.addFood(foodData, { type: vm.selectedTypeOfFood }).then(function (res) {
                    if (res.status === 200) {
                        vm.updateMicroNutrient(res.data.id);
                        vm.updateTags(res.data.id, tagIds);
                    } else {
                        vm.disableButtonSave = false;
                    }
                });
            }
        }

        function getTagIds() {
            if (vm.newFood.tags?.length) {
                return vm.newFood.tags.map((item) => item.id);
            } else {
                return [];
            }
        }

        function getFoodData() {
            const my_net_diary_bean_id = vm.getNutritionIxFoodId();
          
            return {
                amountUnit: vm.newFood.amountUnit,
                carbs: vm.newFood.carbs,
                fats: vm.newFood.fats,
                fiber: vm.newFood.fiber,
                id: vm.newFood.id,
                barcode: vm.newFood.barcode,
                name: vm.newFood.name_only,
                brand: vm.newFood.brand,
                proteins: vm.newFood.proteins,
                recommendedServingSize: vm.newFood.recommendedServingSize,
                sodium: vm.newFood.sodium,
                sugar: vm.newFood.sugar,
                veggies: vm.newFood.veggies,
                activeCalendar: vm.newFood.activeCalendar,
                servingSize: vm.newFood.servingSize,
                servingPerContainer: vm.newFood.servingPerContainer,
                totalPerContainer: vm.newFood.totalPerContainer,
                my_net_diary_bean_id,
            };
          }

        function getNutritionIxFoodId() {
            if (vm.newFood.my_net_diary_bean_id && !vm.getIsNutritionIxNutrientsChange()) {
                return vm.newFood.my_net_diary_bean_id;
            } else {
                return null
            }
        }

        function getIsNutritionIxNutrientsChange() {
            const isNutrientChange = vm.nutrients.some(vm.getIsNutrientChange);
            const isMicroNutrientsAdded = vm.getIsMicroNutrientsAdded();
           
            return isNutrientChange || isMicroNutrientsAdded;
        }

        function getIsMicroNutrientsAdded() {
            return !!vm.newFood.microNutrients?.length;
        }

        function getIsNutrientChange(nutrient) {
            const amount = vm.newFood.recommendedServingSize;
            const originalNutrientValue = vm.getRoundedValue(vm.selectedMeasurement[nutrient]);
            const foodNutrientValue = vm.getRoundedValue(vm.newFood[nutrient] / amount);

            return originalNutrientValue !== foodNutrientValue
        }

        function getRoundedValue(value) {
            return parseFloat(value.toFixed(3));
        }

        function updateMicroNutrient(id) {
            const isMicroNutrientsAdded = vm.getIsMicroNutrientsAdded();

            if (isMicroNutrientsAdded) {
                FoodService.updateMicroNutrient(id, {nutrients: vm.newFood.microNutrients});
            }
        }

        function updateTags(id, tagIds) {
            FoodService.updateTags(id, {'tags[]': tagIds}).then(function (res) {
                if (res.status === 200) {
                    vm.updateRecommended(id);
                } else {
                    vm.disableButtonSave = false;
                }
            })
        }

        function updateRecommended(id) {
            FoodService.updateRecommended(id, {recommended: vm.newFood.recommended}).then(function (res) {
                vm.disableButtonSave = false;
                if (res.status === 200) {
                    ModalService.savedChanges();
                    utilService.goTo('/foods/' + vm.selectedTypeOfFood);
                }
            })
        }

        function findMeasurement() {
            return vm.units.find(function (unit) {
                return unit === vm.newFood.amountUnit;
            });
        }

        function initMeasuremetsList() {
            if (vm.units && !vm.findMeasurement()) {
                vm.units.unshift(vm.newFood.amountUnit);
            }
        }

        function getOneFood(id) {
            FoodService.getOneFood(id).then(function (res) {
                if (res.status === 200) {
                    vm.newFood = vm.setDefaultFieldsToFood(res.data);
                    
                    vm.copyEditedFood = JSON.parse(JSON.stringify(vm.newFood));
                    vm.changeAmount(vm.newFood.recommendedServingSize, vm.copyEditedFood);
                }
            });
        }

        function setDefaultFieldsToFood(food) {
            if (!food.tags) {
                food.tags = [];
            }

            return food
        }

        function setTotalPerContainer() {
            const servingSize = vm.newFood.servingSize;
            const servingPerContainer = vm.newFood.servingPerContainer;

            if (servingSize && servingPerContainer) {
                vm.newFood.totalPerContainer = servingSize * servingPerContainer;
            } else {
                vm.newFood.totalPerContainer = null;
            }
        }

        function getFoodOrSupplementText() {
            if (vm.isSupplement) {
                return 'Supplement';
            } else {
                return 'Food';
            }
        }

        function openBrandList() {
            vm.isBrandListOpened = true;
        }

        function closeBrandList() {
            vm.isBrandListOpened = false;
        }

        function selectBrand(brand) {
            vm.newFood.brand = brand;
            vm.closeBrandList();
        }
    }
})();
