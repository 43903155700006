import React, { ReactNode } from 'react';

import { Divider } from '@mui/material';

import classes from './ModalInputBox.module.scss';
import { TitleWithDescription } from '../../../text-formation-components';

type ModalInputBoxProps = {
  children: ReactNode;
  title?: ReactNode;
  description?: ReactNode;
  adornment?: ReactNode;
  showDivider?: boolean;
};

const ModalInputBox = ({
  children,
  title,
  description,
  adornment,
  showDivider = true,
}: ModalInputBoxProps) => {
  return (
    <div className={classes.box}>
      {!!title && (
        <div className={classes.titleBlock}>
          <TitleWithDescription
            title={title}
            description={description}
            titleFont="body-14-bold"
            fullWidth
          />
          {!!adornment && <div>{adornment}</div>}
        </div>
      )}
      {children}
      {showDivider && <Divider />}
    </div>
  );
};

export { ModalInputBox };
