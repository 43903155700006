import { getFormattedDate } from '../../../../shared/utils/dateUtils';
import { percentageToDecimal } from '../../../../shared/utils/mathUtils';
import { goalDateFormat } from '../../../constants/goalDateFormat';
import { CreateGoalInfo } from '../../../interfaces/goal';

// transform to format expected by backend to create goal
export const getProcessedCreateGoalInfo = (goalInfo: CreateGoalInfo): CreateGoalInfo => {
  const { targetDate, startDate, targetBodyFatPercent, ...info } = goalInfo;

  return {
    ...info,
    startDate: getFormattedDate(startDate, goalDateFormat),
    targetDate: getFormattedDate(targetDate, goalDateFormat),
    targetBodyFatPercent: percentageToDecimal(targetBodyFatPercent), // % in format 0-1
  };
};
