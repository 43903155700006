import React, { useState } from 'react';

import { Typography } from '@mui/material';

import { PaperWithTitle } from '../../components/PaperWithTitle/PaperWithTitle';
import classes from './Terms.module.scss';
import { ButtonsRow } from '../../components/ButtonsRow/ButtonsRow';
import { TermsCheckbox } from '../../components/ButtonsRow/TermsCheckbox/TermsCheckbox';
import { Agreement } from './Agreement/Agreement';
import { PrivacyPolicy } from './PrivacyPolicy/PrivacyPolicy';

const Terms = () => {
  const [checked, setChecked] = useState(false);

  const paperContent = (
    <>
      <Typography className={classes.title}>Terms and conditions</Typography>
      <Typography className={classes.subTitle}>
        Please read our terms of use carefully
      </Typography>
      <Agreement />
      <PrivacyPolicy />
    </>
  );

  const checkBox = <TermsCheckbox check={checked} setCheck={setChecked} />;

  return (
    <>
      <PaperWithTitle
        title="Read and agree with Terms and Conditions"
        content={paperContent}
      />
      <ButtonsRow yesDisabled={!checked}>{checkBox}</ButtonsRow>
    </>
  );
};

export { Terms };
