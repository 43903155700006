'use strict';

angular.module('app.addSalesRep').controller('AddSalesRepController', AddSalesRepController);

AddSalesRepController.$inject = ['ModalService', 'SalesRepsService', 'constants'];

function AddSalesRepController(ModalService, SalesRepsService, constants) {
    var vm = this;
    vm.salesReps = [{}];
    vm.initialSalesReps = null;
    vm.spinner = {active: false};
    vm.patterns = constants.enums.patterns;

    vm.getSalesReps = getSalesReps;
    vm.addItem = addItem;
    vm.addSalesReps = addSalesReps;
    vm.updateSalesReps = updateSalesReps;
    vm.editSalesReps = editSalesReps;
    vm.deleteSalesReps = deleteSalesReps;
    vm.displayDeleteSalesRepsDialog = displayDeleteSalesRepsDialog;
    vm.cancelEditing = cancelEditing;
    vm.updateInitialReps = updateInitialReps;

    function getSalesReps() {
        vm.spinner.active = true;
        SalesRepsService.getSalesReps().then(function (response) {
            vm.spinner.active = false;
            if (response.status === 200) {
                if (response.data.salesReps.length) {
                    vm.salesReps = response.data.salesReps;
                    vm.updateInitialReps();
                }
            }
        });
    }

    function updateInitialReps(index) {
        if (index || index === 0) {
            vm.initialSalesReps[index] = angular.copy(vm.salesReps[index]);
        } else {
            vm.initialSalesReps = angular.copy(vm.salesReps);
        }
    }

    function addSalesReps(index) {
        vm.spinner.active = true;
        SalesRepsService.addSalesReps(vm.salesReps[index]).then(function (response) {
            vm.spinner.active = false;
            if (response.status === 200) {
                vm.salesReps[index] = response.data;
                vm.updateInitialReps(index);
            }
        });
    }

    function updateSalesReps(index) {
        vm.spinner.active = true;
        SalesRepsService.updateSalesReps(vm.salesReps[index]).then(function (response) {
            vm.spinner.active = false;
            if (response.status === 200) {
                vm.salesReps[index] = response.data;
                vm.updateInitialReps(index);
            }
        });
    }

    function deleteSalesReps(index) {
        if (vm.initialSalesReps[index]) {
            vm.spinner.active = true;
            SalesRepsService.deleteSalesReps(vm.salesReps[index].id).then(function (response) {
                vm.spinner.active = false;
                if (response.status === 200) {
                    vm.salesReps.splice(index, 1);
                    vm.initialSalesReps.splice(index, 1);
                }
            });
        } else {
            vm.salesReps.splice(index, 1);
        }
    }

    function displayDeleteSalesRepsDialog(index) {
        ModalService.confirm('Are you sure you want to delete a sales representative?', vm.deleteSalesReps, index, null, 'Cancel', 'Delete')
    }

    function editSalesReps(index) {
        vm.salesReps[index].edit = true;
    }

    function cancelEditing(index) {
        vm.salesReps[index] = angular.copy(vm.initialSalesReps[index]);
    }

    function addItem() {
        vm.salesReps.push({});
    }
}