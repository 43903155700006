import { paginationListLimit } from '../../shared/constants/systemConstants';
import { apiService } from '../../shared/services/apiService';
import { ConsumptionBetweenType } from '../constants/consumption';
import { ConsumptionInfo } from '../interfaces/consumption/ConsumptionInfo';
import { ConsumptionParams } from '../interfaces/consumption/ConsumptionParams';
// TODO fix any
export const complianceService = {
  async getComplianceBoard(date: string, params: any) {
    const defaultParams = { limit: paginationListLimit };

    const resp = await apiService.get(`/api/coach/compliance-board/${date}`, {
      params: { ...defaultParams, ...params },
    });

    return resp.data;
  },

  async getCustomerComplianceHistory(customerId: number, params: any) {
    const resp = await apiService.get(`/api/coach/compliance-user/${customerId}`, {
      params,
    });

    return resp.data;
  },

  async getCustomerMealComplianceDetails(
    customerId: number,
    mealDayId: number,
    mealType: any,
  ) {
    const resp = await apiService.get(
      `/api/user/${customerId}/compliance-details/meal-day/${mealDayId}/meal/${mealType}`,
    );

    return resp.data;
  },

  async getCustomerDailyComplianceDetails(customerId: number, mealDayId: number) {
    const resp = await apiService.get(
      `/api/user/${customerId}/compliance-details/meal-day/${mealDayId}`,
    );

    return resp.data;
  },

  async getCustomerConsumptionInfo(
    customerId: number,
    betweenType: ConsumptionBetweenType,
    consumptionParams: ConsumptionParams,
  ) {
    const params = {
      betweenType,
      ...consumptionParams,
    };

    const resp = await apiService.get<ConsumptionInfo>(
      `/api/coach/user/${customerId}/stats/total-consumption`,
      { params },
    );

    return resp.data;
  },
};
