/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';

import { ReportSetting } from '../../../../../interfaces/ReportSetting';
import {
  ReportCheckbox,
  ReportDatePicker,
  ReportDateRange,
  ReportSelector,
} from '../report-settings';
import {
  ModalInputBox,
  ModalInputGroup,
} from '../../../../../../shared/components/modals';

type RenderReportSettingProps = {
  settings: ReportSetting[];
  parentSettings?: ReportSetting;
};

export const renderReportSetting = ({
  settings,
  parentSettings,
}: RenderReportSettingProps) => {
  if (!settings?.length) {
    return null;
  }

  return settings.map((currentSettings, index) => {
    const {
      selectorFunctionalType,
      selectorLabel,
      description,
      parameterKey,
      subSettings,
    } = currentSettings;

    const renderReportSettingProps: RenderReportSettingProps = {
      settings: subSettings,
      parentSettings: currentSettings,
    };

    switch (selectorFunctionalType) {
      case 'COMPOSITE_SELECTOR':
        return (
          <ModalInputBox key={index} title={selectorLabel} description={description}>
            {renderReportSetting(renderReportSettingProps)}
          </ModalInputBox>
        );
      case 'CHECKBOX':
        return (
          <Fragment key={index}>
            <ReportCheckbox label={selectorLabel} name={parameterKey!} />
            {renderReportSetting(renderReportSettingProps)}
          </Fragment>
        );
      case 'DATE_RANGE':
        return (
          <ReportDateRange
            key={index}
            label={selectorLabel}
            pickerSettings={subSettings}
          />
        );
      case 'SELECTOR':
        return (
          <ModalInputGroup key={index}>
            <ReportSelector
              selectorSettings={currentSettings}
              parentSelectorSettings={parentSettings}
            />
            {renderReportSetting(renderReportSettingProps)}
          </ModalInputGroup>
        );
      case 'DATE_PICKER':
        return (
          <ModalInputGroup key={index}>
            <ReportDatePicker setting={currentSettings} />
            {renderReportSetting(renderReportSettingProps)}
          </ModalInputGroup>
        );
      case 'NO_SELECTOR':
        return null;
      default:
        return null;
    }
  });
};
