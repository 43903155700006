import React from 'react';

import { useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react';

import classes from './MeasurementUnitRow.module.scss';
import { TitleWithDescription } from '../../../../../shared/components/text-formation-components';
import { LbSelect } from '../../../../../shared/components/form-components';
import {
  INBODY_MEASURE_UNIT,
  inbodyMeasureUnitOptions,
} from '../../../../../shared/constants/inbody-info/inbodyMeasureUnit';
import { loggedInUserDataStore } from '../../../../../shared/stores/loggedInUserDataStore';
import { DisabledSelectInfo } from '../../component/DisabledSelectInfo/DisabledSelectInfo';
import { EntityInbodyDataFormValue } from '../../EntityInbodyDataConfig.settings';

const MeasurementUnitRow = observer(() => {
  const { control } = useFormContext<EntityInbodyDataFormValue>();

  const { isSuperAdmin } = loggedInUserDataStore;

  if (!isSuperAdmin) {
    return (
      <DisabledSelectInfo
        name="nonInbodyData.measureUnit"
        label="Measurement type:"
        options={inbodyMeasureUnitOptions}
      />
    );
  }

  return (
    <div className={classes.measurementUnitRow}>
      <TitleWithDescription
        title="Select measurement type"
        description="The data obtained from the InBody scan will be measured in this manner."
        titleFont="body-14-bold"
      />
      <LbSelect
        name="nonInbodyData.measureUnit"
        control={control}
        options={inbodyMeasureUnitOptions}
        defaultValue={INBODY_MEASURE_UNIT.INCHES}
        variant="outlined"
        size="small"
        className={classes.select}
      />
    </div>
  );
});

export { MeasurementUnitRow };
