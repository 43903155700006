import { apiService } from '../../shared/services/apiService';
import { CustomerGoHighLevelAccountInfo } from '../interfaces/goHighLevelAccount';

export type CustomerGoHighLevelAccountInfoResponse =
  | CustomerGoHighLevelAccountInfo
  | undefined;

export const customerGoHighLevelAccountService = {
  async getConnectedAccountInfo(customerId: number) {
    const resp = await apiService.get<CustomerGoHighLevelAccountInfoResponse>(
      `/api/user/${customerId}/ghl/contact`,
    );

    return resp.data;
  },

  async refreshConnectionInfo(customerId: number) {
    const resp = await apiService.post<CustomerGoHighLevelAccountInfoResponse>(
      `/api/user/${customerId}/ghl/contact`,
    );

    return resp.data;
  },
};
