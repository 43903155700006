'use strict';

angular.module('app').factory('ScenariosService', ScenariosService);

ScenariosService.$inject = ['ApiService', 'ModalService', 'utilService'];

function ScenariosService(ApiService, ModalService, utilService) {
    return {
        getSuggestionForUser: function (id, date) {
            const params = { date };

            return ApiService.get('api/coach/scenario/' + id, params).then(function (res) {
                res.data.scanDetails.scores = res.data.scanDetails.scores.sort(function (a, b) {
                    var dateA = moment(new Date(a.date));
                    var dateB = moment(new Date(b.date));

                    if (dateA > dateB)
                        return -1;
                    if (dateA < dateB)
                        return 1;
                    return 0;
                });
                res.data.firstScanDate = utilService.formatDateFromResponse(res.data.prevScanDate);
                res.data.secondScanDate = utilService.formatDateFromResponse(res.data.lastScanDate);
                return res;
            }, function (err) {
                return err;
            });
        },
        import: function () {
            return ApiService.get('api/super/gettemptoken').then(function (res) {
                    return res;
                }, function (err) {
                    return err;
                }
            );
        },
        export: function (e) {
            return ApiService.post('api/super/scenarios/export', e.target.result).then(function (res) {
                    ModalService.alert('CSV file is uploaded.', 'Success');
                    return res;
                }, function (err) {
                    return err;
                }
            );
        }
    };
}

