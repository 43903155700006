import { SpringPagination } from '../../shared/interfaces/SpringPagination';
import { apiService, apiReportService } from '../../shared/services/apiService';
import { downloadBinaryFile } from '../../shared/utils/apiUtils';
import {
  ScholasticCommunicationFilters,
  CommunicationScholasticReportFilter,
} from '../interfaces/scholastic-list/ScholasticCommunicationFilters';
import { ScholasticCustomerInfo } from '../interfaces/scholastic-list/ScholasticCustomerInfo';

type GetCustomerListResponse = SpringPagination<ScholasticCustomerInfo[]>;

export const scholasticCommunicationService = {
  async getCustomerList(params: ScholasticCommunicationFilters) {
    const resp = await apiService.post<GetCustomerListResponse>(
      '/api/data-filters/communication/scholastic',
      params,
    );

    return resp.data;
  },

  async downloadCommunicationScholasticReports(
    reportFilter: CommunicationScholasticReportFilter,
  ) {
    const resp = await apiReportService.post(
      `/api/data-filters/communication/scholastic/reports`,
      reportFilter,
    );

    const filename = 'Users Activity Report';
    downloadBinaryFile(resp.data, 'text/csv', filename);
  },
};
