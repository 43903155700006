import printJS from 'print-js';
import { apiStatusStore } from '../../stores/apiStatusStore';

const { loadingStart, loadingEnd } = apiStatusStore;

export const printFile = (fileUrl) => {
  printJS({
    printable: fileUrl,
    onLoadingStart: () => {
      loadingStart(true);
    },
    onLoadingEnd: loadingEnd,
  });
};
