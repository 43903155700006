import type { ChartData, ChartDataset } from 'chart.js';

import { palette } from '../../../../../../../shared/theme/palette';
import {
  REGULAR_MEAL_NAME,
  RegularMealName,
} from '../../../../../../../shared/constants/mealNames';
import { getEnumArray } from '../../../../../../../shared/utils/jsUtils';
import { processMealName } from '../../../../../../../shared/utils/mealUtils';
import { PerformanceScore } from '../../../../../../interfaces/performance-metrics/PerformanceScore';

const mealsList: RegularMealName[] = getEnumArray(REGULAR_MEAL_NAME);

export const getChartData = (performanceScore: PerformanceScore): ChartData<'bar'> => {
  const maxValue = 5;

  const trackedMeals = mealsList.map(
    (meal) => performanceScore[meal].avgComplianceScoreTracked,
  );
  const allMeals = mealsList.map(
    (meal) => performanceScore[meal].avgComplianceScoreTotal,
  );
  const labels = mealsList.map((meal) => processMealName(meal));
  const backgroundBarData = mealsList.map(() => maxValue);

  const barSizeOptions = {
    barThickness: 22,
    maxBarThickness: 10,
  };

  const backgroundBarOptions: ChartDataset<'bar'> = {
    ...barSizeOptions,
    data: backgroundBarData,
    backgroundColor: palette.background['50-light'],
    borderSkipped: 'bottom',
    datalabels: { display: false },
    animation: false,
  };

  return {
    labels,
    datasets: [
      {
        ...barSizeOptions,
        backgroundColor: palette.mainBlack,
        data: allMeals,
        stack: 'allMeals',
      },
      {
        ...backgroundBarOptions,
        stack: 'allMeals',
      },
      {
        ...barSizeOptions,
        backgroundColor: palette.primary[500],
        data: trackedMeals,
        stack: 'trackedMeals',
      },
      {
        ...backgroundBarOptions,
        stack: 'trackedMeals',
      },
    ],
  };
};
