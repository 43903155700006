import React from 'react';
import PropTypes from 'prop-types';

import classes from './InbodyKeysModalForm.module.scss';
import { ComboBox, LbInput } from '../../../../../../shared/components/form-components';

const InbodyKeysModalForm = ({ control, entities }) => {
  return (
    <form className={classes.form}>
      <ComboBox
        name="entityId"
        label="Entity name"
        valueKey="id"
        labelKey="business_name"
        control={control}
        options={entities}
        disableClearable={false}
      />
      <LbInput
        control={control}
        name="inbodyKey"
        label="Inbody Key"
        rules={{ required: 'Please fill out this required field to proceed.' }}
      />
      <LbInput
        control={control}
        name="accountName"
        label="Webhooks ID"
        rules={{ required: 'Please fill out this required field to proceed.' }}
      />
      <LbInput name="description" label="Description" control={control} />
    </form>
  );
};

InbodyKeysModalForm.propTypes = {
  control: PropTypes.object.isRequired,
  entities: PropTypes.arrayOf(
    PropTypes.shape({
      business_name: PropTypes.string,
      id: PropTypes.number,
      accepted_stripe_terms: PropTypes.bool,
    }),
  ).isRequired,
};

export { InbodyKeysModalForm };
