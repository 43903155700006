import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';

import classes from './BtnCell.module.scss';

const BtnCell = ({ isRecord, openRecordOrderModal, onConfirmOrder, openEditModal }) => {
  const RecordBtn = (
    <Button variant="outlined" size="small" fullWidth onClick={openRecordOrderModal}>
      Add New Order
    </Button>
  );

  const EditConfirmBtn = (
    <>
      <Button variant="outlined" size="small" fullWidth onClick={openEditModal}>
        Edit
      </Button>
      <Button variant="contained" size="small" fullWidth onClick={onConfirmOrder}>
        Confirm
      </Button>
    </>
  );

  return <div className={classes.btnBox}>{isRecord ? EditConfirmBtn : RecordBtn}</div>;
};

BtnCell.propTypes = {
  isRecord: PropTypes.bool,
  openRecordOrderModal: PropTypes.func,
  onConfirmOrder: PropTypes.func,
  openEditModal: PropTypes.func,
};

export { BtnCell };
