import { apiService } from '../../shared/services/apiService';
import { InbodyKeyInfo, InbodyKeyInfoFormValue } from '../interfaces/inbodyKey';

const inbodyKeysBaseUrl = 'api/super/inbody-keys';

export type GetInbodyKeysResponse = { items: InbodyKeyInfo[] };
export type GetEntityInbodyKeysResponse = { inbodyKeys: InbodyKeyInfo[] };

export type DeleteInbodyKeyResponse = { status: string };

export const inbodyKeysService = {
  async getEntitiesBasics() {
    const resp = await apiService.get(`api/super/entities-basics`);

    return resp.data;
  },

  async getInbodyKeys() {
    const resp = await apiService.get<GetInbodyKeysResponse>(inbodyKeysBaseUrl);

    return resp.data;
  },

  async getEntityInbodyKeys(entityId: number) {
    const resp = await apiService.get<GetEntityInbodyKeysResponse>(
      `api/admin/entity/${entityId}/inbody-keys`,
    );

    return resp.data;
  },

  async addInbodyKey(data: InbodyKeyInfoFormValue) {
    const resp = await apiService.post<InbodyKeyInfo>(inbodyKeysBaseUrl, data);

    return resp.data;
  },

  async updateInbodyKey(id: number, data: InbodyKeyInfoFormValue) {
    const resp = await apiService.put<InbodyKeyInfo>(`${inbodyKeysBaseUrl}/${id}`, data);

    return resp.data;
  },

  async deleteInbodyKey(id: number) {
    const resp = await apiService.delete<DeleteInbodyKeyResponse>(
      `${inbodyKeysBaseUrl}/${id}`,
    );

    return resp.data;
  },
};
