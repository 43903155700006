// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../assets/images/stars.png");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<div class=\"entity-setup-step-4\"> <img class=\"stars-img\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"> <div class=\"header\"> <div class=\"header__title\">Almost done!</div> <p class=\"header__text\">It remains to create an <span>ID</span> and <span>Password</span> for quick login!</p> </div> <form name=\"loginIdForm\" class=\"form\" novalidate ng-submit=\"vm.onSubmit()\"> <div layout=\"column\"> <md-input-container class=\"form__email full-input\"> <label>Login ID</label> <input name=\"login\" ng-model=\"vm.entityCredentials.loginID\" required> <md-button class=\"reset\" ng-if=\"vm.entityCredentials.loginID\" ng-click=\"vm.onResetLogin()\">Reset</md-button> <div ng-messages=\"loginIdForm.login.$error\"> <p ng-message=\"required\">Login ID is required</p> <p ng-message=\"existingLoginID\">Such Login ID is already existing</p> </div> </md-input-container> <password-input class=\"form__password full-input\" label=\"Password\" form=\"loginIdForm\" name=\"password\" passwords-obj=\"vm.entityCredentials\" repeat-name=\"repeatedPassword\"></password-input> <password-input class=\"form__password full-input\" label=\"Repeat password\" form=\"loginIdForm\" name=\"repeatedPassword\" passwords-obj=\"vm.entityCredentials\" repeat-name=\"repeatedPassword\"></password-input> <md-button class=\"form__submit\" ng-disabled=\"loginIdForm.$invalid\" type=\"submit\">Finish</md-button> </div> </form> </div> ";
// Exports
var _module_exports = code;;
var path = 'app/directives/entity-setup-step-4/entity-setup-step-4.template.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;