import React from 'react';

import { Path, useFormContext } from 'react-hook-form';

import { GridTableCell } from '../../../../../../../../shared/components/table-components';
import { Nutrient } from '../../../../../../../../shared/components/ui-components';
import { NutrientType } from '../../../../../../../../shared/constants/NUTRIENTS_ENUM';
import { MacroPlanStepFromValue } from '../../../MacroPlanStep.settings';
import { ReadOnlyInput } from '../../../../../../../../shared/components/form-components';

type NutrientInfoGroupProps = {
  label: string;
  name: Path<MacroPlanStepFromValue>;
  nutrient: NutrientType;
};

const NutrientInfoGroup = ({ label, nutrient, name }: NutrientInfoGroupProps) => {
  const { control } = useFormContext<MacroPlanStepFromValue>();

  return (
    <>
      <GridTableCell variant="title">{label}</GridTableCell>
      <GridTableCell>
        <Nutrient nutrient={nutrient}>
          <ReadOnlyInput control={control} name={name} />
        </Nutrient>
      </GridTableCell>
    </>
  );
};

export { NutrientInfoGroup, type NutrientInfoGroupProps };
