const autoNgTemplateLoaderTemplate1 = require('./password-input.template.html');

import { validatePassword } from "../../../modules/shared/utils/reactHookFormUtils/validatePassword/validatePassword";


(function () {
  'use strict';

  PasswordInputController.$inject = [];

  function PasswordInputController() {
    const vm = this;
    vm.validationError = '';

    vm.$onInit = () => {
      vm.repeatName = vm.repeatName || 'repeatPassword';
    }

    vm.checkPassword = () => {
      const result = validatePassword(vm.passwordsObj[vm.name]);
      const isValid = result === true;

      if (vm.name === 'currentPassword') {
        vm.form.currentPassword.$setValidity('wrong', true);
      } else {
        vm.form.password.$setValidity('validation', isValid);
        vm.validationError = isValid ? '' : result;
        vm.form[vm.repeatName].$setValidity('match',
          vm.passwordsObj.password === vm.passwordsObj[vm.repeatName]);
      }
    }
  }

  const appModule = angular.module('app');
  appModule.component('passwordInput', {
    templateUrl: autoNgTemplateLoaderTemplate1,
    controller: PasswordInputController,
    controllerAs: 'vm',
    bindings: {
      label: '@',
      form: '<',
      name: '@',
      passwordsObj: '<',
      class: '@',
      repeatName: '@',
    },
  });
})();
