import React from 'react';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';

import classes from './MedicalTestsPlaceholder.module.scss';
import labTestImg from '../../../../../assets/image/labTestImg.png';
import emptyLabTestImg from '../../../../../assets/image/emptyLabTestImg.png';
import { medicalTestsStore } from '../../../../../stores/medicalTestsStore';

const MedicalTestsPlaceholder = observer(({ isShow }) => {
  const { isTestsDisabled } = medicalTestsStore;

  const imgSrc = isTestsDisabled ? emptyLabTestImg : labTestImg;

  const text = isTestsDisabled
    ? 'Entity hasn’t added lab tests yet'
    : 'Lab tests are already available! Select and add best test for your user';

  return (
    isShow && (
      <div className={classes.box}>
        <img src={imgSrc} alt="placeholder" />
        <p className={classes.text}>{text}</p>
      </div>
    )
  );
});

MedicalTestsPlaceholder.propTypes = {
  isShow: PropTypes.bool,
};

export { MedicalTestsPlaceholder };
