import React from 'react';

import { Button } from '@mui/material';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';

import classes from './ConnectionManagement.module.scss';
import { AccountStatusChip } from './AccountStatusChip/AccountStatusChip';
import { SeparatorLine } from '../../../../shared/components/ui-components';
import { OptionsMenu } from '../../../../shared/components/menu-components';
import { GoHighLevelAccountInfo } from '../../../interfaces/goHighLevelAccount';
import { GoHighLevelAccountStatusFlags } from '../../../constants/goHighLevelAccountStatus';

type ConnectionManagementProps = {
  accountInfo: GoHighLevelAccountInfo;
  accountStatusFlags: GoHighLevelAccountStatusFlags;
  onRefresh: () => void;
  onEdit: () => void;
  onRemove: () => void;
};

const ConnectionManagement = ({
  accountInfo,
  accountStatusFlags,
  onEdit,
  onRefresh,
  onRemove,
}: ConnectionManagementProps) => {
  return (
    <div className={classes.box}>
      <AccountStatusChip status={accountInfo.status} />
      {accountStatusFlags.NOT_AVAILABLE && (
        <Button
          variant="text"
          color="secondary"
          size="small"
          onClick={onRefresh}
          startIcon={<RefreshRoundedIcon />}
        >
          Refresh
        </Button>
      )}
      <SeparatorLine />
      <OptionsMenu
        variant="icon"
        options={[
          { id: 1, label: 'Edit', onClick: onEdit },
          { id: 2, label: 'Remove', onClick: onRemove },
        ]}
      />
    </div>
  );
};

export { ConnectionManagement };
