import { DownloadedFileExtension } from '../../../shared/constants/file/FILE_EXTENSIONS';
import { downloadBinaryFile, getMediaType } from '../../../shared/utils/apiUtils';
import { getFormattedDate } from '../../../shared/utils/dateUtils';

export const downloadReportFile = (
  reportFile: BlobPart,
  reportType: string,
  fileExtension: DownloadedFileExtension,
) => {
  const fileName = getReportFileName(reportType, fileExtension);
  const mediaType = getMediaType(fileExtension);

  downloadBinaryFile(reportFile, mediaType, fileName);
};

const getReportFileName = (
  reportType: string,
  fileExtension: DownloadedFileExtension,
) => {
  const todayDate = getFormattedDate();

  return `${reportType}-${todayDate}.${fileExtension}`;
};
