import { NUTRIENTS_ENUM } from '../../../constants/NUTRIENTS_ENUM';

export const processMeasurement = (nutrientName) => {
  const { VEGGIES, SODIUM, CALORIES, WATER } = NUTRIENTS_ENUM;

  switch (nutrientName) {
    case VEGGIES:
      return 'cups';
    case SODIUM:
      return 'mg';
    case CALORIES:
      return 'cal';
    case WATER:
      return 'oz';
    default:
      return 'g';
  }
};
