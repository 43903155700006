import React from 'react';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';
import { useFieldArray } from 'react-hook-form';
import { Button, Paper } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import classes from './SupplementForm.module.scss';
import { SupplementFormRow } from './SupplementFormRow/SupplementFormRow';
import { SupplementDayCheckbox } from './SupplementDayCheckbox/SupplementDayCheckbox';
import { supplementsStore } from '../../../stores/supplementsStore';
import { SupplementFormHeader } from './SupplementFormHeader/SupplementFormHeader';

const SupplementForm = observer(
  ({ supplementId, control, nameIndex, deleteHandler, copyHandler }) => {
    const { getSupplementById } = supplementsStore;

    const {
      name_only: supplementName,
      brand,
      amountUnit,
      servingSize,
    } = getSupplementById(supplementId);

    const formArrName = `${nameIndex}newSupplementScheduleInfoJsons`;
    const { fields, append, remove } = useFieldArray({
      control,
      name: formArrName,
    });

    const addNewTimeRow = () => {
      const newTime = { amount: servingSize, takeTime: '', withFood: '' };
      append(newTime);
    };

    const isShowDeleteBtn = fields.length > 1;

    return (
      <Paper variant="box" className={classes.formBox}>
        <SupplementFormHeader
          supplementName={supplementName}
          brand={brand}
          copyHandler={copyHandler}
          deleteHandler={deleteHandler}
        />
        <SupplementDayCheckbox control={control} nameIndex={nameIndex} />
        {fields.map((form, index) => (
          <SupplementFormRow
            control={control}
            nameIndex={`${formArrName}.${index}.`}
            key={form.id}
            deleteHandler={() => remove(index)}
            showDeleteBtn={isShowDeleteBtn}
            amountUnit={amountUnit}
          />
        ))}
        <div className={classes.addBtnBox}>
          <Button
            variant="outlined"
            size="small"
            startIcon={<AddRoundedIcon />}
            onClick={addNewTimeRow}
          >
            Add time
          </Button>
        </div>
      </Paper>
    );
  },
);

SupplementForm.propTypes = {
  supplementId: PropTypes.number,
  control: PropTypes.object.isRequired,
  nameIndex: PropTypes.string.isRequired,
  deleteHandler: PropTypes.func.isRequired,
  copyHandler: PropTypes.func.isRequired,
};

export { SupplementForm };
