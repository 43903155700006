import React, { MouseEvent } from 'react';
import { createRoot } from 'react-dom/client';
import {
  LbModal,
  LbModalProps,
  ButtonConfig,
} from '../../../components/modals/LbModal/LbModal';
import { SystemProviders } from '../../../providers';
import { getConfirmationModalRoot } from './utils/getConfirmationModalRoot';

type OpenConfirmationModalProps = {
  confirmBtnConfig: ButtonConfig;
  cancelBtnConfig?: ButtonConfig;
} & Omit<LbModalProps, 'open' | 'primaryBtnConfig' | 'secondaryBtnConfig'>;
type HandleCloseModal = () => void;

const openConfirmationModal = ({
  confirmBtnConfig,
  cancelBtnConfig,
  ...props
}: OpenConfirmationModalProps): HandleCloseModal => {
  const modalRoot = getConfirmationModalRoot();
  const root = createRoot(modalRoot);

  const handleCloseModal = () => {
    root.unmount();
  };

  const handleConfirm = (event: MouseEvent<HTMLButtonElement>) => {
    confirmBtnConfig?.onClick(event);
    handleCloseModal();
  };

  const handleCancel = (event: MouseEvent<HTMLButtonElement>) => {
    cancelBtnConfig?.onClick(event);
    handleCloseModal();
  };

  root.render(
    <SystemProviders>
      <LbModal
        open
        {...props}
        primaryBtnConfig={{ ...confirmBtnConfig, onClick: handleConfirm }}
        secondaryBtnConfig={{ ...cancelBtnConfig, onClick: handleCancel }}
      />
    </SystemProviders>,
  );

  return handleCloseModal;
};
export { openConfirmationModal, OpenConfirmationModalProps };
