'use strict';

angular.module('app')
    .controller('supplementSetupStep1Controller', supplementSetupStep1Controller);

supplementSetupStep1Controller.$inject = [
    '$scope',
    'utilService',
    'CoachService',
    'CustomersFilterService',
    'constants',
    'DateTimeUtil'
];

function supplementSetupStep1Controller($scope, utilService, CoachService, CustomersFilterService, constants, DateTimeUtil) {
    var vm = this;
    vm.loggedInUser = utilService.getLoggedInUserData();
    vm.spinner = {active: false};
    vm.genders = ['male', 'female'];
    vm.states = constants.states;
    vm.supplementUser = $scope.user;
    vm.initialSupplementUser = angular.copy(vm.supplementUser);
    vm.selectedDOB = DateTimeUtil.formatDate(vm.supplementUser.dob, 'MM.DD.YYYY') || null;
    vm.minDate = DateTimeUtil.minusYears(DateTimeUtil.now(), 100);
    vm.maxDate = DateTimeUtil.now();
    vm.dobIsOpen = false;
    vm.enums = constants.enums;

    vm.checkEmailUnique = checkEmailUnique;
    vm.setDOB = setDOB;
    vm.getActiveCoachesList = getActiveCoachesList;
    vm.handleSubmit = handleSubmit;
    vm.init = init;
    vm.init();

    function init() {
        if (vm.loggedInUser.role === 'ADMIN') {
            vm.getActiveCoachesList();
        }
    }

    function checkEmailUnique(form) {
        const isEmailChanged = vm.supplementUser.email !== vm.initialSupplementUser.email;

        if (vm.supplementUser.email && isEmailChanged) {
            CoachService.checkUserEmail(vm.supplementUser.email).then(function (res) {
                form["email"].$setValidity("duplicate", res.status === 404);
            });
        } else {
            form["email"].$setValidity("duplicate", true);
        }
    }

    function setDOB(form) {
        if (vm.supplementUser.dob) {
            if (vm.supplementUser.dob === 'Invalid date') {
                vm.selectedDOB = 'Invalid date';
            } else {
                vm.selectedDOB = moment(vm.supplementUser.dob).format('MM.DD.YYYY');
                DateTimeUtil.validateDOB(form, vm.supplementUser.dob);
            }
            form["dob"].$setValidity("invalidDate", vm.supplementUser.dob !== 'Invalid date');
        }
        $scope.$evalAsync();
    }

    function getActiveCoachesList() {
        CustomersFilterService.getCoachesForReassigning().then(function (res) {
            if (res.status === 200) {
                vm.coachesList = res.data.names.filter(function (coach) {
                    return coach.isComplianceCoach === false;
                });
            }
        });
    }

    function handleSubmit() {
        $scope.submit().then(() => {
            vm.initialSupplementUser = angular.copy(vm.supplementUser);
        });
    }

}
