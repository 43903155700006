import { useForm } from 'react-hook-form';
import { corporateAccountsStore } from '../../../../../../stores/corporateAccountsStore';

export const useCorporateAccountsModal = () => {
  const {
    formDefaultValues,
    currentModalAccountId,
    addNewAccount,
    editCorporateAccount,
    closeModal,
  } = corporateAccountsStore;
  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: { ...formDefaultValues },
    shouldUnregister: true,
  });

  const isEdit = !!formDefaultValues;
  const title = isEdit ? 'Edit Corporate Account' : 'Add Corporate Account';
  const isYesBtnDisabled = !isValid || !isDirty;

  const onSubmit = (data) => {
    if (isEdit) {
      editCorporateAccount(data, currentModalAccountId);
    } else {
      addNewAccount(data);
    }

    closeModal();
  };

  const handleFormSubmit = handleSubmit(onSubmit);

  return { control, isYesBtnDisabled, title, handleFormSubmit, closeModal };
};
