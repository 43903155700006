import { EntitySubscriptionPaymentDetailInfo } from '../../../interfaces/entitySubscription';

const defaultPriceInfo: EntitySubscriptionPaymentDetailInfo = {
  value: 0,
  description: 'Min plan price 0',
  label: 'Min plan price',
};

export const validateMinimalPrice = (
  value: number,
  priceInfo: EntitySubscriptionPaymentDetailInfo = defaultPriceInfo,
) => {
  return value >= priceInfo.value || priceInfo.description;
};
