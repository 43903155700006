import React from 'react';

import { Components } from '@mui/material';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export const MuiAlertTheme: Components['MuiAlert'] = {
  defaultProps: {
    iconMapping: {
      error: <ErrorRoundedIcon fontSize="medium" />,
      warning: <WarningRoundedIcon fontSize="medium" />,
      info: <InfoRoundedIcon fontSize="medium" />,
      success: <CheckCircleRoundedIcon fontSize="medium" />,
    },
    slots: {
      closeIcon: CloseRoundedIcon,
    },
    slotProps: {
      closeIcon: {
        fontSize: 'medium',
        color: 'inherit',
      },
    },
  },
  styleOverrides: {
    standard: {
      color: 'var(--main-black)',
      borderRadius: '8px',
      font: 'var(--title-16-medium)',
      padding: '12px 20px',
      alignItems: 'center',
      gap: '8px',
    },
    standardError: {
      background: 'var(--error-50)',
    },
    standardWarning: {
      background: 'var(--warning-50)',
    },
    standardInfo: {
      background: 'var(--info-50)',
    },
    standardSuccess: {
      background: 'var(--success-50)',
    },
    icon: {
      padding: '0',
      marginRight: '0',
    },
    message: {
      padding: '0',
    },
    action: {
      padding: '0',
      color: 'var(--natural-600)',
      marginRight: '0',

      '.MuiIconButton-root': {
        // fix hover effect
        padding: '5px',
        margin: '-5px',
      },
    },
  },
};
