import React from 'react';
import PropTypes from 'prop-types';

import { IconButton, Paper } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import classes from './InsuranceCertificate.module.scss';
import { TextTruncate } from '../../../../../../../shared/components/ui-components';
import { CertificatePreview } from './CertificatePreview/CertificatePreview';
import { openConfirmationModal } from '../../../../../../../shared/features/confirmation';

const InsuranceCertificate = ({ certificateInfo, onDelete }) => {
  const { id, fileName, fileExtension, createdAt, fileUrl, filePreviewUrl } =
    certificateInfo;

  const handleDelete = (e) => {
    e.stopPropagation();

    openConfirmationModal({
      title: `Do you want to delete ${fileName}?`,
      confirmBtnConfig: { onClick: () => onDelete(id) },
    });
  };

  return (
    <Paper
      data-test-id="insuranceCertificate"
      variant="roundedBox"
      className={classes.insuranceCertificate}
    >
      <CertificatePreview
        fileExtension={fileExtension}
        preview={filePreviewUrl}
        fileUrl={fileUrl}
      />
      <div className={classes.infoWrapper}>
        <TextTruncate
          text={`${fileName}.${fileExtension}`}
          className={classes.fileName}
        />
        <div className={classes.dateWrapper}>
          <h6>{createdAt}</h6>
          <IconButton
            data-test-id="deleteCertificateBtn"
            onClick={handleDelete}
            color="secondary"
          >
            <DeleteOutlineIcon fontSize="small" />
          </IconButton>
        </div>
      </div>
    </Paper>
  );
};

InsuranceCertificate.propTypes = {
  certificateInfo: PropTypes.shape({
    fileName: PropTypes.string,
    id: PropTypes.number,
    fileUrl: PropTypes.string,
    filePreviewUrl: PropTypes.string,
    fileExtension: PropTypes.string,
    createdAt: PropTypes.string,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export { InsuranceCertificate };
