import React from 'react';
import PropTypes from 'prop-types';

import { medicalTestsStore } from '../../../../../stores/medicalTestsStore';
import { MarkRow } from '../../../components/MarkRow/MarkRow';
import { TestRow } from '../../../components/TestRow/TestRow';

const SortedTests = ({ sortedTests }) => {
  const { editTest, deactivateTest } = medicalTestsStore;

  return sortedTests.map(({ firstLetter, tests }) => (
    <React.Fragment key={firstLetter}>
      <MarkRow text={firstLetter} />
      {tests.map((test) => (
        <TestRow
          nameOfTest={test.testName}
          date={test.updatedOn}
          key={test.id}
          editTest={(testName) => editTest(test, testName)}
          deactivateTest={() => deactivateTest(test.id)}
        />
      ))}
    </React.Fragment>
  ));
};

SortedTests.propTypes = {
  sortedTests: PropTypes.array,
};

export { SortedTests };
