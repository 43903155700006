import React from 'react';

import { SvgIconOwnProps } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

import classes from './GeneralScoreInfo.module.scss';

type GeneralScoreInfoProps = {
  title: string;
  value: number;
  color: SvgIconOwnProps['color'];
};

const GeneralScoreInfo = ({ title, value, color }: GeneralScoreInfoProps) => {
  return (
    <div>
      <h5 className={classes.title}>{title}</h5>
      <div className={classes.box}>
        <CircleIcon className={classes.icon} color={color} />
        <span className={classes.value}>{value}</span>
      </div>
    </div>
  );
};

export { GeneralScoreInfo };
