import React from 'react';

import { Button } from '@mui/material';
import { useToggle } from 'react-use';

import classes from './RegistrationFormAccessWidget.module.scss';
import { InfoBox } from '../../../../../../../shared/components/ui-components';
import { TitleWithDescription } from '../../../../../../../shared/components/text-formation-components';
import { UploadRegistrationLogoModal } from './UploadRegistrationLogoModal/UploadRegistrationLogoModal';

type RegistrationFormStyleWidgetProps = {
  entityId: number;
};

const RegistrationFormStyleWidget = ({ entityId }: RegistrationFormStyleWidgetProps) => {
  const [isQrCodeModalOpen, toggleQrCodeModalOpen] = useToggle(false);

  return (
    <>
      <InfoBox title="User Registration Form Style">
        <div className={classes.row}>
          <TitleWithDescription
            title="Company Logo"
            description="Feel free to incorporate your business logo alongside the LifeBase logo to showcase our partnership."
            titleFont="body-14-bold"
            className={classes.title}
          />
          <Button variant="outlined" size="small" onClick={toggleQrCodeModalOpen}>
            Upload logo
          </Button>
        </div>
      </InfoBox>
      {isQrCodeModalOpen && (
        <UploadRegistrationLogoModal
          entityId={entityId}
          onClose={toggleQrCodeModalOpen}
        />
      )}
    </>
  );
};

export { RegistrationFormStyleWidget };
