import React, { useEffect } from 'react';

import { angularize } from 'react-in-angularjs';

import classes from './NgzCustomerPaymentTab.module.scss';
import { SystemProviders } from '../../../../shared/providers';
import { CurrentPlanWidget } from './CurrentPlanWidget/CurrentPlanWidget';
import { PaymentDetailsWidget } from './PaymentDetailsWidget/PaymentDetailsWidget';
import { currentSubscriptionPlanStore } from '../../../stores/currentSubscriptionPlanStore';

type NgzCustomerPaymentTabProps = {
  customerId: number;
  entityId: number;
};

// TODO Refactor
const NgzCustomerPaymentTab = ({ customerId, entityId }: NgzCustomerPaymentTabProps) => {
  const { getPaymentDetails, setDefaultValues } = currentSubscriptionPlanStore;

  useEffect(() => {
    getPaymentDetails(customerId);

    return () => {
      setDefaultValues();
    };
  }, []);

  return (
    <SystemProviders>
      <div className={classes.row}>
        <CurrentPlanWidget customerId={customerId} />
        <PaymentDetailsWidget customerId={customerId} entityId={entityId} />
      </div>
    </SystemProviders>
  );
};

angularize(NgzCustomerPaymentTab, 'ngzCustomerPaymentTab', angular.module('app'), {
  customerId: '<',
  entityId: '<',
});

export { NgzCustomerPaymentTab };
