const autoNgTemplateLoaderTemplate1 = require('./customer-food-list-tab.template.html');

'use strict';

angular
    .module('app')
    .directive('customerFoodListTab', customerFoodListTab);

customerFoodListTab.$inject = [];

function customerFoodListTab() {
    return {
        restrict: 'E',
        scope: {
            userId: '='
        },
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: 'CustomerFoodListTabController',
        controllerAs: 'vm',
    };
}
