import React from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '@mui/material';
import { observer } from 'mobx-react';

import classes from './CreateScholasticCustomer.module.scss';
import { ScholasticProfileInputs } from '../../../../components/ScholasticProfileInputs/ScholasticProfileInputs';
import { YesNoBtnRow } from '../../../../../shared/components/buttons';
import { ScholasticUserDataFormValue } from '../../../../interfaces/ScholasticUserProfileFormValue';
import { useSchoolList } from './hooks/useSchoolList';
import { scholasticCustomerService } from '../../../../services/scholasticCustomerService';

type CreateScholasticCustomerProps = {
  entityId: number;
  token: string;
  goToNextStep: () => void;
};

const CreateScholasticCustomer = observer(
  ({ entityId, token, goToNextStep }: CreateScholasticCustomerProps) => {
    const { schoolList } = useSchoolList(entityId);
    const formMethods = useForm<ScholasticUserDataFormValue>();

    const { handleSubmit, reset } = formMethods;

    const onSubmit = async ({ userData }: ScholasticUserDataFormValue) => {
      await scholasticCustomerService.selfCustomerSetup({ userData, entityId, token });

      goToNextStep();
    };

    const handleClearForm = () => {
      reset();
    };

    return (
      <>
        <div className={classes.title}>
          <h3>Scholastic App Profile</h3>
          <p>To Complete final step in the course, please enter your information below</p>
        </div>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <ScholasticProfileInputs
              schoolList={schoolList}
              className={classes.inputsBlock}
            />
            <YesNoBtnRow yesText="Submit & Send">
              <Button variant="outlined" onClick={handleClearForm}>
                Clear Form
              </Button>
            </YesNoBtnRow>
          </form>
        </FormProvider>
      </>
    );
  },
);

export { CreateScholasticCustomer };
