import { useEffect } from 'react';

import { customFilterStore } from '../stores/customFilterStore';
import { ResourceType } from '../constants/resourceType';

export const useCustomFilter = (resourceType: ResourceType) => {
  const { getCustomFilters, setDefaultValues } = customFilterStore;

  useEffect(() => {
    getCustomFilters(resourceType);
    return () => setDefaultValues();
  }, []);
};
