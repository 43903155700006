import { useState } from 'react';
import { CustomerScanData } from '../interfaces/customerInbodyData';
import { scanServices } from '../services/scanServices';

export type UseCustomerScanReturn = {
  scanData: CustomerScanData | null | undefined;
  isScanUnavailable: boolean;
  getScanData: (inbodyId: number | string) => Promise<void>;
};

export const useCustomerScan = (): UseCustomerScanReturn => {
  const [scanData, setScanData] = useState<CustomerScanData | null | undefined>(
    undefined,
  );

  const getScanData = async (inbodyId: number | string) => {
    try {
      const { prefillReading } = await scanServices.getScanDateByInbodyId(inbodyId, [
        404,
      ]);

      setScanData(prefillReading);
    } catch (error) {
      setScanData(null);
    }
  };

  const isScanUnavailable = scanData === null;

  return { scanData, isScanUnavailable, getScanData };
};
