import { useEffect, useRef, useState } from 'react';
import { unleashClient } from '../unleashClient/unleashClient';

export const useFlag = (flagName) => {
  const [flag, setFlag] = useState(unleashClient.isEnabled(flagName));
  const flagRef = useRef();
  flagRef.current = flag;

  const handelSetFlag = () => {
    const enabled = unleashClient.isEnabled(flagName);

    if (enabled !== flagRef.current) {
      flagRef.current = enabled;
      setFlag(enabled);
    }
  };

  useEffect(() => {
    unleashClient.on('update', handelSetFlag);
    unleashClient.on('ready', handelSetFlag);

    return () => {
      unleashClient.off('update', handelSetFlag);
      unleashClient.off('ready', handelSetFlag);
    };
  }, [unleashClient]);

  return flag;
};
