import classes from './InventoryCell.module.scss';
import { inventoryStatus } from '../../../../constants/inventoryStatus';

export const getInventoryStatus = (inventory) => {
  let iconClass = '';
  let text = '';
  let isDisabled = false;

  switch (inventory) {
    case inventoryStatus.LOW_INVENTORY: {
      iconClass = classes.red;
      text = 'Low';
      break;
    }

    case inventoryStatus.SUFFICIENT_INVENTORY: {
      iconClass = classes.green;
      text = 'Sufficient';
      break;
    }

    default: {
      iconClass = classes.grey;
      text = 'No info';
      isDisabled = true;
    }
  }

  return { iconClass, text, isDisabled };
};
