import { getService } from 'react-in-angularjs';
import { apiService } from '../../shared/services/apiService';

const basePath = 'api/public/pre-check-in/';

const attachPaymentMethod = async (paymentMethodId) => {
  const { token } = getService('$routeParams');
  const params = { paymentMethod: paymentMethodId };

  const resp = await apiService.put(`${basePath}card-details/${token}`, null, { params });

  return resp.data;
};

const setChildRegisterRequestStatus = async (approved, isRunGlobalSpinner) => {
  const { token } = getService('$routeParams');
  const data = { approved };

  const resp = await apiService.post(basePath + token, data, { isRunGlobalSpinner });

  return resp.data;
};

const getChildInfo = async () => {
  const { token } = getService('$routeParams');

  const res = await apiService.get(`/api/public/pre-check-in/${token}`);
  return res.data;
};

const getSubscriptions = async (entityId) => {
  const params = { entityID: entityId };

  const resp = await apiService.get('api/coach/pre-check-in/subscriptions', { params });

  return resp.data;
};

export const preCheckInService = {
  attachPaymentMethod,
  setChildRegisterRequestStatus,
  getChildInfo,
  getSubscriptions,
};
