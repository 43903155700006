import { localDataFormat } from '../../../../../shared/constants/apiDateFormat';
import { pathQueryParams } from '../../../../../shared/constants/pathQueryParams';
import { CUSTOMER_PROFILE_TAB_NAMES } from '../../../../../shared/constants/tabNames/CUSTOMER_PROFILE_TAB_NAMES';
import { getFormattedDate } from '../../../../../shared/utils/dateUtils';

export const getScheduledDay = (labTestStartDate, appointmentStatus) => {
  const formattedLabTestDate = getFormattedDate(labTestStartDate, localDataFormat);
  const scheduledDay = appointmentStatus ? formattedLabTestDate : 'Non-Confirmed';

  return scheduledDay;
};

export const getLinkToCustomerProfile = (customerId) => {
  const { initialTabName } = pathQueryParams;
  const { LAB_TESTS } = CUSTOMER_PROFILE_TAB_NAMES;

  return `#!/customer-profile/${customerId}?${initialTabName}=${LAB_TESTS}`;
};
