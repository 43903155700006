import { HeaderNavSetting } from '../interfaces/system/NavLinkConfig';
import { apiService } from './apiService';

type GetUserNavLinksResponse = {
  headerSettings: HeaderNavSetting[];
};

type GetGoHighLevelAuthorizationLinkResponse = {
  redirectUrl: string;
};

export const systemService = {
  async getUserNavLinks() {
    const resp = await apiService.get<GetUserNavLinksResponse>(
      'api/page-config/header/settings',
    );

    return resp.data;
  },

  async getGoHighLevelAuthorizationLink() {
    const resp = await apiService.get<GetGoHighLevelAuthorizationLinkResponse>(
      'api/oauth/marketplace/authorization-link',
    );

    return resp.data;
  },
};
