import React from 'react';

import { useFormContext } from 'react-hook-form';
import { Button } from '@mui/material';

import { LbSelect } from '../../../../../../../components/form-components';
import { scoreOptions } from './Score.settings';
import { ComparisonInputs } from '../../ComparisonInputs/ComparisonInputs';
import { comparisonOptions } from '../../../../../constants/comparison';
import { CustomFilterFormValues } from '../../AddCustomFilterForm.settings';
import { getComparisonFilterValidateRule } from '../../utils/getComparisonFilterValidateRule';
import { useClearFilter } from '../../hooks/useClearFilter';
import { ModalInputBox, ModalInputGroup } from '../../../../../../../components/modals';

const Score = () => {
  const { control, register } = useFormContext<CustomFilterFormValues>();

  const validate = getComparisonFilterValidateRule('SCORE');

  const { disabled, handleClearFields } = useClearFilter('SCORE', {
    comparison: '',
    from: '',
    lastDays: '',
    score: '',
    to: '',
  });

  return (
    <ModalInputBox
      title="Score"
      adornment={
        <Button
          size="small"
          variant="text"
          color="secondary"
          onClick={handleClearFields}
          disabled={disabled}
        >
          Clear
        </Button>
      }
    >
      <input {...register('filters.SCORE.filterType', { value: 'SCORE' })} hidden />
      <ModalInputGroup>
        <ModalInputGroup>
          <LbSelect
            control={control}
            size="small"
            variant="outlined"
            name="filters.SCORE.score"
            label="Score"
            options={scoreOptions}
            rules={{ validate }}
          />
          <LbSelect
            control={control}
            size="small"
            variant="outlined"
            name="filters.SCORE.comparison"
            label="Condition"
            options={comparisonOptions}
            rules={{ validate }}
          />
        </ModalInputGroup>
        <ComparisonInputs filterType="SCORE" />
      </ModalInputGroup>
    </ModalInputBox>
  );
};

export { Score };
