import { InputAdornment, Paper } from '@mui/material';

import React from 'react';
import { useFormContext } from 'react-hook-form';

import classes from './WaterSetupBlock.module.scss';
import { TitleWithDescription } from '../../../../../../../shared/components/text-formation-components';
import { LbInput } from '../../../../../../../shared/components/form-components';
import { Nutrient } from '../../../../../../../shared/components/ui-components';
import { MacroPlanStepFromValue } from '../../MacroPlanStep.settings';

type WaterSetupBlockProps = {
  nameIndex: number;
};

const WaterSetupBlock = ({ nameIndex }: WaterSetupBlockProps) => {
  const { control } = useFormContext<MacroPlanStepFromValue>();

  return (
    <Paper variant="roundedBox" className={classes.box}>
      <TitleWithDescription
        title="Water Setup"
        description={
          <span>
            The standard water consumption is approx 0.5 oz per 1 lbs of body weight, but
            it can vary based on factors like exercise and propensity to sweat.
            <br />
            <br /> Check urine color to gauge hydration. Dark urine means more water is
            needed.
          </span>
        }
        titleFont="body-14-bold"
      />
      <LbInput
        control={control}
        name={`mealPlans.${nameIndex}.totalWater`}
        size="small"
        type="number"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Nutrient nutrient="water" />
            </InputAdornment>
          ),
        }}
      />
    </Paper>
  );
};

export { WaterSetupBlock };
