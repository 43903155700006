import { makeAutoObservable } from 'mobx';
import { TaxRateInfo } from '../interfaces/tax';
import { taxService } from '../services/taxService';
import { resetAllStore } from '../../shared/stores/resetAllStore';
import { Flags } from '../../utils-types';
import { TaxRateType, TAX_RATE_TYPE } from '../constants/tax';
import { getFlagsObject } from '../../shared/utils/sortingAndGroupingUtil';

class TaxStore {
  private entityId: number | null = null;
  appliedTaxRateInfo: TaxRateInfo | null | undefined = undefined; // null - no tax rate, undefined - initial state
  appliedTaxRateType: Flags<TaxRateType> = {};
  entityTaxRate: TaxRateInfo | null = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.entityId = null;
    this.appliedTaxRateInfo = undefined;
    this.appliedTaxRateType = {};
    this.entityTaxRate = null;
  }

  async getTaxInfo(entityId: number) {
    if (!this.entityId) {
      this.setEntityId(entityId);

      await Promise.all([this.getAppliedTaxRate(), this.getEntityTaxRate()]);
    } else if (this.entityId !== entityId) {
      throw new Error('Already used for another entity');
    }
  }

  async getAppliedTaxRate() {
    if (!this.entityId) {
      throw new Error('entityId is not set');
    }

    const taxRate = await taxService.getAppliedTaxRate(this.entityId);

    this.setAppliedTaxRateInfo(taxRate ?? null);
    this.setAppliedTaxRateType(taxRate?.taxRateType);
  }

  async getEntityTaxRate() {
    if (!this.entityId) {
      throw new Error('entityId is not set');
    }

    const taxRate = await taxService.getTaxRate(this.entityId);

    this.setEntityTaxRate(taxRate);
  }

  async setTaxRate(taxRate: number) {
    if (!this.entityId) {
      throw new Error('entityId is not set');
    }

    if (this.entityTaxRate?.id) {
      await this.updateTaxRate(this.entityId, this.entityTaxRate.id, taxRate);
    } else {
      await this.createTaxRate(this.entityId, taxRate);
    }

    await this.getAppliedTaxRate();
  }

  private async createTaxRate(entityId: number, taxRatePercent: number) {
    const taxRate = await taxService.createTaxRate(entityId, taxRatePercent);

    this.setEntityTaxRate(taxRate);
  }

  private async updateTaxRate(
    entityId: number,
    taxRateId: number,
    taxRatePercent: number,
  ) {
    const taxRate = await taxService.updateTaxRate(entityId, taxRateId, taxRatePercent);

    this.setEntityTaxRate(taxRate);
  }

  private setEntityId(entityId: number) {
    this.entityId = entityId;
  }

  private setAppliedTaxRateInfo(taxRate: TaxRateInfo | null) {
    this.appliedTaxRateInfo = taxRate;
  }

  private setAppliedTaxRateType(taxRateType: TaxRateType | undefined) {
    this.appliedTaxRateType = getFlagsObject(TAX_RATE_TYPE, [taxRateType]);
  }

  private setEntityTaxRate(taxRate: TaxRateInfo | null) {
    this.entityTaxRate = taxRate;
  }
}

const taxStore = new TaxStore();
resetAllStore.addResetMethod(taxStore.setDefaultValues);

export { taxStore, TaxStore };
