import React from 'react';

import classNames from 'classnames';
import { Skeleton } from '@mui/material';

import classes from './PaymentDetailsCell.module.scss';
import { PaymentDetailInfo } from '../../../../interfaces/paymentDetails';
import { PriceLabelRow } from '../PriceLabelRow/PriceLabelRow';

type PaymentDetailsCellProps = {
  showDivider?: boolean;
  paymentDetail?: PaymentDetailInfo;
  isLoading?: boolean;
};

const PaymentDetailsCell = ({
  paymentDetail,
  isLoading,
  showDivider,
}: PaymentDetailsCellProps) => {
  const cellClasses = classNames({ [classes.divider]: showDivider });

  const isShowSkeleton = isLoading || !paymentDetail;

  return (
    <div className={cellClasses}>
      {isShowSkeleton ? (
        <Skeleton width="100%" height="30px" />
      ) : (
        <>
          <PriceLabelRow label={paymentDetail.label} value={paymentDetail.value} />
          <div className={classes.descriptionRow}>{paymentDetail.description}</div>
        </>
      )}
    </div>
  );
};
export { PaymentDetailsCell };
