const autoNgTemplateLoaderTemplate1 = require('./supplement-setup.template.html');

'use strict';

(function () {
    angular
        .module('app.supplementSetup', [])
        .config(supplementSetupConfig);

    supplementSetupConfig.$inject = ['$routeProvider'];

    function supplementSetupConfig($routeProvider) {
        $routeProvider
            .when('/supplement-setup', {
                disableCache: true,
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'SupplementSetupController',
                controllerAs: 'vm',
                resolve: {
                    loginRequired: ['AuthService', function (AuthService) {
                        return AuthService.loginRequired();
                    }]
                }
            })
    }
})();
