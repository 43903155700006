import React from 'react';

import { Button, Link } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

import classes from './RedirectToWebApp.module.scss';
import { goToHomePage } from '../../../../../shared/utils/angularUtils';

const RedirectToWebApp = () => {
  return (
    <div className={classes.box}>
      <div className={classes.titleBox}>
        <CheckCircleRoundedIcon fontSize="extraLarge" className={classes.icon} />
        <h1>Success</h1>
      </div>
      <p>
        Your password has been successfully set.
        <br />
        Click below to log in LifeBase Solutions platform.
      </p>
      <Button
        onClick={goToHomePage}
        variant="contained"
        size="large"
        fullWidth
        className={classes.button}
      >
        Login Now
      </Button>
      <p>
        If you have any questions, please contact
        <br />
        <Link href="mailto:support@lifebasesolutions.com">
          support@lifebasesolutions.com
        </Link>
      </p>
    </div>
  );
};

export { RedirectToWebApp };
