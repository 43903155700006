import { dateFormat } from '../../../../shared/constants/apiDateFormat';
import {
  getDayBeforeDate,
  getFormattedDate,
  isDataBefore,
} from '../../../../shared/utils/dateUtils';

export const getConsumptionDates = (scanDates: string[]) => {
  if (scanDates.length < 2) {
    return {
      consumptionEndDate: undefined,
      consumptionStartDate: undefined,
    };
  }

  const lastScanDay = scanDates[0];
  const dayBeforeLastScan = getDayBeforeDate(lastScanDay, 1);

  const consumptionEndDate = getFormattedDate(dayBeforeLastScan, dateFormat);

  const consumptionStartDate = scanDates.find((date) => {
    return isDataBefore(date, consumptionEndDate);
  });

  return {
    consumptionEndDate,
    consumptionStartDate,
  };
};
