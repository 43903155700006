import { EntityType } from '../../shared/constants/user-info/ENTITY_TYPES';
import { PaymentFlowType } from '../../shared/constants/user-info/paymentFlowType';
import { apiService } from '../../shared/services/apiService';
import {
  FeeSchedule,
  CreatedPaymentBundleInfo,
  EntityPaymentBundleInfo,
  DefaultPaymentBundleInfo,
} from '../interfaces/paymentBundleInfo';

type GetDefaultPaymentBundleResponse<TPaymentFlowType extends PaymentFlowType> =
  TPaymentFlowType extends 'NEW_USER_FLOW_2024'
    ? { feeSchedule: FeeSchedule }
    : DefaultPaymentBundleInfo;

type GetEntityPaymentBundleResponse = EntityPaymentBundleInfo & Record<string, any>; // Exist extra fields in response

export const paymentBundleService = {
  async getDefaultPaymentBundle<TPaymentFlowType extends PaymentFlowType>(
    entityType: EntityType,
    paymentFlowType: TPaymentFlowType,
  ) {
    const params = { entityType, paymentFlowType };

    const resp = await apiService.get<GetDefaultPaymentBundleResponse<TPaymentFlowType>>(
      '/api/payment-bundle/default',
      { params },
    );

    return resp.data;
  },

  async getEntityPaymentBundle(
    entityId: number,
    config: { ignoreErrorCodes?: number[] } = {},
  ) {
    const resp = await apiService.get<GetEntityPaymentBundleResponse>(
      `/api/v2/entity/${entityId}/payment-bundle`,
      config,
    );

    return resp.data;
  },

  async createPaymentBundle(
    entityId: number,
    paymentBundleInfo: CreatedPaymentBundleInfo,
  ) {
    const resp = await apiService.post<undefined>(
      `/api/super/v2/entity/${entityId}/payment-bundle`,
      paymentBundleInfo,
    );

    return resp.data;
  },

  // TODO update when write entity profile, info in LMP-2393
  //   async editPaymentBundle(entityId: number, paymentBundleInfo: any) {
  //     const resp = await apiService.patch<undefined>(
  //       `/api/super/v2/entity/${entityId}/payment-bundle`,
  //       paymentBundleInfo,
  //     );

  //     return resp.data;
  //   },
};
