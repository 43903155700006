import React from 'react';

import { useAsyncFn, useToggle } from 'react-use';
import { useForm } from 'react-hook-form';

import classes from './AddPromoCode.module.scss';
import {
  LbBigModal,
  ModalInputBox,
  ModalInputGroup,
} from '../../../../../shared/components/modals';
import {
  LbDatePicker,
  LbInput,
  LbNumericFormatInput,
} from '../../../../../shared/components/form-components';
import { LbAlert } from '../../../../../shared/components/ui-components';
import { ExpirationDateSwitch } from './ExpirationDateSwitch/ExpirationDateSwitch';
import {
  AddPromoCodeRequest,
  PromoCodeSubscription,
} from '../../../../interfaces/promoCodeInfo';
import { validatePromoCode } from '../../../../utils/validatePromoCode/validatePromoCode';
import { SubscriptionsBox } from './SubscriptionsBox/SubscriptionsBox';

type AddPromoCodeModalProps = {
  subscriptions: PromoCodeSubscription[];
  onClose: () => void;
  addPromoCode: (promoCode: AddPromoCodeRequest) => Promise<void>;
};

const AddPromoCodeModal = ({
  subscriptions,
  addPromoCode,
  onClose,
}: AddPromoCodeModalProps) => {
  const [expirationDateFieldVisible, toggleExpirationDateFieldVisible] = useToggle(false);

  const { control, handleSubmit } = useForm<AddPromoCodeRequest>({
    shouldUnregister: true,
  });

  const [submitState, handleFormSubmit] = useAsyncFn(
    handleSubmit(async (data) => {
      await addPromoCode(data);
      onClose();
    }),
  );

  const generalInputProps = { control, variant: 'outlined', size: 'small' } as const;

  const formId = 'add-promo-code-form';

  return (
    <LbBigModal
      open
      isLoading={submitState.loading}
      title="Add a promo code"
      buttonOptions={{
        yesText: 'Save',
        yesHandler: handleFormSubmit,
        noText: 'Cancel',
        noHandler: onClose,
        formId,
      }}
    >
      <form id={formId} noValidate>
        <ModalInputBox title="General">
          <div className={classes.box}>
            <ModalInputGroup>
              <LbInput
                {...generalInputProps}
                label="Name"
                name="name"
                rules={{ required: 'Name is required' }}
              />
              <LbNumericFormatInput
                {...generalInputProps}
                label="Discount"
                name="discountPercent"
                helperText="Discount limit no more 50%"
                suffix="%"
                rules={{
                  required: 'Discount is required',
                  min: { value: 0, message: 'Discount should be more than 0' },
                  max: { value: 50, message: 'Discount should be less than 50' },
                }}
              />
            </ModalInputGroup>
            <ModalInputGroup>
              <LbInput
                {...generalInputProps}
                label="Code"
                name="code"
                rules={{
                  validate: { validatePromoCode },
                }}
              />
              <LbInput
                {...generalInputProps}
                label="Usage Limit"
                name="usageLimit"
                type="number"
                rules={{
                  required: 'Usage limit is required',
                  min: { value: 0, message: 'Usage limit should be more than 0' },
                }}
              />
            </ModalInputGroup>
          </div>
          <LbAlert severity="info" size="small">
            <p>The promo-code cannot be modified once it has been redeemed.</p>
            <p>
              Uppercase and limit from 4 to 25 characters with no spaces, use only letters
              and numbers
            </p>
          </LbAlert>
        </ModalInputBox>
        <ModalInputBox>
          <ExpirationDateSwitch
            checked={expirationDateFieldVisible}
            onChange={toggleExpirationDateFieldVisible}
          />
          {expirationDateFieldVisible && (
            <div className={classes.datePicker}>
              <LbDatePicker
                {...generalInputProps}
                label="End Date"
                name="endDate"
                disablePast
                rules={{
                  required: 'End date is required',
                }}
              />
            </div>
          )}
        </ModalInputBox>
        <SubscriptionsBox subscriptions={subscriptions} {...generalInputProps} />
      </form>
    </LbBigModal>
  );
};

export { AddPromoCodeModal };
