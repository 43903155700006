export const getIconColor = (daysToTest) => {
  const red = 'var(--error-200)';
  const yellow = 'var(--warning-200)';
  const grey = 'var(--natural-300)';

  if (!daysToTest && daysToTest !== 0) {
    return grey;
  }

  if (daysToTest < 14 && daysToTest >= 0) {
    return yellow;
  } else if (daysToTest < 0) {
    return red;
  }

  return grey;
};
