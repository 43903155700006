import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox, Typography } from '@mui/material';
import classes from './TermsCheckbox.module.scss';

const TermsCheckbox = ({ check, setCheck }) => {
  return (
    <div className={classes.checkContainer}>
      <Checkbox
        className={classes.checkBox}
        checked={check}
        onChange={(value) => setCheck(value.target.checked)}
      />
      <Typography className={classes.checkBoxText}>I agree with </Typography>
      <Typography className={classes.checkBoxLink}>Terms of Conditions</Typography>
    </div>
  );
};

TermsCheckbox.propTypes = {
  check: PropTypes.bool.isRequired,
  setCheck: PropTypes.func.isRequired,
};

export { TermsCheckbox };
