import React from 'react';

import classes from './SaasTaxDescription.module.scss';
import { TitleWithDescription } from '../../../../../shared/components/text-formation-components';
import { ActivationStatusChip } from '../../../../components/ActivationStatusChip/ActivationStatusChip';

type SaasTaxDescriptionProps = {
  showChip?: boolean;
  isSaasTaxApplied?: boolean;
};

const SaasTaxDescription = ({ showChip, isSaasTaxApplied }: SaasTaxDescriptionProps) => {
  return (
    <TitleWithDescription
      title={
        <div className={classes.titleBox}>
          <span>Sales tax (SaaS incl)</span>
          {showChip && <ActivationStatusChip active={isSaasTaxApplied} />}
        </div>
      }
      description={
        <span>
          As per the Terms of Use, LifeBase will set sales tax if entity does not do so
          (when applicable).
          <br />
          <br />
          Total = (Sales Tax % * LifeBase Fees/per payment) + Subscription Price
        </span>
      }
      titleFont="body-14-bold"
    />
  );
};

export { SaasTaxDescription, SaasTaxDescriptionProps };
