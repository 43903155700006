import { CoachShortInfo } from '../../interfaces/coach/CoachShortInfo';
import { apiService } from '../apiService';

type GetCoachesListResponse = { names: CoachShortInfo[] };

export const coachService = {
  async getCoachesList(isRunGlobalSpinner: boolean = true) {
    const resp = await apiService.get<GetCoachesListResponse>(
      `/api/admin/coaches-short-list`,
      { isRunGlobalSpinner },
    );

    return resp.data;
  },

  async reassignCoachForCustomers(coachId: number, userIds: number[]) {
    const data = { ids: userIds };

    const resp = await apiService.put(`/api/admin/assign/coach/${coachId}`, data);

    return resp.data;
  },
};
