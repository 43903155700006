import React from 'react';
import { createPortal } from 'react-dom';

import classes from './BlackBgRow.module.scss';

type BlackBgRowProps = {
  children: React.ReactNode;
  bgRowHeight: string;
};

/**
 * Component add black background to the top of the page,
 * can not use general css for this because of <md-content></md-content> add padding and row will not be full width
 */
const BlackBgRow = ({ children, bgRowHeight }: BlackBgRowProps) => {
  return (
    <>
      {createPortal(
        <div className={classes.blackBg} style={{ height: bgRowHeight }} />,
        document.body,
      )}
      <div className={classes.container}>{children}</div>
    </>
  );
};

export { BlackBgRow };
