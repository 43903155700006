'use strict';

angular.module('app').factory('CorporateAccountService', CorporateAccountService);

CorporateAccountService.$inject = ['ApiService'];

function CorporateAccountService(ApiService) {
    return {
        getCoachPinCode: function (coachId) {
            return ApiService.get('api/admin/pincode/' + coachId).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        saveCoachPinCode: function (coachId, data) {
            return ApiService.post('api/admin/pincode/' + coachId, data).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        updateCoachPinCode: function (coachId, data) {
            return ApiService.put('api/admin/pincode/' + coachId, data).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
    };
}
