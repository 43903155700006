(function () {
    'use strict';

    angular.module('app.foods')
        .controller('FoodsController', FoodsController);

    FoodsController.$inject = [
        '$rootScope',
        '$mdDialog',
        '$routeParams',
        'FoodService',
        'ModalService',
        'utilService',
        'constants',
        'PaidModulesUtil',
    ];

    function FoodsController($rootScope, $mdDialog, $routeParams, FoodService, ModalService, utilService, constants, PaidModulesUtil) {
        var vm = this;

        vm.filter = { name: '', brand: '', supplementType: 'all' };
        vm.loggedInUser = utilService.getLoggedInUserData();
        vm.pagination = {};
        vm.spinner = {active: false};
        vm.foodSearchField = '';
        vm.selectedTypeOfFood = $routeParams.type;
        vm.typesOfFood = ['core', 'supplement', 'veggies', 'barcode'];
        vm.foods = [];
        vm.searchMode = false;
        vm.page = 0;
        vm.offset = 50;
        vm.supplementTypes = [
            { name: 'All', value: 'all' },
            { name: 'For Active calendar', value: 'activeCalendar' },
            { name: 'Standard supplement', value: 'default' },
        ];
        vm.foodTypes = constants.foodTypes;
        vm.isSupplements = vm.selectedTypeOfFood === constants.foodTypes.supplement;
        vm.isActiveCalendarEnabled = false;

        vm.changeMode = changeMode;
        vm.goTo = utilService.goTo;
        vm.getAllFoods = getAllFoods;
        vm.searchFoods = searchFoods;
        vm.removeOneFood = removeOneFood;
        vm.updateFood = updateFood;
        vm.getFoodLink = getFoodLink;
        vm.saveSelectedFood = saveSelectedFood;
        vm.doSearch = doSearch;
        vm.getIsTemplateFood = getIsTemplateFood;
        vm.processFoodId = processFoodId;
        vm.onSwitchClick = onSwitchClick;
        vm.getFoodActionIcon = getFoodActionIcon;
        vm.setEnabledModules = setEnabledModules;

        vm.$onInit = () => {
            vm.getAllFoods(0);
            vm.setEnabledModules();
        }

        function saveSelectedFood(food) {
            const isTemplateFood = vm.getIsTemplateFood(food);

            if (vm.searchMode || isTemplateFood) {
                if (food.microNutrients === null) food.microNutrients = [];
                if (food.tags === null) food.tags = [];
                if (food.id) {
                    vm.processFoodId(food); 
                }

                localStorage.setItem('selected-food', JSON.stringify(food));
            }
        }

        function processFoodId (food) {
            food.id = 0;
            food.isTemplate = true;
        }

        function getFoodLink(food) {
            const isTemplateFood = vm.getIsTemplateFood(food);

            if (vm.searchMode || isTemplateFood) {
                return 'add-food/' + vm.selectedTypeOfFood;
            } else {
                return 'edit-food/' + vm.selectedTypeOfFood + '/' + food.id;
            }
        }

        function getIsTemplateFood(food) {
            return !food.addedByUser && vm.loggedInUser.role !== constants.enums.userRoles.SUPERUSER;
        }

        function changeMode(mode) {
            vm.foods = [];
            vm.searchMode = mode;
            vm.doSearch();
        }

        function doSearch() {
            if (!vm.searchMode) {
                vm.getAllFoods(0);
            } else {
                vm.searchFoods();
            }
        }

        function searchFoods() {
            vm.spinner.active = true;
            vm.foods = [];
            FoodService.searchFoods({
                q: vm.foodSearchField,
                src: vm.selectedTypeOfFood,
                my_food_diary: true
            }).then(function (res) {
                vm.spinner.active = false;
                if (res.status === 200) {
                    vm.foods = res.data.foods.filter(function (item) {
                        if (!item.addedByUser) {
                            return item;
                        }
                    });
                }
            });
        }

        function removeOneFood(id, index) {
            FoodService.removeOneFood(id).then(function (res) {
                if (res.status === 200) {
                    ModalService.savedChanges();
                    vm.foods.splice(index, 1);
                }
            });
        }

        function getAllFoods(skip) {
            vm.spinner.active = true;
            vm.page = skip / vm.offset;
            var query = (vm.filter.brand + ' ' + vm.filter.name).trim();
            (query.length ? FoodService.getAllFoods114Search(vm.selectedTypeOfFood, {page: vm.page, limit: vm.offset, q: query}) :
                FoodService.getAllFoods114(vm.selectedTypeOfFood, {page: vm.page, limit: vm.offset, supplementType: vm.filter.supplementType}))
                .then(function (res) {
                    vm.spinner.active = false;
                    if (res.status === 200) {
                        if (res.data.foods.length === 0 && vm.page > 0) {
                            $rootScope.$broadcast('goBack');
                        } else {
                            vm.foods = res.data.foods;
                            vm.pagination.reset(vm.foods.length + 1, vm.page * vm.offset);
                        }
                    }
                });
        }

        function updateFood(item) {
            FoodService.updateFood(item.id, null, {action: item.banned ? 'ban' : 'unban'}).then(function (res) {
                if (res.status === 200) {
                    item.banned = res.data.banned;
                } else {
                    item.banned = !item.banned;
                }
            });
        }

        function onSwitchClick($event) {
            $event.preventDefault(); 
            $event.stopPropagation();
        }

        function getFoodActionIcon(food) {
            const isTemplateFood = vm.getIsTemplateFood(food);

            return isTemplateFood ? "content_copy" : "edit";   
        }

        function setEnabledModules () {
            vm.isActiveCalendarEnabled = PaidModulesUtil.getIsActiveCalendarEnabled();
        }
    }
})();
