import React from 'react';

import { useFormContext } from 'react-hook-form';
import classes from './MealTimeRow.module.scss';
import {
  GridTableCell,
  GridTableRow,
} from '../../../../../../../shared/components/table-components';
import { processMealName } from '../../../../../../../shared/utils/mealUtils';
import { daysOfWeek } from '../../../../../../../shared/constants/daysOfWeek';
import {
  MealDaysFormFieldName,
  MealSchedule,
} from '../../../../../../interfaces/MealTimes';
import { MealTimeCell } from '../MealTimeCell/MealTimeCell';
import { EditCell } from '../EditCell/EditCell';
import { MealName } from '../../../../../../../shared/constants/mealNames';

type MealTimeRowProps = {
  mealName: MealName;
  mealFormKey: 'mealTime' | 'workoutMealTime';
};

const MealTimeRow = ({ mealName, mealFormKey }: MealTimeRowProps) => {
  const { watch } = useFormContext<MealSchedule>();

  return (
    <GridTableRow
      key={mealName}
      className={classes.container}
      variant="unstyled"
      dataTestId="meal-time-row"
    >
      <GridTableCell className={classes.mealTitle}>
        {processMealName(mealName)}
      </GridTableCell>
      {daysOfWeek.map((day) => {
        const fieldName = `${day}.${mealFormKey}.${mealName}` as MealDaysFormFieldName;

        const disabled =
          mealFormKey === 'workoutMealTime' && !watch(`${day}.workoutMealTime.enabled`);

        return <MealTimeCell key={day} fieldName={fieldName} disabled={disabled} />;
      })}
      <EditCell mealName={mealName} mealFormKey={mealFormKey} />
    </GridTableRow>
  );
};

export { MealTimeRow };
