const autoNgTemplateLoaderTemplate1 = require('./releases.template.html');

(function () {
  'use strict';

  ReleasesController.$inject = ['CoachService'];

  function ReleasesController(CoachService) {
    const vm = this;
    vm.spinner = false;
    vm.releases = [];

    vm.$onInit = () => {
      vm.spinner = true;
      CoachService.getUpdateHistory()
        .then(res => {
          if (res.status === 200) {
            vm.releases = res.data;
          }
        })
        .finally(() => vm.spinner = false);
    }
  }

  const releasesModule = angular.module('app.releases', []);
  releasesModule.component('releases', {
      templateUrl: autoNgTemplateLoaderTemplate1,
      controller: ReleasesController,
      controllerAs: 'vm',
    });

  releasesModule.config(['$routeProvider', function($routeProvider) {
    $routeProvider.when('/releases', {
      template: '<releases></releases>',
      resolve: {
        loginRequired: ['AuthService', function (AuthService) {
            return AuthService.loginRequired();
        }]
      }
    });
  }]);

})();
