import React, { ReactNode, Children } from 'react';
import classNames from 'classnames';

import classes from './InfoCell.module.scss';

type InfoCellProps = {
  title: string;
  children: ReactNode;
  className?: string;
};
/**
 * shows dash when children is nullish or array of nullish values
 */
const InfoCell = ({ title, children, className }: InfoCellProps) => {
  const cellClasses = classNames(classes.infoCell, className);

  const isChildrenExist = Children.toArray(children).length > 0;

  return (
    <div className={cellClasses}>
      <h6 className={classes.title}>{title}</h6>
      <div className={classes.description}>
        {isChildrenExist ? children : <span>—</span>}
      </div>
    </div>
  );
};

export { InfoCell };
