import React from 'react';

import { LbModal } from '../../../../../../shared/components/modals/LbModal/LbModal';
import { CorporateAccountsModalForm } from './CorporateAccountsModalForm/CorporateAccountsModalForm';
import { useCorporateAccountsModal } from './hooks/useCorporateAccountsModal';

const CorporateAccountsModal = () => {
  const { isYesBtnDisabled, title, control, handleFormSubmit, closeModal } =
    useCorporateAccountsModal();

  return (
    <LbModal
      open
      title={title}
      primaryBtnConfig={{
        text: 'Save',
        onClick: handleFormSubmit,
        disabled: isYesBtnDisabled,
      }}
      secondaryBtnConfig={{ onClick: closeModal }}
    >
      <CorporateAccountsModalForm control={control} />
    </LbModal>
  );
};

export { CorporateAccountsModal };
