const autoNgTemplateLoaderTemplate1 = require('./customer-meal-plan-macros-tab.template.html');

'use strict';

angular
    .module('app')
    .directive('customerMealPlanMacrosTab', customerMealPlanMacrosTab);

customerMealPlanMacrosTab.$inject = [];

function customerMealPlanMacrosTab() {
    return {
        restrict: 'E',
        scope: {},
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: 'CustomerMealPlanMacrosTabController',
        controllerAs: 'vm'
    };
}
