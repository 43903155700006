import React from 'react';

import { useFormContext } from 'react-hook-form';

import { LbInput } from '../../../../../../../components/form-components';
import { CustomFilterFormValues } from '../../AddCustomFilterForm.settings';
import { ModalInputBox } from '../../../../../../../components/modals';

const NoFoodTracking = () => {
  const { control, register } = useFormContext<CustomFilterFormValues>();

  return (
    <ModalInputBox title="No Food Tracking">
      <input
        {...register('filters.NO_FOOD_TRACKING.filterType', {
          value: 'NO_FOOD_TRACKING',
        })}
        hidden
      />
      <LbInput
        control={control}
        size="small"
        variant="outlined"
        name="filters.NO_FOOD_TRACKING.lastDays"
        label="Last Days"
        type="number"
        rules={{
          min: {
            value: 0,
            message: "Can't be less than 0",
          },
          max: {
            value: 30,
            message: "Can't be more than 30",
          },
        }}
      />
    </ModalInputBox>
  );
};

export { NoFoodTracking };
