import React from 'react';

import { FormControlLabel, FormControlLabelProps } from '@mui/material';
import classNames from 'classnames';

import classes from './LbFormControlLabel.module.scss';

import { PropsRewrite } from '../../../../utils-types';

export type LbFormControlLabelProps = PropsRewrite<
  FormControlLabelProps,
  {
    align?: 'start' | 'center' | 'end';
    font?: 'inherit' | 'body-14-medium';
  }
>;

/**
 * @description This component is used to rewrite the MUI FormControlLabel component.
 *
 *  New props:
 * - align - default: center;
 * - font - default: body-14-medium;
 */

const LbFormControlLabel = ({
  className,
  align = 'center',
  font = 'body-14-medium',
  ...props
}: LbFormControlLabelProps) => {
  const labelClasses = classNames(
    className,
    classes.labelBox,
    classes[`align-${align}`],
    classes[font],
  );

  return <FormControlLabel {...props} className={labelClasses} />;
};

export { LbFormControlLabel };
