import { DownloadedFileExtension } from '../../../constants/file/FILE_EXTENSIONS';

export const getMediaType = (fileExtension: DownloadedFileExtension) => {
  return mediaTypeForFiles[fileExtension];
};

const mediaTypeForFiles: Record<DownloadedFileExtension, BlobPropertyBag['type']> = {
  pdf: 'application/pdf',
  csv: 'text/csv',
};
