import { getRoundedNumber } from '../../../../../../../shared/utils/mathUtils';

export const getGapToGoalDescription = (total: number, consumed: number) => {
  const gap = getRoundedNumber(total - consumed, 0);

  if (gap > 0) {
    return `${gap} oz short`;
  } else if (gap < 0) {
    return `${Math.abs(gap)} oz over`;
  } else {
    return 'exactly';
  }
};
