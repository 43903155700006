import React from 'react';
import { createPortal } from 'react-dom';

import { IconButton, Paper } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import classes from './ConsumptionBetweenWidget.module.scss';
import { ConsumptionBetweenTable } from '../ConsumptionBetweenTable/ConsumptionBetweenTable';
import { ConsumptionInfo } from '../../../interfaces/consumption/ConsumptionInfo';
import { getFormattedDate } from '../../../../shared/utils/dateUtils';
import { localDataFormat } from '../../../../shared/constants/apiDateFormat';

type ConsumptionBetweenWidgetProps = {
  customerName: string;
  consumptionInfo: ConsumptionInfo;
  onClose: () => void;
};

const ConsumptionBetweenWidget = ({
  customerName,
  consumptionInfo,
  onClose,
}: ConsumptionBetweenWidgetProps) => {
  if (!consumptionInfo) {
    return null;
  }

  const formattedFrom = getFormattedDate(consumptionInfo.startDate, localDataFormat);
  const formattedTo = getFormattedDate(consumptionInfo.endDate, localDataFormat);

  return createPortal(
    <Paper
      variant="roundedBox"
      className={classes.widgetBox}
      data-test-id="consumption-between-widget"
    >
      <div className={classes.titleBox} data-test-id="consumption-widget-title">
        <div className={classes.titleInfo}>
          <h5>
            Consumption Between {formattedFrom} - {formattedTo}
          </h5>
          <p>{customerName}</p>
        </div>
        <IconButton onClick={onClose}>
          <CloseRoundedIcon fontSize="large" />
        </IconButton>
      </div>
      <ConsumptionBetweenTable
        goal={consumptionInfo.goal}
        actual={consumptionInfo.actual}
      />
    </Paper>,
    document.body,
  );
};

export { ConsumptionBetweenWidget };
