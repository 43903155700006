(function () {
    'use strict';

    angular.module('app.supplementSetup').controller('SupplementSetupController', SupplementSetupController);

    SupplementSetupController.$inject = [
        '$location',
        'utilService',
        'SupplementService'
    ];

    function SupplementSetupController($location, utilService, SupplementService) {
        var vm = this;

        vm.loggedInUser = utilService.getLoggedInUserData();
        vm.step = {name: 'step1'};
        vm.done = [false, false];
        vm.supplementUser = {
            country: 'USA',
            coach: {}
        };
        vm.spinner = {active: false};

        vm.saveSupplementUserInfo = saveSupplementUserInfo;
        vm.getTabState = getTabState;

        function saveSupplementUserInfo() {
            vm.spinner.active = true;
            return SupplementService.createSupplementUser(vm.supplementUser).then(function (res) {
                vm.spinner.active = false;
                if (res.status === 200) {
                    vm.supplementUser = res.data;
                    vm.step.name = 'step2';
                    vm.done[0] = true;
                }
            });
        }

        function getTabState(index) {
            if (index < 2 && vm.done[index - 1]) {
                return 'completed';
            }
            return vm.step.name === 'step' + index ? 'active' : '';
        }

    }

})();
