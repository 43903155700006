import React from 'react';
import { SvgIcon } from '@mui/material';

import classes from './StripeInfoChip.module.scss';
import { ReactComponent as StripeIcon } from '../../../shared/assets/icons/stripe.svg';

type StripeInfoChipProps = {
  isStripeConnected: boolean;
};
const StripeInfoChip = ({ isStripeConnected }: StripeInfoChipProps) => {
  return (
    <div className={classes.chip}>
      <SvgIcon component={StripeIcon} inheritViewBox />
      <span>{isStripeConnected ? 'Stripe is Connected' : 'Not Connected'}</span>
    </div>
  );
};

export { StripeInfoChip };
