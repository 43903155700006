import { apiService } from '../../shared/services/apiService';
import { paginationListLimit } from '../../shared/constants/systemConstants';

const baseUrl = 'api/food-scoring';

const getFoodScoreTypes = async () => {
  const resp = await apiService.get(`${baseUrl}/types`);

  return resp.data;
};

const saveFoodScoring = async (foodId, scoreResults) => {
  const resp = await apiService.put(`${baseUrl}/food/${foodId}`, scoreResults);

  return resp.data;
};

const getWorkingTable = async (page) => {
  const params = { limit: paginationListLimit, page };

  const resp = await apiService.get(`${baseUrl}/working-table`, { params });

  return resp.data;
};

const getFinalTable = async (page) => {
  const params = { limit: paginationListLimit, page };

  const resp = await apiService.get(`${baseUrl}/result-table`, { params });

  return resp.data;
};

const deleteFood = async (foodId) => {
  const params = { foodId };

  const resp = await apiService.post(`${baseUrl}/blacklist`, null, { params });

  return resp.data;
};

export const foodScoringService = {
  getFoodScoreTypes,
  saveFoodScoring,
  getWorkingTable,
  getFinalTable,
  deleteFood,
};
