import { Button, ButtonProps, Menu, MenuItem } from '@mui/material';
import React, { ReactNode, useRef } from 'react';
import { useToggle } from 'react-use';
import { MenuOptions } from '../../../../general-types';

type LbMenuProps = {
  buttonProps?: ButtonProps;
  children: ReactNode;
  options: MenuOptions[];
};

const LbMenu = ({ buttonProps, children, options }: LbMenuProps) => {
  const buttonElement = useRef<HTMLButtonElement>(null);
  const [isMenuOpen, toggleMenuOpen] = useToggle(false);

  return (
    <>
      <Button {...buttonProps} ref={buttonElement} onClick={toggleMenuOpen}>
        {children}
      </Button>
      <Menu
        anchorEl={buttonElement.current}
        open={isMenuOpen}
        onClick={toggleMenuOpen}
        variant="menu"
      >
        {options.map(
          (item) =>
            !item.isHide && (
              <MenuItem
                onClick={item.onClick}
                key={item.id}
                component={item.href ? 'a' : 'li'}
                href={item.href}
              >
                {item.label}
              </MenuItem>
            ),
        )}
      </Menu>
    </>
  );
};

export { LbMenu, LbMenuProps };
