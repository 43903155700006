const autoNgTemplateLoaderTemplate1 = require('./revenue-report.template.html');

'use strict';

angular
    .module('app')
    .directive('revenueReport', revenueReport);

revenueReport.$inject = [];

function revenueReport() {
    return {
        restrict: 'E',
        scope: {},
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: 'RevenueReportController',
        controllerAs: 'vm'
    };
}
