(function () {
    'use strict';

    angular.module('app')
        .controller('PinCodeController', PinCodeController);

    PinCodeController.$inject = [
        'utilService',
        'AuthService'
    ];

    function PinCodeController(utilService, AuthService) {
        var vm = this;

        vm.pinCodeMask = [null, null, null, null];
        vm.pinCode = '';

        vm.loggedInUser = utilService.getLoggedInUserData();
        vm.spinner = {active: false};

        vm.corporateSignIn = corporateSignIn;
        vm.changePinCode = changePinCode;
        vm.addValueToPin = addValueToPin;
        vm.removeValueFromPin = removeValueFromPin;

        function corporateSignIn() {
            vm.spinner.active = true;
            AuthService.corporateSignIn(vm.pinCode, vm.loggedInUser.corporateAccountInfo).then(function (res) {
                vm.spinner.active = false;
                if (res.status !== 200) {
                    vm.pinCode = '';
                }
            });
        }

        function changePinCode() {
            if (vm.pinCode.length === 4) {
                vm.corporateSignIn();
            }
        }

        function addValueToPin(value) {
            if (vm.pinCode.length < 4) {
                vm.pinCode += value;
            }

            if (vm.pinCode.length >= 4) {
                vm.corporateSignIn();
            }
        }

        function removeValueFromPin() {
            vm.pinCode = vm.pinCode.substring(0, vm.pinCode.length - 1);
        }
    }

})();
