import React from 'react';

import { useFormContext } from 'react-hook-form';

import classes from './SelectActivityForm.module.scss';
import { InfoBox } from '../../../../../../shared/components/ui-components';
import { MultipleComboBox } from '../../../../../../shared/components/form-components';
import { useActivitiesList } from '../../../../../hooks/useActivitiesList';

export type SelectActivityFormValues = {
  selectActivity: {
    workouts: string[];
    sports: string[];
  };
};

const SelectActivityForm = () => {
  const { control } = useFormContext<SelectActivityFormValues>();
  const { workouts, sports } = useActivitiesList();

  const generalInputProps = { control, size: 'small', variant: 'outlined' } as const;

  return (
    <InfoBox title="Activity">
      <div className={classes.container}>
        <div className={classes.infoGroup}>
          <p>Select the workouts/sports the user does during the week.</p>
          <p>The user will have the option to modify this information in their profile</p>
        </div>
        <MultipleComboBox
          {...generalInputProps}
          name="selectActivity.workouts"
          label="Workouts"
          options={workouts}
          limitTags={3}
        />
        <MultipleComboBox
          {...generalInputProps}
          name="selectActivity.sports"
          label="Sports"
          options={sports}
          limitTags={3}
        />
      </div>
    </InfoBox>
  );
};

export { SelectActivityForm };
