import { Base64 } from 'js-base64';
import { apiService } from '../../shared/services/apiService';
import { setUserInfo } from '../../shared/utils/userInfoUtils';
import { RESPONSE_STATUSES } from '../../shared/constants/RESPONSE_STATUSES';
import { CorporateAccountInfo, LoggedInUser } from '../../shared/interfaces/LoggedInUser';
import { goTo, goToHomePage } from '../../shared/utils/angularUtils';

type SignInInfo = {
  email: string;
  password: string;
};

type SignInParams = { deleteOtherSessions?: boolean };

export type SignInResponse = CorporateAccountInfo | LoggedInUser;

export const authService = {
  // status 211 return for mfa but it newer used, check api/mfa/cookie-login on backend
  async signIn({ email, password }: SignInInfo, params?: SignInParams) {
    const headers = {
      Authorization: `Basic ${Base64.encode(`${email}:${password}`)}`,
    };

    const resp = await apiService.post<SignInResponse>('api/cookie-login', null, {
      headers,
      params,
      ignoreErrorCodes: [RESPONSE_STATUSES.SESSION_EXIST, RESPONSE_STATUSES.UNAUTHORIZED],
    });

    const user = resp.data;

    if (resp.status === RESPONSE_STATUSES.CORPORATE_LOGIN) {
      await setUserInfo({ corporateAccountInfo: user });
      goTo('/pin-code');
    } else {
      await setUserInfo(user);
      goToHomePage();
    }

    return resp.data;
  },

  async resetPassword(email: string) {
    const params = { email };

    const resp = await apiService.post('api/public/resetpasswordlink', null, { params });

    return resp.data;
  },
};
