import React, { ReactNode } from 'react';

import { CircularProgress } from '@mui/material';
import classNames from 'classnames';

import classes from './LbCircularProgress.module.scss';
import { getProgressColor } from './utils/getProgressColor';

type LbCircularProgressProps = {
  value: number;
  size: number;
  children?: ReactNode;
  className?: string;
};
// TODO after understand rules add string size (small, medium, large)
const LbCircularProgress = ({
  value,
  size,
  children,
  className,
}: LbCircularProgressProps) => {
  const boxClasses = classNames(classes.box, className);

  return (
    <div className={boxClasses}>
      <div className={classes.circleBox}>
        <CircularProgress variant="determinate" color="inherit" value={100} size={size} />
        <CircularProgress
          variant="determinate"
          color={getProgressColor(value)}
          value={value > 100 ? 100 : value} // fix overflow
          size={size}
          className={classes.circle}
        />
        <div className={classes.valueBox}>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

export { LbCircularProgress, LbCircularProgressProps };
