'use strict';

angular.module('app').controller('EntitySetupStep4Controller', EntitySetupStep4Controller);

EntitySetupStep4Controller.$inject = ['$scope', 'EntityService', 'utilService'];

function EntitySetupStep4Controller($scope, EntityService, utilService) {
    var vm = this;
    vm.spinner = $scope.spinner;
    vm.createEntity = $scope.createEntity;
    vm.entityCredentials = {};

    vm.init = init;
    vm.onResetLogin = onResetLogin;
    vm.onSubmit = onSubmit;

    init();

    function init() {
        const email = vm.createEntity?.userEmail;
        if (email) {
            vm.entityCredentials.loginID = email.slice(0, email.indexOf('@'));
        }
    }

    function onResetLogin() {
        vm.entityCredentials.loginID = '';
    }

    function onSubmit() {
        vm.spinner.active = true;
        EntityService.setupCredentials(vm.createEntity.entityID, vm.entityCredentials)
            .then(res => {
                if (res.status === 200) {
                    utilService.goTo('entity-profile/' + vm.createEntity.entityID, { replaceHistory: true });
                }
            })
            .finally(() => vm.spinner.active = false);
    }
}
