import React from 'react';

import { Button } from '@mui/material';
import { useToggle } from 'react-use';
import { observer } from 'mobx-react';

import classes from './EntityLoginIdBlock.module.scss';
import { InfoBox, LbChip } from '../../../../../../../shared/components/ui-components';
import { TitleWithDescription } from '../../../../../../../shared/components/text-formation-components';
import { ChangeLoginIdModal } from './ChangeLoginIdModal/ChangeLoginIdModal';
import { useEntityLoginId } from './hooks/useEntityLoginId';
import { getDescriptionInfo } from './utils/getDescriptionInfo';
import { loggedInUserDataStore } from '../../../../../../../shared/stores/loggedInUserDataStore';

type EntityLoginIdBlockProps = {
  entityId: number;
};

const EntityLoginIdBlock = observer(({ entityId }: EntityLoginIdBlockProps) => {
  const [isShowChangeLoginIdModal, toggleShowChangeLoginIdModal] = useToggle(false);
  const { loginId, updateLoginId } = useEntityLoginId(entityId);
  const { isSuperAdmin } = loggedInUserDataStore;

  const description = getDescriptionInfo(isSuperAdmin);

  return (
    <>
      <InfoBox title="Login ID">
        <div className={classes.row}>
          <TitleWithDescription
            title={
              <div className={classes.titleRow}>
                <span>Current Login ID:</span>
                <LbChip label={loginId} size="small" />
              </div>
            }
            description={description}
            titleFont="body-14-bold"
          />
          <Button variant="outlined" size="small" onClick={toggleShowChangeLoginIdModal}>
            Change Login ID
          </Button>
        </div>
      </InfoBox>
      <ChangeLoginIdModal
        open={isShowChangeLoginIdModal}
        onSubmit={updateLoginId}
        onClose={toggleShowChangeLoginIdModal}
      />
    </>
  );
});

export { EntityLoginIdBlock };
