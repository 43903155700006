import React, { ReactNode } from 'react';

import { SelectSubscriptionCard } from './SelectSubscriptionCard/SelectSubscriptionCard';
import { PromoCodeCard } from './PromoCodeCard/PromoCodeCard';
import { CustomerSubscription } from '../../interfaces/customerSubscription';

type SelectSubscriptionBlockProps = {
  subscriptions: CustomerSubscription[];
  discountPercent: number;
  onApplyPromoCode: (promoCode: string) => void;
  selectedSubscriptionId: number | null;
  setSelectedSubscriptionId: (subscriptionId: number) => void;
  titleDescription?: ReactNode;
};

const SelectSubscriptionBlock = ({
  subscriptions,
  discountPercent,
  onApplyPromoCode,
  selectedSubscriptionId,
  setSelectedSubscriptionId,
  titleDescription,
}: SelectSubscriptionBlockProps) => {
  return (
    <>
      <SelectSubscriptionCard
        subscriptions={subscriptions}
        discountPercent={discountPercent}
        selectedSubscriptionId={selectedSubscriptionId}
        setSelectedSubscriptionId={setSelectedSubscriptionId}
        titleDescription={titleDescription}
      />
      <PromoCodeCard onApplyPromoCode={onApplyPromoCode} />
    </>
  );
};

export { SelectSubscriptionBlock };
