const autoNgTemplateLoaderTemplate1 = require('./add-video.template.html');

'use strict';

(function () {
    angular
        .module('app.addVideo', [])
        .config(addVideoConfig);

    addVideoConfig.$inject = ['$routeProvider'];

    function addVideoConfig($routeProvider) {
        $routeProvider
            .when('/add-videos', {
                disableCache: true,
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'AddVideoController',
                controllerAs: 'vm',
                resolve: {
                    loginRequired: ['AuthService', function (AuthService) {
                        return AuthService.loginRequired();
                    }]
                }
            })
    }
})();
