/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';

import { Badge, Box, Fab, Modal } from '@mui/material';
import ForumIcon from '@mui/icons-material/Forum';
import { observer } from 'mobx-react';
import { angularize } from 'react-in-angularjs';
import { StreamChat } from 'stream-chat';

import { SystemProviders } from '../../../shared/providers';
import { LbChat } from '../../components/LbChat/LbChat';
import { chatStore } from '../../stores/chatStore';
import classes from './NgzChatModal.module.scss';
import { RefreshButton } from './RefreshButton/RefreshButton';
import { loggedInUserDataStore } from '../../../shared/stores/loggedInUserDataStore';

const apiKey = process.env.APP_STREAM_API_KEY;

const ChatModal = observer(() => {
  const [unreadCount, setUnreadCount] = useState(0);

  const {
    user,
    userToken,
    chatClient,
    setChatClient,
    getChatInfo,
    setIsOpenChat,
    isOpenChat,
    resetChat,
  } = chatStore;

  useEffect(() => {
    initChatData();

    return resetChat;
  }, []);

  useEffect(() => {
    if (!chatClient && userToken) {
      initChat();
    }
  }, [userToken]);

  const initChatData = async () => {
    await getChatInfo();
  };

  const initChat = async () => {
    const client = StreamChat.getInstance(apiKey, {
      timeout: 0, // fix canceled requests after 3s (default)
    });

    const { id, name, imageUrl } = user;
    await client.connectUser({ id, name, image: imageUrl }, userToken);
    watchUnreadCount(client);
    setChatClient(client);
  };

  const watchUnreadCount = (chatClient) => {
    chatClient.on((event) => {
      if (event.total_unread_count !== undefined) {
        setUnreadCount(event.total_unread_count);
      }
    });
  };

  const onOpenHandler = () => setIsOpenChat(true);
  const onCloseHandler = () => setIsOpenChat(false);

  return (
    <SystemProviders>
      <Badge className={classes.chatBtn} badgeContent={unreadCount} color="error">
        <Fab onClick={onOpenHandler} color="primary" aria-label="Open chat">
          <ForumIcon fontSize="large" />
        </Fab>
      </Badge>
      <Modal className={classes.modal} onClose={onCloseHandler} open={isOpenChat}>
        <Box className={classes.chatWrapper}>
          <RefreshButton />
          <LbChat />
        </Box>
      </Modal>
    </SystemProviders>
  );
});

// TODO remove this component after all components will be migrated to React
const NgzChatModal = observer(() => {
  const { loggedInUser } = loggedInUserDataStore;

  return loggedInUser?.id ? <ChatModal /> : null;
});

angularize(NgzChatModal, 'ngzChatModal', angular.module('app'), {});

export { NgzChatModal, ChatModal };
