import moment from 'moment';

import { getFormattedDate } from '../../../shared/utils/dateUtils';
import { scanDateTimeFormat } from '../../constants/scanDateTimeFormat';
import { dateFormat, timeFormat } from '../../../shared/constants/apiDateFormat';

export const getScanDateTimes = (scanDate: string, scanTime: string) => {
  const dateTime = moment(`${scanDate} ${scanTime}`, `${dateFormat} ${timeFormat}`);

  return getFormattedDate(dateTime, scanDateTimeFormat);
};
