import React from 'react';

import { observer } from 'mobx-react';

import { PageTitle } from '../../../../shared/components/ui-components';
import { entityListStore } from '../../../stores/entityListStore';
import { PaymentFlowTabs } from './PaymentFlowTabs/PaymentFlowTabs';
import { AddEntityMenu } from '../../../components/NgzAddEntityMenu/NgzAddEntityMenu';

const EntityListPageTitle = observer(() => {
  const { filteredEntityCount } = entityListStore;

  return (
    <PageTitle title={`Entities (${filteredEntityCount})`} tabs={<PaymentFlowTabs />}>
      <AddEntityMenu variant="contained" size="medium" />
    </PageTitle>
  );
});

export { EntityListPageTitle };
