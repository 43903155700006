import React from 'react';

import { angularize } from 'react-in-angularjs';
import { Button } from '@mui/material';

import classes from './NgzPageNotFound.module.scss';
import { SystemProviders } from '../../providers';
import pageNotFoundImg from '../../assets/image/pageNotFoundImg.png';
import { goToHomePage } from '../../utils/angularUtils';

const NgzPageNotFound = () => {
  return (
    <SystemProviders>
      <div className={classes.box}>
        <img src={pageNotFoundImg} alt="page not found" />
        <div className={classes.textBox}>
          <p>
            The page you were looking for is taking a break and is no longer available. If
            you need more help please let us know at&nbsp;
            <a href="mailto:jpetersen@lifebase.solutions">jpetersen@lifebase.solutions</a>
            . Thank you.
          </p>
        </div>
        <Button variant="contained" onClick={goToHomePage}>
          Return to the Main page
        </Button>
      </div>
    </SystemProviders>
  );
};

angularize(NgzPageNotFound, 'ngzPageNotFound', angular.module('app'), {});

export { NgzPageNotFound };
