import React from 'react';

import { Button, AppBar, Toolbar } from '@mui/material';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';

import classes from './PreviewLogoCard.module.scss';
import {
  CompanySubLogo,
  LbChip,
} from '../../../../../../../../../shared/components/ui-components';

type PreviewLogoCardProps = {
  logoUrl: string;
  onClear: () => void;
};

const PreviewLogoCard = ({ logoUrl, onClear }: PreviewLogoCardProps) => {
  return (
    <div className={classes.previewBox}>
      <LbChip label="Preview" size="small" color="info" className={classes.chip} />
      <Button
        variant="text"
        size="small"
        startIcon={<DeleteOutlineRoundedIcon />}
        className={classes.deleteButton}
        onClick={onClear}
      >
        Clear
      </Button>
      {/* represent header */}
      <AppBar component="div">
        <Toolbar component="div" className={classes.havBar}>
          <CompanySubLogo logoUrl={logoUrl} />
        </Toolbar>
      </AppBar>
    </div>
  );
};

export { PreviewLogoCard };
