const autoNgTemplateLoaderTemplate1 = require('./modal/cancel-subscription.modal.html');

'use strict';

angular.module('app').factory('CancelSubscriptionService', CancelSubscriptionService);

CancelSubscriptionService.$inject = ['$mdDialog', 'ApiService'];

function CancelSubscriptionService($mdDialog, ApiService) {
  const cancelSubscriptionService = {
    getStatisticAndReasons: customerId => ApiService.get(`api/customer/${customerId}/statistics`),
    postSubscriptionCancelFeedback: (subscriptionId, data) => {
      return ApiService.post(`api/subscription-transaction/${subscriptionId}/canceling-reason`, data);
    },
    openCancelSubscriptionModal: (ev, customerId, subscriptionId, cancelRenewalOnServer) => {
      $mdDialog.show({
        controller: ['$scope', '$mdDialog', ($scope, $mdDialog) => {
          $scope.spinner = false;
          $scope.imgBaseSrc = './images/cancel-subscription/';
          $scope.statistic = {};
          $scope.feedback = {
            reasons: [],
            description: ''
          };

          $scope.init = init;
          $scope.initStatistic = initStatistic;
          $scope.initFeedback = initFeedback;
          $scope.setFeedbackFormValidity = setFeedbackFormValidity;
          $scope.onContinue = onContinue;
          $scope.getDataForSubmit = getDataForSubmit;

          $scope.init();

          $scope.setFeedbackForm = (form) => $scope.feedbackForm = form;

          function init() {
            $scope.spinner = true;
            cancelSubscriptionService.getStatisticAndReasons(customerId)
              .then(res => {
                if (res.status === 200) {
                  const response = res.data;
                  $scope.initStatistic(response);
                  $scope.initFeedback(response);
                }
              })
              .finally(() => $scope.spinner = false);
          }

          function initStatistic(response) {
            $scope.statistic = Object.assign({}, response);
            delete $scope.statistic.reasons;
          }

          function initFeedback(response) {
            $scope.feedback.reasons = response.reasons.map(reason => ({ title: reason, selected: false }));
            $scope.setFeedbackFormValidity();
          }

          function setFeedbackFormValidity() {
            const isValid = $scope.feedback.reasons.some(reason => reason.selected);
            $scope.feedbackForm.$setValidity('someSelected', isValid);
          }

          function onContinue() {
            const data = getDataForSubmit();
            $scope.spinner = true;
            cancelSubscriptionService.postSubscriptionCancelFeedback(subscriptionId, data)
              .then(res => {
                if (res.status === 200 && typeof cancelRenewalOnServer === 'function') {
                  cancelRenewalOnServer();
                  $scope.cancel();
                }
              })
              .finally(() => $scope.spinner = false);
          }

          function getDataForSubmit() {
            const data = { description: $scope.feedback.description };
            data.reasons = $scope.feedback.reasons
              .filter(reason => reason.selected)
              .map(reason => reason.title);
            return data;
          }

          $scope.cancel = () => $mdDialog.cancel();
        }],
        templateUrl: autoNgTemplateLoaderTemplate1,
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose:true,
      });
    },
  };

  return cancelSubscriptionService;
}
