import React from 'react';

import { Link } from '@mui/material';

import classes from './LogoLink.module.scss';
import { ReactComponent as LogoIcon } from '../../../assets/icons/logo.svg';

const LogoLink = () => {
  return (
    <Link href="/" className={classes.iconBox}>
      <LogoIcon />
    </Link>
  );
};

export { LogoLink };
