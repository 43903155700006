import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import Button from '@mui/material/Button';

import classes from './ConfirmButtonsBlock.module.scss';

const ConfirmButtonsBlock = ({
  onCancel,
  onConfirm,
  yesDisabled,
  className,
  size = 'small',
  noText = 'Cancel',
  yesText = 'OK',
  isShowNo = true,
}) => {
  const containerClassName = classNames(classes.container, className);

  return (
    <div className={containerClassName}>
      {isShowNo && (
        <Button variant="outlined" onClick={onCancel} size={size}>
          {noText}
        </Button>
      )}
      <Button
        variant="contained"
        type="submit"
        onClick={onConfirm}
        disabled={yesDisabled}
        size={size}
      >
        {yesText}
      </Button>
    </div>
  );
};

ConfirmButtonsBlock.propTypes = {
  noText: PropTypes.string,
  onCancel: PropTypes.func,
  yesText: PropTypes.string,
  onConfirm: PropTypes.func,
  yesDisabled: PropTypes.bool,
  isShowNo: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export { ConfirmButtonsBlock };
