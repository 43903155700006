import React from 'react';

import { angularize } from 'react-in-angularjs';
import { Container } from '@mui/material';

import { SystemProviders } from '../../../../../shared/providers';
import { PageTitle } from '../../../../../shared/components/ui-components';
import { VideoList } from './VideoList/VideoList';
import { YoutubeVideoModal } from './YoutubeVideoModal/YoutubeVideoModal';
import { useVideoTab } from './hooks/useVideoTab';
import {
  LbTabPanel,
  LbTabs,
  LbTabsList,
  TabsConfig,
} from '../../../../../shared/components/tabs';

const NgzVideosTab = () => {
  const { videoData, showedVideoSrc, handleShowVideoModal, handleCloseVideoModal } =
    useVideoTab();

  const tabsConfig: TabsConfig[] = videoData.map((videoCategory, index) => ({
    label: videoCategory.name,
    hide: !videoCategory.videos.length,
    value: index,
    panel: (
      <VideoList videos={videoCategory.videos} onVideoClick={handleShowVideoModal} />
    ),
  }));

  return (
    <SystemProviders>
      <Container>
        <LbTabs defaultValue={0}>
          <PageTitle
            title="Videos"
            tabs={<LbTabsList tabsConfig={tabsConfig} variant="underline" />}
          />
          <LbTabPanel tabsConfig={tabsConfig} />
        </LbTabs>
        <YoutubeVideoModal
          open={!!showedVideoSrc}
          videoSrc={showedVideoSrc}
          onClose={handleCloseVideoModal}
        />
      </Container>
    </SystemProviders>
  );
};

angularize(
  NgzVideosTab,
  'ngzVideosTab',
  angular.module('app.guidesAndAdditionalInfo'),
  {},
);

export { NgzVideosTab };
