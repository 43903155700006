import React from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { observer } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';

import { ButtonsRow } from '../../components/ButtonsRow/ButtonsRow';
import { PersonalDataForm } from './PersonalDataForm/PersonalDataForm';
import { UserProfileFromValue } from './UserProfileStep.settings';
import { AddressDataFrom } from './AddressDataFrom/AddressDataFrom';
import { GeneralDataFrom } from './GeneralDataFrom/GeneralDataFrom';
import { getUserProfileFromValue } from './utils/getUserProfileFromValue';
import { customerService } from '../../../../services/customerService';
import { processAssignCustomersToGroup } from './utils/processAssignCustomersToGroup';
import { useInbodyData } from './hooks/useInbodyData';
import { loggedInUserDataStore } from '../../../../../shared/stores/loggedInUserDataStore';
import { useCustomerScan } from '../../../../../inbody';
import { processCreateInbodyScan } from './utils/processCreateInbodyScan';
import { HiddenInput } from '../../../../../shared/components/form-components';
import { AddInbodyScan } from './AddInbodyScan/AddInbodyScan';
import { customerSetupStore } from '../../../../stores/customerSetupStore';

type UserProfileStepProps = {
  customerId: number;
};
// TODO need refactor
const UserProfileStep = observer(({ customerId }: UserProfileStepProps) => {
  const formMethods = useForm<UserProfileFromValue>({
    mode: 'all', // need to correct validate inbodId on load scan
    shouldUnregister: true, // delete also default values what not used from data
    defaultValues: async () => getUserProfileFromValue(customerId),
  });
  const { control, handleSubmit, register } = formMethods;

  const { stepperMethods, setCustomerBodyMetrics } = customerSetupStore;
  const { getEntityId } = loggedInUserDataStore;
  const entityId = getEntityId();

  const { entityInbodyData, supportedInbodyTypeOptions } = useInbodyData(entityId);
  const { scanData, isScanUnavailable, getScanData } = useCustomerScan();

  const handleFormSubmit = handleSubmit(
    async ({ user, customer, inbodyInfo, customerGroup, scanData: manualScanData }) => {
      if (!scanData && !manualScanData) {
        enqueueSnackbar(
          "Please select 'Load scan from InBody' or 'Fill in data manually' to proceed further.",
          { variant: 'error', autoHideDuration: 20000 },
        );
      } else {
        await Promise.all([
          processAssignCustomersToGroup(customerId, customerGroup),
          customerService.updateCustomer(customerId, { user, customer, inbodyInfo }),
        ]);
        // customer inbody type can be changed by previous api call
        await processCreateInbodyScan(customerId, inbodyInfo.inbodyType, manualScanData);

        const customerScanDate = scanData || manualScanData;

        setCustomerBodyMetrics({
          weight: customerScanDate!.weight,
          percentBodyFat: customerScanDate!.percentBodyFat,
        });
        stepperMethods.goToNextStep();
      }
    },
  );

  return (
    <div>
      <FormProvider {...formMethods}>
        <HiddenInput register={register} name="user.userId" value={customerId} />
        <GeneralDataFrom
          control={control}
          supportedInbodyTypeOptions={supportedInbodyTypeOptions}
        >
          <AddInbodyScan
            entityInbodyData={entityInbodyData}
            scanData={scanData}
            isScanUnavailable={isScanUnavailable}
            getScanData={getScanData}
          />
        </GeneralDataFrom>
        <PersonalDataForm control={control} />
        <AddressDataFrom control={control} />
        <ButtonsRow onNext={handleFormSubmit} />
      </FormProvider>
    </div>
  );
});

export { UserProfileStep };
