import React from 'react';

import { MenuItem } from '@mui/material';
import CallMadeRoundedIcon from '@mui/icons-material/CallMadeRounded';

import classes from './NavMenuItem.module.scss';
import { useIsActivePage } from '../../../../../hooks/useIsActivePage';
import { MenuItemSetting } from '../../../../../interfaces/system/NavLinkConfig';
import { PATH_TYPE } from '../../../../../constants/system/headerSettings';

type NavMenuItemProps = {
  settings: MenuItemSetting;
};

const NavMenuItem = ({ settings }: NavMenuItemProps) => {
  const { label, path, pathType } = settings;

  const isActive = useIsActivePage({ url: path });

  const isExternalLink = pathType === PATH_TYPE.EXTERNAL_LINK;

  const href = isExternalLink ? path : `#!${path}`;
  const target = isExternalLink ? '_blank' : undefined;

  return (
    <MenuItem
      component="a"
      selected={!!isActive}
      href={href}
      className={classes.menuItem}
      target={target}
    >
      <span>{label}</span>
      {isExternalLink && (
        <CallMadeRoundedIcon
          color="primary"
          fontSize="small"
          className={classes.endIcon}
        />
      )}
    </MenuItem>
  );
};

export { NavMenuItem };
