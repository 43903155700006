import { useEffect, useState } from 'react';
import { UI_RELEASE_NUMBER } from '../../../../../constants/system/UI_RELEASE_NUMBER';
import { VersionInfo } from '../../../../../interfaces/system/VersionInfo';
import { releasesService } from '../../../../../services/releasesService';

type UseVersionInfoReturn = { uiVersion: VersionInfo; apiVersion: VersionInfo };

const defaultVersionInfo: VersionInfo = {
  type: 'no-info',
  build: 'no-info',
  date: 'no-info',
};

export const useVersionInfo = (): UseVersionInfoReturn => {
  const [uiVersion, setUiVersion] = useState<VersionInfo>(defaultVersionInfo);
  const [apiVersion, setApiVersion] = useState<VersionInfo>(defaultVersionInfo);

  const getApiVersionInfo = async () => {
    const versionInfo = await releasesService.getApiVersion();

    setApiVersion(versionInfo);
  };

  const getUiVersionInfo = async () => {
    const versionInfo = await releasesService.getUiVersion();

    if (versionInfo) {
      setUiVersion({
        ...versionInfo,
        build: `${UI_RELEASE_NUMBER}:${versionInfo.build}`,
      });
    }
  };

  useEffect(() => {
    getApiVersionInfo();
    getUiVersionInfo();
  }, []);

  return { uiVersion, apiVersion };
};
