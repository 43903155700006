import { requiredAllOrAnyone } from '../../../../../../utils/reactHookFormUtils';
import { CustomComparisonFilterType } from '../../../../interfaces/CustomFilterData';
import { CustomFilterFormValues } from '../AddCustomFilterForm.settings';

export const getComparisonFilterValidateRule =
  (filterType: CustomComparisonFilterType) =>
  (value: string | number, formValues: CustomFilterFormValues) => {
    const filter = formValues.filters[filterType];

    return requiredAllOrAnyone(value, filter, ['filterType']);
  };
