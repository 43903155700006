import { makeAutoObservable } from 'mobx';
import { resetAllStore } from '../../shared/stores/resetAllStore';

class StepperStore {
  constructor() {
    this.setDefaultValues();
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.isOpenDeclineModal = false;
    this.activeStep = 1;
    this.stepsCount = 4;
  }

  nextStep() {
    this.activeStep++;
  }

  openDeclineModal() {
    this.isOpenDeclineModal = true;
  }

  closeDeclineModal() {
    this.isOpenDeclineModal = false;
  }

  setStepsCount(stepsCount) {
    this.stepsCount = stepsCount;
  }
}

const stepperStore = new StepperStore();

resetAllStore.addResetMethod(stepperStore.setDefaultValues);

export { StepperStore, stepperStore };
