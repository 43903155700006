import React from 'react';
import PropTypes from 'prop-types';

import { IconButton } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';

import classes from './InputWithArrow.module.scss';

const InputWithArrow = ({
  children,
  upArrowClickHandler,
  downArrowClickHandler,
  disabled,
}) => {
  return (
    <div className={classes.inputWithArrow}>
      {children}
      <div className={classes.arrowBox}>
        <IconButton onClick={upArrowClickHandler} disabled={disabled}>
          <KeyboardArrowUpRoundedIcon fontSize="medium" />
        </IconButton>
        <IconButton onClick={downArrowClickHandler} disabled={disabled}>
          <KeyboardArrowDownRoundedIcon fontSize="medium" />
        </IconButton>
      </div>
    </div>
  );
};

InputWithArrow.propTypes = {
  children: PropTypes.node,
  upArrowClickHandler: PropTypes.func,
  downArrowClickHandler: PropTypes.func,
  disabled: PropTypes.bool,
};

export { InputWithArrow };
