import { CustomerCommunicationFilterFormValues } from '../../../interfaces/CustomerCommunicationFilters';

export const filterDefaultValues: CustomerCommunicationFilterFormValues = {
  customFilterId: null,
  generalFilter: {
    fullName: '',
    customerGroupId: '',
    startDate: null,
    status: '',
    coachName: '',
    entityName: '',
  },
};
