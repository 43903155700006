import React from 'react';

import { Button } from '@mui/material';

import { PageTitle } from '../../../../../../shared/components/ui-components';

type SchoolsTitleProps = {
  onShowModalSchool: () => void;
};

const SchoolsTitle = ({ onShowModalSchool }: SchoolsTitleProps) => {
  return (
    <PageTitle title="Schools" customTopMargin>
      <Button
        variant="contained"
        onClick={() => {
          onShowModalSchool();
        }}
      >
        Add new School
      </Button>
    </PageTitle>
  );
};

export { SchoolsTitle };
