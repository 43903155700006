import { apiService } from '../../shared/services/apiService';
import { FreeAccessInfo, FreeAccessMember } from '../interfaces/freeAccess';

export const freeAccessService = {
  async getFreeSeatsInfo(entityId: number) {
    const resp = await apiService.get<FreeAccessInfo>(
      `/api/entity/${entityId}/free-seats`,
    );

    return resp.data;
  },

  async setFreeSeatsLimit(
    entityId: number,
    freeSeats: number,
    isRunGlobalSpinner = false,
  ) {
    const params = { freeSeats };

    const resp = await apiService.post<FreeAccessInfo>(
      `/api/entity/${entityId}/free-seats`,
      undefined,
      { params, isRunGlobalSpinner },
    );

    return resp.data;
  },

  async getFreeAccessMembers(entityId: number, isRunGlobalSpinner = false) {
    const resp = await apiService.get<FreeAccessMember[]>(
      `/api/entity/${entityId}/free-seats/list`,
      { isRunGlobalSpinner },
    );

    return resp.data;
  },

  async inviteFreeAccessMember(
    entityId: number,
    email: string,
    isRunGlobalSpinner = false,
  ) {
    const params = { email };

    const resp = await apiService.post<FreeAccessMember>(
      `/api/entity/${entityId}/free-seats/list/invite`,
      undefined,
      { params, isRunGlobalSpinner },
    );

    return resp.data;
  },

  async deleteFreeAccessMember(
    entityId: number,
    memberId: number,
    isRunGlobalSpinner = false,
  ) {
    await apiService.delete(`/api/entity/${entityId}/free-seats/list/${memberId}`, {
      isRunGlobalSpinner,
    });
  },
};
