import { makeAutoObservable } from 'mobx';
import { resetAllStore } from '../../shared/stores/resetAllStore';
import { SendMessageWayTypes } from '../constants/SEND_MESSAGE_WAY';
import { communicationService } from '../services/communicationService';
import {
  SendMessageFormValues,
  MessageData,
  SendMessageResourceType,
} from '../interfaces/SendMessageData';
import { NotGotMessageUser } from '../interfaces/NotGotMessageUser';
import { fileService } from '../../shared/services/fileService';

type OpenCommunicationModalInfo = {
  via?: SendMessageWayTypes;
  customerIds?: number[];
  filters?: Record<string, any>;
  customersCount: number;
  resourceType: SendMessageResourceType;
};

class CommunicationModalStore {
  isModalOpen: boolean = false;
  customerIds: number[] = [];
  via: SendMessageWayTypes = 'PUSH';
  filters: Record<string, any> = {};
  customersCount: number | null = null;
  resourceType: SendMessageResourceType | null = null;
  isSendingMessage: boolean = false;
  isSentSuccess: boolean = false;
  isSentWithError: boolean = false;
  notGotMessageUsers: NotGotMessageUser[] = [];
  message?: SendMessageFormValues | null = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  closeCommunicationModal() {
    this.isModalOpen = false;
    this.via = 'PUSH';
    this.customerIds = [];
    this.filters = {};
    this.customersCount = null;
    this.resourceType = null;
    this.isSendingMessage = false;
    this.isSentSuccess = false;
    this.isSentWithError = false;
    this.notGotMessageUsers = [];
    this.message = null;
  }

  openCommunicationModal({
    via = 'PUSH',
    customerIds = [],
    filters = {},
    customersCount,
    resourceType,
  }: OpenCommunicationModalInfo) {
    this.setVia(via);
    this.setCustomerIds(customerIds);
    this.setFilters(filters);
    this.setIsModalOpen(true);
    this.setCustomersCount(customersCount);
    this.setResourceType(resourceType);
  }

  async sendMessage(
    message: SendMessageFormValues,
    files?: File[],
    sendVia?: SendMessageWayTypes,
  ) {
    this.handleStartSending();
    this.setMessage(message);

    try {
      const messageData: MessageData = await this.getMessageData(message, files, sendVia);

      const { sent: notGotMassageUsers } = await communicationService.sendMessage({
        messageData,
        filters: this.filters,
        resourceType: this.resourceType!,
      });

      if (notGotMassageUsers.length) {
        this.handleSentWithError(notGotMassageUsers);
      } else {
        this.handleSentSuccess();
      }
    } catch {
      // show general error modal
      this.closeCommunicationModal();
    }
  }

  private async getMessageData(
    message: SendMessageFormValues,
    files?: File[],
    sendVia?: SendMessageWayTypes,
  ) {
    let attachmentFileIds;

    if (files?.length) {
      const uploadFilesResults = await fileService.uploadFiles('CHAT_ATTACHMENT', files);
      attachmentFileIds = uploadFilesResults.map((file) => file.id);
    }

    return {
      ...message,
      via: sendVia ?? this.via,
      userIds: this.customerIds,
      attachmentFileIds,
    };
  }

  resentViaEmail() {
    this.setIsSentWithError(false);

    const title = this.message?.title ?? 'LifeBase Default Subject';

    this.sendMessage({ ...this.message!, title }, undefined, 'EMAIL');
  }

  setVia(via: SendMessageWayTypes) {
    this.via = via;
  }

  handleStartSending() {
    this.setIsModalOpen(false);
    this.setIsSendingMessage(true);
  }

  handleSentSuccess() {
    this.setIsSendingMessage(false);
    this.setIsSentSuccess(true);
  }

  handleSentWithError(notGotMessageUsers: NotGotMessageUser[]) {
    this.setNotGotMessageUsers(notGotMessageUsers);
    this.setIsSendingMessage(false);
    this.setIsSentWithError(true);
  }

  private setCustomerIds(customerIds: number[]) {
    this.customerIds = customerIds;
  }

  private setIsModalOpen(isModalOpen: boolean) {
    this.isModalOpen = isModalOpen;
  }

  private setFilters(filters: Record<string, any>) {
    this.filters = filters;
  }

  private setCustomersCount(customersCount: number) {
    this.customersCount = customersCount;
  }

  private setResourceType(resourceType: SendMessageResourceType) {
    this.resourceType = resourceType;
  }

  private setIsSendingMessage(isSendingMessage: boolean) {
    this.isSendingMessage = isSendingMessage;
  }

  private setIsSentSuccess(isSentSuccess: boolean) {
    this.isSentSuccess = isSentSuccess;
  }

  private setIsSentWithError(isSentWithError: boolean) {
    this.isSentWithError = isSentWithError;
  }

  private setNotGotMessageUsers(notGotMessageUsers: NotGotMessageUser[]) {
    this.notGotMessageUsers = notGotMessageUsers;
  }

  private setMessage(message: SendMessageFormValues) {
    this.message = message;
  }
}

const communicationModalStore = new CommunicationModalStore();

resetAllStore.addResetMethod(communicationModalStore.closeCommunicationModal);

export { communicationModalStore, CommunicationModalStore };
