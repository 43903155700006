import React from 'react';
import PropTypes from 'prop-types';

import { complianceMealTimesConfig } from '../../constants/complianceMealTimesConfig';
import { GoalCell } from './GoalCell/GoalCell';
import { ScoreCell } from './ScoreCell/ScoreCell';

const ComplianceDataCells = ({ complianceInfo, isInteractive, customerId }) => {
  const { water, sodium, mealdayId } = complianceInfo;

  return (
    <>
      {complianceMealTimesConfig.map((meal) => (
        <ScoreCell
          key={meal.id}
          score={complianceInfo[meal.dbName]}
          mealDayId={mealdayId}
          meal={meal.dbName}
          isInteractive={isInteractive}
          customerId={customerId}
        />
      ))}
      <GoalCell actual={water.current} goal={water.goal} isInteractive={isInteractive} />
      <GoalCell
        actual={sodium.current}
        goal={sodium.goal}
        isInteractive={isInteractive}
        goalIsLess
      />
    </>
  );
};

ComplianceDataCells.propTypes = {
  complianceInfo: PropTypes.shape({
    mealdayId: PropTypes.number,
    breakfast: PropTypes.number,
    daily: PropTypes.number,
    dinner: PropTypes.number,
    lunch: PropTypes.number,
    snack1: PropTypes.number,
    snack2: PropTypes.number,
    snack3: PropTypes.number,
    water: PropTypes.shape({ current: PropTypes.number, goal: PropTypes.number }),
    sodium: PropTypes.shape({ current: PropTypes.number, goal: PropTypes.number }),
  }),
  isInteractive: PropTypes.bool,
  customerId: PropTypes.number,
};

export { ComplianceDataCells };
