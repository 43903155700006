import React from 'react';
import PropTypes from 'prop-types';

import { Button, Chip } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import classes from './UploadFiles.modules.scss';
import { LbModal } from '../../modals/LbModal/LbModal';
import { useUploadFiles } from '../../../hooks/useUploadFiles';

const UploadFiles = ({ register, files, setFiles }) => {
  const { error, setFilesHandler, deleteFileHandler, errorModalBtnHandler } =
    useUploadFiles(files, setFiles);

  return (
    <>
      <div className={classes.filesContainer}>
        {files?.length > 0 &&
          files.map((item, index) => (
            <div key={item.lastModified}>
              <Chip
                className={classes.chip}
                icon={<FileCopyIcon />}
                label={item.name}
                onDelete={() => deleteFileHandler(index)}
              />
            </div>
          ))}
      </div>
      <label htmlFor="files">
        <Button
          startIcon={<AttachFileIcon />}
          variant="outlined"
          className={classes.uploadBtn}
          component="span"
        >
          <input
            {...register('files')}
            onChange={setFilesHandler}
            id="files"
            type="file"
            multiple
          />
          Attach Files
        </Button>
      </label>
      <LbModal
        open={!!error}
        title="Warning"
        text={`${error} This files was automatically unselected.`}
        primaryBtnConfig={{ text: 'Ok', onClick: errorModalBtnHandler }}
      />
    </>
  );
};

UploadFiles.propTypes = {
  register: PropTypes.func,
  files: PropTypes.array,
  setFiles: PropTypes.func,
};

export { UploadFiles };
