import React from 'react';

import { angularize } from 'react-in-angularjs';
import { FormProvider, useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';

import classes from './NgzBusinessInfoTab.module.scss';
import { SystemProviders } from '../../../../../shared/providers';
import {
  EntityBusinessInfoFromValue,
  EntityBusinessInfoInputs,
} from '../../../../components/EntityBusinessInfoInputs/EntityBusinessInfoInputs';
import { YesNoBtnRow } from '../../../../../shared/components/buttons';
import { entityService } from '../../../../services/entityService';
import { getEntityBusinessInfoFormValue } from './utils/getEntityBusinessInfoFormValue';

type NgzBusinessInfoTabProps = {
  entityId: string;
};

const NgzBusinessInfoTab = ({ entityId }: NgzBusinessInfoTabProps) => {
  const formMethods = useForm<EntityBusinessInfoFromValue>({
    defaultValues: async () => getEntityBusinessInfoFormValue(entityId),
  });
  const { handleSubmit, formState } = formMethods;

  const handleFormSubmit = handleSubmit(async ({ businessInfo }) => {
    await entityService.updateEntityBusinessInfo(entityId, businessInfo);
    enqueueSnackbar('Business info updated', { variant: 'success' });
  });

  return (
    <SystemProviders>
      <FormProvider {...formMethods}>
        <EntityBusinessInfoInputs mode="EDIT" />
      </FormProvider>
      <YesNoBtnRow
        yesText="Save"
        yesHandler={handleFormSubmit}
        className={classes.buttonRow}
        yesDisabled={!formState.isDirty}
      />
    </SystemProviders>
  );
};

angularize(
  NgzBusinessInfoTab,
  'ngzBusinessInfoTab',
  angular.module('app.entityManagement'),
  { entityId: '<' },
);
