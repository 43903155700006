import React from 'react';

import classes from './TableHeader.module.scss';
import { Cell } from '../Cell/Cell';
import { Row } from '../Row/Row';

const TableHeader = () => {
  return (
    <Row className={classes.tableHeader}>
      <Cell text="Date" />
      <Cell text="Consumed / Goal" />
      <Cell text="Gap" />
      <Cell text="Last Drink" />
      <Cell text="Total Score" />
    </Row>
  );
};

export { TableHeader };
