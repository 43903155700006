'use strict';

angular.module('app').controller('ComplianceCoachesController', ComplianceCoachesController);

ComplianceCoachesController.$inject = ['$scope', '$routeParams', 'ModalService', 'ComplianceCoachesService', 'CustomersFilterService'];

function ComplianceCoachesController($scope, $routeParams, ModalService, ComplianceCoachesService, CustomersFilterService) {

    var vm = this;
    vm.selectedComplianceCoach = undefined;
    vm.assignedComplianceCoaches = [];
    vm.availableCoaches = [];
    vm.queryName = "";
    vm.spinner = { active: false };
    vm.pagination = {};
    vm.pagesCount = 0;

    vm.getComplianceCoachesAssignedToUser = getComplianceCoachesAssignedToUser;
    vm.showReassignComplianceCoachDialog = showReassignComplianceCoachDialog;
    vm.deleteComplianceCoach = deleteComplianceCoach;
    vm.getAllAvailableCoaches = getAllAvailableCoaches;
    vm.loadCoachesByQueryName = loadCoachesByQueryName;
    vm.assignComplianceCoachToCustomer = assignComplianceCoachToCustomer;

    function getComplianceCoachesAssignedToUser(page) {
        vm.spinner.active = true;
        ComplianceCoachesService.getComplianceCoachesForCustomer($routeParams.id, {page: page}).then(function (result) {
            vm.spinner.active = false;
            if (result.status === 200) {
                vm.assignedComplianceCoaches = result.data.items;
                vm.pagesCount = result.data.page.count;
                if (result.data.page.currentPage === 0) {
                    vm.pagination.reset(vm.pagesCount);
                }
            }
        })
    }

    function showReassignComplianceCoachDialog(selectedCoach, index) {
        ModalService.confirm('Are you sure you want to delete ' + selectedCoach.name + '?', vm.deleteComplianceCoach, index, null, 'Cancel', 'Delete')
    }

    function deleteComplianceCoach(index) {
        vm.spinner.active = true;
        ComplianceCoachesService.deleteComplianceCoachFromCustomer(vm.assignedComplianceCoaches[index].id).then(function (result) {
            vm.spinner.active = false;
            if (result.status === 200) {
                vm.assignedComplianceCoaches.splice(index, 1);
                vm.getAllAvailableCoaches();
            }
        })
    }

    function getAllAvailableCoaches() {
        vm.availableCoaches = [];
        CustomersFilterService.getCoachesForReassigning($scope.customerDetails.gymId, true).then(function (result) {
            if (result.status === 200) {
                vm.availableCoaches = result.data.names;

                var index = vm.availableCoaches.findIndex(function (coach) {
                    return coach.id === $scope.customerDetails.coach.id;
                });

                if (index !== -1) {
                    vm.availableCoaches.splice(index, 1);
                }
            }
        })
    }

    function loadCoachesByQueryName() {
        return (vm.availableCoaches && vm.queryName !== undefined) ? vm.availableCoaches.filter(function (coach) {
            return coach.name.toLowerCase().indexOf(vm.queryName.toLowerCase()) !== -1 ||
                coach.email.toLowerCase().indexOf(vm.queryName.toLowerCase()) !== -1;
        }) : null
    }

    function assignComplianceCoachToCustomer(selectedComplianceCoach) {
        if (selectedComplianceCoach && selectedComplianceCoach.id) {
            vm.spinner.active = true;
            ComplianceCoachesService.addComplianceCoachToCustomer($routeParams.id, selectedComplianceCoach.id).then(function (result) {
                vm.spinner.active = false;
                vm.queryName = undefined;
                vm.selectedComplianceCoach = undefined;
                if (result.status === 200) {
                    vm.assignedComplianceCoaches.unshift(result.data);
                    vm.getAllAvailableCoaches();
                }
            })
        }
    }
}