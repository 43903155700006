import React, { useEffect } from 'react';

import { observer } from 'mobx-react';

import { FoodScoreTable } from '../../components/FoodScoreTable/FoodScoreTable';
import { foodScoreStore } from '../../../../stores/foodScoreStore';

const WorkingTable = observer(() => {
  const { workingTableData, getWorkingTableData } = foodScoreStore;

  useEffect(() => {
    getWorkingTableData();
  }, []);

  const isShowWorkingTable = !!workingTableData?.content;

  return (
    isShowWorkingTable && (
      <FoodScoreTable tableData={workingTableData} getTableData={getWorkingTableData} />
    )
  );
});

export { WorkingTable };
