const autoNgTemplateLoaderTemplate1 = require('./location-metrics.template.html');

'use strict';

angular
    .module('app')
    .directive('locationMetrics', locationMetrics);

locationMetrics.$inject = [];

function locationMetrics() {
    return {
        restrict: 'E',
        scope: {},
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: 'LocationMetricsController',
        controllerAs: 'vm'
    };
}
