import React from 'react';

import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react';
import moment from 'moment';

import { PageTitle } from '../../../shared/components/ui-components';
import { DateRangePicker } from '../../../shared/components/form-components';
import { PaymentHistoryList } from './PaymentHistoryList/PaymentHistoryList';
import { RevenuePerformanceSummary } from './RevenuePerformanceSummary/RevenuePerformanceSummary';
import { useAutoSubmitForm } from '../../../shared/hooks/useAutoSubmitForm';
import { RevenueDateRangeFormValues } from './DetailedRevenue.settings';
import { useRevenueDateRange } from './hooks/useRevenueDateRange';
import { dateMonthFormat, oldDateFormat } from '../../../shared/constants/apiDateFormat';
import { getDefaultDateRange } from './utils/getDefaultDateRange';
import { getFormattedDate } from '../../../shared/utils/dateUtils';

type DetailedRevenueProps = {
  entityId: number;
  entityRegistrationDate: string | null; // oldDateFormat
};

const DetailedRevenue = observer(
  ({ entityId, entityRegistrationDate }: DetailedRevenueProps) => {
    const defaultDateRange = getDefaultDateRange(entityRegistrationDate);

    const { control, watch, handleSubmit } = useForm<RevenueDateRangeFormValues>({
      shouldFocusError: false,
      mode: 'onBlur',
      defaultValues: defaultDateRange,
    });

    const { dateRange, applyDateRange } = useRevenueDateRange(defaultDateRange);

    useAutoSubmitForm(handleSubmit(applyDateRange), watch);

    const minDate = entityRegistrationDate
      ? moment(getFormattedDate(entityRegistrationDate, dateMonthFormat, oldDateFormat))
      : undefined;

    return (
      <div>
        <PageTitle title="Detailed Revenue">
          <DateRangePicker
            control={control}
            size="medium"
            variant="outlined"
            mode="month"
            startPickerProps={{
              name: 'firstMonth',
              label: 'First month',
              rules: { required: 'Start date is required' },
              minDate,
            }}
            endPickerProps={{
              name: 'lastMonth',
              label: 'Last month',
              disableFuture: true,
              rules: { required: 'End date is required' },
            }}
            dataTestId="date-range-picker"
          />
        </PageTitle>
        <RevenuePerformanceSummary entityId={entityId} dateRange={dateRange} />
        <PaymentHistoryList entityId={entityId} dateRange={dateRange} />
      </div>
    );
  },
);

export { DetailedRevenue };
