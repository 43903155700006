import React from 'react';

import { GridTableCell } from '../../../../../shared/components/table-components';
import { getPriceOrDash } from '../../../../utils/getPriceOrDash/getPriceOrDash';

type PriceCellProps = {
  price: number;
};

const PriceCell = ({ price }: PriceCellProps) => {
  return <GridTableCell>{getPriceOrDash(price)}</GridTableCell>;
};

export { PriceCell, PriceCellProps };
