import React from 'react';
import { angularize } from 'react-in-angularjs';
import { Container, Paper } from '@mui/material';

import classes from './NgzSetNewPassword.module.scss';
import { SystemProviders } from '../../../shared/providers';
import { RedirectToMobileApp } from './containers/RedirectToMobileApp/RedirectToMobileApp';
import { RedirectToWebApp } from './containers/RedirectToWebApp/RedirectToWebApp';
import { SetPasswordForm } from './containers/SetPasswordForm/SetPasswordForm';
import { useStepper } from '../../../shared/hooks/useStepper';
import { NgzPageNotFound } from '../../../shared/containers/NgzPageNotFound/NgzPageNotFound';
import { getAccessParamsFromUrl } from '../../../shared/utils/apiUtils';
import { getRouteParam } from '../../../shared/utils/angularUtils';

const NgzSetNewPassword = () => {
  const { activeStep, goToNextStep } = useStepper(0);

  const { accessParams, allParamsExist } = getAccessParamsFromUrl();
  const redirectType = getRouteParam('redirectType');

  if (!allParamsExist) {
    return <NgzPageNotFound />;
  }

  const SuccessRedirect =
    redirectType === 'WEB' ? <RedirectToWebApp /> : <RedirectToMobileApp />;

  const stepsComponent = [
    <SetPasswordForm goToNextStep={goToNextStep} accessParams={accessParams} />,
    SuccessRedirect,
  ];

  return (
    <SystemProviders>
      <Container className={classes.block}>
        <Paper className={classes.scholasticSetNewPassword} variant="roundedBox">
          {stepsComponent[activeStep]}
        </Paper>
      </Container>
    </SystemProviders>
  );
};

angularize(NgzSetNewPassword, 'ngzSetNewPassword', angular.module('app'), {});

export default NgzSetNewPassword;
