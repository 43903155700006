import { ValueOfKey } from '../../utils-types';

// Use REGULAR_MEAL_NAME and WORKOUT_MEAL_NAME instead
/** @deprecated */
export const OLD_MEAL_NAMES = {
  SNACK1: 'snack1',
  SNACK2: 'snack2',
  SNACK3: 'snack3',
  PRE_WORKOUT: 'preworkout',
  POST_WORKOUT: 'postworkout',
  BREAKFAST: 'breakfast',
  LUNCH: 'lunch',
  Dinner: 'dinner',
};

export const REGULAR_MEAL_NAME = {
  breakfast: 'breakfast',
  snack1: 'snack1',
  lunch: 'lunch',
  snack2: 'snack2',
  dinner: 'dinner',
  snack3: 'snack3',
} as const;

export type RegularMealName = ValueOfKey<typeof REGULAR_MEAL_NAME>;

export const WORKOUT_MEAL_NAME = {
  preWorkout: 'preWorkout',
  postWorkout: 'postWorkout',
} as const;

export type WorkoutMealName = ValueOfKey<typeof WORKOUT_MEAL_NAME>;

export type MealName = RegularMealName | WorkoutMealName;
