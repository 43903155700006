import React, { ReactNode } from 'react';

import { Radio } from '@mui/material';
import { Control, useController } from 'react-hook-form';
import classNames from 'classnames';

import classes from './SubscriptionTypeBox.module.scss';
import { InfoBox } from '../../../../../shared/components/ui-components';
import { TitleWithDescription } from '../../../../../shared/components/text-formation-components';
import { LbFormControlLabel } from '../../../../../shared/components/form-components';
import { SubscriptionType } from '../../../../../shared/constants/user-info/subscription';
import { CreateEntitySubscriptionFormValues } from '../../CreateEntitySubscriptionModal.settings';

type SubscriptionTypeBoxProps = {
  control: Control<CreateEntitySubscriptionFormValues>;
  subscriptionType: SubscriptionType;
  title: string;
  description: string;
  children: ReactNode;
};

const SubscriptionTypeBox = ({
  control,
  subscriptionType,
  title,
  description,
  children,
}: SubscriptionTypeBoxProps) => {
  const {
    field: { value, onChange },
  } = useController({
    control,
    name: 'subscriptionType',
    rules: { required: 'Select type of subscription' },
  });

  const isSelected = value === subscriptionType;

  const boxClasses = classNames(classes.box, { [classes.selected]: isSelected });

  const Label = <TitleWithDescription title={title} description={description} />;

  const BoxTitle = (
    <LbFormControlLabel
      label={Label}
      control={
        <Radio
          checked={isSelected}
          onChange={() => {
            onChange(subscriptionType);
          }}
          color="primary"
        />
      }
      align="start"
    />
  );
  return (
    <InfoBox
      title={BoxTitle}
      variant="innerBox"
      hideConfig={{ show: isSelected }}
      className={boxClasses}
      customMargin
    >
      {children}
    </InfoBox>
  );
};

export { SubscriptionTypeBox };
