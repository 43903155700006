import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';
import { observer } from 'mobx-react';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';

import classes from './TitleRow.module.scss';
import { TabTitleRow } from '../../../../components/TabTitleRow/TabTitleRow';
import { customerActiveCalendarStore } from '../../../../../../stores/customerActiveCalendarStore';
import { DateSubTitle } from './DateSubTitle/DateSubTitle';
import { activeCalendarService } from '../../../../../../services/activeCalendarService';
import { printFile } from '../../../../../../../shared/utils/apiUtils';

const { getScheduleFile } = activeCalendarService;

const TitleRow = observer(({ onEdit, customerId }) => {
  const { isActiveCalendarCreate } = customerActiveCalendarStore;

  const handlerPrint = async () => {
    const { fileUrl } = await getScheduleFile(customerId);

    printFile(fileUrl);
  };

  const btnText = isActiveCalendarCreate ? 'Edit' : 'Create';

  return (
    <TabTitleRow title="Active Calendar" subTitle={<DateSubTitle />}>
      {isActiveCalendarCreate && (
        <Button
          variant="outlined"
          onClick={handlerPrint}
          startIcon={<PrintRoundedIcon />}
          className={classes.printBtn}
        >
          Print
        </Button>
      )}
      <Button variant="contained" onClick={onEdit}>
        {btnText}
      </Button>
    </TabTitleRow>
  );
});

TitleRow.propTypes = {
  onEdit: PropTypes.func,
  customerId: PropTypes.string,
};

export { TitleRow };
