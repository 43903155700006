'use strict';

angular.module('app.membersAdded').controller('MembersAddedController', MembersAddedController);

MembersAddedController.$inject = ['StatsService', 'utilService'];

function MembersAddedController(StatsService, utilService) {
    var vm = this;
    vm.endDate = new Date();
    vm.startDate = new Date(new Date().setDate(vm.endDate.getDate() - 30));
    vm.startIsOpen = false;
    vm.endIsOpen = false;
    vm.allEntities = {};
    vm.prms = {entity: '', sort: 'desc', sortField: 'members'};
    vm.spinner = {active: false};

    vm.filterEntities = filterEntities;
    vm.sortBy = sortBy;
    vm.formatDate = formatDate;
    vm.goTo = utilService.goTo;

    vm.filterEntities();

    function filterEntities() {
        vm.spinner.active = true;
        vm.prms.startDate = vm.formatDate(vm.startDate, 'MM-DD-YYYY');
        vm.prms.endDate = vm.formatDate(vm.endDate, 'MM-DD-YYYY');

        StatsService.getStatsMembersAdded(vm.prms).then(function (result) {
            vm.spinner.active = false;
            if (result.status === 200) {
                vm.allEntities = result.data;
                while (vm.allEntities.entities.length < 6) {
                    vm.allEntities.entities.push({});
                }
            }
        });
    }

    function sortBy(sort, sortField) {
        vm.prms.sort = sort;
        vm.prms.sortField = sortField;
        vm.filterEntities();
    }

    function formatDate(date, format) {
        return utilService.formatDate(date, format);
    }
}
