import React from 'react';

import { Container } from '@mui/material';
import { observer } from 'mobx-react';
import { angularize } from 'react-in-angularjs';

import { SystemProviders } from '../../../shared/providers';
import { DetailedRevenue } from '../../containers/DetailedRevenue/DetailedRevenue';
import { loggedInUserDataStore } from '../../../shared/stores/loggedInUserDataStore';

const NgzDetailedRevenuePage = observer(() => {
  const { getEntityId, loggedInUser } = loggedInUserDataStore;

  const entityId = getEntityId();

  if (!loggedInUser) {
    return null;
  }

  return (
    <SystemProviders>
      <Container>
        <DetailedRevenue
          entityId={entityId}
          entityRegistrationDate={loggedInUser.registrationDate}
        />
      </Container>
    </SystemProviders>
  );
});

angularize(
  NgzDetailedRevenuePage,
  'ngzDetailedRevenuePage',
  angular.module('app.payment'),
  {},
);

export { NgzDetailedRevenuePage };
