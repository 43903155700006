import { ValueOfKey } from '../../utils-types';

export const userRoles = {
  SUPERUSER: 'SUPERUSER',
  ADMIN: 'ADMIN',
  AGENT: 'AGENT',
  CUSTOMER: 'CUSTOMER',
  FOOD_ADMIN: 'FOOD_ADMIN',
} as const;

export type UserRoleTypes = ValueOfKey<typeof userRoles>;
