import { FeeModule } from '../../../../constants/feeModule';
import { CreatedPaymentBundleInfo } from '../../../../interfaces/paymentBundleInfo';
import { FeeModuleSelectFormValues } from '../../FeeModuleWidget.settings';

// Transform FeeModuleSelectFormValues to CreatedPaymentBundleInfo, replace true to 0 and delete fields with false

export const getCreatedPaymentBundleInfo = (
  feeModulesValue: FeeModuleSelectFormValues['feeModules'],
): CreatedPaymentBundleInfo => {
  const createdPaymentBundleInfo: CreatedPaymentBundleInfo = {};

  Object.entries(feeModulesValue).forEach(([key, value]) => {
    const fixedKey = key as FeeModule;

    if (value) {
      createdPaymentBundleInfo[fixedKey] = 0;
    }
  });

  return createdPaymentBundleInfo;
};
