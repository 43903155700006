import { isEmptyValue } from '../isEmptyValue/isEmptyValue';
import { isObject } from '../isObject/isObject';

type AllFieldExist<T> = T extends Record<string, any>
  ? { [K in keyof T]: AllFieldExist<T[K]> }
  : NonNullable<T>;

export const isAllFieldExist = <T>(value: T): value is AllFieldExist<T> => {
  if (isObject(value)) {
    return Object.values(value).every(isAllFieldExist);
  } else if (Array.isArray(value)) {
    return value.every(isAllFieldExist);
  }
  return !isEmptyValue(value);
};
