import React from 'react';

import classes from './DiscountDescription.module.scss';
import { getPriceView } from '../../../../../../shared/utils/stringUtils';

type DiscountDescriptionProps = {
  discountPercent: number;
  oldPrice: number | null;
};

const DiscountDescription = ({ discountPercent, oldPrice }: DiscountDescriptionProps) => {
  if (!oldPrice) {
    return null;
  }

  return (
    <span>
      (<span className={classes.oldPrice}>{getPriceView(oldPrice)}</span>
      <span> - {discountPercent}% discount</span>)
    </span>
  );
};

export { DiscountDescription };
