import React from 'react';

import classes from './BeyondNutrients.module.scss';
import { NUTRIENTS_ENUM } from '../../../../shared/constants/NUTRIENTS_ENUM';
import { getEnumArray } from '../../../../shared/utils/jsUtils';
import { processBeyondNutrients } from './utils/processBeyondNutrients';
import { processMeasurement } from '../../../../shared/utils/mealUtils';

const BeyondNutrients = ({ beyond }) => {
  const fullNutrients = getEnumArray(NUTRIENTS_ENUM);

  return fullNutrients.map((nutrient) => {
    const isFiber = nutrient === NUTRIENTS_ENUM.FIBER;

    if (isFiber) {
      return <span key={nutrient} />;
    }

    const { value, overUnderText } = processBeyondNutrients(beyond, nutrient);
    const measurement = processMeasurement(nutrient);

    return (
      <div key={nutrient} className={classes.beyondText}>
        <span>{value}</span>
        <span>{measurement}</span>
        <span className={classes.overUnderText}>{overUnderText}</span>
      </div>
    );
  });
};

export { BeyondNutrients };
