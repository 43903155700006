(function () {
    'use strict';

    angular.module('app.termsAndConditions')
        .controller('TermsAndConditionsController', TermsAndConditionsController);

    TermsAndConditionsController.$inject = ['$location', '$routeParams', 'AuthService', 'TermsAndConditionService'];

    function TermsAndConditionsController($location, $routeParams, AuthService, TermsAndConditionService) {
        var vm = this;
        vm.termsHasRead = false;
        vm.isSupplementUser = $location.path().indexOf("/supplement-user") > -1;

        vm.signOut = signOut;
        vm.login = login;
        vm.readTermsAndConditions = readTermsAndConditions;

        function login() {
            if (vm.termsHasRead) {
                TermsAndConditionService.readTermsAndConditionVersion();
            }
        }

        function signOut() {
            AuthService.signOut();
        }

        function readTermsAndConditions() {
            TermsAndConditionService.readTermsAndConditionVersionBySupplementUser($routeParams.accessData);
        }
    }
})();
