import React from 'react';

import classNames from 'classnames';
import { Paper, Radio } from '@mui/material';

import classes from './CoachRow.module.scss';
import { Coach } from '../../../../../../interfaces/coachList/CoachListInfo';
import { AvatarIcon } from '../../../../../../../shared/components/ui-components';

type CoachRowProps = {
  coach: Coach;
  selectedCoachId?: number | null;
  onClick: (coach: Coach) => void;
};

const CoachRow = ({ coach, selectedCoachId, onClick }: CoachRowProps) => {
  const checked = selectedCoachId === coach.coachId;

  const rowClasses = classNames(classes.row, {
    [classes.checkedRow]: checked,
  });

  const handleClick = () => {
    onClick(coach);
  };

  return (
    <Paper
      className={rowClasses}
      variant="roundedBox"
      onClick={handleClick}
      data-test-id="coach-row"
    >
      <div className={classes.coachCell}>
        <Radio checked={checked} />
        <AvatarIcon userName={coach.coachName} avatarImgLink={coach.coachAvatar} />
        <div> {coach.coachName}</div>
      </div>
    </Paper>
  );
};

export { CoachRow };
