import React from 'react';

import { Button, InputAdornment } from '@mui/material';
import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

import classes from './TitleFilterRow.module.scss';
import { LbInput, LbSelect } from '../../../../shared/components/form-components';
import { EntitySubscriptionsFilterFormValue } from '../../../interfaces/entitySubscriptionFilters';
import { newFlow2024SubscriptionTypeOptionsWithAll } from '../../../../shared/constants/user-info/subscription';
import {
  adminSubscriptionsManagementStore,
  superSubscriptionsManagementStore,
} from '../../../stores/entitySubscriptionsManagementStore';
import { useAutoSubmitForm } from '../../../../shared/hooks/useAutoSubmitForm';

type TitleFilterRowProps = {
  toggleShowCreateSubscriptionModal: () => void;
};

const TitleFilterRow = observer(
  ({ toggleShowCreateSubscriptionModal }: TitleFilterRowProps) => {
    const { control, handleSubmit, watch } =
      useForm<EntitySubscriptionsFilterFormValue>();

    useAutoSubmitForm(
      handleSubmit((data) => {
        adminSubscriptionsManagementStore.filterMethods.applyFilters(data);
        superSubscriptionsManagementStore.filterMethods.applyFilters(data);
      }),
      watch,
      ['generalFilter.name'],
    );

    const generalInputProps = { control, variant: 'outlined', size: 'small' } as const;

    return (
      <div className={classes.titleRow}>
        <h3 className={classes.title}>Subscription management</h3>
        <div className={classes.controlBlock}>
          <LbInput
            {...generalInputProps}
            name="generalFilter.name"
            placeholder="Search by name"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchRoundedIcon fontSize="large" color="tertiary" />
                </InputAdornment>
              ),
            }}
          />
          <LbSelect
            {...generalInputProps}
            name="generalFilter.subscriptionType"
            options={newFlow2024SubscriptionTypeOptionsWithAll}
            displayEmpty
          />
          <Button
            onClick={toggleShowCreateSubscriptionModal}
            variant="contained"
            size="small"
          >
            New Subscription
          </Button>
        </div>
      </div>
    );
  },
);

export { TitleFilterRow, TitleFilterRowProps };
