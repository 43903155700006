const autoNgTemplateLoaderTemplate1 = require('./life-base-fees.template.html');

'use strict';

angular
    .module('app')
    .directive('lifeBaseFees', lifeBaseFees);

lifeBaseFees.$inject = [];

function lifeBaseFees() {
    return {
        restrict: 'E',
        scope: {
            createEntity: '=?',
            spinner: '=',
            next: '&onNext',
            entityId: '<?',
            isActiveCalendarAvailable: '<?',
            entityType: '<?'
        },
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: 'LifeBaseFeesController',
        controllerAs: 'vm'
    };
}
