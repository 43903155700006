import React from 'react';

import { Control } from 'react-hook-form';

import classes from './InbodyInfo.module.scss';
import { LbInput, LbSelect } from '../../../../../shared/components/form-components';
import {
  inbodyTypeOptions,
  INBODY_TYPES,
} from '../../../../../shared/constants/inbody-info/InbodyType';
import { deleteOptionsByValues } from '../../../../../shared/utils/arrayUtils';
import { EntityInbodyDataFormValue } from '../../EntityInbodyDataConfig.settings';

const availableInbodyTypeOptions = deleteOptionsByValues(inbodyTypeOptions, [
  INBODY_TYPES.NON_INBODY,
]);

type InbodyInfoProps = {
  control: Control<EntityInbodyDataFormValue>;
};

const InbodyInfo = ({ control }: InbodyInfoProps) => {
  return (
    <div className={classes.inputRow}>
      <LbSelect
        control={control}
        options={availableInbodyTypeOptions}
        name="supportedInbodyType"
        label="InBody Type"
        variant="outlined"
        size="small"
        className={classes.input}
        rules={{ required: 'InBody Type required when InBody selected' }}
      />
      <LbInput
        control={control}
        name="inbodySerialNumber"
        label="Serial number"
        variant="outlined"
        size="small"
        className={classes.input}
      />
    </div>
  );
};

export { InbodyInfo, InbodyInfoProps };
