import type { ChartOptions } from 'chart.js';

import { palette } from '../../../shared/theme/palette';

export const chartOption: ChartOptions<'bar'> = {
  responsive: true,
  aspectRatio: 21 / 9,
  plugins: {
    tooltip: { enabled: false },
    legend: { display: false },
    datalabels: {
      font: { weight: 500, family: 'Lato', size: 12 },
      anchor: 'end',
      offset: 2,
      align: 'top',
    },
  },
  elements: {
    bar: {
      borderRadius: 5,
      borderSkipped: false,
    },
  },
  events: [],
  scales: {
    y: {
      stacked: false,
      max: 5,
      min: 0,
      ticks: { display: false, stepSize: 2.5 },
      border: { display: false, dash: [4, 4] },
      grid: { color: palette.natural[200] },
    },
    x: {
      border: { display: false },
      grid: { display: false },
      ticks: {
        color: palette.mainBlack,
        font: { weight: 500, size: 14, family: 'Lato' },
      },
    },
  },
};
