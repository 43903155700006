const autoNgTemplateLoaderTemplate1 = require('./sales-dashboard.template.html');

'use strict';

(function () {
    angular
        .module('app.salesDashboard', [])
        .config(salesDashboardConfig);

    salesDashboardConfig.$inject = ['$routeProvider'];

    function salesDashboardConfig($routeProvider) {
        $routeProvider
            .when('/sales-dashboard', {
                disableCache: true,
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'SalesDashboardController',
                controllerAs: 'vm',
                resolve: {
                    loginRequired: ['AuthService', function (AuthService) {
                        return AuthService.loginRequired();
                    }]
                }
            })
    }
})();
