import React from 'react';

import classes from './CompareScanTable.module.scss';
import { HeaderRow } from './HeaderRow/HeaderRow';
import { CompareMetricRow } from './CompareMetricRow/CompareMetricRow';
import { compareScansTableConfig } from './CompareScanTable.settings';
import { InbodyScanMetrics } from '../../../../../interfaces/customerInbodyData';
import { InbodyType } from '../../../../../../shared/constants/inbody-info/InbodyType';
import { inbodyEntryFieldConfig } from '../../../../../constants/inbodyEntryFieldConfig';
import { EmptyList } from '../../../../../../shared/components/ui-components';

type CompareScanTableProps = {
  startScan: InbodyScanMetrics | null;
  endScan: InbodyScanMetrics | null;
  inbodyType: Exclude<InbodyType, 'NON_INBODY'>;
};

const CompareScanTable = ({ startScan, endScan, inbodyType }: CompareScanTableProps) => {
  const isShowTable = startScan && endScan;

  const entryFieldConfig = inbodyEntryFieldConfig[inbodyType];

  return (
    <div className={classes.compareScanTable}>
      <HeaderRow />
      {isShowTable ? (
        compareScansTableConfig.map((config) => {
          if (!entryFieldConfig[config.configField]) {
            return null;
          }

          return (
            <CompareMetricRow
              key={config.label}
              metric={config.label}
              startValue={startScan[config.scanField]}
              endValue={endScan[config.scanField]}
            />
          );
        })
      ) : (
        <EmptyList
          show={!isShowTable}
          description="Select scans dates to compare the progress"
          size="small"
        />
      )}
    </div>
  );
};

export { CompareScanTable };
