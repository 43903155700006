import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import classes from './TextTruncate.module.scss';

const TextTruncate = ({ text, className }) => {
  const boxClasses = classNames(className, classes.slice);

  return <p className={boxClasses}>{text}</p>;
};

TextTruncate.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export { TextTruncate };
