import React from 'react';

import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import { childInformationStore } from '../../../../stores/childInformationStore';
import { SubscriptionBlock } from './SubscriptionBlock/SubscriptionBlock';
import { Header } from './Header/Header';
import { ButtonsRow } from '../../components/ButtonsRow/ButtonsRow';
import { stepperStore } from '../../../../stores/stepperStore';
import { preCheckInService } from '../../../../services/preCheckInService';

const Subscription = observer(() => {
  const { setChildRegisterRequestStatus } = preCheckInService;
  const { childInformation } = childInformationStore;
  const { activeStep, stepsCount, nextStep } = stepperStore;
  const subscriptions = toJS(childInformation.subscriptions.subscriptions) ?? [];
  const isPreFinish = stepsCount - 1 === activeStep;

  const yesHandler = async () => {
    if (isPreFinish) {
      await setChildRegisterRequestStatus(true);
      nextStep();
    } else {
      nextStep();
    }
  };

  const yesText = isPreFinish ? 'Finish' : 'Continue';

  return (
    <>
      <Header />
      {subscriptions.map((subscription) => (
        <SubscriptionBlock
          key={subscription.subscriptionID}
          subscription={subscription}
        />
      ))}
      <ButtonsRow yesHandler={yesHandler} yesText={yesText} />
    </>
  );
});

export { Subscription };
