import { Components } from '@mui/material';

export const MuiPaginationTheme: Components['MuiPagination'] = {
  defaultProps: {
    shape: 'rounded',
    hideNextButton: true,
    hidePrevButton: true,
    size: 'large',
    color: 'primary',
  },
  styleOverrides: {
    root: {
      button: {
        font: ' var(--title-18-bold)',

        '&.Mui-selected:hover': {
          backgroundColor: 'var(--primary-500)',
        },
      },
    },
  },
};
