'use strict';

angular.module('app').factory('MealScheduleService', MealScheduleService);

MealScheduleService.$inject = ['ApiService', 'utilService', 'ModalService'];

function MealScheduleService(ApiService, utilService, ModalService) {
    return {
        getWeeklyMealSchedule: function (id, prms) {
            var url = utilService.getLoggedInUserData().role === 'CUSTOMER' ? 'api/mealschedule' : 'api/coach/user/' + id + '/mealschedule';
            return ApiService.get(url, prms).then(function (res) {
                    return res;
                }, function (err) {
                    return err
                }
            )
        },
        updateWeeklyMealSchedule: function (id, data, prms) {
            var url = utilService.getLoggedInUserData().role === 'CUSTOMER' ? 'api/mealschedule' : 'api/coach/user/' + id + '/mealschedule';
            return ApiService.put(url, data, prms).then(function (res) {
                    return res;
                }, function (err) {
                    return err
                }
            )
        }
    };
}
