const autoNgTemplateLoaderTemplate1 = require('./compliance-coaches.template.html');

'use strict';

angular
    .module('app')
    .directive('complianceCoaches', complianceCoaches);

complianceCoaches.$inject = [];

function complianceCoaches() {
    return {
        restrict: 'E',
        scope: {
            customerDetails: '='
        },
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: 'ComplianceCoachesController',
        controllerAs: 'vm'
    };
}
