import { makeAutoObservable } from 'mobx';

import { FilterStore } from '../../shared/util-stores';
import { resetAllStore } from '../../shared/stores/resetAllStore';
import { paginationSmallListLimit } from '../../shared/constants/systemConstants';
import {
  PaymentHistoryFilterFormValues,
  PaymentHistoryFilters,
  PaymentHistoryRecord,
} from '../interfaces/paymentHistoryList';
import { detailedRevenueService } from '../services/detailedRevenueService';

class PaymentHistoryListStore {
  private entityId: number | null = null;
  paymentsList: PaymentHistoryRecord[] = [];
  filterMethods: FilterStore<PaymentHistoryFilterFormValues>;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    this.filterMethods = new FilterStore<PaymentHistoryFilterFormValues>(
      this.handleGetPaymentsList,
      { elementsPerPage: paginationSmallListLimit },
    );
  }

  setDefaultValues() {
    this.entityId = null;
    this.paymentsList = [];
    this.filterMethods.setDefaultValues();
  }

  private async handleGetPaymentsList(filterInfo: PaymentHistoryFilters) {
    if (!this.entityId) {
      throw new Error('entityId is not set');
    }

    const data = await detailedRevenueService.getPaymentHistoryList(
      this.entityId,
      filterInfo,
    );

    this.setPaymentsList(data.content);

    return data;
  }

  async getPaymentsList(entityId: number, filterInfo: PaymentHistoryFilterFormValues) {
    this.setEntityId(entityId);

    return this.filterMethods.applyFilters(filterInfo);
  }

  private setEntityId(entityId: number) {
    this.entityId = entityId;
  }

  private setPaymentsList(payments: PaymentHistoryRecord[]) {
    this.paymentsList = payments;
  }
}

const paymentHistoryListStore = new PaymentHistoryListStore();

resetAllStore.addResetMethod(paymentHistoryListStore.setDefaultValues);

export { paymentHistoryListStore, PaymentHistoryListStore };
