'use strict';

angular.module('app').factory('VideoService', VideoService);

VideoService.$inject = ['$mdDialog', 'ApiService', 'ModalService'];

function VideoService($mdDialog, ApiService, ModalService) {
    return {
        getAllVideos: function () {
            return ApiService.get('api/coach/videos').then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getVideosForCustomer: function () {
            return ApiService.get('api/devices/videos').then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        saveVideos: function (videos) {
            return ApiService.put('api/super/videos/save', videos).then(function (res) {
                ModalService.savedChanges();
                return res;
            }, function (err) {
                return err;
            });
        },
        deleteVideoById: function (id) {
            return ApiService.delete('api/super/video/' + id).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        compareVideos: function (a, b) {
            if (parseInt(a.position) < parseInt(b.position))
                return -1;
            if (parseInt(a.position) > parseInt(b.position))
                return 1;
            return 0;
        }
    };
}

