import React, { ReactNode } from 'react';

import { Paper, Radio } from '@mui/material';

import classNames from 'classnames';
import classes from './RadioCard.module.scss';

type RadioCardProps<T> = {
  value: T;
  checked: boolean;
  onClick: (value: T) => void;
  className?: string;
  children: ReactNode;
};

const RadioCard = <T,>({
  value,
  checked,
  onClick,
  className,
  children,
}: RadioCardProps<T>) => {
  const containerClasses = classNames(classes.container, className, {
    [classes.checkedContainer]: checked,
  });

  const handleClick = () => {
    if (onClick) {
      onClick(value);
    }
  };

  return (
    <Paper variant="roundedBox" className={containerClasses} onClick={handleClick}>
      <Radio checked={checked} value={value} />
      {children}
    </Paper>
  );
};

export { RadioCard };
