import React from 'react';
import PropTypes from 'prop-types';

import { getFormattedDate } from '../../../../../../shared/utils/dateUtils';
import { localDataFormat } from '../../../../../../shared/constants/apiDateFormat';

const DateCell = ({ date }) => {
  const formattedDate = date ? getFormattedDate(date, localDataFormat) : 'No Date';

  return <div>{formattedDate}</div>;
};

DateCell.propTypes = {
  date: PropTypes.string,
};

export { DateCell };
