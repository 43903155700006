import React from 'react';

import { useFormContext } from 'react-hook-form';
import { Button } from '@mui/material';

import { LbSelect } from '../../../../../../../components/form-components';
import { mealOptions } from './Meal.settings';
import { ComparisonInputs } from '../../ComparisonInputs/ComparisonInputs';
import { comparisonOptions } from '../../../../../constants/comparison';
import { CustomFilterFormValues } from '../../AddCustomFilterForm.settings';
import { getComparisonFilterValidateRule } from '../../utils/getComparisonFilterValidateRule';
import { useClearFilter } from '../../hooks/useClearFilter';
import { ModalInputBox, ModalInputGroup } from '../../../../../../../components/modals';

const Meal = () => {
  const { control, register } = useFormContext<CustomFilterFormValues>();
  const { disabled, handleClearFields } = useClearFilter('MEAL_SCORE', {
    comparison: '',
    from: '',
    lastDays: '',
    meal: '',
    to: '',
  });

  const validate = getComparisonFilterValidateRule('MEAL_SCORE');

  return (
    <ModalInputBox
      title="Meal"
      adornment={
        <Button
          size="small"
          variant="text"
          color="secondary"
          onClick={handleClearFields}
          disabled={disabled}
        >
          Clear
        </Button>
      }
    >
      <input
        {...register('filters.MEAL_SCORE.filterType', { value: 'MEAL_SCORE' })}
        hidden
      />
      <ModalInputGroup>
        <ModalInputGroup>
          <LbSelect
            control={control}
            size="small"
            variant="outlined"
            name="filters.MEAL_SCORE.meal"
            label="Type"
            options={mealOptions}
            rules={{ validate }}
          />
          <LbSelect
            control={control}
            size="small"
            variant="outlined"
            name="filters.MEAL_SCORE.comparison"
            options={comparisonOptions}
            label="Condition"
            rules={{ validate }}
          />
        </ModalInputGroup>
        <ComparisonInputs filterType="MEAL_SCORE" />
      </ModalInputGroup>
    </ModalInputBox>
  );
};

export { Meal };
