import { makeAutoObservable } from 'mobx';
import { StepperStore } from '../../shared/util-stores';
import { resetAllStore } from '../../shared/stores/resetAllStore';
import { PredefinedMealPlansInfo } from '../interfaces/PredefinedMealPlansInfo';
import { mealPlanService } from '../services/mealPlanService';

class SetupScholasticCustomerStore {
  stepperMethods: StepperStore;
  userId: number | null = null;
  predefinedMealPlansInfo: PredefinedMealPlansInfo | null = null;

  constructor() {
    this.stepperMethods = new StepperStore();
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.stepperMethods.setDefaultValues();
    this.userId = null;
    this.predefinedMealPlansInfo = null;
  }

  async getPredefinedMealPlansInfo() {
    const predefinedMealPlansInfo = await mealPlanService.getPredefinedMealPlans(
      this.userId!,
    );

    this.setPredefinedMealPlansInfo(predefinedMealPlansInfo);
  }

  setUserId(userId: number) {
    this.userId = userId;
  }

  private setPredefinedMealPlansInfo(predefinedMealPlansInfo: PredefinedMealPlansInfo) {
    this.predefinedMealPlansInfo = predefinedMealPlansInfo;
  }
}

const setupScholasticCustomerStore = new SetupScholasticCustomerStore();

resetAllStore.addResetMethod(setupScholasticCustomerStore.setDefaultValues);

export { setupScholasticCustomerStore, SetupScholasticCustomerStore };
