import { makeAutoObservable } from 'mobx';

import { FreeAccessInfo, FreeAccessMember } from '../interfaces/freeAccess';
import { resetAllStore } from '../../shared/stores/resetAllStore';
import { freeAccessService } from '../services/freeAccessService';
import { deleteItemByField } from '../../shared/utils/arrayUtils';

class FreeAccessStore {
  freeSeatsInfo: FreeAccessInfo = {
    freeSeatsCount: 0,
    freeSeatsUsed: 0,
  };
  freeAccessMembers: FreeAccessMember[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.freeSeatsInfo = {
      freeSeatsCount: 0,
      freeSeatsUsed: 0,
    };
    this.freeAccessMembers = [];
  }

  async getFreeAccessInfo(entityId: number) {
    const freeSeatsInfo = await freeAccessService.getFreeSeatsInfo(entityId);
    this.setFreeAccessInfo(freeSeatsInfo);
  }

  async getFreeAccessMembers(entityId: number) {
    const freeAccessMembers = await freeAccessService.getFreeAccessMembers(entityId);
    this.setFreeAccessMembers(freeAccessMembers);
  }

  async setFreeSeatsLimit(freeSeats: number, entityId: number) {
    const newFreeSeatsInfo = await freeAccessService.setFreeSeatsLimit(
      entityId,
      freeSeats,
    );

    this.setFreeAccessInfo(newFreeSeatsInfo);
  }

  async inviteFreeAccessMember(entityId: number, email: string) {
    const member = await freeAccessService.inviteFreeAccessMember(entityId, email);
    this.freeAccessMembers.push(member);
    this.setFreeSeatsUsed(this.freeAccessMembers.length);
  }

  async deleteFreeAccessMember(entityId: number, memberId: number) {
    await freeAccessService.deleteFreeAccessMember(entityId, memberId);
    this.setFreeAccessMembers(
      deleteItemByField(this.freeAccessMembers, { id: memberId }),
    );
    this.setFreeSeatsUsed(this.freeAccessMembers.length);
  }

  private setFreeAccessInfo = (freeSeatsInfo: FreeAccessInfo) => {
    this.freeSeatsInfo = freeSeatsInfo;
  };

  private setFreeAccessMembers = (freeAccessMembers: FreeAccessMember[]) => {
    this.freeAccessMembers = freeAccessMembers;
  };

  private setFreeSeatsUsed = (freeSeatsUsed: number) => {
    this.freeSeatsInfo.freeSeatsUsed = freeSeatsUsed;
  };
}

const freeAccessStore = new FreeAccessStore();
resetAllStore.addResetMethod(freeAccessStore.setDefaultValues);

export { freeAccessStore, FreeAccessStore };
