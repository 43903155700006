import React, { ReactNode } from 'react';

import classes from './VideoBlock.module.scss';

type VideoBlockProps = {
  title: ReactNode;
  description: ReactNode;
  videoUrl: string;
  videoName: string;
};

const VideoBlock = ({ title, description, videoUrl, videoName }: VideoBlockProps) => {
  return (
    <div className={classes.container}>
      <p className={classes.title}>{title}</p>
      <p className={classes.subTitle}>{description}</p>
      <iframe
        src={videoUrl}
        title={videoName}
        allowFullScreen
        className={classes.video}
      />
    </div>
  );
};

export { VideoBlock };
