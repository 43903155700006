import { QuestionInfo, QuestionWithAnswer } from '../../interfaces/QuestionnaireInfo';

export const convertUserAnswers = (
  questions: QuestionInfo[],
  answers: Record<string, string>,
): QuestionWithAnswer[] =>
  questions.map((question) => {
    const answer = answers[question.questionId];

    switch (question.fieldType) {
      case 'TEXT':
        return {
          questionId: question.questionId,
          answer: { answerId: question.answers[0].answerId, name: answer },
        };
      case 'RADIO':
      default:
        return {
          questionId: question.questionId,
          answer: { answerId: Number(answer) },
        };
    }
  });
