import React from 'react';

import { useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react';

import { MultipleComboBox } from '../../../../../../../../components/form-components';
import { useGroupList } from '../../../../../../../../../customer-group';
import { CustomFilterFormValues } from '../../../AddCustomFilterForm.settings';

const UserGroupFilter = observer(() => {
  const { control } = useFormContext<CustomFilterFormValues>();

  const { groupListOption } = useGroupList({ isRunGlobalSpinner: false });

  return (
    <MultipleComboBox
      control={control}
      size="small"
      variant="outlined"
      name="filters.PERSONAL_INFO.userGroupIds"
      label="User Group"
      options={groupListOption}
      limitTags={3}
    />
  );
});

export { UserGroupFilter };
