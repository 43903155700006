import { apiService } from '../../../services/apiService';
import { ResourceType } from '../constants/resourceType';
import { CustomFilterData } from '../interfaces/CustomFilterData';
import { CustomFilterInfo } from '../interfaces/CustomFilterInfo';

type GetCustomFiltersResponse = {
  customFilters: CustomFilterInfo[];
};

export const customFilterService = {
  async getCustomFilters(resourceType: ResourceType) {
    const resp = await apiService.get<GetCustomFiltersResponse>(
      `/api/data-filters/communication/${resourceType}/filters`,
    );

    return resp.data;
  },

  async createFilter(resourceType: ResourceType, filterData: CustomFilterData) {
    const resp = await apiService.post<CustomFilterInfo>(
      `/api/data-filters/communication/${resourceType}/filter`,
      filterData,
    );

    return resp.data;
  },

  async getFilterInfo(resourceType: ResourceType, filterId: number) {
    const resp = await apiService.get<CustomFilterData>(
      `/api/data-filters/communication/${resourceType}/filter/${filterId}`,
    );

    return resp.data;
  },

  async editFilter(
    resourceType: ResourceType,
    filterId: number,
    filterData: CustomFilterData,
  ) {
    const resp = await apiService.put<Required<CustomFilterData>>(
      `/api/data-filters/communication/${resourceType}/filter/${filterId}`,
      filterData,
    );

    return resp.data;
  },

  async deleteFilter(resourceType: ResourceType, filterId: number) {
    const resp = await apiService.delete<CustomFilterInfo>(
      `/api/data-filters/communication/${resourceType}/filter/${filterId}`,
    );

    return resp.data;
  },
};
