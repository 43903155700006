import { dateFormat } from '../../../../shared/constants/apiDateFormat';
import { getFormattedDate } from '../../../../shared/utils/dateUtils';
import { decimalToPercentage } from '../../../../shared/utils/mathUtils';
import { goalDateFormat } from '../../../constants/goalDateFormat';
import { GoalInfo } from '../../../interfaces/goal';

// transform goal info to format expected on frontend
export const getProcessedGoalInfo = (goalInfo: GoalInfo): GoalInfo => {
  const { targetDate, startDate, targetBodyFatPercent, progress, ...info } = goalInfo;

  return {
    ...info,
    startDate: getFormattedDate(startDate, dateFormat, goalDateFormat),
    targetDate: getFormattedDate(targetDate, dateFormat, goalDateFormat),
    targetBodyFatPercent: decimalToPercentage(targetBodyFatPercent),
    progress: progress && decimalToPercentage(progress),
  };
};
