import React, { useEffect } from 'react';

import { angularize } from 'react-in-angularjs';
import { observer } from 'mobx-react';

import { SystemProviders } from '../../../../../shared/providers';
import { CreateTest } from './CreateTest/CreateTest';
import { useModalToggle } from '../../../../../shared/hooks/useModalToggle';
import { medicalTestsStore } from '../../../../stores/medicalTestsStore';
import { TestsPlaceholder } from './TestsPlaceholder/TestsPlaceholder';
import { EntityTestsBox } from './EntityTestsBox/EntityTestsBox';

const NgzActiveCalendarEntityTestsTab = observer(() => {
  const [isCreateTestModalOpen, createTestModalHandler] = useModalToggle();

  const { isTestsDisabled, getTests } = medicalTestsStore;

  useEffect(() => {
    getTests();
  }, []);

  return (
    <SystemProviders>
      <CreateTest
        isModalOpen={isCreateTestModalOpen}
        modalHandler={createTestModalHandler}
      />
      {isTestsDisabled ? <TestsPlaceholder /> : <EntityTestsBox />}
    </SystemProviders>
  );
});

angularize(
  NgzActiveCalendarEntityTestsTab,
  'ngzActiveCalendarEntityTestsTab',
  angular.module('app'),
  {},
);

export default NgzActiveCalendarEntityTestsTab;
