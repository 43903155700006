type GetDisplayedElementsInfoProps = {
  page: number;
  elementsPerPage: number;
  totalElements: number;
};

type DisplayedElementsInfo = {
  startElement: number;
  endElement: number;
};

export const getDisplayedElementsInfo = ({
  page,
  elementsPerPage,
  totalElements,
}: GetDisplayedElementsInfoProps): DisplayedElementsInfo => {
  if (!totalElements) {
    return {
      startElement: 0,
      endElement: 0,
    };
  }

  const startElement = (page - 1) * elementsPerPage + 1;
  const endElement = Math.min(page * elementsPerPage, totalElements);

  return { startElement, endElement };
};
