import React, { useState } from 'react';

import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react';

import classes from './CommunicationForm.module.scss';
import { SendMessageFormValues } from '../../../interfaces/SendMessageData';
import { LbInput, TextArea } from '../../../../shared/components/form-components';
import { UploadFileButton, YesNoBtnRow } from '../../../../shared/components/buttons';
import { LbModal } from '../../../../shared/components/modals/LbModal/LbModal';
import { FileBox } from './FileBox/FileBox';
import { communicationModalStore } from '../../../stores/communicationModalStore';
import { useUploadFiles } from '../../../../shared/hooks/useUploadFiles';

type CommunicationFormProps = {
  withSubject?: boolean;
  uploadFilesEnabled?: boolean;
};

const CommunicationForm = observer(
  ({ withSubject, uploadFilesEnabled }: CommunicationFormProps) => {
    const [files, setFiles] = useState<File[]>([]);
    const { closeCommunicationModal, sendMessage } = communicationModalStore;

    const { control, handleSubmit } = useForm<SendMessageFormValues>();
    const { deleteFileHandler, error, errorModalBtnHandler, setFilesHandler } =
      useUploadFiles(files, setFiles);

    const onSubmit = async (message: SendMessageFormValues) => {
      sendMessage(message, files);
    };

    return (
      <>
        <form className={classes.formBox} onSubmit={handleSubmit(onSubmit)}>
          {withSubject && (
            <LbInput
              control={control}
              name="title"
              variant="outlined"
              label="Subject"
              rules={{
                required: 'Subject is required',
              }}
            />
          )}
          <TextArea
            control={control}
            name="message"
            rows={12}
            label="Description"
            rules={{
              required: 'Description is required',
            }}
          />
          {uploadFilesEnabled && (
            <FileBox files={files} onDeleteFile={deleteFileHandler} />
          )}
          <YesNoBtnRow
            noHandler={closeCommunicationModal}
            className={classes.btnRow}
            yesText="Send"
          >
            {uploadFilesEnabled && <UploadFileButton onFilesUpload={setFilesHandler} />}
          </YesNoBtnRow>
        </form>
        <LbModal
          open={!!error}
          title="Warning"
          text={`${error} This files was automatically unselected.`}
          primaryBtnConfig={{ text: 'OK', onClick: errorModalBtnHandler }}
        />
      </>
    );
  },
);

export { CommunicationForm };
