import { makeAutoObservable } from 'mobx';

import { resetAllStore } from '../../shared/stores/resetAllStore';
import { FilterStore } from '../../shared/util-stores';
import { EntityRowInfo } from '../interfaces/entity-list/EntityRowInfo';
import { entityService } from '../services/entityService';
import {
  EntityListFilters,
  EntityListFormValues,
  EntityListSortField,
} from '../interfaces/entity-list/EntityListFilters';

// not export if need to use use defaultFilterData from filterMethods
const defaultFilterData: EntityListFormValues = {
  businessEntityType: '',
  businessName: '',
  businessPhone: '',
  inbodyType: '',
  ownerName: '',
  paymentFlowType: '',
  status: '',
};

class EntityListStore {
  entityList: EntityRowInfo[] = [];
  filterMethods: FilterStore<EntityListFormValues, EntityListSortField>;
  filteredEntityCount: number = 0;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    this.filterMethods = new FilterStore<EntityListFormValues, EntityListSortField>(
      this.handleGetEntityList,
      { defaultFilterData },
    );
  }

  setDefaultValues() {
    this.entityList = [];
    this.filterMethods.setDefaultValues();
  }

  async handleGetEntityList(filterInfo: EntityListFilters) {
    const data = await entityService.getEntityList(filterInfo);
    const { content, totalElements } = data;

    this.setEntityList(content);
    this.setFilteredEntityCount(totalElements);

    return data;
  }

  async getEntityList() {
    return this.filterMethods.getData();
  }

  private setEntityList(entityList: EntityRowInfo[]) {
    this.entityList = entityList;
  }

  private setFilteredEntityCount(filteredEntityCount: number) {
    this.filteredEntityCount = filteredEntityCount;
  }
}

const entityListStore = new EntityListStore();

resetAllStore.addResetMethod(entityListStore.setDefaultValues);

export { entityListStore, EntityListStore };
