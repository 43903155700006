import { ValueOfKey } from '../../utils-types';
// old, only on customer list
export const USER_ACTIVATION_STATUSES = {
  PAYMENT_ISSUE_USER: 'payment_issue_user',
  PAYMENT_ISSUE_CA: 'payment_issue_ca',
  PAYMENT_ISSUE: 'payment_issue',
  INCOMPLETE_USER: 'incomplete_user',
  INCOMPLETE_CA: 'incomplete_ca',
  CANCELED_USER: 'canceled_user',
  ACTIVE_USER: 'active_user',
  ACTIVE_CA: 'active_ca',
  PREREGISTERED: 'preregistered',
} as const;

export type UserActivationStatusesType = ValueOfKey<typeof USER_ACTIVATION_STATUSES>;

// TODO add options
export const CUSTOMER_STATUS = {
  ACTIVE: 'ACTIVE',
  INCOMPLETE: 'INCOMPLETE',
  CANCELED: 'CANCELED',
  PAYMENT_ISSUE: 'PAYMENT_ISSUE',
  PRE_REGISTERED: 'PRE_REGISTERED', // PRE-REGISTERED used in options ask BE possibility to use PRE_REGISTERED
};

export type CustomerStatusType = ValueOfKey<typeof CUSTOMER_STATUS>;
