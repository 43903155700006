import React, { useState } from 'react';

import { observer } from 'mobx-react';

import { Button } from '@mui/material';
import { entityCustomerSetupService } from '../../../../../entity-management';
import { loggedInUserDataStore } from '../../../../../shared/stores/loggedInUserDataStore';

const AddNewFlow2024CustomerButton = observer(() => {
  const [registrationLink, setRegistrationLink] = useState<string | null>('');
  const { isCoach, isComplianceCoach, getEntityId } = loggedInUserDataStore;
  const isShowAddCustomerMenu = isCoach && !isComplianceCoach;

  if (!isShowAddCustomerMenu) {
    return null;
  }

  const handleAddCustomer = async () => {
    if (registrationLink) {
      window.open(registrationLink, '_blank');
    } else {
      const entityId = getEntityId();
      const { url } = await entityCustomerSetupService.getRegistrationLink(entityId);

      setRegistrationLink(url);
      window.open(url, '_blank');
    }
  };

  return (
    <Button variant="contained" onClick={handleAddCustomer}>
      Add New Customer
    </Button>
  );
});

export { AddNewFlow2024CustomerButton };
