import React, { useEffect } from 'react';

import { observer } from 'mobx-react';

import classes from './ReportTable.module.scss';
import { HeaderRow } from './HeaderRow/HeaderRow';
import { ReportRow } from './ReportRow/ReportRow';
import { reportStore } from '../../../../stores/reportStore';

const ReportTable = observer(() => {
  const { reports, getReports, setDefaultValues } = reportStore;

  useEffect(() => {
    getReports();

    return () => setDefaultValues();
  }, []);

  return (
    <div className={classes.reportTable}>
      <HeaderRow />
      {reports.map((reportInfo) => (
        <ReportRow key={reportInfo.reportType} reportInfo={reportInfo} />
      ))}
    </div>
  );
});

export { ReportTable };
