import { dateFormat } from '../../../../../shared/constants/apiDateFormat';

export const scrollToDay = (date, previousDate) => {
  const elementId = date.format(dateFormat);
  const previousElementId = previousDate.format(dateFormat);
  const row = document.getElementById(elementId);
  const previousRow = document.getElementById(previousElementId);
  if (row) {
    row.scrollIntoView({ behavior: 'smooth' });
    row.style.backgroundColor = 'var(--background-50-light)';
    previousRow.style.backgroundColor = 'transparent';
  }
};
