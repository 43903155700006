import React, { ReactNode } from 'react';
import { LinearProgress } from '@mui/material';

import classes from './Stepper.module.scss';
import { getPercentages } from '../../../../shared/utils/mathUtils';
import { BlackBgRow } from '../../../../shared/components/ui-components';

export type StepOption = {
  title: string;
  description?: string;
  step: ReactNode;
};

type StepperProps = {
  activeStep: number;
  stepsConfig: StepOption[];
};

const Stepper = ({ activeStep, stepsConfig }: StepperProps) => {
  const activeStepInfo = stepsConfig[activeStep];
  const stepsCount = stepsConfig.length;
  const activeStepNumber = activeStep + 1;
  const progress = getPercentages(activeStepNumber, stepsCount);

  return (
    <BlackBgRow bgRowHeight="330px">
      <div className={classes.stepperInfoRow}>
        <h5 className={classes.title}>LifeBase User Form</h5>
        <div className={classes.stepInfo}>
          {activeStepNumber}/{stepsCount}
        </div>
      </div>
      <LinearProgress
        variant="determinate"
        value={progress}
        className={classes.linearProgress}
      />
      <div className={classes.stepTitleRow}>
        <h2 className={classes.title}>{activeStepInfo.title}</h2>
        {!!activeStepInfo.description && (
          <p className={classes.description}>{activeStepInfo.description}</p>
        )}
      </div>
      <div>{activeStepInfo.step}</div>
    </BlackBgRow>
  );
};

export { Stepper };
