import { Option } from '../../general-types';
import { ValueOfKey } from '../../utils-types';

export const PROMO_CODE_STATUS = {
  ACTIVE: 'ACTIVE',
  PAUSED: 'PAUSED',
  INACTIVE: 'INACTIVE',
} as const;

export type PromoCodeStatus = ValueOfKey<typeof PROMO_CODE_STATUS>;

export const PROMO_CODE_APPLY_TYPE = {
  INITIAL_PLAN_AND_MONTHLY_RENEWAL: 'INITIAL_PLAN_AND_MONTHLY_RENEWAL',
  MONTHLY_RENEWAL: 'MONTHLY_RENEWAL',
  INITIAL_PLAN: 'INITIAL_PLAN',
} as const;

export type PromoCodeApplyType = ValueOfKey<typeof PROMO_CODE_APPLY_TYPE>;

export const statusFilterOptions: Option<PromoCodeStatus>[] = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Paused', value: 'PAUSED' },
  { label: 'Inactive', value: 'INACTIVE' },
];

export const applyDiscountOptions: Option<PromoCodeApplyType>[] = [
  { label: 'Initial Plan & Monthly Renewal ', value: 'INITIAL_PLAN_AND_MONTHLY_RENEWAL' },
  { label: 'Initial Plan', value: 'INITIAL_PLAN' },
  { label: 'Monthly Renewal', value: 'MONTHLY_RENEWAL' },
];

export const PromoCodeApplyTypeTitles: Record<PromoCodeApplyType, string> = {
  INITIAL_PLAN_AND_MONTHLY_RENEWAL: 'Initial Plan and Monthly Renewal',
  MONTHLY_RENEWAL: 'Monthly Renewal',
  INITIAL_PLAN: 'Initial Plan',
};
