import { createTheme } from '@mui/material';
import type {} from '@mui/x-date-pickers/themeAugmentation';

import { palette } from './palette';
import { MuiStepperTheme } from './navigation/stepper.theme';
import {
  MuiButtonBaseTheme,
  MuiButtonTheme,
  MuiIconButtonTheme,
} from './inputs/button.theme';
import { MuiFormControlTheme } from './inputs/form-control.theme';
import { MuiAutocompleteTheme } from './inputs/autocomplete.theme';
import { MuiDateCalendarTheme } from './inputs/date-calendar.theme';
import { MuiChipTheme } from './data-display/chip.theme';
import { MuiSwitchTheme } from './inputs/switch.theme';
import { MuiBadgeTheme } from './data-display/badge.theme';
import { MuiLinkTheme } from './navigation/link.theme';
import { MuiPaginationTheme } from './navigation/pagination.theme';
import { MuiPaperTheme } from './surfaces/paper.theme';
import { MuiTextFieldTheme } from './inputs/text-field.theme';
import { MuiSelectTheme } from './inputs/select.theme';
import { MuiCheckboxTheme } from './inputs/checkbox.theme';
import { MuiTooltipTheme } from './data-display/tooltip.theme';
import { MuiAppBarTheme, MuiToolbarTheme } from './surfaces/app-bar.theme';
import { MuiContainerTheme } from './layout/container.theme';
import { MuiModalTheme } from './utils/modal.theme';
import { MuiMenuTheme } from './navigation/menu.theme';
import { MuiRadioTheme } from './inputs/radio.theme';
import { MuiDividerTheme } from './data-display/divider.theme';
import { MuiAlertTheme } from './feedback/alert.theme';
import { MuiSvgIconTheme } from './data-display/icon.theme';

const theme = createTheme({
  palette: {
    primary: {
      main: palette.primary[500],
      contrastText: palette.white,
    },
    secondary: {
      main: palette.mainBlack,
      contrastText: palette.white,
    },
    error: {
      main: palette.error[300],
      contrastText: palette.white,
    },
    success: {
      main: palette.success[300],
      contrastText: palette.white,
    },
    tertiary: {
      main: palette.natural[400],
      contrastText: palette.white,
    },
    warning: {
      main: palette.warning[600],
      contrastText: palette.white,
    },
    info: {
      main: palette.info[300],
      contrastText: palette.white,
    },
    white: {
      main: palette.white,
      contrastText: palette.mainBlack,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 694,
      md: 900,
      lg: 1376,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: '"Lato", "Roboto", "Helvetica Neue", "sans-serif"',
    h1: {
      fontSize: 28,
      fontWeight: 900,
      lineHeight: '34px',
      letterSpacing: -0.67,
    },
  },
  components: {
    MuiButton: MuiButtonTheme,
    MuiButtonBase: MuiButtonBaseTheme,
    MuiIconButton: MuiIconButtonTheme,
    MuiPaper: MuiPaperTheme,
    MuiTextField: MuiTextFieldTheme,
    MuiFormControl: MuiFormControlTheme,
    MuiSelect: MuiSelectTheme,
    MuiStepper: MuiStepperTheme,
    MuiAutocomplete: MuiAutocompleteTheme,
    MuiDateCalendar: MuiDateCalendarTheme,
    MuiModal: MuiModalTheme,
    MuiCheckbox: MuiCheckboxTheme,
    MuiChip: MuiChipTheme,
    MuiPagination: MuiPaginationTheme,
    MuiTooltip: MuiTooltipTheme,
    MuiAppBar: MuiAppBarTheme,
    MuiToolbar: MuiToolbarTheme,
    MuiContainer: MuiContainerTheme,
    MuiSwitch: MuiSwitchTheme,
    MuiLink: MuiLinkTheme,
    MuiBadge: MuiBadgeTheme,
    MuiMenu: MuiMenuTheme,
    MuiRadio: MuiRadioTheme,
    MuiDivider: MuiDividerTheme,
    MuiAlert: MuiAlertTheme,
    MuiSvgIcon: MuiSvgIconTheme,
  },
});

export { theme };

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary'];
    white: Palette['primary'];
  }

  interface PaletteOptions {
    tertiary?: PaletteOptions['primary'];
    white?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    tertiary: true;
    white: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    tertiary: true;
    white: true;
  }
}
