import React from 'react';

import classes from './ScanInfoTable.module.scss';
import { InputBox, InputView } from '../../../shared/components/form-components';
import { CustomerScanData } from '../../interfaces/customerInbodyData';
import { calculateTbw } from '../../utils/metricUtils';
import { getFormattedDate } from '../../../shared/utils/dateUtils';
import {
  localDataFormat,
  localTimeFormat,
  timeFormat,
} from '../../../shared/constants/apiDateFormat';

type ScanInfoTableProps = {
  scanData: CustomerScanData;
};

const ScanInfoTable = ({ scanData }: ScanInfoTableProps) => {
  const formattedScanDate = getFormattedDate(scanData.scanDate, localDataFormat);
  const formattedScanTime = getFormattedDate(
    scanData.scanTime,
    localTimeFormat,
    timeFormat,
  );

  return (
    <InputBox title={`Scan data ${formattedScanDate}`} variant="innerBox">
      <InputView label="Check-in-date" value={formattedScanDate} />
      <InputView label="Check-in-time" value={formattedScanTime} />
      <InputView label="Height" value={scanData.height} endAdornment="inches" />
      <InputView label="Weight" value={scanData.weight} endAdornment="lbs" />

      <InputView label="Body fat" value={scanData.percentBodyFat} endAdornment="%" />

      <InputView
        label="Intracellular water"
        value={scanData.intracellularWatter}
        endAdornment="lbs"
      />
      <InputView
        label="Extracellular water"
        value={scanData.extracellularWatter}
        endAdornment="lbs"
      />
      <InputView label="Dry lean mass" value={scanData.dryLeanMass} endAdornment="lbs" />
      <InputView
        label="Skeletal muscle mass"
        value={scanData.skeletalMuscleMass}
        endAdornment="lbs"
      />
      <InputView
        label="Basal metabolic rate"
        value={scanData.basalMetabolicRate}
        endAdornment="kcal"
      />
      <div className={classes.calculatedRow}>
        <InputView
          label="Body fat mass"
          value={scanData.bodyFatMass}
          endAdornment="lbs"
        />
        <InputView
          label="Lean body mass"
          value={scanData.leanBodyMass}
          endAdornment="lbs"
        />
        <InputView
          label="TBW"
          value={calculateTbw(scanData.intracellularWatter, scanData.extracellularWatter)}
          endAdornment="lbs"
        />
        <InputView label="ECW/TBW" value={scanData.ecwRatio} endAdornment="ratio" />
      </div>
    </InputBox>
  );
};

export { ScanInfoTable };
