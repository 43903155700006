import React from 'react';

import { useFormContext } from 'react-hook-form';

import { LbInput } from '../../../../../../components/form-components';
import { CustomFilterFormValues } from '../AddCustomFilterForm.settings';
import { useBetweenComparison } from './hooks/useBetweenComparison';
import { CustomComparisonFilterType } from '../../../../interfaces/CustomFilterData';
import { getComparisonFilterValidateRule } from '../utils/getComparisonFilterValidateRule';
import { ModalInputGroup } from '../../../../../../components/modals';

type ComparisonInputsProps = {
  filterType: CustomComparisonFilterType;
};

const ComparisonInputs = ({ filterType }: ComparisonInputsProps) => {
  const { control } = useFormContext<CustomFilterFormValues>();
  const isBetweenComparison = useBetweenComparison(filterType);
  const fromInputLabel = isBetweenComparison ? 'From' : 'Value';

  const validate = getComparisonFilterValidateRule(filterType);

  return (
    <ModalInputGroup>
      <LbInput
        control={control}
        size="small"
        variant="outlined"
        name={`filters.${filterType}.from`}
        label={fromInputLabel}
        type="number"
        rules={{ validate }}
      />

      {isBetweenComparison && (
        <LbInput
          control={control}
          size="small"
          variant="outlined"
          name={`filters.${filterType}.to`}
          label="To"
          type="number"
          rules={{ validate }}
        />
      )}
      <LbInput
        control={control}
        size="small"
        variant="outlined"
        name={`filters.${filterType}.lastDays`}
        label="Last Days"
        type="number"
        rules={{
          min: {
            value: 0,
            message: "Can't be less than 0",
          },
          max: {
            value: 30,
            message: "Can't be more than 30",
          },
          validate,
        }}
      />
    </ModalInputGroup>
  );
};

export { ComparisonInputs };
