import { Flags, ValueOfKey } from '../../../utils-types';

export const PAYMENT_FLOW_TYPE = {
  OLD: 'OLD', // First version of payment flow
  NEW: 'NEW', // version when entity pay for users created 2022
  NEW_USER_FLOW_2024: 'NEW_USER_FLOW_2024',
} as const;

export type PaymentFlowType = ValueOfKey<typeof PAYMENT_FLOW_TYPE>;
export type PaymentFlowTypeKey = keyof typeof PAYMENT_FLOW_TYPE;
export type PaymentFlowTypeFlags = Flags<PaymentFlowTypeKey>;

// use getHumanizePaymentFlowType utils to get showed name
export const humanizePaymentFlowType: Record<PaymentFlowType, string> = {
  OLD: 'Old Flow',
  NEW: 'New Flow',
  NEW_USER_FLOW_2024: 'New Flow 2024',
};
