import React from 'react';

import { Control } from 'react-hook-form';
import { observer } from 'mobx-react';
import { Button } from '@mui/material';

import {
  ComboBox,
  LbDatePicker,
  LbInput,
  LbSelect,
} from '../../../../../shared/components/form-components';
import {
  CheckboxCell,
  GridTableRow,
} from '../../../../../shared/components/table-components';
import { scholasticCustomersListStore } from '../../../../stores/ScholasticCustomersListStore';
import { scholasticCustomersStatusOptions } from '../../../../constants/scholasticCustomersStatus';
import { ScholasticCommunicationFilterFormValue } from '../../../../interfaces/scholastic-list/ScholasticCommunicationFilters';
import { useSchoolList } from '../../../../hooks/school';
import { CustomFiltersSelector } from '../../../../../shared/features/custom-filter';

type GeneralFiltersProps = {
  control: Control<ScholasticCommunicationFilterFormValue>;
  onReset: () => void;
};

const GeneralFilters = observer(({ control, onReset }: GeneralFiltersProps) => {
  const { schoolListOptionWithAll } = useSchoolList();
  const { isShowLocationColumn, isShowCoachColumn, selectMethod } =
    scholasticCustomersListStore;
  const { getMasterCheckboxProps } = selectMethod;

  const inputGeneralProps = {
    control,
    variant: 'outlined',
    size: 'small',
  } as const;

  return (
    <GridTableRow variant="filter" component="form">
      <CheckboxCell showCheckbox {...getMasterCheckboxProps()}>
        <LbInput
          {...inputGeneralProps}
          name="generalFilter.fullName"
          placeholder="Name"
          dataTestId="name-input"
        />
      </CheckboxCell>
      <GridTableRow variant="unstyled">
        {isShowLocationColumn && (
          <LbInput
            {...inputGeneralProps}
            name="generalFilter.entityName"
            placeholder="Location"
            dataTestId="location-input"
          />
        )}
        <ComboBox
          {...inputGeneralProps}
          name="generalFilter.schoolId"
          options={schoolListOptionWithAll}
          defaultValue=""
          dataTestId="group-input"
        />
        {isShowCoachColumn && (
          <LbInput
            {...inputGeneralProps}
            name="generalFilter.coachName"
            placeholder="Name"
            dataTestId="coach-input"
          />
        )}
        <LbDatePicker
          {...inputGeneralProps}
          name="generalFilter.validFrom"
          placeholder="Date"
        />
        <LbSelect
          {...inputGeneralProps}
          name="generalFilter.status"
          options={scholasticCustomersStatusOptions}
          displayEmpty
          dataTestId="status-input"
        />
        <CustomFiltersSelector {...inputGeneralProps} name="customFilterId" />
      </GridTableRow>
      <Button variant="outlined" size="small" type="reset" fullWidth onClick={onReset}>
        Clear All
      </Button>
    </GridTableRow>
  );
});

export { GeneralFilters };
