import { useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { CustomFilterFormValues } from '../AddCustomFilterForm.settings';
import { isEmptyValue } from '../../../../../../utils/jsUtils';
import {
  CustomFilterType,
  CustomFilterByType,
} from '../../../../interfaces/CustomFilterData';

type ClearFields<TFilter extends CustomFilterType> = Omit<
  CustomFilterByType<TFilter>,
  'filterType'
>;

export const useClearFilter = <TFilter extends CustomFilterType>(
  filter: TFilter,
  clearFields: ClearFields<TFilter>,
) => {
  const [disabled, setDisabled] = useState(true);

  const { watch, resetField } = useFormContext<CustomFilterFormValues>();

  const fieldNames = Object.keys(clearFields).map(
    (field) => `filters.${filter}.${field}` as any,
  );

  const fieldsValue = watch(fieldNames);

  const handleClearFields = () => {
    resetField(`filters.${filter}`, { defaultValue: clearFields as any });
  };

  useEffect(() => {
    const isAllFieldEmpty = fieldsValue.every(isEmptyValue);
    setDisabled(isAllFieldEmpty);
  }, [fieldsValue]);

  return { handleClearFields, disabled };
};
