import React from 'react';
import PropTypes from 'prop-types';

import classes from './Row.module.scss';

const Row = ({ id, className, children }) => {
  return (
    <div id={id} className={`${classes.row} ${className}`}>
      {children}
    </div>
  );
};

Row.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  id: PropTypes.string,
};

export { Row };
