'use strict';

angular.module('app').factory('RecipeService', RecipeService);

RecipeService.$inject = ['ApiService'];

function RecipeService(ApiService) {
    return {
        getAllRecipes: function (prms) {
            return ApiService.get('api/recipes', prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        createRecipe: function (data) {
            return ApiService.post('api/recipe', data).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        updateRecipe: function (recipeId, data) {
            data.append('id', recipeId);
            return ApiService.put('api/recipe', data).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getRecipeDetails: function (recipeId) {
            return ApiService.get('api/recipe/' + recipeId).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        deleteRecipe: function (recipeId) {
            return ApiService.delete('api/recipe/' + recipeId).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        changeRecipeState: function (recipeId, prms) {
            return ApiService.post('api/recipe/' + recipeId + '/ban', null, prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        saveAsFood: function (data) {
            return ApiService.post('api/recipe/save-as-food', data).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        }
    };
}
