(function () {
    'use strict';

    angular.module('app.coachList').controller('CoachListController', CoachListController);


    CoachListController.$inject = ['$rootScope', 'CoachService', 'utilService', 'constants', 'PaymentService'];

    function CoachListController($rootScope, CoachService, utilService, constants, PaymentService) {
        var vm = this;

        vm.loggedInUser = utilService.getLoggedInUserData();
        vm.coaches = [];
        vm.spinner = {active: false};
        vm.pagination = {};
        vm.pagesCount = 0;
        vm.isChildEntity = utilService.getIsChildEntity();
        vm.paymentFlowType = {};

        vm.init = init;
        vm.savePageParam = savePageParam;
        vm.getAllCoaches = getAllCoaches;
        vm.goTo = utilService.goTo;
        vm.openSocialLink = openSocialLink;

        vm.init();

        function init() {
            var savedParams = utilService.getCurrentPage(
                constants.pages.coachList,
                constants.filters.coachList
            );
            var page = savedParams[0];
            var filters = savedParams[1];

            vm.filters = filters ? filters : {
                name: '', phone: '', entityId: '', entityAddress: '', entityCity: '',
                state: '', zip: '', commissionSetUp: '', commissionRenew: '', page: 0
            };

            vm.getAllCoaches(page ? page : 0, filters === null, page !== null);

            if(vm.loggedInUser.role === constants.enums.userRoles.ADMIN) {
                PaymentService.getPaymentFlowTypeAndIsStripeUsed(vm);
            }
        }

        function savePageParam() {
            localStorage.setItem(constants.pages.coachList, angular.copy(vm.filters.page));
            localStorage.setItem(constants.filters.coachList, JSON.stringify(angular.copy(vm.filters)));
        }

        function getAllCoaches(page, isInitial, hasPageParam) {
            vm.isInitialList = isInitial;
            vm.spinner.active = true;
            vm.filters.page = page;
            CoachService.getAllCoaches(vm.filters).then(function (res) {
                vm.spinner.active = false;
                if (res.status === 200) {
                    vm.coaches = res.data.items;
                    vm.pagesCount = res.data.page.count;
                    if (hasPageParam) {
                        $rootScope.$broadcast('changePage', {page: page})
                    } else if (vm.filters.page === 0) {
                        vm.pagination.reset(vm.pagesCount);
                    }
                }
            });
        }

        function openSocialLink(evt, path) {
            evt.preventDefault();
            window.open(path, '_blank');
        }
    }
})();
