import { isObject } from '../../jsUtils/isObject/isObject';

// Draft types
// export type RenamedFieldsConfig<T> = T extends
//   | Record<infer R, infer K>
//   | Record<infer R, infer K>[]
//   ? K extends number | string | boolean
//     ? { [key in R]?: string }
//     : RenamedFieldsConfig<K>
//   : never;

// export type RenameFields<
//   T extends Record<string, any>,
//   M extends Record<string, string>,
// > = {
//   [K in keyof T as K extends keyof M ? M[K] : K]: K extends keyof T
//     ? T[K] extends Record<string, any>
//       ? RenameFields<T[K], M>
//       : T[K]
//     : never;
// };

// TODO Update the types
export const renameFields = (data: any, config: Record<string, string>): any => {
  if (isObject(data)) {
    const keyValues = Object.entries(data).map(([key, value]) => {
      const newKey = config[key] || key;

      return { [newKey]: renameFields(value, config) };
    });

    return Object.assign({}, ...keyValues);
  } else if (Array.isArray(data)) {
    return data.map((item) => renameFields(item, config));
  } else {
    return data;
  }
};
