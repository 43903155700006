import React from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react';
import moment from 'moment';

import { apiStatusStore } from '../../../../../../shared/stores/apiStatusStore';
import { getFormattedDate } from '../../../../../../shared/utils/dateUtils';
import { AddWaterGoalModal } from '../../../../../components/AddWaterGoalModal/AddWaterGoalModal';
import { waterGoalService } from '../../../../../services/waterGoalService';
import { customerWaterGoalStore } from '../../../../../stores/customerWaterGoalStore';

const CreateWaterGoalModal = observer(({ open, closeHandler, customerId }) => {
  const { isLoading } = apiStatusStore;
  const { initDateToRender } = customerWaterGoalStore;

  const onSubmit = async (data) => {
    const submitDate = {
      ...data,
      customerID: customerId,
    };

    await waterGoalService.sendGoal(submitDate);
    closeHandler();
    initDateToRender(customerId, getFormattedDate(moment()));
  };

  const { control, handleSubmit } = useForm();
  return (
    <AddWaterGoalModal
      open={open}
      control={control}
      name="targetGoal"
      noHandler={closeHandler}
      yesHandler={handleSubmit(onSubmit)}
      isLoading={isLoading}
    />
  );
});

CreateWaterGoalModal.propTypes = {
  open: PropTypes.bool,
  closeHandler: PropTypes.func,
  customerId: PropTypes.string,
};

export { CreateWaterGoalModal };
