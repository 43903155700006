import { MomentFormatSpecification } from 'moment';

import { SortDataType, SortDirection } from '../../../../general-types';
import { getSortedArrByDate } from '../getSortedArrByDate/getSortedArrByDate';
import { sortArrAlphabetically } from '../sortArrAlphabetically/sortArrAlphabetically';
import { sortArrNumerically } from '../sortArrNumerically/sortArrNumerically';

type GetSortedArrProps<TItem extends Record<string, any>> = {
  data: TItem[];
  fieldName: keyof TItem;
  direction: SortDirection;
  type: SortDataType;
  dateFormat?: MomentFormatSpecification;
};

export const getSortedArr = <TItem extends Record<string, any>>({
  data,
  fieldName,
  direction,
  type,
  dateFormat,
}: GetSortedArrProps<TItem>) => {
  const newData = [...data];

  switch (type) {
    case 'STRING':
      return sortArrAlphabetically(newData, fieldName, direction === 'DESC');
    case 'NUMBER':
      return sortArrNumerically(newData, fieldName, direction === 'DESC');
    case 'DATE':
      return getSortedArrByDate(newData, fieldName, dateFormat, direction === 'ASC'); // TODO update getSortedArrByDate change bool to direction field
    default:
      return newData;
  }
};
