'use strict';

import { RESPONSE_STATUSES } from '../modules/shared/constants/RESPONSE_STATUSES';
import { clearUserInfo } from '../modules/shared/utils/userInfoUtils';

const { SESSION_EXIST } = RESPONSE_STATUSES;

angular.module('app').factory('HttpRequestInterceptor', HttpRequestInterceptor);

HttpRequestInterceptor.$inject = ['$q', '$location', '$rootScope', '$injector','localStorageService'];
// can't use utilsServices because of circular dependency
function HttpRequestInterceptor($q, $location, $rootScope, $injector, localStorageService) {
    const httpRequestInterceptor = {
        request: function (config) {
            config.headers['Content-Type'] = 'application/json;charset=UTF-8';

            if (config.url.indexOf('api/profile/avatar') >= 0 ||
                (config.url.indexOf('api/recipe') >= 0 && config.url.indexOf('api/recipes') < 0 && config.url.indexOf('api/recipe/') < 0)) {
                config.transformRequest = angular.identity;
                config.headers['Content-Type'] = undefined;
            }

            return config;
        },
        responseError: function (rejection) {
            const path = rejection.data.path ?? "";
            const errorMessage = rejection.data.message;

            switch (rejection.status) {
                case 400:
                    if (
                        !path.includes('pos/status') &&
                        !path.includes('payment-source') &&
                        !path.includes('api/coach/inbody/update')
                    ) {
                        httpRequestInterceptor.showErrorModal(errorMessage);
                    }
                    break;
                case 401:
                    httpRequestInterceptor.clearUserInfo();
                    $location.path('/').search({}).replace();
                    
                    const isSingInRequest = path.includes('api/cookie-login');

                    if (isSingInRequest && errorMessage !== 'User not found') {
                        httpRequestInterceptor.showErrorModal(errorMessage);
                    }
                    
                    break;
                case 402:
                    if (path.includes('api/super/zip')) {
                        httpRequestInterceptor.showErrorModal(errorMessage);
                    }
                    break;
                case 404:
                    if (path.includes('api/coach/user/goal-adjustments') || path.includes('api/corporate/cookie-login')) {
                        httpRequestInterceptor.showErrorModal(errorMessage);
                    }
                    break;
                case SESSION_EXIST:
                    break;
                case 503:
                    if (path.includes('api/coach/inbody')) {
                        if (rejection.config.method === 'GET' || rejection.config.method === 'PUT') {
                            break;
                        }
                    }
                default:
                    httpRequestInterceptor.showErrorModal(errorMessage);
            }

            return $q.reject(rejection);
        },
        showErrorModal: function (message) {
            $injector.get('ModalService').alert(message, 'Error');
        },
        clearUserInfo: function () {
            clearUserInfo()
        },
    };

    return httpRequestInterceptor;
}

