// validate by order of appearance
/**
 * Password should include lowercase, uppercase, number, special character, no spaces, and length between 8 to 16 characters
 */
export const validatePassword = (password: string): true | string => {
  if (!/[a-z]/.test(password)) {
    return 'Password must include at least one lowercase letter (a-z)';
  }

  if (!/[A-Z]/.test(password)) {
    return 'Password must include at least one uppercase letter (A-Z)';
  }

  if (!/[0-9]/.test(password)) {
    return 'Password must include at least one number (0-9)';
  }

  if (!/[^A-Za-z0-9\s]/.test(password)) {
    return 'Password must include at least one special character (such as @, #, $, %, !, *)';
  }

  if (/\s/.test(password)) {
    return 'Password should not contain spaces';
  }

  if (password.length < 8) {
    return 'Password must be at least 8 characters';
  }

  if (password.length > 16) {
    return 'Password must be less than 16 characters';
  }

  return true;
};
