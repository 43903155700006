import React from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react';

import { Button } from '@mui/material';

import classes from './EditOrderModal.module.scss';
import { LbModal } from '../../../../../../../shared/components/modals/LbModal/LbModal';
import { InventoryInputs } from '../InventoryInputs/InventoryInputs';
import { apiStatusStore } from '../../../../../../../shared/stores/apiStatusStore';
import { openConfirmationModal } from '../../../../../../../shared/features/confirmation';

const EditOrderModal = observer(
  ({
    open,
    closeHandler,
    editOrderHandler,
    deleteOrderHandler,
    orderValue,
    dateValue,
  }) => {
    const { control, handleSubmit } = useForm();
    const { isLoading } = apiStatusStore;

    const onSaveHandler = async (data) => {
      await editOrderHandler(data);
      closeHandler();
    };

    const handleDelete = () => {
      closeHandler();

      openConfirmationModal({
        title: 'Do you want to delete this Order?',
        confirmBtnConfig: { onClick: deleteOrderHandler },
      });
    };

    const ButtonsBox = (
      <div className={classes.btnBox}>
        <Button variant="contained" onClick={handleSubmit(onSaveHandler)}>
          Save
        </Button>
        <Button variant="outlined" onClick={closeHandler}>
          Cancel
        </Button>
        <Button variant="text" color="error" onClick={handleDelete}>
          Delete Order
        </Button>
      </div>
    );

    return (
      <LbModal
        open={open}
        title="Edit Order"
        isLoading={isLoading}
        renderBtnBox={ButtonsBox}
      >
        <InventoryInputs
          control={control}
          orderValue={orderValue}
          dateValue={dateValue}
        />
      </LbModal>
    );
  },
);

EditOrderModal.propTypes = {
  open: PropTypes.bool,
  closeHandler: PropTypes.func,
  editOrderHandler: PropTypes.func,
  deleteOrderHandler: PropTypes.func,
  orderValue: PropTypes.number,
  dateValue: PropTypes.string,
};

export { EditOrderModal };
