import React from 'react';

import Container from '@mui/material/Container';

import classes from './Finish.module.scss';
import stars from '../../../../../shared/assets/image/stars.png';
import facebook from '../../../../assets/images/facebook.png';
import instagram from '../../../../assets/images/instagram.png';
import earth from '../../../../assets/images/earth.png';

const Finish = () => {
  return (
    <Container className={classes.container}>
      <img src={stars} alt="star" />
      <div className={classes.titleContainer}>
        <div className={classes.title}>Well done!</div>
      </div>
      <div className={classes.description}>
        The information will be processed by the coach as soon as possible.
      </div>
      <div className={classes.followUs}>Follow us on our social networks</div>
      <div>
        <img src={facebook} alt="facebook" className={classes.icon} />
        <img src={instagram} alt="instagram" className={classes.icon} />
        <img src={earth} alt="earth" className={classes.icon} />
      </div>
    </Container>
  );
};

export { Finish };
