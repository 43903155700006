import React from 'react';
import PropTypes from 'prop-types';

import { IconButton, SvgIcon } from '@mui/material';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { ReactComponent as editOptionIcon } from '../../../../../../assets/images/material-icons/edit-option.svg';

import classes from './EditDeleteButtonGroup.module.scss';
import { GridTableCell } from '../GridTableCell/GridTableCell';
import { SeparatorLine } from '../../ui-components/SeparatorLine/SeparatorLine';
import { openConfirmationModal } from '../../../features/confirmation';

const EditDeleteButtonGroup = ({ onEdit, onDelete, modalTitle }) => {
  const handleDelete = () => {
    openConfirmationModal({
      title: modalTitle,
      confirmBtnConfig: { onClick: onDelete },
    });
  };

  return (
    <GridTableCell justify="end" className={classes.iconsContainer}>
      <SeparatorLine />
      <IconButton onClick={onEdit} color="secondary" className={classes.editButton}>
        <SvgIcon component={editOptionIcon} fontSize="small" inheritViewBox />
      </IconButton>
      <IconButton onClick={handleDelete} color="secondary">
        <DeleteOutlineRoundedIcon fontSize="large" />
      </IconButton>
    </GridTableCell>
  );
};

EditDeleteButtonGroup.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  modalTitle: PropTypes.string,
};

export { EditDeleteButtonGroup };
