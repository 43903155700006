import React, { ReactNode } from 'react';

import { Paper } from '@mui/material';

import classes from './InfoContainer.module.scss';

type InfoContainerProps = {
  children: ReactNode;
};

const InfoContainer = ({ children }: InfoContainerProps) => {
  return (
    <Paper className={classes.box} variant="roundedBox">
      {children}
    </Paper>
  );
};

export { InfoContainer };
