'use strict';

angular.module('app').factory('ComplianceCoachesService', ComplianceCoachesService);

ComplianceCoachesService.$inject = ['ApiService', 'utilService'];

function ComplianceCoachesService(ApiService, utilService) {
    return {
        getComplianceCoachesForCustomer: function (customerId, prms) {
            var url = 'api/' + (utilService.getLoggedInUserData().role === 'SUPERUSER' ? 'super' : 'admin') + '/compliance-coach/' + customerId;
            return ApiService.get(url, prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        addComplianceCoachToCustomer: function (customerId, complianceCoachId) {
            var url = 'api/' + (utilService.getLoggedInUserData().role === 'SUPERUSER' ? 'super' : 'admin') + '/compliance-coach/' + customerId + '/' + complianceCoachId;
            return ApiService.post(url).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        deleteComplianceCoachFromCustomer: function (complianceCoachId) {
            var url = 'api/' + (utilService.getLoggedInUserData().role === 'SUPERUSER' ? 'super' : 'admin') + '/compliance-coach/' + complianceCoachId;
            return ApiService.delete(url).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        }
    }
}