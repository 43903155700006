export const renderInputStyle = {
  fieldset: {
    display: 'none',
  },
  '& .MuiOutlinedInput-root': {
    padding: '9px',
    paddingLeft: '18px',
    paddingRight: '210px !important',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: 'var(--panel-shadow)',
    font: 'var(--title-16-bold)',
    color: 'var(--main-black)',
  },
  '.Mui-focused.MuiOutlinedInput-root': {
    borderRadius: '8px 8px 0 0',
  },
  '.MuiInputAdornment-root': {
    color: 'var(--natural-400)',
  },
  'input::placeholder': {
    opacity: '1',
    color: 'var(--natural-300)',
    font: 'var(--title-16-regular)',
  },
  '& .MuiAutocomplete-endAdornment': {
    right: '130px !important',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  '.MuiAutocomplete-clearIndicator': {
    width: '110px',
    height: '35px',
    borderRadius: '4px',
    font: 'var(--title-16-bold)',
    color: 'var(--natural-500)',
    visibility: 'visible',
  },
};

export const customPaperStyle = {
  boxShadow: 'var(--panel-shadow)',
  marginTop: '1px',
  borderRadius: '0 0 8px 8px',

  '.MuiAutocomplete-listbox': {
    paddingLeft: '16px',
  },
  '.MuiListSubheader-root': {
    padding: '10px',
    font: 'var( --body-14-bold)',
    color: 'var(--main-black)',
  },
  '.MuiAutocomplete-groupUl': {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
  },
};
