import React from 'react';

import classes from './ConsumptionBetweenTable.module.scss';
import {
  GridTableRow,
  GridTableCell,
} from '../../../../shared/components/table-components';
import { ConsumptionNutrition } from '../../../interfaces/consumption/ConsumptionInfo';
import { consumptionBetweenTableConfig } from './ConsumptionBetweenTable.settings';
import { Nutrient } from '../../../../shared/components/ui-components';
import {
  getRoundedNumberOrDash,
  getPercentagesOrDash,
} from '../../../../shared/utils/mathUtils';

type ConsumptionBetweenTableProps = {
  goal: ConsumptionNutrition;
  actual: ConsumptionNutrition;
};

const ConsumptionBetweenTable = ({ actual, goal }: ConsumptionBetweenTableProps) => {
  return (
    <div className={classes.consumptionBetweenTable}>
      <GridTableRow variant="unstyled" className={classes.header}>
        <div />
        {consumptionBetweenTableConfig.map((nutrient) => (
          <p key={nutrient.id}>{nutrient.title}</p>
        ))}
      </GridTableRow>
      <GridTableRow variant="subRow">
        <GridTableCell variant="title">Actual</GridTableCell>
        {consumptionBetweenTableConfig.map(({ valueKey: nutrient }) => (
          <Nutrient nutrient={nutrient} key={nutrient}>
            {getRoundedNumberOrDash(actual[nutrient])}
          </Nutrient>
        ))}
      </GridTableRow>
      <GridTableRow variant="subRow">
        <GridTableCell variant="title">Goal</GridTableCell>
        {consumptionBetweenTableConfig.map(({ valueKey: nutrient }) => (
          <p key={nutrient}>{getRoundedNumberOrDash(goal[nutrient])}</p>
        ))}
      </GridTableRow>
      <GridTableRow variant="subRow">
        <GridTableCell variant="title">%</GridTableCell>
        {consumptionBetweenTableConfig.map(({ valueKey: nutrient }) => (
          <p key={nutrient}>{getPercentagesOrDash(actual[nutrient], goal[nutrient])}</p>
        ))}
      </GridTableRow>
    </div>
  );
};

export { ConsumptionBetweenTable };
