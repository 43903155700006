export const calculateBodyFatMass = (
  weight?: number | null,
  percentBodyFat?: number | null,
) => {
  if (!weight || !percentBodyFat) {
    return null;
  }

  return +(weight * (percentBodyFat / 100)).toFixed(1);
};
