export const checkList = [
  '90%+ Proteins, Carbs, Fats compliance',
  '100% or less sugar compliance',
  'All "Blue" meals at 3.5 stars or better',
  'Logged 90%+ of their meals',
  '60%+ Fiber Consumption',
  'Food quality -1 ingredient vs processed',
  'Accurate food logs',
];

export const askList = [
  'Did you exercise more/less?',
  'How did you sleep?',
  'Did you drink alcohol?',
  'Were you sick or stressed?',
  'How many meal were at restaurants?',
  'Did you travel?',
  'Did you attend party or celebration?',
];
