import React from 'react';

import classes from './TextInfo.module.scss';

type TextInfoProps = {
  title: string;
  text: string;
};

const TextInfo = ({ title, text }: TextInfoProps) => {
  return (
    <div className={classes.info}>
      <h1 className={classes.title}>{title}</h1>
      <p className={classes.text}>{text}</p>
    </div>
  );
};

export { TextInfo };
