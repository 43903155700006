import { useState } from 'react';
import {
  validateGoal as validateGoalUtils,
  ValidationResult,
  BodyMetrics,
} from '../utils/validateGoal';

export const useGoalValidation = () => {
  const [validationInfo, setValidationInfo] = useState<null | ValidationResult>(null);

  const validateGoal = (metrics: BodyMetrics) => {
    const info = validateGoalUtils(metrics);
    setValidationInfo(info);
  };

  const reset = () => {
    setValidationInfo(null);
  };

  return {
    validationInfo,
    validateGoal,
    reset,
  };
};
