import { ValueOfKey } from '../../../../utils-types';

type GetEnumArrayReturn<
  T extends Record<string, string>,
  TExclude extends Readonly<ValueOfKey<T>[]> = [],
> = Exclude<ValueOfKey<T>, TExclude[number]>[];
// TODO update type need return correct array of strings
export const getEnumArray = <
  T extends Record<string, string>,
  TExclude extends Readonly<ValueOfKey<T>[]>,
>(
  enumLikeObj: T,
  exclude?: TExclude,
): GetEnumArrayReturn<T, TExclude> => {
  const enumValues = Object.values(enumLikeObj) as GetEnumArrayReturn<T, TExclude>;

  if (exclude) {
    return enumValues.filter((value) => !exclude.includes(value));
  }

  return enumValues;
};
