import { useEffect, useMemo, useState } from 'react';

import { isEqual } from 'lodash';
import { enqueueSnackbar } from 'notistack';

import { apiStatusStore } from '../../../../../../../../../shared/stores/apiStatusStore';
import { entityCustomerSetupService } from '../../../../../../../../services/entityCustomerSetupService';
import { LoadedFile } from '../../../../../../../../../shared/interfaces/LoadedFile';
import { fileService } from '../../../../../../../../../shared/services/fileService';
import { uploadFileTypes } from '../../../../../../../../../shared/constants/uploadFileTypes';

type UseCompanyLogoReturn = {
  logo: LoadedFile | null;
  isLoading: boolean | undefined;
  isChanged: boolean;
  handleUploadLogo: (acceptedFiles: File[]) => Promise<void>;
  handleRemoveLogo: () => void;
  handleSaveChanges: () => Promise<void>;
};

/**
 * observer is required
 */
export const useCompanyLogo = (entityId: number): UseCompanyLogoReturn => {
  const [initialLogo, setInitialLogo] = useState<LoadedFile | null>(null);
  const [currentLogo, setCurrentLogo] = useState<LoadedFile | null>(null);

  const { isLoading } = apiStatusStore;

  const isChanged = useMemo(
    () => !isEqual(initialLogo, currentLogo),
    [initialLogo, currentLogo],
  );

  const getCompanyLogo = async () => {
    const logoInfo = await entityCustomerSetupService.getCompanyLogo(entityId, {
      isRunGlobalSpinner: false,
    });

    if (logoInfo) {
      setInitialLogo(logoInfo);
      setCurrentLogo(logoInfo);
    }
  };

  useEffect(() => {
    getCompanyLogo();
  }, []);

  const handleUploadLogo = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];

    if (file) {
      const logo = await fileService.uploadFile(uploadFileTypes.ENTITY_LOGO, file, {
        isRunGlobalSpinner: false,
      });

      setCurrentLogo(logo);
    }
  };

  const handleRemoveLogo = () => {
    setCurrentLogo(null);
  };

  const handleSaveChanges = async () => {
    const isLogoDeleted = initialLogo && !currentLogo;
    const isLogoUpdated = currentLogo && isChanged;

    if (isLogoDeleted) {
      await entityCustomerSetupService.deleteCompanyLogo(entityId);

      enqueueSnackbar('The logo is deleted', {
        variant: 'success',
        autoHideDuration: 5000,
      });
    } else if (isLogoUpdated) {
      await entityCustomerSetupService.setCompanyLogo(entityId, currentLogo.id);

      enqueueSnackbar('The logo is uploaded and saved successfully', {
        variant: 'success',
        autoHideDuration: 5000,
      });
    }
  };

  return {
    logo: currentLogo,
    isLoading,
    isChanged,
    handleUploadLogo,
    handleRemoveLogo,
    handleSaveChanges,
  };
};
