import { useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';

import { AddPromoCodeRequest, PromoCodeInfo } from '../../../../interfaces/promoCodeInfo';
import { promoCodesService } from '../../../../services/promoCodesService';
import { updateItemByField } from '../../../../../shared/utils/arrayUtils';

type UsePromoCodesReturn = {
  promoCodes: PromoCodeInfo[];
  loading: boolean;
  addPromoCode: (promoCode: AddPromoCodeRequest) => Promise<void>;
  togglePromoCode: (promoCodeId: number, pause: boolean) => Promise<void>;
};

export const usePromoCodes = (entityId: number): UsePromoCodesReturn => {
  const [promoCodes, setPromoCodes] = useState<PromoCodeInfo[]>([]);

  const [state, getPromoCodes] = useAsyncFn(async () => {
    const newPromoCodes = await promoCodesService.getPromoCodes(entityId);

    setPromoCodes(newPromoCodes);
  });

  useEffect(() => {
    getPromoCodes();
  }, []);

  const addPromoCode = async (promoCodeRequest: AddPromoCodeRequest) => {
    const response = await promoCodesService.addPromoCode(entityId, promoCodeRequest);
    setPromoCodes((prevPromoCodes) => [...prevPromoCodes, response]);
  };

  const togglePromoCode = async (promoCodeId: number, pause: boolean) => {
    const response = await promoCodesService.togglePromoCode(
      entityId,
      promoCodeId,
      pause,
    );
    setPromoCodes(
      updateItemByField(promoCodes, response, {
        id: response.id,
      }),
    );
  };

  return { promoCodes, loading: state.loading, addPromoCode, togglePromoCode };
};
