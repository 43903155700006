const autoNgTemplateLoaderTemplate1 = require('./pagination-next-back.template.html');

'use strict';

angular
    .module('app')
    .directive('paginationNextBack', paginationNextBack);

paginationNextBack.$inject = [];

function paginationNextBack() {
    return {
        restrict: 'E',
        scope: {
            skip: '=',
            offset: '=',
            rowCount: '=',
            pagination: '=',
            onChange: '&onChange'
        },
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: 'PaginationNextBackController',
        controllerAs: 'vm'
    };
}
