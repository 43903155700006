'use strict';

angular
    .module('app')
    .controller('VideosTabController', VideosTabController);

VideosTabController.$inject = ['$scope', 'VideoService'];

function VideosTabController($scope, VideoService) {
    var vm = this;

    vm.getVideosForCustomer = getVideosForCustomer;

    vm.getVideosForCustomer();

    function getVideosForCustomer() {
        VideoService.getVideosForCustomer().then(function (res) {
            if (res.status === 200) {
                for (var i in res.data.categories) {
                    var currentCategory = res.data.categories[i];
                    var sortedVideos = currentCategory.videos.sort(VideoService.compareVideos);

                    switch (currentCategory.name) {
                        case 'Success factors':
                            vm.successVideo = sortedVideos;
                            break;
                        case 'Explainer videos':
                            vm.explainerVideo = sortedVideos;
                            break;
                        case 'FAQ':
                            vm.helpVideo = sortedVideos;
                            break;
                        case 'Training videos':
                            vm.trainingVideo = sortedVideos;
                            break;
                    }
                }
            }
        });
    }
}

