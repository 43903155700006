import React from 'react';

import LocalPrintshopRoundedIcon from '@mui/icons-material/LocalPrintshopRounded';
import { observer } from 'mobx-react';

import classes from './OptionRow.module.scss';
import { activeUsersCommunicationStore } from '../../../stores/activeUsersCommunicationStore';
import { OptionButton } from '../../../../shared/components/ui-components';

const OptionRow = observer(() => {
  const { printReport } = activeUsersCommunicationStore;

  return (
    <div className={classes.row}>
      <OptionButton
        tooltipText="Print filtered list"
        onClick={printReport}
        MuiIcon={LocalPrintshopRoundedIcon}
      />
    </div>
  );
});

export { OptionRow };
