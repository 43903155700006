import React from 'react';

import { observer } from 'mobx-react';

import { ButtonsRow } from '../../components/ButtonsRow/ButtonsRow';
import { setupEntityStore } from '../../../../stores/setupEntityStore';
import { EntityInbodyDataConfig } from '../../../../../inbody';

const InBodySetupStep = observer(() => {
  const { stepperMethods, entityId } = setupEntityStore;
  const { goToNextStep } = stepperMethods;

  if (!entityId) {
    throw new Error('Entity id is not defined');
  }

  const formId = 'inbodyDataForm';

  return (
    <div>
      <EntityInbodyDataConfig
        entityId={entityId}
        formId={formId}
        onSuccessSubmit={goToNextStep}
      />
      <ButtonsRow formId={formId} />
    </div>
  );
});

export { InBodySetupStep };
