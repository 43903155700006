import React from 'react';

import classes from './SegmentedBar.module.scss';
import { TotalAmountChartConfigItem } from '../TotalAmountChart.settings';
import { palette } from '../../../../../../shared/theme/palette';

type SegmentedBarProps = {
  config: TotalAmountChartConfigItem[];
  totalAmount: number;
};

const SegmentedBar = ({ totalAmount, config }: SegmentedBarProps) => {
  const showEmptyBar = totalAmount === 0;

  return (
    <div className={classes.bar}>
      {showEmptyBar ? (
        <div
          className={classes.segment}
          style={{ backgroundColor: palette.natural[200], width: '100%' }}
        />
      ) : (
        config.map(
          (item) =>
            item.percentage > 0 && (
              <div
                key={item.dbName}
                className={classes.segment}
                style={{ backgroundColor: item.color, width: `${item.percentage}%` }}
              />
            ),
        )
      )}
    </div>
  );
};

export { SegmentedBar };
