'use strict';

angular.module('app').factory('SalesService', SalesService);

SalesService.$inject = ['ApiReportService', 'ModalService', 'utilService'];

function SalesService(ApiReportService, ModalService, utilService) {
    return {
        getDashboard: function (prms) {
            var url = utilService.getLoggedInUserData().role === 'SUPERUSER' ? 'api/super/sales-dashboard/dashboard' : 'api/admin/sales-dashboard/dashboard';
            return ApiReportService.get(url, prms).then(function (res) {
                Object.keys(res.data).map(function (key) {
                    Object.keys(res.data[key]).map(function (item) {
                        if (item !== 'memberships') {
                            res.data[key][item] = (res.data[key][item] / 100).toFixed(2);
                        }
                    })
                });
                return res;
            }, function (err) {
                return err;
            });
        },
        getTransactions: function (prms) {
            var url = utilService.getLoggedInUserData().role === 'SUPERUSER' ? 'api/super/sales-dashboard/transactions' : 'api/admin/sales-dashboard/transactions';
            return ApiReportService.get(url, prms).then(function (res) {
                res.data.deposits.map(function (item) {
                    item.gross_revenue = (item.gross_revenue / 100).toFixed(2);
                    item.stripe_fees = (item.stripe_fees / 100).toFixed(2);
                    item.ekin_fees = (item.ekin_fees / 100).toFixed(2);
                    item.net = (item.net / 100).toFixed(2);
                });
                return res;
            }, function (err) {
                return err;
            });
        },
        getDeposits: function (prms) {
            var url = utilService.getLoggedInUserData().role === 'SUPERUSER' ? 'api/super/sales-dashboard/deposits' : 'api/admin/sales-dashboard/deposits';
            return ApiReportService.get(url, prms).then(function (res) {
                res.data.deposits.map(function (item) {
                    item.amount = (item.amount / 100).toFixed(2);
                });
                return res;
            }, function (err) {
                return err;
            });
        },
        getDownload: function (reportType, fileType, prms) {
            var role = utilService.getLoggedInUserData().role === 'SUPERUSER' ? 'super' : 'admin';
            var url = fileType === 'pdf' ? 'api/' + role + '/sales-report/download/' + reportType : 'api/' + role + '/sales-report/download-csv/' + reportType;
            return ApiReportService.get(url, prms, 'arraybuffer').then(function (res) {
                // todo test
                var linkElement = document.createElement('a');
                document.body.appendChild(linkElement);
                try {
                    var blob = new Blob([res.data], {type: 'application/' + fileType});
                    linkElement.href = window.URL.createObjectURL(blob);
                    linkElement.download = reportType + '-' + moment(new Date()).format('YYYY-MM-DD') + '.' + fileType;
                    linkElement.click();
                    setTimeout(function () {
                        window.URL.revokeObjectURL(blob);
                    }, 100);
                } catch (ex) {
                    ModalService.alert(ex, 'Exception');
                }
                return res;
            }, function (err) {
                return err;
            });
        },
    };
}

