import React from 'react';
import PropTypes from 'prop-types';

import { Paper } from '@mui/material';

import classes from './CustomerComplianceHistoryTable.module.scss';
import { TitleRow } from './TitleRow/TitleRow';
import { CustomerComplianceHistoryList } from '../../../../../components/CustomerComplianceHistoryList/CustomerComplianceHistoryList';

const CustomerComplianceHistoryTable = ({
  sortedComplianceHistory,
  averageComplianceInfo,
  sortMethods,
  customerId,
}) => {
  return (
    <div className={classes.complianceHistoryTable}>
      <TitleRow sortMethods={sortMethods} />
      <Paper variant="roundedBox">
        <CustomerComplianceHistoryList
          complianceHistory={sortedComplianceHistory}
          averageComplianceInfo={averageComplianceInfo}
          customerId={customerId}
        />
      </Paper>
    </div>
  );
};

const complianceInfoProps = {
  breakfast: PropTypes.number,
  daily: PropTypes.number,
  dinner: PropTypes.number,
  lunch: PropTypes.number,
  snack1: PropTypes.number,
  snack2: PropTypes.number,
  snack3: PropTypes.number,
  sodiumGoal: PropTypes.number,
  totalSodium: PropTypes.number,
  totalWater: PropTypes.number,
  waterGoal: PropTypes.number,
};

CustomerComplianceHistoryTable.propTypes = {
  sortMethods: PropTypes.object,
  sortedComplianceHistory: PropTypes.arrayOf(PropTypes.shape(complianceInfoProps)),
  averageComplianceInfo: PropTypes.shape(complianceInfoProps),
  customerId: PropTypes.number,
};

export { CustomerComplianceHistoryTable };
