import { getService } from 'react-in-angularjs';

import {
  paymentResultQueryParams,
  PaymentResultQueryParamsKeys,
} from '../../../constants/pathQueryParams';

export const getPaymentResultParams = (): Record<PaymentResultQueryParamsKeys, any> => {
  const $routeParams = getService('$routeParams');

  const params = Object.entries(paymentResultQueryParams).map(([key, value]) => {
    return {
      [key]: $routeParams[value],
    };
  });

  return Object.assign({}, ...params);
};
