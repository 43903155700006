import { apiService } from '../../shared/services/apiService';

type GetStripeConnectLinkResponse = { stripeUrl: string };

type GetPublishApiKeyResponse = {
  key: string;
  connectedAccountId: string; // not exist for super admin
};

type GetConnectedAccountIdResponse = {
  stripeAccountID: string;
};

type GetStripePublicKeyResponse = {
  key: string;
};

export const stripeService = {
  // used on create NEW flow entity page
  //   async getStripeConnectLinkBySuper(entityId: number, redirectPageUrl: string) {
  //     const params = { entityID: entityId, redirectSubUrl: redirectPageUrl };

  //     const resp = await apiService.get<GetStripeConnectLinkResponse>(
  //       'api/super/get-stripe-link',
  //       { params },
  //     );

  //     return resp.data;
  //   },

  async getStripeConnectLinkByEntity(entityId: number) {
    const resp = await apiService.get<GetStripeConnectLinkResponse>(
      `/api/entity/${entityId}/stripe/connect-account-link`,
    );

    return resp.data;
  },

  async getPublishApiKey() {
    const resp = await apiService.get<GetPublishApiKeyResponse>('api/publish-api-key');

    return resp.data;
  },

  async getConnectedAccountId(entityId: number) {
    const resp = await apiService.get<GetConnectedAccountIdResponse>(
      `/api/super/entity/${entityId}/stripe-account-id`,
    );

    return resp.data;
  },

  async getStripePublicKey() {
    const resp = await apiService.get<GetStripePublicKeyResponse>('api/payment/pubkey');

    return resp.data;
  },

  async updateCustomerCard(customerId: number, paymentMethodId: string) {
    const params = { paymentMethodId };

    const resp = await apiService.put<undefined>(
      `api/coach/card-details/${customerId}`,
      undefined,
      { params },
    );

    return resp.data;
  },
};
