import 'angular';
import 'angular-animate';
import 'angular-aria';
import 'angular-countries/dist/js/ngCountries';
import 'angular-drag-and-drop-lists/angular-drag-and-drop-lists';
import 'angular-material/angular-material';
import 'angular-messages/angular-messages';
import 'angular-resource/angular-resource';
import 'angular-route/angular-route';
import 'angular-sanitize/angular-sanitize';
import 'angular-treasure-overlay-spinner/src/treasure-overlay-spinner';
import 'angular-ui-bootstrap/dist/ui-bootstrap';
import 'angular-ui-bootstrap/dist/ui-bootstrap-tpls';
import 'angular-local-storage/dist/angular-local-storage';
import 'moment/moment';
import 'ng-file-upload/dist/ng-file-upload';
import 'ng-file-upload/dist/ng-file-upload-all';
import 'ng-file-upload/dist/ng-file-upload-shim';
import 'ng-idle/angular-idle';
import 'ng-meta/dist/ngMeta';
import 'ng-tags-input/build/ng-tags-input';
import './assets/libraries/autotrack';

import { requireAll } from './requireAll';

// auto import all js files in the 'app' directory except specs and which are in the 'modules' subdirectory
requireAll(require.context('./app/', true, /^(?!.*\.spec\.js|.*\/modules\/.*$).*\.js$/));
// auto import all *.config.js files from './app/modules/' directory, the config file needs to import before Ngz to create angular modules
requireAll(require.context('./app/modules/', true, /.*\.config.js$/));
// auto import all Ngz*.jsx and *.config.js files from './app/modules/' directory
requireAll(require.context('./app/modules/', true, /.*\/Ngz\w*\.(jsx|tsx)$/));
