import { TooltipItem } from 'chart.js';
import { getPriceView } from '../../../../../../shared/utils/stringUtils';

// https://www.chartjs.org/docs/latest/configuration/tooltip.html#label-callback
export const getTooltipLabel = (context: TooltipItem<'line'>) => {
  let label = context.dataset.label || '';

  if (label) {
    label += ' - ';
  }
  if (context.parsed.y !== null) {
    label += getPriceView(context.parsed.y);
  }
  return label;
};
