const autoNgTemplateLoaderTemplate1 = require('./supplement-setup-step-1.template.html');

'use strict';

angular
    .module('app')
    .directive('supplementSetupStep1', supplementSetupStep1);

supplementSetupStep1.$inject = [];

function supplementSetupStep1() {
    return {
        restrict: 'E',
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: 'supplementSetupStep1Controller',
        scope: {
            user: '=',
            submitBtnText: '=',
            title1: '=',
            title2: '=',
            submit: '&onSubmit'
        },
        controllerAs: 'vm',
        replace: true,
    };
}
