import React, { useEffect } from 'react';

import { angularize } from 'react-in-angularjs';
import { observer } from 'mobx-react';
import { Button, IconButton } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import classes from './NgzCheckReleasesInfo.module.scss';
import { loggedInUserDataStore } from '../../stores/loggedInUserDataStore';
import { goTo } from '../../utils/angularUtils';
import { releasesInfoStore } from '../../stores/releasesInfoStore';
import { SystemProviders } from '../../providers';

const NgzCheckReleasesInfo = observer(() => {
  const { loggedInUser, isCustomer, isFoodAdmin } = loggedInUserDataStore;
  const { isShowReleasesInfo, getReleasesInfo, closeReleasesInfo } = releasesInfoStore;

  useEffect(() => {
    const isGetUpdateInfo = loggedInUser?.id && !isCustomer && !isFoodAdmin;

    if (isGetUpdateInfo) {
      getReleasesInfo();
    }
  }, [loggedInUser]);

  const openReleasesPage = () => {
    goTo('/releases');
    closeReleasesInfo();
  };

  if (!isShowReleasesInfo) {
    return null;
  }

  return (
    <SystemProviders>
      <div className={classes.checkReleases}>
        <Button variant="unstyled" className={classes.linkBtn} onClick={openReleasesPage}>
          Check Last updates
        </Button>
        <IconButton disableRipple onClick={closeReleasesInfo}>
          <CloseRoundedIcon fontSize="medium" />
        </IconButton>
      </div>
    </SystemProviders>
  );
});

angularize(NgzCheckReleasesInfo, 'ngzCheckReleasesInfo', angular.module('app'), {});

export { NgzCheckReleasesInfo };
