'use strict';

angular.module('app').factory('StatsService', StatsService);

StatsService.$inject = ['$location', 'ApiService'];

function StatsService($location, ApiService) {
    return {
        getStatsLeaderboardBySuperadmin: function (prms) {
            return ApiService.get('api/coach/stats/leaderboard', prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getStatsLeaderboardUserPosition: function (id) {
            return ApiService.get('api/coach/user/' + id + '/stats/leaderboard-position').then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getStatsMembersAdded: function (prms) {
            return ApiService.get('api/super/stats/members-added', prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getRevenueReport: function (prms) {
            return ApiService.get('api/super/stats/revenue', prms).then(function (res) {
                res.data.map(function (value) {
                    value.ekinRevenue = value.ekinRevenue / 100;
                    value.entityRevenue = value.entityRevenue / 100;
                    value.grossRevenue = value.grossRevenue / 100;
                    value.merchantFees = value.merchantFees / 100;
                    value.otherFees = value.otherFees / 100;
                    value.salesTax = value.salesTax / 100;
                });
                return res;
            }, function (err) {
                return err;
            });
        },
        getSuperSummaryInfo: function (entity) {
            var prms = entity && entity.id ? {entityId: entity.id} : {entityId: null};

            return ApiService.get('api/super/stats/summary', prms).then(function (res) {
                    return res;
                }, function (err) {
                    return err;
                }
            );
        },
        getAmountOfUsersBilledData: function (prms) {
            return ApiService.get('api/super/stats/user-billed-chart', prms).then(function (res) {
                    res.data.data.reverse();
                    return res;
                }, function (err) {
                    return err;
                }
            );
        },
        getLocationMetrics: function (type, prms) {
            return ApiService.get('api/coach/stats/location-metrics/' + type, prms).then(function (res) {
                    res.data.data.reverse();
                    return res;
                }, function (err) {
                    return err;
                }
            );
        },
        getEntityDetailsById: function (id) {
            return ApiService.get('api/admin/stats/entity/' + id).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getAllEntitiesDetails: function () {
            return ApiService.get('api/super/stats/entity').then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getStatsKeyBodyMetrics: function (userId) {
            return ApiService.get('api/coach/user/' + userId + '/stats/body-metrics').then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
    };
}
