import React from 'react';

import { LbChip } from '../../../shared/components/ui-components';
import { SubscriptionType } from '../../../shared/constants/user-info/subscription';

type SubscriptionTypeChipProps = {
  subscriptionType: SubscriptionType;
};
// TODO add utils to get humanize subscription type
const SubscriptionTypeChip = ({ subscriptionType }: SubscriptionTypeChipProps) => {
  switch (subscriptionType) {
    case 'REGULAR':
      return <LbChip label="Regular Plan" size="small" />;
    case 'REACTIVATION':
      return <LbChip label="Reactivation" size="small" color="info" />;
    default:
      return <LbChip label={subscriptionType} size="small" />;
  }
};

export { SubscriptionTypeChip };
