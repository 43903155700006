import { useState } from 'react';

import { useAsyncFn } from 'react-use';

import {
  EntitySubscriptionPaymentInfo,
  InitialPlanPricingPaymentDetail,
  MonthlyRenewalPaymentDetail,
} from '../../../interfaces/entitySubscription';
import { entitySubscriptionService } from '../../../services/entitySubscriptionService';

type UsePaymentDetailsReturn = {
  initialPlanPricingPaymentDetail: InitialPlanPricingPaymentDetail | null;
  monthlyRenewalPaymentDetail: MonthlyRenewalPaymentDetail | null;
  getPaymentDetails: (paymentInfo: EntitySubscriptionPaymentInfo) => Promise<void>;
  isLoading: boolean;
};

export const usePaymentDetails = (entityId: number): UsePaymentDetailsReturn => {
  const [initialPlanPricingPaymentDetail, setInitialPlanPricingPaymentDetail] =
    useState<InitialPlanPricingPaymentDetail | null>(null);
  const [monthlyRenewalPaymentDetail, setMonthlyRenewalPaymentDetail] =
    useState<MonthlyRenewalPaymentDetail | null>(null);

  const handleGetPaymentDetails = async (paymentInfo: EntitySubscriptionPaymentInfo) => {
    if (!paymentInfo.subscriptionType) {
      return;
    }

    const { paymentDetails } =
      await entitySubscriptionService.getCalculatedPaymentDetails(entityId, paymentInfo);

    setInitialPlanPricingPaymentDetail(paymentDetails.initialPlanPricing ?? null);
    setMonthlyRenewalPaymentDetail(paymentDetails.monthlyRenewal ?? null);
  };

  const [state, getPaymentDetails] = useAsyncFn(handleGetPaymentDetails);

  return {
    initialPlanPricingPaymentDetail,
    monthlyRenewalPaymentDetail,
    getPaymentDetails,
    isLoading: state.loading,
  };
};
