import React from 'react';

import { GridTableRow } from '../../../../../../../shared/components/table-components';

type TitlesRowProps = {
  isMobile: boolean;
};

const TitlesRow = ({ isMobile }: TitlesRowProps) => {
  if (isMobile) {
    return null;
  }

  return (
    <GridTableRow variant="header">
      <p>Coach name</p>
      <p>Business Name</p>
    </GridTableRow>
  );
};

export { TitlesRow };
