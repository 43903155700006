import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';

import classes from './Stepper.module.scss';

const Stepper = ({ steps, activeStep }) => {
  const getStepClasses = (value) => {
    const activeClass = value <= activeStep - 1 ? classes.active : null;

    return `${classes.step} ${activeClass}`;
  };

  return (
    <Box className={classes.container}>
      {[...new Array(steps).keys()].map((value) => (
        <div className={getStepClasses(value)} key={value} />
      ))}
    </Box>
  );
};

Stepper.propTypes = {
  steps: PropTypes.number.isRequired,
  activeStep: PropTypes.number.isRequired,
};

export { Stepper };
