import React from 'react';

import classes from './Agreement.module.scss';

const Agreement = () => (
  <div className={classes.agreement}>
    <h2>
      User - Coaching Services Informed Consent, Disclosure Waiver and Release of
      Liability and Terms of Service Agreement
    </h2>
    <p>
      I agree to receive SMS text messages, emails with nutrition tips, recipes, and other
      educational materials from LifeBase.
    </p>
    <p>
      Welcome to LifeBase. We believe that transparency is the key to any healthy
      relationship. We appreciate that you trust us with information that is important to
      you, the “User” (collectively referred to throughout as “I”, “you”, “me”, “my” or
      “myself”), and we want to be transparent about how we use it. We appreciate that you
      have chosen to use one of our services. You will learn about the coaching services,
      platform suggestions, cancellation policies, data we collect, how we use it, the
      controls we give you over your information, and the measures we take to keep it
      safe.
    </p>

    <p>
      LifeBase Solutions LLC (“LifeBase”) and its affiliates and subsidiaries, including,
      but not limited to ekin Nutrition LLC (“ekin” or “ekin Nutrition) (collectively
      referred to throughout as “LifeBase,” “we”, “us” or “the Company”), offer a variety
      of products and services, including, but not limited to the LifeBase and ekin
      Nutrition SaaS application(s), coaching dashboard, macro exchange tracker app,
      hardware, coaching services, platform suggestions, cancellation policies, software,
      APIs, products, blogs, applications, devices, hardware, content and other technology
      products and services; our e-commerce websites and applications, fitness and
      wellness related websites and all other current and future digital products and
      service offerings, nutrition products or nutritional supplements we make available,
      including any products and services provided in collaboration with our partners
      (collectively referred to throughout as, the “Services” or the “Platform”). We are
      driven to create Services that enhance the lives and performance of all wellness
      seekers and athletes everywhere, and thatʼs why we refer to online and offline
      community members as “athletes.”
    </p>

    <p>
      These Terms and Conditions of Use (collectively referred to throughout as “Terms”,
      “TOS” or “Agreement”) govern your access to and use of our Services. Please read the
      Terms carefully before using our Services, as they are a legally binding contract
      between you and LifeBase. Your access and use of the Services (regardless of whether
      you create an account with us), constitutes your agreement to these Terms and our
      Privacy Policy, which is incorporated into the Terms. Stated alternatively, if you
      disagree with any part of the Terms, then you are not permitted to use our Services.
      Further, by accessing and using our Services, you understand and agree that your
      Personal Data (as that term is defined in our Privacy Policy) and Coach-Generated
      Content that is shared with a Service may also be shared among the entirety of
      LifeBase and its other Services.
    </p>

    <p>
      Please contact us through our Support Team if you have any questions via email at
      <a href="mailto:support@lifebasesolutions.com"> support@lifebasesolutions.com</a>.
    </p>

    <h2>COACHING SERVICES</h2>

    <p>
      I acknowledge, fully understand and accept that the LifeBase Solutions Nutrition
      Platform (“Platform”) enables one to one Coaching Services. I will receive automated
      Coaching from the Platform and the Platform will enable me to communicate with a
      live health, fitness, or wellness Coach (collectively “Coaching Services”) that is
      non-medical in nature. My personal nutrition coach (“Coach”) will typically be
      someone with which I have contracted for his or her services. However, Coaches may
      be provided by third parties, such as my employer, school, insurance company, or by
      third-party Coaching service providers (“Coach”). I understand, certify, consent and
      agree that during and after voluntarily signing up for the Platform and
      participating in Coaching Services from LifeBase the following:
    </p>

    <ul>
      <li>
        The Platform is designed to allow me to improve and make informed decisions about
        my health, but the Coaching Services are not meant to take the place of a primary
        care physician, they are not a substitute for a medical examination and neither my
        Coach or the Platform functions as a physician, to diagnose or treat disease, nor
        do these services replace the necessary services of a licensed physician. A
        nutrition consultation as provided by a Coach does not constitute a medical
        service or health care treatment. I am strongly encouraged that in addition to any
        health consultation, that I maintain a relationship with one or more physicians
        qualified to care for my health condition(s).
      </li>
      <li>
        It may be important to alert my primary healthcare provider to the fact that I’m
        consulting with my Coach as an adjunct to traditional care. Coaching Services may
        alter the need or efficacy of medications therefore it is imperative to fully
        disclose all medications and supplements at the initial consultation and to my
        primary healthcare provider. The Coaching Services provided are not considered to
        be medical advice and that I am encouraged to continue to pursue medical care with
        my healthcare provider.
      </li>
      <li>
        I assume all responsibility and any risks associated with the nutritional choices
        that I make. I agree to hold the Company harmless and release them from any
        liabilities associated with suggestions and information given by them to me
        relating to dietary changes or nutritional supplements. I specifically recognize
        and agree that I have been advised by the Company that dietary changes and/or the
        taking of nutritional supplements may have differing effects on individuals. I
        understand that with respect to changes in my diet or in my nutritional practices
        it is suggested that I consult with my physician and primary healthcare provider.
      </li>
      <li>
        I verify and confirm that I am physically healthy and have NOT been advised to NOT
        participate in the Platform by a qualified medical professional. I have NOT been
        advised to discontinue treatments with any other medical specialists or other
        health care providers. I assume full responsibility for any injuries or damages,
        known or unknown, sustained by participating in any Coaching Services and waive
        any claim I may have against Company.
      </li>
      <li>
        My acceptance is being given prior to my Coach rendering any service, advice,
        and/or suggestions whatsoever.
      </li>
    </ul>

    <h2>PLATFORM SUGGESTIONS</h2>

    <p>
      I acknowledge, fully understand and accept that the suggestions of the Platform are
      based upon a combination of the Company’s nutrition and health coaching education
      and knowledge of natural health literature and the company makes no representations,
      claims, or guarantees regarding the efficacy of suggestions. Individualized
      suggestions are offered and applied as an educational and informative consultation.
      Any action taken as a result of the consultation is done at the sole discretion and
      risk of User.
    </p>

    <p>
      The Company provides no guarantee or assurances that through Coaching Services, I
      will achieve my wellness goals, lose weight, or overcome or avoid health issues,
      such as cardiovascular disease or diabetes. I acknowledge, fully understand and
      accept that the platform has limitations including, but not limited to:
    </p>

    <ul>
      <li>
        The nutritional food database utilized by the Platform is provided by a
        third-party and as such should not be assumed accurate.
      </li>
      <li>
        The suggestions provided by the platform, including but not limited to food
        suggestions or macro changes are based upon a number of data points, including the
        accuracy of the input data by the User and as such, cannot be assumed reliable.
      </li>
      <li>
        My Coach has the ability and choice to override recommendations made by the
        Platform.
      </li>
      <li>
        The Platform automatically reviews and creates scores, including but not limited
        to a Compliance Score, based upon the input data by the User. These scores
        accuracy are limited by a number of factors including the accuracy of the input
        data by the User and therefore cannot be assumed to be reliable, nor can obtaining
        specific Compliance Scores guarantee the user goals may, can or will be achieved.
      </li>
    </ul>

    <h2>DATA AND INFORMATION</h2>

    <p>
      I have reviewed, acknowledge, fully understand and accept the Company Privacy Policy
      <a href="https://dashboard.lifebase.solutions/privacyoverview.html">
        https://dashboard.lifebase.solutions/privacyoverview.html
      </a>
      (“Privacy Policy”), which includes, but is not limited to: Information We Collect,
      How We Use Information, Control: Your Choices, Rights To Access Your Personal Data,
      Data Retention, Analytics And Advertising Services Provided By Others, Our Policies
      For Children, How We Secure Information, Risks and Considerations, Business
      Transactions, Key Definitions and VU Information and I understand that:
    </p>

    <ul>
      <li>
        The use of the Coaching Services, will allow the Company to collect information
        about such use, including but not limited to: the plan, goals, and actions I
        record with my Coach, food entries and results, my calendar events, communications
        with my Coach, notes my Coach records about me, and other information submitted by
        me or my Coach. This information also can generally be categorized as Registration
        Information, Self-Reported Information, and/or User Content (collectively called
        Volunteered User Information or “VU Information”) as found in the Privacy Policy
        (“Privacy Policy”)
        <a href="https://dashboard.lifebase.solutions/privacyoverview.html">
          https://dashboard.lifebase.solutions/privacyoverview.html
        </a>
        or in the full Privacy Statement (“Privacy Statement”).
      </li>
      <li>
        Periodically (generally weekly) I will measure my body on a bioelectrical
        impedance, body composition analyzer (“Analyzer(s)”) that utilizes a direct
        segmental multi-frequency analysis method, to provide data to the Platform. The
        Company exclusively owns the rights to my data generated by the Analyzer(s) and I
        allow the Company to hereby perpetually convey, assign, and exclusively license
        all rights of use to the Entity User Data (“the Data”) and Entity data derived,
        generated or outputted from the Analyzer(s), including, but not limited, to the
        Analyzer(s) and the Data used in conjunction with the Platform, collectively
        called the VU Information. These rights will survive the expiration or termination
        of this User Agreement.
      </li>
      <li>
        My consent will continue forever for both my identified data and de-identified
        data, unless I cancel my consent to my non de-identified data, in writing to the
        Company at LifeBase Solutions LLC 4800 S Louise Ave #308, Sioux Falls, SD 57106 or
        I use the Platform account settings and tools to withdraw my consent including by
        stopping the use of the Platform, removing my access to a third-party service, or
        deleting my non de-identified data or my account. However, this will not affect
        any information that has already been disclosed.
      </li>
      <li>
        A person to whom records and information are disclosed pursuant to this User
        Agreement, may not further use or disclose the medical information unless
        additional authorization is obtained from me or unless such disclosure is
        specifically required or permitted by law.
      </li>
      <li>
        I grant the Company the right and permission to take, use, re-use, publish, and
        republish photographic portraits or pictures of me or in which I may be included,
        in whole or in part, or composite or distorted in character or form, without
        restriction as to changes or alterations, in conjunction with my name or a
        fictitious name, or reproductions thereof in color or otherwise, made through any
        medium at the Company, and in any and all media now or hereafter known for
        illustration, promotion, art, editorial, advertising, trade, or any other purpose
        whatsoever, both separately and collectively in conjunction with my VU
        Information. I also consent to the use of any published matter in conjunction
        therewith. I hereby waive any right that I may have to inspect and approve the
        finished product or copy that may be used in connection with an image that the
        Company has taken, or the use to which it may be applied. I further release the
        Company from any claims for remuneration associated with any form of damage,
        foreseen or unforeseen, associated with the proper commercial or artistic use of
        these images unless it can be shown that said reproduction was maliciously caused,
        produced and published for the sole purpose of subjecting me to conspicuous
        ridicule, scandal, reproach, scorn and indignity. I acknowledge that the photos,
        if any, were taken in a completely proper and highly professional manner, and this
        release in advance is willingly signed.
      </li>
    </ul>

    <h2>TERMINATION OR CANCELLATION OF PREMIUM SERVICES</h2>

    <p>
      I have reviewed, acknowledge, fully understand and accept the Cancellation Policy.
      Services and membership programs are collectively referred to as “Premium Services”.
      The Cancellation Policy is as follows:
    </p>
    <ul>
      <li>
        If you do not pay the fees or charges due for your use of the Premium Services, we
        may make reasonable efforts to notify you and resolve the issue; however, we
        reserve the right to disable or terminate your access to the Premium Services (and
        may do so without notice). You can cancel the Premium Services at any time. Once
        you have cancelled your Premium Service and received confirmation, no other
        changes can be made to your account. The cancellation of a Premium Service will go
        into effect at the end of your current billing cycle, and you will have the same
        level of access to the Premium Service through the remainder of such billing
        cycle. For example, if you are billed on a monthly basis and cancel during a given
        month, you will be charged for the entirety of that month and maintain access to
        the Premium Service through the end of that month. There are no refunds for
        termination or cancellation of your Premium Service. If you no longer wish to
        subscribe to a Premium Service, it is your responsibility to cancel your Premium
        Service in due time, regardless of whether or not you actively use the Premium
        Service.
      </li>
    </ul>

    <h2>REFUNDS AND CANCELATION (USER SUBSCRIPTIONS)</h2>
    <p>
      Any requests to reactivate, cancel, change, or refund a LifeBase subscription must
      be handled through a user&apos;s Coach and/or Entity Admin.
    </p>
    <p>
      If/when a subscription is cancelled, users will still have access for the remaining
      time purchased. For example:
    </p>
    <p>
      Paid for 3 months on July 1, 2024. <br />
      Cancelled on Aug 8, 2024. <br />
      Access until October 1, 2024 (the full 3 months) <br />
    </p>
    <p>
      Paid for 1 month on February 10, 2024. <br />
      Cancelled on March 3, 2024. <br />
      Access until March 10, 2024. <br />
    </p>
    <p>
      Any refund requests must be handled by the user&apos;s Coach or Entity Admin. The
      associated LifeBase Fees will not be refunded to end users. Any, and all refunds,
      are the responsibility of the Coach or Entity Admin.
    </p>

    <h2>USER CERTIFIES AND RELEASES</h2>

    <p>
      I certify that I am not a minor, and am free and able to give such consent. In
      consideration for being allowed to utilize the Platform, on behalf of myself and my
      next of kin, heirs and representatives, I release from all liability and promise not
      to sue the Company and its employees, officers, directors, volunteers and agents
      from any and all claims, including claims of negligence, resulting in any physical
      or psychological injury (including paralysis and death), illness, damages, or
      economic or emotional loss I may suffer because of my use of the Platform. I agree
      to hold the Company harmless from any and all claims, including attorney’s fees,
      that may occur as a result of my use of the Platform. If the Company incurs any of
      these types of expenses, I agree to reimburse the Company. If I need medical
      treatment, I agree to be fully financially responsible for any costs incurred as a
      result of such treatment. I am aware and understand that I should carry my own
      health insurance. I understand the legal consequences of signing this document,
      including (a) releasing the Company from all liability, (b) promising not to sue the
      Company, (c) and assuming all risks of utilizing the Platform. I understand that
      this document is written to be as broad and inclusive as legally permitted by the
      State of South Dakota. I agree that if any portion is held invalid or unenforceable,
      I will continue to be bound by the remaining terms. I have read this document, and I
      am signing it freely. No other representations concerning the legal effect of this
      document have been made to me.
    </p>
    <p>
      The Company reserves the right, in its sole discretion, to cancel, remove, modify,
      or suspend the User from the Platform for any reason whatsoever or for no reason.
      Any and all disputes, claims, and causes of action arising out of or in connection
      with the use of the Platform, shall be resolved individually, without resort to any
      form of class action.
    </p>
  </div>
);

export { Agreement };
