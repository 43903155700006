(function () {
    'use strict';

    angular.module('app.coachDashboard').controller('CoachDashboardController', CoachDashboardController);

    CoachDashboardController.$inject = [];

    function CoachDashboardController() {
        var vm = this;

        vm.statisticTab1 = 'all_1';
        vm.statisticTab2 = 'all_2';

        vm.statisticData1 = [
            {
                name: 'Total',
                users: 11,
                age: 100,
                prct: 100,
                avgMembershipLength: 17,
                avgComplianceScore: 67
            },
            {
                name: 'Males',
                users: 5,
                age: 67,
                prct: 67,
                avgMembershipLength: 17,
                avgComplianceScore: 67
            },
            {
                name: 'Females',
                users: 6,
                age: 33,
                prct: 33,
                avgMembershipLength: 17,
                avgComplianceScore: 67
            }
        ];

        vm.statisticData2 = [
            {
                name: 'Total',
                poundsOfFatLost: 5.7,
                bodyFatDrop: 4.25,
                poundsMuscleGained: 2.2
            },
            {
                name: 'Males',
                poundsOfFatLost: 5.7,
                bodyFatDrop: 4.25,
                poundsMuscleGained: 2.2
            },
            {
                name: 'Females',
                poundsOfFatLost: 5.7,
                bodyFatDrop: 4.25,
                poundsMuscleGained: 2.2
            }
        ];
    }

})();
