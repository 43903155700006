import { ValueOfKey } from '../../../utils-types';

export const NAV_ITEM_TYPE = {
  LINK: 'LINK',
  MENU: 'MENU',
  ADDITIONAL_LINKS: 'ADDITIONAL_LINKS',
} as const;

export type NavItemType = ValueOfKey<typeof NAV_ITEM_TYPE>;

export const PATH_TYPE = {
  EXTERNAL_LINK: 'EXTERNAL_LINK',
  LOCAL_PATH: 'LOCAL_PATH',
} as const;

export type PathType = ValueOfKey<typeof PATH_TYPE>;
