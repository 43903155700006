import React, { ReactNode } from 'react';

import classNames from 'classnames';

import classes from './ModalInputGroup.module.scss';

type ModalInputGroupProps = {
  children: ReactNode;
  align?: 'center' | 'end' | 'start' | 'stretch' | 'baseline';
  className?: string;
};
/**
 * @description use to create row or subGroup in ModalInputBox
 */
const ModalInputGroup = ({
  children,
  align = 'center',
  className,
}: ModalInputGroupProps) => {
  const groupClasses = classNames(classes.group, classes[`align-${align}`], className);

  return <div className={groupClasses}>{children}</div>;
};

export { ModalInputGroup };
