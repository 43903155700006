import React from 'react';

import { TabPanel } from '@mui/base';

import { TabPanelOption, TabsConfig } from '../types/TabsConfig';

type LbTabsPanelProps = {
  tabsConfig: TabPanelOption[] | TabsConfig[];
};

const LbTabPanel = ({ tabsConfig }: LbTabsPanelProps) => {
  return tabsConfig.map((tab) => (
    <TabPanel value={tab.value} key={tab.value}>
      {tab.panel}
    </TabPanel>
  ));
};

export { LbTabPanel, LbTabsPanelProps };
