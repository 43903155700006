'use strict';

angular.module('app').factory('GeneralService', GeneralService);

GeneralService.$inject = ['ApiService', 'ModalService', 'AuthService', '$rootScope'];

function GeneralService(ApiService, ModalService, AuthService,  $rootScope) {
    return {
        disableEnableUser: function (userId, prms) {
            return ApiService.put('api/activate/user/' + userId, null, prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getEntityAdmin: function (id) {
            return ApiService.get('api/super/entity/' + id + '/admin').then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        changePassword: function (data) {
            return ApiService.put('api/general/change-password', data).then(function (res) {
                ModalService.alert(null, 'Password was changed. You will be logged out from the system automatically. Please login to the system with a new password.', AuthService.signOut);
                return res;
            }, function (err) {
                return err;
            });
        },
        setPassword: function (id, data) {
            return ApiService.put('api/general/set-password/' + id, data).then(function (res) {
                ModalService.savedChanges();
                return res;
            }, function (err) {
                return err;
            });
        },
        checkPassword: function (form, password, repeatPassword, passwordsObj) {
            if (passwordsObj[password]) {
                form[password].$setValidity('format', passwordsObj[password].length >= 8 && passwordsObj[password].length <= 16);
                form[repeatPassword].$setValidity('match', passwordsObj[password] === passwordsObj[repeatPassword]);
            }
        },
        checkCellPhone: function(form, name, value) {
            var count = value.replace(/[^0-9]/g,"").length;
            form[name].$setValidity('phoneLength', count === 10 || count === 11 || value === '');
        },
        updateUserInfo: function (id, data) {
            return ApiService.put('api/userprofile/' + id, data).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getStripePublicKey: function () {
            return ApiService.get('api/payment/pubkey').then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        deleteUserBySuper: function (userId) {
            return ApiService.delete('api/super/delete-user/' + userId).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        }
    };
}

