import { Paper } from '@mui/material';
import React from 'react';

import classes from './MealPlanTitleRow.module.scss';
import {
  GridTableCell,
  GridTableRow,
} from '../../../../../../shared/components/table-components';

const MealPlanTitleRow = () => {
  return (
    <Paper variant="roundedBox" className={classes.row}>
      <h5 className={classes.title}>Meal Plan</h5>
      <GridTableRow variant="unstyled" className={classes.header}>
        <GridTableCell>Name</GridTableCell>
        <GridTableCell>Proteins</GridTableCell>
        <GridTableCell>Carbs</GridTableCell>
        <GridTableCell>Fats</GridTableCell>
        <GridTableCell>Calories</GridTableCell>
      </GridTableRow>
    </Paper>
  );
};

export { MealPlanTitleRow };
