(function () {
    'use strict';

    angular.module('app.userProfile')
        .controller('UserProfileController', UserProfileController);

    UserProfileController.$inject = ['$scope', 'utilService','BaseUserUtils'];

    function UserProfileController($scope, utilService, BaseUserUtils) {
        var vm = this;
        vm.loggedInUser = utilService.getLoggedInUserData();
        vm.spinner = {active: false};
        vm.preMainTabName = 'userMealPlanMacros';
        vm.showRecipeList = true;
        vm.selectedRecipeId = null;

        vm.changeAvatar = changeAvatar;
        vm.updateUser = updateUser;
        vm.changeTab = changeTab;
        vm.goAddRecipe = goAddRecipe;

        $scope.$on('updateUser', vm.updateUser);

        $scope.$on('selectRecipe', function(event, opt) {
            vm.selectedRecipeId = opt.recipeId;
            vm.showRecipeList = false;
        });

        $scope.$on('goToRecipeList', function() {
            vm.showRecipeList = true;
            vm.selectedRecipeId = null;
        });

        function updateUser() {
            vm.loggedInUser = utilService.getLoggedInUserData();
        }

        function changeAvatar() {
            BaseUserUtils.updateAvatar(vm.userAvatar, vm.loggedInUser)
        }

        function changeTab(value) {
            vm.preMainTabName = value;
            if(value === 'userRecipes') {
                vm.showRecipeList = true;
                vm.selectedRecipeId = null
            }
        }

        function goAddRecipe() {
            vm.showRecipeList = false;
        }
    }
})();
