import React from 'react';

import { angularize } from 'react-in-angularjs';
import { Paper } from '@mui/material';

import classes from './NgzCustomerGoHighLevelWidget.module.scss';
import { SystemProviders } from '../../../shared/providers';
import { useCustomerGoHighLevelConnection } from './hooks/useCustomerGoHighLevelConnection';
import { ConnectionManagement } from './ConnectionManagement/ConnectionManagement';
import { TitleSection } from './TitleSection/TitleSection';

type NgzCustomerGoHighLevelWidgetProps = {
  customerId: number;
};

const NgzCustomerGoHighLevelWidget = ({
  customerId,
}: NgzCustomerGoHighLevelWidgetProps) => {
  const { accountInfo, refreshConnectionInfo } =
    useCustomerGoHighLevelConnection(customerId);

  return (
    <SystemProviders>
      <Paper variant="boxWithPadding" className={classes.box}>
        <TitleSection contactId={accountInfo?.contactId} />
        <ConnectionManagement
          contactId={accountInfo?.contactId}
          onRefresh={refreshConnectionInfo}
        />
      </Paper>
    </SystemProviders>
  );
};

angularize(
  NgzCustomerGoHighLevelWidget,
  'ngzCustomerGoHighLevelWidget',
  angular.module('app'),
  { customerId: '<' },
);

export { NgzCustomerGoHighLevelWidget };
