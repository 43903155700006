import { makeAutoObservable } from 'mobx';
import { resetAllStore } from '../../shared/stores/resetAllStore';
import { getArrOfUniqueObj } from '../../shared/utils/sortingAndGroupingUtil';
import { supplementsService } from '../services/supplementsService';

class SupplementsStore {
  constructor() {
    this.setDefaultValues();
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.supplements = [];
    this.lastAdded = [];
  }

  async getSupplements() {
    const data = await supplementsService.getSupplements();
    this.setSupplements(data.supplements);
    this.setLastAdded(data.lastAdded);
  }

  getSupplementById(supplementId) {
    return this.supplements.find((supplement) => supplement.id === supplementId);
  }

  setToLastAdded(supplements) {
    const uniqueLastAdded = getArrOfUniqueObj([...this.lastAdded, ...supplements], 'id');

    this.setLastAdded(uniqueLastAdded);
  }

  setSupplements(supplements) {
    this.supplements = supplements;
  }

  setLastAdded(lastAdded) {
    this.lastAdded = lastAdded;
  }
}

const supplementsStore = new SupplementsStore();

resetAllStore.addResetMethod(supplementsStore.setDefaultValues);

export { SupplementsStore, supplementsStore };
