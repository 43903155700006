import React from 'react';
import PropTypes from 'prop-types';

import classes from './SupplementsValuesTab.module.scss';
import { HeaderRow } from './HeaderRow/HeaderRow';
import { InventoryRow } from '../../../../pages/customers-dashboard/components/InventoryRow/InventoryRow';

const SupplementsValuesTab = ({ customerId, orderInventory }) => {
  return (
    <div className={classes.tableBox}>
      <HeaderRow />
      {orderInventory.map((inventory) => (
        <InventoryRow
          inventoryInfo={inventory}
          key={inventory.inventoryID}
          customerId={customerId}
        />
      ))}
    </div>
  );
};

SupplementsValuesTab.propTypes = {
  customerId: PropTypes.number,
  orderInventory: PropTypes.array,
};

export { SupplementsValuesTab };
