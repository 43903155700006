import React from 'react';
import PropTypes from 'prop-types';

import { Badge, Link } from '@mui/material';

import classes from './LabTestCell.module.scss';
import { getLinkToCustomerProfile } from './LabTestCell.settings';
import { getDaysToTestTitle } from '../../../../utils/labTestUtils';
import { getDaysToDate } from '../../../../../shared/utils/dateUtils';
import { LabTestInfoIcon } from '../../../lab-test-components';

const LabTestCell = ({ customerId, labTestStartDate, labTestCount }) => {
  const daysToTest = getDaysToDate(labTestStartDate);
  const daysToTestTitle = getDaysToTestTitle(daysToTest);
  const linkToCustomerProfile = getLinkToCustomerProfile(customerId);

  return (
    <Link className={classes.container} href={linkToCustomerProfile}>
      <Badge
        badgeContent={labTestCount}
        invisible={labTestCount === 1}
        color="secondary"
        variant="small"
        overlap="circular"
      >
        <LabTestInfoIcon daysToTest={daysToTest} />
      </Badge>
      <p>{daysToTestTitle}</p>
    </Link>
  );
};

LabTestCell.propTypes = {
  customerId: PropTypes.number,
  labTestStartDate: PropTypes.string,
  labTestCount: PropTypes.number,
};

export { LabTestCell };
