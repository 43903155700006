import React from 'react';

import { angularize } from 'react-in-angularjs';
import { Container } from '@mui/material';

import classes from './NgzCustomerMealReportsTab.module.scss';
import { SystemProviders } from '../../../../../shared/providers';
import { TabHeader } from './TabHeader/TabHeader';
import { MealInfoTable } from '../../../../components/MealInfoTable/MealInfoTable';
import { DailyMealTable } from '../../../../components/DailyMealTable/DailyMealTable';
import { EmptyList } from '../../../../../shared/components/ui-components';
import useMealReportsTabData from './hooks/useMealReportsTabData';

const NgzCustomerMealReportsTab = () => {
  const { mealReportsInfo, getAdaptedMealReports } = useMealReportsTabData();

  return (
    <SystemProviders>
      <Container className={classes.reportsTabContainer}>
        <TabHeader getAdaptedMealReports={getAdaptedMealReports} />
        {mealReportsInfo ? (
          <>
            {mealReportsInfo.mealReports.map((report) => (
              <MealInfoTable key={report.name} mealDetails={report} isReport />
            ))}
            <DailyMealTable dailyResult={mealReportsInfo.dailyResult} />
          </>
        ) : (
          <EmptyList show description="There is no meal report" />
        )}
      </Container>
    </SystemProviders>
  );
};

angularize(
  NgzCustomerMealReportsTab,
  'ngzCustomerMealReportsTab',
  angular.module('app'),
  {},
);

export { NgzCustomerMealReportsTab };
