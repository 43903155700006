import { Components } from '@mui/material';

export const MuiStepperTheme: Components['MuiStepper'] = {
  styleOverrides: {
    root: {
      backgroundColor: 'var(--white)',
      borderRadius: '8px',
      padding: '18px 20px',
      boxShadow: 'var(--panel-shadow)',
      gap: '12px',

      '.MuiStep-root': {
        padding: '0px',
      },

      '.MuiStepButton-root': {
        '&:hover': {
          backgroundColor: 'transparent',
        },

        '&:disabled': {
          backgroundColor: 'transparent',
        },
      },

      '& .MuiStepLabel-label': {
        color: 'var(--natural-500)',
        font: 'var(--title-16-bold)',

        '&.Mui-completed': {
          color: 'var(--main-black)',
          font: 'var(--title-16-bold)',
        },

        '&.Mui-active': {
          color: 'var(--main-black)',
          font: 'var(--title-16-bold)',
        },
      },

      '& .MuiStepIcon-root circle': {
        display: 'none',
      },
      '& .MuiStepIcon-root': {
        border: '1px solid',
        borderColor: 'var(--natural-500)',
        borderRadius: '100%',
        width: '28px',
        height: '28px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '& .MuiStepIcon-text ': {
        fill: 'var(--natural-500)',
      },
      '& .MuiStepIcon-root.Mui-active': {
        borderColor: 'var(--main-black)',
      },
      '& .Mui-active .MuiStepIcon-text': {
        fill: 'var(--main-black) ',
      },
      '& .MuiStepIcon-root.Mui-completed': {
        fill: 'var(--main-black)',
        border: 'none',
      },

      '.MuiStepConnector-line': {
        borderColor: 'var(--natural-300)',
      },

      '.Mui-completed, .Mui-active': {
        '& .MuiStepConnector-line': {
          borderColor: 'var(--main-black)',
        },
      },
    },
  },
};
