'use strict';

angular.module('app').factory('CertificationsService', CertificationsService);

CertificationsService.$inject = ['ApiService', 'utilService'];

function CertificationsService(ApiService, utilService) {
    return {
        getAllCoaches: function (prms) {
            return ApiService.get('api/admin/certificates/coaches', prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getAvailableCertificates: function () {
            return ApiService.get('api/admin/certificates/qty').then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        assignCertificate: function (coachId, certType) {
            return ApiService.post('api/admin/certificates/assign/' + coachId + '/' + certType).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        buyBundleOfCertificates: function (prms, planId, entityId) {
            var url = utilService.getLoggedInUserData().role === 'SUPERUSER' ? 'api/super/certificates/buy/' + planId + '/' + entityId : 'api/admin/certificates/buy/' + planId;
            return ApiService.post(url, null, prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        updateEntityCertificates: function (id, data) {
            return ApiService.put('api/super/entity/' + id + '/certificates', data).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getEntityCertificates: function (id) {
            var url = utilService.getLoggedInUserData().role === 'SUPERUSER' ? 'api/super/entity/' + id + '/certificates': 'api/admin/entity/certificates';
            return ApiService.get(url).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        createAccount: function (id) {
            return ApiService.post('api/admin/certificates/create-account/' + id).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        }
    };
}

