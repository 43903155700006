import { useEffect, useMemo, useState } from 'react';

import {
  GoHighLevelAccountInfo,
  GoHighLevelConnectionInfo,
} from '../../../interfaces/goHighLevelAccount';
import { goHighLevelAccountService } from '../../../services/goHighLevelAccountService';
import { getFlagsObject } from '../../../../shared/utils/sortingAndGroupingUtil';
import {
  GO_HIGH_LEVEL_ACCOUNT_STATUS,
  GoHighLevelAccountStatusFlags,
} from '../../../constants/goHighLevelAccountStatus';

type UseGoHighLevelConnectionReturn = {
  connectionInfo: GoHighLevelConnectionInfo;
  createConnection: (locationId: string) => Promise<void>;
  updateAccountInfo: (locationId: string) => Promise<void>;
  removeConnection: () => Promise<void>;
  refreshConnectionInfo: () => Promise<void>;
};

export const useGoHighLevelConnection = (
  entityId: number,
): UseGoHighLevelConnectionReturn => {
  const [accountInfo, setAccountInfo] = useState<GoHighLevelAccountInfo | undefined>(
    undefined,
  );

  const accountStatusFlags: GoHighLevelAccountStatusFlags = useMemo(
    () => getFlagsObject(GO_HIGH_LEVEL_ACCOUNT_STATUS, [accountInfo?.status]),
    [accountInfo?.status],
  );

  const connectionInfo: GoHighLevelConnectionInfo = useMemo(
    () =>
      accountInfo
        ? { isConnected: true, accountInfo, accountStatusFlags }
        : { isConnected: false, accountInfo: undefined, accountStatusFlags: undefined },
    [accountInfo],
  );

  const getAccountInfo = async () => {
    const resp = await goHighLevelAccountService.getConnectedAccountInfo(entityId);

    setAccountInfo(resp);
  };

  useEffect(() => {
    getAccountInfo();
  }, [entityId]);

  const createConnection = async (locationId: string) => {
    const resp = await goHighLevelAccountService.createConnection(entityId, locationId);

    setAccountInfo(resp);
  };

  const updateAccountInfo = async (locationId: string) => {
    const resp = await goHighLevelAccountService.updateConnectedAccountInfo(
      entityId,
      locationId,
    );

    setAccountInfo(resp);
  };

  const removeConnection = async () => {
    await goHighLevelAccountService.removeConnection(entityId);

    setAccountInfo(undefined);
  };

  const refreshConnectionInfo = async () => {
    const resp = await goHighLevelAccountService.refreshConnectionInfo(entityId);

    setAccountInfo(resp);
  };

  return {
    connectionInfo,
    createConnection,
    updateAccountInfo,
    removeConnection,
    refreshConnectionInfo,
  };
};
