import React from 'react';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { SvgIconOwnProps } from '@mui/material';

import classes from './CheckList.module.scss';

type CheckListProps = {
  title: string;
  iconColor: SvgIconOwnProps['color'];
  list: string[];
};

const CheckList = ({ title, iconColor, list }: CheckListProps) => {
  return (
    <div>
      <h6 className={classes.title}>{title}</h6>
      <ul className={classes.list}>
        {list.map((item) => (
          <li className={classes.item} key={item}>
            <CheckRoundedIcon fontSize="medium" color={iconColor} />
            <span>{item}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export { CheckList };
