import { useEffect, useMemo, useState } from 'react';

import { schoolService } from '../../../services/schoolService';
import { loggedInUserDataStore } from '../../../../shared/stores/loggedInUserDataStore';
import { SchoolInfo } from '../../../interfaces/SchoolInfo';
import { transformToOptions, addAllOption } from '../../../../shared/utils/arrayUtils';
import { Option } from '../../../../general-types';

type UseSchoolListReturn = {
  schoolList: SchoolInfo[];
  schoolListOption: Option[];
  schoolListOptionWithAll: Option[];
};

type UseSchoolListProps = {
  onlyActive?: boolean;
  isRunGlobalSpinner?: boolean;
};

export const useSchoolList = ({
  onlyActive = false,
  isRunGlobalSpinner,
}: UseSchoolListProps = {}): UseSchoolListReturn => {
  const [schoolList, setSchoolList] = useState<SchoolInfo[]>([]);
  const { getEntityId, isSuperAdmin } = loggedInUserDataStore;

  const initShoolList = async () => {
    const { customerSchoolGroups } = await getShoolList();

    if (onlyActive) {
      const activeSchools = customerSchoolGroups.filter((school) => school.active);
      setSchoolList(activeSchools);
    } else {
      setSchoolList(customerSchoolGroups);
    }
  };

  const getShoolList = async () => {
    if (isSuperAdmin) {
      return schoolService.getSchoolsForAllEntity(isRunGlobalSpinner);
    }

    const entityId = getEntityId();
    return schoolService.getSchoolList(entityId, isRunGlobalSpinner);
  };

  useEffect(() => {
    initShoolList();
  }, []);

  const schoolListOption = useMemo(
    () => transformToOptions({ list: schoolList, labelKey: 'name', valueKey: 'id' }),
    [schoolList],
  );

  const schoolListOptionWithAll = useMemo(
    () => addAllOption(schoolListOption),
    [schoolListOption],
  );

  return { schoolList, schoolListOption, schoolListOptionWithAll };
};
