import { useFormContext, useWatch } from 'react-hook-form';

import { MealNutrientsInfo } from '../../../../../../../interfaces/MealPlan';
import { MacroPlanStepFromValue } from '../../../MacroPlanStep.settings';
import { REGULAR_MEAL_NAME } from '../../../../../../../../shared/constants/mealNames';

export const useNutrientsTotal = (nameIndex: number) => {
  const { control } = useFormContext<MacroPlanStepFromValue>();

  const meals = useWatch({
    control,
    name: Object.values(REGULAR_MEAL_NAME).map(
      (meal) => `mealPlans.${nameIndex}.${meal}` as const,
    ),
  });

  const total = meals.reduce<MealNutrientsInfo>(
    (acc, meal) => {
      Object.keys(acc).forEach((nutrient) => {
        const nutrientKey = nutrient as keyof MealNutrientsInfo;

        acc[nutrientKey] += meal[nutrientKey] || 0;
      });
      return acc;
    },
    { calories: 0, proteins: 0, carbs: 0, fats: 0 },
  );

  return total;
};
