import { StatusDetailsInfo } from '../interfaces/statusDetails';
import { apiService } from '../../shared/services/apiService';

export const entityStatusService = {
  async getStatusDetails(entityId: number) {
    const resp = await apiService.get<StatusDetailsInfo>(
      `/api/entity/${entityId}/status/details`,
    );

    return resp.data;
  },
};
