import React from 'react';
import PropTypes from 'prop-types';

import classes from './DashedBorder.module.scss';
import { ReactComponent as Border } from '../../../assets/image/dashed-border.svg';

const DashedBorder = ({ color = 'primary-700' }) => {
  return (
    <div style={{ color: `var(--${color})` }}>
      <Border className={classes.border} />
    </div>
  );
};

DashedBorder.propTypes = {
  color: PropTypes.string,
};

export { DashedBorder };
