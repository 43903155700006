import React from 'react';
import PropTypes from 'prop-types';

import classes from './InventoryRow.module.scss';
import { useModalToggle } from '../../../../../shared/hooks/useModalToggle';
import { inventoryStatus } from '../../../../constants/inventoryStatus';
import {
  BtnCell,
  DateCell,
  OrderCell,
  InventoryBalanceCell,
  StatusCell,
  SupplementCell,
} from '../Cells';
import { useInventory } from './InventoryRow.hook';
import { CurrentServingModal, EditOrderModal, RecordOrderModal } from './Modals';
import { Row } from '../Row/Row';

const InventoryRow = ({ inventoryInfo, customerId, className, hideAmountUnit }) => {
  const [isRecordModalOpen, recordModalHandler] = useModalToggle();
  const [isServingModalOpen, servingModalHandler] = useModalToggle();
  const [isEditModalOpen, editModalHandler] = useModalToggle();

  const {
    inventory,
    onConfirmOrder,
    recordOrderHandler,
    updateServingSizesHandler,
    editOrderHandler,
    deleteOrderHandler,
  } = useInventory(inventoryInfo, customerId);

  const {
    fullPackageSize,
    servingActual,
    order,
    status,
    supplementJson: { supplementName, supplementBrand, amountUnit },
  } = inventory;

  const isRecord = status === inventoryStatus.PENDING;
  const lowInventoryClasses = status === inventoryStatus.LOW_INVENTORY ? classes.low : '';

  const inventoryClasses = className
    ? `${lowInventoryClasses} ${className}`
    : lowInventoryClasses;

  return (
    <>
      <Row className={inventoryClasses}>
        <SupplementCell
          supplementName={supplementName}
          supplementBrand={supplementBrand}
        />
        <InventoryBalanceCell
          amountUnit={amountUnit}
          fullPackageSize={fullPackageSize}
          servingActual={servingActual}
          openServingModal={servingModalHandler}
          hideAmountUnit={hideAmountUnit}
        />
        <OrderCell orderAmount={order?.orderAmount} />
        <DateCell date={order?.orderDate} />
        <StatusCell status={status} />
        <BtnCell
          isRecord={isRecord}
          openRecordOrderModal={recordModalHandler}
          onConfirmOrder={onConfirmOrder}
          openEditModal={editModalHandler}
        />
      </Row>
      <CurrentServingModal
        open={isServingModalOpen}
        closeHandler={servingModalHandler}
        amountUnit={amountUnit}
        fullPackageSize={fullPackageSize}
        servingActual={servingActual}
        updateServingSizesHandler={updateServingSizesHandler}
      />
      <RecordOrderModal
        open={isRecordModalOpen}
        closeHandler={recordModalHandler}
        recordOrderHandler={recordOrderHandler}
      />
      <EditOrderModal
        open={isEditModalOpen}
        closeHandler={editModalHandler}
        editOrderHandler={editOrderHandler}
        deleteOrderHandler={deleteOrderHandler}
        orderValue={order?.orderAmount}
        dateValue={order?.orderDate}
      />
    </>
  );
};

InventoryRow.propTypes = {
  inventoryInfo: PropTypes.object,
  customerId: PropTypes.number,
  className: PropTypes.string,
  hideAmountUnit: PropTypes.bool,
};

export { InventoryRow };
