const autoNgTemplateLoaderTemplate1 = require('./notes.template.html');

'use strict';

angular
    .module('app')
    .directive('notes', notes);

notes.$inject = [];

function notes() {
    return {
        restrict: 'E',
        scope: {},
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: 'NotesController',
        controllerAs: 'vm'
    };
}
