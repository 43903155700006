import React from 'react';

import classes from './BodyMetricRow.module.scss';
import {
  GridTableCell,
  GridTableRow,
} from '../../../../../../../shared/components/table-components';

type BodyMetricRowProps = {
  title: string;
  current: number | null;
  goal: number | null;
  change: number | null;
  measureUnit?: string;
};

// TODO add unit metric fix ts
const BodyMetricRow = ({
  title,
  current,
  goal,
  change,
  measureUnit,
}: BodyMetricRowProps) => {
  return (
    <GridTableRow variant="subRow" className={classes.row}>
      <GridTableCell variant="title">{title}</GridTableCell>
      <GridTableCell>
        {current}
        {measureUnit}
      </GridTableCell>
      <GridTableCell>
        {goal}
        {measureUnit}
      </GridTableCell>
      <GridTableCell className={classes.changeCell}>
        {change}
        {measureUnit}
      </GridTableCell>
    </GridTableRow>
  );
};

export { BodyMetricRow };
