import { useEffect, useState } from 'react';
import { loggedInUserDataStore } from '../../../stores/loggedInUserDataStore';
import { getDaysToDate } from '../../../utils/dateUtils';
import { entityPaymentService } from '../../../services/entity/entityPaymentService';

export const useCardExpiredInfo = () => {
  const [daysToCardExpiration, setDaysToCardExpiration] = useState(null);
  const { loggedInUser, isEntity, paymentFlowType } = loggedInUserDataStore;

  const getExpirationCardDate = async () => {
    const { date } = await entityPaymentService.getEntityExpirationCardDate();

    setDaysToCardExpiration(getDaysToDate(date));
  };

  useEffect(() => {
    const isGetExpirationCardDate = isEntity && !paymentFlowType.NEW_USER_FLOW_2024;

    if (isGetExpirationCardDate) {
      getExpirationCardDate();
    }
  }, [loggedInUser]);

  const isShowCardExpiredInfo =
    isEntity && daysToCardExpiration && daysToCardExpiration <= 30;

  return { isShowCardExpiredInfo: !!isShowCardExpiredInfo, daysToCardExpiration };
};
