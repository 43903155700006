import React from 'react';
import PropTypes from 'prop-types';

import { Button, Paper, Switch } from '@mui/material';
import moment from 'moment';

import classes from './CustomerComplianceHistory.module.scss';
import {
  GridTableCell,
  GridTableRow,
} from '../../../../../../../shared/components/table-components';
import {
  DateRangePicker,
  LbFormControlLabel,
} from '../../../../../../../shared/components/form-components';
import { CustomerComplianceHistoryList } from '../../../../../../components/CustomerComplianceHistoryList/CustomerComplianceHistoryList';

import { useCustomerComplianceHistory } from '../../../../../../hooks/compliance-hooks';
import { SortButtons } from '../../../../../../../shared/components/buttons';
import { SeparatorLine } from '../../../../../../../shared/components/ui-components';
import { ConsumptionBetweenWidget } from '../../../../../../components/consumption';

const CustomerComplianceHistory = ({
  customerId,
  customerName,
  customerRegistrationDate,
}) => {
  const { formMethods, handleSubmit, complianceFields, consumptionFields } =
    useCustomerComplianceHistory({
      customerId,
      customerRegistrationDate,
    });
  const { averageComplianceInfo, sortedComplianceHistory, sortMethods } =
    complianceFields;
  const { handleSort, getIsSortedBy } = sortMethods;
  const { consumptionInfo, isShowConsumptionWidget, toggleShowConsumptionWidget } =
    consumptionFields;

  const { control } = formMethods;

  return (
    <>
      <Paper variant="roundedBox" className={classes.box}>
        <GridTableRow variant="subRow" customLayout className={classes.filterRow}>
          <GridTableCell justify="start" variant="title" dataTestId="data-cell">
            <SortButtons
              fieldName="date"
              onSort={handleSort}
              getIsActive={getIsSortedBy}
              tooltipTitle={{
                asc: 'Sort Oldest to Newest',
                desc: 'Sort Newest to Oldest',
              }}
            >
              Date
            </SortButtons>
          </GridTableCell>
          <form className={classes.formBox}>
            <LbFormControlLabel
              control={
                <Switch
                  checked={isShowConsumptionWidget}
                  onChange={toggleShowConsumptionWidget}
                />
              }
              label="Show Consumption:"
              labelPlacement="start"
              data-test-id="show-consumption-switch"
            />
            <SeparatorLine />
            <DateRangePicker
              control={control}
              size="small"
              variant="outlined"
              startPickerProps={{
                name: 'startDate',
                label: 'Start Date',
                minDate: moment(customerRegistrationDate),
                rules: { required: 'Start date is required' },
              }}
              endPickerProps={{
                name: 'endDate',
                label: 'End Date',
                disableFuture: true,
                rules: { required: 'End date is required' },
              }}
              dataTestId="date-range-picker"
            />
            <Button variant="outlined" type="submit" size="small" onClick={handleSubmit}>
              Apply
            </Button>
          </form>
        </GridTableRow>
        <CustomerComplianceHistoryList
          complianceHistory={sortedComplianceHistory}
          averageComplianceInfo={averageComplianceInfo}
          customerId={customerId}
        />
      </Paper>
      {isShowConsumptionWidget && (
        <ConsumptionBetweenWidget
          consumptionInfo={consumptionInfo}
          customerName={customerName}
          onClose={toggleShowConsumptionWidget}
        />
      )}
    </>
  );
};

CustomerComplianceHistory.propTypes = {
  customerId: PropTypes.number,
  customerName: PropTypes.string,
  customerRegistrationDate: PropTypes.string,
};

export { CustomerComplianceHistory };
