import React, { ReactNode } from 'react';

import { ThemeProvider } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { ErrorBoundary } from 'react-error-boundary';

import { theme } from '../../theme/theme';
import { ErrorFallback } from '../../components/ErrorFallback/ErrorFallback';

type SystemProvidersProps = {
  children: ReactNode;
};

const SystemProviders = ({ children }: SystemProvidersProps) => {
  return (
    <React.StrictMode>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
        </ThemeProvider>
      </StyledEngineProvider>
    </React.StrictMode>
  );
};

export { SystemProviders };
