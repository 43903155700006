import React, { FocusEvent } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import {
  useController,
  UseControllerProps,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';

export type LbNumericFormatInputProps<T extends FieldValues, TName extends Path<T>> = {
  selectValueOnFocus?: boolean;
  dataTestId?: string;
} & UseControllerProps<T, TName> &
  Pick<
    TextFieldProps,
    | 'label'
    | 'InputProps'
    | 'variant'
    | 'size'
    | 'helperText'
    | 'className'
    | 'placeholder'
    | 'disabled'
    | 'autoFocus'
    | 'margin'
  > &
  Pick<
    NumericFormatProps,
    'prefix' | 'suffix' | 'allowNegative' | 'decimalScale' | 'fixedDecimalScale'
  >;

/**
 * @description Used to change view format of numbers (mask, add prefix...) but save it like number
 */

const LbNumericFormatInput = <T extends FieldValues, TName extends Path<T>>({
  control,
  name,
  rules,
  defaultValue = '' as PathValue<T, TName>,
  shouldUnregister,
  helperText,
  selectValueOnFocus,
  ...props
}: LbNumericFormatInputProps<T, TName>) => {
  const {
    field: { onChange, ref, ...field },
    fieldState: { error },
  } = useController<any>({
    control,
    name,
    rules,
    defaultValue,
    shouldUnregister,
  });

  const handlerFocus = (event: FocusEvent<HTMLInputElement>) => {
    if (selectValueOnFocus) {
      event.target.select();
    }
  };

  const isRequired = !!rules?.required;
  return (
    <NumericFormat
      // connect to react-hook-form
      {...field}
      onValueChange={(value) => {
        onChange(value.floatValue ?? ''); // fix set undefined value
      }}
      valueIsNumericString
      // MUI TextField props
      customInput={TextField}
      onFocus={handlerFocus}
      helperText={error?.message || helperText}
      error={!!error}
      required={isRequired}
      fullWidth
      inputRef={ref}
      // add config props
      {...props}
    />
  );
};

export { LbNumericFormatInput };
