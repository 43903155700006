import React, { ReactNode } from 'react';

import classNames from 'classnames';
import { Collapse } from '@mui/material';

import classes from './InfoBox.module.scss';

type HideConfig = { show: boolean; liveMountOnExit?: boolean };

// TODO rename icon to adornment
export type InfoBoxProps = {
  children: ReactNode;
  title: ReactNode;
  icon?: ReactNode;
  className?: string;
  customMargin?: boolean;
  customContentPadding?: boolean;
  variant?: 'box' | 'innerBox';
  hideConfig?: HideConfig;
};
// for performance reasons Collapse used only if hideConfig provided
const InfoBox = ({
  children,
  title,
  icon,
  className,
  customMargin = false,
  customContentPadding = false,
  variant = 'box',
  hideConfig,
}: InfoBoxProps) => {
  const paperClasses = classNames(classes.paperBox, classes[variant], className, {
    [classes.margin]: !customMargin,
  });

  const contentBoxClasses = classNames(classes.contentBox, {
    [classes.contentPadding]: !customContentPadding,
  });

  return (
    <div className={paperClasses}>
      <div className={classes.title}>
        <h3>{title}</h3>
        {icon}
      </div>
      {hideConfig ? (
        <Collapse in={hideConfig.show} unmountOnExit={!hideConfig.liveMountOnExit}>
          <div className={contentBoxClasses}>{children}</div>
        </Collapse>
      ) : (
        <div className={contentBoxClasses}>{children}</div>
      )}
    </div>
  );
};

export { InfoBox };
