import React from 'react';

import { SvgIcon } from '@mui/material';

import { UserMenuItem } from '../UserMenuItem/UserMenuItem';
import { ReactComponent as logoutIcon } from '../../../../../../../../assets/images/material-icons/logout.svg';
import { logout } from './utils/logout';

const LogoutItem = () => {
  return (
    <UserMenuItem
      label="Logout"
      onClick={logout}
      startIcon={<SvgIcon component={logoutIcon} inheritViewBox />}
    />
  );
};

export { LogoutItem };
