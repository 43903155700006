import React from 'react';
import PropTypes from 'prop-types';

import {
  GridTableCell,
  GridTableRow,
} from '../../../../shared/components/table-components';
import { ComplianceDataCells } from '../../ComplianceDataCells/ComplianceDataCells';

const ComplianceDayRow = ({ complianceDayInfo, customerId }) => {
  const { date, ...complianceInfo } = complianceDayInfo;

  return (
    <GridTableRow variant="subRow" dataTestId="compliance-day-row">
      <GridTableCell justify="start" variant="title">
        {date}
      </GridTableCell>
      <ComplianceDataCells
        complianceInfo={complianceInfo}
        isInteractive
        customerId={customerId}
      />
    </GridTableRow>
  );
};

ComplianceDayRow.propTypes = {
  complianceDayInfo: PropTypes.shape({
    date: PropTypes.string,
    breakfast: PropTypes.number,
    daily: PropTypes.number,
    dinner: PropTypes.number,
    lunch: PropTypes.number,
    snack1: PropTypes.number,
    snack2: PropTypes.number,
    snack3: PropTypes.number,
    water: PropTypes.shape({ current: PropTypes.number, goal: PropTypes.number }),
    sodium: PropTypes.shape({ current: PropTypes.number, goal: PropTypes.number }),
  }),
  customerId: PropTypes.number,
};

export { ComplianceDayRow };
