import React from 'react';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';

import { DoubleInput } from '../../../../../../../shared/components/form-components/DoubleInput/DoubleInput';
import { LbModal } from '../../../../../../../shared/components/modals/LbModal/LbModal';
import { apiStatusStore } from '../../../../../../../shared/stores/apiStatusStore';

const CurrentServingModal = observer(
  ({
    open,
    closeHandler,
    amountUnit,
    fullPackageSize,
    servingActual,
    updateServingSizesHandler,
  }) => {
    const { isLoading } = apiStatusStore;

    const { control, handleSubmit } = useForm();

    const onSubmit = async (data) => {
      await updateServingSizesHandler(data);
      closeHandler();
    };

    return (
      <LbModal
        open={open}
        title="Current Serving"
        isLoading={isLoading}
        primaryBtnConfig={{ text: 'Update', onClick: handleSubmit(onSubmit) }}
        secondaryBtnConfig={{ onClick: closeHandler }}
      >
        <DoubleInput
          control={control}
          label="Amount of Order"
          firstInputProps={{
            name: 'servingActual',
            defaultValue: servingActual,
          }}
          secondInputProps={{
            name: 'fullPackageSize',
            defaultValue: fullPackageSize,
          }}
          endAdornment={amountUnit}
        />
      </LbModal>
    );
  },
);

CurrentServingModal.propTypes = {
  open: PropTypes.bool,
  closeHandler: PropTypes.func,
  amountUnit: PropTypes.string,
  fullPackageSize: PropTypes.number,
  servingActual: PropTypes.number,
  updateServingSizesHandler: PropTypes.func,
};

export { CurrentServingModal };
