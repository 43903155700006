import React from 'react';
import PropTypes from 'prop-types';

import classes from './InfoCell.module.scss';

const InfoCell = ({ label, text }) => {
  const infoCellClasses = text
    ? classes.infoCell
    : `${classes.infoCell} ${classes.noInfo}`;

  return (
    <div className={infoCellClasses}>
      <h6>{label}</h6>
      <p>{text || 'No Info'}</p>
    </div>
  );
};

InfoCell.propTypes = {
  label: PropTypes.string,
  text: PropTypes.string,
};

export { InfoCell };
