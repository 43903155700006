import { useMemo, useState } from 'react';

import { MomentFormatSpecification } from 'moment';

import { SortInfo, SortDataType, SortDirection } from '../../general-types';
import { getSortedArr } from '../utils/sortingAndGroupingUtil';
import { StringKey } from '../../utils-types';

export type SortTypeConfig<TItem extends Record<string, any>> = {
  [P in keyof TItem]?: SortDataType;
};

export type UseSortProps<
  TItem extends Record<string, any>,
  TSortTypeConfig extends SortTypeConfig<TItem>,
  TSortField extends StringKey<TSortTypeConfig>,
  TDefaultSortInfo extends SortInfo<TSortField>,
> = {
  data: TItem[];
  sortTypeConfig: TSortTypeConfig;
  dateFormat?: MomentFormatSpecification;
  defaultSortInfo?: TDefaultSortInfo;
};

export type SortMethods<TSortField> = {
  handleSort: (fieldName: TSortField, direction: SortDirection) => void;
  getIsSortedBy: (fieldName: TSortField, direction: SortDirection) => boolean;
  reset: () => void;
};

export type UseSortReturn<
  TItem extends Record<string, any>,
  TSortField extends keyof TItem,
> = {
  sortedData: TItem[];
  sortMethods: SortMethods<TSortField>;
};

export const useSort = <
  TItem extends Record<string, any>,
  TSortTypeConfig extends SortTypeConfig<TItem>,
  TSortField extends StringKey<TSortTypeConfig>,
  TDefaultSortInfo extends SortInfo<TSortField>,
>({
  data,
  sortTypeConfig,
  dateFormat,
  defaultSortInfo,
}: UseSortProps<TItem, TSortTypeConfig, TSortField, TDefaultSortInfo>): UseSortReturn<
  TItem,
  TSortField
> => {
  const [sortInfo, setSortInfo] = useState<SortInfo<TSortField> | undefined>(
    defaultSortInfo,
  );

  const getSortedData = () => {
    if (!data || !sortInfo) {
      return data;
    }

    const { fieldName, direction } = sortInfo;
    const type = sortTypeConfig[fieldName];

    if (!type) {
      throw new Error(`Sort type not set for this field "${fieldName}"`);
    }

    return getSortedArr({ data, fieldName, direction, type, dateFormat });
  };

  const sortedData = useMemo(() => getSortedData(), [sortInfo, data]);

  const handleSort = (fieldName: TSortField, direction: SortDirection) => {
    setSortInfo({ fieldName, direction });
  };

  const reset = () => {
    setSortInfo(defaultSortInfo);
  };

  const getIsSortedBy = (fieldName: TSortField, direction: SortDirection) => {
    const isSortedByFieldName = fieldName === sortInfo?.fieldName;
    const isSortedByDirection = direction === sortInfo?.direction;

    return isSortedByFieldName && isSortedByDirection;
  };

  return {
    sortedData,
    sortMethods: {
      handleSort,
      getIsSortedBy,
      reset,
    },
  };
};
