import React from 'react';

import { angularize } from 'react-in-angularjs';

import { SystemProviders } from '../../../../../shared/providers';
import { RegistrationFormAccessWidget } from './containers/RegistrationFormAccessWidget/RegistrationFormAccessWidget';
import { RegistrationFormStyleWidget } from './containers/RegistrationFormStyleWidget/RegistrationFormStyleWidget';

type NgzRegistrationFormSettingsProps = {
  entityId: number;
};

const NgzRegistrationFormSettings = ({ entityId }: NgzRegistrationFormSettingsProps) => {
  return (
    <SystemProviders>
      <RegistrationFormAccessWidget entityId={entityId} />
      <RegistrationFormStyleWidget entityId={entityId} />
    </SystemProviders>
  );
};

angularize(
  NgzRegistrationFormSettings,
  'ngzRegistrationFormSettings',
  angular.module('app.entityManagement'),
  { entityId: '<' },
);

export { NgzRegistrationFormSettings };
