import queryString from 'query-string';
import { PaymentResultQueryParams } from '../../constants/pathQueryParams';

export const generatePaymentResultUrl = (
  params: Partial<Record<PaymentResultQueryParams, string>>,
) => {
  const queryParams = queryString.stringify(params, {
    skipNull: true,
    skipEmptyString: true,
  });

  return `${window.location.protocol}//${window.location.host}/#!/payment-result?${queryParams}`;
};
