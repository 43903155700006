import { getIsNullish } from '../../../../../shared/utils/jsUtils';

export const getScoreStatus = (score) => {
  const isScoreNotExist = getIsNullish(score);

  if (isScoreNotExist) {
    return 'scoreNotExist';
  } else if (score < 3) {
    return 'badScore';
  } else {
    return 'goodScore';
  }
};
