import { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import { ConsumptionInfo } from '../../../../../interfaces/consumption/ConsumptionInfo';
import { complianceService } from '../../../../../services/complianceService';
import { ConsumptionParams } from '../../../../../interfaces/consumption/ConsumptionParams';
import { useCustomerScanDays } from '../../../../../../inbody';
import { defaultConsumptionInfo } from '../../../../../constants/defaultConsumptionInfo';
import { ConsumptionFormFormValues } from '../../../../../interfaces/consumption/ConsumptionFormFormValues';
import { useAutoSubmitForm } from '../../../../../../shared/hooks/useAutoSubmitForm';

export const useConsumptionBetweenScan = (customerId: number) => {
  const [consumptionInfo, setConsumptionInfo] =
    useState<ConsumptionInfo>(defaultConsumptionInfo);

  const customerScansInfo = useCustomerScanDays(customerId);
  const { customerScanDays, lastScanDay, previousScanDay } = customerScansInfo;

  const formMethods = useForm<ConsumptionFormFormValues>();
  const { handleSubmit, watch, reset } = formMethods;

  const getConsumptionInfo = async (consumptionParams: ConsumptionParams) => {
    const info = await complianceService.getCustomerConsumptionInfo(
      customerId,
      'SCAN',
      consumptionParams,
    );

    setConsumptionInfo(info);
  };

  const getInitConsumptionInfo = async () => {
    const consumptionParams = { startDate: previousScanDay!, endDate: lastScanDay! };
    reset(consumptionParams); // useAutoSubmitForm auto call getConsumptionInfo after reset
  };

  useAutoSubmitForm(handleSubmit(getConsumptionInfo), watch);

  useEffect(() => {
    if (customerScanDays.length > 1) {
      getInitConsumptionInfo();
    }
  }, [customerScanDays]);

  return { consumptionInfo, formMethods, customerScansInfo };
};
