import { userRoles } from '../../constants/userRoles';
import { apiStatusStore } from '../../stores/apiStatusStore';
import { loggedInUserDataStore } from '../../stores/loggedInUserDataStore';

export const getApiUrlRole = () => {
  const userRole = loggedInUserDataStore.getUserRole();
  const errorMessage = 'The user role cannot be identified';

  switch (userRole) {
    case userRoles.SUPERUSER:
      return 'super';
    case userRoles.ADMIN:
      return 'admin';
    case userRoles.AGENT:
      return 'coach';
    default:
      apiStatusStore.addErrorMessage(errorMessage);
      throw new Error(errorMessage);
  }
};
