import React from 'react';

import { Paper } from '@mui/material';
import { observer } from 'mobx-react';

import { FiltersRow } from './FiltersRow/FiltersRow';
import { CoachRow } from './CoachRow/CoachRow';
import {
  EmptyList,
  LbPagination,
} from '../../../../../../shared/components/ui-components';
import { coachStore } from '../../../../../stores/customerSelfSetupStores/coachStore';

const CoachTable = observer(() => {
  const { coachList, selectedCoach, setSelectedCoach, filterMethods } = coachStore;

  const { pagesCount, elementsPerPage, totalElements, isSearchUsed } = filterMethods;
  const { page, onChange } = filterMethods.getPaginationProps();

  return (
    <div>
      <FiltersRow />
      {coachList.map((coach) => (
        <CoachRow
          key={coach.coachId}
          coach={coach}
          selectedCoachId={selectedCoach?.coachId}
          onClick={setSelectedCoach}
        />
      ))}
      <LbPagination
        elementsPerPage={elementsPerPage}
        count={pagesCount}
        variant="outBox"
        totalElements={totalElements}
        page={page}
        onChange={onChange}
      />
      <Paper variant="roundedBox">
        <EmptyList
          show={!totalElements}
          isSearch={isSearchUsed}
          description="Coach list is empty"
        />
      </Paper>
    </div>
  );
});

export { CoachTable };
