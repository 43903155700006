import { EntityBusinessInfoFromValue } from '../../../../../components/EntityBusinessInfoInputs/EntityBusinessInfoInputs';
import { entityService } from '../../../../../services/entityService';

export const getEntityBusinessInfoFormValue = async (
  entityId: number | string,
): Promise<EntityBusinessInfoFromValue> => {
  const { paymentFlowType, ...businessInfo } =
    await entityService.getEntityBusinessInfo(entityId);

  return { businessInfo };
};
