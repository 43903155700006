'use strict';

angular
    .module('app')
    .directive('complianceCoach', complianceCoach);

complianceCoach.$inject = ['utilService'];

function complianceCoach(utilService) {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            scope.$watch(attrs.complianceCoach, function(isDisabled) {
                if (utilService.getComplianceCoachIndicator() || utilService.getComplianceCoachForCustomerIndicator()) {
                    element.attr('disabled', '');
                    element.append(angular.element(
                        '<div class="compliance-coach-tooltip">' +
                        '<span>You can\'t do this action as you\'re compliance coach to this user</span>' +
                        '</div>'
                    ));
                } else {
                    isDisabled ? element.attr('disabled', '') : element.removeAttr('disabled');
                }
            });
        }
    };
}