import { SpringPagination } from '../../shared/interfaces/SpringPagination';
import { apiService, apiReportService } from '../../shared/services/apiService';
import { CustomerCommunicationFilters } from '../interfaces/CustomerCommunicationFilters';
import { CustomerCommunicationInfo } from '../interfaces/CustomerCommunicationInfo';
import { CustomerCommunicationReportFilter } from '../interfaces/CustomerCommunicationReportFilter';

type GetCustomerListResponse = SpringPagination<CustomerCommunicationInfo[]>;

export const customerCommunicationService = {
  async getCustomerList(params: CustomerCommunicationFilters) {
    const resp = await apiService.post<GetCustomerListResponse>(
      '/api/data-filters/communication/customers',
      params,
    );

    return resp.data;
  },

  async getCommunicationCustomerReports(reportFilter: CustomerCommunicationReportFilter) {
    const resp = await apiReportService.post(
      `/api/data-filters/communication/customers/reports`,
      reportFilter,
    );

    return resp.data;
  },
};
