'use strict';

angular.module('app')
    .controller('CustomerFoodListTabController', CustomerFoodListTabController);

CustomerFoodListTabController.$inject = [
    '$rootScope',
    'ModalService',
    'FoodService'
];

function CustomerFoodListTabController($rootScope, ModalService, FoodService) {
    var vm = this;

    vm.foodSearchField = '';
    vm.typesOfFood = ['core', 'supplement', 'veggies'];
    vm.selectedTypeOfFood = vm.typesOfFood[0];
    vm.foods = [];
    vm.measurements = [];
    vm.pagination = {};
    vm.searchMode = false;
    vm.spinner = {active: false};
    vm.page = 0;
    vm.limit = 10;

    vm.updateFood = updateFood;
    vm.removeOneFood = removeOneFood;
    vm.doSearch = doSearch;
    vm.showAddFoodModal = showAddFoodModal;
    vm.getAllMeasurements = getAllMeasurements;
    vm.getAllFoods = getAllFoods;
    vm.showAddFoodWithMyNetDiaryModal = showAddFoodWithMyNetDiaryModal;
    vm.searchFoods = searchFoods;
    vm.changeMode = changeMode;

    vm.getAllFoods(0);
    vm.getAllMeasurements();

    function changeMode(mode) {
        vm.foods = [];
        vm.searchMode = mode;
        vm.doSearch();
    }

    function doSearch() {
        if (!vm.searchMode) {
            vm.getAllFoods(0);
        } else {
            vm.searchFoods();
        }
    }

    function getAllFoods(skip) {
        vm.spinner.active = true;
        vm.page = skip/vm.limit;
        FoodService.getAllFoods114(vm.selectedTypeOfFood, {page: vm.page, limit: vm.limit}).then(function (res) {
            vm.spinner.active = false;
            if (res.status === 200) {
                if (res.data.foods.length === 0 && vm.page > 0) {
                    $rootScope.$broadcast('goBack');
                } else {
                    vm.foods = res.data.foods;
                    vm.pagination.reset(vm.foods.length+1, vm.page*vm.limit);
                }
            }
        });
    }

    function getAllMeasurements() {
        FoodService.getAllMeasurements().then(function (res) {
            if (res.status === 200) {
                vm.measurements = res.data.measurements;
            }
        });
    }

    function searchFoods() {
        vm.spinner.active = true;
        vm.foods = [];
        FoodService.searchFoods({
            q: vm.foodSearchField,
            src: vm.selectedTypeOfFood,
            my_food_diary: true
        }).then(function (res) {
            vm.spinner.active = false;
            if (res.status === 200) {
                vm.foods = res.data.foods.filter(function (item) {
                    if (!item.addedByUser) {
                        return item;
                    }
                });
            }
        });
    }

    function updateFood(item) {
        FoodService.updateFood(item.id, null, {action: item.banned ? 'ban' : 'unban'}).then(function (res) {
            if (res.status === 200) {
                item.banned = res.data.banned;
            }
        });
    }

    function removeOneFood(food, index) {
        FoodService.removeOneFood(food.id).then(function (res) {
            if (res.status === 200) {
                vm.foods.splice(index, 1);
            }
        });
    }

    function showAddFoodWithMyNetDiaryModal(food) {
        ModalService.addFoodForMeal(angular.copy(food), vm.selectedTypeOfFood, vm.doSearch);
    }

    function showAddFoodModal(food) {
        vm.foodInfo = angular.copy(food);
        if (vm.foodInfo.id) {
            vm.foodInfo.proteins = parseFloat((vm.foodInfo.proteins * vm.foodInfo.recommendedServingSize).toFixed(1));
            vm.foodInfo.carbs = parseFloat((vm.foodInfo.carbs * vm.foodInfo.recommendedServingSize).toFixed(1));
            vm.foodInfo.fats = parseFloat((vm.foodInfo.fats * vm.foodInfo.recommendedServingSize).toFixed(1));
            vm.foodInfo.sugar = parseFloat((vm.foodInfo.sugar * vm.foodInfo.recommendedServingSize).toFixed(1));
            vm.foodInfo.fiber = parseFloat((vm.foodInfo.fiber * vm.foodInfo.recommendedServingSize).toFixed(1));
            vm.foodInfo.sodium = parseFloat((vm.foodInfo.sodium * vm.foodInfo.recommendedServingSize).toFixed(1));
        } else {
            vm.foodInfo.amountUnit = vm.measurements[0];
        }
        ModalService.addFood(vm.foodInfo, vm.measurements, vm.selectedTypeOfFood, vm.doSearch);
    }
}

