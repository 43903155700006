import React from 'react';

import { angularize } from 'react-in-angularjs';
import { useToggle } from 'react-use';

import classes from './NgzPromoCodesList.module.scss';
import { SystemProviders } from '../../../../shared/providers';
import { EmptyList } from '../../../../shared/components/ui-components';
import { HeaderRow } from './HeaderRow/HeaderRow';
import { PromoCodeRow } from './PromoCodeRow/PromoCodeRow';
import { AddPromoCodeModal } from './AddPromoCodeModal/AddPromoCodeModal';
import { usePromoCodes } from './hooks/usePromoCodes';
import { useSort } from '../../../../shared/hooks/useSort';
import { useFilterByField } from '../../../../shared/hooks/useFilterByField';
import { LbHorizontalScrollContainer } from '../../../../shared/components/LbHorizontalScrollContainer/LbHorizontalScrollContainer';
import { TitleBlock } from './TitleBlock/TitleBlock';
import { PromoCodeInfo } from '../../../interfaces/promoCodeInfo';
import { PromoCodeStatus } from '../../../constants/promoCodes';
import { usePromoCodeSubscription } from './hooks/usePromoCodeSubscription';

type NgzPromoCodesListProps = {
  entityId: number;
};

const NgzPromoCodesList = ({ entityId }: NgzPromoCodesListProps) => {
  const { promoCodes, loading, addPromoCode, togglePromoCode } = usePromoCodes(entityId);
  const { subscriptions } = usePromoCodeSubscription(entityId);

  const { filteredData, filterValue, handleSetFilterValue } = useFilterByField<
    PromoCodeInfo,
    'status',
    PromoCodeStatus
  >(promoCodes, 'status');

  const { sortedData: sortedPromoCodes, sortMethods } = useSort({
    data: filteredData,
    sortTypeConfig: { name: 'STRING' },
  });

  const [isShowAddPromoCodeModal, toggleShowAddPromoCodeModal] = useToggle(false);

  return (
    <SystemProviders>
      <TitleBlock
        filterValue={filterValue}
        onAddPromoCodeButtonClick={toggleShowAddPromoCodeModal}
        onChangeFilterValue={handleSetFilterValue}
      />
      <LbHorizontalScrollContainer>
        <div className={classes.promoCodesTable}>
          <HeaderRow
            handleSort={sortMethods.handleSort}
            getIsSortedBy={sortMethods.getIsSortedBy}
            reset={sortMethods.reset}
          />
          {sortedPromoCodes.map((promoCodeInfo) => (
            <PromoCodeRow
              key={promoCodeInfo.id}
              promoCodeInfo={promoCodeInfo}
              onPromoCodeToggle={togglePromoCode}
            />
          ))}
        </div>
        <EmptyList
          show={!sortedPromoCodes.length && !loading}
          isSearch={!!filterValue}
          description="There are no promo codes"
        />
      </LbHorizontalScrollContainer>
      {isShowAddPromoCodeModal && (
        <AddPromoCodeModal
          subscriptions={subscriptions}
          onClose={toggleShowAddPromoCodeModal}
          addPromoCode={addPromoCode}
        />
      )}
    </SystemProviders>
  );
};

angularize(NgzPromoCodesList, 'ngzPromoCodesList', angular.module('app'), {
  entityId: '<',
});

export { NgzPromoCodesList };
