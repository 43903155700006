import React from 'react';

export const getDescriptionInfo = (isSuperAdmin: boolean) => {
  const adminInfo = (
    <span>
      Login ID can be used as a login to the dashboard.
      <br />
      Whenever you are changing Login ID, entity need use one as a login.
    </span>
  );

  const entityInfo = (
    <span>
      Login ID can be used as a login to the dashboard.
      <br />
      Whenever you are changing your Login ID, you need use one as a login.
    </span>
  );

  return isSuperAdmin ? adminInfo : entityInfo;
};
