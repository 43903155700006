import React from 'react';

import { Radio } from '@mui/material';

import classes from './CoachInfoDesktop.module.scss';
import { GridTableCell } from '../../../../../../../../shared/components/table-components';
import { AvatarIcon } from '../../../../../../../../shared/components/ui-components';
import { Coach } from '../../../../../../../interfaces/coachList/CoachListInfo';
import { getIsNullish } from '../../../../../../../../shared/utils/jsUtils';

type CoachInfoDesktopProps = {
  coach: Coach;
  checked: boolean;
};

const CoachInfoDesktop = ({ coach, checked }: CoachInfoDesktopProps) => {
  return (
    <>
      <GridTableCell className={classes.coachCell} variant="title">
        <Radio checked={checked} />
        <AvatarIcon userName={coach.coachName} avatarImgLink={coach.coachAvatar} />
        <div> {coach.coachName}</div>
      </GridTableCell>
      <GridTableCell>{coach.entityName}</GridTableCell>
      {!getIsNullish(coach.distance) && (
        <GridTableCell>{coach.distance} miles</GridTableCell>
      )}
    </>
  );
};

export { CoachInfoDesktop };
