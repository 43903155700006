import React from 'react';

import { observer } from 'mobx-react';

import {
  GridTableCell,
  GridTableRow,
} from '../../../../../../shared/components/table-components';
import { ReportInfo } from '../../../../../interfaces/ReportInfo';
import { DownloadReportButtons } from '../../../../../components/DownloadReportButtons/DownloadReportButtons';
import { reportStore } from '../../../../../stores/reportStore';

type ReportRowProps = {
  reportInfo: ReportInfo;
};

const ReportRow = observer(({ reportInfo }: ReportRowProps) => {
  const { openDownloadModal } = reportStore;
  const { title, description } = reportInfo;

  return (
    <GridTableRow variant="row">
      <GridTableCell variant="title">{title}</GridTableCell>
      <GridTableCell>{description}</GridTableCell>
      <GridTableCell>
        <DownloadReportButtons
          onPdfDownload={() => {
            openDownloadModal(reportInfo, 'pdf');
          }}
          onCsvDownload={() => {
            openDownloadModal(reportInfo, 'csv');
          }}
        />
      </GridTableCell>
    </GridTableRow>
  );
});

export { ReportRow };
