import React from 'react';
import { observer } from 'mobx-react';

import { Modal } from '@mui/material';

import classes from './ComplianceInfoModal.module.scss';
import { MealInfoTable } from '../MealInfoTable/MealInfoTable';
import { complianceScoreModalStore } from '../../stores/complianceScoreModalStore';
import { DailyMealTable } from '../DailyMealTable/DailyMealTable';

const ComplianceInfoModal = observer(() => {
  const { closeModal, mealDetails, dailyResult, isDailyModalOpened, isMealModalOpened } =
    complianceScoreModalStore;

  const onClose = (event, reason) => {
    if (reason === 'backdropClick') {
      closeModal();
    }
  };

  return (
    <Modal
      open={isDailyModalOpened || isMealModalOpened}
      onClose={onClose}
      className={classes.complianceInfoModal}
      data-test-id="compliance-info-modal"
    >
      <div className={classes.container}>
        {isMealModalOpened && <MealInfoTable mealDetails={mealDetails} isModal />}
        {isDailyModalOpened && <DailyMealTable dailyResult={dailyResult} />}
      </div>
    </Modal>
  );
});

export { ComplianceInfoModal };
