import React, { useState } from 'react';

import { TextField, TextFieldProps } from '@mui/material';
import {
  FieldValues,
  Path,
  PathValue,
  UseControllerProps,
  useController,
} from 'react-hook-form';

import { ShowPasswordButton } from './ShowPasswordButton/ShowPasswordButton';
import { addValidateRules } from '../../../utils/reactHookFormUtils';
import { validatePassword } from '../../../utils/reactHookFormUtils/validatePassword/validatePassword';

type PasswordInputProps<T extends FieldValues, TName extends Path<T>> = {
  helperText?: string;
  className?: string;
  /**
   * @description
   * omit only default password validation
   */
  omitValidation?: boolean;
} & UseControllerProps<T, TName> &
  Pick<TextFieldProps, 'label' | 'variant' | 'size' | 'margin'>;

/**
 * @description
 * Need omitValidation when use for existing password (sing-in, current password, etc)
 * Password should include lowercase, uppercase, number, special character, no spaces, and length between 8 to 16 characters
 */
const PasswordInput = <T extends FieldValues, TName extends Path<T>>({
  control,
  name,
  label,
  defaultValue = '' as PathValue<T, TName>,
  shouldUnregister,
  variant,
  size,
  margin,
  rules,
  helperText,
  className,
  omitValidation = false,
}: PasswordInputProps<T, TName>) => {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const {
    field: { ref, ...field },
    fieldState: { error },
  } = useController<any>({
    control,
    name,
    rules: omitValidation ? rules : addValidateRules(rules, { validatePassword }),
    defaultValue,
    shouldUnregister,
  });

  const handleClickShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  return (
    <TextField
      {...field}
      inputRef={ref}
      helperText={error?.message || helperText}
      error={!!error}
      label={label}
      type={isShowPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <ShowPasswordButton
            isShowPassword={isShowPassword}
            onClick={handleClickShowPassword}
          />
        ),
      }}
      variant={variant}
      size={size}
      margin={margin}
      required
      className={className}
    />
  );
};

export { PasswordInput };
