import React from 'react';

import classes from './FoodScoreTableHead.modules.scss';
import { NUTRIENTS } from '../../../../../shared/constants/NUTRIENTS';
import {
  GridTableCell,
  GridTableRow,
} from '../../../../../shared/components/table-components';

const FoodScoreTableHead = () => {
  const nutrientCells = NUTRIENTS.map((nutrient) => (
    <GridTableCell key={nutrient} justify="center" className={classes.nutrient}>
      {nutrient}
    </GridTableCell>
  ));

  return (
    <GridTableRow variant="header" className={classes.header}>
      <GridTableCell />
      <GridTableCell>#ID</GridTableCell>
      <GridTableCell>Recipe/ Individual</GridTableCell>
      <GridTableCell>Product Name</GridTableCell>
      <GridTableCell>Tracked Amount</GridTableCell>
      <GridTableCell>Unit Description</GridTableCell>
      {nutrientCells}
      <GridTableCell />
    </GridTableRow>
  );
};

export { FoodScoreTableHead };
