import React from 'react';
import PropTypes from 'prop-types';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Button } from '@mui/material';

const AddTestBtn = ({ onClick, disabled }) => {
  return (
    <Button
      variant="contained"
      startIcon={<AddRoundedIcon />}
      onClick={onClick}
      disabled={disabled}
    >
      Add New Lab Test
    </Button>
  );
};

AddTestBtn.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export { AddTestBtn };
