import React from 'react';

import {
  GridTableCell,
  GridTableRow,
} from '../../../../../../../shared/components/table-components';
import { MealNutrients } from '../MealNutrients/MealNutrients';
import { MealNutrientInfo } from '../../../../../../interfaces/MealPlan';
import { processMealName } from '../../../../../../../shared/utils/mealUtils';

type MealInfoProps = {
  meal: string;
  nutrientsInfo: MealNutrientInfo;
};

const MealInfo = ({ meal, nutrientsInfo }: MealInfoProps) => {
  const mealName = processMealName(meal);

  return (
    <GridTableRow variant="subRow">
      <GridTableCell variant="title">{mealName}</GridTableCell>
      <MealNutrients nutrientsInfo={nutrientsInfo} />
    </GridTableRow>
  );
};

export { MealInfo };
