import { apiService } from '../../shared/services/apiService';

const sendUser = async (userData) => {
  const resp = await apiService.post('api/coach/customer', userData);
  return resp.data;
};

const getCustomerInfo = async (customerId) => {
  const resp = await apiService(`api/customers/${customerId}`);
  return resp.data;
};

const getUserInfo = async (userId) => {
  const resp = await apiService(`api/users/${userId}`);
  return resp.data;
};

const changeUserActiveStatus = async (userId, activeStatus) => {
  const params = { value: activeStatus };

  const resp = await apiService.post(`api/users/${userId}/activate`, null, { params });
  return resp;
};

// TODO delete  use from customer-management module customerService
export const userProfileService = {
  sendUser,
  getCustomerInfo,
  getUserInfo,
  changeUserActiveStatus,
};
