import React from 'react';
import PropTypes from 'prop-types';

import MedicalServicesRoundedIcon from '@mui/icons-material/MedicalServicesRounded';
import { getIconColor } from './settings/getIconColor';

const LabTestInfoIcon = ({ daysToTest }) => {
  const iconColor = getIconColor(daysToTest);

  return <MedicalServicesRoundedIcon fontSize="large" sx={{ fill: iconColor }} />;
};

LabTestInfoIcon.propTypes = {
  daysToTest: PropTypes.number,
};

export { LabTestInfoIcon };
