import React from 'react';

import { Button } from '@mui/material';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';

import classes from './ConnectionManagement.module.scss';
import { AccountStatusChip } from './AccountStatusChip/AccountStatusChip';

type ConnectionManagementProps = {
  contactId?: string | null;
  onRefresh: () => void;
};

const ConnectionManagement = ({ contactId, onRefresh }: ConnectionManagementProps) => {
  const isConnected: boolean = !!contactId;

  return (
    <div className={classes.box}>
      <AccountStatusChip isConnected={isConnected} />
      {!isConnected && (
        <Button
          variant="text"
          color="secondary"
          size="small"
          onClick={onRefresh}
          startIcon={<RefreshRoundedIcon />}
        >
          Try Again
        </Button>
      )}
    </div>
  );
};

export { ConnectionManagement };
