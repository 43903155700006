import React from 'react';

import { ResultTitle } from '../components/ResultTitle/ResultTitle';
import { PaymentDetails } from '../components/PaymentDetails/PaymentDetails';
import { InfoContainer } from '../components/InfoContainer/InfoContainer';
import { PaymentInfo } from '../../../interfaces/checkoutInfo';

type ReactivationPaymentInfoProps = {
  paymentInfo: PaymentInfo;
};

const ReactivationPaymentInfo = ({ paymentInfo }: ReactivationPaymentInfoProps) => {
  return (
    <InfoContainer>
      <ResultTitle isSuccessful title="Thank you for your payment!" />
      <PaymentDetails paymentInfo={paymentInfo} />
    </InfoContainer>
  );
};

export { ReactivationPaymentInfo };
