import React from 'react';

import { Button } from '@mui/material';
import { UsePaginationItem } from '@mui/lab';

type PaginationItemProps = UsePaginationItem;

const PaginationItem = ({
  page,
  type,
  selected,
  onClick,
  disabled,
}: PaginationItemProps) => {
  switch (type) {
    case 'start-ellipsis':
    case 'end-ellipsis':
      return (
        <li>
          <span>...</span>
        </li>
      );
    case 'page':
      return (
        <li>
          <Button
            variant="text"
            color={selected ? 'primary' : 'secondary'}
            size="small"
            onClick={onClick}
            disabled={disabled}
          >
            {page}
          </Button>
        </li>
      );
    default:
      return <li>error</li>;
  }
};

export { PaginationItem };
