import { Flags, ValueOfKey } from '../../utils-types';

export const PAYMENT_RESULT_TYPE = {
  REGISTRATION: 'REGISTRATION',
  REACTIVATION: 'REACTIVATION',
} as const;

export type PaymentResultType = ValueOfKey<typeof PAYMENT_RESULT_TYPE>;
export type PaymentResultTypeKeys = keyof typeof PAYMENT_RESULT_TYPE;
export type PaymentResultTypeFlags = Flags<PaymentResultTypeKeys>;
