const autoNgTemplateLoaderTemplate1 = require('./coach-list.template.html');

'use strict';

(function () {
    angular
        .module('app.coachList', [])
        .config(coachListConfig);

    coachListConfig.$inject = ['$routeProvider'];

    function coachListConfig($routeProvider) {
        $routeProvider
            .when('/coach-list', {
                disableCache: true,
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'CoachListController',
                controllerAs: 'vm',
                reloadOnSearch:false,
                resolve: {
                    loginRequired: ['AuthService', function (AuthService) {
                        return AuthService.loginRequired();
                    }]
                }
            })
    }
})();
