import { SubscriptionType } from '../../shared/constants/user-info/subscription';
import { apiService } from '../../shared/services/apiService';
import { Subscription } from '../interfaces/Subscription';
import { SubscriptionSetup } from '../interfaces/SubscriptionSetup';

type GetSubscriptionListResponse = {
  stripe: boolean;
  tax: number;
  subscriptions: Subscription[];
};

export const subscriptionService = {
  async getSubscriptionList(
    entityId: number,
    activeOnly?: boolean,
    subscriptionType?: SubscriptionType,
  ) {
    const params = { activeOnly, subscriptionType };

    const resp = await apiService.get<GetSubscriptionListResponse>(
      `api/subscription/entity/${entityId}`,
      { params },
    );

    return resp.data;
  },

  async setSubscription(subscriptionParams: SubscriptionSetup) {
    const resp = await apiService.post(`/api/subscription-transaction/manual`, null, {
      params: subscriptionParams,
    });

    return resp.data;
  },
};
