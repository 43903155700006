import {
  UserEditInfo,
  CustomerInfo,
  UserInfo,
  CustomerInbodyInfo,
} from '../interfaces/Customer';
import { apiService } from '../../shared/services/apiService';

export const customerService = {
  async createCustomer(customerInfo: UserEditInfo) {
    const resp = await apiService.post<UserEditInfo>('api/coach/customer', customerInfo);

    return resp.data;
  },

  async updateCustomer(customerId: number, customerInfo: UserEditInfo) {
    const resp = await apiService.put<UserEditInfo>(
      `api/coach/customer/${customerId}`,
      customerInfo,
    );

    return resp.data;
  },

  async getCustomerInfo(customerId: number) {
    const resp = await apiService<CustomerInfo>(`api/customers/${customerId}`);
    return resp.data;
  },

  async getUserInfo(customerId: number) {
    const resp = await apiService<UserInfo>(`api/users/${customerId}`);
    return resp.data;
  },

  async getCustomerInbodyInfo(customerId: number) {
    const resp = await apiService<CustomerInbodyInfo>(
      `api/coach/customer/${customerId}/inbody-info`,
    );

    return resp.data;
  },

  // TODO replace to auth module
  async sendSetPasswordLink(customerId: number) {
    const resp = await apiService.post<undefined>(
      `/api/user/${customerId}/set-password-link`,
    );

    return resp.data;
  },
};
