export type CalculateMealCaloriesProps = {
  proteins?: number;
  carbs?: number;
  fats?: number;
};

export const calculateMealCalories = ({
  proteins = 0,
  carbs = 0,
  fats = 0,
}: CalculateMealCaloriesProps) => proteins * 4 + carbs * 4 + fats * 9;
