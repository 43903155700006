import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Paper } from '@mui/material';

import classes from './CustomerDetailBox.module.scss';
import { SupplementsValuesTab } from './SupplementsValuesTab/SupplementsValuesTab';
import { HistoryOrdersTab } from './HistoryOrdersTab/HistoryOrdersTab';
import { orderSupplementsService } from '../../../services/orderService';
import { useGetDataWithLoading } from '../../../../shared/hooks/useGetDataWithLoading';
import { Spinner } from '../../../../shared/components/Spinner/Spinner';
import { getSortedArrFromDateGroupedObj } from '../../../../shared/utils/sortingAndGroupingUtil';
import { dateFormat } from '../../../../shared/constants/apiDateFormat';
import { LbTabs, LbTabsList, LbTabPanel } from '../../../../shared/components/tabs';

const { getOrderInventory, getSupplementOrdersHistory } = orderSupplementsService;

const CustomerDetailBox = ({ customerId }) => {
  const [orderInventory, setOrderInventory] = useState([]);
  const [ordersHistory, setOrdersHistory] = useState([]);

  const getData = async () => {
    const [inventory, history] = await Promise.all([
      getOrderInventory(customerId, false),
      getSupplementOrdersHistory(customerId),
    ]);

    const sortedHistory = getSortedArrFromDateGroupedObj(history, dateFormat, false);

    setOrderInventory(inventory);
    setOrdersHistory(sortedHistory);
  };

  const isLoading = useGetDataWithLoading(getData);

  const isOrderInventoryExist = orderInventory.length !== 0;

  const tabsConfigs = [
    {
      label: 'Supplements values',
      value: 0,
      panel: (
        <SupplementsValuesTab customerId={customerId} orderInventory={orderInventory} />
      ),
    },
    {
      label: 'History of Orders',
      value: 1,
      panel: <HistoryOrdersTab customerId={customerId} ordersHistory={ordersHistory} />,
    },
  ];

  return (
    <Paper variant="roundedBox" className={classes.box}>
      {isOrderInventoryExist && (
        <LbTabs defaultValue={0}>
          <LbTabsList tabsConfig={tabsConfigs} variant="underline" />
          <LbTabPanel tabsConfig={tabsConfigs} />
        </LbTabs>
      )}
      <Spinner isLoading={isLoading} />
    </Paper>
  );
};

CustomerDetailBox.propTypes = {
  customerId: PropTypes.number,
};

export { CustomerDetailBox };
