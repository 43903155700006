import { useEffect, useState } from 'react';

import { PaymentInfo } from '../../../interfaces/checkoutInfo';
import { checkoutService } from '../../../services/checkoutService';
import { PaymentResultQueryParamsKeys } from '../../../constants/pathQueryParams';
import {
  SUBSCRIPTION,
  SubscriptionTypeFlags,
} from '../../../../shared/constants/user-info/subscription';
import { getPaymentResultParams } from '../utils/getPaymentResultParams';
import { getFlagsObject } from '../../../../shared/utils/sortingAndGroupingUtil';
import {
  PAYMENT_RESULT_TYPE,
  PaymentResultTypeFlags,
} from '../../../constants/paymentResultType';

type UsePaymentResultReturn = {
  paymentInfo: PaymentInfo | null;
  params: Record<PaymentResultQueryParamsKeys, any>;
  subscriptionTypeFlags: SubscriptionTypeFlags;
  paymentResultTypeFlags: PaymentResultTypeFlags;
  isSuccess: boolean;
};

export const usePaymentResult = (): UsePaymentResultReturn => {
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfo | null>(null);

  const params = getPaymentResultParams();
  const {
    paymentIntentId,
    connectedAccountId,
    subscriptionType,
    paymentTimeEpoch,
    senderName,
    paymentResultType,
    redirectStatus,
  } = params;

  const subscriptionTypeFlags = getFlagsObject(SUBSCRIPTION, [subscriptionType]);
  const paymentResultTypeFlags = getFlagsObject(PAYMENT_RESULT_TYPE, [paymentResultType]);
  const isSuccess = redirectStatus === 'succeeded';

  const getPaymentInfo = async () => {
    if (!paymentIntentId || !connectedAccountId) {
      console.error('Not all required data is set');
    } else {
      const result = await checkoutService.getPaymentDetails(
        paymentIntentId,
        connectedAccountId,
      );

      setPaymentInfo(result);
    }
  };

  const getPaymentInfoForFreeSubscription = async () => {
    if (!paymentTimeEpoch || !senderName) {
      console.error('Not all required data is set');
    } else {
      setPaymentInfo({
        paymentTimeEpoch,
        senderName,
        amount: 0,
        paymentMethod: null,
        cardFunding: null,
        currency: '',
        amountExcludingTax: 0,
      });
    }
  };

  const processPaymentDetails = () => {
    if (subscriptionTypeFlags.FREE_ACCESS) {
      getPaymentInfoForFreeSubscription();
    } else {
      getPaymentInfo();
    }
  };

  useEffect(() => {
    processPaymentDetails();
  }, []);

  return {
    paymentInfo,
    params,
    subscriptionTypeFlags,
    paymentResultTypeFlags,
    isSuccess,
  };
};
