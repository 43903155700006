import React from 'react';
import PropTypes from 'prop-types';

import { InputAdornment } from '@mui/material';
import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react';

import classes from './EditWaterGoalModal.module.scss';
import water from '../../../../../assets/image/water.png';
import { LbModal } from '../../../../../../shared/components/modals/LbModal/LbModal';
import { LbInput } from '../../../../../../shared/components/form-components/LbInput/LbInput';
import { LbDatePicker } from '../../../../../../shared/components/form-components/LbDatePicker/LbDatePicker';
import { apiStatusStore } from '../../../../../../shared/stores/apiStatusStore';
import { getTomorrow } from '../../../../../../shared/utils/dateUtils';
import { customerWaterGoalStore } from '../../../../../stores/customerWaterGoalStore';
import { waterGoalService } from '../../../../../services/waterGoalService';
import { validateOnlyInteger } from '../../../../../../shared/utils/reactHookFormUtils';

const EditWaterGoalModal = observer(
  ({ open, closeHandler, customerId, selectedMonth }) => {
    const { isLoading } = apiStatusStore;
    const { setChangeWaterGoal } = customerWaterGoalStore;

    const { control, handleSubmit } = useForm({ mode: 'onChange' });

    const tomorrow = getTomorrow();

    const onSubmit = async (data) => {
      const { startDate, targetGoal } = data;

      const dailyGoalID = await waterGoalService.sendGoal({
        startDate,
        targetGoal,
        customerID: customerId,
      });

      const newGoal = { dailyGoalID, startDate, targetGoal: +targetGoal };

      setChangeWaterGoal(newGoal, selectedMonth);
      closeHandler();
    };

    return (
      <LbModal
        open={open}
        imgSrc={water}
        title="Edit Water Goal "
        text="Water is best set between 30 and 35ml per kg of body weight.  Simple Rule of thumb one can use is to take body weight in pounds, divide by 2 and that is how many ounces to drink. "
        isLoading={isLoading}
        primaryBtnConfig={{ text: 'Save', onClick: handleSubmit(onSubmit) }}
        secondaryBtnConfig={{ onClick: closeHandler }}
      >
        <div className={classes.inputBox}>
          <LbInput
            control={control}
            label="Water Goal"
            name="targetGoal"
            type="number"
            rules={{
              required: 'Water Goal is required',
              validate: validateOnlyInteger,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">oz</InputAdornment>,
            }}
          />
          <LbDatePicker
            control={control}
            label="Date of Update"
            name="startDate"
            minDate={tomorrow}
            defaultValue={tomorrow}
          />
        </div>
      </LbModal>
    );
  },
);

EditWaterGoalModal.propTypes = {
  open: PropTypes.bool,
  closeHandler: PropTypes.func,
  customerId: PropTypes.string,
  selectedMonth: PropTypes.object,
};

export { EditWaterGoalModal };
