import { makeAutoObservable } from 'mobx';
import {
  SubscriptionSettings,
  SubscriptionSettingsFormValues,
} from '../interfaces/subscriptionSettings';
import { resetAllStore } from '../../shared/stores/resetAllStore';
import { subscriptionSettingsService } from '../services/subscriptionSettingsService';

class SubscriptionSettingsStore {
  settings: SubscriptionSettings | null = null;
  entityId: number | null = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.settings = null;
    this.entityId = null;
  }

  async getSettings(entityId: number) {
    if (!this.entityId) {
      this.setEntityId(entityId);

      const settingsInfo =
        await subscriptionSettingsService.getSubscriptionSettings(entityId);

      this.setSettings(settingsInfo);
    } else if (this.entityId !== entityId) {
      throw new Error('Already used for another entity');
    }
  }

  async submitSettings(subscriptionSettingsData: SubscriptionSettingsFormValues) {
    if (!this.entityId) {
      throw new Error('entityId is not set');
    }

    if (this.settings?.id) {
      await this.updateSettings(this.entityId, subscriptionSettingsData);
    } else {
      await this.createSettings(this.entityId, subscriptionSettingsData);
    }
  }

  private async createSettings(
    entityId: number,
    { subscriptionSettings }: SubscriptionSettingsFormValues,
  ) {
    const settings = await subscriptionSettingsService.setupSubscriptionSettings(
      entityId,
      subscriptionSettings,
    );

    this.setSettings(settings);
  }

  private async updateSettings(
    entityId: number,
    { subscriptionSettings }: SubscriptionSettingsFormValues,
  ) {
    const settings = await subscriptionSettingsService.updateSubscriptionSettings(
      entityId,
      { ...this.settings!, ...subscriptionSettings },
    );

    this.setSettings(settings);
  }

  private setSettings(settings: SubscriptionSettings) {
    this.settings = settings;
  }

  private setEntityId(entityId: number) {
    this.entityId = entityId;
  }
}

const subscriptionSettingsStore = new SubscriptionSettingsStore();
resetAllStore.addResetMethod(subscriptionSettingsStore.setDefaultValues);

export { subscriptionSettingsStore, SubscriptionSettingsStore };
