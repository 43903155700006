import { paidModuleTypes } from '../constants/paidModuleTypes';
import { paidModulesService } from '../services/paidModulesService';
import { getFlagsObject } from './sortingAndGroupingUtil';

export const getAvailableModules = async (entityType) => {
  const availableModules = await paidModulesService.getAvailableModules(entityType);

  return getModulesBoolObj(availableModules);
};

export const getEnabledModules = async (entityId) => {
  const enabledModules = await paidModulesService.getEnabledModules(entityId);

  return getModulesBoolObj(enabledModules);
};

export const getCustomerAvailableModules = async (customerId) => {
  const availableModules =
    await paidModulesService.getCustomerAvailableModules(customerId);

  return getModulesBoolObj(availableModules);
};

export const getModulesBoolObj = (paidModules) => {
  const paidModulesList = paidModules.map((module) => module.paidServiceType);

  return getFlagsObject(paidModuleTypes, paidModulesList);
};
