import React from 'react';

import { Link, Paper } from '@mui/material';

import classes from './CompleteCreateInfo.module.scss';
import { ReactComponent as CheckIcon } from '../../../../../shared/assets/icons/check.svg';

const CompleteCreateInfo = () => {
  return (
    <Paper variant="roundedBox" className={classes.box}>
      <div className={classes.contentBox}>
        <div className={classes.titleBox}>
          <CheckIcon />
          <h1>Thank you</h1>
        </div>
        <div className={classes.infoBox}>
          <p>
            Thank you for submitting your information. An invitation link has been sent to
            your email, it will allow you to complete the registration process.
          </p>
          <p>
            <span className={classes.description}>Disclaimer: </span>The macro plan on
            your app is for EDUCATIONAL PURPOSES ONLY and may not be a reflection of your
            actual caloric needs.
          </p>
        </div>
        <p>
          If you have any questions, please contact &nbsp;
          <Link href="mailto:support@lifebasesolutions.com">
            support@lifebasesolutions.com
          </Link>
        </p>
      </div>
    </Paper>
  );
};

export { CompleteCreateInfo };
