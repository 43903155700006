import React from 'react';

import { angularize } from 'react-in-angularjs';
import { Container } from '@mui/material';

import { SystemProviders } from '../../../shared/providers';
import { InbodyKeysModal } from './containers/InbodyKeysModal/InbodyKeysModal';
import { InbodyKeysTable } from './containers/InbodyKeysTable/InbodyKeysTable';
import { InbodyKeysPageTitle } from './components/InbodyKeysPageTitle/InbodyKeysPageTitle';

const NgzInbodyKeys = () => {
  return (
    <SystemProviders>
      <Container>
        <InbodyKeysPageTitle />
        <InbodyKeysTable />
        <InbodyKeysModal />
      </Container>
    </SystemProviders>
  );
};

angularize(NgzInbodyKeys, 'ngzInbodyKeys', angular.module('app'), {});

export { NgzInbodyKeys };
