import React from 'react';

import classes from './QuestionnaireAnswerOverview.module.scss';
import { InfoBox, LbAlert } from '../../../../../shared/components/ui-components';
import { AnswerInfoRow } from './AnswerInfoRow/AnswerInfoRow';

const QuestionnaireAnswerOverview = () => {
  return (
    <InfoBox title="Overview">
      <div className={classes.container}>
        <LbAlert severity="info" size="small">
          The data was downloaded from a questionnaire filled out by the user.
        </LbAlert>
        <div className={classes.answersBox}>
          <AnswerInfoRow title="Activity Index" questionType="ACTIVITY_INDEX" />
          <AnswerInfoRow title="Goal" questionType="GOAL" />
        </div>
      </div>
    </InfoBox>
  );
};

export { QuestionnaireAnswerOverview };
