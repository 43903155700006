import { makeAutoObservable } from 'mobx';
import { complianceService } from '../services/complianceService';
import { resetAllStore } from '../../shared/stores/resetAllStore';

class ComplianceScoreModalStore {
  constructor() {
    this.setDefaultValues();
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.isMealModalOpened = false;
    this.isDailyModalOpened = false;
    this.mealDetails = {};
    this.dailyResult = {};
  }

  async openModal(customerId, mealDayId, meal) {
    if (meal === 'daily') {
      this.openDailyModal(customerId, mealDayId);
    } else {
      this.openMealModal(customerId, mealDayId, meal);
    }
  }

  async openMealModal(customerId, mealDayId, meal) {
    const mealDetails = await complianceService.getCustomerMealComplianceDetails(
      customerId,
      mealDayId,
      meal,
    );

    this.setMealDetails(mealDetails);
    this.setIsMealModalOpened(true);
  }

  async openDailyModal(customerId, mealDayId) {
    const { dailyResult } = await complianceService.getCustomerDailyComplianceDetails(
      customerId,
      mealDayId,
    );

    this.setDailyResult(dailyResult);
    this.setIsDailyModalOpened(true);
  }

  closeModal() {
    this.setDefaultValues();
  }

  setIsMealModalOpened(isMealModalOpened) {
    this.isMealModalOpened = isMealModalOpened;
  }

  setIsDailyModalOpened(isDailyModalOpened) {
    this.isDailyModalOpened = isDailyModalOpened;
  }

  setMealDetails(mealDetail) {
    this.mealDetails = mealDetail;
  }

  setDailyResult(dailyResult) {
    this.dailyResult = dailyResult;
  }
}

const complianceScoreModalStore = new ComplianceScoreModalStore();

resetAllStore.addResetMethod(complianceScoreModalStore.setDefaultValues);

export { complianceScoreModalStore, ComplianceScoreModalStore };
