import React, { ReactNode } from 'react';

import classNames from 'classnames';

import classes from './Status.module.scss';

type StatusProps = {
  icon: ReactNode;
  children: ReactNode;
  dataTestId?: string;
  align?: 'start' | 'center' | 'end';
};

const Status = ({
  icon,
  children,
  dataTestId = 'status-info',
  align = 'center',
}: StatusProps) => {
  const boxClasses = classNames(classes.statusBox, classes[`align-${align}`]);

  return (
    <div className={boxClasses} data-test-id={dataTestId}>
      {icon}
      <div>{children}</div>
    </div>
  );
};

export { Status };
