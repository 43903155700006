import { apiService } from '../apiService';

export const entityPaymentService = {
  async payManually(entityId) {
    const params = { entityID: entityId };

    const resp = await apiService.post('api/super/subscription/pay-manually', null, {
      params,
    });

    return resp.data;
  },

  async getEntityExpirationCardDate() {
    const resp = await apiService.get('api/admin/entity-expire-card');

    return resp.data;
  },
};
