'use strict';

angular.module('app')
    .controller('CustomerMealPlanMacrosTabController', CustomerMealPlanMacrosTabController);

CustomerMealPlanMacrosTabController.$inject = ['utilService', 'ModalService', 'MealPlanMacroService', 'FoodService', 'CustomerRoleService'];

function CustomerMealPlanMacrosTabController(utilService, ModalService, MealPlanMacroService, FoodService, CustomerRoleService) {
    var vm = this;
    vm.currentDate = new Date();
    vm.currentDateIsOpen = false;
    vm.foodListWithCategories = [];
    vm.foodList = [];
    vm.addedFoodList = [];
    vm.myNetDiaryFood = [];
    vm.foodSearchField = '';
    vm.foodTab = 'recent';
    vm.tabName = 'breakfast';
    vm.foodSpinner = {active: false};

    vm.addFood = addFood;
    vm.formatDate = formatDate;
    vm.newWorkoutTab = newWorkoutTab;
    vm.newFoodTab = newFoodTab;
    vm.newTab = newTab;
    vm.roundMeal = roundMeal;
    vm.getMealsForDay = getMealsForDay;
    vm.getNutrients = getNutrients;
    vm.initFoodListByCategory = initFoodListByCategory;
    vm.doSearch = doSearch;
    vm.getRecentFood = getRecentFood;
    vm.getFavoriteFoodForMeal = getFavoriteFoodForMeal;
    vm.formSaveMealRequest = formSaveMealRequest;
    vm.formSuccessMessage = formSuccessMessage;
    vm.saveMeal = saveMeal;
    vm.removeFood = removeFood;
    vm.addFoodGroup = addFoodGroup;
    vm.getMeasurements = getMeasurements;
    vm.createFoodManually = createFoodManually;
    vm.changeDayType = changeDayType;
    vm.createFavoriteCategory = createFavoriteCategory;
    vm.updateFavCategory = updateFavCategory;
    vm.removeFavoriteCategory = removeFavoriteCategory;
    vm.removeFavoriteFood = removeFavoriteFood;
    vm.initInfiniteItems = initInfiniteItems;
    vm.getFoodWithCategory = getFoodWithCategory;

    getMealsForDay(vm.currentDate);
    getMeasurements();

    function initInfiniteItems () {
        if(vm.isNextPageExist){
            vm.foodSpinner.active = true;
            FoodService.getAllFoods114(vm.foodTab, {page: vm.currentPage, limit: 10})
                .then( function (result) {
                    vm.foodSpinner.active = false;
                    if (result.status === 200) {
                        vm.foodList = vm.foodList.concat(result.data.foods);
                        vm.currentPage = result.data.page + 1;
                        vm.isNextPageExist = result.data.more;
                    }
                });
        }
    }

    function addFood(food) {
        if (!FoodService.isFoodExist(vm.addedFoodList, food)) {
            vm.selectedFood = angular.copy(food);
            vm.selectedFood.proteins = vm.selectedFood.proteins / vm.selectedFood.amount;
            vm.selectedFood.carbs = vm.selectedFood.carbs / vm.selectedFood.amount;
            vm.selectedFood.fats = vm.selectedFood.fats / vm.selectedFood.amount;
            vm.selectedFood.sodium = vm.selectedFood.sodium / vm.selectedFood.amount;
            vm.selectedFood.sugar = vm.selectedFood.sugar / vm.selectedFood.amount;
            vm.selectedFood.veggies = vm.selectedFood.veggies / vm.selectedFood.amount;
            vm.selectedFood.fiber = vm.selectedFood.fiber / vm.selectedFood.amount;
            vm.selectedFood.calorie = vm.selectedFood.calorie / vm.selectedFood.amount;
            ModalService.addFoodToMeal(vm.selectedFood, vm.addedFoodList, vm.myNetDiaryFood, vm.roundMeal);
        } else {
            ModalService.alert('This food is already added to meal.', 'Warning!');
        }
    }

    function formatDate(value, template) {
        return utilService.formatDate(value, template);
    }

    function newWorkoutTab(type, value) {
        vm.workoutTabName = value;
        vm.changeDayType(type);
    }

    function newFoodTab(value) {
        vm.foodTab = value;
        vm.foodSearchField = '';
        vm.initFoodListByCategory(value, vm.tabName);
    }

    function newTab(value, meal) {
        vm.tabName = value;
        vm.addedFoodList = meal.foods;
        vm.initFoodListByCategory(vm.foodTab, value);
    }

    function roundMeal(value) {
        var processedValue = parseFloat((value % 1).toFixed(2));
        if (processedValue >= 0.05) {
            if (processedValue >= 0.95) {
                return Math.ceil(value);
            }
            return value.toFixed(1);
        } else {
            return Math.floor(value);
        }
    }

    function getMealsForDay(currDate) {
        var date = vm.formatDate(currDate, 'YYYY-MM-DD');

        MealPlanMacroService.getMealsForDay({date: date}).then(function (result) {
            vm.hasError = result.status !== 200;
            if (!vm.hasError) {
                vm.daymeals = result.data;
                vm.tabName = vm.daymeals.meals[0].name;
                vm.addedFoodList = vm.daymeals.meals[0].foods;
                vm.workoutTabName = vm.daymeals.workoutDay ? 'workoutDay' : 'nonWorkoutDay';

                for (var i = 0; i < vm.daymeals.meals.length; i++) {
                    if (!vm.daymeals.meals[i].done && !vm.daymeals.meals[i].skipped) {
                        vm.tabName = vm.daymeals.meals[i].name;
                        vm.addedFoodList = vm.daymeals.meals[i].foods;
                        break;
                    }
                }

                vm.getNutrients(date);
                vm.initFoodListByCategory(vm.foodTab, vm.tabName);
            }
        });
    }

    function getNutrients(date) {
        MealPlanMacroService.getNutrients({date: date}).then(function (result) {
            if (result.status === 200) {
                vm.nutrients = result.data.nutrients;
            }
        });
    }

    function getFoodWithCategory() {
        vm.foodList = [];
        vm.isNextPageExist = true;
        vm.currentPage = 0;
        vm.initInfiniteItems();
    }

    function initFoodListByCategory(type,  meal) {
        vm.foodList = [];
        switch (type) {
            case 'recent':
                vm.getRecentFood(meal);
                break;
            case 'favorite':
                vm.getFavoriteFoodForMeal();
                break;
            default:
                vm.getFoodWithCategory();
                break;
        }
    }

    function doSearch(query, type) {
        if (query === '') {
            vm.initFoodListByCategory(type, vm.tabName);
        } else {
            if (!query || !type) {
                query = '';
                type = 'recent';
            }

            FoodService.searchFoods({
                q: query,
                src: type,
                my_food_diary: true
            }).then(function (result) {
                if (result.status === 200) {
                    vm.foodList = result.data.foods;
                }
            });
        }
    }

    function getRecentFood(tabName) {
        vm.foodSpinner.active = true;
        FoodService.getRecentFood({meal: tabName}).then(function (result) {
            vm.foodSpinner.active = false;
            if (result.status === 200) {
                vm.foodListWithCategories = FoodService.formRecentFoodList(result.data.foods);
            }
        });
    }

    function getFavoriteFoodForMeal() {
        vm.foodSpinner.active = true;
        FoodService.getFavoriteFoodByMeal().then(function (result) {
            vm.foodSpinner.active = false;
            if (result.status === 200) {
                vm.foodListWithCategories = result.data.categories.filter(function (el) {
                    return el.meal === vm.tabName || el.meal === 'all_categories' || ((vm.tabName === 'snack1' || vm.tabName === 'snack2' || vm.tabName === 'snack3') && el.mealType === 'snack');
                });
            }
        });
    }

    function formSaveMealRequest() {
        var addedFood = vm.addedFoodList.filter(function (item1) {
            if (!vm.myNetDiaryFood || !vm.myNetDiaryFood.length) {
                return true;
            } else {
                return vm.myNetDiaryFood.filter(function (item2) {
                    return item1.id !== item2.id;
                });
            }
        });

        var myNetDiaryFood = [];
        if (vm.myNetDiaryFood && vm.myNetDiaryFood.length) {
            myNetDiaryFood = vm.myNetDiaryFood.map(function (item) {
                return {
                    my_net_diary_bean_id: item.my_net_diary_bean_id,
                    amountUnit: item.amountUnit,
                    amount: item.amount
                };
            });
        }

        return {
            foods: addedFood.map(function (item) {
                return {id: item.id, amount: item.amount};
            }),
            myNetDiaryFood: myNetDiaryFood
        };
    }

    function formSuccessMessage(food) {
        var mealName = vm.tabName.indexOf('snack') > -1 ? 'snack' : vm.tabName;
        switch (mealName) {
            case 'preworkout':
                mealName = 'pre workout';
                break;
            case 'postworkout':
                mealName = 'post workout';
                break;
        }

        if (food && food.foods.length) {
            ModalService.alert('Added food was saved to ' + mealName, 'Success');
        } else {
            ModalService.alert('You skipped ' + mealName, 'Success');
        }
    }

    function saveMeal() {
        var food = vm.formSaveMealRequest();

        MealPlanMacroService.saveMeal(food, {
            date: vm.formatDate(vm.currentDate, 'YYYY-MM-DD'),
            name: vm.tabName
        }).then(function (result) {
            if (result.status === 200) {
                vm.myNetDiaryFood = [];
                vm.getMealsForDay(vm.currentDate);
                vm.formSuccessMessage(food);
            }
        });
    }

    function removeFood(index) {
        vm.addedFoodList.splice(index, 1);
    }

    function addFoodGroup(foodGroup) {
        var errorFood = '';
        for (var i in foodGroup) {
            if (!FoodService.isFoodExist(vm.addedFoodList, foodGroup[i])) {
                vm.addedFoodList.push(foodGroup[i]);
            } else {
                errorFood += foodGroup[i].name + ', ';
            }
        }
        if (errorFood !== '') {
            ModalService.alert("This food is already added to meal: " + errorFood.substring(0, errorFood.length - 2), 'Warning!');
        }
    }

    function getMeasurements() {
        FoodService.getAllMeasurements().then(function (result) {
            if (result.status === 200) {
                vm.measurements = result.data.measurements;
            }
        });
    }

    function createFoodManually() {
        vm.newFood = {
            amountUnit: vm.measurements[0]
        };

        ModalService.addFood(vm.newFood, vm.measurements, vm.foodTab, vm.getFoodWithCategory);
    }

    function changeDayType(type) {
        CustomerRoleService.changeDayType({
            workout: type,
            date: vm.formatDate(vm.currentDate, 'YYYY-MM-DD')
        }).then(function (result) {
            if (result.status === 200) {
                vm.getMealsForDay(vm.currentDate);
            }
        });
    }

    function createFavoriteCategory() {
        vm.favoriteCategory = {
            action: 'fav',
            meal: vm.tabName,
            foods: vm.addedFoodList.map(function (item) {
                return {
                    id: item.id,
                    amountUnit: item.amountUnit,
                    amount: item.amount
                };
            })
        };

        ModalService.addFavoriteCategory(vm.favoriteCategory, vm.getFavoriteFoodForMeal);
    }

    function updateFavCategory(favCategory) {
        FoodService.updateFavoriteCategory(favCategory).then(function (result) {
            if (result.status === 200) {
                vm.getFavoriteFoodForMeal();
            }
        });
    }

    function removeFavoriteCategory(favCategory) {
        vm.updateFavCategory({
            action: 'unfav',
            meal: favCategory.meal,
            categoryName: favCategory.categoryName,
            foods: favCategory.foods.map(function (item) {
                return {
                    id: item.id,
                    amountUnit: item.amountUnit,
                    amount: item.amount
                };
            })
        });
    }

    function removeFavoriteFood(favCategory, item) {
        vm.updateFavCategory({
            action: 'unfav',
            meal: favCategory.meal,
            categoryName: favCategory.categoryName,
            foods: [{
                id: item.id,
                amountUnit: item.amountUnit,
                amount: item.amount
            }]
        });
    }
}
