import { isEmptyValue } from '../utils/jsUtils';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

type Data = Record<string, any>;

type EventData = {
  event: string;
  event_id: string;
  event_cat: string;
  event_name: string;
} & Record<string, string>;

type PropertyData = {
  event: string;
  user_property_id: string;
  user_property_name: string;
} & Record<string, string>;

type PushEventParams = {
  event: string;
  eventId: string;
  eventCat: string;
  eventName: string;
  props: Data;
};

type PushPushPropertyParams = {
  event: string;
  userPropertyId: string;
  userPropertyName: string;
  userPropertyValue: any;
};

const analyticService = {
  pushData(data: Data): void {
    window.dataLayer.push(data);
  },

  pushEvent({ event, eventId, eventCat, eventName, props }: PushEventParams): void {
    const eventData: EventData = {
      event,
      event_id: eventId,
      event_cat: eventCat,
      event_name: eventName,
    };

    if (!isEmptyValue(props)) {
      Object.entries(props).forEach(([key, value]) => {
        eventData[key] = JSON.stringify(value);
      });
    }

    window.dataLayer.push(eventData);
  },

  pushProperty({
    event,
    userPropertyId,
    userPropertyName,
    userPropertyValue,
  }: PushPushPropertyParams): void {
    const dataForPushing: PropertyData = {
      event,
      user_property_id: userPropertyId,
      user_property_name: userPropertyName,
    };
    dataForPushing[userPropertyName] = JSON.stringify(userPropertyValue);

    window.dataLayer.push(dataForPushing);
  },
};

export { analyticService };
