import { getAvailableModules, getEnabledModules } from '../../modules/shared/utils/paidModulesUtils';
import { ENTITY_PROFILE_TAB_NAME } from '../../modules/shared/constants/tabNames/ENTITY_PROFILE_TAB_NAME';
import { pathQueryParams } from '../../modules/shared/constants/pathQueryParams';

(function () {
    'use strict';

    angular.module('app.entityProfile').controller('EntityProfileController', EntityProfileController);

    EntityProfileController.$inject = [
        '$scope',
        '$routeParams',
        '$location',
        '$mdDialog',
        'utilService',
        'EntityService',
        'CorporateUsersService',
        '$rootScope',
        'ModalService',
        'GeneralService',
        'PaymentService',
        'constants',
        'PosService',
        'SupplementService',
        'CertificationsService',
        'DateTimeUtil',
        '$window',
        'BaseUserUtils',
    ];

    function EntityProfileController($scope, $routeParams, $location, $mdDialog, utilService,
                                     EntityService, CorporateUsersService, $rootScope, ModalService, GeneralService,
                                     PaymentService, constants, PosService, SupplementService, CertificationsService,
                                     DateTimeUtil, $window, BaseUserUtils) {
        var vm = this;
        vm.states = constants.states;
        vm.entityTypeOptions = constants.entityTypeOptions;
        vm.ENTITY_TYPES = constants.enums.entityTypes;
        vm.occupation = constants.occupations;
        vm.jobDepartments = constants.jobDepartments;
        vm.dataEntryFields = constants.inbodyData;
        vm.inbodyTypes = constants.inbodyTypes;
        vm.subscriptionTypes = constants.subscriptionTypes;
        vm.patterns = constants.enums.patterns;
        vm.loggedInUser = utilService.getLoggedInUserData();
        vm.isSuperAdmin = vm.loggedInUser.role === constants.enums.userRoles.SUPERUSER;
        vm.isAdmin = vm.loggedInUser.role === constants.enums.userRoles.ADMIN;
        vm.isSchoolLoggedIn = utilService.getLoggedInUserData().entityType === constants.enums.entityTypes.SCHOOL;
        vm.isParent = vm.loggedInUser.isLoggedByParent;
        vm.sports = [];
        vm.listOfTransactions = [];
        vm.entitySettings = {};
        vm.entityPassword = {};

        vm.TAB_NAME = ENTITY_PROFILE_TAB_NAME;
        vm.selectedTabName = null; // tabs should be initialized only after all necessary data is loaded

        vm.positionGroup = [];
        vm.statuses = [
            {name: 'Active', value: true},
            {name: 'Inactive', value: false}
        ];
        vm.spinner = {active: false};
        vm.corporatePlans = [];
        vm.corporatePlansColors = ['blue', 'green', 'purple', 'red', 'seablue', 'lightgreen', 'yellow', 'orange'];
        vm.startCorporatePlanIndex = 0;
        vm.updateEntity = {};
        vm.currentEntityAdmin = {};
        vm.assignedAtIsOpen = false;
        vm.assignedAt = null;
        vm.minDate = DateTimeUtil.minusYears(vm.currentDate, 100);
        vm.maxDate = DateTimeUtil.plusYears(vm.currentDate, 100);
        vm.loginId = {
            current: '',
            new: ''
        };
        vm.isActiveCalendarAvailable = false;
        vm.isActiveCalendarEnabled = false; // TODO used enabledModules instead of this one
        vm.enabledModules = {};
        vm.paymentFlowType = {};

        vm.init = init;
        vm.setUserId = setUserId;
        vm.setEntityInfo = setEntityInfo;
        vm.goBack = goBack;
        vm.initStripePublicKey = initStripePublicKey;
        vm.initPageTab = initPageTab;
        vm.initCurrentEntity = initCurrentEntity;
        vm.processEntityInfo = processEntityInfo;
        vm.setIsActiveCalendarAvailable = setIsActiveCalendarAvailable;
        vm.setIsActiveCalendarEnabled = setIsActiveCalendarEnabled;
        vm.payForSubscription = payForSubscription;

        vm.initSports = initSports;
        vm.isSportAvailable = isSportAvailable;
        vm.isPositionGroupAvailable = isPositionGroupAvailable;
        vm.isJobDepartmentAvailable = isJobDepartmentAvailable;
        vm.changeEntityType = changeEntityType;
        vm.initPositionGroup = initPositionGroup;

        vm.getLoginId = getLoginId;
        vm.saveLoginId = saveLoginId;
        vm.saveNewPassword = saveNewPassword;
        vm.setPassword = setPassword;
        vm.changePassword = changePassword;

        vm.initSerialNumbers = initSerialNumbers;
        vm.addSerialNumber = addSerialNumber;
        vm.deleteSerialNumber = deleteSerialNumber;

        vm.addContact = addContact;
        vm.countContacts = countContacts;
        vm.removeContact = removeContact;

        vm.showUnlinkPopup = showUnlinkPopup;
        vm.unlinkLightSpeedService = unlinkLightSpeedService;
        vm.getRetrieveCustomerTypes = getRetrieveCustomerTypes;
        vm.savePosData = savePosData;
        vm.goToLightSpeedPage = goToLightSpeedPage;


        vm.saveCorporatePlans = saveCorporatePlans;
        vm.saveSupplementPriceByPlan = saveSupplementPriceByPlan;
        vm.saveSupplementPrice = saveSupplementPrice;
        vm.getCorporatePlans = getCorporatePlans;
        vm.addNewPlan = addNewPlan;
        vm.removeNewPlan = removeNewPlan;

        vm.showUpdateCardDialog = showUpdateCardDialog;
        vm.pay = pay;
        vm.calculateExpirationPeriod = calculateExpirationPeriod;
        vm.checkRenewalSubscriptions = checkRenewalSubscriptions;
        vm.getEntityPayments = getEntityPayments;
        vm.cancelRenewalSubscription = cancelRenewalSubscription;
        vm.compareSubscriptions = compareSubscriptions;
        vm.addNewSubscription = addNewSubscription;
        vm.formatExpireDate = formatExpireDate;

        vm.initBundles = initBundles;
        vm.removeBundle = removeBundle;
        vm.addNewBundle = addNewBundle;
        vm.checkPlanDuplicate = checkPlanDuplicate;

        vm.updateEntityContacts = updateEntityContacts;
        vm.updateEntityBusinessInfo = updateEntityBusinessInfo;
        vm.updateEntityLegalInfo = updateEntityLegalInfo;
        vm.updateEntityInbodyInfo = updateEntityInbodyInfo;
        vm.updateEntityAppCost = updateEntityAppCost;
        vm.updateEntityPricing = updateEntityPricing;
        vm.updateEntityCertificates = updateEntityCertificates;
        vm.updateUserPaymentSource = updateUserPaymentSource;
        vm.getEntityAdmin = getEntityAdmin;
        vm.changeAvatar = changeAvatar;

        vm.allowNonInbodyUsers = allowNonInbodyUsers;
        vm.checkSelectedInbodyTypes = checkSelectedInbodyTypes;
        vm.checkInbodyTypes = checkInbodyTypes;
        vm.enableWaterFields = enableWaterFields;

        vm.init();

        function init() {
            vm.setUserId();
            vm.spinner.active = true;
            // rewrite to async/await
            PaymentService.getPaymentFlowTypeFlags(vm.userId)
                .then((paymentFlowType) => {
                    vm.paymentFlowType = paymentFlowType;

                    return Promise.all([
                        vm.initStripePublicKey(),
                        vm.initCurrentEntity('business'),
                        ]).then(() => {
                            vm.initPageTab();
                        });
                })
                .finally(() => vm.spinner.active = false);
        }

        function setUserId() {
            if (vm.loggedInUser.role === constants.enums.userRoles.ADMIN) {
                vm.userId = vm.loggedInUser.gymId;
            } else {
                vm.userId = $routeParams.id;
            }
        }

        function allowNonInbodyUsers() {
            vm.entitySettings.entryFields = {
                weight: true,
                gender: true,
                height: true,
                percent_body_fat: true,
                lbm: true,
                bmr: true,
                tbw: true,
                ecw_tbw: true,
                smm: true,
                navel: true,
                thigh: true,
                bicep: true,
                chest: true,
                hip: true
            };
        }

        function goBack () {
            utilService.goBackWithParam('/entities-list', constants.pages.entityList);
        }

        function changeAvatar(newAvatar) {
            BaseUserUtils.updateAvatar(newAvatar, vm.currentEntityAdmin);
        }

        function initStripePublicKey() {
           return GeneralService.getStripePublicKey().then(function (res) {
                if (res.status === 200 && res.data.key) {
                    vm.handler = StripeCheckout.configure({
                        key: res.data.key,
                        image: 'https://content.lifebase.solutions/static-content/logo.png',
                        locale: 'auto',
                        token: vm.payForSubscription
                    });

                    vm.updatePaymentConf = StripeCheckout.configure({
                        key: res.data.key,
                        image: 'https://content.lifebase.solutions/static-content/logo.png',
                        locale: 'auto',
                        panelLabel: 'Update',
                        source: vm.updateUserPaymentSource
                    });
                }
            });
        }

        function updateUserPaymentSource(src) {
            PaymentService.updateUserPaymentSource(vm.entitySettings.id, {src: src.id});
        }

        function initPageTab() {
            const initialTab = $routeParams[pathQueryParams.initialTabName];
            const isInitialTabExist = initialTab && Object.values(vm.TAB_NAME).includes(initialTab);

            if (!isInitialTabExist) {
                vm.selectedTabName = vm.TAB_NAME.BUSINESS_INFO;
                return;
            }

            vm.selectedTabName = initialTab;

            if (initialTab === vm.TAB_NAME.POS) {
                PosService.connectEntityToLightspeedPosSystem(localStorage.getItem('lightspeedToken')).then(function (res) {
                    if (res.status === 200) {
                        vm.entitySettings.posAccountId = res.data.posAccountId;
                        vm.entitySettings.posAccountName = res.data.posAccountName;
                        localStorage.setItem('lightspeedToken', null);
                    }
                });
            }
        }

        function initCurrentEntity(field) {
            if (vm.loggedInUser.role === 'SUPERUSER') {
                return EntityService.getEntityById($routeParams.id).then(function (res) {
                    if (res.status === 200) {
                        vm.processEntityInfo(field, res.data);
                        vm.entitySettings.subscriptions.sort(vm.compareSubscriptions);
                    }
                });
            } else if (vm.loggedInUser.role === 'ADMIN') {
                return EntityService.getEntitiesOfAdmin().then(function (res) {
                    if (res.status === 200) {
                        vm.processEntityInfo(field, res.data.entities[0]);
                    }
                });
            }
        }

        function processEntityInfo(field, info) {
            vm.entitySettings = info;
            vm.entitySettings.monthlySupplementPrise = vm.entitySettings.monthlySupplementPrise / 100;
            vm.setEntityInfo();
            vm.initSerialNumbers();
            vm.initSports(field);
            vm.initPositionGroup(field);
            vm.getEntityPayments();
            vm.setIsActiveCalendarAvailable();
            vm.setIsActiveCalendarEnabled();

            if (!vm.entitySettings.allowSupplementUsers) {
                vm.getCorporatePlans();
            }

            for (var i in vm.entitySettings.subscriptions) {
                vm.entitySettings.subscriptions[i].setUpEntityPrice = vm.entitySettings.subscriptions[i].setUpEntityPrice / 100;
                vm.entitySettings.subscriptions[i].setUpEkinPrice = vm.entitySettings.subscriptions[i].setUpEkinPrice / 100;
                vm.entitySettings.subscriptions[i].initInvoiceEntityPrice = vm.entitySettings.subscriptions[i].initInvoiceEntityPrice / 100;
                vm.entitySettings.subscriptions[i].initInvoiceEkinPrice = vm.entitySettings.subscriptions[i].initInvoiceEkinPrice / 100;
                vm.entitySettings.subscriptions[i].renewFeeEntityPrice = vm.entitySettings.subscriptions[i].renewFeeEntityPrice / 100;
                vm.entitySettings.subscriptions[i].renewFeeEkinPrice = vm.entitySettings.subscriptions[i].renewFeeEkinPrice / 100;
                vm.entitySettings.subscriptions[i].minChargeSetup = vm.entitySettings.subscriptions[i].minChargeSetup / 100;
                vm.entitySettings.subscriptions[i].minChargeInit = vm.entitySettings.subscriptions[i].minChargeInit / 100;
                vm.entitySettings.subscriptions[i].minChargeRenew = vm.entitySettings.subscriptions[i].minChargeRenew / 100;
            }
        }

        function setEntityInfo() {
            if (vm.isSuperAdmin) {
                vm.getEntityAdmin();
            } else {
                vm.currentEntityAdmin = angular.copy(vm.loggedInUser);
            }
        }

        function payForSubscription(token) {
            vm.spinner.active = true;
            PaymentService.entityPayForSubscription(vm.userId, {token: token.id}).then(function (res) {
                vm.spinner.active = false;
                if (res.status === 200) {
                    vm.subscriptionLoading = true;
                    setTimeout(function () {
                        vm.getEntityPayments();
                    }, 8000);
                } else if (res.status === 402) {
                    ModalService.confirm('Error Message', vm.pay, null, res.data.message, null, 'Enter card details');
                }
            })
        }

        function initSports(field) {
            vm.sports = vm.entitySettings[field + '_entity_type'] === vm.ENTITY_TYPES.UNIVERSITY ?
                constants.sportsForUniversity : constants.sportsForOrganization;
        }

        function isSportAvailable(field) {
            if (!vm.entitySettings) return false;
            switch (vm.entitySettings[field + '_entity_type']) {
                case vm.ENTITY_TYPES.UNIVERSITY:
                case vm.ENTITY_TYPES.SPORTS_ORGANIZATION:
                    return true;
            }
        }

        function isPositionGroupAvailable(field) {
            if (!vm.entitySettings) return false;
            return vm.entitySettings[field + '_entity_type'] === vm.ENTITY_TYPES.UNIVERSITY && vm.positionGroup.length > 0;
        }

        function isJobDepartmentAvailable(field) {
            if (!vm.entitySettings) return false;
            return vm.entitySettings[field + '_entity_type'] === vm.ENTITY_TYPES.CORPORATE_ACCOUNT;
        }

        function changeEntityType(field) {
            if (!vm.isSportAvailable(field)) vm.entitySettings[field + '_sport'] = null;
            if (!vm.isJobDepartmentAvailable(field)) vm.entitySettings[field + '_job_department'] = null;
            vm.initSports(field);
            vm.initPositionGroup(field);
        }

        function initPositionGroup(field) {
            vm.positionGroup = vm.entitySettings[field + '_entity_type'] !== vm.ENTITY_TYPES.UNIVERSITY || vm.entitySettings[field + '_sport'] === 'Golf' ?
                [] : EntityService.getPositionGroups(vm.entitySettings[field + '_sport']);
            if (!vm.isPositionGroupAvailable(field)) vm.entitySettings[field + '_position_group'] = null;
        }

        function getLoginId() {
            vm.spinner.active = true;
            EntityService.getLoginId(vm.userId)
              .then(res => {
                  if (res.status === 200) {
                      vm.loginId.current = res.data.loginId;
                  }
              }).finally(() => vm.spinner.active = false);
        }

        function saveLoginId(form) {
            vm.spinner.active = true;
            EntityService.saveLoginId(vm.userId, vm.loginId.new)
              .then(res => {
                  if (res.status === 200) {
                      ModalService.savedChanges();
                      vm.loginId.current = res.data.loginId;
                      vm.loginId.new = '';
                      form.$setPristine();
                      form.$setUntouched();
                  }
              }).finally(() => vm.spinner.active = false);
        }

        function saveNewPassword(form) {
            if ($routeParams.id) {
                const entityId = vm.currentEntityAdmin.id;
                vm.setPassword(entityId, form);
            } else {
                vm.changePassword(form);
            }
        }

        function getEntityAdmin(form) {
            vm.spinner.active = true;
            GeneralService.getEntityAdmin($routeParams.id).then(function (res) {
                vm.spinner.active = false;
                if (res.status === 200) {
                    vm.currentEntityAdmin = res.data;
                }
            });
        }

        function setPassword(id, form) {
            GeneralService.setPassword(id, vm.entityPassword).then(function (result) {
                if (result.status === 200) {
                    vm.entityPassword = {};
                    form.$setPristine();
                    form.$setUntouched();
                }
            });
        }

        function changePassword(form) {
            GeneralService.changePassword(vm.entityPassword).then(function (res) {
                if (res.data.message === 'Current password is wrong') {
                    form['currentPassword'].$setValidity('wrong', false);
                }
            });
        }

        function initSerialNumbers() {
            vm.entitySettings.serial_numbers = vm.entitySettings.serial_numbers.map(function (value) {
                return {number: value};
            });
        }

        function addSerialNumber() {
            vm.entitySettings.serial_numbers.push({});
        }

        function deleteSerialNumber(index) {
            vm.entitySettings.serial_numbers.splice(index, 1);
        }

        function addContact() {
            vm.entitySettings.contacts.push({});
        }

        function countContacts() {
            var count = 0;
            vm.entitySettings.contacts.forEach(function (value) {
                count += value.id ? 1 : 0;
            });
            return count;
        }

        function removeContact(index) {
            if (vm.entitySettings.contacts[index].id) {
                if (vm.countContacts() > 1) {
                    EntityService.deleteEntityContact(vm.entitySettings.id, vm.entitySettings.contacts[index].id).then(function (res) {
                        if (res.status === 200) {
                            vm.entitySettings.contacts.splice(index, 1);
                        }
                    });
                } else {
                    ModalService.alert('Entity must have one contact at least.', 'Error');
                }
            } else {
                vm.entitySettings.contacts.splice(index, 1);
            }
        }

        function showUnlinkPopup() {
            ModalService.unlinkLightSpeedDialog(vm.unlinkLightSpeedService);
        }

        function unlinkLightSpeedService() {
            PosService.unlinkLightspeedPosAccountFromEntity().then(function (res) {
                if (res.status === 200) {
                    vm.entitySettings.posAccountId = res.data.posAccountId;
                    vm.entitySettings.posAccountName = res.data.posAccountName;
                }
            });
        }

        function savePosData() {
            SupplementService.updateSupplementInfoAsAdmin(vm.userId, {
                noneCustomerTypeId: vm.entitySettings.noneCustomerTypeId,
                ekinCustomerTypeId: vm.entitySettings.ekinCustomerTypeId,
                supplemnetCustomerTypeId: vm.entitySettings.supplemnetCustomerTypeId
            });
        }

        function getRetrieveCustomerTypes() {
            PosService.getListOfCustomerTypesFromPosSystem().then(function (res) {
                if (res.status === 200) {
                    vm.retrieveCustomerTypes = res.data.types;

                    vm.entitySettings.noneCustomerTypeId = vm.entitySettings.noneCustomerTypeId === null ?
                        vm.retrieveCustomerTypes[0].customerTypeID : vm.entitySettings.noneCustomerTypeId;

                    vm.entitySettings.supplemnetCustomerTypeId = vm.entitySettings.supplemnetCustomerTypeId === null ?
                        vm.retrieveCustomerTypes[0].customerTypeID : vm.entitySettings.supplemnetCustomerTypeId;

                    vm.entitySettings.ekinCustomerTypeId = vm.entitySettings.ekinCustomerTypeId === null ?
                        vm.retrieveCustomerTypes[0].customerTypeID : vm.entitySettings.ekinCustomerTypeId;
                }
            });
        }

        function goToLightSpeedPage() {
            window.open('https://cloud.lightspeedapp.com/login.html?redirect_after_login=%2Foauth%2Fauthorize.php%3Fresponse_type%3Dcode%26client_id%3D' +
                constants.clientId + '%26scope%3Demployee%3Acustomers', "_self");
        }

        function saveCorporatePlans() {
            if (vm.loggedInUser.role === 'SUPERUSER') {
                var processedPlans = angular.copy(vm.corporatePlans);
                processedPlans.map(function (value) {
                    value.price = value.price * 100;
                });

                CorporateUsersService.putListOfSubscriptionPlans($routeParams.id, {plans: processedPlans}).then(function (res) {
                    if (res.status === 200) {
                        if (vm.entitySettings.monthlySupplementPrise !== processedPlans[vm.selectedCorpPlan].price / 100) {
                            var plan = res.data.plans.find(function (value) {
                                return value.price === processedPlans[vm.selectedCorpPlan].price;
                            });
                            vm.saveSupplementPriceByPlan(plan);
                        } else {
                            ModalService.savedChanges();
                        }
                    }
                });
            }
        }

        function saveSupplementPriceByPlan(plan) {
            PaymentService.updateEntitySubscriptionPriceFromPlan($routeParams.id, plan.id);
        }

        function saveSupplementPrice() {
            SupplementService.updateSupplementInfoAsAdmin(vm.userId, {monthlySupplementPrise: vm.entitySettings.monthlySupplementPrise * 100});
        }

        function getCorporatePlans() {
            CorporateUsersService.getListOfSubscriptionPlans($routeParams.id).then(function (result) {
                if (result.status === 200) {
                    vm.corporatePlans = angular.copy(result.data.plans);
                    vm.corporatePlans.map(function (value, index) {
                        value.price = value.price / 100;
                        if (vm.entitySettings.monthlySupplementPrise === value.price) {
                            vm.selectedCorpPlan = index;
                        }
                    });
                    for (var i = 0; i < Math.floor(vm.corporatePlans.length / 8); i++) {
                        vm.corporatePlansColors = vm.corporatePlansColors.concat(vm.corporatePlansColors);
                    }
                }
            });
        }

        function addNewPlan() {
            vm.corporatePlans.push({name: "Plan Name", price: 31, userQty: 0});
        }

        function removeNewPlan(index) {
            if (vm.selectedCorpPlan === index) {
                vm.selectedCorpPlan = null;
            } else if (vm.selectedCorpPlan > index) {
                vm.selectedCorpPlan--;
            }
            vm.corporatePlans.splice(index, 1);
        }

        function showUpdateCardDialog() {
            vm.updatePaymentConf.open({
                email: vm.entitySettings.owner_email,
                name: vm.entitySettings.business_name,
                description: 'Update payment source',
                currency: 'usd'
            });
        }

        function pay() {
            if (vm.handler) {
                vm.handler.open({
                    email: vm.entitySettings.owner_email,
                    name: 'Renewal Subscription',
                    amount: vm.entitySettings.monthlySupplementPrise * 100,
                    currency: 'usd'
                });
            }
        }

        function calculateExpirationPeriod(date) {
            return PaymentService.calculateExpirationPeriod(date);
        }

        function checkRenewalSubscriptions() {
            if (vm.entityPayments) {
                for (var i = 0; i < vm.entityPayments.length; i++) {
                    var subscr = vm.entityPayments[i];
                    if (subscr.renewalActive && subscr.paid) {
                        return subscr;
                    }
                }
            }
            return -1;
        }

        function getEntityPayments() {
            PaymentService.getEntitysPaymentTransaction(vm.userId).then(function (res) {
                vm.subscriptionLoading = false;
                if (res.status === 200) {
                    vm.entityPayments = res.data.transactions;
                }
            })
        }

        function formatExpireDate(date) {
            return utilService.formatExpireDate(date);
        }

        function cancelRenewalSubscription() {
            vm.canceledPlan = vm.checkRenewalSubscriptions();

            vm.spinner.active = true;
            EntityService.entityCancelSubscription(vm.userId).then(function (res) {
                vm.spinner.active = false;

                if (res.status === 200) {
                    ModalService.canceledEntityPlanInfoDialog(vm.canceledPlan, vm.getEntityPayments);
                }
            })
        }

        function compareSubscriptions(a, b) {
            if (a.daysOfSubscription > b.daysOfSubscription)
                return -1;
            if (a.daysOfSubscription < b.daysOfSubscription)
                return 1;
            return 0;
        }

        function addNewSubscription() {
            vm.entitySettings.subscriptions.push({
                title: '',
                info: '',
                daysOfSubscription: null,
                renewDaysOfSubscription: null,
                setUpEntityPrice: null,
                setUpEkinPrice: null,
                initInvoiceEntityPrice: null,
                initInvoiceEkinPrice: null,
                minChargeInit: null,
                minChargeSetup: null,
                renewFeeEntityPrice: null,
                renewFeeEkinPrice: null,
                minChargeRenew: null,
                active: true,
                subscriptionType: 'NUTRITION'
            })
        }

        function initBundles() {
            CertificationsService.getEntityCertificates($routeParams.id).then(function (result) {
                if (result.status === 200) {
                    vm.entitySettings.certificationPlansL1 = result.data.certificationPlansL1;
                    vm.entitySettings.certificationPlansL2 = result.data.certificationPlansL2;

                    vm.entitySettings.certificationPlansL1.map(function (value) {
                        value.price = value.price / 100;
                    });
                    vm.entitySettings.certificationPlansL2.map(function (value) {
                        value.price = value.price / 100;
                    });
                }
            });
        }

        function removeBundle(name, index) {
            vm.entitySettings[name].splice(index, 1);
        }

        function addNewBundle(name) {
            vm.entitySettings[name].push({name: "Bundle Name", price: 0.31, qty: 0});
        }

        function checkPlanDuplicate(currList) {
            var valueArr = currList.map(function (item) {
                return item.price;
            });
            return valueArr.some(function (item, idx) {
                return valueArr.indexOf(item) !== idx;
            });
        }

        function updateEntityBusinessInfo() {
            vm.spinner.active = true;
            EntityService.updateEntityBusinessInfo(vm.entitySettings.id, {
                business_name: vm.entitySettings.business_name,
                business_address: vm.entitySettings.business_address,
                business_city: vm.entitySettings.business_city,
                business_state: vm.entitySettings.business_state,
                business_zip: vm.entitySettings.business_zip,
                bus_tax_id: vm.entitySettings.bus_tax_id,
                business_phone: vm.entitySettings.business_phone,
                numbers_of_members: vm.entitySettings.numbers_of_members,
                business_occupation: vm.entitySettings.business_occupation,
                business_entity_type: vm.entitySettings.business_entity_type,
                business_sport: vm.entitySettings.business_sport,
                business_job_department: vm.entitySettings.business_job_department,
                business_position_group: vm.entitySettings.business_position_group,
                owner_first_name: vm.entitySettings.owner_first_name,
                owner_last_name: vm.entitySettings.owner_last_name,
                owner_phone: vm.entitySettings.owner_phone,
                owner_email: vm.entitySettings.owner_email,
                owner_state: vm.entitySettings.owner_state,
                owner_city: vm.entitySettings.owner_city,
                owner_zip: vm.entitySettings.owner_zip,
                owner_address1: vm.entitySettings.owner_address1,
                business_website: vm.entitySettings.business_website,
                business_facebook: vm.entitySettings.business_facebook,
                business_instagram: vm.entitySettings.business_instagram,
                owner_address2: vm.entitySettings.owner_address2
            }).then(function (result) {
                vm.spinner.active = false;
                if (result.status === 200) {
                    ModalService.savedChanges();
                }
            });
        }

        function updateEntityLegalInfo() {
            vm.spinner.active = true;
            EntityService.updateEntityLegalInfo(vm.entitySettings.id, {
                business_name: vm.entitySettings.legal_business_name,
                business_address: vm.entitySettings.legal_business_address,
                business_city: vm.entitySettings.legal_business_city,
                business_state: vm.entitySettings.legal_business_state,
                business_zip: vm.entitySettings.legal_zip,
                bus_tax_id: vm.entitySettings.legal_bus_tax_id,
                business_phone: vm.entitySettings.legal_business_phone,
                numbers_of_members: vm.entitySettings.legal_numbers_of_members,
                business_occupation: vm.entitySettings.legal_occupation,
                business_entity_type: vm.entitySettings.legal_entity_type,
                business_sport: vm.entitySettings.legal_sport,
                business_job_department: vm.entitySettings.legal_job_department,
                business_position_group: vm.entitySettings.legal_position_group,
                owner_first_name: vm.entitySettings.legal_owner_first_name,
                owner_last_name: vm.entitySettings.legal_owner_last_name,
                owner_phone: vm.entitySettings.legal_owner_phone,
                owner_email: vm.entitySettings.legal_owner_email,
                owner_state: vm.entitySettings.legal_owner_state,
                owner_city: vm.entitySettings.legal_owner_city,
                owner_zip: vm.entitySettings.legal_owner_zip,
                owner_address1: vm.entitySettings.legal_owner_address1,
                business_website: vm.entitySettings.legal_business_website,
                business_facebook: vm.entitySettings.legal_business_facebook,
                business_instagram: vm.entitySettings.legal_business_instagram,
                owner_address2: vm.entitySettings.legal_owner_address2
            }).then(function (result) {
                vm.spinner.active = false;
                if (result.status === 200) {
                    ModalService.savedChanges();
                }
            });
        }

        function checkSelectedInbodyTypes() {
            for (var type in vm.entitySettings.supportedInbodyTypes) {
                if (vm.entitySettings.supportedInbodyTypes[type]) {
                    return true;
                }
            }
            return false;
        }

        function updateEntityInbodyInfo() {
            vm.spinner.active = true;
            const isSuper = vm.loggedInUser.role === constants.enums.userRoles.SUPERUSER;
            const request = isSuper ?
                {
                    allowInbodyUsers: vm.checkSelectedInbodyTypes(),
                    allowNonInbodyUsers: vm.entitySettings.allowNonInbodyUsers,
                    serialNumbers: vm.entitySettings.serial_numbers.map(function (value) {
                        return value.number;
                    }),
                    entryFields: vm.entitySettings.entryFields,
                    supportedInbodyTypes: vm.entitySettings.supportedInbodyTypes
                } : {
                    numbers: vm.entitySettings.serial_numbers.map(function (value) {
                        return value.number;
                    })
                };

            EntityService.updateEntityInbodyInfo(vm.entitySettings.id, request, isSuper).then(function (result) {
                vm.spinner.active = false;
                if (result.status === 200) {
                    ModalService.savedChanges();
                }
            });
        }

        function updateEntityAppCost() {
            if (!vm.entitySettings.allowSupplementUsers && !(vm.selectedCorpPlan >= 0)) {
                ModalService.alert('Corporate plan isn\'t selected', 'Warning');
            } else if (!vm.entitySettings.allowSupplementUsers && vm.checkPlanDuplicate(vm.corporatePlans)) {
                ModalService.alert('Few corporate plans have the same price', 'Warning');
            } else {
                vm.spinner.active = true;
                EntityService.updateEntityPaymentInformation(vm.entitySettings.id, {
                    gymPayments: vm.entitySettings.gymPayments,
                    set_up_facility: vm.entitySettings.set_up_facility,
                    set_up_coach: vm.entitySettings.set_up_coach,
                    number_of_coaches: vm.entitySettings.number_of_coaches,
                    cap_cost_enabled: vm.entitySettings.cap_cost_enabled,
                    cap_cost_amount: vm.entitySettings.cap_cost_amount,
                    nutrition_certification: vm.entitySettings.nutrition_certification,
                    supplement_certification: vm.entitySettings.supplement_certification,
                    purchesed_nutrition_certification: vm.entitySettings.purchesed_nutrition_certification,
                    purchesed_supplement_certification: vm.entitySettings.purchesed_supplement_certification,
                    routing_number: vm.entitySettings.routing_number,
                    account_number: vm.entitySettings.account_number,
                    transfers_name: vm.entitySettings.transfers_name,
                    transfers_website: vm.entitySettings.transfers_website,
                    transfers_owner_first_name: vm.entitySettings.transfers_owner_first_name,
                    transfers_owner_last_name: vm.entitySettings.transfers_owner_last_name,
                    transfers_owner_email: vm.entitySettings.transfers_owner_email,
                    transfers_city: vm.entitySettings.transfers_city,
                    transfers_address: vm.entitySettings.transfers_address,
                    transfers_state: vm.entitySettings.transfers_state,
                    transfers_country: vm.entitySettings.transfers_country,
                    transfers_zip: vm.entitySettings.transfers_zip
                }).then(function (res) {
                    vm.spinner.active = false;
                    if (res.status === 200) {
                        if (vm.loggedInUser.role === 'SUPERUSER') {
                            !vm.entitySettings.allowSupplementUsers ? vm.saveCorporatePlans() : vm.saveSupplementPrice();
                        } else {
                            ModalService.savedChanges();
                        }
                    }
                });
            }
        }

        function updateEntityContacts() {
            vm.spinner.active = true;
            EntityService.updateEntityContacts(vm.entitySettings.id, {contacts: vm.entitySettings.contacts}).then(function (res) {
                vm.spinner.active = false;
                if (res.status === 200) {
                    ModalService.savedChanges();
                    vm.entitySettings.contacts = res.data.contacts;
                }
            });
        }

        function updateEntityPricing() {
            var countActiveSubscription = vm.entitySettings.subscriptions.filter(function (value) {
                return value.active === true;
            }).length;
            if (countActiveSubscription > 0) {
                vm.spinner.active = true;
                PaymentService.updateEntitySubscriptions(vm.entitySettings.id, {subscriptions: vm.entitySettings.subscriptions}).then(function (res) {
                    vm.spinner.active = false;
                    if (res.status === 200) {
                        ModalService.savedChanges();
                    }
                });
            } else {
                ModalService.alert('You must select at least one subscription.', 'Error');
            }
        }

        function updateEntityCertificates() {
            if (vm.checkPlanDuplicate(vm.entitySettings.certificationPlansL1) ||
                vm.checkPlanDuplicate(vm.entitySettings.certificationPlansL2)) {
                ModalService.alert('Few bundles have the same price', 'Warning');
            } else {
                CertificationsService.updateEntityCertificates($routeParams.id, {
                    certificationPlansL1: vm.entitySettings.certificationPlansL1.map(function (value) {
                        return {id: value.id, name: value.name, price: value.price * 100, qty: value.qty}
                    }),
                    certificationPlansL2: vm.entitySettings.certificationPlansL2.map(function (value) {
                        return {id: value.id, name: value.name, price: value.price * 100, qty: value.qty}
                    })
                }).then(function (result) {
                    if (result.status === 200) {
                        ModalService.savedChanges();
                    }
                })
            }
        }

        function checkInbodyTypes() {
            for (var type in vm.inbodyTypes) {
                if (vm.entitySettings.supportedInbodyTypes[vm.inbodyTypes[type].dbName] &&
                    type !== vm.inbodyTypes.NON_INBODY.value) {
                    return true;
                }
            }
            return vm.entitySettings.allowNonInbodyUsers;
        }

        function enableWaterFields() {
            if (!vm.entitySettings.entryFields.icw || !vm.entitySettings.entryFields.ecw) {
                vm.entitySettings.entryFields.tbw = false;
                vm.entitySettings.entryFields.ecw_tbw = false;
            }
        }

        async function setIsActiveCalendarAvailable() {
            const availableModules = await getAvailableModules(vm.entitySettings.business_entity_type);
            vm.isActiveCalendarAvailable = availableModules.ACTIVE_CALENDAR;
            $scope.$apply();
        }

        async function setIsActiveCalendarEnabled () {
            const enabledModules = await getEnabledModules(vm.entitySettings.id);
            vm.enabledModules = enabledModules;
            vm.isActiveCalendarEnabled = enabledModules.ACTIVE_CALENDAR;
            $scope.$apply();
        }
    }
})();
