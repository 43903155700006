'use strict';

angular.module('app').factory('CorporateUsersService', CorporateUsersService);

CorporateUsersService.$inject = ['ApiService', 'utilService'];

function CorporateUsersService(ApiService, utilService) {
    return {
        getNumberOfCorporateUsers: function (entityId) {
            var url = utilService.getLoggedInUserData().role === 'SUPERUSER' ? 'api/super/corporate-entity/' + entityId + '/limits' : 'api/coach/corporate-entity/limits';

            return ApiService.get(url).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getListOfSubscriptionPlans: function (entityId) {
            var url = utilService.getLoggedInUserData().role === 'SUPERUSER' ? 'api/super/corporate-entity/' + entityId + '/plans' : 'api/admin/corporate-entity/plans';

            return ApiService.get(url).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getEntitiesPaymentTransaction: function (userId) {
            var url = utilService.getLoggedInUserData().role === 'CUSTOMER' ? 'api/corporate-subscriptions' : 'api/coach/' + userId + '/corporate-subscriptions';
            return ApiService.get(url).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        putListOfSubscriptionPlans: function (entityId, prms) {
            return ApiService.put('api/super/corporate-entity/' + entityId + '/plans', prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        }
    };
}
