import React from 'react';

import { angularize } from 'react-in-angularjs';
import { Paper, Collapse } from '@mui/material';

import { useToggle } from 'react-use';

import classes from './NbzCustomerSetupFinalizationChecklist.module.scss';

import { SystemProviders } from '../../../shared/providers';
import { TitleWithDescription } from '../../../shared/components/text-formation-components';
import { YesNoBtnRow } from '../../../shared/components/buttons';
import { CheckPointsList } from './CheckPointsList/CheckPointsList';
import { useSetupFinalizationChecklist } from './hooks/useSetupFinalizationChecklist';

type NgzCustomerSetupFinalizationChecklistProps = {
  customerId: number;
};

// only for new flow and coach, control on the page
const NgzCustomerSetupFinalizationChecklist = ({
  customerId,
}: NgzCustomerSetupFinalizationChecklistProps) => {
  const [isShowPoints, toggleIsShowPoints] = useToggle(true);
  const { isShowWidget, handleFinishChecklist } =
    useSetupFinalizationChecklist(customerId);

  if (!isShowWidget) {
    return null;
  }

  return (
    <SystemProviders>
      <Paper variant="roundedBox" className={classes.box}>
        <TitleWithDescription
          title="Congrats! The consultation is complete and your client is ready to begin using LifeBase app!"
          description="Do these things immediately"
          titleFont="body-14-bold"
        />
        <Collapse in={isShowPoints}>
          <CheckPointsList />
        </Collapse>
        <YesNoBtnRow
          yesText="Finish"
          yesHandler={handleFinishChecklist}
          noText={isShowPoints ? 'Hide' : 'Expand'}
          noHandler={toggleIsShowPoints}
          size="small"
        />
      </Paper>
    </SystemProviders>
  );
};

angularize(
  NgzCustomerSetupFinalizationChecklist,
  'ngzCustomerSetupFinalizationChecklist',
  angular.module('app.customerManagement'),
  { customerId: '<' },
);

export { NgzCustomerSetupFinalizationChecklist };
