import moment from 'moment';
import {
  getFirstLetter,
  sortArrAlphabetically,
} from '../../../../../../shared/utils/sortingAndGroupingUtil';

export const prepTestsToRender = (tests) => {
  const { deactivatedTests, activeTests } = getActiveAndDeactivatedTests(tests);
  const recentlyAddedTests = getRecentlyAddedTests(activeTests);
  const sortedTests = getAlphabeticallySortedTests(activeTests);
  sortedTests.unshift({
    firstLetter: 'Recently Added',
    tests: recentlyAddedTests,
  });

  return { deactivatedTests, sortedTests };
};

const getActiveAndDeactivatedTests = (tests) => {
  const deactivatedTests = [];

  const activeTests = tests.filter((test) => {
    if (test.active) {
      return true;
    } else {
      deactivatedTests.push(test);
      return false;
    }
  });

  return { deactivatedTests, activeTests };
};

const getRecentlyAddedTests = (tests) =>
  tests.sort((a, b) => (moment(a.updatedOn) < moment(b.updatedOn) ? 1 : -1)).slice(0, 6);

const getAlphabeticallySortedTests = (tests) => {
  const groupTests = getAlphabeticallyGroupedTests(tests);
  const transformTests = transformingGroupedObjToArr(groupTests);
  return sortArrAlphabetically(transformTests, 'firstLetter');
};

const getAlphabeticallyGroupedTests = (tests) =>
  tests.reduce((group, test) => {
    const firstLetter = getFirstLetter(test.testName);

    group[firstLetter] = group[firstLetter] ?? [];
    group[firstLetter].push(test);

    return group;
  }, {});

const transformingGroupedObjToArr = (groupTests) =>
  Object.entries(groupTests).map(([value, item]) => ({
    firstLetter: value,
    tests: item,
  }));
