import { makeAutoObservable } from 'mobx';
import { StepperStore } from '../../shared/util-stores';
import { resetAllStore } from '../../shared/stores/resetAllStore';
import { InbodyDataField } from '../../shared/constants/inbody-info/inbodyData';

type CustomerBodyMetrics = Record<
  Extract<InbodyDataField, 'weight' | 'percentBodyFat'>,
  number
>;
class CustomerSetupStore {
  stepperMethods: StepperStore;
  customerBodyMetrics: CustomerBodyMetrics | null = null;
  mealsPerDay: number | null = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    this.stepperMethods = new StepperStore();
  }

  setDefaultValues() {
    this.stepperMethods.setDefaultValues();
    this.customerBodyMetrics = null;
    this.mealsPerDay = null;
  }

  setCustomerBodyMetrics(metrics: CustomerBodyMetrics) {
    this.customerBodyMetrics = metrics;
  }

  setMealsPerDay(mealsPerDay: number | string) {
    this.mealsPerDay = +mealsPerDay;
  }
}

const customerSetupStore = new CustomerSetupStore();
resetAllStore.addResetMethod(customerSetupStore.setDefaultValues);

export { customerSetupStore, CustomerSetupStore };
