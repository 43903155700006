import { Path, UseControllerProps } from 'react-hook-form';

import { InbodyDataField } from '../../../shared/constants/inbody-info/inbodyData';
import { ManualScanData } from '../../interfaces/customerInbodyData';
import { Option } from '../../../general-types';
import { bodyFatCalcMethodOptions } from '../../constants/bodyFatCalcMethod';
import {
  calculateBodyFatMass,
  calculateEcwTbw,
  calculateLbm,
  calculateTbw,
} from '../../utils/metricUtils';
import { KeysOfType } from '../../../utils-types';

export type AddScanManualFormValues = {
  scanData: Omit<ManualScanData, 'datetimes' | 'scanType'> & {
    scanDate: string;
    scanTime: string;
  };
};

type ScanFieldConfig = {
  name: Path<AddScanManualFormValues>;
  label: string;
  configField: InbodyDataField | null; // null if always show
  rules?: UseControllerProps<AddScanManualFormValues>['rules'];
};

type NumberInputConfig = {
  type: 'NUMBER_INPUT';
  measureUnit: string | null; // null get measure unit from config
} & ScanFieldConfig;

type SelectInputConfig = {
  type: 'SELECT_INPUT';
  options: Option[];
} & ScanFieldConfig;

type CalculatedMetricInputConfig = {
  type: 'CALCULATED_METRIC_INPUT';
  measureUnit: string;
  deps: KeysOfType<AddScanManualFormValues, number | undefined>[];
  calcFunction: (...depends: (number | undefined)[]) => number | null;
} & ScanFieldConfig;

// improve this create a function to get the config use the inbodyDataConfig like template
export const scanInputConfigs: (NumberInputConfig | SelectInputConfig)[] = [
  {
    type: 'NUMBER_INPUT',
    name: 'scanData.height',
    label: 'Height',
    measureUnit: 'inches',
    configField: 'height',
    rules: {
      required: 'Height is required',
      min: { value: 1, message: 'Height must be greater than 1' },
      max: { value: 140, message: 'Height must be less than 140' },
    },
  },
  {
    type: 'NUMBER_INPUT',
    name: 'scanData.weight',
    label: 'Weight',
    measureUnit: 'lbs',
    configField: 'weight',
    rules: {
      required: 'Weight is required',
      min: { value: 1, message: 'Weight must be greater than 1' },
      max: { value: 600, message: 'Weight must be less than 600' },
    },
  },
  {
    type: 'NUMBER_INPUT',
    name: 'scanData.percentBodyFat',
    label: 'Body Fat',
    measureUnit: '%',
    configField: 'percentBodyFat',
    rules: {
      required: 'Body fat is required',
      min: { value: 0, message: 'Body fat must be greater than 0' },
      max: { value: 100, message: 'Body fat must be less than 100' },
    },
  },
  {
    type: 'SELECT_INPUT',
    name: 'scanData.bodyFatSource',
    label: 'Body fat method',
    configField: 'bodyFatMethod',
    options: bodyFatCalcMethodOptions,
    rules: { required: 'Body fat method is required' },
  },
  {
    type: 'NUMBER_INPUT',
    name: 'scanData.navelMeasurement',
    label: 'Navel circumference',
    measureUnit: null,
    configField: 'navel',
    rules: {
      min: { value: 1, message: 'Navel must be greater than 1' },
    },
  },
  {
    type: 'NUMBER_INPUT',
    name: 'scanData.thighMeasurement',
    label: 'Thigh circumference',
    measureUnit: null,
    configField: 'thigh',
    rules: {
      min: { value: 1, message: 'Thigh must be greater than 1' },
    },
  },
  {
    type: 'NUMBER_INPUT',
    name: 'scanData.bicepMeasurement',
    label: 'Bicep circumference',
    measureUnit: null,
    configField: 'bicep',
    rules: {
      min: { value: 1, message: 'Bicep must be greater than 1' },
    },
  },
  {
    type: 'NUMBER_INPUT',
    name: 'scanData.chestMeasurement',
    label: 'Chest circumference',
    measureUnit: null,
    configField: 'chest',
    rules: {
      min: { value: 1, message: 'Chest must be greater than 1' },
    },
  },
  {
    type: 'NUMBER_INPUT',
    name: 'scanData.hipMeasurement',
    label: 'Hip circumference',
    measureUnit: null,
    configField: 'hip',
    rules: {
      min: { value: 1, message: 'Hip must be greater than 1' },
    },
  },
  {
    type: 'NUMBER_INPUT',
    name: 'scanData.inWatter',
    label: 'Intracellular water',
    measureUnit: 'lbs',
    configField: 'icw',
    rules: {
      required: 'Intracellular water is required',
      min: { value: 0, message: 'Intracellular water must be greater than 0' },
    },
  },
  {
    type: 'NUMBER_INPUT',
    name: 'scanData.exWatter',
    label: 'Extracellular water',
    measureUnit: 'lbs',
    configField: 'ecw',
    rules: {
      required: 'Extracellular water is required',
      min: { value: 0, message: 'Extracellular water must be greater than 0' },
    },
  },
  {
    type: 'NUMBER_INPUT',
    name: 'scanData.dryLeanMass',
    label: 'Dry Lean Mass',
    measureUnit: 'lbs',
    configField: 'dlm',
    rules: {
      required: 'Dry lean mass is required',
      min: { value: 0, message: 'Dry lean mass must be greater than 0' },
    },
  },
  {
    type: 'NUMBER_INPUT',
    name: 'scanData.skeletalMuscleMass',
    label: 'Skeletal Muscle Mass',
    measureUnit: 'lbs',
    configField: 'smm',
    rules: {
      required: 'Skeletal muscle mass is required',
      min: { value: 0, message: 'Skeletal muscle mass must be greater than 0' },
    },
  },
  {
    type: 'NUMBER_INPUT',
    name: 'scanData.bodyMassIndex',
    label: 'Body Mass Index',
    measureUnit: 'kg/m²',
    configField: 'bmi',
    rules: {
      required: 'Body Mass Index is required',
      min: { value: 0, message: 'Body Mass Index must be greater than 0' },
    },
  },
  {
    type: 'NUMBER_INPUT',
    name: 'scanData.basalMetabolicRate',
    label: 'Basal Metabolic Rate',
    measureUnit: 'kcal',
    configField: 'bmr',
    rules: {
      required: 'Basal Metabolic Rate is required',
      min: { value: 0, message: 'Basal Metabolic Rate must be greater than 0' },
    },
  },
];

export const calculatedMetricConfigs: CalculatedMetricInputConfig[] = [
  {
    type: 'CALCULATED_METRIC_INPUT',
    name: 'scanData.bodyFatMass',
    label: 'Body fat mass',
    measureUnit: 'lbs',
    configField: 'poundOfFat',
    deps: ['scanData.weight', 'scanData.percentBodyFat'],
    calcFunction: calculateBodyFatMass,
  },
  {
    type: 'CALCULATED_METRIC_INPUT',
    name: 'scanData.leanBodyMass',
    label: 'Lean body mass',
    measureUnit: 'lbs',
    configField: 'lbm',

    deps: ['scanData.weight', 'scanData.bodyFatMass'],
    calcFunction: calculateLbm,
  },
  {
    type: 'CALCULATED_METRIC_INPUT',
    name: 'scanData.totalBodyWatter',
    label: 'TBW',
    measureUnit: 'lbs',
    configField: 'tbw',
    deps: ['scanData.inWatter', 'scanData.exWatter'],
    calcFunction: calculateTbw,
  },
  {
    type: 'CALCULATED_METRIC_INPUT',
    name: 'scanData.ecwtbw',
    label: 'ECW/TBW',
    measureUnit: 'ratio',
    configField: 'ecwTbw',
    deps: ['scanData.exWatter', 'scanData.totalBodyWatter'],
    calcFunction: calculateEcwTbw,
  },
];
