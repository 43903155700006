import { cloneDeep } from 'lodash';

import { CustomFilterData } from '../../../../interfaces/CustomFilterData';
import { getFilteredTruthyFieldsObj } from '../../../../../../utils/sortingAndGroupingUtil';
import { CustomFilterFormValues } from '../AddCustomFilterForm.settings';

export const transformCustomFiltersDataToFormValues = (
  customFiltersData?: CustomFilterData,
): CustomFilterFormValues | undefined => {
  if (!customFiltersData) {
    return undefined;
  }

  const { filters, ...args } = cloneDeep(customFiltersData);

  const transformFilters: CustomFilterFormValues['filters'] = {};

  filters.forEach((filter) => {
    if (filter.filterType) {
      transformFilters[filter.filterType] = getFilteredTruthyFieldsObj(filter) as any;
    }
  });

  return { ...args, filters: transformFilters };
};
