import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useFormContext } from 'react-hook-form';
import classNames from 'classnames/bind';
import { has } from 'lodash';

import classes from './Result.module.scss';
import { ResultFilesRow } from '../ResultFilesRow/ResultFilesRow';
import { ResultFormRow } from '../ResultFormRow/ResultFormRow';
import { customerTestsService } from '../../../../services/customerTestsService';
import { scrollTo } from '../../../../../shared/utils/htmlElementsUtils';

const cx = classNames.bind(classes);

const Result = ({
  result,
  formArrName,
  update,
  remove,
  resultIndex,
  showDeleteBtn,
  focusResultId,
}) => {
  const resultRef = useRef();
  const {
    getValues,
    formState: { touchedFields },
  } = useFormContext();
  const isFieldsNotTouched = !has(touchedFields, formArrName);
  const isFocusResult = focusResultId === result.id;
  const isHighlightResult = isFocusResult && isFieldsNotTouched && result.id;

  const resultClasses = cx('testResult', { highlight: isHighlightResult });

  useEffect(() => {
    if (isFocusResult) {
      scrollTo(resultRef.current);
    }
  }, []);

  const fieldName = `${formArrName}.${resultIndex}.`;

  const updateField = (obj) => {
    const fieldObj = getValues(fieldName);
    update(resultIndex, { ...fieldObj, ...obj });
  };

  const handleDeleteResult = async () => {
    if (result.id) {
      await customerTestsService.deleteTestResult(result.id);
    }

    remove(resultIndex);
  };

  return (
    <div className={resultClasses} ref={resultRef}>
      <ResultFormRow
        nameIndex={fieldName}
        onDeleteResult={handleDeleteResult}
        showDeleteBtn={showDeleteBtn}
      />
      <ResultFilesRow nameIndex={fieldName} updateField={updateField} />
    </div>
  );
};

Result.propTypes = {
  formArrName: PropTypes.string,
  result: PropTypes.object,
  update: PropTypes.func,
  remove: PropTypes.func,
  resultIndex: PropTypes.number,
  showDeleteBtn: PropTypes.bool,
  focusResultId: PropTypes.number,
};

export { Result };
