import React, { useState } from 'react';

import { Control } from 'react-hook-form';
import { TextFieldProps } from '@mui/material';

import { VisibilityOffRounded } from '@mui/icons-material';
import classes from './SubscriptionsBox.module.scss';
import { SubscriptionsRadioButtons } from './SubscriptionsRadioButtons/SubscriptionsRadioButtons';
import {
  applyDiscountOptions,
  PROMO_CODE_APPLY_TYPE,
} from '../../../../../constants/promoCodes';
import { LbMultipleAutocomplete } from '../../../../../../shared/components/form-components/autocomplete-components/LbMultipleAutocomplete/LbMultipleAutocomplete';
import { LbSelect } from '../../../../../../shared/components/form-components';
import { ModalInputBox } from '../../../../../../shared/components/modals';
import {
  AddPromoCodeRequest,
  PromoCodeSubscription,
} from '../../../../../interfaces/promoCodeInfo';
import {
  SubscriptionsRadioButtonsValue,
  SubscriptionsRadioButtonsValues,
} from './SubscriptionsBox.settings';

type SubscriptionsBoxProps = {
  subscriptions: PromoCodeSubscription[];
  control: Control<AddPromoCodeRequest>;
  variant: TextFieldProps['variant'];
  size: TextFieldProps['size'];
};

const SubscriptionsBox = ({
  subscriptions,
  control,
  variant,
  size,
}: SubscriptionsBoxProps) => {
  const [subscriptionsDisplayType, setSubscriptionsDisplayType] =
    useState<SubscriptionsRadioButtonsValue>(SubscriptionsRadioButtonsValues.ALL);

  const renderSubscriptionOptionLabel = (option: PromoCodeSubscription) => (
    <div className={classes.subscriptionOptionContainer}>
      <span>{option.name}</span>
      {!option.displayToUser && (
        <div className={classes.notDisplayed}>
          <VisibilityOffRounded fontSize="small" />
          Not displayed
        </div>
      )}
    </div>
  );

  return (
    <ModalInputBox
      title="Subscriptions:"
      description="Choose the subscriptions to which you want to apply the promo code."
    >
      <SubscriptionsRadioButtons
        value={subscriptionsDisplayType}
        onChange={setSubscriptionsDisplayType}
      />
      {subscriptionsDisplayType === SubscriptionsRadioButtonsValues.SPECIFIC && (
        <LbMultipleAutocomplete
          shouldUnregister
          control={control}
          variant={variant}
          size={size}
          label="Subscriptions"
          name="subscriptionSetupList"
          renderOptionLabel={renderSubscriptionOptionLabel}
          options={subscriptions}
          labelKey="name"
          valueKey="id"
        />
      )}
      <LbSelect
        control={control}
        variant={variant}
        size={size}
        options={applyDiscountOptions}
        label="Apply to"
        rules={{ required: 'Apply type is required' }}
        defaultValue={PROMO_CODE_APPLY_TYPE.INITIAL_PLAN_AND_MONTHLY_RENEWAL}
        name="applyType"
      />
    </ModalInputBox>
  );
};

export { SubscriptionsBox };
