'use strict';

angular.module('app').factory('FoodService', FoodService);

FoodService.$inject = ['ApiService', 'utilService'];

function FoodService(ApiService, utilService) {
    return {
        getAllFoods: function (type) {
            return ApiService.get('api/food/' + type).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getAllFoods114: function (type, prms) {
            return ApiService.get('api/1.1.4/food/' + type, prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getAllFoods114Search: function (type, prms) {
            return ApiService.get('api/1.1.4/food/' + type + '/search', prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getOneFood: function (id) {
            return ApiService.get('api/web/food/' + id).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        removeOneFood: function (id) {
            return ApiService.delete('api/food/' + id).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getAllMicroNutrients: function () {
            return ApiService.get('api/web/food/micro-nutrient/get-all').then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getAllMeasurements: function () {
            return ApiService.get('api/measurements').then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getAllTags: function () {
            return ApiService.get('api/web/food/tags/get-all').then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getTableForScores: function (scoreType) {
            return ApiService.get('api/super/score-table/' + scoreType).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        updateTableForScores: function (scoreType, data) {
            return ApiService.put('api/super/score-table/' + scoreType, data).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        updateTags: function (id, prms) {
            return ApiService.post('api/web/food/update-tags/' + id, null, prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        updateMicroNutrient: function (id, data) {
            return ApiService.post('api/web/food/update-micro-nutrients/' + id, data).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        updateRecommended: function (id, prms) {
            return ApiService.put('api/web/food/recommended/' + id, null, prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        addFood: function (data, prms) {
            var url = utilService.getLoggedInUserData().role === 'CUSTOMER' ? 'api/food/manual' : 'api/web/food/manual';
            return ApiService.post(url, data, prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        addFoodWithMyMetDiary: function (id, data, prms) {
            return ApiService.post('api/food/mynetdiary/' + id, data, prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        updateFood: function (id, data, prms) {
            return ApiService.put('api/1.2.0/food/' + id, data, prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        searchFoods: function (prms) {
            return ApiService.get('api/food/search', prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getRecentFood: function (prms) {
            return ApiService.get('api/recent-food', prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        updateFavoriteCategory: function (prms) {
            return ApiService.put('api/food/favoritecategory', prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getFavoriteFoodByMeal: function () {
            return ApiService.get('api/food/favoritecategory').then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        formRecentFoodList: function (result) {
            var tempList = [];
            result.map(function (item) {
                var index = utilService.findWithAttr(tempList, 'categoryName', item.ateOn);
                if (index > -1) {
                    tempList[index].foods.push(item)
                } else {
                    tempList.push({
                        categoryName: item.ateOn,
                        foods: [item]
                    })
                }
            });
            return tempList;
        },
        isFoodExist: function (list, element) {
            var result = list.filter(function (item) {
                if (element.id === 0 && item.id === 0) {
                    return item.my_net_diary_bean_id === element.my_net_diary_bean_id;
                } else if (element.id !== 0 && item.id !== 0) {
                    return item.id === element.id;
                }
            });

            return result && result.length > 0;
        },
        getBrands: function () {
            return ApiService.get('api/food/brands').then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        }
    };
}

