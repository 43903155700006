import React, { SyntheticEvent } from 'react';

import { angularize } from 'react-in-angularjs';
import { observer } from 'mobx-react';
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';

import { SystemProviders } from '../../../shared/providers';
import { communicationModalStore } from '../../stores/communicationModalStore';
import { SendMessageWayTypes, SEND_MESSAGE_WAY } from '../../constants/SEND_MESSAGE_WAY';
import { CommunicationForm } from './CommunicationForm/CommunicationForm';
import { SendingMessageModal } from './SendingMessageModal/SendingMessageModal';
import { SuccessSentMessageModal } from './SuccessSentMessageModal/SuccessSentMessageModal';
import { ErrorSentMessageModal } from './ErrorSentMessageModal/ErrorSentMessageModal';
import { LbBigModal } from '../../../shared/components/modals';
import {
  LbTabPanel,
  LbTabs,
  LbTabsList,
  TabsConfig,
} from '../../../shared/components/tabs';

const NgzCommunicationModal = observer(() => {
  const { isModalOpen, via, customersCount, setVia } = communicationModalStore;

  const handleTabChange = (
    event: SyntheticEvent | null,
    newActiveTab: string | number | null,
  ) => {
    setVia(newActiveTab as SendMessageWayTypes);
  };

  const tabsConfig: TabsConfig[] = [
    {
      label: 'Push Notification',
      value: SEND_MESSAGE_WAY.PUSH,
      icon: <NotificationsNoneRoundedIcon fontSize="medium" />,
      panel: <CommunicationForm withSubject />,
    },
    {
      label: 'Chat Message',
      value: SEND_MESSAGE_WAY.CHAT,
      icon: <ChatBubbleOutlineRoundedIcon fontSize="medium" />,
      panel: <CommunicationForm uploadFilesEnabled />,
    },
    {
      label: 'Email Letter',
      value: SEND_MESSAGE_WAY.EMAIL,
      icon: <MailOutlineRoundedIcon fontSize="medium" />,
      panel: <CommunicationForm withSubject />,
    },
  ];

  return (
    <SystemProviders>
      <LbBigModal
        open={isModalOpen}
        title={`Send Message to ${customersCount} Customers`}
        renderButtonRow={null}
      >
        <LbTabs value={via} onChange={handleTabChange}>
          <LbTabsList tabsConfig={tabsConfig} variant="toggle" fullWidth />
          <LbTabPanel tabsConfig={tabsConfig} />
        </LbTabs>
      </LbBigModal>
      <SendingMessageModal />
      <SuccessSentMessageModal />
      <ErrorSentMessageModal />
    </SystemProviders>
  );
});

angularize(NgzCommunicationModal, 'ngzCommunicationModal', angular.module('app'), {});

export { NgzCommunicationModal };
