import { watchFlagAngular } from "../modules/shared/clients/unleash";
import { pathQueryParams } from "../modules/shared/constants/pathQueryParams";
import { ENTITY_PROFILE_TAB_NAME } from "../modules/shared/constants/tabNames/ENTITY_PROFILE_TAB_NAME";
import { getEnabledModules } from "../modules/shared/utils/paidModulesUtils";
import { getFlagsObject } from "../modules/shared/utils/sortingAndGroupingUtil";

angular.module('app').factory('utilService', utilService);

utilService.$inject = ['$mdDialog', 'constants', '$location', 'ApiService', 'TimeZoneService', 'EntityService',
    '$rootScope', 'localStorageService', 'ModalService', '$timeout'];

function utilService($mdDialog, constants, $location, ApiService, TimeZoneService, EntityService, $rootScope,
                     localStorageService, ModalService, $timeout) {
    const { SUPERUSER, ADMIN, AGENT, CUSTOMER, FOOD_ADMIN } = constants.enums.userRoles;
    const { LOGGED_IN_USER, ENABLED_MODULES, PAYMENT_FLOW_TYPE } = constants.enums.storageKeys;
    const corporateSignInStatuses = {
        NOT_CORPORATE_USER: 'NOT_CORPORATE_USER',
        FINISHED_SING_IN: 'FINISHED_SING_IN',
        UNFINISHED_SING_IN: 'UNFINISHED_SING_IN'
    };

    return {
        getComplianceCoachIndicator: function () {
            return JSON.parse(localStorage.getItem('complianceCoach'));
        },
        getComplianceCoachForCustomerIndicator: function () {
            return JSON.parse(localStorage.getItem('complianceCoachForCustomer'));
        },
        getLoggedInUserData: function () {
            return JSON.parse(localStorage.getItem(LOGGED_IN_USER));
        },
        setLoggedInUserData(loggedInUser) {
            localStorage.setItem(LOGGED_IN_USER, JSON.stringify(loggedInUser));
            $rootScope.$broadcast('updateUser');
        },
        updateLoggedInUserData(newLoggedInUserData) {
            const loggedInUserData = this.getLoggedInUserData();
            
            this.setLoggedInUserData({ ...loggedInUserData, ...newLoggedInUserData });
        },
        getIsSuperAdmin: function () {
          return this.getLoggedInUserData().role === SUPERUSER;
        },
        getIsAdminRole: function () {
            return this.getLoggedInUserData().role === ADMIN;
        },
        getIsCoachRole() {
            return this.getLoggedInUserData().role === AGENT;
        },
        getLoggedInUserEntityType() {
            const entityTypes = constants.enums.entityTypes;
            const loggedInUserEntityType = {};
            const loggedInUser = this.getLoggedInUserData();
            
            Object.entries(entityTypes).forEach(([entityTypeKey, entityTypeValue]) => { 
                const isEntityType = entityTypeValue === loggedInUser?.entityType;

                loggedInUserEntityType[entityTypeKey] = isEntityType;
            });

            return loggedInUserEntityType;
        },
        getIsCorporateSignInFinished() {
            const corporateSignInStatus = this.getCorporateSignInStatus();
            
            return corporateSignInStatus === corporateSignInStatuses.FINISHED_SING_IN;
        },
        getIsCorporateSignInUnfinished() {
            const corporateSignInStatus = this.getCorporateSignInStatus();

            return corporateSignInStatus === corporateSignInStatuses.UNFINISHED_SING_IN;
        },
        getCorporateSignInStatus() {
            const user = this.getLoggedInUserData();
            const isCorporateTokenNotExist = !user?.corporateAccountInfo?.token;
         
            if (isCorporateTokenNotExist) {
                return corporateSignInStatuses.NOT_CORPORATE_USER;
            } else if (!user.id) {
                return corporateSignInStatuses.UNFINISHED_SING_IN;
            } else {
                return corporateSignInStatuses.FINISHED_SING_IN;
            }
        },
        nextScreen: function () {
            const user = this.getLoggedInUserData();
            const isCorporateSignInUnfinished = this.getIsCorporateSignInUnfinished();

            if (user?.role) {
                this.processNextScreenByUserRole(user);
            } else if(isCorporateSignInUnfinished){
                this.goTo('/pin-code');
            } else {
                this.goTo('/');
            }
        },
        processNextScreenByUserRole(user) {
            switch (user?.role) {
                case CUSTOMER:
                    TimeZoneService.setTimeZone();
                    this.goTo('/user-profile');
                    break;
                case SUPERUSER:
                    this.goTo('/superuser-dashboard');
                    break;
                case AGENT:
                    this.goTo('/customers-dashboard');
                    break;
                case ADMIN:
                    this.caseAdminNextScreenHandler(user);
                    break;
                case FOOD_ADMIN:
                    this.goTo('/food-score');
                    break;
            }
        },
        async caseAdminNextScreenHandler(user) {
            const processNextScreenFunctions = [
              this.processNextScreenForNonChildrenEntityAdmin,
              this.processNextScreenForLightspeed,
              this.processNextScreenByEnabledModule,
            ];

            for (const processNextScreen of processNextScreenFunctions) {
                const isSuccess = await processNextScreen.call(this, user);
                this.applyAsyncFunc();
  
                if (isSuccess) {
                  return;
                }
            }
            
            this.goTo('/entity-dashboard');
        },
        async processNextScreenForNonChildrenEntityAdmin(user) {
            $rootScope.$broadcast('isLoading', true);
            const {status, data} = await EntityService.getIsEntityHasChildren(user.gymId);
            $rootScope.$broadcast('isLoading', false);
            const entityHasChildren = status === 200 && data;

            if (entityHasChildren) {
              this.goTo('/child-entity-list');
              return true;
            } 

            return false;
        },
        processNextScreenForLightspeed() {
            const isLinkFormLightspeed = $location.absUrl().indexOf('code=') > -1;
        
            if (isLinkFormLightspeed) {
                localStorage.setItem('lightspeedToken', $location.absUrl().match(/code=([^"]+)#/)[1]);
                this.goTo('/entity-profile', { replaceHistory: true, params: { [pathQueryParams.initialTabName]: ENTITY_PROFILE_TAB_NAME.POS } });
                return true;
            }
        
            return false;
        },
        async processNextScreenByEnabledModule(user) {
            let enabledModules = localStorageService.get(ENABLED_MODULES);
            
            if (!enabledModules) {
              enabledModules = await getEnabledModules(user.gymId);
            }
        
            if (enabledModules.ACTIVE_CALENDAR) {
              this.goTo('/active-calendar/users');
              return true;
            }
        
            return false;
        },
        formatDate: function (date, template) {
            return typeof date === 'string' ? moment(date, 'M/D/YYYY', true).format(template) : moment(date).format(template);
        },
        findWithAttr: function (array, attr, value) {
            for (var i = 0; i < array.length; i++) {
                if (array[i][attr] === value) {
                    return i;
                }
            }
            return -1;
        },
        formatExpireDate: function (date) {
            var replacedDate = date.replace(/\./g, '/');
            var res = moment(replacedDate, 'MM/DD/YYYY').format('MMMM DD');
            var day = parseInt(moment(replacedDate, 'MM/DD/YYYY').format('DD'));

            if (day >= 11 && day <= 13) res += "th";
            else if (day % 10 === 1) res += "st";
            else if (day % 10 === 2) res += "nd";
            else if (day % 10 === 3) res += "rd";
            else res += "th";

            return res;
        },
        formatDateFromResponse: function (strDate) {
            return strDate.substr(4, 2) + '.' + strDate.substr(6, 2) + '.' + strDate.substr(0, 4);
        },
        sum: function (arr, property) {
            var total = 0;
            for (var i in arr) {
                total += arr[i][property];
            }
            return total;
        },
        getCurrentPage:  function (pageName, filterName) {
            var urlParams = angular.copy($location.search());
            var filters = JSON.parse(localStorage.getItem(filterName));
            $location.search('page', null);
            localStorage.removeItem(pageName);
            localStorage.removeItem(filterName);

            return urlParams.page ? [urlParams.page, filters] : [null, null];
        },
        goBackWithParam: function (pageUrl, param) {
            var page = localStorage.getItem(param);
            this.goTo(pageUrl, { params: { page: page ? page : null } });
        },
        goTo: function (path, { keepQueryParams = false, replaceHistory = false, params = {} } = {}) {
            if (path.includes('?')) {
               console.error("Do not include query parameters in the path. Use the 'params' option instead.", path);
            }
            
            $location.path(path);

            if (keepQueryParams) {
                const currentQueryParams = $location.search();
                $location.search({ ...currentQueryParams, ...params });
            } else {
                $location.search(params);
            }

            if (replaceHistory) {
                $location.replace();
            }

            return $location;
        },
        getPaymentFlowType() {
           return localStorageService.get(PAYMENT_FLOW_TYPE);
        },
        getIsChildEntity: function () {
            // TODO: LMP-1782 fix after confirm, uncommit tests
            return false;
            // const { SCHOOL } = this.getLoggedInUserEntityType();
            // const isAdminRole = this.getIsAdminRole();
            // const paymentFlowType = this.getPaymentFlowType();
            // const isNotParent = !loggedInUser.isLoggedByParent;;
            
            // return SCHOOL && isAdminRole && !paymentFlowType.OLD && isNotParent;
        },
        getNumbersFromString(string) {
            return string.replace(/\D/g, '');
        },
        findObjKeyByValue: (obj, value) => Object.keys(obj).find(key => obj[key] === value),
        getAsyncResult: async ({vm, func, args = []}) => {
            setSpinner(vm, true);
            try {
                const resp = await func(...args);
                if (resp.status === 200) {
                    return resp.data;
                } else {
                    ModalService.alert(resp.data.message, 'Error');
                }
            } finally {
                setSpinner(vm, false);
                $timeout(() => {
                    $rootScope.$apply();
                });
            }
        },
        applyAsyncFunc($scope) {
            const scope = $scope ?? $rootScope;

            $timeout(() => {
                scope.$apply();
            });
        },
        getIsNullish(data) {
            return data === undefined || data === null;
        },
        watchFlagAngular: watchFlagAngular,
        getFlagsObject: getFlagsObject,
    };

    function setSpinner(vm, isActive) {
        if (typeof vm.spinner === 'boolean') {
            vm.spinner = isActive;
        } else {
            vm.spinner.active = isActive;
        }
    }
}
