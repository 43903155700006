import React, { useEffect } from 'react';

import { angularize } from 'react-in-angularjs';
import { Container, Pagination } from '@mui/material';
import { observer } from 'mobx-react';
import { FormProvider, useForm } from 'react-hook-form';

import classes from './NgzEntityList.module.scss';
import { SystemProviders } from '../../../shared/providers';
import { TitleRow } from './TitleRow/TitleRow';
import { FilterRow } from './FilterRow/FilterRow';
import { entityListStore } from '../../stores/entityListStore';
import { EntityRow } from './EntityRow/EntityRow';
import { EntityListPageTitle } from './EntityListPageTitle/EntityListPageTitle';
import { EntityListFormValues } from '../../interfaces/entity-list/EntityListFilters';
import { useAutoSubmitForm } from '../../../shared/hooks/useAutoSubmitForm';
import { EmptyList } from '../../../shared/components/ui-components';

/*
INFORMATION ABOUT THE COMPONENT:
The component displays a list of entities; 
Filtering occurs automatically when data is entered into the filter; 
Tabs are part of filters (filtering by payment type); 
Selected filters must be saved when switching between tabs; 
Clearing filters (the clear button) clears all filters except for the selected tab; do not save used filters after unmounting.
*/

const NgzEntityList = observer(() => {
  const { entityList, filterMethods, getEntityList, setDefaultValues } = entityListStore;
  const {
    isShowFilterRow,
    isShowPlaceholder,
    isSearchUsed,
    isShowPagination,
    defaultFilterData,
    getPaginationProps,
    applyFilters,
  } = filterMethods;

  useEffect(() => {
    getEntityList();
    return () => setDefaultValues();
  }, []);

  const formMethods = useForm<EntityListFormValues>({ defaultValues: defaultFilterData });
  const { handleSubmit, watch } = formMethods;

  useAutoSubmitForm(handleSubmit(applyFilters), watch, [
    'businessName',
    'ownerName',
    'businessPhone',
  ]);

  const paginationProps = getPaginationProps();

  return (
    <SystemProviders>
      <Container>
        <FormProvider {...formMethods}>
          <EntityListPageTitle />
          <div className={classes.entityTable}>
            <TitleRow />
            {isShowFilterRow && <FilterRow />}
            {entityList.map((entityInfo) => (
              <EntityRow key={entityInfo.entityId} entityInfo={entityInfo} />
            ))}
          </div>
        </FormProvider>
        <EmptyList
          show={isShowPlaceholder}
          isSearch={isSearchUsed}
          description="There are no entities"
        />
        {isShowPagination && <Pagination {...paginationProps} />}
      </Container>
    </SystemProviders>
  );
});

angularize(NgzEntityList, 'ngzEntityList', angular.module('app.entityManagement'), {});

export { NgzEntityList };
