import { getRoundedNumber } from '../getRoundedNumber/getRoundedNumber';

export const getPercentages = (
  partialValue: number,
  totalValue: number,
  roundDigits: number = 1,
): number => {
  return totalValue === 0 && partialValue === 0
    ? 0
    : getRoundedNumber((partialValue / totalValue) * 100, roundDigits);
};
