import React, { HTMLAttributes, MouseEvent } from 'react';

import { AutocompleteRenderOptionState, IconButton, SvgIcon } from '@mui/material';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';

import classes from './FilterOption.module.scss';
import { customFilterStore } from '../../../stores/customFilterStore';
import { ReactComponent as PenIcon } from '../../../../../assets/icons/pen.svg';
import { openConfirmationModal } from '../../../../confirmation';

type FilterOptionProps = {
  filterId: number;
  label: string;
  state: AutocompleteRenderOptionState;
} & HTMLAttributes<HTMLLIElement>;

const FilterOption = ({ filterId, label, state, ...props }: FilterOptionProps) => {
  const { openEditFilterModal, deleteCustomFilter } = customFilterStore;

  const handleEditFilter = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    openEditFilterModal(filterId);
  };

  const handleDeleteFilter = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    openConfirmationModal({
      title: `Do you want to delete custom filter "${label}"`,
      confirmBtnConfig: {
        text: 'Confirm',
        onClick: () => deleteCustomFilter(filterId),
      },
    });
  };

  return (
    <li {...props}>
      <span>{label}</span>
      <div className={classes.iconBox}>
        <IconButton
          color="secondary"
          onClick={handleEditFilter}
          disabled={state.selected}
        >
          <SvgIcon component={PenIcon} inheritViewBox fontSize="small" />
        </IconButton>
        <IconButton color="error" onClick={handleDeleteFilter} disabled={state.selected}>
          <DeleteOutlineRoundedIcon fontSize="small" />
        </IconButton>
      </div>
    </li>
  );
};

export { FilterOption };
