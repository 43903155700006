'use strict';

import { updateUserInfo } from "../modules/shared/utils/userInfoUtils";

angular.module('app').factory('TermsAndConditionService', TermsAndConditionService);

TermsAndConditionService.$inject = ['$q', 'ApiService', 'utilService', 'ModalService', '$rootScope', 'constants'];

function TermsAndConditionService($q, ApiService, utilService, ModalService, $rootScope, constants) {
    return {
        readTermsAndConditionVersion: function () {
            return ApiService.put('api/coach/terms-and-conditions', null, {version: constants.termsAndConditionVersion}).then((res) => {
                this.updateUserInfo({ readTermsAndConditionVersion: constants.termsAndConditionVersion });

                utilService.nextScreen();
                return res;
            }, function (err) {
                return err
            });
        },
        checkIfTermsAndConditionAreRead: function () {
            return ApiService.get('api/coach/terms-and-conditions', null, {version: constants.termsAndConditionVersion}).then(function (res) {
                return res;
            }, function (err) {
                return err
            });
        },
        readTermsAndConditionVersionBySupplementUser: function (accessData) {
            return ApiService.put('api/public/supplement-user/terms-and-conditions/' + encodeURI(accessData), null, {version: constants.termsAndConditionVersion}).then(function (res) {
                ModalService.alert('You successfully accepted Terms & Conditions agreement', 'Terms & Conditions');
                return res;
            }, function (err) {
                return err
            });
        },
        checkIfTermsAndConditionAreReadBySupplementUser: function (id) {
            var type = utilService.getLoggedInUserData().role === 'AGENT' ? 'coach' : 'admin';

            return ApiService.get('api/' + type + '/supplement-user/terms-and-conditions/' + id, {version: constants.termsAndConditionVersion}).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        resendTermsAndConditionAreReadToSupplementUser: function (id) {
            var type = utilService.getLoggedInUserData().role === 'AGENT' ? 'coach' : 'admin';

            return ApiService.post('api/' + type + '/supplement-user/send/terms-and-conditions/' + id).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        updateUserInfo: function (user) {
            updateUserInfo(user)
        },
    };
}

