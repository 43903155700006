import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react';

import { apiStatusStore } from '../../../stores/apiStatusStore';
import { Option } from '../../../../general-types';
import { transformToOptions } from '../../../utils/arrayUtils';
import { LbModal } from '../LbModal/LbModal';
import { ComboBox } from '../../form-components';
import { coachService } from '../../../services/coach/coachService';

type SelectCoachFormValue = {
  coachId: number;
};

type ReassignCoachForCustomersModalProps = {
  open: boolean;
  customerIds: number[];
  onSubmit: () => void;
  onClose: () => void;
};

const ReassignCoachForCustomersModal = observer(
  ({ open, customerIds, onSubmit, onClose }: ReassignCoachForCustomersModalProps) => {
    const [coachesOptions, setCoachesOptions] = useState<Option[]>([]);
    const { control, handleSubmit } = useForm<SelectCoachFormValue>();
    const { isLoading } = apiStatusStore;

    const getCoachesList = async () => {
      const { names } = await coachService.getCoachesList(false);

      const withOutComplianceCoaches = names.filter((coach) => !coach.isComplianceCoach);

      const options = transformToOptions({
        list: withOutComplianceCoaches,
        labelKey: 'name',
        valueKey: 'id',
      });

      setCoachesOptions(options);
    };

    useEffect(() => {
      if (open) {
        getCoachesList();
      }
    }, [open]);

    const handleReassignCoachToCustomer = async ({ coachId }: SelectCoachFormValue) => {
      await coachService.reassignCoachForCustomers(coachId, customerIds);

      onClose();
      onSubmit();
    };

    return (
      <LbModal
        open={open}
        title="Reassign Customers to Another Coach"
        isLoading={isLoading}
        primaryBtnConfig={{
          text: 'Save',
          onClick: handleSubmit(handleReassignCoachToCustomer),
        }}
        secondaryBtnConfig={{ onClick: onClose }}
      >
        <ComboBox
          control={control}
          label="Coach Name"
          name="coachId"
          options={coachesOptions}
          rules={{ required: 'Need to choose a coach' }}
        />
      </LbModal>
    );
  },
);

export { ReassignCoachForCustomersModal };
