const autoNgTemplateLoaderTemplate1 = require('./user-profile-sub-tab.template.html');

(function () {
  'use strict';

  UserProfileSubTabController.$inject = ['utilService', 'constants', 'CustomersFilterService',
    'ModalService', 'GeneralService', '$routeParams', 'CustomerGroupsService', 'DateTimeUtil',
    '$scope', 'EntityService'];

  function UserProfileSubTabController(utilService, constants, CustomersFilterService, ModalService,
                                       GeneralService, $routeParams, CustomerGroupsService,
                                       DateTimeUtil, $scope, EntityService) {
    const vm = this;
    vm.spinner = false;
    vm.loggedInUser = utilService.getLoggedInUserData();
    vm.groupsList = [];
    vm.states = constants.states;
    vm.customerDOB = '';
    vm.dobIsOpen = false;
    vm.inbodyTypes = constants.inbodyTypes;
    vm.inbodyTypesOptions = Object.values(constants.inbodyTypes);
    vm.userTypes = [];
    vm.enums = constants.enums;
    vm.sports = constants.sports;

    vm.$onInit = () => {
      if (vm.loggedInUser.role === 'AGENT') {
        vm.getGroupsList();
      }

      vm.formUserTypes();
      vm.customerDOB = utilService.formatDate(vm.currentCustomerDetails.customer.dob, 'MM.DD.YYYY');
      vm.setPositions();
    };

    vm.getGroupsList = () => {
      CustomersFilterService.loadGroups().then(function (res) {
        if (res.status === 200) {
          vm.groupsList = res.data.groups;
          vm.userGroup = vm.currentCustomerDetails.customer.customerGroup;
        }
      });
    };
    /* 
      Need refactor and use this for setup customer inbody type when will be possible to change it. 
      Now use all inbody types for User type fields because:
        - inbody type is not editable
        - entity supported Inbody Types can be changed and we need to show correct user types
    */
    vm.formUserTypes = () => {
        vm.userTypes = [];
        var supportedInbodyTypes = JSON.parse(localStorage.getItem('supportedInbodyTypes'));
        var allowNonInbodyUsers = JSON.parse(localStorage.getItem('allowNonInbodyUsers'));
        if (allowNonInbodyUsers)  vm.userTypes.push(vm.inbodyTypes.NON_INBODY);
        for (var type in supportedInbodyTypes) {
            if (supportedInbodyTypes[type]) {
                vm.userTypes.push(constants.inbodyTypes[type.toUpperCase()]);
            }
        }
    }

    vm.setPositions = () => {
      vm.positions = EntityService.getPositionGroups(vm.currentCustomerDetails.customer.teamSport);
    };

    vm.changeUserProfile = () => {
      vm.currentCustomerDetails.customer.dob = moment(vm.currentCustomerDetails.customer.dob)
        .format('MM/DD/YYYY');

      const newCustomer = {
        user: {
          firstName: vm.currentCustomerDetails.firstName,
          lastName: vm.currentCustomerDetails.lastName,
          email: vm.currentCustomerDetails.email,
          billingEmail: vm.currentCustomerDetails.billingEmail
        },
        customer: vm.currentCustomerDetails.customer
      };

      localStorage.setItem('selectedCustomerProfile', JSON.stringify({
        firstName: vm.currentCustomerDetails.firstName,
        lastName: vm.currentCustomerDetails.lastName,
        email: vm.currentCustomerDetails.email,
        billingEmail: vm.currentCustomerDetails.billingEmail,
        avatar: vm.currentCustomerDetails.avatar,
        customer: vm.currentCustomerDetails.customer
      }));

      if (vm.loggedInUser.role !== 'SUPERUSER') {
        vm.noWarnings = true;
        vm.isNewGroup = true;

        if (vm.groupsList && vm.groupsList.length > 0 && vm.userGroup) {
          vm.groupsList.filter(function (group) {
            if (group.name.toLowerCase() === vm.userGroup.name.toLowerCase()) {
              vm.isNewGroup = false;
              if (group.active) {
                vm.userGroup = group;
              } else {
                vm.noWarnings = false;
                ModalService.alert('Group ' + vm.userGroup.name + ' is not active.', 'Warning');
              }
            }
          });
        }

        if (vm.isNewGroup && vm.userGroup) {
          delete vm.userGroup.id;
          delete vm.userGroup.amountOfCustomers;
          delete vm.userGroup.active;

          if (vm.userGroup.name === '' || vm.userGroup.name === 'Default') {
            vm.userGroup = {id: 0, name: 'Default'};
          }
        }

        if (vm.noWarnings) {
          vm.spinner = true;
          GeneralService.updateUserInfo($routeParams.id, newCustomer)
            .then(function(res) {
              if (res.status === 200) {
                if (vm.userGroup && vm.userGroup.id && vm.userGroup.id !== 0) {
                  vm.saveGroup(vm.userGroup.id, $routeParams.id);
                } else if (vm.userGroup && vm.userGroup.name && vm.userGroup.id !== 0) {
                  vm.createNewGroup(vm.userGroup && vm.userGroup.name);
                } else {
                  ModalService.savedChanges();
                }
              }
            })
            .finally(() => vm.spinner = false);
        }
      } else {
        vm.updateUserInfo(newCustomer);
      }
    };

    vm.complete = (string) => {
      vm.userGroup = {name: string};
      const output = [];
      angular.forEach(vm.groupsList, function (group) {
        if (group.name.toLowerCase().indexOf(string.toLowerCase()) > -1 && group.active) {
          output.push(group);
        }
      });
      vm.filterGroups = output;
    };

    vm.saveGroup = (groupId, userid) => {
      CustomerGroupsService.assignCustomersToCustomerGroup(groupId, { ids: [userid] })
        .then(function(res) {
          if (res.status === 200) {
            ModalService.savedChanges();
          }
        });
    };

    vm.createNewGroup = (groupName) => {
      CustomerGroupsService.createCustomerGroup({
        name: groupName,
        active: true
      }).then(function (res) {
        if (res.status === 200) {
          vm.saveGroup(res.data.id, $routeParams.id);
        }
      });
    }

    vm.updateUserInfo = (newCustomer) => {
      GeneralService.updateUserInfo($routeParams.id, newCustomer).then(function (res) {
        if (res.status === 200) {
          ModalService.savedChanges();
        }
      });
    };

    vm.fillTextBox = (group) => {
      vm.userGroup = angular.copy(group);
      vm.filterGroups = null;
    };

    vm.setDOB = (form) => {
      vm.customerDOB = moment(vm.currentCustomerDetails.customer.dob).format('MM.DD.YYYY');
      DateTimeUtil.validateDOB(form, vm.currentCustomerDetails.customer.dob);
      $scope.$evalAsync();
    };

    vm.deactivateActivateUser = () => {
      if (vm.currentCustomerDetails.active) {
        ModalService.confirm('Do you want to deactivate ' + vm.currentCustomerDetails.firstName + ' ' + vm.currentCustomerDetails.lastName + ' as a user ?', vm.disableEnableUser, null, '', 'No', 'Yes')
      } else {
        vm.disableEnableUser();
      }
    };

    vm.disableEnableUser = () => {
      vm.spinner = true;
      GeneralService.disableEnableUser(vm.currentCustomerDetails.id, { active: !vm.currentCustomerDetails.active })
        .then((res) => {
          if (res.status === 200) {
            vm.currentCustomerDetails.active = !vm.currentCustomerDetails.active;
            localStorage.setItem('selectedCustomerProfile', JSON.stringify(vm.currentCustomerDetails));
          }
        })
        .finally(() => vm.spinner = false);
    };

    vm.onSportChange = () => {
      vm.setPositions();
      vm.currentCustomerDetails.customer.teamPosition = vm.positions[0];
    };
  }

  angular
    .module('app')
    .component('userProfileSubTab', {
      templateUrl: autoNgTemplateLoaderTemplate1,
      controller: UserProfileSubTabController,
      controllerAs: 'vm',
      bindings: {
        currentCustomerDetails: '<',
      }
    });
})();
