import { useEffect, useState } from 'react';

import { entityStatusService } from '../../../services/entityStatusService';
import { loggedInUserDataStore } from '../../../../shared/stores/loggedInUserDataStore';
import { EntityStatus } from '../../../constants/entityStatus';
import { UnconfiguredSettingsType } from '../../../constants/unconfiguredSettings';

export const useStatusDetails = () => {
  const [status, setStatus] = useState<EntityStatus | null>(null);
  const [unconfigured, setUnconfigured] = useState<UnconfiguredSettingsType[]>([]);

  const { loggedInUser, isEntity, paymentFlowType, getEntityId } = loggedInUserDataStore;

  const getStatusDetails = async () => {
    const isGetStatusDetailsAllowed = isEntity && paymentFlowType.NEW_USER_FLOW_2024;

    if (!isGetStatusDetailsAllowed) {
      return;
    }

    const entityId = getEntityId();
    const statusDetails = await entityStatusService.getStatusDetails(entityId);

    setStatus(statusDetails.status);
    setUnconfigured(statusDetails.unconfigured || []);
  };

  useEffect(() => {
    getStatusDetails();
  }, [loggedInUser]);

  return { status, unconfigured, getStatusDetails };
};
