import React from 'react';

import { useFormContext } from 'react-hook-form';
import { Button } from '@mui/material';
import { observer } from 'mobx-react';

import {
  GridTableCell,
  GridTableRow,
} from '../../../../shared/components/table-components';
import { LbInput, LbSelect } from '../../../../shared/components/form-components';
import { EntityListFormValues } from '../../../interfaces/entity-list/EntityListFilters';
import { inbodyTypeOptionsWithAll } from '../../../../shared/constants/inbody-info/InbodyType';
import { entityStatusOption } from '../../../constants/entityStatus';
import { validatePhone } from '../../../../shared/utils/reactHookFormUtils';
import { entityListStore } from '../../../stores/entityListStore';
import { entityTypeOptionsWithAll } from '../../../../shared/constants/user-info/ENTITY_TYPES';

const FilterRow = observer(() => {
  const { control, reset } = useFormContext<EntityListFormValues>();
  const {
    filterMethods: { defaultFilterData, filterData },
  } = entityListStore;

  const handleReset = () => {
    // Keep paymentFlowType value, not reset selected tab
    reset({ ...defaultFilterData, paymentFlowType: filterData.paymentFlowType });
  };

  const inputGeneralProps = { control, variant: 'outlined', size: 'small' } as const;

  return (
    <GridTableRow variant="filter" component="form">
      <LbInput
        {...inputGeneralProps}
        name="businessName"
        placeholder="Name"
        dataTestId="business-name-input"
      />
      <LbSelect
        {...inputGeneralProps}
        name="businessEntityType"
        options={entityTypeOptionsWithAll}
        displayEmpty
        dataTestId="business-entity-type-select"
      />
      <LbSelect
        {...inputGeneralProps}
        name="inbodyType"
        options={inbodyTypeOptionsWithAll}
        displayEmpty
        dataTestId="inbody-type-select"
      />
      <LbInput
        {...inputGeneralProps}
        name="ownerName"
        placeholder="Name"
        dataTestId="owner-name-input"
      />
      <LbInput
        {...inputGeneralProps}
        type="tel"
        name="businessPhone"
        placeholder="(xxx) xxx xxxx"
        rules={{ validate: (value) => !value || validatePhone(value) }}
        dataTestId="business-phone-input"
      />
      <LbSelect
        {...inputGeneralProps}
        name="status"
        options={entityStatusOption}
        displayEmpty
        dataTestId="status-select"
      />
      <GridTableCell justify="stretch">
        <Button
          variant="outlined"
          size="small"
          type="reset"
          fullWidth
          onClick={handleReset}
        >
          Clear All
        </Button>
      </GridTableCell>
    </GridTableRow>
  );
});

export { FilterRow };
