import React from 'react';

import { useForm, UseFormProps } from 'react-hook-form';

import {
  InputBox,
  LbAutocomplete,
  LbDatePicker,
  LbInput,
  LbSelect,
} from '../../../../shared/components/form-components';
import { genderOptions } from '../../../../shared/constants/user-info/gender';
import { states } from '../../../../shared/constants/states';
import {
  validateEmail,
  validatePhone,
  validateAge,
  validateZipCode,
} from '../../../../shared/utils/reactHookFormUtils';
import { validationService } from '../../../services/validationService';
import { Customer } from '../../../interfaces/Customer';

type PersonalInfoFormFormValues = Customer;

type PersonalInfoFormProps = {
  formConfig: UseFormProps<PersonalInfoFormFormValues>;
  onSubmit: (data: PersonalInfoFormFormValues) => void;
  formId: string;
};

const PersonalInfoForm = ({ formConfig, onSubmit, formId }: PersonalInfoFormProps) => {
  const { control, handleSubmit, setError, formState } =
    useForm<PersonalInfoFormFormValues>(formConfig);

  const { dirtyFields } = formState;

  const generalInputProps = {
    control,
    size: 'small',
    variant: 'standard',
  } as const;

  const validateExistUser = async (email: string) => {
    try {
      await validationService.validateEmail(email, [400]);
    } catch (error: any) {
      setError('email', {
        message: error.response?.data?.message || 'User already exists',
      });
      throw error;
    }
  };

  const handleFormSubmit = handleSubmit(async (data) => {
    if (dirtyFields.email) {
      await validateExistUser(data.email);
    }

    onSubmit(data);
  });

  return (
    <form onSubmit={handleFormSubmit} id={formId} noValidate>
      <InputBox title="Personal Data">
        <LbInput
          {...generalInputProps}
          name="firstName"
          label="First Name"
          rules={{ required: 'First Name is required' }}
        />
        <LbInput
          {...generalInputProps}
          name="lastName"
          label="Last Name"
          rules={{ required: 'Last Name is required' }}
        />
        <LbInput
          {...generalInputProps}
          name="email"
          label="Email address"
          type="email"
          rules={{ required: 'Email is required', validate: { validateEmail } }}
        />
        <LbInput
          {...generalInputProps}
          name="phone"
          label="Cell Phone"
          type="tel"
          rules={{ required: 'Cell Phone is required', validate: { validatePhone } }}
        />
        <LbDatePicker
          {...generalInputProps}
          name="dateOfBirth"
          label="Date of Birth"
          helperText="You need to be at least 14 years to join the LifeBase"
          openTo="year"
          rules={{
            required: 'Date of Birth is required',
            validate: { validateMinAge: (value) => validateAge(value, 14) },
          }}
        />
        <LbSelect
          {...generalInputProps}
          name="gender"
          label="Gender"
          options={genderOptions}
          rules={{ required: 'Gender is required' }}
        />
      </InputBox>
      <InputBox title="Address">
        <LbInput
          {...generalInputProps}
          name="address"
          label="Address"
          rules={{ required: 'Address is required' }}
        />
        <LbInput
          {...generalInputProps}
          name="city"
          label="City"
          rules={{ required: 'City is required' }}
        />
        <LbAutocomplete
          {...generalInputProps}
          name="state"
          label="State"
          options={states}
          rules={{ required: 'State is required', deps: ['zipCode'] }}
        />
        <LbInput
          {...generalInputProps}
          name="zipCode"
          label="Zip Code"
          rules={{
            required: 'Zip Code is required',
            validate: async (zipCode, { state }) => validateZipCode(zipCode, state),
          }}
        />
      </InputBox>
    </form>
  );
};

export { PersonalInfoForm, PersonalInfoFormFormValues, PersonalInfoFormProps };
