import { apiService } from '../../shared/services/apiService';

export const validationService = {
  // validate email for exist and draft users, replace to validationService on shared module
  async validateEmail(email: string, ignoreErrorCodes?: number[]) {
    const params = { email };

    const response = await apiService.get('/api/sign-up/validate-email', {
      params,
      ignoreErrorCodes,
    });

    return response.data;
  },
};
