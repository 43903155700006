import React from 'react';

import { Divider } from '@mui/material';

import classes from './RegistrationPaymentInfo.module.scss';
import { ResultTitle } from '../components/ResultTitle/ResultTitle';
import { PaymentDetails } from '../components/PaymentDetails/PaymentDetails';
import { InfoContainer } from '../components/InfoContainer/InfoContainer';
import { WELCOME_VIDEO_TITLE, WELCOME_VIDEO_URL } from '../../../constants/welcomeVIdeo';
import { VideoBlock } from '../components/VideoBlock/VideoBlock';
import { PaymentInfo } from '../../../interfaces/checkoutInfo';

type RegistrationPaymentInfoProps = {
  paymentInfo: PaymentInfo;
};

const RegistrationPaymentInfo = ({ paymentInfo }: RegistrationPaymentInfoProps) => {
  return (
    <InfoContainer>
      <ResultTitle
        isSuccessful
        title="Thank you for your payment!"
        description="Access to the LifeBase app starts when your coach finalizes your setup and custom macro plan."
      />
      <PaymentDetails paymentInfo={paymentInfo} />
      <Divider className={classes.divider} />
      <VideoBlock
        title="We're excited to help you reach your goals."
        description="Your consultation with your personal nutrition coach is next. Watch the video
        below to get started."
        videoName={WELCOME_VIDEO_TITLE}
        videoUrl={WELCOME_VIDEO_URL}
      />
    </InfoContainer>
  );
};

export { RegistrationPaymentInfo };
