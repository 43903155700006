import { apiService } from '../../shared/services/apiService';
import { TaxRateInfo } from '../interfaces/tax';

export const taxService = {
  async getTaxRate(entityId: number) {
    const resp = await apiService.get<TaxRateInfo>(`/api/entity/${entityId}/tax-rate`);

    return resp.data;
  },

  async createTaxRate(entityId: number, taxRatePercent: number) {
    const resp = await apiService.post<TaxRateInfo>(`/api/entity/${entityId}/tax-rate`, {
      taxRatePercent,
    });

    return resp.data;
  },
  // test is need id in body
  async updateTaxRate(entityId: number, taxRateId: number, taxRatePercent: number) {
    const resp = await apiService.put<TaxRateInfo>(
      `/api/entity/${entityId}/tax-rate/${taxRateId}`,
      {
        taxRatePercent,
      },
    );

    return resp.data;
  },

  async getAppliedTaxRate(entityId: number) {
    const resp = await apiService.get<TaxRateInfo | undefined>(
      `/api/entity/${entityId}/tax-rate/applied`,
    );

    return resp.data;
  },
};
