import React from 'react';
import PropTypes from 'prop-types';

import classes from './Cell.module.scss';
import { TableCell } from '../../../../components/TableCell/TableCell';

const Cell = ({ text, adornment }) => {
  return (
    <TableCell position="between">
      <p>{text}</p>
      <span className={classes.adornment}>{adornment}</span>
    </TableCell>
  );
};

Cell.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  adornment: PropTypes.string,
};

export { Cell };
