'use strict';

angular.module('app').factory('OperatorService', OperatorService);

OperatorService.$inject = ['ApiService', 'ModalService'];

function OperatorService(ApiService, ModalService) {
    return {
        getOperatorAccountSettings: function (id) {
            return ApiService.get('api/admin/account/' + id).then(function (res) {
                return res;
            }, function (err) {
                return err
            })
        },
        createOperatorAccount: function (data) {
            return ApiService.post('api/super/operator', data).then(function (res) {
                return res;
            }, function (err) {
                return err
            })
        },
        updateOperatorAccountSettings: function (id, data) {
            return ApiService.put('api/admin/account/' + id, data).then(function (res) {
                ModalService.savedChanges();
                return res;
            }, function (err) {
                return err
            })
        },
    };
}
