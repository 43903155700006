'use strict';

angular.module('app').factory('EntityService', EntityService);

EntityService.$inject = ['ApiService', 'constants', '$location'];

function EntityService(ApiService, constants, $location) {
  var service = {
    getAllEntities: function () {
      return ApiService.get('api/super/entities').then(function (res) {
        return res;
      }, function (err) {
        return err;
      });
    },
    getEntityById: function (id) {
      return ApiService.get('api/super/entity/' + id).then(function (res) {
        return res;
      }, function (err) {
        return err;
      });
    },
    updateEntityBusinessInfo: function (id, data) {
      return ApiService.put('api/admin/entity/' + id + '/info/business', data).then(function (res) {
        return res;
      }, function (err) {
        return err;
      });
    },
    updateEntityLegalInfo: function (id, data) {
      return ApiService.put('api/admin/entity/' + id + '/info/legal', data).then(function (res) {
        return res;
      }, function (err) {
        return err;
      });
    },
    updateEntityContacts: function (id, data) {
      return ApiService.put('api/admin/entity/' + id + '/contacts', data).then(function (res) {
        return res;
      }, function (err) {
        return err;
      });
    },
    updateEntityInbodyInfo: function (id, data, isSuper) {
      return ApiService.put('api/' + (isSuper ? 'super' : 'admin') + '/entity/' + id + '/inbody', data).then(function (res) {
        return res;
      }, function (err) {
        return err;
      });
    },

    deleteEntityContact: function (entityId, contactId) {
      return ApiService.delete('api/admin/entity/' + entityId + '/contacts/' + contactId).then(function (res) {
        return res;
      }, function (err) {
        return err;
      });
    },
    entityCancelSubscription: function (userId) {
      return ApiService.delete('api/admin/entity/' + userId + '/subscription').then(function (res) {
        return res;
      }, function (err) {
        return err;
      });
    },
    updateEntityPaymentInformation: function (id, data) {
      return ApiService.put('api/admin/entity/' + id + '/payments', data).then(function (res) {
        return res;
      }, function (err) {
        return err;
      });
    },
    getEntitiesBasics: function () {
      return ApiService.get('api/super/entities-basics').then(function (res) {
        res.data.entities.sort(service.compareEntities);
        return res;
      }, function (err) {
        return err;
      });
    },
    getEntitiesOfAdmin: function () {
      return ApiService.get('api/admin/entities').then(function (res) {
        return res;
      }, function (err) {
        return err;
      });
    },
    acceptStripeTerms: function (id) {
      return ApiService.post('api/admin/accept/stripe/terms/' + id).then(function (res) {
        return res;
      }, function (err) {
        return err;
      });
    },
    checkCorpPlanDuplicate: function (currList) {
      var valueArr = currList.map(function (item) {
        return item.price
      });
      return valueArr.some(function (item, idx) {
        return valueArr.indexOf(item) !== idx;
      });
    },
    compareEntities: function (a, b) {
      if (a.business_name < b.business_name)
        return -1;
      if (a.business_name > b.business_name)
        return 1;
      return 0;
    },
    arrayUnique: function (array) {
      var a = array.concat();
      for (var i = 0; i < a.length; ++i) {
        for (var j = i + 1; j < a.length; ++j) {
          if (a[i] === a[j])
            a.splice(j--, 1);
        }
      }
      return a;
    },
    getPositionGroups: function (sport) {
      var result = [];
      var sportPositions = constants.positions;

      for (var i in sportPositions) {
        if (!sport || sport === 'All') {
          result = result.concat(sportPositions[i].positions)
        } else if (sport === sportPositions[i].sport) {
          result = sportPositions[i].positions;
        }
      }

      return service.arrayUnique(result);
    },
    checkZipCode: function (prms) {
      return ApiService.get('api/super/zip', prms).then(function (res) {
        return res;
      }, function (err) {
        return err;
      });
    },
    createEntity: function (data) {
      return ApiService.post('api/super/v2/entity', data).then(function (res) {
        return res;
      }, function (err) {
        return err;
      });
    },
    createSchool: function (data) {
      return ApiService.post('api/super/v2/entity/school', data).then(function (res) {
        return res;
      }, function (err) {
        return err;
      });
    },
    getBusinessDetails: function (entityId) {
      return ApiService.get(`api/v2/entity/${entityId}/business-details`).then(res => {
        return res;
      }, err => {
        return err;
      });
    },
    editBusinessDetails: function (entityID, body) {
      return ApiService.patch(`api/v2/entity/${entityID}/business-details`, body).then(res => {
        return res;
      }, err => {
        return err;
      });
    },
    getStripeLink: function (params) {
      params.redirectSubUrl = $location.url();
      return ApiService.get('api/super/get-stripe-link', params).then(res => {
        return res;
      }, err => {
        return err;
      });
    },
    getInbodyDetails: function (entityId) {
      return ApiService.get(`api/v2/entity/${entityId}/inbody-specification`).then(res => {
        return res;
      }, err => {
        return err;
      });
    },
    saveInbodyDetails: function (entityId, data) {
      return ApiService.patch(`api/v2/entity/${entityId}/inbody-specification`, data).then(function (res) {
        return res;
      }, function (err) {
        return err;
      });
    },
    saveInbodySerialNumbers: function (entityId, data) {
      return ApiService.patch(`api/v2/entity/${entityId}/inbody-serial-numbers`, data).then(function (res) {
        return res;
      }, function (err) {
        return err;
      });
    },
    setInbodyTypeAndPaymentBundle: function (entityID, data) {
      return ApiService.put(`api/super/v3/entity/${entityID}/set-inbody-and-payment-bundle`, data).then(function (res) {
        return res;
      }, function (err) {
        return err;
      })
    },
    setupCredentials: function (entityID, data) {
      return ApiService.put(`api/super/v2/entity/${entityID}/credentials`, data).then(function (res) {
        return res;
      }, function (err) {
        return err;
      });
    },
    getParentEntities: function () {
      return ApiService.get('api/super/entity/parent').then(function (res) {
        return res;
      }, function (err) {
        return err;
      });
    },
    getPaymentBundle: function (entityID) {
      return ApiService.get(`api/v2/entity/${entityID}/payment-bundle`).then(function (res) {
        return res;
      }, function (err) {
        return err;
      });
    },
    savePaymentBundle: function (entityID, data) {
      return ApiService.patch(`api/super/v2/entity/${entityID}/payment-bundle`, data).then(function (res) {
        return res;
      }, function (err) {
        return err;
      });
    },
    setTax: function (entityID, tax) {
      return ApiService.patch(`api/entity/${entityID}/tax`, null, {tax}).then(function (res) {
        return res;
      }, function (err) {
        return err;
      });
    },
    getLoginId: function (entityId) {
      return ApiService.get(`api/admin/${entityId}/loginId`).then(function (res) {
        return res;
      }, function (err) {
        return err;
      });
    },
    saveLoginId: function (entityId, loginId) {
      return ApiService.patch(`api/admin/${entityId}/loginId`, null, {loginId}).then(function (res) {
        return res;
      }, function (err) {
        return err;
      });
    },
    getIsEntityHasChildren: function (entityId) {
      const params = { parentEntityID: entityId};
      return ApiService.get('api/linked-entity-group/exist-by-parent-id', params).then(function (res) {
        return res;
      }, function (err) {
        return err;
      });
    },
    getChildEntitiesInfo: function () {
      return ApiService.get('api/admin/schools').then(function (res) {
        return res;
      }, function (err) {
        return err;
      });
    },
    getDefaultPaymentBundle: (entityType) => ApiService.get('api/payment-bundle/default', { entityType }),
  };

  return service;
}
