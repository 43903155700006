import { Button } from '@mui/material';
import React from 'react';
import { useToggle } from 'react-use';
import { observer } from 'mobx-react';

import { ManageMembersModal } from './ManageMembersModal/ManageMembersModal';
import { freeAccessStore } from '../../../stores/freeAccessStore';

type ManageMembersButtonProps = {
  entityId: number;
};

const ManageMembersButton = observer(({ entityId }: ManageMembersButtonProps) => {
  const [showManageMembersModal, toggleShowManageMembersModal] = useToggle(false);

  const { freeSeatsInfo } = freeAccessStore;

  const { freeSeatsCount, freeSeatsUsed } = freeSeatsInfo;

  return (
    <div>
      <Button variant="outlined" size="small" onClick={toggleShowManageMembersModal}>
        Manage members
      </Button>
      <ManageMembersModal
        open={showManageMembersModal}
        onClose={toggleShowManageMembersModal}
        entityId={entityId}
        freeSeatsCount={freeSeatsCount}
        freeSeatsUsed={freeSeatsUsed}
      />
    </div>
  );
});

export { ManageMembersButton };
