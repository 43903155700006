import React from 'react';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import BlockIcon from '@mui/icons-material/Block';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import LoopRoundedIcon from '@mui/icons-material/LoopRounded';

import classes from './StatusCell.module.scss';
import { UserActivationStatusesType } from '../../../../constants/USER_ACTIVATION_STATUSES';
import { Status } from '../../../../../shared/components/ui-components';
import { getFormattedDate, isFutureOrToday } from '../../../../../shared/utils/dateUtils';
import {
  localDataFormat,
  oldDateFormat,
} from '../../../../../shared/constants/apiDateFormat';

type StatusCellProps = {
  activationStatus: UserActivationStatusesType;
  subscriptionExpire: string;
};

const StatusCell = ({ activationStatus, subscriptionExpire }: StatusCellProps) => {
  const dataTestId = 'status-cell';

  switch (activationStatus) {
    case 'active_user':
    case 'active_ca':
      return (
        <Status
          icon={<CheckCircleRoundedIcon fontSize="large" color="success" />}
          dataTestId={dataTestId}
        >
          Active
        </Status>
      );
    case 'canceled_user': {
      const isStillActive =
        subscriptionExpire && isFutureOrToday(subscriptionExpire, oldDateFormat);

      const formattedExpirationDay = getFormattedDate(
        subscriptionExpire,
        localDataFormat,
        oldDateFormat,
      );

      return (
        <Status
          icon={<CancelRoundedIcon fontSize="large" color="error" />}
          dataTestId={dataTestId}
          align={isStillActive ? 'start' : 'center'}
        >
          <div>Canceled</div>
          {isStillActive && (
            <div className={classes.description}>Active to {formattedExpirationDay}</div>
          )}
        </Status>
      );
    }
    case 'incomplete_user':
    case 'incomplete_ca':
      return (
        <Status
          icon={<BlockIcon fontSize="large" color="error" />}
          dataTestId={dataTestId}
        >
          Incomplete
        </Status>
      );
    case 'preregistered':
      return (
        <Status
          icon={<LoopRoundedIcon fontSize="large" color="warning" />}
          dataTestId={dataTestId}
        >
          Pre-Registered
        </Status>
      );
    case 'payment_issue_user':
    case 'payment_issue_ca':
    case 'payment_issue':
      return (
        <Status
          icon={<WarningRoundedIcon fontSize="large" color="primary" />}
          dataTestId={dataTestId}
        >
          Payment issue
        </Status>
      );
    default:
      return <div data-test-id={dataTestId}>No Info</div>;
  }
};

export { StatusCell };
