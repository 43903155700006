import React from 'react';
import PropTypes from 'prop-types';

import { Paper } from '@mui/material';

import classes from './CustomerRow.module.scss';
import { CustomerInfoBox } from './CustomerInfoBox/CustomerInfoBox';
import { InventoryRow } from '../../../components/InventoryRow/InventoryRow';

const CustomerRow = ({ customerInfo }) => {
  const {
    customerEmail,
    customerFirstName,
    customerLastName,
    customerPhone,
    customerID,
    inventorySupplements,
  } = customerInfo;

  const customerName = `${customerFirstName} ${customerLastName}`;

  return (
    <Paper variant="roundedBox" className={classes.customerRow}>
      <CustomerInfoBox
        customerEmail={customerEmail}
        customerName={customerName}
        customerPhone={customerPhone}
        customerId={customerID}
      />
      <div className={classes.inventoryBox}>
        {inventorySupplements?.map((inventory) => (
          <InventoryRow
            customerId={customerID}
            inventoryInfo={inventory}
            className={classes.inventoryRow}
            hideAmountUnit
            key={inventory.inventoryID}
          />
        ))}
      </div>
    </Paper>
  );
};

CustomerRow.propTypes = {
  customerInfo: PropTypes.shape({
    customerEmail: PropTypes.string,
    customerFirstName: PropTypes.string,
    customerLastName: PropTypes.string,
    customerPhone: PropTypes.string,
    customerID: PropTypes.number,
    inventorySupplements: PropTypes.array,
  }),
};

export { CustomerRow };
