const autoNgTemplateLoaderTemplate1 = require('./customers-dashboard.template.html');

'use strict';

(function () {
    angular
        .module('app.customersDashboard', [])
        .config(customersDashboardConfig);

    customersDashboardConfig.$inject = ['$routeProvider'];

    function customersDashboardConfig($routeProvider) {
        $routeProvider
            .when('/customers-dashboard', {
                disableCache: true,
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'CustomersDashboardController',
                controllerAs: 'vm',
                reloadOnSearch:false,
                resolve: {
                    loginRequired: ['AuthService', function (AuthService) {
                        return AuthService.loginRequired();
                    }]
                }
            })
    }
})();