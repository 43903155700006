import { SpringPagination } from '../../shared/interfaces/SpringPagination';
import { apiService } from '../../shared/services/apiService';
import {
  PaymentHistoryRecord,
  PaymentHistoryFilters,
} from '../interfaces/paymentHistoryList';
import {
  PerformanceSummary,
  PerformanceSummaryFilters,
  PerformanceSummaryGraph,
} from '../interfaces/performanceSummary';

export type GetPaymentHistoryListResponse = SpringPagination<PaymentHistoryRecord[]>;

export const detailedRevenueService = {
  async getPaymentHistoryList(entityId: number, params: PaymentHistoryFilters) {
    const resp = await apiService.post<GetPaymentHistoryListResponse>(
      `api/entity/${entityId}/detailed-revenue/payment-history`,
      params,
    );

    return resp.data;
  },

  async getPerformanceSummary(entityId: number, params: PerformanceSummaryFilters) {
    const resp = await apiService.post<PerformanceSummary>(
      `api/entity/${entityId}/detailed-revenue/performance-summary`,
      params,
    );

    return resp.data;
  },

  async getPerformanceSummaryGraph(entityId: number, params: PerformanceSummaryFilters) {
    const resp = await apiService.post<PerformanceSummaryGraph>(
      `api/entity/${entityId}/detailed-revenue/performance-summary/graph/total-amount`,
      params,
    );

    return resp.data;
  },
};
