import React from 'react';
import PropTypes from 'prop-types';

import { Paper } from '@mui/material';

import classes from './DailyMealTable.module.scss';
import { MealTableTitle } from '../MealInfoTable/MealTableTitle/MealTableTitle';
import { StarText } from '../../../shared/components/ui-components';
import { DailyMealHeadRow } from './DailyMealHeadRow/DailyMealHeadRow';
import { DailyMealInfoRow } from './DailyMealInfoRow/DailyMealInfoRow';
import { BeyondInfoRow } from './BeyondInfoRow/BeyondInfoRow';

const DailyMealTable = ({ dailyResult }) => {
  const { actual, goal, beyond, score } = dailyResult;

  return (
    <Paper
      variant="roundedBox"
      className={classes.dailyMealTable}
      data-test-id="daily-meal-table"
    >
      <MealTableTitle isReport mealName="Daily Result" scoreValue={score} />
      <DailyMealHeadRow />
      <DailyMealInfoRow
        withPoint={false}
        mealInfo={goal}
        titleText={<StarText>Goal</StarText>}
      />
      <DailyMealInfoRow mealInfo={actual} titleText="Actual" />
      <BeyondInfoRow mealInfo={beyond} />
    </Paper>
  );
};

DailyMealTable.propTypes = {
  dailyResult: PropTypes.object.isRequired,
};

export { DailyMealTable };
