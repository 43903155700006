import { useForm } from 'react-hook-form';
import { activeUsersCommunicationStore } from '../../../stores/activeUsersCommunicationStore';

export const useFilterRow = () => {
  const { control, handleSubmit, setValue } = useForm({ mode: 'onBlur' });

  const { getFilteredCustomers } = activeUsersCommunicationStore;

  const filterCustomer = (event) => {
    event?.preventDefault();

    handleSubmit(getFilteredCustomers)();
  };

  const changeOrderHandler = (field, order) => {
    setValue('field', field);
    setValue('order', order);

    filterCustomer();
  };

  const ascOrderHandler = (field) => changeOrderHandler(field, 'asc');
  const descOrderHandler = (field) => changeOrderHandler(field, 'desc');

  return { control, ascOrderHandler, descOrderHandler, filterCustomer };
};
