import React from 'react';
import PropTypes from 'prop-types';

import classes from './OverviewSupplementTable.module.scss';
import { SupplementRows } from './SupplementRows/SupplementRows';
import { TableHeader } from './TableHeader/TableHeader';
import { Cell } from './Cell/Cell';
import { getFormattedDate } from '../../../../../../shared/utils/dateUtils';
import { getReceptionsSupplements } from './OverviewSupplementTable.settings';
import {
  localTimeFormat,
  timeFormat,
} from '../../../../../../shared/constants/apiDateFormat';
import { InfoRow } from './InfoRow/InfoRow';

const OverviewSupplementTable = ({ schedule, customerSupplements, selectedDate }) => {
  const receptions = getReceptionsSupplements(schedule);

  const scheduleTable = schedule.map(([time, timeSchedule]) => {
    const formatTime = getFormattedDate(time, localTimeFormat, timeFormat);
    const key = timeSchedule[0].scheduleId;

    return (
      <div className={classes.timeRow} key={key}>
        <Cell text={formatTime} />
        <SupplementRows
          schedule={timeSchedule}
          customerSupplements={customerSupplements}
        />
      </div>
    );
  });

  return (
    <div>
      <InfoRow selectedDate={selectedDate} receptions={receptions} />
      <TableHeader />
      <div className={classes.tableBox}>{scheduleTable}</div>
    </div>
  );
};

OverviewSupplementTable.propTypes = {
  schedule: PropTypes.array,
  customerSupplements: PropTypes.object,
  selectedDate: PropTypes.object,
};

export { OverviewSupplementTable };
