import { useEffect } from 'react';

import { unleashClient } from '../unleashClient/unleashClient';

export const useUnleashClient = (loggedInUser) => {
  useEffect(() => {
    unleashClient.start();

    return unleashClient.stop;
  }, []);

  useEffect(() => {
    if (loggedInUser?.email) {
      unleashClient.updateContext({ userId: loggedInUser.email });
    }
  }, [loggedInUser]);
};
