import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Pagination } from '@mui/material';

import classes from './FoodScoreTable.module.scss';
import { FoodInfoRow } from '../FoodInfoRow/FoodInfoRow';
import { FoodScoreTableHead } from '../FoodScoreTableHead/FoodScoreTableHead';

const FoodScoreTable = ({ tableData, getTableData }) => {
  const { content: foods, number: pageNumber, totalPages, last: isLastPage } = tableData;

  const reloadTableIfPageScored = () => {
    const isLastFoodOnPage = foods.length === 0;

    if (isLastFoodOnPage && !isLastPage) {
      getTableData();
    }
  };

  useEffect(() => {
    reloadTableIfPageScored();
  }, [foods]);

  const isShowPagination = totalPages > 1;
  const pageChangeHandler = (event, page) => getTableData(page - 1);

  return (
    <>
      <div className={classes.foodScoreTable}>
        <FoodScoreTableHead />
        {foods.map((food) => (
          <FoodInfoRow food={food} key={food.foodId} />
        ))}
      </div>
      {isShowPagination && (
        <Pagination
          className={classes.pagination}
          count={totalPages}
          onChange={pageChangeHandler}
          page={pageNumber + 1}
        />
      )}
    </>
  );
};

FoodScoreTable.propTypes = {
  tableData: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.shape({ foodId: PropTypes.number })),
    number: PropTypes.number,
    totalPages: PropTypes.number,
    last: PropTypes.bool,
  }),
  getTableData: PropTypes.func,
};

export { FoodScoreTable };
