'use strict';

angular.module('app').factory('SharingService', SharingService);

SharingService.$inject = ['ApiService'];

function SharingService(ApiService) {
    return {
        getSharedDataByToken: function (token) {
            return ApiService.get('api/public/share/' + token).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        }
    };
}
