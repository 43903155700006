import React from 'react';
import PropTypes from 'prop-types';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

import classes from './StatusCell.module.scss';
import { activationStatuses } from '../../../../constants/activationStatuses';
import { Status } from '../../../../../shared/components/ui-components';

const StatusCell = ({ activationStatus }) => {
  switch (activationStatus) {
    case activationStatuses.ACTIVE: {
      return (
        <Status
          icon={
            <CheckCircleRoundedIcon fontSize="large" className={classes.activeIcon} />
          }
        >
          Active
        </Status>
      );
    }
    case activationStatuses.DEACTIVATED: {
      return (
        <Status
          icon={<CancelRoundedIcon fontSize="large" className={classes.deactivateIcon} />}
        >
          Deactivated
        </Status>
      );
    }
    default: {
      return <p>No info</p>;
    }
  }
};

StatusCell.propTypes = {
  activationStatus: PropTypes.oneOf(Object.values(activationStatuses)),
};

export { StatusCell };
