import moment from 'moment';

import { apiService } from '../../shared/services/apiService';
import { getFormattedDate } from '../../shared/utils/dateUtils';

const sendGoal = async ({
  targetGoal,
  customerID,
  startDate,
  isRunGlobalSpinner = false,
}) => {
  startDate = startDate ?? moment().format('YYYY-MM-DD');

  const submitDate = {
    targetGoal,
    customerID,
    startDate,
  };

  const resp = await apiService.post('/api/daily-goal', submitDate, {
    isRunGlobalSpinner,
  });
  return resp.data;
};

const getTrackedGoals = async (customerID, date) => {
  const params = {
    customerID,
    month: getFormattedDate(date, 'MM'),
    year: getFormattedDate(date, 'YYYY'),
  };

  const resp = await apiService('/api/daily-goal/tracked/statistic', {
    params,
  });

  return resp.data;
};

export const waterGoalService = { sendGoal, getTrackedGoals };
