import React from 'react';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

import classes from './StatusCell.module.scss';
import { InfoTooltip, Status } from '../../../../../../shared/components/ui-components';
import {
  STRIPE_PAYMENT_STATUS,
  StripePaymentStatus,
} from '../../../../../constants/paymentStatus';

type StatusCellProps = {
  status: StripePaymentStatus;
};

const StatusCell = ({ status }: StatusCellProps) => {
  switch (status) {
    case STRIPE_PAYMENT_STATUS.SUCCEEDED:
      return (
        <Status icon={<CheckCircleRoundedIcon fontSize="large" color="success" />}>
          Succeeded
        </Status>
      );
    case STRIPE_PAYMENT_STATUS.PAYMENT_ISSUE:
      return (
        <Status icon={<WarningRoundedIcon fontSize="large" color="warning" />}>
          <div className={classes.issue}>
            <span>Payment issue</span>
            <InfoTooltip iconFontSize="medium" placement="right">
              This customer has payment issues in Stripe. Either the card was expired or
              insufficient funds. Please contact with customer
            </InfoTooltip>
          </div>
        </Status>
      );

    default:
      return <div>{status}</div>;
  }
};

export { StatusCell };
