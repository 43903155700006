import React, { ReactNode } from 'react';

import { Paper } from '@mui/material';
import classNames from 'classnames';

import classes from './PageTitle.module.scss';

type PageTitleProps = {
  title: string;
  children?: ReactNode;
  className?: string;
  tabs?: ReactNode;
  customTopMargin?: boolean;
  customBottomMargin?: boolean;
};

const PageTitle = ({
  title,
  children,
  className,
  tabs,
  customTopMargin,
  customBottomMargin,
}: PageTitleProps) => {
  const paperClasses = classNames(className, classes.titleBox, {
    [classes.marginTop]: !customTopMargin,
    [classes.marginBottom]: !customBottomMargin,
  });

  return (
    <Paper variant="roundedBox" className={paperClasses}>
      <div className={classes.titleRow}>
        <h3 className={classes.title}>{title}</h3>
        {children}
      </div>
      <div>{tabs}</div>
    </Paper>
  );
};

export { PageTitle };
