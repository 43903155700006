import { useEffect } from 'react';

import { getService } from 'react-in-angularjs';
import { pathQueryParams } from '../../../constants/pathQueryParams';
import { getRouteParam } from '../../../utils/angularUtils';

export const useAuth0SingIn = () => {
  const continueAuthSingIn = () => {
    const isLoginByAuth0 = getRouteParam(pathQueryParams.loginByAuth0);

    if (isLoginByAuth0) {
      const AuthService = getService('AuthService');

      AuthService.continueAuth0SingIn();
    }
  };

  useEffect(() => {
    continueAuthSingIn();
  }, []);
};
