import { makeAutoObservable } from 'mobx';

import { releasesService } from '../services/releasesService';
import { resetAllStore } from './resetAllStore';

class ReleasesInfoStore {
  constructor() {
    this.setDefaultValues();
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.isShowReleasesInfo = false;
    this.releaseVersionNumber = undefined;
  }

  async getReleasesInfo() {
    const { version, updatesAvailable } = await releasesService.getReleaseStatus();

    this.setReleaseVersionNumber(version);
    this.setIsShowReleasesInfo(updatesAvailable);
  }

  setIsShowReleasesInfo(isShowReleasesInfo) {
    this.isShowReleasesInfo = isShowReleasesInfo;
  }

  setReleaseVersionNumber(versionNumber) {
    this.releaseVersionNumber = versionNumber;
  }

  closeReleasesInfo() {
    this.setIsShowReleasesInfo(false);
  }
}

const releasesInfoStore = new ReleasesInfoStore();

resetAllStore.addResetMethod(releasesInfoStore.setDefaultValues);

export { releasesInfoStore, ReleasesInfoStore };
