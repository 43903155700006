import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react';

import { LbModal } from '../../../shared/components/modals/LbModal/LbModal';
import { LbAutocomplete } from '../../../shared/components/form-components';
import { medicalTestsStore } from '../../stores/medicalTestsStore';

const AddTestModals = observer(
  ({ open, addTestHandler, cancelHandler, selectedTests }) => {
    const {
      control,
      handleSubmit,
      formState: { isValid },
    } = useForm({ mode: 'onChange' });

    const { tests, getTests } = medicalTestsStore;

    useEffect(() => {
      getTests();
    }, []);

    const onSubmit = ({ testName }) => {
      addTestHandler(testName);
    };

    const getIsTestUnique = (medicalTest) =>
      !selectedTests.some((test) => test.testID === medicalTest.id) ||
      'Lab Test already exists';

    return (
      <LbModal
        open={open}
        title="Add New Lab Test"
        primaryBtnConfig={{
          text: 'Add',
          onClick: handleSubmit(onSubmit),
          disabled: !isValid,
          startIcon: <AddRoundedIcon />,
        }}
        secondaryBtnConfig={{ onClick: cancelHandler }}
      >
        <LbAutocomplete
          name="testName"
          control={control}
          options={tests}
          getOptionLabel={(option) => option.testName}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          label="Choose Lab Test"
          shouldUnregister
          rules={{
            required: 'Lab Test name is required',
            validate: getIsTestUnique,
          }}
        />
      </LbModal>
    );
  },
);

AddTestModals.propTypes = {
  open: PropTypes.bool,
  addTestHandler: PropTypes.func,
  cancelHandler: PropTypes.func,
  selectedTests: PropTypes.array,
};

export { AddTestModals };
