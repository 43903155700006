import { DownloadedFileExtension } from '../../shared/constants/file/FILE_EXTENSIONS';
import { apiReportService } from '../../shared/services/apiService';
import { getResponseTypeForFile } from '../../shared/utils/apiUtils';
import { getFilteredTruthyFieldsObj } from '../../shared/utils/sortingAndGroupingUtil';
import { ReportSelectorOption } from '../interfaces/ReportSelectorOption';
import { ReportSelectorParams } from '../interfaces/ReportSelectorParams';

import { GetReportsListResponse } from '../interfaces/GetReportsListResponse';
import { downloadReportFile } from '../utils';

type GetReportSelectorOptionsResponse = {
  data: ReportSelectorOption[];
  selectorType: string;
};

export const reportService = {
  async getReportsList() {
    const resp = await apiReportService.get<GetReportsListResponse>('/api/reports/list');

    return resp.data;
  },

  async getReportSelectorOptions(
    selectorParams: ReportSelectorParams,
    isRunGlobalSpinner = false,
  ) {
    const filteredParams = getFilteredTruthyFieldsObj(selectorParams);

    const resp = await apiReportService.post<GetReportSelectorOptionsResponse>(
      '/api/reports/selector-data',
      filteredParams,
      {
        isRunGlobalSpinner,
      },
    );

    return resp.data;
  },

  async downloadReport(
    reportType: string,
    fileFormat: DownloadedFileExtension,
    params: Record<string, any>,
  ) {
    const responseType = getResponseTypeForFile(fileFormat);
    const backendFileFormat = fileFormat.toUpperCase();

    const resp = await apiReportService.get(
      `/api/reports/download/${reportType}/${backendFileFormat}`,
      { params, responseType },
    );

    downloadReportFile(resp.data, reportType, fileFormat);
  },
};
