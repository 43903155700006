import React, { useState } from 'react';

import { groupBy } from 'lodash';
import { observer } from 'mobx-react';

import { LbModal } from '../../../../shared/components/modals/LbModal/LbModal';
import { UserList } from './UserList/UserList';
import { communicationModalStore } from '../../../stores/communicationModalStore';

const ErrorSentMessageModal = observer(() => {
  const [openListIndex, setOpenListIndex] = useState<number | null>(null);
  const {
    notGotMessageUsers,
    isSentWithError,
    customersCount,
    resentViaEmail,
    closeCommunicationModal,
  } = communicationModalStore;

  const handleOpenUserList = (index: number) => {
    setOpenListIndex((currentIndex) => (index === currentIndex ? null : index));
  };

  const groupNotGotMessageUsers = groupBy(notGotMessageUsers, 'error');

  const infoTest = `${notGotMessageUsers.length} out of ${customersCount} messages were not sent`;

  return (
    <LbModal
      open={isSentWithError}
      title="Error!"
      text={infoTest}
      primaryBtnConfig={{ text: 'Resend via Email', onClick: resentViaEmail }}
      secondaryBtnConfig={{ text: 'Close', onClick: closeCommunicationModal }}
    >
      <div>
        {Object.entries(groupNotGotMessageUsers).map(([reason, users], index) => (
          <UserList
            key={reason}
            isShowUserList={openListIndex === index}
            onInfoClick={() => handleOpenUserList(index)}
            reason={reason}
            users={users}
          />
        ))}
      </div>
    </LbModal>
  );
});

export { ErrorSentMessageModal };
