import { omit } from 'lodash';
import { getFilteredTruthyFieldsObj } from '../../sortingAndGroupingUtil';
import { isEmptyValue } from '../../jsUtils';

export const requiredAllOrAnyone = <T extends Record<string, any>>(
  value: string | number,
  formValues?: T,
  omitFields: (keyof T)[] = [],
) => {
  const fieldsValue = omit(formValues, omitFields);

  const usedField = getFilteredTruthyFieldsObj(fieldsValue);

  const isEmpty = isEmptyValue(value);

  if (isEmpty && usedField) {
    return 'Required';
  }

  return true;
};
