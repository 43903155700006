import { ValueOfKey } from '../../utils-types';

export const BILLING_PERIOD_TYPE = {
  MONTHLY: 'MONTHLY',
} as const;

export type BillingPeriodType = ValueOfKey<typeof BILLING_PERIOD_TYPE>;

// use getHumanizeBillingPeriodType utils to get showed name
export const humanizeBillingPeriodType: Record<BillingPeriodType, string> = {
  MONTHLY: 'Monthly',
};
