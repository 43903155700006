'use strict';

angular.module('app')
    .controller('CustomerOverviewTabController', CustomerOverviewTabController);

CustomerOverviewTabController.$inject = [
    '$scope',
    '$routeParams',
    'GoalService',
    'CustomerService',
    'StatsService',
    'DateTimeUtil',
];

function CustomerOverviewTabController($scope, $routeParams, GoalService, CustomerService, StatsService, DateTimeUtil) {
    var vm = this;
    vm.scanDates = [];
    vm.keyBodyMetrics = {};
    vm.differencesPreviousCurrent = {};
    vm.differencesInitialCurrent = {};
    vm.currentDate = DateTimeUtil.formatDate(DateTimeUtil.now(), 'MM.DD.YYYY');
    vm.scansFrom = DateTimeUtil.minusDays(vm.scansFrom, 30);
    vm.scansTo = DateTimeUtil.now();
    vm.initialScan = null;
    vm.currentGoal = null;
    vm.metricsSpinner = {active: false};

    vm.getLastGoal = getLastGoal;
    vm.getKeyBodyMetrics = getKeyBodyMetrics;
    vm.initUserScanDatesInfo = initUserScanDatesInfo;

    vm.getKeyBodyMetrics();
    vm.initUserScanDatesInfo();
    vm.getLastGoal();

    function getLastGoal() {
        GoalService.getAllGoals($routeParams.id).then(function (res) {
            if (res.status === 200) {
                vm.goalList = res.data.current.sort(GoalService.compareByDate);
                vm.currentGoal = vm.goalList.length ? vm.goalList[vm.goalList.length - 1] : null;
            }
        })
    }

    function getKeyBodyMetrics() {
        vm.metricsSpinner.active = true;
        StatsService.getStatsKeyBodyMetrics($routeParams.id).then(function (res) {
            vm.metricsSpinner.active = false;
            if (res.status === 200) {
                vm.keyBodyMetrics = res.data;

                var previous = vm.keyBodyMetrics.previous;
                var current = vm.keyBodyMetrics.current;
                var initial = vm.keyBodyMetrics.initial;

                vm.differencesPreviousCurrent = {
                    weight: (previous.weight - current.weight).toFixed(1),
                    percentBodyFat: (previous.percentBodyFat - current.percentBodyFat).toFixed(1),
                    bodyFat: (previous.bodyFat - current.bodyFat).toFixed(1),
                    leanBodyMass: (previous.leanBodyMass - current.leanBodyMass).toFixed(1)
                };

                vm.differencesInitialCurrent = {
                    weight: (initial.weight - current.weight).toFixed(1),
                    percentBodyFat: (initial.percentBodyFat - current.percentBodyFat).toFixed(1),
                    bodyFat: (initial.bodyFat - current.bodyFat).toFixed(1),
                    leanBodyMass: (initial.leanBodyMass - current.leanBodyMass).toFixed(1)
                };
            }
        });
    }

    function initUserScanDatesInfo() {
        vm.metricsSpinner.active = true;
        CustomerService.getUserScanDates($routeParams.id).then(function (res) {
            vm.metricsSpinner.active = false;
            const isScansExist = res.status === 200 && res.data.scanDates.length > 0;

            if (isScansExist) {
                vm.scanDates = res.data.scanDates;

                if (vm.scanDates.length > 1) {
                    vm.scansTo = DateTimeUtil.parseDate(vm.scanDates[0], '.');
                    vm.scansFrom = DateTimeUtil.parseDate(vm.scanDates[1], '.');
                } else {
                    vm.scansFrom = DateTimeUtil.parseDate(vm.scanDates[0], '.');
                }

                vm.initialScan = vm.scanDates[vm.scanDates.length - 1];
                vm.lastScan = DateTimeUtil.formatDate(vm.scansTo, 'MM.DD.YYYY');
                vm.previousScan = DateTimeUtil.formatDate(vm.scansFrom, 'MM.DD.YYYY');
            }
        });
    }
}
