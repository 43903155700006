'use strict';

function createService($http, { baseUrl }) {
    return {
        get: function (url, prms, responseType) {
            var requestConfig = {
                method: 'GET',
                url: baseUrl + url,
                params: prms,
                withCredentials: true
            };
            if (responseType) {
                requestConfig.responseType = responseType
            }
            return $http(requestConfig);
        },
        post: function (url, data, prms, headers) {
            return $http({
                method: 'POST',
                url: baseUrl + url,
                params: prms,
                data: data,
                headers: headers,
                withCredentials: true
            });
        },
        put: function (url, data, prms) {
            return $http({
                method: 'PUT',
                url: baseUrl + url,
                params: prms,
                data: data,
                withCredentials: true
            });
        },
        delete: function (url, data) {
            return $http({
                method: 'DELETE',
                data: data,
                url: baseUrl + url,
                withCredentials: true
            });
        },
        patch: function (url, data, prms) {
            return $http({
                method: 'PATCH',
                url: baseUrl + url,
                params: prms,
                data: data,
                withCredentials: true
            });
        },
    };
};

// ApiService instance used for general requests

angular.module('app').factory('ApiService', ApiService);

ApiService.$inject = ['$http', 'constants'];

function ApiService($http, constants) {
    return createService($http, { baseUrl: constants.url });
}

// ApiReportService instance used for report requests to prevent performance issues

angular.module('app').factory('ApiReportService', ApiReportService);

ApiReportService.$inject = ['$http', 'constants'];

function ApiReportService($http, constants) {
    return createService($http, { baseUrl: constants.reportUrl });
}
