import React from 'react';

import classes from './HeaderRow.module.scss';
import {
  GridTableCell,
  GridTableRow,
} from '../../../../../../../../shared/components/table-components';
import { TitleWithDescription } from '../../../../../../../../shared/components/text-formation-components';

const HeaderRow = () => {
  const generalTitleProps = {
    titleFont: 'body-14-bold',
    withoutGap: true,
  } as const;

  return (
    <GridTableRow variant="unstyled" className={classes.row}>
      <GridTableCell>Meals</GridTableCell>
      <GridTableCell>
        <TitleWithDescription
          {...generalTitleProps}
          title="Fiber"
          description="grams/meal, # meals"
        />
      </GridTableCell>
      <GridTableCell>
        <TitleWithDescription
          {...generalTitleProps}
          title="Sodium"
          description="milligrams/meal"
        />
      </GridTableCell>
      <GridTableCell>
        <TitleWithDescription
          {...generalTitleProps}
          title="Sugar"
          description="grams/meal"
        />
      </GridTableCell>
      <GridTableCell>
        <TitleWithDescription
          {...generalTitleProps}
          title="Vegetables"
          description="cups/meal"
        />
      </GridTableCell>
      <GridTableCell>
        <TitleWithDescription
          {...generalTitleProps}
          title="Calories"
          description="cal/meal"
        />
      </GridTableCell>
    </GridTableRow>
  );
};

export { HeaderRow };
