(function () {
    'use strict';

    angular.module('app.sharing')
        .controller('SharingController', SharingController);

    SharingController.$inject = [
        '$routeParams',
        'SharingService'
    ];

    function SharingController($routeParams, SharingService) {
        var vm = this;

        vm.sharedData = {};
        vm.sharingData = {};

        vm.initSharingData = initSharingData;
        vm.checkMobileDevice = checkMobileDevice;

        vm.initSharingData(window.navigator.userAgent);
        vm.checkMobileDevice();

        function checkMobileDevice(userAgent) {
            var testExp = new RegExp('Android|webOS|iPhone|BlackBerry|Windows Phone|Opera Mini|IEMobile|Mobile', 'i');
            vm.isMobileDevice = testExp.test(userAgent);
        }

        function initSharingData() {
            SharingService.getSharedDataByToken($routeParams.token).then(function (res) {
                if (res.status === 200) {
                    vm.sharedData = res.data;
                    var complianceScore = vm.sharedData.mealData.complianceScore;
                    if (complianceScore === 5) {
                        vm.sharingData = {
                            emoji: '../images/vectors/emoji-score-5.svg',
                            mainPopupText: 'Wow, doesn’t get better than this!',
                            popupText: 'You are in the top of the top performers!'
                        }
                    } else if (complianceScore >= 4.5 && complianceScore < 5) {
                        vm.sharingData = {
                            emoji: '../images/vectors/emoji-score-4-5.svg',
                            mainPopupText: 'Great job!',
                            popupText: 'Stay at this level consistently and you will be transformed!'
                        }
                    } else if (complianceScore >= 4 && complianceScore < 4.5) {
                        vm.sharingData = {
                            emoji: '../images/vectors/emoji-score-4.svg',
                            mainPopupText: 'You are doing really well.',
                            popupText: 'Results will continue to come quickly!'
                        }
                    } else if (complianceScore >= 3.5 && complianceScore < 4) {
                        vm.sharingData = {
                            emoji: '../images/vectors/emoji-score-3-5.svg',
                            mainPopupText: 'Keep it up!',
                            popupText: 'You are on the path to good results.'
                        }
                    } else if (complianceScore >= 3 && complianceScore < 3.5) {
                        vm.sharingData = {
                            emoji: '../images/vectors/emoji-score-3.svg',
                            mainPopupText: 'Good Job.',
                            popupText: 'Little improvements will yield big results!'
                        }
                    } else if (complianceScore >= 2.5 && complianceScore < 3) {
                        vm.sharingData = {
                            emoji: '../images/vectors/emoji-score-2-5.svg',
                            mainPopupText: 'Stay strong.',
                            popupText: 'Never give up!'
                        }
                    } else if (complianceScore >= 2 && complianceScore < 2.5) {
                        vm.sharingData = {
                            emoji: '../images/vectors/emoji-score-2.svg',
                            mainPopupText: 'Keep pushing.',
                            popupText: 'Improvements will yield results!'
                        }
                    } else if (complianceScore >= 0 && complianceScore < 2) {
                        vm.sharingData = {
                            emoji: '../images/vectors/emoji-score-0.svg',
                            mainPopupText: 'Hang in there.',
                            popupText: 'It\'s only one day. Improve tomorrow!'
                        }
                    }
                }
            });
        }
    }

})();
