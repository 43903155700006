import React from 'react';
import PropTypes from 'prop-types';

import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { IconButton } from '@mui/material';
import { observer } from 'mobx-react';

import classes from './CardHeader.module.scss';
import { customerMedicalTestsStore } from '../../../../../../stores/customerMedicalTestsStore';
import { openConfirmationModal } from '../../../../../../../shared/features/confirmation';

const CardHeader = observer(({ testName, onDeleteTest, onEditTest }) => {
  const { isFormOpen } = customerMedicalTestsStore;

  const handleDeleteTest = () => {
    openConfirmationModal({
      title: 'Do you want to delete this Lab Test?',
      text: 'You will lose the added Information',
      confirmBtnConfig: {
        onClick: onDeleteTest,
      },
    });
  };

  return (
    <div className={classes.cardHeader}>
      <p>{testName}</p>
      <div className={classes.btnBox}>
        <IconButton onClick={onEditTest} disabled={isFormOpen} color="secondary">
          <EditRoundedIcon fontSize="medium" />
        </IconButton>
        <IconButton onClick={handleDeleteTest} color="secondary" disabled={isFormOpen}>
          <DeleteRoundedIcon fontSize="medium" />
        </IconButton>
      </div>
    </div>
  );
});

CardHeader.propTypes = {
  testName: PropTypes.string,
  onDeleteTest: PropTypes.func,
  onEditTest: PropTypes.func,
};

export { CardHeader };
