import React, { useEffect } from 'react';

import { angularize } from 'react-in-angularjs';
import { Container } from '@mui/material';
import { observer } from 'mobx-react';

import classes from './NgzCustomerSetupFlow2024.module.scss';
import { SystemProviders } from '../../../shared/providers';
import { LbStepper, StepOption } from '../../../shared/components/LbStepper/LbStepper';
import { UserProfileStep } from './containers/UserProfileStep/UserProfileStep';
import { WorkoutSettingsStep } from './containers/WorkoutSettingsStep/WorkoutSettingsStep';
import { GoalsStep } from './containers/GoalsStep/GoalsStep';
import { MacroPlanStep } from './containers/MacroPlanStep/MacroPlanStep';
import { MealTimeStep } from './containers/MealTimeStep/MealTimeStep';
import { getRouteParam } from '../../../shared/utils/angularUtils';
import { questionnaireReplyStore } from '../../stores/questionnaireReplyStore';
import { customerSetupStore } from '../../stores/customerSetupStore';

const NgzCustomerSetupFlow2024 = observer(() => {
  const { getCustomerQuestionnaire } = questionnaireReplyStore;
  const { stepperMethods } = customerSetupStore;
  const { activeStep } = stepperMethods;
  const customerId = getRouteParam('id');

  const stepsOptions: StepOption[] = [
    {
      label: 'User Profile',
      step: <UserProfileStep customerId={customerId} />,
    },
    {
      label: 'Workout Settings',
      step: <WorkoutSettingsStep customerId={customerId} />,
    },
    {
      label: 'Goals',
      step: <GoalsStep customerId={customerId} />,
    },
    {
      label: 'Macro Plan',
      step: <MacroPlanStep customerId={customerId} />,
    },
    {
      label: 'Meal Time',
      step: <MealTimeStep customerId={customerId} />,
    },
  ];

  useEffect(() => {
    getCustomerQuestionnaire(customerId);

    return () => {
      questionnaireReplyStore.setDefaultValues();
      customerSetupStore.setDefaultValues();
    };
  }, [customerId]);

  return (
    <SystemProviders>
      <Container>
        <h2 className={classes.title}>Add New Client</h2>
        <LbStepper
          stepsArr={stepsOptions}
          activeStep={activeStep}
          className={classes.stepper}
        />
      </Container>
    </SystemProviders>
  );
});

angularize(
  NgzCustomerSetupFlow2024,
  'ngzCustomerSetupFlow2024',
  angular.module('app.customerManagement'),
  {},
);

export { NgzCustomerSetupFlow2024 };
