import { FeeScheduleWithoutDisabled } from '../../../../interfaces/paymentBundleInfo';
import { FeeModuleSelectFormValues } from '../../FeeModuleWidget.settings';

export const processFeeScheduleToFeeModuleValues = (
  feeSchedule: FeeScheduleWithoutDisabled,
): FeeModuleSelectFormValues => {
  const feeModules = Object.fromEntries(
    Object.entries(feeSchedule).map(([key, info]) => [key, info.selected]),
  ) as FeeModuleSelectFormValues['feeModules'];

  return { feeModules };
};
