'use strict';

angular.module('app').factory('CustomerRoleService', CustomerRoleService);

CustomerRoleService.$inject = ['ApiService', 'ModalService'];

function CustomerRoleService(ApiService, ModalService) {
    return {
        changeDayType: function (prms) {
            return ApiService.put('api/workoutday', null, prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        changeType: function (id, data) {
            return ApiService.put('api/workouttype/' + id, data).then(function (res) {
                ModalService.savedChanges();
                return res;
            }, function (err) {
                return err;
            });
        },
        getType: function (id) {
            return ApiService.get('api/workouttype/' + id).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        }
    };
}
