(function () {
    'use strict';

    angular.module('app.coachSetup').controller('CoachSetupController', CoachSetupController);
    CoachSetupController.$inject = [
        '$routeParams',
        'utilService',
        'EntityService',
        'CoachService',
        'PaymentService',
        'constants',
    ];

    function CoachSetupController($routeParams, utilService, EntityService, CoachService, PaymentService, constants) {
        var vm = this;

        vm.loggedInUser = utilService.getLoggedInUserData();
        vm.states = constants.states;
        vm.patterns = constants.enums.patterns;
        vm.coach = {
            additional: {
                ableToGiveDiscount: false,
                complianceCoach: false
            }
        };
        vm.availableEntities = [];
        vm.paymentFlowType = {};
        vm.spinner = {active: false};

        vm.getEntitiesBasics = getEntitiesBasics;
        vm.getEntitiesOfAdmin = getEntitiesOfAdmin;
        vm.createCoach = createCoach;
        vm.checkUserEmail = checkUserEmail;
        vm.getEntities = getEntities;
        vm.changeComplianceCoachIndicator = changeComplianceCoachIndicator;

        vm.getEntities();


        if(vm.loggedInUser.role === 'ADMIN') {
            PaymentService.getPaymentFlowTypeAndIsStripeUsed(vm);
        }

        function getEntities() {
            switch (vm.loggedInUser.role) {
                case 'SUPERUSER':
                    vm.getEntitiesBasics();
                    break;
                case 'ADMIN':
                    vm.getEntitiesOfAdmin();
                    break;
            }
        }

        function getEntitiesBasics() {
            EntityService.getEntitiesBasics().then(function (res) {
                if (res.status === 200) {
                    vm.availableEntities = res.data.entities;

                    vm.coach.entity = vm.availableEntities.find(function (item) {
                        return item.id === parseInt($routeParams.id);
                    });

                }
            });
        }

        function getEntitiesOfAdmin() {
            EntityService.getEntitiesOfAdmin().then(function (res) {
                if (res.status === 200) {
                    vm.availableEntities = res.data.entities;
                    vm.coach.entity = vm.availableEntities[0];
                }
            });
        }

        function createCoach() {
            CoachService.createCoach(vm.coach).then(function (res) {
                if (res.status === 200) {
                    utilService.goTo('/coach-list');
                }
            });
        }

        function checkUserEmail(form) {
            if (vm.coach.email) {
                CoachService.checkUserEmail(vm.coach.email).then(function (res) {
                    form['email'].$setValidity('duplicate', res.status !== 200);
                });
            }
        }

        function changeComplianceCoachIndicator() {
            if (vm.coach.additional.complianceCoach) {
                vm.coach.additional.ableToGiveDiscount = false;
                delete vm.coach.additional.commissionSetUp;
                delete vm.coach.additional.commissionRenew;
                delete vm.coach.additional.setupDiscountPercent;
                delete vm.coach.additional.membershipDiscountPercent;
                delete vm.coach.additional.renewalDiscountPercent;
            }
        }
    }
})();
