import React from 'react';

import { observer } from 'mobx-react';

import classes from './ScholasticCustomerPageTitle.module.scss';
import {
  AddCustomerMenu,
  OptionsMenu,
} from '../../../../shared/components/menu-components';
import { PageTitle } from '../../../../shared/components/ui-components';
import { MenuOptions } from '../../../../general-types';
import { scholasticCustomersListStore } from '../../../stores/ScholasticCustomersListStore';
import { AttachFileOption } from './AttachFileOption/AttachFileOption';

const ScholasticCustomerPageTitle = observer(() => {
  const { downloadReportForAll, sendMessageToAll, selectMethod } =
    scholasticCustomersListStore;
  const { itemCount } = selectMethod;

  const options: MenuOptions[] = [
    { id: 1, label: 'Contact All', onClick: sendMessageToAll },
    { id: 2, label: 'Export in CSV', onClick: downloadReportForAll },
    { id: 3, label: <AttachFileOption /> },
  ];

  return (
    <PageTitle title={`Customer Communication (${itemCount})`}>
      <div className={classes.titleBtnBox}>
        <AddCustomerMenu />
        <OptionsMenu options={options} />
      </div>
    </PageTitle>
  );
});

export { ScholasticCustomerPageTitle };
