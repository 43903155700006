import React from 'react';
import PropTypes from 'prop-types';

import classes from './TableCell.module.scss';

const TableCell = ({ children, position = 'start', className = '' }) => {
  const cellClasses = `${classes.cell} ${classes[position]} ${className}`;

  return <div className={cellClasses}>{children}</div>;
};

TableCell.propTypes = {
  children: PropTypes.node,
  position: PropTypes.oneOf(['start', 'center', 'end', 'between']),
  className: PropTypes.string,
};

export { TableCell };
