import React, { ReactNode } from 'react';

import { SvgIconOwnProps, Tooltip, TooltipProps } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import classes from './InfoTooltip.module.scss';

type InfoTooltipProps = {
  children: ReactNode;
  iconFontSize?: SvgIconOwnProps['fontSize'];
} & Pick<TooltipProps, 'placement'>;

const InfoTooltip = ({
  children,
  placement = 'bottom',
  iconFontSize = 'small',
}: InfoTooltipProps) => {
  return (
    <Tooltip
      title={<div className={classes.textBox}>{children}</div>}
      placement={placement}
    >
      <InfoOutlinedIcon
        fontSize={iconFontSize}
        color="tertiary"
        className={classes.icon}
      />
    </Tooltip>
  );
};

export { InfoTooltip, InfoTooltipProps };
