import React from 'react';
import { angularize } from 'react-in-angularjs';
import { SystemProviders } from '../../providers';

import classes from './NgzPageUnderConstruction.module.scss';

import ReportsSvg from '../../assets/image/under-construction/reports.svg';

const underConstructionImagesMap = {
  reports: ReportsSvg,
};

export type NgzPageUnderConstructionProps = {
  title?: string;
  description?: string;
  imageType: keyof typeof underConstructionImagesMap;
};

const NgzPageUnderConstruction = ({
  title = 'Coming soon!',
  description,
  imageType,
}: NgzPageUnderConstructionProps) => {
  if (!imageType) {
    return null;
  }

  return (
    <SystemProviders>
      <div className={classes.underConstructionFallback}>
        <h3 className={classes.fallbackTitle}>{title}</h3>
        <p className={classes.fallbackDescription}>{description}</p>
        <img src={underConstructionImagesMap[imageType]} alt="under-construction" />
      </div>
    </SystemProviders>
  );
};

angularize(NgzPageUnderConstruction, 'ngzPageUnderConstruction', angular.module('app'), {
  title: '@',
  description: '@',
  imageType: '@',
});

export { NgzPageUnderConstruction };
