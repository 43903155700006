import React from 'react';
import PropTypes from 'prop-types';

import { InputAdornment } from '@mui/material';

import classes from './InventoryInputs.module.scss';
import { LbDatePicker } from '../../../../../../../shared/components/form-components/LbDatePicker/LbDatePicker';
import { LbInput } from '../../../../../../../shared/components/form-components/LbInput/LbInput';

const InventoryInputs = ({ control, dateValue, orderValue }) => {
  return (
    <div className={classes.inputBox}>
      <LbInput
        control={control}
        name="orderAmount"
        label="Amount of Order"
        type="number"
        defaultValue={orderValue}
        InputProps={{
          endAdornment: <InputAdornment position="end">bottle</InputAdornment>,
        }}
        rules={{
          required: 'Amount of Order is required',
        }}
      />
      <LbDatePicker
        control={control}
        name="orderDate"
        label="Date of Order"
        defaultValue={dateValue}
        disablePast
        rules={{
          required: 'Date of Order is required',
        }}
      />
    </div>
  );
};

InventoryInputs.propTypes = {
  control: PropTypes.object,
  dateValue: PropTypes.string,
  orderValue: PropTypes.number,
};

export { InventoryInputs };
