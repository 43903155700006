import React from 'react';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';

import { LbModal } from '../../../../../../../shared/components/modals/LbModal/LbModal';
import { customerActiveCalendarStore } from '../../../../../../stores/customerActiveCalendarStore';

const SubmitModal = observer(({ open, onCloseHandler }) => {
  const { isActiveCalendarCreate } = customerActiveCalendarStore;

  const title = isActiveCalendarCreate
    ? 'The changes you added will take effect tomorrow'
    : 'Congratulations';

  const text = isActiveCalendarCreate
    ? 'Once saved, you can find your last changes in the Tomorrow tab.'
    : 'You have successfully created an active calendar for the user';

  return (
    <LbModal
      open={open}
      onClose={onCloseHandler}
      title={title}
      text={text}
      primaryBtnConfig={{ text: 'Ok', onClick: onCloseHandler }}
    />
  );
});

SubmitModal.propTypes = {
  open: PropTypes.bool,
  onCloseHandler: PropTypes.func,
};

export { SubmitModal };
