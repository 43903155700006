const autoNgTemplateLoaderTemplate1 = require('./supplement-setup-step-2.template.html');

'use strict';

angular
    .module('app')
    .directive('supplementSetupStep2', supplementSetupStep2);

supplementSetupStep2.$inject = [];

function supplementSetupStep2() {
    return {
        restrict: 'E',
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: 'supplementSetupStep2Controller',
        scope: {
            user: '='
        },
        controllerAs: 'vm',
    };
}
