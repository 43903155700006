import React from 'react';
import PropTypes from 'prop-types';

import classes from './TabTitleRow.module.scss';

const TabTitleRow = ({ title, subTitle, children }) => {
  return (
    <div className={classes.tabTitleRow}>
      <div className={classes.titleBox}>
        <h4>{title}</h4>
        {subTitle && <div>{subTitle}</div>}
      </div>
      <div>{children}</div>
    </div>
  );
};

TabTitleRow.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.node,
  children: PropTypes.node,
};

export { TabTitleRow };
