import { useEffect, useState } from 'react';

import {
  EntitySubscriptionInfo,
  CreateEntitySubscriptionInfo,
} from '../../../interfaces/entitySubscription';
import { entitySubscriptionService } from '../../../services/entitySubscriptionService';
import { updateItemByField } from '../../../../shared/utils/arrayUtils';

type UseEntitySubscriptionsReturn = {
  entitySubscriptions: EntitySubscriptionInfo[];
  createEntitySubscription: (subscription: CreateEntitySubscriptionInfo) => Promise<void>;
  changeActivationStatus: (subscriptionId: number, activate: boolean) => Promise<void>;
  changeDisplayToUserStatus: (
    subscriptionId: number,
    displayToUser: boolean,
  ) => Promise<void>;
  getEntitySubscriptions: () => Promise<void>;
};

export const useEntitySubscriptions = (
  entityId: number,
): UseEntitySubscriptionsReturn => {
  const [entitySubscriptions, setEntitySubscriptions] = useState<
    EntitySubscriptionInfo[]
  >([]);

  const getEntitySubscriptions = async () => {
    const response = await entitySubscriptionService.getEntitySubscriptions(entityId);

    setEntitySubscriptions(response);
  };

  useEffect(() => {
    getEntitySubscriptions();
  }, []);

  const createEntitySubscription = async (subscription: CreateEntitySubscriptionInfo) => {
    const newSubscription = await entitySubscriptionService.createEntitySubscription(
      entityId,
      subscription,
    );

    // need use callback to get the latest state
    setEntitySubscriptions((existSubscriptions) => [
      ...existSubscriptions,
      newSubscription,
    ]);
  };

  const changeActivationStatus = async (subscriptionId: number, activate: boolean) => {
    const changedSubscription = await entitySubscriptionService.changeActivationStatus(
      entityId,
      subscriptionId,
      activate,
    );

    setEntitySubscriptions((existSubscriptions) =>
      updateItemByField(existSubscriptions, changedSubscription, { id: subscriptionId }),
    );
  };

  const changeDisplayToUserStatus = async (
    subscriptionId: number,
    displayToUser: boolean,
  ) => {
    const changedSubscription = await entitySubscriptionService.changeDisplayToUserStatus(
      entityId,
      subscriptionId,
      displayToUser,
    );

    setEntitySubscriptions((existSubscriptions) =>
      updateItemByField(existSubscriptions, changedSubscription, { id: subscriptionId }),
    );
  };

  return {
    entitySubscriptions,
    createEntitySubscription,
    changeActivationStatus,
    changeDisplayToUserStatus,
    getEntitySubscriptions,
  };
};
