import React, { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';

import classes from './ReportCheckbox.module.scss';
import { LbCheckbox } from '../../../../../../../shared/components/form-components';

type ReportCheckboxProps = {
  name: string;
  label: ReactNode;
};

const ReportCheckbox = ({ name, label }: ReportCheckboxProps) => {
  const { control } = useFormContext();

  return (
    <LbCheckbox
      control={control}
      color="primary"
      name={name}
      label={label}
      className={classes.checkbox}
    />
  );
};

export { ReportCheckbox };
