// used to get first letter in upper case for groping or to set
export const getFirstLetter = (word: string) => {
  for (let i = 0; i < word.length; i++) {
    const char = word[i];
    if (/[a-zA-Z]/.test(char)) {
      return char.toUpperCase();
    } else if (/[0-9]/.test(char)) {
      return '0-9';
    }
  }
  return '';
};
