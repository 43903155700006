import { useState } from 'react';

export type UseStepperReturn = {
  activeStep: number;
  goToNextStep: () => void;
  goToStep: (num: number) => void;
  goToPreviousStep: () => void;
  skipStep: () => void;
};

export const useStepper = (initialStep: number = 0): UseStepperReturn => {
  const [activeStep, setActiveStep] = useState(initialStep);

  const goToNextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const goToStep = (num: number) => {
    if (activeStep > num) {
      setActiveStep(num);
    }
  };

  const goToPreviousStep = () => {
    goToStep(activeStep - 1);
  };

  const skipStep = () => {
    setActiveStep(activeStep + 2);
  };

  return { activeStep, goToNextStep, goToStep, goToPreviousStep, skipStep };
};
