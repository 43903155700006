import React from 'react';
import PropTypes from 'prop-types';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

import classes from './CheckCirleLabel.module.scss';

const CheckCircleLabel = ({ children, color = 'success' }) => {
  return (
    <div className={classes.titleLabel}>
      <CheckCircleRoundedIcon color={color} fontSize="large" />
      {children}
    </div>
  );
};

CheckCircleLabel.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
};

export { CheckCircleLabel };
