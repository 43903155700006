import React from 'react';
import PropTypes from 'prop-types';

import { SupplementBox } from '../SupplementBox/SupplementBox';

const DayColumn = ({ supplementsForDay, isShowMore }) => {
  const supplementsArr = [];

  supplementsForDay.forEach((supplement, index) => {
    const { amount, supplementId, withFood, scheduleId } = supplement;

    if (isShowMore || index < 3) {
      supplementsArr.push(
        <SupplementBox
          amount={amount}
          supplementId={supplementId}
          withFood={withFood}
          key={scheduleId}
        />,
      );
    }
  });

  return <div>{supplementsArr}</div>;
};

DayColumn.propTypes = {
  supplementsForDay: PropTypes.array,
  isShowMore: PropTypes.bool,
};

export { DayColumn };
