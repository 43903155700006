import { apiService } from '../../shared/services/apiService';
import { MealPlan, MealPlansMacrosInfo } from '../interfaces/MealPlan';
import { PredefinedMealPlansInfo } from '../interfaces/PredefinedMealPlansInfo';

export const mealPlanService = {
  async getPredefinedMealPlans(userId: number) {
    const resp = await apiService.get<PredefinedMealPlansInfo>(
      `/api/coach/user/${userId}/meal-plan/predefined`,
    );

    return resp.data;
  },

  async setPredefinedMealPlans(userId: number, predefinedMealPlanId: number) {
    const resp = await apiService.post<MealPlan>(
      `/api/coach/user/${userId}/meal-plan/${predefinedMealPlanId}`,
    );

    return resp.data;
  },

  async getMealPlans(userId: number) {
    const resp = await apiService.get<MealPlansMacrosInfo>(
      `/api/coach/user/${userId}/mealplan`,
    );

    return resp.data;
  },

  async updateMealPlans(userId: number, mealPlansInfo: MealPlansMacrosInfo) {
    const resp = await apiService.put<MealPlansMacrosInfo>(
      `/api/coach/user/${userId}/mealplan`,
      mealPlansInfo,
    );

    return resp.data;
  },
};
