const autoNgTemplateLoaderTemplate1 = require('./recipe-list.template.html');

'use strict';

angular
    .module('app')
    .directive('recipeList', recipeList);

recipeList.$inject = [];

function recipeList() {
    return {
        restrict: 'E',
        scope: {},
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: 'RecipesController',
        controllerAs: 'vm'
    };
}
