import React from 'react';
import PropTypes from 'prop-types';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BlockIcon from '@mui/icons-material/Block';

import classes from './CheckboxInfoCell.module.scss';
import { MED_TEST_RESULT_STATUSES_LABELS } from '../../../../../../../constants/MED_TEST_RESULT_STATUSES_LABELS';

const CheckboxInfoCell = ({ isChecked, fieldName }) => {
  const checkedIcon = <CheckCircleIcon fontSize="large" color="success" />;
  const unCheckedIcon = <BlockIcon fontSize="large" color="error" />;

  const icon = isChecked ? checkedIcon : unCheckedIcon;
  const text = MED_TEST_RESULT_STATUSES_LABELS[fieldName][isChecked];

  return (
    <div className={classes.container}>
      {icon}
      <p className={classes.text}>{text}</p>
    </div>
  );
};

CheckboxInfoCell.propTypes = {
  isChecked: PropTypes.bool,
  fieldName: PropTypes.string,
};

export { CheckboxInfoCell };
