import React, { useEffect, useState } from 'react';

import { Paper } from '@mui/material';
import { angularize } from 'react-in-angularjs';
import moment from 'moment';

import classes from './NgzActiveCalendarOverviewTab.module.scss';
import { TabTitleRow } from '../../components/TabTitleRow/TabTitleRow';
import { SystemProviders } from '../../../../../shared/providers';
import { OverviewSupplementTable } from './OverviewSupplementTable/OverviewSupplementTable';
import { CalendarColumn } from '../../components/CalendarColumn/CalendarColumn';
import { activeCalendarService } from '../../../../services/activeCalendarService';
import { timeFormat } from '../../../../../shared/constants/apiDateFormat';
import { getSortedArrFromDateGroupedObj } from '../../../../../shared/utils/sortingAndGroupingUtil';
import { getFormattedDate } from '../../../../../shared/utils/dateUtils';
import { getRouteParam } from '../../../../../shared/utils/angularUtils';
import { OverviewPlaceholder } from './OverviewPlaceholder/OverviewPlaceholder';

const { getScheduleByDay, getCustomerSupplements } = activeCalendarService;

const NgzActiveCalendarOverviewTab = () => {
  const [selectedDate, setSelectedDate] = useState(moment());
  const [schedule, setSchedule] = useState([]);
  const [customerSupplements, setCustomerSupplements] = useState({});

  const customerId = getRouteParam('id');

  const getDataForRender = async () => {
    const date = getFormattedDate(selectedDate);

    const [scheduler, customerSupplementsInfo] = await Promise.all([
      getScheduleByDay(customerId, date),
      getCustomerSupplements(customerId, date, date),
    ]);

    const sortedScheduleArr = getSortedArrFromDateGroupedObj(scheduler, timeFormat);

    setCustomerSupplements(customerSupplementsInfo);
    setSchedule(sortedScheduleArr);
  };

  useEffect(() => {
    getDataForRender();
  }, [selectedDate]);

  const isShowPlaceholder = schedule.length === 0;

  const OverviewComponent = (
    <Paper variant="roundedBox" className={classes.box}>
      <CalendarColumn
        color="green"
        date={selectedDate}
        setDate={setSelectedDate}
        descriptionText="100% of tracked supplements on day"
      />
      <OverviewSupplementTable
        schedule={schedule}
        customerSupplements={customerSupplements}
        selectedDate={selectedDate}
      />
    </Paper>
  );

  return (
    <SystemProviders>
      <TabTitleRow title="Overview" />
      {isShowPlaceholder ? <OverviewPlaceholder /> : OverviewComponent}
    </SystemProviders>
  );
};

angularize(
  NgzActiveCalendarOverviewTab,
  'ngzActiveCalendarOverviewTab',
  angular.module('app'),
  {},
);

export default NgzActiveCalendarOverviewTab;
