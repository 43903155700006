import React, { ReactNode } from 'react';

import classes from './ResultTitle.module.scss';
import { TitleWithDescription } from '../../../../../shared/components/text-formation-components';
import SuccessImage from '../../../../../../../assets/images/vectors/success-rounded.svg';
import ErrorImage from '../../../../../../../assets/images/vectors/error-rounded.svg';

type ResultTitleProps = {
  isSuccessful: boolean;
  title: ReactNode;
  description?: ReactNode;
};

const ResultTitle = ({ isSuccessful, title, description }: ResultTitleProps) => {
  const image = isSuccessful ? (
    <img src={SuccessImage} alt="success" />
  ) : (
    <img src={ErrorImage} alt="error" />
  );

  return (
    <div className={classes.container}>
      {image}
      <TitleWithDescription
        title={title}
        description={description}
        titleFont="title-22-bold"
      />
    </div>
  );
};

export { ResultTitle };
