import { makeAutoObservable } from 'mobx';
import { resetAllStore } from '../../shared/stores/resetAllStore';

import { customerTestsService } from '../services/customerTestsService';

class CustomerMedicalTestsStore {
  constructor() {
    this.setDefaultValues();
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.medicalTests = [];
    this.medicalTestForm = null;
    this.customerId = null;
    this.isFormOpen = false;
    this.medTestResultIdForInitialEdit = null;
    this.medTestFieldNameForInitialFocus = null;
  }

  initDataToRender(customerId) {
    this.setCustomerId(customerId);
    this.getTests();
  }

  setCustomerId(customerId) {
    this.customerId = customerId;
  }

  async getTests() {
    const tests = await customerTestsService.getTests(this.customerId);

    this.setMedicalTests(tests);
  }

  setMedicalTests(tests) {
    this.medicalTests = tests;
  }

  async sendTest(testData, testIndex) {
    const test = await customerTestsService.sendOneTest(this.customerId, testData);

    this.processTestIndex(test, testIndex);

    this.setIsFormOpen(false);
  }

  processTestIndex(test, testIndex) {
    if (testIndex >= 0) {
      this.updateMedicalTest(test, testIndex);
    } else {
      this.addNewMedicalTest(test);
    }
  }

  updateMedicalTest(test, testIndex) {
    const newMedicalTests = [...this.medicalTests];
    newMedicalTests[testIndex] = test;
    this.setMedicalTests(newMedicalTests);
  }

  addNewMedicalTest(test) {
    const newMedicalTests = [test, ...this.medicalTests];

    this.setMedicalTests(newMedicalTests);
  }

  setIsFormOpen(isFormOpen) {
    this.isFormOpen = isFormOpen;
  }

  async deleteTest(testId, testIndex) {
    await customerTestsService.deleteTest(this.customerId, testId);

    this.updateTestsAfterDeleting(testIndex);
  }

  updateTestsAfterDeleting(testIndex) {
    const newMedicalTests = [...this.medicalTests];
    newMedicalTests.splice(testIndex, 1);

    this.setMedicalTests(newMedicalTests);
  }

  addNewTestForm({ id, testName }) {
    const newTest = {
      testName,
      testID: id,
      results: [{ uploadedTestFiles: [] }],
    };

    this.setMedicalTestForm(newTest);
    this.setIsFormOpen(true);
  }

  setMedicalTestForm(test) {
    this.medicalTestForm = test;
  }

  cleanMedicalTestForm() {
    this.setMedicalTestForm(null);
  }

  setMedTestResultIdForInitialEdit(testResultId) {
    this.medTestResultIdForInitialEdit = testResultId;
  }

  setMedTestFieldNameForInitialFocus(fieldName) {
    this.medTestFieldNameForInitialFocus = fieldName;
  }
}

const customerMedicalTestsStore = new CustomerMedicalTestsStore();

resetAllStore.addResetMethod(customerMedicalTestsStore.setDefaultValues);

export { CustomerMedicalTestsStore, customerMedicalTestsStore };
