import { useMemo } from 'react';

import { isDataBefore, isDayAfter } from '../../../../../../shared/utils/dateUtils';

export type UseScanDataOptionsReturn = {
  startScanDateOptions: string[];
  endScanDateOptions: string[];
};

export type UseScanDataOptionsProps = {
  scanDateTimes: string[];
  startScanDate: string;
  endScanDate: string;
};

export const useScanDataOptions = ({
  scanDateTimes,
  startScanDate,
  endScanDate,
}: UseScanDataOptionsProps): UseScanDataOptionsReturn => {
  const timeOptions = useMemo(
    () => [...scanDateTimes].sort((a, b) => (isDayAfter(a, b) ? -1 : 1)),
    [scanDateTimes],
  );

  const startScanDateOptions = useMemo(() => {
    if (!endScanDate) {
      return timeOptions.slice(1);
    }

    return timeOptions.filter((date) => isDataBefore(date, endScanDate));
  }, [timeOptions, endScanDate]);

  const endScanDateOptions = useMemo(() => {
    if (!startScanDate) {
      return timeOptions;
    }
    return timeOptions.filter((date) => isDayAfter(date, startScanDate));
  }, [timeOptions, startScanDate]);

  return { startScanDateOptions, endScanDateOptions };
};
