import React from 'react';

import { RadioGroup } from '@mui/material';
import { useAsync } from 'react-use';

import classes from './SelectReactivationSubscriptionModal.module.scss';
import { LbBigModal } from '../../../../../shared/components/modals';
import { customerSubscriptionService } from '../../../../services/customerSubscriptionService';
import { SubscriptionCard } from '../../../../components/SubscriptionRow/SubscriptionCard';

type SelectReactivationSubscriptionModalProps = {
  customerId: number;
  onClose: () => void;
  onConfirm: (subscriptionId: number) => void;
};

const description = `Upon confirmation, the user will receive an email containing a payment link.
Once the payment is made, access to the application will be resumed.`;

// TODO refactor find better name
const SelectReactivationSubscriptionModal = ({
  customerId,
  onClose,
  onConfirm,
}: SelectReactivationSubscriptionModalProps) => {
  const [selectedSubscriptionId, setSelectedSubscriptionId] = React.useState<
    number | null
  >(null);

  const {
    value: subscriptions = [],
    loading,
    error,
  } = useAsync(
    () => customerSubscriptionService.getExplorePlans(customerId),
    [customerId],
  );

  const handleConfirm = () => {
    if (selectedSubscriptionId) {
      onConfirm(selectedSubscriptionId);
      onClose();
    }
  };

  return (
    <LbBigModal
      open={!loading && !error}
      title="Explore Plans"
      description={description}
      buttonOptions={{
        yesText: 'Confirm & Send the Payment Link via email',
        noText: 'Cancel',
        yesHandler: handleConfirm,
        noHandler: onClose,
        yesDisabled: !selectedSubscriptionId,
      }}
    >
      <RadioGroup value={selectedSubscriptionId} className={classes.subscriptionsBox}>
        {subscriptions.map((subscription) => (
          <SubscriptionCard
            key={subscription.id}
            subscription={subscription}
            checked={subscription.id === selectedSubscriptionId}
            onClick={setSelectedSubscriptionId}
          />
        ))}
      </RadioGroup>
    </LbBigModal>
  );
};

export { SelectReactivationSubscriptionModal };
