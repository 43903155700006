import React from 'react';

import { angularize } from 'react-in-angularjs';

import classes from './NgzSchoolsListTab.module.scss';
import { LbInput } from '../../../../../shared/components/form-components';
import { LbModal } from '../../../../../shared/components/modals/LbModal/LbModal';
import { SystemProviders } from '../../../../../shared/providers';
import { useScholasticSchool } from './hooks/useScholasticSchool';
import { SchoolsHeaderRow } from './SchoolsHeaderRow/SchoolsHeaderRow';
import { SchoolsTitle } from './SchoolsTitle/SchoolsTitle';
import { SchoolInfoRow } from './SchoolInfoRow/SchoolInfoRow';
import { useSort } from '../../../../../shared/hooks/useSort';

const NgzSchoolsListTab = () => {
  const {
    schoolsList,
    isOpenModalSchool,
    editSchoolId,
    control,
    handleFormSubmit,
    handleSchoolActiveStatusChange,
    handleCloseModalSchool,
    openAddNewSchoolModal,
    openEditSchoolModal,
  } = useScholasticSchool();

  const { sortedData: sortedSchoolsList, sortMethods } = useSort({
    data: schoolsList,
    sortTypeConfig: { name: 'STRING', amountOfCustomers: 'NUMBER' },
  });

  const validateIsSchoolAlreadyExist = (newSchoolName: string) => {
    const isExist = schoolsList.some((school) => school.name === newSchoolName);

    return !isExist || 'School already exist';
  };

  return (
    <SystemProviders>
      <SchoolsTitle onShowModalSchool={openAddNewSchoolModal} />
      <div className={classes.schoolsTable}>
        <SchoolsHeaderRow sortMethods={sortMethods} />
        {sortedSchoolsList.map((school) => (
          <SchoolInfoRow
            onActivationStatusChange={handleSchoolActiveStatusChange}
            onEdit={openEditSchoolModal}
            schoolInfo={school}
            key={school.id}
          />
        ))}
      </div>
      <LbModal
        open={isOpenModalSchool}
        title={editSchoolId ? 'Edit School' : 'Add New School'}
        primaryBtnConfig={{ text: 'Confirm', onClick: handleFormSubmit }}
        secondaryBtnConfig={{ onClick: handleCloseModalSchool }}
      >
        <LbInput
          name="name"
          label="School Name"
          control={control}
          rules={{ validate: validateIsSchoolAlreadyExist }}
        />
      </LbModal>
    </SystemProviders>
  );
};

angularize(NgzSchoolsListTab, 'ngzSchoolListTab', angular.module('app'), {});
