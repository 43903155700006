/* eslint-disable no-constant-condition */
import { makeAutoObservable } from 'mobx';
import moment from 'moment';

import { dateFormat } from '../../shared/constants/apiDateFormat';
import { resetAllStore } from '../../shared/stores/resetAllStore';
import {
  isDayAfter,
  isDayAfterOrSame,
  isInMonth,
  isTomorrow,
} from '../../shared/utils/dateUtils';
import { getSortedArrByDate } from '../../shared/utils/sortingAndGroupingUtil';
import { waterGoalService } from '../services/waterGoalService';

class CustomerWaterGoalStore {
  constructor() {
    this.setDefaultValues();
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.dailyGoals = [];
    this.trackedDailyGoal = [];
    this.startGoalDate = null;
    this.monthScore = null;
    this.waterCalendar = [];
    this.successfulDays = [];
  }

  async initDateToRender(customerId, selectedMonth) {
    const data = await waterGoalService.getTrackedGoals(customerId, selectedMonth);

    this.setInitDateToRender(data);
    this.addSuccessfulDays();
    this.createWaterCalendar(selectedMonth);
  }

  setInitDateToRender(data) {
    this.setDailyGoals(data.dailyGoals);
    this.setTackedDailyGoal(data.trackedDailyGoal);
    this.setStartGoalDate(data.startDateOfInitialDailyGoal);
    this.setMonthScore(data.scorePerMonth);
  }

  setDailyGoals(dailyGoals) {
    this.dailyGoals = dailyGoals;
  }

  setTackedDailyGoal(trackedDailyGoal) {
    this.trackedDailyGoal = trackedDailyGoal;
  }

  setStartGoalDate(startGoalDate) {
    this.startGoalDate = startGoalDate;
  }

  setMonthScore(monthScore) {
    if (typeof monthScore === 'number') {
      this.monthScore = monthScore.toFixed(1);
    } else {
      this.monthScore = 0;
    }
  }

  addSuccessfulDays() {
    const successfulDays = this.trackedDailyGoal.filter((day) => day.totalScore >= 4.5);

    const successfulDaysDate = successfulDays.map((day) => day.date);

    this.setSuccessfulDays(successfulDaysDate);
  }

  setSuccessfulDays(successfulDays) {
    this.successfulDays = successfulDays;
  }

  createWaterCalendar(selectedMonth) {
    const calendarArr = this.getCalendarArr(selectedMonth);

    const calendarWithGoalRow = this.setChangeGoalRow(calendarArr);
    const reverseCalendar = calendarWithGoalRow.reverse();
    this.setWaterCalendar(reverseCalendar);
  }

  getCalendarArr(selectedMonth) {
    const calendarArr = [];

    for (let i = 0; true; i++) {
      const day = this.getDayByIndex(i, selectedMonth);

      if (!isInMonth(day, selectedMonth) || isTomorrow(day)) {
        break;
      }

      this.pushDayToCalendar(day, calendarArr);
    }

    return calendarArr;
  }

  getDayByIndex(index, selectedMonth) {
    const firstDay = this.getFirstDay(selectedMonth);

    return firstDay.add(index, 'days').format(dateFormat);
  }

  getFirstDay(selectedMonth) {
    if (isDayAfter(selectedMonth, this.startGoalDate)) {
      return moment(selectedMonth);
    } else {
      return moment(this.startGoalDate);
    }
  }

  pushDayToCalendar(day, calendarArr) {
    const trackedDay = this.getTrackedDayByDate(day);

    if (trackedDay) {
      calendarArr.push(trackedDay);
    } else {
      this.pushFakeDay(day, calendarArr);
    }
  }

  pushFakeDay(day, calendarArr) {
    const dailyGoalID = this.getDailyGoalIdByFakeDay(day);

    const fakeDay = {
      date: day,
      dailyGoalID,
      totalScore: 0,
      consumed: 0,
    };

    calendarArr.push(fakeDay);
  }

  getTrackedDayByDate(date) {
    return this.trackedDailyGoal.find((day) => day.date === date);
  }

  getDailyGoalIdByFakeDay = (dayDate) => {
    const sortedDailyGoals = getSortedArrByDate(this.dailyGoals, 'startDate');

    const dailyGoalId = sortedDailyGoals.reduce((goalId, day) => {
      const isAfterOrSameDay = isDayAfterOrSame(dayDate, day.startDate);

      if (isAfterOrSameDay) {
        goalId = day.dailyGoalID;
      }

      return goalId;
    }, 0);

    return dailyGoalId;
  };

  setChangeGoalRow = (calendarArr) => {
    const resultCalendar = [...calendarArr];

    this.dailyGoals.forEach(({ startDate, targetGoal }) => {
      const goalRowObj = { isGoalRow: true, startDate, targetGoal };

      const dayIndex = resultCalendar.findIndex((day) => day.date === startDate);

      if (dayIndex >= 0) {
        resultCalendar.splice(dayIndex, 0, goalRowObj);
      } else {
        resultCalendar.push(goalRowObj);
      }
    });

    return resultCalendar;
  };

  setWaterCalendar(waterCalendar) {
    this.waterCalendar = waterCalendar;
  }

  getTargetGoalById(dailyGoalId) {
    const goal = this.dailyGoals.find((day) => day.dailyGoalID === dailyGoalId);
    return goal?.targetGoal;
  }

  setChangeWaterGoal = (newGoal, selectedMonth) => {
    this.setNewDailyGoals(newGoal);

    this.createWaterCalendar(selectedMonth);
  };

  setNewDailyGoals(newGoal) {
    const newDailyGoals = [...this.dailyGoals];

    const index = newDailyGoals.findIndex(
      (day) => day.dailyGoalID === newGoal.dailyGoalID,
    );

    if (index !== -1) {
      newDailyGoals[index] = newGoal;
    } else {
      newDailyGoals.push(newGoal);
    }

    this.setDailyGoals(newDailyGoals);
  }
}

const customerWaterGoalStore = new CustomerWaterGoalStore();

resetAllStore.addResetMethod(customerWaterGoalStore.setDefaultValues);

export { CustomerWaterGoalStore, customerWaterGoalStore };
