const autoNgTemplateLoaderTemplate1 = require('./pre-check-in.template.html');

'use strict';

(function () {
    angular
        .module('app.preCheckIn', [])
        .config(preCheckInConfig);

    preCheckInConfig.$inject = ['$routeProvider'];

    function preCheckInConfig($routeProvider) {
        $routeProvider
            .when('/pre-check-in', {
                disableCache: true,
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'PreCheckInController',
                controllerAs: 'vm',
                reloadOnSearch:false,
                resolve: {
                    loginRequired: ['AuthService', function (AuthService) {
                        return AuthService.loginRequired();
                    }]
                }
            })
    }
})();
