import { Components } from '@mui/material';

// if change check style for autocomplete and select
export const MuiFormControlTheme: Components['MuiFormControl'] = {
  defaultProps: {
    variant: 'standard',
  },
  styleOverrides: {
    root: {
      '.MuiFormLabel-asterisk': {
        color: 'var(--error-300)',
      },
      '.MuiInputAdornment-root .MuiIconButton-root': {
        margin: '0',
      },
      '.MuiInputAdornment-root .MuiTypography-root': {
        color: 'var(--natural-600)',
        font: 'inherit',
      },
    },
  },
  variants: [
    {
      props: { variant: 'standard' },
      style: {
        '& .MuiInput-root::before': {
          borderColor: 'var(--natural-300)',
        },
        '&.MuiFormControl-root:hover  .MuiInput-root::before': {
          borderBottom: '1px solid',
          borderBottomColor: 'var(--primary-500)',
        },
        '.Mui-error.MuiInput-root::before': {
          borderBottomColor: 'var(--error-300) !important',
        },
        '.Mui-disabled.MuiInput-root::before': {
          display: 'none',
        },
        '.MuiInput-root::after': {
          display: 'none',
          borderBottom: '1px solid',
          borderBottomColor: 'var(--primary-500)',
          transform: 'scaleX(1)',
        },
        '.Mui-focused.MuiInput-root::after': {
          display: 'block',
        },
        '.Mui-error.MuiInput-root::after': {
          borderBottom: '1px solid',
          borderBottomColor: 'var(--error-300)',
        },
        '.MuiInputBase-root': {
          font: 'inherit',
        },
        '.MuiInputLabel-root': {
          font: 'inherit',
          color: 'var(--natural-600)',
        },
        '.MuiInputLabel-root.Mui-error': {
          color: 'var(--error-300)',
        },
        '.MuiInputLabel-root.Mui-focused': {
          color: 'var(--natural-600)',
        },
        '.MuiFormHelperText-root': {
          font: 'var(--body-12-medium)',
          paddingBottom: '3px',
        },
        '.MuiFormHelperText-root.Mui-error': {
          color: 'var(--error-300)',
        },
        // if change also change in autocomplete theme
        '.MuiInput-input': {
          padding: '4px 0',
          height: 'auto',
        },
        '.MuiAutocomplete-input': {
          padding: '4px 0 !important',
        },
      },
    },
    {
      props: { variant: 'standard', margin: 'normal' }, // use to set label space if no label
      style: {
        marginTop: '16px', // margin same as used to save space for label
        marginBottom: 0,
      },
    },
    {
      props: { variant: 'standard', size: 'medium' },
      style: {
        font: 'var(--title-16-medium)',
      },
    },
    {
      props: { variant: 'standard', size: 'small' },
      style: {
        font: 'var(--body-14-medium)',

        '.MuiInputLabel-root': {
          top: '3px',
        },
      },
    },

    {
      props: { variant: 'outlined' },
      style: {
        '.MuiFormHelperText-root': {
          font: 'var(--body-12-medium)',
          margin: 0,
          position: 'absolute',
          left: 0,
          top: '100%',
        },
        '.MuiInputLabel-root': {
          color: 'var(--natural-600)',
        },
        '.MuiOutlinedInput-root': {
          color: 'var(--main-black)',
        },
        '.MuiOutlinedInput-root:hover fieldset': {
          borderColor: 'var(--primary-500)',
        },
        '.MuiOutlinedInput-input': {
          height: 'auto',
          padding: '0',
        },
        // fix padding for icon in select and autocomplete
        '.MuiOutlinedInput-input.MuiAutocomplete-input, .MuiOutlinedInput-input.MuiSelect-select ':
          {
            padding: ' 0 18px 0 0 !important',
          },
        '.MuiInputAdornment-positionEnd': {
          marginLeft: '4px',
        },
        '.MuiInputAdornment-positionStart': {
          marginRight: '4px',
        },
        fieldset: {
          borderColor: 'var(--natural-300)',
        },
        '.Mui-disabled': {
          'fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: 'var(--natural-300)',
          },
          '&.MuiInputLabel-root': {
            color: 'var(--natural-400)',
          },
        },
      },
    },
    {
      props: { variant: 'outlined', size: 'medium' },
      style: {
        '.MuiInputLabel-root': {
          font: 'var(--body-14-medium)',
          transform: 'translate(14px, 12px) scale(1)',
        },
        '.MuiInputLabel-shrink': {
          transform: 'translate(14px, -8px) scale(0.85)', // scale percent off font decrease
        },
        legend: {
          fontSize: '0.85em', // scale number
        },
        '.MuiOutlinedInput-root': {
          padding: '12px 14px',
          font: 'var(--body-14-medium)',
        },
        // fix autocomplete style
        '.MuiAutocomplete-inputRoot.MuiOutlinedInput-root': {
          padding: '12px 14px',
        },
      },
    },
    {
      props: { variant: 'outlined', size: 'small' },
      style: {
        '.MuiInputLabel-root': {
          font: 'var(--body-14-medium)',
          transform: 'translate(12px, 9px) scale(1)',
        },
        '.MuiInputLabel-shrink': {
          transform: 'translate(12px, -9px) scale(0.85)',
        },
        fieldset: {
          padding: '0 7.5px',
        },
        legend: {
          fontSize: '0.85em', // scale number
        },
        '.MuiOutlinedInput-root': {
          padding: '9px 12px',
          font: 'var(--body-14-medium)',
        },
        '.MuiAutocomplete-inputRoot.MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
          padding: '9px 12px',
        },
      },
    },
  ],
};
