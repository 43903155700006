import { apiService } from '../../shared/services/apiService';
import { SendMessageData } from '../interfaces/SendMessageData';
import { NotGotMessageUser } from '../interfaces/NotGotMessageUser';

type SendMessageResponse = {
  sent: NotGotMessageUser[];
};

export const communicationService = {
  async sendMessage(messageData: SendMessageData) {
    const resp = await apiService.post<SendMessageResponse>(
      '/api/data-filters/communication/message',
      messageData,
    );

    return resp.data;
  },
};
