import { useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';

import { PromoCodeSubscription } from '../../../../interfaces/promoCodeInfo';
import { promoCodesService } from '../../../../services/promoCodesService';

export const usePromoCodeSubscription = (entityId: number) => {
  const [subscriptions, setSubscriptions] = useState<PromoCodeSubscription[]>([]);

  const [fetchSubscriptionsState, fetchSubscriptions] = useAsyncFn(async () => {
    const response = await promoCodesService.getSubscriptions(entityId);

    setSubscriptions(response);
  });

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  return { subscriptions, fetchSubscriptionsLoading: fetchSubscriptionsState.loading };
};
