import React from 'react';
import PropTypes from 'prop-types';

import {
  GridTableCell,
  GridTableRow,
} from '../../../../shared/components/table-components';
import { NutrientValues } from '../../NutrientValues/NutrientValues';
import { NUTRIENTS_ENUM } from '../../../../shared/constants/NUTRIENTS_ENUM';

const MealInfoRow = ({ titleText, mealInfo, withPoint = true }) => {
  return (
    <GridTableRow variant="subRow">
      <GridTableCell variant="title">{titleText}</GridTableCell>
      <span>{mealInfo?.amount}</span>
      <span>{mealInfo?.measurement}</span>
      <NutrientValues
        excludeNutrients={[NUTRIENTS_ENUM.WATER]}
        content={mealInfo}
        iconShown={withPoint}
        coloredText={!withPoint}
      />
    </GridTableRow>
  );
};

MealInfoRow.propTypes = {
  mealInfo: PropTypes.object.isRequired,
  titleText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  withPoint: PropTypes.bool,
};

export { MealInfoRow };
