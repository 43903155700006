import React, { useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useAsyncFn } from 'react-use';
import { observer } from 'mobx-react';

import classes from './CreateEntitySubscriptionModal.module.scss';
import { LbBigModal } from '../../../shared/components/modals';
import { LbInput } from '../../../shared/components/form-components';
import { CreateEntitySubscriptionInfo } from '../../interfaces/entitySubscription';
import { SubscriptionTypeBox } from './components/SubscriptionTypeBox/SubscriptionTypeBox';
import { InitialPlanPricingForm } from './containers/InitialPlanPricingForm/InitialPlanPricingForm';
import { MonthlyRenewalPricingForm } from './containers/MonthlyRenewalPricingForm/MonthlyRenewalPricingForm';
import { CreateEntitySubscriptionFormValues } from './CreateEntitySubscriptionModal.settings';
import { usePaymentDetails } from './hooks/usePaymentDetails';
import { getSubscriptionInfoFromFormValue } from './utils/getSubscriptionInfoFromFormValue';
import { useFormChange } from '../../../shared/hooks/useFormChange';
import { loggedInUserDataStore } from '../../../shared/stores/loggedInUserDataStore';
import {
  SubscriptionSettings,
  CreateSubscriptionSettingsInfo,
} from '../../interfaces/subscriptionSettings';
import { processFormDefaultValues } from './utils/processFormDefaultValues';

type CreateEntitySubscriptionModalProps = {
  onClose: () => void;
  onSubmit: (data: CreateEntitySubscriptionInfo) => Promise<void>;
  entityId: number;
  subscriptionSettings?: SubscriptionSettings | CreateSubscriptionSettingsInfo | null;
};

// TODO look if better make api call from here not from parent because of Spinner control
// Can not use isLoading from apiStatusStore because call for payment details triggered it too
// Can not use shouldUnregister because watch is not working properly

const CreateEntitySubscriptionModal = observer(
  ({
    onClose,
    onSubmit,
    entityId,
    subscriptionSettings,
  }: CreateEntitySubscriptionModalProps) => {
    const { isSuperAdmin } = loggedInUserDataStore;
    const { control, handleSubmit, watch, resetField } =
      useForm<CreateEntitySubscriptionFormValues>({
        defaultValues: processFormDefaultValues(subscriptionSettings),
      });

    // Reset subscriptionPrice when subscriptionType changes
    const subscriptionType = watch('subscriptionType');

    useEffect(() => {
      resetField('subscriptionPrice');
    }, [subscriptionType]);

    const handleFormSubmit = handleSubmit(async (data) => {
      const subscriptionInfo = getSubscriptionInfoFromFormValue(data);

      await onSubmit(subscriptionInfo);
      onClose();
    });

    const [state, createSubscription] = useAsyncFn(handleFormSubmit);

    const {
      initialPlanPricingPaymentDetail,
      monthlyRenewalPaymentDetail,
      getPaymentDetails,
      isLoading: isLoadingPaymentDetails,
    } = usePaymentDetails(entityId);

    const handleGetPaymentDetails = async (data: CreateEntitySubscriptionFormValues) => {
      const { name, ...paymentInfo } = getSubscriptionInfoFromFormValue(data);

      await getPaymentDetails(paymentInfo);
    };

    useFormChange({
      watch,
      onChange: handleGetPaymentDetails,
      ignoreFields: ['name'],
    });

    return (
      <LbBigModal
        open
        title="New Subscription"
        buttonOptions={{
          yesText: 'Save',
          noText: 'Cancel',
          noHandler: onClose,
          yesHandler: createSubscription,
        }}
        isLoading={state.loading}
      >
        <form className={classes.modalContent}>
          <LbInput
            control={control}
            name="name"
            label="Name"
            rules={{ required: 'Name is required' }}
            variant="outlined"
            size="small"
          />
          <SubscriptionTypeBox
            control={control}
            subscriptionType="REGULAR"
            title="Regular Plan (for new users)"
            description="Split the full bundle price over several monthly payments, including one-time payment"
          >
            <InitialPlanPricingForm
              control={control}
              paymentDetails={initialPlanPricingPaymentDetail}
              isLoadingPaymentDetails={isLoadingPaymentDetails}
              feesEditMode={isSuperAdmin}
            />
            <MonthlyRenewalPricingForm
              control={control}
              paymentDetails={monthlyRenewalPaymentDetail}
              isLoadingPaymentDetails={isLoadingPaymentDetails}
              feesEditMode={isSuperAdmin}
            />
          </SubscriptionTypeBox>
          <SubscriptionTypeBox
            control={control}
            subscriptionType="REACTIVATION"
            title="Reactivation (for existing, non-active users)"
            description="Use this to give existing, non-active, users access to the LifeBase platform again"
          >
            <MonthlyRenewalPricingForm
              control={control}
              paymentDetails={monthlyRenewalPaymentDetail}
              isLoadingPaymentDetails={isLoadingPaymentDetails}
              feesEditMode={isSuperAdmin}
            />
          </SubscriptionTypeBox>
        </form>
      </LbBigModal>
    );
  },
);

export { CreateEntitySubscriptionModal };
