import React from 'react';

import { AdapterMoment as DateAdapter } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  FieldValues,
  Path,
  PathValue,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import moment from 'moment';
import { DesktopTimePicker } from '@mui/x-date-pickers';
import { TextFieldProps } from '@mui/material';

import classes from './LbTimePicker.module.scss';
import { addTimeIsValidRule } from './LbTimePicker.settings';
import { timeFormat } from '../../../constants/apiDateFormat';

type LbTimePickerProps<T extends FieldValues, TName extends Path<T>> = {
  format?: string;
} & UseControllerProps<T, TName> &
  Pick<
    TextFieldProps,
    'label' | 'variant' | 'size' | 'helperText' | 'placeholder' | 'disabled' | 'margin'
  >;

const LbTimePicker = <T extends FieldValues, TName extends Path<T>>({
  control,
  name,
  rules,
  defaultValue = '' as PathValue<T, TName>,
  label,
  format = timeFormat,
  variant,
  helperText,
  placeholder,
  size,
  disabled,
  margin,
}: LbTimePickerProps<T, TName>) => {
  const {
    field: { value, onChange, onBlur, ref },
    fieldState: { error },
  } = useController<any>({
    control,
    name,
    rules: addTimeIsValidRule<T, TName>(rules, format),
    defaultValue,
  });

  const isRequired = !!rules?.required;

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <DesktopTimePicker
        label={label}
        disabled={disabled}
        value={moment(value, format)}
        onChange={(time) => onChange(moment(time).format(format))}
        onClose={onBlur}
        inputRef={ref}
        slotProps={{
          textField: {
            variant,
            onBlur,
            error: !!error,
            helperText: error?.message || helperText,
            fullWidth: true,
            size,
            placeholder,
            required: isRequired,
            margin,
          },
          actionBar: { className: classes.actionBar },
          digitalClockSectionItem: {
            className: classes.digitalClockSectionItem,
            disableRipple: true,
          },
        }}
      />
    </LocalizationProvider>
  );
};

export { LbTimePicker };
