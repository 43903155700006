import React, { useEffect, useState } from 'react';

import { angularize } from 'react-in-angularjs';
import { observer } from 'mobx-react';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

import classes from './NgzEntityAccountIssueInfo.module.scss';
import { loggedInUserDataStore } from '../../stores/loggedInUserDataStore';
import { SystemProviders } from '../../providers';
import { corporateUsersService } from '../../services/corporateUsersService';

// used for old flow, delete when old flow entity will not exist
const NgzEntityAccountIssueInfo = observer(() => {
  const [corporateAccountsInfo, setCorporateAccountsInfo] = useState({});
  const { loggedInUser, isEntity } = loggedInUserDataStore;

  const isSubscriptionNotActive = !loggedInUser?.entity?.activeSubscription;

  const getCorporateAccountsInfo = async () => {
    const { corporateAccounts } = await corporateUsersService.getNumberOfCorporateUsers();

    setCorporateAccountsInfo(corporateAccounts);
  };

  useEffect(() => {
    if (isSubscriptionNotActive && isEntity) {
      getCorporateAccountsInfo();
    }
  }, [loggedInUser]);

  const isShowEntityAccountIssueInfo =
    isEntity && isSubscriptionNotActive && corporateAccountsInfo.used;

  if (!isShowEntityAccountIssueInfo) {
    return null;
  }

  return (
    <SystemProviders>
      <div className={classes.errorMessage}>
        <WarningAmberRoundedIcon fontSize="medium" />
        <p>
          There is an issue with your Account. Your Entity Subscription Plan or credit
          card has expired. Your clients can’t access the Platform. Please update your
          payment information or contact your Super Admin to resolve the issue.
        </p>
        <a href="#!/entity-profile" className={classes.link}>
          <EditRoundedIcon fontSize="medium" />
          <span>Fix Issue</span>
        </a>
      </div>
    </SystemProviders>
  );
});

angularize(
  NgzEntityAccountIssueInfo,
  'ngzEntityAccountIssueInfo',
  angular.module('app'),
  {},
);

export { NgzEntityAccountIssueInfo };
