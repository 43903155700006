import React from 'react';
import PropTypes from 'prop-types';

import { Paper } from '@mui/material';

import classes from './OrderInventoryTable.module.scss';
import { InitialOrderStepFormRow } from './InitialOrderStepFormRow/InitialOrderStepFormRow';

const OrderInventoryTable = ({ orderInventory, nameIndex }) => {
  const InventoryTable = orderInventory?.map((item, index) => {
    const { supplementId, supplementName, supplementBrand, amountUnit } =
      item.supplementJson;

    return (
      <InitialOrderStepFormRow
        key={supplementId}
        nameIndex={`${nameIndex}.${supplementId}.`}
        supplementName={supplementName}
        brand={supplementBrand}
        amountUnit={amountUnit}
        fullPackageSize={item.fullPackageSize}
        supplementId={supplementId}
        isAutoFocus={index === 0}
      />
    );
  });

  return (
    <>
      <div className={classes.titleRow}>
        <p>Supplement</p>
        <p>Units / Container</p>
        <p>Beginning Inventory</p>
        <p>Order Date</p>
      </div>
      <Paper variant="box" className={classes.formBox}>
        {InventoryTable}
      </Paper>
    </>
  );
};

OrderInventoryTable.propTypes = {
  orderInventory: PropTypes.array.isRequired,
  nameIndex: PropTypes.string,
};

export { OrderInventoryTable };
