import React, { forwardRef } from 'react';

import { Alert, AlertProps } from '@mui/material';
import classNames from 'classnames';

import classes from './LbAlert.module.scss';

type LbAlertProps = { size?: 'small' | 'medium' } & AlertProps;

const LbAlert = forwardRef<HTMLDivElement, LbAlertProps>(
  ({ children, size = 'medium', className, ...props }, ref) => {
    const alertClasses = classNames(classes[size], className);

    return (
      <Alert ref={ref} {...props} className={alertClasses}>
        {children}
      </Alert>
    );
  },
);

export { LbAlert, LbAlertProps };
