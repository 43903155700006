import { useMemo, useState } from 'react';

import { enqueueSnackbar } from 'notistack';

import { CustomerSubscription } from '../interfaces/customerSubscription';
import { PromoCodeInfo } from '../interfaces/promoCodeInfo';
import { SUBSCRIPTION } from '../../shared/constants/user-info/subscription';

type ApplyPromoCodeInfo = {
  subscriptions: CustomerSubscription[];
  promocode: PromoCodeInfo;
};

export type UseSelectSubscriptionsReturn = {
  subscriptions: CustomerSubscription[];
  discountPercent: number;
  selectedSubscription: CustomerSubscription | undefined;
  isFreeAccessSubscription: boolean;
  applyPromoCode: (promoCode: string) => void;
  setSubscriptions: (subscriptions: CustomerSubscription[]) => void;
  setSelectedSubscriptionId: (selectedSubscriptionId: number) => void;
};

type UseSelectSubscriptionProps = {
  onApplyPromoCode: (promoCode: string) => Promise<ApplyPromoCodeInfo>;
};

export const useSelectSubscription = ({
  onApplyPromoCode,
}: UseSelectSubscriptionProps): UseSelectSubscriptionsReturn => {
  const [subscriptions, setSubscriptions] = useState<CustomerSubscription[]>([]);
  const [discountPercent, setDiscountPercent] = useState<number>(0);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<number | null>(
    null,
  );

  const applyPromoCode = async (promoCode: string) => {
    const response = await onApplyPromoCode(promoCode);

    setSubscriptions(response.subscriptions);
    setDiscountPercent(response.promocode.discountPercent);

    enqueueSnackbar('Promo code successfully applied', {
      variant: 'success',
      autoHideDuration: 3000,
    });
  };

  const selectedSubscription = useMemo(
    () =>
      subscriptions.find((subscription) => subscription.id === selectedSubscriptionId),
    [selectedSubscriptionId, subscriptions],
  );

  const isFreeAccessSubscription =
    selectedSubscription?.subscriptionType === SUBSCRIPTION.FREE_ACCESS;

  return {
    subscriptions,
    discountPercent,
    selectedSubscription,
    isFreeAccessSubscription,
    applyPromoCode,
    setSubscriptions,
    setSelectedSubscriptionId,
  };
};
