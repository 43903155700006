import { apiService } from '../../shared/services/apiService';
import {
  CurrentSubscriptionInfo,
  CustomerSubscription,
} from '../interfaces/customerSubscription';

// check name customerSubscriptionService, subscriptionManagmantService ??

type CancelSubscriptionResponse = {
  success: true;
  effectedTransaction: 1;
};

export const customerSubscriptionService = {
  async getCurrentSubscriptionInfo(customerId: number) {
    const resp = await apiService.get<CurrentSubscriptionInfo>(
      `/api/user/${customerId}/subscriptions/current`,
    );

    return resp.data;
  },

  async cancelSubscription(subscriptionTransactionId: number) {
    const resp = await apiService.put<CancelSubscriptionResponse>(
      `/api/coach/cancelrenewal/${subscriptionTransactionId}`,
    );

    return resp.data;
  },

  async getExplorePlans(customerId: number) {
    const resp = await apiService.get<CustomerSubscription[]>(
      `/api/user/${customerId}/explore-plans/all`,
    );

    return resp.data;
  },

  // setup new subscription for customer that he need to pay
  async setupExplorePlan(customerId: number, subscriptionId: number) {
    const params = { subscriptionId };

    const resp = await apiService.post<undefined>(
      `/api/user/${customerId}/explore-plans/setup`,
      undefined,
      { params },
    );

    return resp.data;
  },

  async sendLinkToSelectNewExplorePlan(customerId: number) {
    const resp = await apiService.post<undefined>(
      `/api/user/${customerId}/explore-plans/sendAllViaEmail`,
    );

    return resp.data;
  },

  async cancelSetupExplorePlan(customerId: number) {
    const resp = await apiService.post<undefined>(
      `/api/user/${customerId}/explore-plans/cancel`,
    );

    return resp.data;
  },

  async retryPayment(customerId: number) {
    const resp = await apiService.post<undefined>(
      `/api/user/${customerId}/subscriptions/retry`,
    );

    return resp.data;
  },
};
