import { Option } from '../../../../../general-types';

export const statusOptions: Option[] = [
  { value: '', label: 'All' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'INCOMPLETE', label: 'Incomplete' },
  { value: 'CANCELED', label: 'Canceled' },
  { value: 'PAYMENT_ISSUE', label: 'Payment issue' },
  { value: 'PRE-REGISTERED', label: 'Pre-Registered' },
];
