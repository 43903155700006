import { uniq } from 'lodash';
import { makeAutoObservable } from 'mobx';

export class SelectItemStore<T extends string | number> {
  selectedItems: T[] = [];
  isAllSelect: boolean = false;
  itemCount: number = 0;
  items: T[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.selectedItems = [];
    this.isAllSelect = false;
  }

  getSelectedItemCount(actionForAll?: boolean) {
    return this.isAllSelect || actionForAll ? this.itemCount : this.selectedItems.length;
  }

  selectItem(itemId: T) {
    const idIndex = this.selectedItems.indexOf(itemId);

    const newSelectedItems = [...this.selectedItems];

    if (idIndex !== -1) {
      newSelectedItems.splice(idIndex, 1);
    } else {
      newSelectedItems.push(itemId);
    }

    this.setIsAllSelect(this.itemCount === newSelectedItems.length);
    this.setSelectedItems(newSelectedItems);
  }

  selectAllItems(selectAll: boolean) {
    if (selectAll) {
      this.setIsAllSelect(true);
      this.setSelectedItems([...this.items]);
    } else {
      this.setIsAllSelect(false);
      this.setSelectedItems([]);
    }
  }

  getMasterCheckboxProps() {
    return {
      isChecked: !!this.selectedItems.length,
      indeterminate: !!this.selectedItems.length && !this.isAllSelect,
      onCheck: () => this.selectAllItems(!this.selectedItems.length && !this.isAllSelect),
    };
  }

  getIsSelected(itemId: T) {
    return this.isAllSelect || this.selectedItems.includes(itemId);
  }

  getSelectedItems(actionForAll?: boolean) {
    return this.isAllSelect || actionForAll ? [] : this.selectedItems;
  }

  addItems(items: T[]) {
    this.items = items;

    if (this.isAllSelect) {
      const allItems = uniq([...this.selectedItems, ...items]);
      this.setSelectedItems(allItems);
    }
  }

  setItemCount(itemCount: number) {
    this.itemCount = itemCount;
  }

  private setSelectedItems(selectedItems: T[]) {
    this.selectedItems = selectedItems;
  }

  private setIsAllSelect(isAllSelect: boolean) {
    this.isAllSelect = isAllSelect;
  }
}
