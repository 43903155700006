import React from 'react';

import { useForm } from 'react-hook-form';

import { LbModal } from '../../../../shared/components/modals';
import { LbInput } from '../../../../shared/components/form-components';
import { validateLocationId } from '../../../utils/goHighLevelUtils';

type EditGoHighLevelFormValues = {
  locationId: string;
};

type EditGoHighLevelModalProps = {
  defaultLocationId?: string;
  onClose: () => void;
  onSubmit: (locationId: string) => Promise<void>;
};

const EditGoHighLevelModal = ({
  defaultLocationId,
  onClose,
  onSubmit,
}: EditGoHighLevelModalProps) => {
  const { control, handleSubmit, formState } = useForm<EditGoHighLevelFormValues>({
    defaultValues: { locationId: defaultLocationId },
  });

  const handleFormSubmit = handleSubmit(async ({ locationId }) => {
    await onSubmit(locationId);

    onClose();
  });

  return (
    <LbModal
      open
      title="Edit GoHighLevel Integration"
      primaryBtnConfig={{
        onClick: handleFormSubmit,
        text: 'Confirm',
        disabled: !formState.isDirty,
      }}
      secondaryBtnConfig={{ onClick: onClose, text: 'Cancel' }}
    >
      <LbInput
        control={control}
        name="locationId"
        label="GoHighLevel ID"
        variant="outlined"
        size="small"
        rules={{
          required: 'GoHighLevel ID is required',
          validate: {
            validateLocationId,
          },
        }}
      />
    </LbModal>
  );
};
export { EditGoHighLevelModal };
