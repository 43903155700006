const autoNgTemplateLoaderTemplate1 = require('./foods.template.html');

'use strict';

(function () {
    angular
        .module('app.foods', [])
        .component('foods', {
            templateUrl: autoNgTemplateLoaderTemplate1,
            controller: 'FoodsController',
            controllerAs: 'vm'
        })
        .config(foodsConfig);

    foodsConfig.$inject = ['$routeProvider'];

    function foodsConfig($routeProvider) {
        $routeProvider
            .when('/foods/:type', {
                disableCache: true,
                template: '<foods></foods>',
                resolve: {
                    loginRequired: ['AuthService', function (AuthService) {
                        return AuthService.loginRequired();
                    }]
                }
            })
    }
})();
