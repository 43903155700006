import React from 'react';
import { Control } from 'react-hook-form';

import { InfoBox } from '../../../../../shared/components/ui-components';
import { TextArea } from '../../../../../shared/components/form-components';

type TextQuestionProps = {
  control: Control;
  questionId: number;
  name: string;
};

const TextQuestion = ({ control, questionId, name }: TextQuestionProps) => {
  return (
    <InfoBox title={name}>
      <TextArea
        control={control}
        name={questionId.toString()}
        placeholder="Type your answer here"
      />
    </InfoBox>
  );
};

export { TextQuestion };
