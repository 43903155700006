import { DependencyList } from 'react';

import { usePrevious, useUpdateEffect } from 'react-use';
import { isEqual, isUndefined } from 'lodash';

import { taxStore } from '../stores/taxStore';

// Required use observer
export const useAppliedTaxUpdate = (effect: () => void, deps: DependencyList = []) => {
  const { appliedTaxRateInfo } = taxStore;
  const prevAppliedTaxRateInfo = usePrevious(appliedTaxRateInfo);

  useUpdateEffect(() => {
    const isInitial = isUndefined(prevAppliedTaxRateInfo);
    const isReset = isUndefined(appliedTaxRateInfo);
    const isChanged = !isEqual(prevAppliedTaxRateInfo, appliedTaxRateInfo);

    if (!isInitial && !isReset && isChanged) {
      effect();
    }
  }, [appliedTaxRateInfo, ...deps]);
};
