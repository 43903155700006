import React, { useCallback, useEffect } from 'react';

import { useToggle, useUnmount } from 'react-use';
import { Divider, Paper } from '@mui/material';
import { observer } from 'mobx-react';

import classes from './EntitySubscriptionManagement.module.scss';
import { CreateEntitySubscriptionModal } from '../../components/CreateEntitySubscriptionModal/CreateEntitySubscriptionModal';
import {
  adminSubscriptionsManagementStore,
  superSubscriptionsManagementStore,
} from '../../stores/entitySubscriptionsManagementStore';
import { TitleFilterRow } from './TitleFilterRow/TitleFilterRow';
import { subscriptionSettingsStore } from '../../stores/subscriptionSettingsStore';
import { useGetNecessaryData } from '../../../shared/hooks/useGetNecessaryData';
import {
  LbTabPanel,
  LbTabs,
  LbTabsList,
  TabsConfig,
} from '../../../shared/components/tabs';
import { CreateEntitySubscriptionInfo } from '../../interfaces/entitySubscription';
import { AdminSubscriptionList } from './AdminSubscriptionList/AdminSubscriptionList';
import { SuperSubscriptionList } from './SuperSubscriptionList/SuperSubscriptionList';
import { loggedInUserDataStore } from '../../../shared/stores/loggedInUserDataStore';
import { taxStore } from '../../stores/taxStore';
import { useAppliedTaxUpdate } from '../../hooks/useAppliedTaxUpdate';
import { SubscriptionOwnerRoleType } from '../../interfaces/subscriptionOwnerInfo';

type EntitySubscriptionManagementProps = {
  entityId: number;
};

const EntitySubscriptionManagement = observer(
  ({ entityId }: EntitySubscriptionManagementProps) => {
    const [isShowCreateSubscriptionModal, toggleShowCreateSubscriptionModal] =
      useToggle(false);

    const { isSuperAdmin, isEntity } = loggedInUserDataStore;
    const { settings } = subscriptionSettingsStore;

    const adminTotalSubscriptions =
      adminSubscriptionsManagementStore.filterMethods.totalElements;
    const superTotalSubscriptions =
      superSubscriptionsManagementStore.filterMethods.totalElements;

    const getSubscriptionsLists = useCallback(() => {
      adminSubscriptionsManagementStore.getSubscriptionsList(entityId);
      superSubscriptionsManagementStore.getSubscriptionsList(entityId);
    }, [entityId]);

    useEffect(() => {
      getSubscriptionsLists();

      return () => {
        adminSubscriptionsManagementStore.setDefaultValues();
        superSubscriptionsManagementStore.setDefaultValues();
      };
    }, [getSubscriptionsLists]);

    useAppliedTaxUpdate(() => {
      getSubscriptionsLists();
    }, [getSubscriptionsLists]);

    useGetNecessaryData(async () => {
      await Promise.all([
        subscriptionSettingsStore.getSettings(entityId),
        taxStore.getTaxInfo(entityId),
      ]);
    }, [entityId]);
    useUnmount(() => {
      subscriptionSettingsStore.setDefaultValues();
      taxStore.setDefaultValues();
    });

    const tabsConfig: TabsConfig<SubscriptionOwnerRoleType>[] = [
      {
        label: `Entity Subscriptions (${adminTotalSubscriptions})`,
        value: 'ADMIN',
        panel: <AdminSubscriptionList />,
      },
      {
        label: `Standard subscriptions (${superTotalSubscriptions})`,
        value: 'SUPERUSER',
        panel: <SuperSubscriptionList />,
      },
    ];

    const handleCreateSubscription = async (
      subscription: CreateEntitySubscriptionInfo,
    ) => {
      if (isEntity) {
        await adminSubscriptionsManagementStore.createEntitySubscription(subscription);
      } else if (isSuperAdmin) {
        await superSubscriptionsManagementStore.createEntitySubscription(subscription);
      } else {
        throw new Error('User role is not set');
      }
    };

    return (
      <Paper variant="roundedBox" className={classes.box}>
        <TitleFilterRow
          toggleShowCreateSubscriptionModal={toggleShowCreateSubscriptionModal}
        />
        <LbTabs defaultValue="ADMIN">
          <LbTabsList
            tabsConfig={tabsConfig}
            variant="underline"
            className={classes.tabList}
          />
          <Divider />
          <LbTabPanel tabsConfig={tabsConfig} />
        </LbTabs>
        {isShowCreateSubscriptionModal && (
          <CreateEntitySubscriptionModal
            onClose={toggleShowCreateSubscriptionModal}
            onSubmit={handleCreateSubscription}
            entityId={entityId}
            subscriptionSettings={settings}
          />
        )}
      </Paper>
    );
  },
);

export { EntitySubscriptionManagement, EntitySubscriptionManagementProps };
