import React from 'react';
import PropTypes from 'prop-types';

import { useFieldArray, useFormContext } from 'react-hook-form';

import classes from './TestStep.module.scss';
import { registrationStepperStore } from '../../../../stores/registrationStepperStore';
import { AddTestBtn } from '../../../../components/AddTestBtn/AddTestBtn';
import { useModalToggle } from '../../../../../shared/hooks/useModalToggle';
import { MedicalTestFormCard } from '../../../../components/lab-test-components';
import { AddTestModals } from '../../../../components/AddTestModals/AddTestModals';
import { ButtonRow } from '../../components/ButtonRow/ButtonRow';
import { customerTestsService } from '../../../../services/customerTestsService';

const TestStep = ({ fieldName }) => {
  const { userId, isMedicalTestsSend, goToNextStep, setIsMedicalTestsSend } =
    registrationStepperStore;

  const [isAddTestModalOpen, addTestModalHandler] = useModalToggle();

  const {
    control,
    handleSubmit,
    getValues,
    formState: { isValid },
  } = useFormContext();

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: fieldName,
  });

  const onSubmit = async (data) => {
    const tests = await customerTestsService.sendTests(userId, data[fieldName]);

    replace(tests);
    setIsMedicalTestsSend();
    goToNextStep();
  };

  const addTestHandler = ({ id, testName }) => {
    const newTest = {
      testName,
      testID: id,
      results: [{ uploadedTestFiles: [] }],
    };

    append(newTest);
    addTestModalHandler();
  };

  const deleteTestHandler = async (testId, index) => {
    if (isMedicalTestsSend) {
      await customerTestsService.deleteTest(userId, testId);
    }
    remove(index);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {fields.map((test, index) => (
          <MedicalTestFormCard
            nameIndex={`${fieldName}.${index}.`}
            onDeleteTest={() => deleteTestHandler(test.testID, index)}
            key={test.id}
          />
        ))}
        {fields.length === 0 && (
          <div className={classes.addTestRow}>
            <AddTestBtn onClick={addTestModalHandler} />
          </div>
        )}
        <ButtonRow
          handlerNext={handleSubmit(onSubmit)}
          skipHandler={goToNextStep}
          isDisabled={!isValid}
          cancelBtnName="Skip for now"
          showSubmit={fields.length > 0}
        >
          {fields.length !== 0 && <AddTestBtn onClick={addTestModalHandler} />}
        </ButtonRow>
      </form>
      <AddTestModals
        open={isAddTestModalOpen}
        addTestHandler={addTestHandler}
        selectedTests={getValues(`${fieldName}`)}
        cancelHandler={addTestModalHandler}
      />
    </>
  );
};

TestStep.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

export { TestStep };
