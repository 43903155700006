import { apiService } from '../../shared/services/apiService';

export type PasswordInfo = {
  password: string;
  repeatPassword: string;
};

export type ChangePasswordInfo = {
  currentPassword: string;
  password: string;
  repeatPassword: string;
};

type SetNewPasswordToUserResponse = { success: boolean };
type ChangePasswordResponse = { refresh_token: string };
type ChangePasswordConfig = { isRunGlobalSpinner?: boolean; ignoreErrorCodes?: number[] };

// TODO replace to auth module after marge LMP-2406 with types
export const passwordService = {
  // used to set new password to user not logged in to system
  async setNewPasswordToUser(userId: number, passwordInfo: PasswordInfo) {
    const resp = await apiService.put<SetNewPasswordToUserResponse>(
      `api/general/set-password/${userId}`,
      passwordInfo,
    );

    return resp.data;
  },

  async changePassword(
    passwordInfo: ChangePasswordInfo,
    config: ChangePasswordConfig = {},
  ) {
    const { isRunGlobalSpinner = false, ignoreErrorCodes } = config;
    const resp = await apiService.put<ChangePasswordResponse>(
      `api/general/change-password`,
      passwordInfo,
      { isRunGlobalSpinner, ignoreErrorCodes },
    );

    return resp.data;
  },

  async sendPasswordResetLink(entityId: number) {
    const resp = await apiService.post<undefined>(
      `api/super/entity/${entityId}/password-reset-link`,
    );

    return resp.data;
  },
};
