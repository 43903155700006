import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';

import { Calendar } from './Calendar/Calendar';
import { CalendarsWithTabs } from './CalendarsWithTabs/CalendarsWithTabs';
import { customerActiveCalendarStore } from '../../../../../stores/customerActiveCalendarStore';
import { ActiveCalendarPlaceholder } from '../ActiveCalendarPlaceholder/ActiveCalendarPlaceholder';
import { TitleRow } from './TitleRow/TitleRow';

const ActiveCalendarSchedule = observer(({ showEditHandler, customerId }) => {
  const { calendar, calendarForTomorrow, isActiveCalendarCreate, getDataForRender } =
    customerActiveCalendarStore;

  useEffect(() => {
    getDataForRender(customerId);
  }, []);

  const isShowCalendarsWithTabs = calendarForTomorrow.length !== 0;

  const CalendarComponent = isShowCalendarsWithTabs ? (
    <CalendarsWithTabs calendar={calendar} calendarForTomorrow={calendarForTomorrow} />
  ) : (
    <Calendar activeCalendar={calendar} />
  );

  return (
    <>
      <TitleRow onEdit={showEditHandler} customerId={customerId} />
      {isActiveCalendarCreate ? CalendarComponent : <ActiveCalendarPlaceholder />}
    </>
  );
});

ActiveCalendarSchedule.propTypes = {
  showEditHandler: PropTypes.func,
  customerId: PropTypes.string,
};

export { ActiveCalendarSchedule };
