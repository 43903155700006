import React from 'react';

import { usePagination } from '@mui/lab';
import classNames from 'classnames';

import classes from './LbPagination.module.scss';
import { PaginationItem } from './PaginationItem/PaginationItem';
import { getDisplayedElementsInfo } from './utils/getDisplayedElementsInfo';

type LbPaginationProps = {
  count: number;
  onChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  page: number;
  totalElements: number;
  elementsPerPage: number;
  variant: 'outBox' | 'inBox';
  className?: string;
};

const LbPagination = ({
  count,
  onChange,
  page,
  totalElements,
  elementsPerPage,
  variant,
  className,
}: LbPaginationProps) => {
  const { items } = usePagination({
    count,
    onChange,
    page,
    boundaryCount: 1,
    hideNextButton: true,
    hidePrevButton: true,
  });

  if (!totalElements) {
    return null;
  }

  const { startElement, endElement } = getDisplayedElementsInfo({
    page,
    elementsPerPage,
    totalElements,
  });

  const paginationBoxClasses = classNames(
    classes.paginationBox,
    classes[variant],
    className,
  );

  return (
    <div className={paginationBoxClasses}>
      <span
        className={classes.info}
      >{`${startElement} - ${endElement} of ${totalElements}`}</span>
      <ul className={classes.buttonList}>
        {items.map((itemInfo, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <PaginationItem key={index} {...itemInfo} />
        ))}
      </ul>
    </div>
  );
};

export { LbPagination };
