import React from 'react';

import { Tabs, TabsProps } from '@mui/base';

type LbTabsProps = TabsProps;

// TODO improve vertical tabs add style
const LbTabs = ({ children, ...props }: LbTabsProps) => {
  return <Tabs {...props}>{children}</Tabs>;
};

export { LbTabs, LbTabsProps };
