import React, { useEffect } from 'react';

import { angularize, getService } from 'react-in-angularjs';
import { observer } from 'mobx-react';

import { SystemProviders } from '../../../../../shared/providers';
import { ActiveCalendarSchedule } from './ActiveCalendarSchedule/ActiveCalendarSchedule';
import { EditActiveCalendar } from './EditActiveCalendar/EditActiveCalendar';
import { useModalToggle } from '../../../../../shared/hooks/useModalToggle';
import { customerActiveCalendarStore } from '../../../../stores/customerActiveCalendarStore';

const NgzCustomerActiveCalendarTab = observer(() => {
  const [isShowEdit, showEditHandler] = useModalToggle();

  const $routeParams = getService('$routeParams');
  useEffect(() => customerActiveCalendarStore.setDefaultValues, []);

  return (
    <SystemProviders>
      {isShowEdit ? (
        <EditActiveCalendar
          showEditHandler={showEditHandler}
          customerId={$routeParams.id}
        />
      ) : (
        <ActiveCalendarSchedule
          showEditHandler={showEditHandler}
          customerId={$routeParams.id}
        />
      )}
    </SystemProviders>
  );
});

angularize(
  NgzCustomerActiveCalendarTab,
  'ngzCustomerActiveCalendarTab',
  angular.module('app.active-calendar'),
  {},
);

export default NgzCustomerActiveCalendarTab;
