import { GroupInfo, customerGroupService } from '../../../../../../customer-group';

// TODO replace to customer-group module if used in other places, with groupInput
export const processAssignCustomersToGroup = async (
  customerId: number,
  groupInfo: GroupInfo | null,
) => {
  if (!groupInfo) {
    return;
  }

  const isNewGroup = groupInfo.id === 0;

  if (isNewGroup) {
    const newGroup = await customerGroupService.createGroup(groupInfo.name);
    await customerGroupService.assignCustomersToGroup(newGroup.id, [customerId]);
  } else {
    await customerGroupService.assignCustomersToGroup(groupInfo.id, [customerId]);
  }
};
