import { makeAutoObservable } from 'mobx';

import { CustomFilterInfo } from '../interfaces/CustomFilterInfo';
import { resetAllStore } from '../../../stores/resetAllStore';
import { customFilterService } from '../services/customFilterService';
import { deleteItemByField, updateItemByField } from '../../../utils/arrayUtils';
import { CustomFilterData } from '../interfaces/CustomFilterData';
import {
  SelectedResourceType,
  ResourceType,
  RESOURCE_TYPE,
} from '../constants/resourceType';
import { getFlagsObject } from '../../../utils/sortingAndGroupingUtil';

class CustomFilterStore {
  customFilters: CustomFilterInfo[] = [];
  resourceType: ResourceType = 'customers';
  selectedResourceType: SelectedResourceType = {};
  isShowCustomFilterModal: boolean = false;
  editedFilterId: number | null = null;
  editedFilterInfo?: CustomFilterData = undefined;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.customFilters = [];
    this.resourceType = 'customers';
    this.isShowCustomFilterModal = false;
    this.editedFilterId = null;
    this.editedFilterInfo = undefined;
  }

  async getCustomFilters(resourceType: ResourceType) {
    const { customFilters } = await customFilterService.getCustomFilters(resourceType);

    this.setResourceType(resourceType);
    this.setSelectedResourceType(resourceType);
    this.setCustomFilters(customFilters);
  }

  async handelSubmitFilter(data: CustomFilterData) {
    this.setIsShowCustomFilterModal(false);

    if (this.editedFilterId) {
      await this.editCustomFilters(this.editedFilterId, data);
    } else {
      await this.createCustomFilters(data);
    }
    this.resetEditFilterData();
  }

  async createCustomFilters(customFilterData: CustomFilterData) {
    const customFilter = await customFilterService.createFilter(
      this.resourceType,
      customFilterData,
    );

    this.setCustomFilters([...this.customFilters, customFilter]);
  }

  async editCustomFilters(filterId: number, customFilterData: CustomFilterData) {
    const { name, id } = await customFilterService.editFilter(
      this.resourceType,
      filterId,
      customFilterData,
    );

    const updatedFilters = updateItemByField(this.customFilters, { name, id }, { id });

    this.setCustomFilters(updatedFilters);
  }

  async deleteCustomFilter(deleteFilterId: number) {
    await customFilterService.deleteFilter(this.resourceType, deleteFilterId);
    const filterWithoutDeleted = deleteItemByField(this.customFilters, {
      id: deleteFilterId,
    });

    this.setCustomFilters(filterWithoutDeleted);
  }

  async openEditFilterModal(filterId: number) {
    const filterData = await customFilterService.getFilterInfo(
      this.resourceType,
      filterId,
    );

    this.setEditedFilterInfo(filterData);
    this.setEditedFilterId(filterId);
    this.setIsShowCustomFilterModal(true);
  }

  openAddFilterModal() {
    this.setIsShowCustomFilterModal(true);
  }

  closeFilterModal() {
    this.resetEditFilterData();
    this.setIsShowCustomFilterModal(false);
  }

  private resetEditFilterData() {
    this.setEditedFilterInfo(undefined);
    this.setEditedFilterId(null);
  }

  private setCustomFilters(customFilters: CustomFilterInfo[]) {
    this.customFilters = customFilters;
  }

  private setResourceType(resourceType: ResourceType) {
    this.resourceType = resourceType;
  }

  private setIsShowCustomFilterModal(isShowCustomFilterModal: boolean) {
    this.isShowCustomFilterModal = isShowCustomFilterModal;
  }

  private setEditedFilterId(editedFilterId: number | null) {
    this.editedFilterId = editedFilterId;
  }

  private setSelectedResourceType(resourceType: ResourceType) {
    const selectedResourceType = getFlagsObject(RESOURCE_TYPE, [resourceType]);

    this.selectedResourceType = selectedResourceType;
  }

  private setEditedFilterInfo(editedFilterInfo?: CustomFilterData) {
    this.editedFilterInfo = editedFilterInfo;
  }
}

const customFilterStore = new CustomFilterStore();

resetAllStore.addResetMethod(customFilterStore.setDefaultValues);

export { customFilterStore, CustomFilterStore };
