import { useState } from 'react';
import { inventoryStatus } from '../../../../constants/inventoryStatus';
import { orderSupplementsService } from '../../../../services/orderService';
import { getStatus } from './InventoryRow.settings';

const {
  recordOrder,
  confirmOrder,
  updateInventoriesServingSizes,
  editOrder,
  deleteOrder,
} = orderSupplementsService;

export const useInventory = (inventoryInfo, customerId) => {
  const [inventory, setInventory] = useState(inventoryInfo);

  const { supplementJson, order, status, servingActual, fullPackageSize } = inventory;

  const onConfirmOrder = async () => {
    const resp = await confirmOrder(order.orderID);

    setInventory({
      ...inventory,
      servingActual: resp.servingActual,
      order: null,
      status: inventoryStatus.SUFFICIENT_INVENTORY,
    });
  };

  const recordOrderHandler = async (data) => {
    const dataToSend = { ...data, supplementID: supplementJson.supplementId };

    const resp = await recordOrder(dataToSend, customerId);

    setInventory({ ...inventory, order: resp[0], status: inventoryStatus.PENDING });
  };

  const updateServingSizesHandler = async (data) => {
    const dataToSend = [{ ...data, supplementID: supplementJson.supplementId }];

    await updateInventoriesServingSizes(dataToSend, customerId);

    const newStatus = getStatus(status, +data.servingActual, +data.fullPackageSize);
    setInventory({
      ...inventory,
      status: newStatus,
      fullPackageSize: +data.fullPackageSize,
      servingActual: +data.servingActual,
    });
  };

  const editOrderHandler = async (data) => {
    const dataToSend = { ...data, orderID: order.orderID };

    const resp = await editOrder(dataToSend);

    setInventory({ ...inventory, order: resp });
  };

  const deleteOrderHandler = async () => {
    await deleteOrder(order.orderID);

    const newStatus = getStatus(status, servingActual, fullPackageSize, true);

    setInventory({ ...inventory, order: null, status: newStatus });
  };

  return {
    inventory,
    onConfirmOrder,
    recordOrderHandler,
    updateServingSizesHandler,
    editOrderHandler,
    deleteOrderHandler,
  };
};
