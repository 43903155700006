import React from 'react';

import classes from './HeaderRow.module.scss';
import { Row } from '../../../../../pages/customers-dashboard/components/Row/Row';

const HeaderRow = () => {
  return (
    <Row className={classes.headerRow}>
      <div>Date of Order</div>
      <div>Name of Supplement</div>
      <div>Amount of Order</div>
    </Row>
  );
};

export { HeaderRow };
