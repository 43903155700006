import { useEffect } from 'react';

import { useForm } from 'react-hook-form';

import { foodScoreStore } from '../../../../../stores/foodScoreStore';
import { useModalToggle } from '../../../../../../shared/hooks/useModalToggle';
import { openConfirmationModal } from '../../../../../../shared/features/confirmation';

export const useFoodInfoRow = ({ food, toggleIsOpenCancelModal }) => {
  const { foodId } = food;
  const { saveFoodScore, deleteFood } = foodScoreStore;
  const {
    control,
    handleSubmit,
    formState: { isDirty, isSubmitSuccessful },
    reset,
  } = useForm();

  const [isScoreFormOpen, toggleScoreForm] = useModalToggle();

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful]);

  const onSave = async (data) => {
    const scoreResult = getScoreResult(data);

    await saveFoodScore(foodId, scoreResult);

    toggleScoreForm();
  };

  const getScoreResult = (formData) => {
    return Object.entries(formData).map(([key, value]) => ({
      foodScoreType: key,
      foodScoreValue: value,
    }));
  };

  const submitForm = handleSubmit(onSave);

  const onDelete = (event) => {
    event.stopPropagation();

    openConfirmationModal({
      title: 'The record will be deleted. Would you like to proceed?',
      confirmBtnConfig: { onClick: () => deleteFood(foodId) },
    });
  };

  const cancelModalYesHandler = () => {
    submitForm();
    toggleIsOpenCancelModal();
  };

  const cancelModalNoHandler = () => {
    cancelScoring();
    toggleIsOpenCancelModal();
  };

  const cancelScoring = () => {
    toggleScoreForm();
    reset();
  };

  const cancelHandler = () => {
    if (isDirty) {
      toggleIsOpenCancelModal();
    } else {
      toggleScoreForm();
    }
  };

  return {
    control,
    submitForm,
    onDelete,
    cancelHandler,
    cancelModalNoHandler,
    cancelModalYesHandler,
    isScoreFormOpen,
  };
};
