import { Components } from '@mui/material';

export const MuiRadioTheme: Components['MuiRadio'] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      padding: '2px',
      '.MuiSvgIcon-root': {
        fill: 'var(--natural-300)',
      },
      '&.Mui-disabled': {
        '.MuiSvgIcon-root': {
          fill: 'var(--natural-400)',
        },
      },
      '&:focus-within .MuiSvgIcon-root, &:hover .MuiSvgIcon-root ': {
        fill: 'currentColor',
      },
      '&.Mui-checked': {
        '.MuiSvgIcon-root': {
          fill: 'currentColor',
        },
      },
    },
  },
  variants: [
    {
      props: { color: 'secondary' },
      style: {
        color: 'var(--main-black)',
      },
    },
    {
      props: { color: 'primary' },
      style: {
        color: 'var(--primary-500)',
      },
    },
  ],
};
