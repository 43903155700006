import { makeAutoObservable } from 'mobx';

import { resetAllStore } from '../../shared/stores/resetAllStore';
import { CustomerCommunicationInfo } from '../interfaces/CustomerCommunicationInfo';
import {
  CustomerCommunicationFilterFormValues,
  CustomerCommunicationSortField,
  CustomerCommunicationFilters,
} from '../interfaces/CustomerCommunicationFilters';
import { customerCommunicationService } from '../services/customerCommunicationService';
import { downloadBinaryFile } from '../../shared/utils/apiUtils';
import { SelectItemStore, FilterStore } from '../../shared/util-stores';
import { communicationModalStore } from '../../chat/stores/communicationModalStore';
import { loggedInUserDataStore } from '../../shared/stores/loggedInUserDataStore';

class CustomerCommunicationStore {
  customerList: CustomerCommunicationInfo[] = [];
  selectMethod: SelectItemStore<CustomerCommunicationInfo['id']>;
  filterMethod: FilterStore<
    CustomerCommunicationFilterFormValues,
    CustomerCommunicationSortField
  >;
  isShowCoachColumn: boolean = false;
  isShowLocationColumn: boolean = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    this.selectMethod = new SelectItemStore();
    this.filterMethod = new FilterStore<
      CustomerCommunicationFilterFormValues,
      CustomerCommunicationSortField
    >(this.handleGetCustomerList);
  }

  setDefaultValues() {
    this.customerList = [];
    this.filterMethod.setDefaultValues();
    this.selectMethod.setDefaultValues();
    this.isShowCoachColumn = false;
    this.isShowLocationColumn = false;
  }

  setShowColumnsSettings() {
    const { isCoach, isSuperAdmin } = loggedInUserDataStore;

    this.isShowCoachColumn = !isCoach;
    this.isShowLocationColumn = !!isSuperAdmin;
  }

  async getCustomerListInitData() {
    this.setShowColumnsSettings();
    this.getCustomerList();
  }

  async getCustomerList() {
    return this.filterMethod.getData();
  }

  async handleGetCustomerList(filterData: CustomerCommunicationFilters) {
    const data = await customerCommunicationService.getCustomerList(filterData);
    const { content, totalElements } = data;

    const customerIds = content.map((customer) => customer.id);

    this.setCustomerList(content);

    this.selectMethod.addItems(customerIds);
    this.selectMethod.setItemCount(totalElements);

    return data;
  }

  async downloadReport(forAll?: boolean) {
    const customerList = this.selectMethod.getSelectedItems(forAll);

    const file = await customerCommunicationService.getCommunicationCustomerReports({
      customerList,
      filters: this.filterMethod.filterData,
    });

    const filename = 'Users Activity Report';
    downloadBinaryFile(file, 'text/csv', filename);
  }

  sendMessage(toAll: boolean) {
    const customerIds = this.selectMethod.getSelectedItems(toAll);
    const customersCount = this.selectMethod.getSelectedItemCount(toAll);

    communicationModalStore.openCommunicationModal({
      customerIds,
      filters: this.filterMethod.filterData,
      customersCount,
      resourceType: 'customers',
    });
  }

  private setCustomerList(customerList: CustomerCommunicationInfo[]) {
    this.customerList = customerList;
  }
}

const customerCommunicationStore = new CustomerCommunicationStore();

resetAllStore.addResetMethod(customerCommunicationStore.setDefaultValues);

export { customerCommunicationStore, CustomerCommunicationStore };
