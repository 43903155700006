import React, { Children, ReactNode } from 'react';

import classes from './GridStickyGroup.module.scss';
import { useGridRowHeight } from './hook/useGridRowHeight';

type GridStickyGroupProps = {
  children: ReactNode;
};

// "overflow: visible;" in row is necessary for correct work of sticky group"
const GridStickyGroup = ({ children }: GridStickyGroupProps) => {
  const { cellRef, rowHeight } = useGridRowHeight();

  const sellCount = Children.count(children);

  return (
    <div
      ref={cellRef}
      className={classes.stickyGroup}
      style={{ gridColumnStart: `span ${sellCount}` }}
    >
      {children}
      <div className={classes.ground} style={{ height: `${rowHeight}px` }} />
    </div>
  );
};

export { GridStickyGroup, GridStickyGroupProps };
