import { apiService } from '../../shared/services/apiService';

type GetIsShowChecklistResponse = {
  showChecklist: boolean;
};

export const setupFinalizationChecklistService = {
  async getIsShowChecklist(customerId: number) {
    const resp = await apiService.get<GetIsShowChecklistResponse>(
      `api/users/${customerId}/checklist`,
    );

    return resp.data;
  },

  async finishChecklist(customerId: number) {
    const resp = await apiService.post<GetIsShowChecklistResponse>(
      `api/users/${customerId}/checklist`,
      undefined,
      { params: { showChecklist: false } },
    );

    return resp.data;
  },
};
