import moment from 'moment';

export const getSupplementsSortedByDay = (schedules) => {
  const weekDays = Array.from(Array(7), () => []);

  schedules.forEach((dose) => {
    const dayIndex = moment().isoWeekday(dose.dayOfWeek).days();
    weekDays[dayIndex].push(dose);
  });
  return weekDays;
};
