import React from 'react';
import PropTypes from 'prop-types';

import classes from './InfoRow.module.scss';
import { getHumanizeDay } from '../../../../../../../shared/utils/dateUtils';

const InfoRow = ({ selectedDate, receptions }) => {
  const dayText = getHumanizeDay(selectedDate);

  const { success, total } = receptions;

  return (
    <div className={classes.successRow}>
      <p>{dayText}</p>
      <span>
        Successful receptions of supplements {success}/{total}
      </span>
    </div>
  );
};

InfoRow.propTypes = {
  selectedDate: PropTypes.object,
  receptions: PropTypes.shape({
    success: PropTypes.number,
    total: PropTypes.number,
  }),
};

export { InfoRow };
