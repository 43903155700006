import React from 'react';

import classes from './PaymentDetails.module.scss';
import { PaymentDetailsRow } from './PaymentDetailsRow/PaymentDetailsRow';
import { getFormattedDate } from '../../../../../shared/utils/dateUtils';
import { getPriceView } from '../../../../../shared/utils/stringUtils';
import { localDateTimeFormat } from '../../../../../shared/constants/apiDateFormat';
import { paymentMethodsTitles } from './PaymentDetailsSettings';
import { PaymentInfo } from '../../../../interfaces/checkoutInfo';

type PaymentDetailsProps = {
  paymentInfo: PaymentInfo;
};

const PaymentDetails = ({ paymentInfo }: PaymentDetailsProps) => {
  const { paymentTimeEpoch, senderName, paymentMethod, amount } = paymentInfo;
  const paymentTime = getFormattedDate(
    new Date(paymentTimeEpoch * 1000),
    localDateTimeFormat,
  );

  return (
    <div className={classes.container}>
      <PaymentDetailsRow title="Payment Time" value={paymentTime} />
      <PaymentDetailsRow
        title="Payment Method"
        value={paymentMethod ? paymentMethodsTitles[paymentMethod] : '—'}
      />
      <PaymentDetailsRow title="Sender Name" value={senderName} />
      <PaymentDetailsRow title="Amount" value={getPriceView(amount || 0)} />
    </div>
  );
};

export { PaymentDetails };
