// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../assets/images/vectors/no_videos_dark.svg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<div class=\"video-help-tab\"> <div ng-if=\"vm.videos\" class=\"app-card\"> <div class=\"app-card-header\"> <p class=\"app-card-header__title\">{{ vm.title }}</p> </div> <div class=\"app-card-body\" layout=\"row\" layout-wrap=\"wrap\"> <figure layout=\"column\" class=\"view-video\" ng-repeat=\"video in vm.videos\" ng-click=\"vm.showDialogWithVideo(video)\"> <img ng-src=\"{{ video.image }}\"> <figcaption class=\"view-video__text\"> {{ video.title }} </figcaption> </figure> </div> </div> <figure ng-if=\"!vm.videos\" class=\"under-construction\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"> <figcaption class=\"under-construction__description\"> Block is under construction. No videos yet </figcaption> </figure> </div> ";
// Exports
var _module_exports = code;;
var path = 'app/directives/view-video/view-video.template.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;