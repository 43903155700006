import React from 'react';

import { useToggle } from 'react-use';
import { enqueueSnackbar } from 'notistack';
import { observer } from 'mobx-react';
import { Button } from '@mui/material';

import classes from './CurrentPlanWidget.module.scss';
import { InfoBox, LbAlert } from '../../../../../shared/components/ui-components';
import { openConfirmationModal } from '../../../../../shared/features/confirmation';
import { SelectReactivationSubscriptionModal } from '../SelectReactivationSubscriptionModal/SelectReactivationSubscriptionModal';
import { currentSubscriptionPlanStore } from '../../../../stores/currentSubscriptionPlanStore';
import { pluralize } from '../../../../../shared/utils/stringUtils';
import { SubscriptionCancelAlert } from './SubscriptionCancelAlert/SubscriptionCancelAlert';
import { GeneralSubscriptionInformation } from './GeneralSubscriptionInformation/GeneralSubscriptionInformation';
import { PriceInfo } from '../../../../components/PriceInfo/PriceInfo';
import { InfoCell } from '../components';
import { SUBSCRIPTION } from '../../../../../shared/constants/user-info/subscription';

type CurrentPlanWidgetProps = {
  customerId: number;
};

const CurrentPlanWidget = observer(({ customerId }: CurrentPlanWidgetProps) => {
  const [isShowSelectSubscriptionModal, toggleShowSelectSubscriptionModal] =
    useToggle(false);

  const {
    currentSubscriptionPlan,
    subscriptionTypeFlags,
    paymentStatusFlag,
    cancelSubscription,
    cancelSetupExplorePlan,
    setupExplorePlan,
  } = currentSubscriptionPlanStore;

  if (!currentSubscriptionPlan) {
    return null;
  }

  const { subscription, lastTransaction, explorePlan } = currentSubscriptionPlan;

  const enqueueSuccessSentEmailForSubscription = () => {
    const { customerName } = lastTransaction.billingInfo;
    const successMassage = `The payment link for the new subscription has been successfully sent to ${customerName} via email`;

    enqueueSnackbar(successMassage, { variant: 'success', autoHideDuration: 5000 });
  };

  const handleCancelSubscription = () => {
    const title = `Are you sure you want to cancel ${subscription?.name}?`;
    const text =
      'Upon canceling, the user will still have access to the app for the time they paid for with the subscription.';

    openConfirmationModal({
      title,
      text,
      confirmBtnConfig: { text: 'Confirm', onClick: cancelSubscription },
    });
  };

  const handleSetupExplorePlan = async (subscriptionId: number) => {
    await setupExplorePlan(subscriptionId);

    enqueueSuccessSentEmailForSubscription();
  };

  return (
    <>
      <InfoBox title="Current Plan">
        <div className={classes.box}>
          <div className={classes.titleRow}>
            {explorePlan ? (
              <LbAlert severity="warning" size="small">
                <span className={classes.subscriptionNotPaid}>{explorePlan.name} </span>
                is not paid by the user
              </LbAlert>
            ) : (
              <GeneralSubscriptionInformation />
            )}
          </div>
          <div className={classes.paymentInfoRow}>
            <InfoCell title="Initial payment">
              {subscription && (
                <PriceInfo
                  price={subscription.initialPlanPrice}
                  finalPrice={subscription.initialPlanPriceFinal}
                  duration={pluralize(subscription.initialPlanDuration, 'month')}
                  hidePrice={subscriptionTypeFlags.FREE_ACCESS}
                />
              )}
              {explorePlan && (
                <PriceInfo
                  price={explorePlan.initialPlanPrice}
                  finalPrice={explorePlan.initialPlanPriceFinal}
                  duration={pluralize(explorePlan.initialPlanDuration, 'month')}
                  hidePrice={explorePlan.subscriptionType === SUBSCRIPTION.FREE_ACCESS}
                />
              )}
            </InfoCell>
            <InfoCell title="Auto Renew">
              {subscription && (
                <PriceInfo
                  price={subscription.monthlyRenewalPrice}
                  finalPrice={subscription.monthlyRenewalPriceFinal}
                  duration="per month"
                  hidePrice={subscriptionTypeFlags.FREE_ACCESS}
                />
              )}
              {!subscription && explorePlan && (
                <PriceInfo
                  price={explorePlan.monthlyRenewalPriceFinal}
                  finalPrice={explorePlan.monthlyRenewalPrice}
                  duration="per month"
                  hidePrice={explorePlan.subscriptionType === SUBSCRIPTION.FREE_ACCESS}
                />
              )}
            </InfoCell>
          </div>
          <div>
            {!paymentStatusFlag.CANCELED && (
              <Button variant="outlined" size="small" onClick={handleCancelSubscription}>
                Cancel subscription
              </Button>
            )}
            {paymentStatusFlag.CANCELED && explorePlan && (
              <Button variant="outlined" size="small" onClick={cancelSetupExplorePlan}>
                Cancel subscription
              </Button>
            )}
            {paymentStatusFlag.CANCELED && subscription && (
              <SubscriptionCancelAlert
                setupNewSubscriptionDate={lastTransaction.setupNewSubscriptionDate}
              />
            )}
            {paymentStatusFlag.CANCELED && !subscription && !explorePlan && (
              <Button
                variant="contained"
                size="small"
                onClick={toggleShowSelectSubscriptionModal}
              >
                Explore Plans
              </Button>
            )}
          </div>
        </div>
      </InfoBox>
      {isShowSelectSubscriptionModal && (
        <SelectReactivationSubscriptionModal
          customerId={customerId}
          onClose={toggleShowSelectSubscriptionModal}
          onConfirm={handleSetupExplorePlan}
        />
      )}
    </>
  );
});

export { CurrentPlanWidget };
