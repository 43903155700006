import { Components } from '@mui/material';

export const MuiLinkTheme: Components['MuiLink'] = {
  styleOverrides: {
    root: {
      textDecoration: 'underline',
      textUnderlineOffset: '4px',
      textDecorationColor: 'var(--natural-400)',
      font: 'inherit',

      '&:hover': {
        textDecoration: 'none',
        opacity: '0.5',
      },
    },
  },
};
