import React, { ReactNode, useRef } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';

import { stripeElementsAppearance } from './StripeElementsProvider.settings';

type StripeElementsProviderProps = {
  publishableKey: string;
  connectedAccountId: string;
  children: ReactNode;
  elementsOptions?: StripeElementsOptions;
};

const StripeElementsProvider = ({
  publishableKey,
  connectedAccountId,
  elementsOptions,
  children,
}: StripeElementsProviderProps) => {
  // prevent re-call on every render
  const stripePromiseRef = useRef(
    loadStripe(publishableKey, {
      stripeAccount: connectedAccountId,
    }),
  );

  return (
    <Elements
      stripe={stripePromiseRef.current}
      options={{
        appearance: stripeElementsAppearance,
        ...elementsOptions,
      }}
    >
      {children}
    </Elements>
  );
};

export { StripeElementsProvider, StripeElementsProviderProps };
