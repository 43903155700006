import React from 'react';
import PropTypes from 'prop-types';

import { Paper } from '@mui/material';

import classes from './Calendar.module.scss';
import { DaysRow } from '../DaysRow/DaysRow';
import { TimeRow } from '../TimeRow/TimeRow';

const Calendar = ({ activeCalendar, isTab }) => {
  const paperClasses = isTab ? `${classes.calendar} ${classes.isTab}` : classes.calendar;

  return (
    <Paper variant="roundedBox" className={paperClasses}>
      <DaysRow />
      {activeCalendar.map(([time, schedules]) => (
        <TimeRow time={time} schedules={schedules} key={time} />
      ))}
    </Paper>
  );
};

Calendar.propTypes = {
  activeCalendar: PropTypes.array,
  isTab: PropTypes.bool,
};

export { Calendar };
