import React from 'react';

import { Paper, Radio } from '@mui/material';
import { useToggle } from 'react-use';
import classNames from 'classnames';

import classes from './MealRow.module.scss';
import {
  GridTableCell,
  GridTableRow,
} from '../../../../../../shared/components/table-components';
import { MealInfo } from './MealInfo/MealInfo';
import { PredefinedMealPlan } from '../../../../../interfaces/MealPlan';
import { MealNutrients } from './MealNutrients/MealNutrients';
import { OpenCloseArrow } from '../../../../../../shared/components/ui-components';
import { LbFormControlLabel } from '../../../../../../shared/components/form-components';

type MealRowProps = {
  mealPlan: PredefinedMealPlan;
};

const MealRow = ({ mealPlan }: MealRowProps) => {
  const [isMealInfoOpen, toggleMealInfoOpen] = useToggle(false);

  const mealRowClasses = classNames(classes.mealRow, {
    [classes.mealInfoIsOpen]: isMealInfoOpen,
  });

  const { total, name, id } = mealPlan;

  // add type or use arrayFromEnum utils
  const meals = ['breakFast', 'lunch', 'snack1', 'dinner', 'snack2'] as const;

  return (
    <>
      <GridTableRow
        variant="header"
        className={mealRowClasses}
        onClick={toggleMealInfoOpen}
      >
        <GridTableCell>
          <LbFormControlLabel
            onClick={(event) => event.stopPropagation()}
            value={id}
            control={<Radio />}
            className={classes.mealNameCell}
            label={name}
          />
        </GridTableCell>
        <MealNutrients nutrientsInfo={total} />
        <GridTableCell justify="end">
          <OpenCloseArrow open={isMealInfoOpen} />
        </GridTableCell>
      </GridTableRow>
      {isMealInfoOpen && (
        <Paper variant="roundedBox" className={classes.mealInfoBox}>
          {meals.map((meal) => (
            <MealInfo meal={meal} nutrientsInfo={mealPlan[meal]} key={meal} />
          ))}
        </Paper>
      )}
    </>
  );
};

export { MealRow };
