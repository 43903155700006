import React from 'react';

import { InputAdornment } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';

import classes from './MealRow.module.scss';
import { Nutrient } from '../../../../../../../shared/components/ui-components';
import {
  ReadOnlyInput,
  LbInput,
} from '../../../../../../../shared/components/form-components';
import {
  GridTableCell,
  GridTableRow,
} from '../../../../../../../shared/components/table-components';
import { NUTRIENTS_ENUM } from '../../../../../../../shared/constants/NUTRIENTS_ENUM';
import {
  calculateMealCalories,
  processMealName,
} from '../../../../../../../shared/utils/mealUtils';
import {
  RegularMealName,
  WorkoutMealName,
} from '../../../../../../../shared/constants/mealNames';
import { MacroPlanStepFromValue } from '../../MacroPlanStep.settings';
import { validateNegativeNumber } from '../../../../../../../shared/utils/reactHookFormUtils';

type MealRowProps = {
  meal: RegularMealName | WorkoutMealName;
  mealFormKey: `mealPlans.${number}.${RegularMealName}` | WorkoutMealName;
};

const nutrientsList = [
  NUTRIENTS_ENUM.PROTEINS,
  NUTRIENTS_ENUM.CARBS,
  NUTRIENTS_ENUM.FATS,
] as const;

const MealRow = ({ meal, mealFormKey }: MealRowProps) => {
  const { control } = useFormContext<MacroPlanStepFromValue>();

  const nutrientsInputsSetting = nutrientsList.map(
    (nutrient) =>
      ({
        name: `${mealFormKey}.${nutrient}`,
        nutrient,
      }) as const,
  );

  const [proteins, carbs, fats] = useWatch({
    control,
    name: nutrientsInputsSetting.map((nutrient) => nutrient.name),
  });

  const calories = calculateMealCalories({ proteins, carbs, fats });

  return (
    <GridTableRow variant="unstyled" className={classes.row}>
      <GridTableCell variant="title">{processMealName(meal)}</GridTableCell>
      {nutrientsInputsSetting.map(({ name, nutrient }) => (
        <GridTableCell className={classes.cell} key={nutrient}>
          <LbInput
            control={control}
            name={name}
            size="small"
            type="number"
            rules={{
              required: 'required, set at least 0',
              validate: { validateNegativeNumber },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Nutrient nutrient={nutrient} />
                </InputAdornment>
              ),
            }}
          />
        </GridTableCell>
      ))}
      <GridTableCell>
        <Nutrient nutrient="calories">
          <ReadOnlyInput
            control={control}
            name={`${mealFormKey}.calories`}
            value={calories}
          />
        </Nutrient>
      </GridTableCell>
    </GridTableRow>
  );
};

export { MealRow };
