import { Option } from '../../general-types';
import { ValueOfKey } from '../../utils-types';

export const SCHOLASTIC_CUSTOMERS_STATUS = {
  INCOMPLETE: 'INCOMPLETE',
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
} as const;

export type ScholasticCustomersStatusType = ValueOfKey<
  typeof SCHOLASTIC_CUSTOMERS_STATUS
>;

export const scholasticCustomersStatusOptions: Option<
  ScholasticCustomersStatusType | ''
>[] = [
  { label: 'All', value: '' },
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Incomplete', value: 'INCOMPLETE' },
  { label: 'Expired', value: 'EXPIRED' },
  { label: 'Pending', value: 'PENDING' },
];
