import { useEffect, useState } from 'react';
import { calculateTimeLeft } from '../../../../../../../shared/utils/timeUtils/calculateTimeLeft';

type UseTimerReturn = {
  minutes: string;
  seconds: string;
  isTimeOver: boolean;
};

export const useTimer = (expirationEpoch: number): UseTimerReturn => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(expirationEpoch));

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(expirationEpoch));
    }, 1000);
    return () => clearTimeout(timer);
  });

  return {
    minutes: timeLeft.minutes.toString().padStart(2, '0'),
    seconds: timeLeft.seconds.toString().padStart(2, '0'),
    isTimeOver: timeLeft.minutes <= 0 && timeLeft.seconds <= 0,
  };
};
