import { useEffect, useState } from 'react';

import { last } from 'lodash';
import { Moment } from 'moment';

import { scanServices } from '../services/scanServices';
import { isSameDay } from '../../shared/utils/dateUtils';

export const useCustomerScanDays = (customerId: number) => {
  const [customerScanDays, setCustomerScanDays] = useState<string[]>([]);
  const [lastScanDay, setLastScanDay] = useState<string | null>(null);
  const [previousScanDay, setPreviousScanDay] = useState<string | null>(null);
  const [firstScanDay, setFirstScanDay] = useState<string | null>(null);

  const initCustomerScanDays = async () => {
    const { scanDates } = await scanServices.getScanDays(customerId);

    setCustomerScanDays(scanDates);
    setLastScanDay(scanDates[0] ?? null);
    setPreviousScanDay(scanDates[1] ?? null);
    setFirstScanDay(last(scanDates) ?? null);
  };

  useEffect(() => {
    initCustomerScanDays();
  }, []);

  const getIsScanDay = (day: string | Moment) =>
    customerScanDays.find((scanDay) => isSameDay(scanDay, day));

  return {
    customerScanDays,
    lastScanDay,
    previousScanDay,
    firstScanDay,
    getIsScanDay,
  };
};
