import React from 'react';

import { observer } from 'mobx-react';
import { FormProvider, useForm } from 'react-hook-form';

import classes from './GoalsStep.module.scss';
import { ButtonsRow } from '../../components/ButtonsRow/ButtonsRow';
import { QuestionnaireAnswerOverview } from '../../components/QuestionnaireAnswerOverview/QuestionnaireAnswerOverview';
import { BodyCompositionGoalForm } from './BodyCompositionGoalForm/BodyCompositionGoalForm';
import { BodyMetricsTable } from './BodyMetricsTable/BodyMetricsTable';
import { customerSetupStore } from '../../../../stores/customerSetupStore';
import { CreateGoalInfo } from '../../../../interfaces/goal';
import { goalService } from '../../../../services/goalService';
import { GoalValidationModal } from './GoalValidationModal/GoalValidationModal';
import { questionnaireReplyStore } from '../../../../stores/questionnaireReplyStore';
import { QUESTION_TYPE, GOAL_ANSWER_TYPE } from '../../../../constants/question';
import { useGoalMetrics } from './hooks/useGoalMetrics';
import { useGoalValidation } from './hooks/useGoalValidation';

type GoalsStepProps = {
  customerId: number;
};

const GoalsStep = observer(({ customerId }: GoalsStepProps) => {
  const fromMethods = useForm<CreateGoalInfo>({
    mode: 'onSubmit',
    reValidateMode: 'onChange', // this modes need to correct work of validation goal, clear errors on both after setErrorForInvalidGoal
  });
  const { formState, control, handleSubmit, setError } = fromMethods;

  const { metrics } = useGoalMetrics(control);
  const { validationInfo, validateGoal, reset } = useGoalValidation();

  const { stepperMethods } = customerSetupStore;
  const { getQuestionReplyByType } = questionnaireReplyStore;

  const { answer } = getQuestionReplyByType(QUESTION_TYPE.GOAL) || {};

  const onSubmit = async (data: CreateGoalInfo) => {
    await goalService.createGoal(customerId, data);

    stepperMethods.goToNextStep();
  };

  const setErrorForInvalidGoal = () => {
    setError('targetWeight', { type: 'custom' });
    setError('targetBodyFatPercent', { type: 'custom' });
  };

  const handleFromSubmit = handleSubmit(async (data) => {
    // Now validation available only for FAT_LOSS_AND_MUSCLE_GAIN else gaols can be added without validation
    if (answer?.answerOptionType === GOAL_ANSWER_TYPE.FAT_LOSS_AND_MUSCLE_GAIN) {
      validateGoal(metrics);
    } else {
      onSubmit(data);
    }
  });

  return (
    <div>
      <div className={classes.row}>
        <QuestionnaireAnswerOverview />
        <FormProvider {...fromMethods}>
          <BodyCompositionGoalForm />
        </FormProvider>
      </div>
      {formState.isValid && <BodyMetricsTable {...metrics} />}
      <ButtonsRow onNext={handleFromSubmit} />
      {validationInfo && (
        <GoalValidationModal
          open
          onProceed={handleSubmit(onSubmit)}
          onClose={reset}
          onErrorAdjust={setErrorForInvalidGoal}
          isValid={validationInfo.isValid}
          explanation={validationInfo.message}
        />
      )}
    </div>
  );
});

export { GoalsStep };
