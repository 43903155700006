import React from 'react';

import classes from './InitialPaymentInfo.module.scss';
import { getPriceView, pluralize } from '../../../../shared/utils/stringUtils';

type InitialPaymentInfoProps = {
  price: number | null;
  finalPrice: number | null;
  initialPlanDuration: number | null;
};

const InitialPaymentInfo = ({
  price,
  finalPrice,
  initialPlanDuration,
}: InitialPaymentInfoProps) => {
  // Render empty div because we need to take place in the layout
  if (!price && !finalPrice) return <div className={classes.placeholder} />;

  return (
    <div className={classes.container}>
      <div className={classes.pricesContainer}>
        {price !== finalPrice && (
          <p className={classes.oldPrice}>{getPriceView(price)}</p>
        )}
        <p className={classes.description}>
          <span className={classes.price}>{getPriceView(finalPrice)}</span>&nbsp;/&nbsp;
          {pluralize(initialPlanDuration, 'month')}
        </p>
      </div>
      <p className={classes.title}>Initial payment</p>
    </div>
  );
};

export { InitialPaymentInfo };
