import React from 'react';
import { FieldValues, Path, PathValue, UseFormRegister } from 'react-hook-form';

type HiddenInputProps<T extends FieldValues, TName extends Path<T>> = {
  register: UseFormRegister<T>;
  name: TName;
  value?: PathValue<T, TName>;
};
/**
 * Use to set invisible data to form,
 * prevent lost data when use shouldUnregister: true if input for it not exist
 */
const HiddenInput = <T extends FieldValues, TName extends Path<T>>({
  register,
  name,
  value,
}: HiddenInputProps<T, TName>) => {
  return <input type="hidden" {...register(name, { value })} />;
};

export { HiddenInput };
