import { makeAutoObservable } from 'mobx';

import { QuestionInfo } from '../../interfaces/QuestionnaireInfo';
import { questionnaireService } from '../../services/questionnaireService';
import { resetAllStore } from '../../../shared/stores/resetAllStore';

// use on customer-setup-flow-2024
class QuestionnaireStore {
  questions: QuestionInfo[] = [];
  userAnswers: Record<string, string> = {};

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.questions = [];
    this.userAnswers = {};
  }

  async getQuestions() {
    const data = await questionnaireService.getQuestions();

    this.setQuestions(data.questions);
  }

  private setQuestions(questions: QuestionInfo[]) {
    this.questions = questions;
  }

  setUserAnswers(userAnswers: Record<string, string>) {
    this.userAnswers = userAnswers;
  }
}

const questionnaireStore = new QuestionnaireStore();
resetAllStore.addResetMethod(questionnaireStore.setDefaultValues);

export { questionnaireStore, QuestionnaireStore };
