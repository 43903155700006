import React from 'react';

import { Path, UseControllerProps, useController } from 'react-hook-form';

import { PriceLabelRow } from '../PriceLabelRow/PriceLabelRow';
import { CreateEntitySubscriptionFormValues } from '../../CreateEntitySubscriptionModal.settings';

type ViewPriceInputProps = { label: string } & Pick<
  UseControllerProps<
    CreateEntitySubscriptionFormValues,
    Path<CreateEntitySubscriptionFormValues>
  >,
  'control' | 'name'
>;

const ViewPriceInput = ({ label, control, name }: ViewPriceInputProps) => {
  const {
    field: { value },
  } = useController<any>({ control, name });

  return <PriceLabelRow label={label} value={value} />;
};

export { ViewPriceInput, ViewPriceInputProps };
