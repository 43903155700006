import React from 'react';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import BlockIcon from '@mui/icons-material/Block';

import classes from './StatusCell.module.scss';
import { Status } from '../../../../../shared/components/ui-components';
import { getFormattedDate } from '../../../../../shared/utils/dateUtils';
import { localDataFormat } from '../../../../../shared/constants/apiDateFormat';
import { EntityStatus } from '../../../../constants/entityStatus';

type StatusCellProps = {
  status: EntityStatus;
  subscriptionExpire: string | null;
};

const dataTestId = 'status-cell';

const StatusCell = ({ status, subscriptionExpire }: StatusCellProps) => {
  const subscriptionExpireDate = getFormattedDate(subscriptionExpire, localDataFormat);

  switch (status) {
    case 'ACTIVE':
      return (
        <Status
          icon={<CheckCircleRoundedIcon fontSize="large" color="success" />}
          dataTestId={dataTestId}
        >
          <div className={classes.statusCellWithDate}>
            <p>Active</p>
            <span>{subscriptionExpireDate}</span>
          </div>
        </Status>
      );
    case 'INACTIVE':
      return (
        <Status
          icon={<CancelRoundedIcon fontSize="large" color="error" />}
          dataTestId={dataTestId}
        >
          <div className={classes.statusCellWithDate}>
            <p>Inactive</p>
            <span>{subscriptionExpireDate}</span>
          </div>
        </Status>
      );
    case 'INCOMPLETE':
      return (
        <Status
          icon={<BlockIcon fontSize="large" color="error" />}
          dataTestId={dataTestId}
        >
          Incomplete
        </Status>
      );
    default:
      return <div>No Info</div>;
  }
};

export { StatusCell };
