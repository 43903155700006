import React from 'react';

import { Checkbox, Tooltip } from '@mui/material';

import { GridTableCell } from '../../../../../shared/components/table-components';
import { ChangeDisplayToUserStatus } from '../../EntitySubscriptionList.settings';

type DisplayToUserStatusCellProps = {
  subscriptionId: number;
  displayToUser: boolean;
  active: boolean;
  disabled?: boolean;
  onDisplayToUserStatusChange: ChangeDisplayToUserStatus;
};

// span is needed for Tooltip to work properly with disabled Checkbox

const DisplayToUserStatusCell = ({
  active,
  displayToUser,
  subscriptionId,
  disabled,
  onDisplayToUserStatusChange,
}: DisplayToUserStatusCellProps) => {
  const tooltipTitle = !active
    ? 'Cannot be selected for an inactive subscription!'
    : null;

  return (
    <GridTableCell justify="center">
      <Tooltip title={tooltipTitle}>
        <span>
          <Checkbox
            color="primary"
            checked={displayToUser}
            onChange={(event, checked) =>
              onDisplayToUserStatusChange(subscriptionId, checked)
            }
            disabled={!active || disabled}
          />
        </span>
      </Tooltip>
    </GridTableCell>
  );
};

export { DisplayToUserStatusCell, DisplayToUserStatusCellProps };
