import React from 'react';

import { angularize } from 'react-in-angularjs';
import { SystemProviders } from '../../../../../shared/providers';
import { EntityLoginIdBlock } from './containers/EntityLoginIdBlock/EntityLoginIdBlock';
import { EntityPasswordBlock } from './containers/EntityPasswordBlock/EntityPasswordBlock';

type NgzLoginIdPasswordTabProps = {
  entityId: number;
  entityUserId: number;
};

const NgzLoginIdPasswordTab = ({
  entityId,
  entityUserId,
}: NgzLoginIdPasswordTabProps) => {
  return (
    <SystemProviders>
      <EntityLoginIdBlock entityId={entityId} />
      <EntityPasswordBlock entityUserId={entityUserId} entityId={entityId} />
    </SystemProviders>
  );
};

angularize(
  NgzLoginIdPasswordTab,
  'ngzLoginIdPasswordTab',
  angular.module('app.entityManagement'),
  {
    entityId: '<',
    entityUserId: '<',
  },
);

export { NgzLoginIdPasswordTab };
