import React from 'react';

import { AppBar, Toolbar } from '@mui/material';

import classes from './CompanyHeader.module.scss';
import { CompanySubLogo } from '../../../../../shared/components/ui-components';
import { ReactComponent as LogoIcon } from '../../../../../shared/assets/icons/logo.svg';

type CompanyHeaderProps = { companyLogoUrl?: string };

const CompanyHeader = ({ companyLogoUrl }: CompanyHeaderProps) => {
  return (
    <AppBar className={classes.header}>
      <Toolbar component="div">
        {companyLogoUrl ? <CompanySubLogo logoUrl={companyLogoUrl} /> : <LogoIcon />}
      </Toolbar>
    </AppBar>
  );
};

export { CompanyHeader };
