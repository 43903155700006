import React from 'react';

import classes from './TestsPlaceholder.module.scss';
import entityTestImg from '../../../../../assets/image/entityTestImg.png';

const TestsPlaceholder = () => {
  return (
    <div className={classes.container}>
      <div className={classes.placeholderBox}>
        <h4>Coaches are looking forward to when you add new tests</h4>
        <img src={entityTestImg} alt="placeholder" />
      </div>
    </div>
  );
};

export { TestsPlaceholder };
