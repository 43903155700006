import React from 'react';
import PropTypes from 'prop-types';

import StarRoundedIcon from '@mui/icons-material/StarRounded';

import classes from './StarText.module.scss';

const StarText = ({ children }) => {
  return (
    <div className={classes.textContainer}>
      <StarRoundedIcon fontSize="large" className={classes.rateIco} />
      <span>{children}</span>
    </div>
  );
};

StarText.propTypes = {
  children: PropTypes.node.isRequired,
};

export { StarText };
