import { makeAutoObservable } from 'mobx';
import { timeFormat } from '../../shared/constants/apiDateFormat';
import { resetAllStore } from '../../shared/stores/resetAllStore';
import { getSortedArrFromDateGroupedObj } from '../../shared/utils/sortingAndGroupingUtil';
import { activeCalendarService } from '../services/activeCalendarService';

class CustomerActiveCalendarStore {
  constructor() {
    this.setDefaultValues();
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.calendar = [];
    this.calendarForTomorrow = [];
    this.customerSupplements = {};
    this.dates = {};
    this.isActiveCalendarCreate = false;
  }

  async getDataForRender(customerId) {
    const [scheduler, customerSupplements] = await Promise.all([
      activeCalendarService.getSchedule(customerId),
      activeCalendarService.getCustomerSupplements(customerId),
    ]);
    const { lastUpdate, startDay, schedulerByDate } = scheduler;

    if (startDay) {
      this.setIsActiveCalendarCreate(true);
      this.setDates({ lastUpdate, startDay });
      this.setCustomerSupplements(customerSupplements);
      this.setCustomerCalendars(schedulerByDate);
    }
  }

  setCustomerSupplements(customerSupplements) {
    this.customerSupplements = customerSupplements;
  }

  setCustomerCalendars(schedulerByDate) {
    const schedulerByDateArr = Object.values(schedulerByDate);
    if (schedulerByDateArr.length === 1) {
      this.setOneCalendar(schedulerByDateArr);
    } else {
      this.setTwoCalendars(schedulerByDateArr);
    }
  }

  setOneCalendar(schedulerByDateArr) {
    const calendar = schedulerByDateArr[0].scheduler;
    const sortedCalendarArr = getSortedArrFromDateGroupedObj(calendar, timeFormat);
    this.setCalendar(sortedCalendarArr);
  }

  setTwoCalendars(schedulerByDateArr) {
    schedulerByDateArr.forEach((date) => {
      const sortedCalendarArr = getSortedArrFromDateGroupedObj(
        date.scheduler,
        timeFormat,
      );
      if (date.dateTo) {
        this.setCalendar(sortedCalendarArr);
      } else {
        this.setCalendarForTomorrow(sortedCalendarArr);
      }
    });
  }

  setCalendar(calendar) {
    this.calendar = calendar;
  }

  setCalendarForTomorrow(calendarForTomorrow) {
    this.calendarForTomorrow = calendarForTomorrow;
  }

  setDates(dates) {
    this.dates = dates;
  }

  setIsActiveCalendarCreate(isActiveCalendarCreate) {
    this.isActiveCalendarCreate = isActiveCalendarCreate;
  }
}

const customerActiveCalendarStore = new CustomerActiveCalendarStore();

resetAllStore.addResetMethod(customerActiveCalendarStore.setDefaultValues);

export { customerActiveCalendarStore, CustomerActiveCalendarStore };
