import React from 'react';

import Link from '@mui/material/Link';
import { Button } from '@mui/material';

import classes from './RedirectToMobileApp.module.scss';
import { TextInfo } from '../../components/TextInfo/TextInfo';
import MobileAppImg from '../../../../assets/image/mobileAppImg.webp';
import AppStoreIcon from '../../../../../shared/assets/icons/AppStoreIcon.svg';
import GooglePlayIcon from '../../../../../shared/assets/icons/GooglePlayIcon.svg';

const RedirectToMobileApp = () => {
  return (
    <>
      <TextInfo
        title="Your password has been set up!"
        text="If you don’t have the LifeBase Solutions app on your device, you can download it from the links below."
      />
      <div className={classes.infoBlock}>
        <img src={MobileAppImg} alt="Mobile App" />
        <p className={classes.infoText}>
          To login to the LifeBase Solutions app, you need: to use your email as a login,
          the password that you have currently set up.
        </p>
        <div className={classes.buttonBlock}>
          <Button
            variant="outlined"
            href="https://apps.apple.com/ua/app/lifebase-nutrition-platform/id1439582683"
          >
            <img src={AppStoreIcon} alt="app store" />
          </Button>
          <Button
            variant="outlined"
            href="https://play.google.com/store/apps/details?id=com.lifebase.app&pcampaignid=web_share"
          >
            <img src={GooglePlayIcon} alt="GooglePlay store" />
          </Button>
        </div>
        <p className={classes.footerText}>
          If you have any problems, please contact &nbsp;
          <Link href="mailto:support@lifebasesolutions.com">
            support@lifebasesolutions.com
          </Link>
        </p>
      </div>
    </>
  );
};

export { RedirectToMobileApp };
