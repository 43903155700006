import React from 'react';

import { Radio } from '@mui/material';

import classes from './SubscriptionCell.module.scss';
import { LbFormControlLabel } from '../../../../../../shared/components/form-components';

type SubscriptionCellProps = {
  name: string;
  subscriptionId: number;
};

const SubscriptionCell = ({ name, subscriptionId }: SubscriptionCellProps) => {
  return (
    <LbFormControlLabel
      value={subscriptionId}
      control={<Radio />}
      className={classes.cell}
      label={<p className={classes.name}>{name}</p>}
    />
  );
};

export { SubscriptionCell };
