import React from 'react';

import { observer } from 'mobx-react';

import {
  GridTableCell,
  GridTableRow,
} from '../../../../shared/components/table-components';
import { FilterButton, SortButtons } from '../../../../shared/components/buttons';
import { entityListStore } from '../../../stores/entityListStore';

const TitleRow = observer(() => {
  const { filterMethods } = entityListStore;
  const { isShowFilterRow, toggleShowFilterRow, handleSort, getIsSortedBy } =
    filterMethods;

  return (
    <GridTableRow variant="header" dataTestId="header-row">
      <GridTableCell dataTestId="business-name-cell">
        <SortButtons
          fieldName="BUSINESS_NAME"
          onSort={handleSort}
          getIsActive={getIsSortedBy}
        >
          Entity Name
        </SortButtons>
      </GridTableCell>
      <GridTableCell>Type</GridTableCell>
      <GridTableCell>InBody</GridTableCell>
      <GridTableCell dataTestId="owner-name-cell">
        <SortButtons
          fieldName="OWNER_NAME"
          onSort={handleSort}
          getIsActive={getIsSortedBy}
        >
          Owner name
        </SortButtons>
      </GridTableCell>
      <GridTableCell>Business phone</GridTableCell>
      <GridTableCell>Status</GridTableCell>
      <GridTableCell justify="end">
        <FilterButton isActive={isShowFilterRow} onClick={toggleShowFilterRow} />
      </GridTableCell>
    </GridTableRow>
  );
});

export { TitleRow };
