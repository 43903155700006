import { Components } from '@mui/material';

import { palette } from '../palette';

export const MuiDividerTheme: Components['MuiDivider'] = {
  styleOverrides: {
    root: {
      borderColor: palette.natural[300],
    },
  },
};
