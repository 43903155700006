import { useEffect, useState } from 'react';

import { CustomerGoHighLevelAccountInfo } from '../../../interfaces/goHighLevelAccount';

import { customerGoHighLevelAccountService } from '../../../services/customerGoHighLevelAccountService';

type UseCustomerGoHighLevelConnectionReturn = {
  accountInfo: CustomerGoHighLevelAccountInfo | undefined;
  refreshConnectionInfo: () => Promise<void>;
};

export const useCustomerGoHighLevelConnection = (
  customerId: number,
): UseCustomerGoHighLevelConnectionReturn => {
  const [accountInfo, setAccountInfo] = useState<
    CustomerGoHighLevelAccountInfo | undefined
  >(undefined);

  const getAccountInfo = async () => {
    const resp =
      await customerGoHighLevelAccountService.getConnectedAccountInfo(customerId);

    setAccountInfo(resp);
  };

  useEffect(() => {
    getAccountInfo();
  }, [customerId]);

  const refreshConnectionInfo = async () => {
    const resp =
      await customerGoHighLevelAccountService.refreshConnectionInfo(customerId);

    setAccountInfo(resp);
  };

  return {
    accountInfo,
    refreshConnectionInfo,
  };
};
