import React from 'react';

import { SnackbarProvider } from 'notistack';
import { LbSnackbarAlert } from './LbSnackbarAlert/LbSnackbarAlert';

type LbSnackbarProviderProps = {
  children?: React.ReactNode;
};

/**
 * Used to create toast notification, based on Mui Alert component
 * Not use useSneakbar hook, use enqueueSnackbar and closeSnackbar from notistack
 *
 * Can be used several times in one app, now used in NgzSnackbarNotification create one instance,
 * after delete angular use in App component
 */
const LbSnackbarProvider = ({ children }: LbSnackbarProviderProps) => {
  return (
    <SnackbarProvider
      Components={{
        success: LbSnackbarAlert,
        error: LbSnackbarAlert,
        warning: LbSnackbarAlert,
        info: LbSnackbarAlert,
      }}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      variant="success"
      autoHideDuration={null}
    >
      {children}
    </SnackbarProvider>
  );
};

// more info about this override: https://notistack.com/features/customization#custom-variant-(typescript)
declare module 'notistack' {
  interface VariantOverrides {
    default: false;
  }
}

export { LbSnackbarProvider };
