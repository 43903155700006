import React from 'react';

import { Button } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import classes from './FiltersRow.module.scss';
import { LbInput } from '../../../../../../../shared/components/form-components';
import { CoachListFilterFormValues } from '../../../../../../interfaces/coachList/CoachListFilters';
import { coachStore } from '../../../../../../stores/customerSelfSetupStores/coachStore';
import { GridTableRow } from '../../../../../../../shared/components/table-components';

type FiltersRowProps = {
  isMobile: boolean;
};

const FiltersRow = observer(({ isMobile }: FiltersRowProps) => {
  const { filterMethods } = coachStore;

  const { control, reset } = useFormContext<CoachListFilterFormValues>();

  const inputGeneralProps = { control, variant: 'outlined', size: 'small' } as const;

  const handleReset = () => {
    reset({
      coachType: filterMethods.filterData.coachType,
    });
  };

  const filterClasses = classNames(classes.container, {
    [classes.mobileLayout]: isMobile,
  });

  return (
    <GridTableRow variant="unstyled" customLayout={isMobile} className={filterClasses}>
      <LbInput {...inputGeneralProps} name="coachName" label="Coach Name" />
      <LbInput {...inputGeneralProps} name="entityName" label="Business Name" />
      <Button variant="outlined" fullWidth size="small" onClick={handleReset}>
        Clear All
      </Button>
    </GridTableRow>
  );
});

export { FiltersRow };
