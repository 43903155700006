import React from 'react';

import classNames from 'classnames';

import classes from './EmptyList.module.scss';
import { ReactComponent as EmptyListImg } from '../../../assets/image/emptyList.svg';
import { ReactComponent as SearchNotFoundImg } from '../../../assets/image/searchNotFound.svg';

type EmptyListProps = {
  show: boolean;
  isSearch?: boolean | null;
  description: string;
  searchDescription?: string | null;
  className?: string;
  size?: 'small' | 'medium';
};

const EmptyList = ({
  show,
  isSearch = false,
  description,
  searchDescription = 'No results found',
  size = 'medium',
  className,
}: EmptyListProps) => {
  if (!show) return null;

  const emptyListBoxClasses = classNames(classes.emptyListBox, classes[size], className);

  return (
    <div className={emptyListBoxClasses}>
      {isSearch ? <SearchNotFoundImg /> : <EmptyListImg />}
      <p className={classes.description}>
        {isSearch && searchDescription ? searchDescription : description}
      </p>
    </div>
  );
};

export { EmptyList };
