import React, { ChangeEvent } from 'react';

import { Paper } from '@mui/material';
import { angularize } from 'react-in-angularjs';
import { observer } from 'mobx-react';

import classes from './NgzEntityProfileCard.module.scss';
import { SystemProviders } from '../../../shared/providers';
import { StripeInfoChip } from '../../../payment';
import { PaymentFlowType } from '../../../shared/constants/user-info/paymentFlowType';
import { InbodyTypeKey } from '../../../shared/constants/inbody-info/InbodyType';
import { getHumanizePaymentFlowType } from '../../../shared/utils/userInfoUtils';
import { processInbodyTypes } from './utils/processInbodyTypes';
import { StatusInfo } from './components/StatusInfo/StatusInfo';
import { Point } from './components/Point/Point';
import { useEntityStatusInfo } from './hooks/useEntityStatusInfo';
import { getIsNullish } from '../../../shared/utils/jsUtils';
import { AvatarIcon } from '../../../shared/components/ui-components';
import { UploadAvatar } from './components/UploadAvatar/UploadAvatar';
import { loggedInUserDataStore } from '../../../shared/stores/loggedInUserDataStore';

type NgzEntityProfileCardProps = {
  entityId: number;
  gymName?: string;
  avatar?: string | null;
  paymentFlowType?: PaymentFlowType;
  supportedInbodyTypes?: Record<InbodyTypeKey, boolean>;
  allowNonInbodyUsers?: boolean;
  isStripeAccountConnected?: boolean;
  onAvatarChange: (data: { newAvatar: File }) => void;
};

const NgzEntityProfileCard = observer(
  ({
    entityId,
    gymName,
    avatar,
    paymentFlowType,
    supportedInbodyTypes,
    allowNonInbodyUsers,
    isStripeAccountConnected,
    onAvatarChange,
  }: NgzEntityProfileCardProps) => {
    const { isEntity } = loggedInUserDataStore;
    const { status } = useEntityStatusInfo(entityId);

    const humanizeInbodyTypes = processInbodyTypes(
      supportedInbodyTypes,
      allowNonInbodyUsers,
    );

    const handleUploadAvatar = async (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        onAvatarChange({ newAvatar: event.target.files[0] });
      }
    };

    return (
      <SystemProviders>
        <Paper variant="roundedBox" className={classes.container}>
          <div className={classes.avatarBox}>
            <AvatarIcon userName={gymName} avatarImgLink={avatar} size="large" />
            {isEntity && <UploadAvatar onAvatarChange={handleUploadAvatar} />}
          </div>
          <div className={classes.infoBox}>
            <h3 className={classes.name}>{gymName}</h3>
            <div className={classes.descriptionBox}>
              {paymentFlowType && (
                <span>{getHumanizePaymentFlowType(paymentFlowType)}</span>
              )}

              {!!humanizeInbodyTypes.length && (
                <>
                  <Point />
                  <div className={classes.inbodyInfo}>
                    {humanizeInbodyTypes.map((inbodyType) => (
                      <span key={inbodyType}>{inbodyType}</span>
                    ))}
                  </div>
                </>
              )}
              <Point />
              {status && <StatusInfo status={status} />}
            </div>
          </div>
          <div className={classes.extraOptionsBox}>
            {!getIsNullish(isStripeAccountConnected) && (
              <StripeInfoChip isStripeConnected={isStripeAccountConnected} />
            )}
          </div>
        </Paper>
      </SystemProviders>
    );
  },
);

angularize(NgzEntityProfileCard, 'ngzEntityProfileCard', angular.module('app'), {
  entityId: '<',
  gymName: '<',
  avatar: '<',
  paymentFlowType: '<',
  supportedInbodyTypes: '<',
  allowNonInbodyUsers: '<',
  isStripeAccountConnected: '<',
  onAvatarChange: '&',
});

export { NgzEntityProfileCard };
