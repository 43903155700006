import React, { FormEvent } from 'react';

import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentMethod } from '@stripe/stripe-js';

type StripeSetCardFormProps = {
  formId: string;
  onSubmit: (paymentMethodId: string) => void;
  setLoading: (loading: boolean) => void;
};

const StripeSetCardForm = ({ formId, onSubmit, setLoading }: StripeSetCardFormProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const createPaymentMethod = async (): Promise<PaymentMethod> => {
    if (!stripe || !elements) {
      throw new Error('Stripe or Elements not loaded');
    }

    await elements.submit(); // set error to stripe

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      elements,
    });

    if (error) {
      throw new Error(error.message);
    }

    return paymentMethod;
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);

    try {
      const paymentMethod = await createPaymentMethod();
      onSubmit(paymentMethod.id);
    } catch (error) {
      console.error('Error creating payment method:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form id={formId} onSubmit={handleSubmit}>
      <PaymentElement
        id="card-info-box" // A&A test id
        options={{ wallets: { applePay: 'never', googlePay: 'never' } }}
      />
    </form>
  );
};

export { StripeSetCardForm };
