import React from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react';

import { LbModal } from '../../../../../../../shared/components/modals/LbModal/LbModal';
import { apiStatusStore } from '../../../../../../../shared/stores/apiStatusStore';
import { InventoryInputs } from '../InventoryInputs/InventoryInputs';

const RecordOrderModal = observer(({ open, closeHandler, recordOrderHandler }) => {
  const { control, handleSubmit } = useForm({ mode: 'onBlur' });
  const { isLoading } = apiStatusStore;

  const onSubmit = async (data) => {
    await recordOrderHandler(data);
    closeHandler();
  };

  return (
    <LbModal
      open={open}
      title="Add New Order"
      isLoading={isLoading}
      primaryBtnConfig={{ text: 'Add New Order', onClick: handleSubmit(onSubmit) }}
      secondaryBtnConfig={{ onClick: closeHandler }}
    >
      <InventoryInputs control={control} />
    </LbModal>
  );
});

RecordOrderModal.propTypes = {
  open: PropTypes.bool,
  closeHandler: PropTypes.func,
  recordOrderHandler: PropTypes.func,
};

export { RecordOrderModal };
