import { useEffect, useState } from 'react';

export const useGetDataWithLoading = (func) => {
  const [isLoading, setLoading] = useState(false);

  const getData = async () => {
    try {
      setLoading(true);
      await func();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return isLoading;
};
