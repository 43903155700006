const autoNgTemplateLoaderTemplate1 = require('./schoolFees.html');

SchoolFeesController.$inject = ['utilService', 'constants', 'ApiService', 'ModalService'];

function SchoolFeesController(utilService, constants, ApiService, ModalService) {
  const vm = this;

  vm.entityId;
  vm.schoolFees = {};
  vm.loggedInUser = utilService.getLoggedInUserData();
  vm.isParent = vm.loggedInUser.isLoggedByParent;
  vm.spinner = false;

  vm.$onInit = () => {
    vm.url = `api/entity-fees/${vm.entityId}`;
    vm.getSchoolFees();
  }

  vm.getSchoolFees = () => {
    vm.spinner = true;
    ApiService.get(vm.url)
      .then(res => {
        if (res.status === 200) {
          vm.schoolFees = res.data;
        }
      })
      .finally(() => vm.spinner = false);
  };

  vm.saveSchoolFees = () => {
    vm.spinner = true;
    ApiService.patch(vm.url, vm.schoolFees)
      .then(res => {
        if (res.status === 200) {
          ModalService.savedChanges();
        }
      })
      .finally(() => vm.spinner = false);
  };
}

angular.module('app.entityProfile').component('schoolFees', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: SchoolFeesController,
  controllerAs: 'vm',
  bindings: {
    entityId: '<',
  }
});
