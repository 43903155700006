import React from 'react';

import { observer } from 'mobx-react';

import classes from './DateSubTitle.module.scss';
import { getFormattedDate } from '../../../../../../../../shared/utils/dateUtils';
import { localDataFormat } from '../../../../../../../../shared/constants/apiDateFormat';
import { customerActiveCalendarStore } from '../../../../../../../stores/customerActiveCalendarStore';

const DateSubTitle = observer(() => {
  const { isActiveCalendarCreate, dates } = customerActiveCalendarStore;

  if (!isActiveCalendarCreate) {
    return null;
  }

  const { startDay, lastUpdate } = dates;

  const formattedStartDay = getFormattedDate(startDay, localDataFormat);
  const formattedLastUpdate = getFormattedDate(lastUpdate, localDataFormat);

  return (
    <div className={classes.date}>
      <p>Start date:</p>
      <h6>{formattedStartDay}</h6>
      {lastUpdate && (
        <>
          <p>Last update:</p>
          <h6>{formattedLastUpdate}</h6>
        </>
      )}
    </div>
  );
});

export { DateSubTitle };
