'use strict';

angular.module('app').controller('PaginationNextBackController', PaginationNextBackController);

PaginationNextBackController.$inject = ['$scope'];

function PaginationNextBackController($scope) {
    var vm = this;

    vm.skip = $scope.skip;
    vm.rowCount = $scope.rowCount;
    vm.offset = $scope.offset;
    vm.isLastPageEmpty = false;

    vm.increaseSkip = increaseSkip;
    vm.decreaseSkip = decreaseSkip;
    vm.reset = reset;

    $scope.pagination.reset = reset;

    reset($scope.rowCount, $scope.skip);

    $scope.$on('goBack', function() {
        vm.decreaseSkip();
        vm.isLastPageEmpty = true;
    });

    function increaseSkip() {
        if (vm.rowCount >= vm.offset) {
            vm.skip += vm.offset;
            $scope.onChange({skip: vm.skip});
        }
    }

    function decreaseSkip() {
        vm.isLastPageEmpty = false;
        if (vm.skip >= vm.offset) {
            vm.skip -= vm.offset;
            $scope.onChange({skip: vm.skip});
        }
    }

    function reset(rowCount, skip) {
        vm.rowCount = rowCount;
        vm.skip = skip;
    }
}