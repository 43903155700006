import React from 'react';
import PropTypes from 'prop-types';

import classes from './ComplianceAverageRow.module.scss';
import {
  GridTableCell,
  GridTableRow,
} from '../../../../shared/components/table-components';
import { ComplianceDataCells } from '../../ComplianceDataCells/ComplianceDataCells';

const ComplianceAverageRow = ({ averageComplianceInfo, daysCount }) => {
  return (
    <GridTableRow
      variant="subRow"
      className={classes.averageRow}
      dataTestId="compliance-average-row"
    >
      <GridTableCell justify="start">Average for {daysCount} days</GridTableCell>
      <ComplianceDataCells complianceInfo={averageComplianceInfo} />
    </GridTableRow>
  );
};

ComplianceAverageRow.propTypes = {
  averageComplianceInfo: PropTypes.shape({
    breakfast: PropTypes.number,
    daily: PropTypes.number,
    dinner: PropTypes.number,
    lunch: PropTypes.number,
    snack1: PropTypes.number,
    snack2: PropTypes.number,
    snack3: PropTypes.number,
    water: PropTypes.shape({ current: PropTypes.number, goal: PropTypes.number }),
    sodium: PropTypes.shape({ current: PropTypes.number, goal: PropTypes.number }),
  }),
  daysCount: PropTypes.number,
};

export { ComplianceAverageRow };
