const autoNgTemplateLoaderTemplate1 = require('./add-recipe.template.html');

'use strict';

angular
    .module('app')
    .directive('addRecipe', addRecipe);

addRecipe.$inject = [];

function addRecipe() {
    return {
        restrict: 'E',
        scope: {
            recipeId: '='
        },
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: 'AddRecipeController',
        controllerAs: 'vm'
    };
}
