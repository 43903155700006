import React, { ReactNode } from 'react';

import classNames from 'classnames';
import { Paper } from '@mui/material';

import classes from './SelectedInfoRow.module.scss';

type SelectedInfoRowProps = {
  selectedItemCount: number;
  children: ReactNode;
  isSubGridRow?: boolean;
};

const SelectedInfoRow = ({
  selectedItemCount,
  children,
  isSubGridRow,
}: SelectedInfoRowProps) => {
  if (!selectedItemCount) {
    return null;
  }

  const rowClasses = classNames(classes.selectInfoRow, {
    [classes.subGridRow]: isSubGridRow,
  });

  return (
    <Paper variant="roundedBox" className={rowClasses}>
      <p>{selectedItemCount} selected items</p>
      <div className={classes.optionsBox}>{children}</div>
    </Paper>
  );
};

export { SelectedInfoRow };
