import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useFormContext, useWatch } from 'react-hook-form';
import moment from 'moment';

import classes from './ModalInputs.module.scss';
import { LbDatePicker } from '../../../../../../shared/components/form-components';
import { SelectorsGroup } from '../SelectorsGroup/SelectorsGroup';

const ModalInputs = ({ selectorsSettings, reportType }) => {
  const { control, reset } = useFormContext();
  const startDate = useWatch({ name: 'from' });

  useEffect(() => reset, []);

  const dateFormat = 'MM.DD.YYYY';

  const defaultDatePickerProps = {
    control,
    defaultToday: true,
    disableFuture: true,
    format: dateFormat,
    openTo: 'month',
  };

  return (
    <div>
      <SelectorsGroup selectors={selectorsSettings} reportType={reportType} />
      <div className={classes.datePickerBox}>
        <LbDatePicker
          {...defaultDatePickerProps}
          name="from"
          label="Start Date"
          rules={{ required: 'Start Date is required' }}
        />
        <LbDatePicker
          {...defaultDatePickerProps}
          name="to"
          label="End Date"
          minDate={moment(startDate, dateFormat)}
          rules={{ required: 'End Date is required' }}
        />
      </div>
    </div>
  );
};

ModalInputs.propTypes = {
  reportType: PropTypes.string,
  selectorsSettings: PropTypes.array,
};

export { ModalInputs };
