import React from 'react';

import { Paper } from '@mui/material';
import { observer } from 'mobx-react';

import classes from './EntityTestsBox.module.scss';
import { RowTemplate } from '../../../components/RowTemplate/RowTemplate';
import { SortedTests } from '../SortedTests/SortedTests';
import { DeactivatedTests } from '../DeactivatedTests/DeactivatedTests';
import { medicalTestsStore } from '../../../../../stores/medicalTestsStore';
import { prepTestsToRender } from './EntityTestsBox.settings';

const EntityTestsBox = observer(() => {
  const { tests } = medicalTestsStore;

  const { sortedTests, deactivatedTests } = prepTestsToRender(tests);

  return (
    <Paper variant="roundedBox" className={classes.testBox}>
      <RowTemplate cellOne="Name of Lab Test" cellTwo="Date of Added / Last Update" />
      <SortedTests sortedTests={sortedTests} />
      <DeactivatedTests tests={deactivatedTests} />
    </Paper>
  );
});

export { EntityTestsBox };
