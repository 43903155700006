'use strict';

angular.module('app').factory('GoalService', GoalService);

GoalService.$inject = ['ApiService', 'utilService'];

function GoalService(ApiService, utilService) {
    return {
        getAdjustments: function (prms) {
            return ApiService.get('api/coach/user/goal-adjustments', prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getAllGoals: function (userId) {
            var url = utilService.getLoggedInUserData().role === 'CUSTOMER' ? 'api/user/goals' : 'api/coach/' + userId + '/goals';

            return ApiService.get(url).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getOneGoal: function (userId, id) {
            var url = utilService.getLoggedInUserData().role === 'CUSTOMER' ? 'api/user/goal/' + id : 'api/coach/' + userId + '/goals/' + id;

            return ApiService.get(url).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        addGoal: function (userId, data, prms) {
            var url = utilService.getLoggedInUserData().role === 'CUSTOMER' ? 'api/user/goals' : 'api/coach/' + userId + '/goals';

            return ApiService.post(url, {
                name: data.name,
                startDate: data.startDate,
                targetDate: data.targetDate,
                targetBodyFatPercent: data.targetBodyFatPercent / 100,
                targetWeight: data.targetWeight
            }, prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        updateGoal: function (userId, data) {
            var url = utilService.getLoggedInUserData().role === 'CUSTOMER' ? 'api/user/goal/' + data.id : 'api/coach/' + userId + '/goals/' + data.id;

            return ApiService.put(url, {
                name: data.name,
                startDate: data.startDate,
                targetDate: data.targetDate,
                targetBodyFatPercent: data.targetBodyFatPercent / 100,
                targetWeight: data.targetWeight
            }).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getClosestScanData: function (userId, data) {
            var url = utilService.getLoggedInUserData().role === 'CUSTOMER' ? 'api/user/closest-scan-data/' + data : 'api/coach/' + userId + '/closest-scan-data/' + data;

            return ApiService.get(url).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        updateNotified: function (userId, id) {
            var url = utilService.getLoggedInUserData().role === 'CUSTOMER' ? 'api/user/goal/notified/' + id : 'api/coach/' + userId + '/goals/notified/' + id;

            return ApiService.post(url).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        compareByDate: function (a, b) {
            var dateA = moment(new Date(a.startDate));
            var dateB = moment(new Date(b.startDate));

            if (dateA > dateB)
                return -1;
            if (dateA < dateB)
                return 1;
            return 0;
        }
    };
}

