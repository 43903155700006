import React from 'react';

import classes from './VersionInfoTable.module.scss';
import { InfoCell } from '../../components/InfoCell/InfoCell';
import { useVersionInfo } from './hooks/useVersionInfo';

const VersionInfoTable = () => {
  const { apiVersion, uiVersion } = useVersionInfo();

  return (
    <div className={classes.versionInfoTable}>
      <InfoCell title="UI:" info={uiVersion.type} />
      <InfoCell title="Version:" info={uiVersion.build} />
      <InfoCell title="Date:" info={uiVersion.date} />

      <InfoCell title="API:" info={apiVersion.type} />
      <InfoCell title="Version:" info={apiVersion.build} />
      <InfoCell title="Date:" info={apiVersion.date} />
    </div>
  );
};

export { VersionInfoTable };
