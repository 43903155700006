import { makeAutoObservable } from 'mobx';

export class StepperStore {
  activeStep: number = 0;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.activeStep = 0;
  }

  goToNextStep() {
    this.activeStep += 1;
  }

  goToStep(num: number) {
    if (this.activeStep > num) {
      this.activeStep = num;
    }
  }

  goToPreviousStep() {
    this.goToStep(--this.activeStep);
  }

  skipStep() {
    this.activeStep += 2;
  }
}
