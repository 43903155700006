'use strict';

angular.module('app').factory('CoachNotesService', CoachNotesService);

CoachNotesService.$inject = ['ApiService'];

function CoachNotesService(ApiService) {
    return {
        createCoachNote: function (data) {
            return ApiService.post('api/coach/users-notes/create', data).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        createCoachNoteAsSuper: function (data) {
            return ApiService.post('api/super/users-notes/create', data).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        editNote: function (id, data) {
            return ApiService.put('api/coach/users-notes/' + id + '/edit', data).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getAllCoachNotes: function (prms) {
            return ApiService.get('api/coach/users-notes/all', prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getUsersByTheirNames: function () {
            return ApiService.get('api/coach/user-names').then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getNoteByUserId: function (id, prms) {
            return ApiService.get('api/coach/users-notes/' + id, prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        removeNote: function (id) {
            return ApiService.delete('api/coach/users-notes/' + id + '/remove').then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        }
    };
}

