import { useEffect, useMemo, useState } from 'react';

import { ChartData } from 'chart.js';

import {
  PerformanceNutrient,
  PerformanceScoreInfo,
} from '../../../interfaces/PerformanceScoreInfo';
import { statisticsService } from '../../../services/statisticsService';
import { getChartData } from '../utils/getChartData';

const defaultPerformanceNutrient: PerformanceNutrient = {
  carbs: 0,
  fat: 0,
  protein: 0,
  sugar: 0,
  veggies: 0,
};

const defaultPerformanceScore: PerformanceScoreInfo = {
  entireSystem: defaultPerformanceNutrient,
  myClients: defaultPerformanceNutrient,
};

export const usePerformanceScoreChart = (): ChartData<'bar'> => {
  const [performanceScore, setPerformanceScore] = useState<PerformanceScoreInfo>(
    defaultPerformanceScore,
  );

  const getPerformanceScore = async () => {
    const data = await statisticsService.getPerformanceScore();
    setPerformanceScore(data);
  };

  useEffect(() => {
    getPerformanceScore();
  }, []);

  return useMemo(() => getChartData(performanceScore), [performanceScore]);
};
