import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';

import { registrationStepperStore } from '../../../../stores/registrationStepperStore';
import { ButtonRow } from '../../components/ButtonRow/ButtonRow';
import { LbDatePicker } from '../../../../../shared/components/form-components';
import { supplementsStore } from '../../../../stores/supplementsStore';
import { activeCalendarService } from '../../../../services/activeCalendarService';
import { ActiveCalendarForm } from '../../../../components/ActiveCalendarForm/ActiveCalendarForm';

const ActiveCalendarStep = observer(({ fieldName }) => {
  const { userId, isActiveCalendarSent, goToNextStep, setIsActiveCalendarSent } =
    registrationStepperStore;

  const { getSupplements } = supplementsStore;

  const { sendActiveCalendar } = activeCalendarService;

  const {
    control,
    handleSubmit,
    getValues,
    formState: { isValid },
  } = useFormContext();

  const formArrName = `${fieldName}.newSupplementScheduleJsons`;

  useEffect(() => {
    getSupplements();
  }, []);

  const isBtnDisabled = () => {
    return !isValid || !getValues(formArrName)?.length;
  };

  const onSubmit = async (data) => {
    await sendActiveCalendar(data[fieldName], userId, isActiveCalendarSent);
    setIsActiveCalendarSent();
    goToNextStep();
  };

  return (
    <>
      <LbDatePicker
        control={control}
        name={`${fieldName}.validFrom`}
        label="Start date"
        disablePast
        defaultToday
        fullWidth={false}
      />
      <ActiveCalendarForm formArrName={formArrName} />
      <ButtonRow handlerNext={handleSubmit(onSubmit)} isDisabled={isBtnDisabled()} />
    </>
  );
});

ActiveCalendarStep.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

export { ActiveCalendarStep };
