import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';
import moment from 'moment';

import classes from './DeactivatedTestRow.module.scss';
import { RowTemplate } from '../../../../components/RowTemplate/RowTemplate';

const DeactivatedTestRow = ({ nameOfTest, date, activateTest }) => {
  const ActivateBtn = (
    <Button variant="outlined" size="small" onClick={activateTest}>
      Activate
    </Button>
  );

  return (
    <RowTemplate
      cellOne={nameOfTest}
      cellTwo={moment(date).format('DD MMM YYYY')}
      cellThree={ActivateBtn}
      className={classes.deactivateRow}
    />
  );
};

DeactivatedTestRow.propTypes = {
  nameOfTest: PropTypes.string,
  date: PropTypes.any,
  activateTest: PropTypes.func,
};

export { DeactivatedTestRow };
