import React from 'react';
import PropTypes from 'prop-types';

import {
  ComboBox,
  LbInput,
} from '../../../../../../../../shared/components/form-components';
import { ConfirmButtonsBlock } from '../../../../../../../../shared/components/buttons/ConfirmButtonsBlock/ConfirmButtonsBlock';
import { GridTableCell } from '../../../../../../../../shared/components/table-components';
import useRowForm from './hooks/useRowForm';
import { inbodyKeysStore } from '../../../../../../../stores/inbodyKeysStore';

const EditableView = ({ inbodyKeyInfo, setIsEditable, index }) => {
  const { entities } = inbodyKeysStore;
  const { formMethods, handleClose, handleSubmit } = useRowForm(
    inbodyKeyInfo,
    setIsEditable,
  );
  const { control, formState } = formMethods;

  const inputDefaultProps = {
    size: 'small',
    control,
  };

  return (
    <>
      <GridTableCell variant="title">{index + 1}</GridTableCell>
      <ComboBox
        {...inputDefaultProps}
        disableClearable={false}
        name="entityId"
        valueKey="id"
        labelKey="business_name"
        options={entities}
      />
      <LbInput
        {...inputDefaultProps}
        name="inbodyKey"
        rules={{ required: 'Please fill out this required field to proceed.' }}
      />
      <LbInput
        {...inputDefaultProps}
        name="accountName"
        rules={{ required: 'Please fill out this required field to proceed.' }}
      />
      <LbInput {...inputDefaultProps} name="description" />
      <GridTableCell justify="end">
        <ConfirmButtonsBlock
          yesText="Save"
          noText="Cancel"
          size="small"
          yesDisabled={!formState.isValid || !formState.isDirty}
          onCancel={handleClose}
          onConfirm={handleSubmit}
        />
      </GridTableCell>
    </>
  );
};

EditableView.propTypes = {
  setIsEditable: PropTypes.func.isRequired,
  inbodyKeyInfo: PropTypes.shape({
    entityName: PropTypes.string,
    inbodyKey: PropTypes.string,
    accountName: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.number,
    entityId: PropTypes.number,
  }).isRequired,
  index: PropTypes.number.isRequired,
};

export { EditableView };
