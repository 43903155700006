import { Flags, ValueOfKey } from '../../utils-types';

export const paidModuleTypes = {
  BASE_SYSTEM_PRICE: 'BASE_SYSTEM_PRICE',
  MARKETING: 'MARKETING',
  NUTRITION: 'NUTRITION',
  SUBSCRIPTION_MANAGEMENT: 'SUBSCRIPTION_MANAGEMENT',
  SUPPLEMENT_MEMBER: 'SUPPLEMENT_MEMBER',
  ACTIVE_CALENDAR: 'ACTIVE_CALENDAR',
  SCHOLASTIC: 'SCHOLASTIC',
} as const;

export type PaidModuleType = ValueOfKey<typeof paidModuleTypes>;
export type PaidModuleTypeKey = keyof typeof paidModuleTypes;
export type PaidModuleTypeFlags = Flags<PaidModuleTypeKey>;
