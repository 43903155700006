import React from 'react';
import PropTypes from 'prop-types';

import { Paper } from '@mui/material';

import classes from './MedicalTestCard.module.scss';
import { ResultRow } from './ResultRow/ResultRow';
import { FileRow } from './FileRow/FileRow';
import { CardHeader } from './CardHeader/CardHeader';

const MedicalTestCard = ({ testName, results, onDeleteTest, onEditTest }) => {
  const resultsRows = results.map((result) => {
    const { uploadedTestFiles, ...resultInfo } = result;
    return (
      <div key={result.id} className={classes.resultRow}>
        <ResultRow resultInfo={resultInfo} />
        <FileRow uploadedTestFiles={uploadedTestFiles} />
      </div>
    );
  });

  return (
    <Paper className={classes.testBox} variant="roundedBox">
      <CardHeader
        testName={testName}
        onDeleteTest={onDeleteTest}
        onEditTest={onEditTest}
      />
      <div className={classes.resultBox}>{resultsRows}</div>
    </Paper>
  );
};

MedicalTestCard.propTypes = {
  testName: PropTypes.string,
  results: PropTypes.array,
  onDeleteTest: PropTypes.func,
  onEditTest: PropTypes.func,
};

export { MedicalTestCard };
