(function () {
    'use strict';

    angular.module('app.certificates')
        .controller('CertificatesController', CertificatesController);

    CertificatesController.$inject = [
        'CertificationsService',
        'ModalService',
        'GeneralService',
        'utilService'
    ];

    function CertificatesController(CertificationsService, ModalService, GeneralService, utilService) {
        var vm = this;

        vm.loggedInUser = utilService.getLoggedInUserData();
        vm.spinner = {active: false};
        vm.coaches = [];
        vm.availableCertificates = {};
        vm.filters = {
            page: 0,
            limit: 10
        };
        vm.pagination = {};
        vm.pagesCount = 0;
        vm.isChildEntity = utilService.getIsChildEntity();

        vm.createMoodleAccount = createMoodleAccount;
        vm.getAllCoaches = getAllCoaches;
        vm.filterCoaches = filterCoaches;
        vm.buyCertificates = buyCertificates;
        vm.assignCertificate = assignCertificate;
        vm.updateUserPaymentSource = updateUserPaymentSource;
        vm.showUpdateCardDialog = showUpdateCardDialog;
        vm.getAvailableCertificates = getAvailableCertificates;
        vm.getStripePublicKey = getStripePublicKey;

        vm.getAllCoaches(0);
        vm.getStripePublicKey();
        vm.getAvailableCertificates();

        function createMoodleAccount(coach) {
            vm.spinner.active = true;
            CertificationsService.createAccount(coach.id).then(function (response) {
                vm.spinner.active = false;
                if (response.status === 200) {
                    coach.registered = true;
                    ModalService.alert('Credentials were sent to your email. Please check you mail box.', 'Success');
                }
            });
        }

        function getStripePublicKey() {
            GeneralService.getStripePublicKey().then(function (res) {
                if (res.status === 200) {
                    var image = 'https://content.lifebase.solutions/static-content/logo.png';
                    var key = res.data.key;
                    if (key) {
                        vm.buyCertificateConf = window.StripeCheckout.configure({
                            key: key,
                            image: image,
                            locale: 'auto',
                            panelLabel: 'Pay',
                            token: vm.updateUserPaymentSource
                        });
                    }
                }
            });

        }

        function updateUserPaymentSource(token) {
            vm.spinner.active = true;
            CertificationsService.buyBundleOfCertificates({token: token.id}, vm.selectedCertificate.id, vm.loggedInUser.id).then(function (res) {
                vm.spinner.active = false;
                if (res.status === 200) {
                    vm.getAvailableCertificates();
                    ModalService.savedChanges(null, 'Payment successful');
                }
            });
        }

        function getAvailableCertificates() {
            CertificationsService.getAvailableCertificates().then(function (res) {
                if (res.status === 200) {
                    vm.availableCertificates = res.data;
                }
            });
        }

        function showUpdateCardDialog(selectedCertificate) {
            vm.selectedCertificate = selectedCertificate;
            vm.buyCertificateConf.open({
                email: vm.loggedInUser.email,
                name: vm.loggedInUser.firstName + ' ' + vm.loggedInUser.lastName,
                description: 'Buy Certificate',
                currency: 'usd'
            });
        }

        function getAllCoaches(page) {
            vm.filters.page = page;
            CertificationsService.getAllCoaches(vm.filters).then(function (res) {
                if (res.status === 200) {
                    vm.coaches = res.data.items;
                    vm.pagesCount = res.data.page.count;
                }
            });
        }

        function filterCoaches() {
            if (vm.pagination.reset) {
                vm.pagination.reset();
            }
            vm.getAllCoaches(0);
        }

        function buyCertificates(certLevel) {
            if (vm.availableCertificates[certLevel]) {
                ModalService.buyCertificates(vm.availableCertificates[certLevel].plans, vm.showUpdateCardDialog);
            }
        }

        function assignCertificate(coach, certType) {
            CertificationsService.assignCertificate(coach.id, certType).then(function (res) {
                if (res.status === 200) {
                    ModalService.certificationAssigned();
                    vm.getAvailableCertificates();
                    switch (certType) {
                        case 'LEVEL_1':
                            coach.certificateL1 = 'ASSIGNED';
                            break;
                        case 'LEVEL_2':
                            coach.certificateL2 = 'ASSIGNED';
                            break;
                    }
                }
            })
        }
    }
})();
