import { apiService } from '../../shared/services/apiService';
import { CheckoutInfo, PaymentInfo } from '../interfaces/checkoutInfo';

export const checkoutService = {
  async createSubscription(entityId: number, email: string, subscriptionSetupId: number) {
    const params = { entityId, email, subscriptionSetupId };

    const response = await apiService.post<CheckoutInfo>(
      '/api/sign-up/customer/subscribe',
      params,
    );

    return response.data;
  },

  async createFreeSubscription(
    entityId: number,
    email: string,
    subscriptionSetupId: number,
  ) {
    const params = { entityId, email, subscriptionSetupId };

    const response = await apiService.post<PaymentInfo>(
      '/api/sign-up/customer/free-subscribe',
      params,
    );

    return response.data;
  },

  async getPaymentDetails(paymentIntentId: string, connectedAccountId: string) {
    const params = { paymentIntentId, connectedAccountId };

    const response = await apiService.post<PaymentInfo>(
      '/api/sign-up/payment/details',
      params,
    );

    return response.data;
  },
};
