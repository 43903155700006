import React from 'react';
import PropTypes from 'prop-types';

import { IconButton } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { useFormContext } from 'react-hook-form';

import classes from './ResultFormRow.module.scss';
import { LbInput, LbDatePicker } from '../../../../../shared/components/form-components';
import { TestResultStatusesCheckboxes } from '../TestResultStatusesCheckboxes/TestResultStatusesCheckboxes';
import { LabTestInfoTooltip } from '../../LabTestInfoTooltip/LabTestInfoTooltip';
import { openConfirmationModal } from '../../../../../shared/features/confirmation';

const ResultFormRow = ({ onDeleteResult, showDeleteBtn, nameIndex }) => {
  const { control, watch } = useFormContext();

  const handleDeleteResult = () => {
    openConfirmationModal({
      title: 'Do you want to delete this Results?',
      text: 'You will lose the added Information',
      confirmBtnConfig: {
        onClick: onDeleteResult,
      },
    });
  };

  const timeFormat = 'YYYY-MM-DD HH:mm:ss';
  const testDate = watch(`${nameIndex}testDate`);

  return (
    <div className={classes.testRow}>
      <div className={classes.resultDateCell}>
        <LabTestInfoTooltip testDate={testDate} />
        <LbDatePicker
          control={control}
          name={`${nameIndex}testDate`}
          label="Test Date"
          format={timeFormat}
          required
          rules={{ required: 'Test Date is required' }}
        />
      </div>
      <LbInput control={control} name={`${nameIndex}notes`} label="Notes" />
      <TestResultStatusesCheckboxes nameIndex={nameIndex} />
      {showDeleteBtn && (
        <div>
          <IconButton onClick={handleDeleteResult}>
            <CancelRoundedIcon fontSize="large" />
          </IconButton>
        </div>
      )}
    </div>
  );
};

ResultFormRow.propTypes = {
  onDeleteResult: PropTypes.func,
  showDeleteBtn: PropTypes.bool,
  nameIndex: PropTypes.string.isRequired,
};

export { ResultFormRow };
