import React from 'react';

import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react';

import { Stepper } from '../Stepper/Stepper';
import { stepperStore } from '../../../../stores/stepperStore';
import classes from './Header.module.scss';

const Header = observer(() => {
  const { activeStep, stepsCount } = stepperStore;

  return (
    <Box className={classes.container}>
      <Typography className={classes.typography}>
        Finish registration of your child
      </Typography>
      <Stepper steps={stepsCount} activeStep={activeStep} />
    </Box>
  );
});

export { Header };
