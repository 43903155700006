import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { CustomComparisonFilterType } from '../../../../../interfaces/CustomFilterData';
import { COMPARISON } from '../../../../../constants/comparison';
import { CustomFilterFormValues } from '../../AddCustomFilterForm.settings';

export const useBetweenComparison = (filterType: CustomComparisonFilterType) => {
  const [isBetween, setIsBetween] = useState(false);

  const comparisonValue = useWatch<CustomFilterFormValues>({
    name: `filters.${filterType}.comparison`,
  });

  useEffect(() => {
    if (comparisonValue === COMPARISON.BETWEEN) {
      setIsBetween(true);
    } else {
      setIsBetween(false);
    }
  }, [comparisonValue]);

  return isBetween;
};
