import React from 'react';

import classes from './MealDetailsRow.module.scss';
import { RegularMealName } from '../../../../../../../shared/constants/mealNames';
import { processMealName } from '../../../../../../../shared/utils/mealUtils';
import { getPercentages } from '../../../../../../../shared/utils/mathUtils';
import { LbLinearProgress } from '../../../../../../../shared/components/ui-components';
import { MealStatisticInfo } from '../../../../../../interfaces/performance-metrics/MealStatistic';
import { pluralize } from '../../../../../../../shared/utils/stringUtils';

type MealDetailsRowProps = {
  meal: RegularMealName;
  mealInfo: MealStatisticInfo;
};

const MealDetailsRow = ({ meal, mealInfo }: MealDetailsRowProps) => {
  const { mealsTracked, mealsTotal } = mealInfo;

  const percentage = getPercentages(mealsTracked, mealsTotal, 0);

  return (
    <div className={classes.row}>
      <div className={classes.percentageCell}>{percentage}%</div>
      <div className={classes.mealNameCell}>{processMealName(meal)}</div>
      <div className={classes.progressCell}>
        <LbLinearProgress value={percentage} color="success" />
      </div>
      <div className={classes.mealCountCell}>
        <span className={classes.trackedCount}>{mealsTracked}</span> /&nbsp;
        {pluralize(mealsTotal, 'meal')}
      </div>
    </div>
  );
};

export { MealDetailsRow };
