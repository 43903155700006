// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../assets/images/reached-goal.png");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<md-dialog class=\"reached-goal-dialog\" layout=\"column\" layout-align=\"center center\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"reached goal\"> <p class=\"reached-goal-dialog__title\">You have reached a goal!</p> <p class=\"reached-goal-dialog__description\">Congratulations! You did a great job! Now you can set up a new goal to be reached</p> <div layout=\"row\" layout-align=\"center center\"> <button class=\"reached-goal-dialog__btn reached-goal-dialog__btn-cancel\" ng-click=\"cancel()\">Cancel</button> <button id=\"add-goal-btn\" class=\"reached-goal-dialog__btn reached-goal-dialog__btn-add\" ng-click=\"add()\">+ Add new goal</button> </div> </md-dialog> ";
// Exports
var _module_exports = code;;
var path = 'app/modals/reached-goal.modal.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;