import React from 'react';

import classes from './TableHeader.module.scss';
import { Cell } from '../Cell/Cell';

const TableHeader = () => {
  return (
    <div className={classes.tableHeader}>
      <Cell text="Time" />
      <Cell text="Supplement" />
      <Cell text="Value" />
      <Cell text="Left" />
      <Cell text="Status" />
    </div>
  );
};

export { TableHeader };
