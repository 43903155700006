import React, { MouseEventHandler, ReactNode } from 'react';

import { Button, Modal, ModalProps, Paper } from '@mui/material';

import classes from './LbModal.module.scss';
import { Spinner } from '../../Spinner/Spinner';

type ButtonConfig = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  startIcon?: ReactNode;
  disabled?: boolean;
  text?: string;
};

type LbModalProps = {
  title?: string;
  text?: string;
  imgSrc?: string;
  isLoading?: boolean;
  renderBtnBox?: ReactNode;
  primaryBtnConfig?: ButtonConfig;
  secondaryBtnConfig?: ButtonConfig;
  children?: ReactNode;
} & Pick<ModalProps, 'open' | 'onClose'>;

/**
 * @description Show or hide depending on the onClick function passed.
 * Default text is 'Yes' and 'Cancel'.
 */
const LbModal = ({
  open,
  title,
  text,
  imgSrc,
  children,
  isLoading = false,
  renderBtnBox,
  onClose,
  primaryBtnConfig,
  secondaryBtnConfig,
}: LbModalProps) => {
  const { text: secondaryText, ...secondaryBtnProps } = secondaryBtnConfig || {};
  const { text: primaryText, ...primaryBtnProps } = primaryBtnConfig || {};

  const defaultBtnBox = (
    <div className={classes.buttonBox}>
      {!!secondaryBtnConfig?.onClick && (
        <Button variant="outlined" size="medium" {...secondaryBtnProps}>
          {secondaryText || 'Cancel'}
        </Button>
      )}

      {!!primaryBtnConfig?.onClick && (
        <Button variant="contained" size="medium" {...primaryBtnProps}>
          {primaryText || 'Yes'}
        </Button>
      )}
    </div>
  );

  return (
    <Modal open={open} onClose={onClose} data-test-id={testIds.modalBox}>
      <Paper variant="box" className={classes.modalPaper}>
        {imgSrc && (
          <div className={classes.imageBox}>
            <img src={imgSrc} alt="modal img" />
          </div>
        )}
        <div className={classes.modalText}>
          <h2 className={classes.modalTitle}>{title}</h2>
          {text && <p>{text}</p>}
        </div>
        {!!children && <div>{children}</div>}
        {renderBtnBox || defaultBtnBox}
        <Spinner isLoading={isLoading} />
      </Paper>
    </Modal>
  );
};

const testIds = { modalBox: 'modalBox' };

export { LbModal, testIds, LbModalProps, ButtonConfig };
