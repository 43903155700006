import React from 'react';
import PropTypes from 'prop-types';

import { Link } from '@mui/material';

import { getLinkToCustomerProfile, getScheduledDay } from './AppointmentCell.settings';
import { customerMedicalTestsStore } from '../../../../stores/customerMedicalTestsStore';
import { MED_TEST_RESULT_STATUSES_KEYS } from '../../../../constants/MED_TEST_RESULT_STATUSES_KEYS';

const { APPOINTMENT_COMPLETED } = MED_TEST_RESULT_STATUSES_KEYS;

const AppointmentCell = ({
  customerId,
  labTestStartDate,
  appointmentStatus,
  testResultId,
}) => {
  const { setMedTestResultIdForInitialEdit, setMedTestFieldNameForInitialFocus } =
    customerMedicalTestsStore;

  const scheduledDay = getScheduledDay(labTestStartDate, appointmentStatus);
  const linkToCustomerProfile = getLinkToCustomerProfile(customerId);

  const onLinkClick = () => {
    setMedTestResultIdForInitialEdit(testResultId);

    if (!appointmentStatus) {
      setMedTestFieldNameForInitialFocus(APPOINTMENT_COMPLETED);
    }
  };

  return (
    <Link href={linkToCustomerProfile} onClick={onLinkClick}>
      {scheduledDay}
    </Link>
  );
};

AppointmentCell.propTypes = {
  customerId: PropTypes.number,
  labTestStartDate: PropTypes.string,
  appointmentStatus: PropTypes.bool,
  testResultId: PropTypes.number,
};

export { AppointmentCell };
