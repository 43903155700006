const autoNgTemplateLoaderTemplate1 = require('./customer-setup-step-1.template.html');

'use strict';

angular
    .module('app')
    .directive('customerSetupStep1', customerSetupStep1);

customerSetupStep1.$inject = [];

function customerSetupStep1() {
    return {
        restrict: 'E',
        scope: {
            createUser: '=',
            userGroup: '=',
            inbodyDataList: '=',
            currentEntity: '=',
            next: '&onNext'
        },
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: 'CustomerSetupStep1Controller',
        controllerAs: 'vm'
    };
}
