const autoNgTemplateLoaderTemplate1 = require('./score-table.template.html');

'use strict';

(function () {
    angular
        .module('app.scoreTable', [])
        .config(scoreTableConfig);

    scoreTableConfig.$inject = ['$routeProvider'];

    function scoreTableConfig($routeProvider) {
        $routeProvider
            .when('/score-table', {
                disableCache: true,
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'ScoreTableController',
                controllerAs: 'vm',
                resolve: {
                    loginRequired: ['AuthService', function (AuthService) {
                        return AuthService.loginRequired();
                    }]
                }
            })
    }
})();
