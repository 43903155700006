import React from 'react';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

import classes from './CheckPointsList.module.scss';

export const points = [
  'Set up password',
  'Have your client download app',
  'Confirm that your client has digital access to the Nutrition Playbook, Meal Prep & Recipe Guide',
  'Confirm that your client can access the Onboarding Series',
  'Review in-app shortcuts with your client',
  'Review in-app training videos, success factors & FAQ',
  'Help your client log a few meals using shortcuts and save some to Favorites (5 Star)',
  'Talk about the importance of SMS & email educational campaigns',
];

const CheckPointsList = () => {
  return (
    <div className={classes.listBox}>
      {points.map((point) => (
        <div className={classes.listItem} key={point}>
          <CheckRoundedIcon fontSize="medium" color="success" />
          <span>{point}</span>
        </div>
      ))}
    </div>
  );
};

export { CheckPointsList };
