export const downloadFile = (path, fileName) => {
  const anchor = document.createElement('a');
  anchor.href = path;
  anchor.download = fileName;

  document.body.appendChild(anchor);

  anchor.click();

  document.body.removeChild(anchor);
};
