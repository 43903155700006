import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from '@mui/material';

import { getDaysToDate } from '../../../../shared/utils/dateUtils';
import { getDaysToTestTitle } from '../../../utils/labTestUtils';
import { LabTestInfoIcon } from '../LabTestInfoIcon/LabTestInfoIcon';

// tag div is used for compensation of line-height which is missing in svg and add ref for Tooltip

const LabTestInfoTooltip = ({ testDate }) => {
  const daysToTest = getDaysToDate(testDate);
  const daysToTestTitle = getDaysToTestTitle(daysToTest);

  return (
    <Tooltip title={daysToTestTitle}>
      <div>
        <LabTestInfoIcon daysToTest={daysToTest} />
      </div>
    </Tooltip>
  );
};

LabTestInfoTooltip.propTypes = {
  testDate: PropTypes.string,
};

export { LabTestInfoTooltip };
