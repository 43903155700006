import React from 'react';

import { Paper } from '@mui/material';

import classes from './GeneralInfoRow.module.scss';
import { InfoCell } from './InfoCell/InfoCell';
import { CustomerGeneralInfo } from '../../../../../interfaces/PredefinedMealPlansInfo';

type GeneralInfoRowProps = {
  customerGeneralInfo: CustomerGeneralInfo;
};

const GeneralInfoRow = ({ customerGeneralInfo }: GeneralInfoRowProps) => {
  const { bmr, gender, height, weight } = customerGeneralInfo;

  return (
    <Paper variant="roundedBox" className={classes.box}>
      <InfoCell title="Basal Metabolic Rate (BMR)" info={bmr} adornment="kcal / day" />
      <InfoCell title="Gender" info={gender} />
      <InfoCell title="Age" info={16} />
      <InfoCell title="Height" info={height} adornment="in" />
      <InfoCell title="Weight" info={weight} />
    </Paper>
  );
};

export { GeneralInfoRow };
