import React from 'react';

import { Button, SvgIcon } from '@mui/material';

import { Mode } from '../../../../general-types';
import { ConfirmButtonsBlock } from '../../../../shared/components/buttons/ConfirmButtonsBlock/ConfirmButtonsBlock';
import { ReactComponent as PenIcon } from '../../../../shared/assets/icons/pen.svg';

type EditButtonBlockProps = {
  mode: Mode;
  onEdit: () => void;
  onCancel: () => void;
  onSubmit: () => void;
  disabledSubmit?: boolean;
};

const EditButtonBlock = ({
  mode,
  onEdit,
  onCancel,
  onSubmit,
  disabledSubmit,
}: EditButtonBlockProps) => {
  return mode === 'VIEW' ? (
    <Button
      variant="text"
      size="small"
      color="secondary"
      onClick={onEdit}
      startIcon={<SvgIcon component={PenIcon} inheritViewBox fontSize="small" />}
    >
      Edit
    </Button>
  ) : (
    <ConfirmButtonsBlock
      onCancel={onCancel}
      onConfirm={onSubmit}
      yesDisabled={disabledSubmit}
      noText="Cancel"
      yesText="Save"
      size="small"
    />
  );
};

export { EditButtonBlock };
