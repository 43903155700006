import React from 'react';

import { Control, useController } from 'react-hook-form';
import { RadioCard } from '../../../components/RadioCard/RadioCard';
import classes from './CollaborationTypeRow.module.scss';
import { CoachListFilterFormValues } from '../../../../../interfaces/coachList/CoachListFilters';
import { TitleWithDescription } from '../../../../../../shared/components/text-formation-components';

type IsLocalCoachRowProps = {
  control: Control<CoachListFilterFormValues>;
};

const CollaborationTypeRow = ({ control }: IsLocalCoachRowProps) => {
  const {
    field: { value, onChange },
  } = useController({
    control,
    name: 'coachType',
  });

  return (
    <div className={classes.container}>
      <RadioCard onClick={onChange} value="OFFLINE" checked={value === 'OFFLINE'}>
        <TitleWithDescription
          title="Local in-person coach"
          description="Your initial consultation, and check-ins, will be conducted at your coach's place of business. Please select your coach below."
        />
      </RadioCard>
      <RadioCard onClick={onChange} value="REMOTE" checked={value === 'REMOTE'}>
        <TitleWithDescription
          title="To work with a coach remotely"
          description="You will meet with your personal coach via video call and communicate via text and email. You'll have our exclusive at-home InBody scale from which you will conduct your check-ins."
        />
      </RadioCard>
    </div>
  );
};

export { CollaborationTypeRow };
