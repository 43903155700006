import { apiService } from '../../shared/services/apiService';
import { BILLING_PERIOD_TYPE } from '../constants/billingPeriodType';
import {
  SubscriptionSettings,
  CreateSubscriptionSettingsInfo,
} from '../interfaces/subscriptionSettings';

export const subscriptionSettingsService = {
  async getSubscriptionSettings(entityId: number) {
    const resp = await apiService.get<SubscriptionSettings>(
      `/api/entity/${entityId}/subscription-settings`,
    );

    return resp.data;
  },

  async setupSubscriptionSettings(
    entityId: number,
    settingsFormValue: CreateSubscriptionSettingsInfo,
  ) {
    const settings: Omit<SubscriptionSettings, 'id'> = {
      ...settingsFormValue,
      billingPeriodType: BILLING_PERIOD_TYPE.MONTHLY,
      billingPeriodValue: 1,
    };

    const resp = await apiService.post<SubscriptionSettings>(
      `/api/entity/${entityId}/subscription-settings`,
      settings,
    );

    return resp.data;
  },

  async updateSubscriptionSettings(entityId: number, settings: SubscriptionSettings) {
    const resp = await apiService.put<SubscriptionSettings>(
      `/api/entity/${entityId}/subscription-settings/${settings.id}`,
      settings,
    );

    return resp.data;
  },
};
