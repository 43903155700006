const autoNgTemplateLoaderTemplate1 = require('./tax.template.html');

'use strict';

angular
  .module('app.entityProfile')
  .directive('tax', tax);

tax.$inject = [];

function tax() {
  return {
    restrict: 'E',
    scope: {
      entityId: '<',
      tax: '='
    },
    templateUrl: autoNgTemplateLoaderTemplate1,
    controller: 'TaxController',
    controllerAs: 'vm'
  };
}
