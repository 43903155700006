import React from 'react';

import classes from './CurrentPrice.module.scss';
import { getPriceView } from '../../../../../../shared/utils/stringUtils';

type CurrentPriceProps = {
  price: number | null;
  description: string;
};

const CurrentPrice = ({ price, description }: CurrentPriceProps) => {
  if (!price) {
    return null;
  }

  return (
    <p className={classes.currentPrice}>
      {getPriceView(price)}
      <span className={classes.description}> / {description}</span>
    </p>
  );
};

export { CurrentPrice };
