import React from 'react';

import { Link } from '@mui/material';

import classes from './TermsLink.module.scss';

const TermsLinks = () => {
  return (
    <p className={classes.termsLabel}>
      LifeBase&nbsp;
      <Link className={classes.link} href="/useragreement.html" target="_blank">
        Terms & Conditions
      </Link>
      &nbsp;and&nbsp;
      <Link className={classes.link} href="/privacyoverview.html" target="_blank">
        Privacy policy
      </Link>
    </p>
  );
};

export { TermsLinks };
