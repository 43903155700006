import { useEffect, useState } from 'react';

import { CustomersTotalLostFatInfo } from '../../../interfaces/CustomersTotalLostFatInfo';
import { statisticsService } from '../../../services/statisticsService';

const defaultInfo: CustomersTotalLostFatInfo = {
  total: 0,
  members: 0,
};

export const useCustomersFatLost = () => {
  const [customersTotalLostFatInfo, setCustomersTotalLostFatInfo] =
    useState<CustomersTotalLostFatInfo>(defaultInfo);

  const getCustomersTotalLostFatInfo = async () => {
    const customersInfo = await statisticsService.getCustomersTotalLostFatInfo();

    setCustomersTotalLostFatInfo(customersInfo);
  };

  useEffect(() => {
    getCustomersTotalLostFatInfo();
  }, []);

  return customersTotalLostFatInfo;
};
