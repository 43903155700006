import React from 'react';

import classes from './MealHeaderRow.module.scss';
import {
  GridTableCell,
  GridTableRow,
} from '../../../../../../../shared/components/table-components';
import { InfoTooltip } from '../../../../../../../shared/components/ui-components';

const MealHeaderRow = () => {
  return (
    <GridTableRow variant="unstyled" className={classes.tableHeader}>
      <GridTableCell>Name</GridTableCell>
      <GridTableCell className={classes.infoCell}>
        <span>Proteins</span>
        <InfoTooltip>Proteins must be divisible by 7</InfoTooltip>
      </GridTableCell>
      <GridTableCell className={classes.infoCell}>
        <span>Carbs</span>
        <InfoTooltip>Carbs must be divisible by 15</InfoTooltip>
      </GridTableCell>
      <GridTableCell className={classes.infoCell}>
        <span>Fats</span>
        <InfoTooltip>Fats must be divisible by 5</InfoTooltip>
      </GridTableCell>
      <GridTableCell>Calories</GridTableCell>
    </GridTableRow>
  );
};

export { MealHeaderRow };
