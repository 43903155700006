// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../assets/images/vectors/leaderboard_construction_dark.svg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<div class=\"coach-dashboard\"> <div layout=\"row\"> <div flex=\"50\" class=\"app-card\"> <div class=\"app-card-header\" layout=\"row\" layout-align=\"space-between center\"> <p class=\"app-card-header__title\"> Avg Performance Statistic </p> </div> <div class=\"app-card-body\"> <figure class=\"under-construction\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"> <figcaption class=\"under-construction__description\"> Block is under construction. </figcaption> </figure> </div> </div> <div flex=\"50\" class=\"app-card\"> <div class=\"app-card-header\" layout=\"row\" layout-align=\"space-between center\"> <p class=\"app-card-header__title\"> Average Statistic </p> </div> <div class=\"app-card-body\"> <figure class=\"under-construction\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"> <figcaption class=\"under-construction__description\"> Block is under construction. </figcaption> </figure> </div> </div> </div> </div> ";
// Exports
var _module_exports = code;;
var path = 'app/components/coach-dashboard/coach-dashboard.template.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;