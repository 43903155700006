import React from 'react';
import PropTypes from 'prop-types';

import { Paper, Typography } from '@mui/material';

import classes from './PaperWithTitle.module.scss';

const PaperWithTitle = ({ title, customTitle, content, hasTopMargin }) => {
  const sx = hasTopMargin ? { mt: 2 } : null;

  return (
    <Paper variant="box" className={classes.container} sx={sx}>
      <div className={classes.titleContainer}>
        {customTitle ?? <Typography className={classes.title}>{title}</Typography>}
      </div>
      <div className={classes.line} />
      <div className={classes.bodyContainer}>{content}</div>
    </Paper>
  );
};

PaperWithTitle.propTypes = {
  title: PropTypes.string,
  customTitle: PropTypes.node,
  content: PropTypes.node.isRequired,
  hasTopMargin: PropTypes.bool,
};

export { PaperWithTitle };
