import PropTypes from 'prop-types';

export const subscriptionProps = {
  active: PropTypes.bool,
  autoRenewal: PropTypes.bool,
  initialDaysOfSubscription: PropTypes.number,
  initialSubscriptionAmount: PropTypes.number,
  renewDaysOfSubscription: PropTypes.number,
  renewalSubscriptionAmount: PropTypes.number,
  subscriptionID: PropTypes.number,
  subscriptionType: PropTypes.string,
  title: PropTypes.string,
};
