import {
  OLD_MEAL_NAMES,
  REGULAR_MEAL_NAME,
  WORKOUT_MEAL_NAME,
} from '../../../constants/mealNames';

export const processMealName = (mealName) => {
  return mealNamesMap[mealName] || mealName;
};

const mealNamesMap = {
  [OLD_MEAL_NAMES.SNACK1]: 'Snack-1',
  [OLD_MEAL_NAMES.SNACK2]: 'Snack-2',
  [OLD_MEAL_NAMES.SNACK3]: 'Snack-3',
  [OLD_MEAL_NAMES.PRE_WORKOUT]: 'Pre Workout',
  [OLD_MEAL_NAMES.POST_WORKOUT]: 'Post Workout',
  [REGULAR_MEAL_NAME.breakfast]: 'Breakfast',
  [REGULAR_MEAL_NAME.lunch]: 'Lunch',
  [REGULAR_MEAL_NAME.dinner]: 'Dinner',
  [REGULAR_MEAL_NAME.snack1]: 'Snack-1',
  [REGULAR_MEAL_NAME.snack2]: 'Snack-2',
  [REGULAR_MEAL_NAME.snack3]: 'Snack-3',
  [WORKOUT_MEAL_NAME.postWorkout]: 'Post Workout',
  [WORKOUT_MEAL_NAME.preWorkout]: 'Pre Workout',
};
