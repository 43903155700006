import React from 'react';

import { angularize } from 'react-in-angularjs';
import { Container } from '@mui/material';

import classes from './NgzCustomersStatisticsRow.module.scss';
import { SystemProviders } from '../../../shared/providers';
import { CustomersFatLostWidget } from '../CustomersFatLostWidget/CustomersFatLostWidget';
import { AvgPerformanceScoreChart } from '../AvgPerformanceScoreChart/AvgPerformanceScoreChart';

const NgzCustomersStatisticsRow = () => {
  return (
    <SystemProviders>
      <Container>
        <div className={classes.statisticRow}>
          <CustomersFatLostWidget />
          <AvgPerformanceScoreChart />
        </div>
      </Container>
    </SystemProviders>
  );
};

angularize(
  NgzCustomersStatisticsRow,
  'ngzCustomersStatisticsRow',
  angular.module('app'),
  {},
);

export { NgzCustomersStatisticsRow };
