import React from 'react';
import PropTypes from 'prop-types';

import { MarkRow } from '../../../components/MarkRow/MarkRow';
import { DeactivatedTestRow } from './DeactivatedTestRow/DeactivatedTestRow';
import { medicalTestsStore } from '../../../../../stores/medicalTestsStore';

const DeactivatedTests = ({ tests }) => {
  const { activateTest } = medicalTestsStore;

  const isDeactivatedTestsShow = !!tests.length;

  return (
    isDeactivatedTestsShow && (
      <>
        <MarkRow text="Deactivated Lab Tests" />
        {tests.map((test) => (
          <DeactivatedTestRow
            nameOfTest={test.testName}
            date={test.updatedOn}
            key={test.id}
            activateTest={() => activateTest(test.id)}
          />
        ))}
      </>
    )
  );
};

DeactivatedTests.propTypes = {
  tests: PropTypes.array,
};

export { DeactivatedTests };
