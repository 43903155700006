import { useMemo, useState } from 'react';

import { keyBy } from 'lodash';

import { InbodyScanMetrics } from '../../../../../interfaces/customerInbodyData';

export type CompareDates = {
  startScanDate: string;
  endScanDate: string;
};

export type UseCompareScansReturn = {
  startScan: InbodyScanMetrics | null;
  endScan: InbodyScanMetrics | null;
  getCompareScans: (dates: CompareDates) => void;
};

export const useCompareScans = (
  scansInfo: InbodyScanMetrics[],
): UseCompareScansReturn => {
  const [startScan, setStartScan] = useState<InbodyScanMetrics | null>(null);
  const [endScan, setEndScan] = useState<InbodyScanMetrics | null>(null);

  const scansByDate = useMemo(() => keyBy(scansInfo, 'dateTimeScanOn'), [scansInfo]);

  const getCompareScans = ({ startScanDate, endScanDate }: CompareDates) => {
    if (startScanDate && endScanDate) {
      setStartScan(scansByDate[startScanDate] || null);
      setEndScan(scansByDate[endScanDate] || null);
    } else {
      setStartScan(null);
      setEndScan(null);
    }
  };

  return {
    startScan,
    endScan,
    getCompareScans,
  };
};
