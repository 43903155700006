import { Option } from '../../general-types';
import { ValueOfKey } from '../../utils-types';

export const CORPORATION_TYPE = {
  SOLE_PROPRIETORSHIP: 'SOLE_PROPRIETORSHIP',
  SINGLE_MEMBER_LLC: 'SINGLE_MEMBER_LLC',
  MULTI_MEMBER_LLC: 'MULTI_MEMBER_LLC',
  PRIVATE_PARTNERSHIP: 'PRIVATE_PARTNERSHIP',
  PRIVATE_CORPORATION: 'PRIVATE_CORPORATION',
} as const;

export type CorporationType = ValueOfKey<typeof CORPORATION_TYPE>;

export const corporationTypeOptions: Option<CorporationType>[] = [
  { label: 'Sole Proprietorship', value: 'SOLE_PROPRIETORSHIP' },
  { label: 'Single-member LLC', value: 'SINGLE_MEMBER_LLC' },
  { label: 'Multi-member LLC', value: 'MULTI_MEMBER_LLC' },
  { label: 'Private partnership', value: 'PRIVATE_PARTNERSHIP' },
  { label: 'Private corporation', value: 'PRIVATE_CORPORATION' },
];
