import React from 'react';

import { IconButton, SvgIcon, Switch } from '@mui/material';
import classNames from 'classnames';

import classes from './SchoolInfoRow.module.scss';
import {
  GridTableCell,
  GridTableRow,
} from '../../../../../../shared/components/table-components';
import { SeparatorLine } from '../../../../../../shared/components/ui-components';
import { SchoolInfo } from '../../../../../interfaces/SchoolInfo';
import { ReactComponent as PenIcon } from '../../../../../../shared/assets/icons/penBig.svg';
// TODO pen and penBig need update to one icon

type SchoolInfoRowProps = {
  onEdit: (schoolInfo: SchoolInfo) => void;
  onActivationStatusChange: (value: number, status: boolean) => void;
  schoolInfo: SchoolInfo;
};
// TODO disabled icon btn set theme style
const SchoolInfoRow = ({
  onEdit,
  onActivationStatusChange,
  schoolInfo,
}: SchoolInfoRowProps) => {
  const { name, amountOfCustomers, active, id } = schoolInfo;

  const handelEditShool = () => {
    onEdit(schoolInfo);
  };

  const handelShoolActivationChange = () => {
    onActivationStatusChange(id, !active);
  };

  const rowClasses = classNames({ [classes.deactivated]: !active });

  return (
    <GridTableRow variant="row" className={rowClasses}>
      <GridTableCell variant="title">{name}</GridTableCell>
      <GridTableCell>{amountOfCustomers}</GridTableCell>
      <GridTableCell justify="end" className={classes.optionBlock}>
        <Switch checked={active} onChange={handelShoolActivationChange} />
        <SeparatorLine />
        <IconButton color="secondary" onClick={handelEditShool} disabled={!active}>
          <SvgIcon component={PenIcon} inheritViewBox fontSize="large" />
        </IconButton>
      </GridTableCell>
    </GridTableRow>
  );
};

export { SchoolInfoRow };
