import {
  NUTRIENTS_ENUM,
  NutrientType,
} from '../../../../../../../../shared/constants/NUTRIENTS_ENUM';
import { RegularMealName } from '../../../../../../../../shared/constants/mealNames';
import {
  NutrientMetrics,
  NutrientMetricsGroupByMeal,
} from '../../../../../../../interfaces/performance-metrics/NutrientMetrics';

export const getNutrientMetricsGroupByMeal = (
  nutrientMetrics: NutrientMetrics,
): NutrientMetricsGroupByMeal => {
  const result = {} as NutrientMetricsGroupByMeal;

  Object.entries(nutrientMetrics).forEach(([nutrientKey, mealsInfo]) => {
    const nutrient = nutrientKey as Exclude<NutrientType, typeof NUTRIENTS_ENUM.WATER>;

    Object.entries(mealsInfo).forEach(([mealKey, mealValue]) => {
      const meal = mealKey as RegularMealName | 'avgOnMealsTracked';

      result[meal] = result[meal] || {};
      result[meal][nutrient] = mealValue;
    });
  });

  return result;
};
