import { Option } from '../../../general-types';
import { Flags, ValueOfKey } from '../../../utils-types';

const NEW_FLOW_2024_SUBSCRIPTIONS = {
  REGULAR: 'REGULAR',
  REACTIVATION: 'REACTIVATION',
  FREE_ACCESS: 'FREE_ACCESS',
} as const;

export const SUBSCRIPTION = {
  SUPPLEMENT: 'SUPPLEMENT',
  NON_INBODY: 'NON_INBODY',
  NUTRITION: 'NUTRITION',
  SCHOLARSHIP: 'SCHOLARSHIP',
  CORPORATE: 'CORPORATE',
  SCHOLASTIC: 'SCHOLASTIC',
  ...NEW_FLOW_2024_SUBSCRIPTIONS,
} as const;

export type SubscriptionType = ValueOfKey<typeof SUBSCRIPTION>;
export type SubscriptionTypeFlags = Flags<SubscriptionType>;
export type NewFlow2024SubscriptionType = ValueOfKey<typeof NEW_FLOW_2024_SUBSCRIPTIONS>;

export const newFlow2024SubscriptionTypeOptions: Option<NewFlow2024SubscriptionType>[] = [
  { label: 'Regular', value: 'REGULAR' },
  { label: 'Reactivation', value: 'REACTIVATION' },
];

export const newFlow2024SubscriptionTypeOptionsWithAll: Option<
  NewFlow2024SubscriptionType | ''
>[] = [{ label: 'All Subscriptions', value: '' }, ...newFlow2024SubscriptionTypeOptions];
