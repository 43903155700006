import { ChartArea } from 'chart.js';

import { palette } from '../../../../../../shared/theme/palette';

type DrawLabelOptions = {
  label: string;
  pointX: number;
  gapFromChartArea: number; // control position like general labels
};
// draw label on with background rectangle
export const drawLabel = (
  ctx: CanvasRenderingContext2D,
  chartArea: ChartArea,
  options: DrawLabelOptions,
) => {
  const { left, right, bottom } = chartArea;
  const { label, pointX, gapFromChartArea } = options;

  const textWidth = ctx.measureText(label).width;
  const rectPadding = 4;
  const rectHeight = 22;

  const labelY = bottom + gapFromChartArea;
  let labelX = pointX;

  ctx.save();

  // config draw center, left, right label
  if (pointX - textWidth / 2 < left) {
    labelX = left;
  } else if (pointX + textWidth / 2 > right) {
    labelX = right - textWidth;
  } else {
    labelX = pointX - textWidth / 2;
  }

  ctx.fillStyle = palette.white;
  ctx.fillRect(
    labelX - rectPadding,
    labelY - rectHeight / 2, // center the text
    textWidth + rectPadding * 2,
    rectHeight,
  );

  ctx.textBaseline = 'middle';
  ctx.textAlign = 'left';
  ctx.fillStyle = palette.mainBlack;
  ctx.fillText(label, labelX, labelY);

  ctx.restore();
};
