import React from 'react';
import { Switch } from '@mui/material';

import classes from './ExpirationDateSwitch.module.scss';

type ExpirationDateSwitchProps = {
  checked: boolean;
  onChange: () => void;
};

const ExpirationDateSwitch = ({ checked, onChange }: ExpirationDateSwitchProps) => {
  return (
    <div className={classes.container}>
      <Switch checked={checked} onChange={onChange} />
      <div>
        <p className={classes.title}>Set up a fixed expiration date</p>
        <p className={classes.subtitle}>
          After end date, the code becomes inactive, and the benefits cannot be redeemed
        </p>
      </div>
    </div>
  );
};

export { ExpirationDateSwitch };
