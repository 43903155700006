import React from 'react';

import { SvgIcon } from '@mui/material';

import classes from './EntityRow.module.scss';
import {
  GridTableCell,
  GridTableRow,
} from '../../../../shared/components/table-components';
import { EntityRowInfo } from '../../../interfaces/entity-list/EntityRowInfo';
import { StatusCell } from './StatusCell/StatusCell';
import { getHumanizeEntityType } from '../../../../shared/utils/userInfoUtils';
import { getHumanizeInbodyType } from '../../../../shared/utils/inbodyUtils';
import { ReactComponent as StripeIcon } from '../../../../shared/assets/icons/stripe.svg';

type EntityRowProps = {
  entityInfo: EntityRowInfo;
};

const EntityRow = ({ entityInfo }: EntityRowProps) => {
  const {
    entityId,
    businessName,
    isStripeAccountConnected,
    businessEntityType,
    inbodyType,
    ownerName,
    businessPhone,
    status,
    subscriptionExpire,
  } = entityInfo;

  return (
    <GridTableRow
      variant="row"
      component="a"
      href={`#!/entity-profile/${entityId}`}
      className={classes.entityRow}
      dataTestId="entity-row"
    >
      <GridTableCell
        variant="title"
        className={classes.businessNameCell}
        dataTestId="business-name-cell"
      >
        <p>{businessName}</p>

        {isStripeAccountConnected && (
          <SvgIcon
            component={StripeIcon}
            inheritViewBox
            fontSize="medium"
            data-test-id="stripe-icon"
          />
        )}
      </GridTableCell>
      <GridTableCell dataTestId="entity-type-cell">
        {getHumanizeEntityType(businessEntityType)}
      </GridTableCell>
      <GridTableCell dataTestId="inbody-type-cell">
        {getHumanizeInbodyType(inbodyType)}
      </GridTableCell>
      <GridTableCell className={classes.ownerCell} dataTestId="owner-name-cell">
        {ownerName}
      </GridTableCell>
      <GridTableCell dataTestId="business-phone-cell">{businessPhone}</GridTableCell>
      <GridTableCell dataTestId="status-cell">
        <StatusCell status={status} subscriptionExpire={subscriptionExpire} />
      </GridTableCell>
    </GridTableRow>
  );
};

export { EntityRow };
