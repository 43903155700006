import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import { SvgIcon } from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';
import { observer } from 'mobx-react';

import classes from './FoodInfoRow.module.scss';
import { ScoreForm } from '../ScoreForm/ScoreForm';
import { ReactComponent as UsersIcon } from '../../../../assets/icons/users.svg';
import { ReactComponent as NutritionIxIcon } from '../../../../assets/icons/nutrition-ix.svg';
import { useFoodInfoRow } from './hook/useFoodInfoRow';
import { NUTRIENTS } from '../../../../../shared/constants/NUTRIENTS';
import { LbModal } from '../../../../../shared/components/modals/LbModal/LbModal';
import { useModalToggle } from '../../../../../shared/hooks/useModalToggle';
import { Nutrient } from '../../../../../shared/components/ui-components/Nutrient/Nutrient';
import {
  GridTableCell,
  GridTableRow,
} from '../../../../../shared/components/table-components';

const FoodInfoRow = observer(({ food }) => {
  const { foodId, foodScoreResultList } = food;

  const [isOpenCancelModal, toggleIsOpenCancelModal] = useModalToggle();

  const {
    control,
    submitForm,
    onDelete,
    cancelHandler,
    cancelModalNoHandler,
    cancelModalYesHandler,
    isScoreFormOpen,
  } = useFoodInfoRow({
    food,
    toggleIsOpenCancelModal,
  });

  const foodSource = food.isRecipe ? 'R' : 'I';
  const foodSourceIcon = food.isNutritionIxFood ? NutritionIxIcon : UsersIcon;

  const nutrientCells = NUTRIENTS.map((nutrient) => (
    <GridTableCell key={nutrient} justify="center">
      <Nutrient nutrient={nutrient}>{food[nutrient]}</Nutrient>
    </GridTableCell>
  ));

  return (
    <>
      <GridTableRow
        variant="row"
        className={classes.row}
        onClick={cancelHandler}
        dataTestId={testIds.row}
      >
        <GridTableCell justify="center">
          <SvgIcon component={foodSourceIcon} fontSize="large" />
        </GridTableCell>
        <GridTableCell>{foodId}</GridTableCell>
        <GridTableCell>{foodSource}</GridTableCell>
        <GridTableCell>{food.productName}</GridTableCell>
        <GridTableCell>{food.amount}</GridTableCell>
        <GridTableCell>{food.amountUnit}</GridTableCell>
        {nutrientCells}
        <GridTableCell justify="center">
          <IconButton onClick={onDelete} aria-label="delete">
            <DeleteOutline fontSize="large" />
          </IconButton>
        </GridTableCell>
      </GridTableRow>
      {isScoreFormOpen && (
        <GridTableRow variant="row" customLayout className={classes.scoreFormBox}>
          <ScoreForm
            foodScoreResultList={foodScoreResultList}
            cancelHandler={cancelHandler}
            control={control}
            onSubmit={submitForm}
          />
        </GridTableRow>
      )}
      <LbModal
        open={isOpenCancelModal}
        text="Please save the scoring result; otherwise, it will be lost."
        onClose={toggleIsOpenCancelModal}
        primaryBtnConfig={{ text: 'Save', onClick: cancelModalYesHandler }}
        secondaryBtnConfig={{ onClick: cancelModalNoHandler }}
      />
    </>
  );
});

FoodInfoRow.propTypes = {
  food: PropTypes.shape({
    foodId: PropTypes.number,
    isRecipe: PropTypes.bool,
    isNutritionIxFood: PropTypes.bool,
    productName: PropTypes.string,
    amount: PropTypes.number,
    amountUnit: PropTypes.string,
    proteins: PropTypes.number,
    fats: PropTypes.number,
    carbs: PropTypes.number,
    sugar: PropTypes.number,
    fiber: PropTypes.number,
    sodium: PropTypes.number,
    calories: PropTypes.number,
    foodScoreResultList: PropTypes.array,
  }),
};

const testIds = { row: 'row' };

export { FoodInfoRow, testIds };
