const autoNgTemplateLoaderTemplate1 = require('./entity-setup.template.html');

'use strict';

(function () {
    angular
        .module('app.entitySetup', [])
        .config(entitySetupConfig);

    entitySetupConfig.$inject = ['$routeProvider'];

    function entitySetupConfig($routeProvider) {
        $routeProvider
            .when('/entity-setup', {
                disableCache: true,
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'EntitySetupController',
                controllerAs: 'vm',
                resolve: {
                    loginRequired: ['AuthService', function (AuthService) {
                        return AuthService.loginRequired();
                    }]
                }
            })
    }
})();
