import { loggedInUserDataStore } from '../../../stores/loggedInUserDataStore';
import { ANGULAR_EVENT_KEYS } from '../../../constants/system/ANGULAR_EVENT_KEYS';
import { LOCAL_STORAGE_KEYS } from '../../../constants/system/LOCAL_STORAGE_KEYS';
import { broadcastEvent } from '../../angularUtils';

export const updateUserInfo = (newLoggedInUserData) => {
  const userData = { ...loggedInUserDataStore.loggedInUser, ...newLoggedInUserData };

  localStorage.setItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER, JSON.stringify(userData));

  loggedInUserDataStore.setLoggedInUser(userData);

  broadcastEvent(ANGULAR_EVENT_KEYS.UPDATE_USER);
};
