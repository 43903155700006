import { useForm } from 'react-hook-form';
import { inbodyKeysStore } from '../../../../../../../../stores/inbodyKeysStore';

const useRowForm = (rowData, setIsEditable) => {
  const { editKey, decreaseEditableKeysCount } = inbodyKeysStore;
  const { inbodyKey, accountName, description, entityId, id } = rowData;
  const formMethods = useForm({
    mode: 'onBlur',
    defaultValues: {
      inbodyKey,
      accountName,
      description,
      entityId,
    },
  });

  const handleClose = () => {
    setIsEditable(false);
    decreaseEditableKeysCount();
  };

  const handleEdit = (data) => {
    editKey(id, data);
    formMethods.reset(data);
    handleClose();
  };

  return {
    formMethods,
    handleClose,
    handleSubmit: formMethods.handleSubmit(handleEdit),
  };
};

export default useRowForm;
