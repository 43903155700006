import React from 'react';

import {
  FieldValues,
  Path,
  PathValue,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import {
  TextField,
  Autocomplete,
  AutocompleteProps,
  TextFieldProps,
} from '@mui/material';

type LbAutocompleteProps<
  T extends FieldValues,
  TName extends Path<T>,
  TOption,
> = UseControllerProps<T, TName> &
  Pick<
    AutocompleteProps<TOption, false, true, false>,
    'options' | 'getOptionLabel' | 'isOptionEqualToValue'
  > &
  Pick<TextFieldProps, 'label' | 'variant' | 'size' | 'margin'>;

const LbAutocomplete = <T extends FieldValues, TName extends Path<T>, TOption>({
  control,
  name,
  rules,
  defaultValue = null as PathValue<T, TName>,
  options,
  label,
  variant,
  size,
  margin,
  shouldUnregister = false,
  getOptionLabel,
  isOptionEqualToValue,
}: LbAutocompleteProps<T, TName, TOption>) => {
  const {
    field: { ref, onChange, ...field },
    fieldState: { error },
  } = useController<any>({
    control,
    name,
    rules,
    defaultValue,
    shouldUnregister,
  });

  const isRequired = !!rules?.required;

  return (
    <Autocomplete
      {...field}
      onChange={(_, data) => onChange(data)}
      componentsProps={{
        popper: { className: 'general-style variant-select', placement: 'bottom-start' },
      }}
      size={size}
      disableClearable
      options={options}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      renderInput={(params) => (
        <TextField
          {...params}
          helperText={error?.message}
          error={!!error}
          label={label}
          required={isRequired}
          variant={variant}
          size={size}
          margin={margin}
          inputRef={ref}
        />
      )}
    />
  );
};

export { LbAutocomplete };
