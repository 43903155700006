import { REGULAR_MEAL_NAME } from '../../../../../../shared/constants/mealNames';
import { getEnumArray } from '../../../../../../shared/utils/jsUtils';
import {
  MealStatistic,
  MealStatisticInfo,
} from '../../../../../interfaces/performance-metrics/MealStatistic';

const mealStatisticInfo: MealStatisticInfo = {
  mealsTotal: 0,
  mealsTracked: 0,
};

export const mealsList = getEnumArray(REGULAR_MEAL_NAME);

export const defaultMealStatistic: MealStatistic = {
  ...mealStatisticInfo,
  breakfast: mealStatisticInfo,
  snack1: mealStatisticInfo,
  lunch: mealStatisticInfo,
  snack2: mealStatisticInfo,
  dinner: mealStatisticInfo,
  snack3: mealStatisticInfo,
};
