import { makeAutoObservable } from 'mobx';
import { set } from 'lodash';

import {
  CreateEntitySubscriptionInfo,
  EntitySubscriptionInfo,
} from '../interfaces/entitySubscription';
import { FilterStore } from '../../shared/util-stores';
import {
  EntitySubscriptionsFilterFormValue,
  EntitySubscriptionsSortField,
} from '../interfaces/entitySubscriptionFilters';
import { entitySubscriptionService } from '../services/entitySubscriptionService';
import { updateItemByField } from '../../shared/utils/arrayUtils';
import { resetAllStore } from '../../shared/stores/resetAllStore';
import { SpringFiltration } from '../../shared/interfaces/SpringFiltration';
import { paginationSmallListLimit } from '../../shared/constants/systemConstants';
import { SubscriptionOwnerRoleType } from '../interfaces/subscriptionOwnerInfo';

type EntitySubscriptionsFilterInfo = SpringFiltration<
  EntitySubscriptionsFilterFormValue,
  EntitySubscriptionsSortField
>;

class SubscriptionsManagementStore {
  private entityId: number | null = null;
  private ownerRoleType: SubscriptionOwnerRoleType;
  subscriptionsList: EntitySubscriptionInfo[] = [];
  filterMethods: FilterStore<
    EntitySubscriptionsFilterFormValue,
    EntitySubscriptionsSortField
  >;

  constructor(ownerRoleType: SubscriptionOwnerRoleType) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.ownerRoleType = ownerRoleType;
    this.filterMethods = new FilterStore<
      EntitySubscriptionsFilterFormValue,
      EntitySubscriptionsSortField
    >(this.handleGetSubscriptionsList, { elementsPerPage: paginationSmallListLimit });
  }

  setDefaultValues() {
    this.entityId = null;
    this.subscriptionsList = [];
    this.filterMethods.setDefaultValues();
  }

  private async handleGetSubscriptionsList(filterInfo: EntitySubscriptionsFilterInfo) {
    if (!this.entityId) {
      throw new Error('entityId is not set');
    }

    const data = await entitySubscriptionService.getFilteredEntitySubscriptions(
      this.entityId,
      set(filterInfo, 'filters.generalFilter.ownerRoleType', this.ownerRoleType),
    );

    const { content } = data;

    this.setSubscriptionsList(content);

    return data;
  }

  async getSubscriptionsList(entityId: number) {
    this.setEntityId(entityId);
    return this.filterMethods.getData();
  }

  async createEntitySubscription(subscription: CreateEntitySubscriptionInfo) {
    if (!this.entityId) {
      throw new Error('entityId is not set');
    }

    await entitySubscriptionService.createEntitySubscription(this.entityId, subscription);
    await this.getSubscriptionsList(this.entityId);
  }

  async changeActivationStatus(subscriptionId: number, activate: boolean) {
    if (!this.entityId) {
      throw new Error('entityId is not set');
    }

    const changedSubscription = await entitySubscriptionService.changeActivationStatus(
      this.entityId,
      subscriptionId,
      activate,
    );

    this.updateSubscription(changedSubscription);
  }

  async changeDisplayToUserStatus(subscriptionId: number, displayToUser: boolean) {
    if (!this.entityId) {
      throw new Error('entityId is not set');
    }

    const changedSubscription = await entitySubscriptionService.changeDisplayToUserStatus(
      this.entityId,
      subscriptionId,
      displayToUser,
    );

    this.updateSubscription(changedSubscription);
  }

  private setEntityId(entityId: number) {
    this.entityId = entityId;
  }

  private updateSubscription(changedSubscription: EntitySubscriptionInfo) {
    const updatedSubscriptionsList = updateItemByField(
      this.subscriptionsList,
      changedSubscription,
      { id: changedSubscription.id },
    );

    this.setSubscriptionsList(updatedSubscriptionsList);
  }

  private setSubscriptionsList(subscriptions: EntitySubscriptionInfo[]) {
    this.subscriptionsList = subscriptions;
  }
}

const adminSubscriptionsManagementStore = new SubscriptionsManagementStore('ADMIN');
resetAllStore.addResetMethod(adminSubscriptionsManagementStore.setDefaultValues);

const superSubscriptionsManagementStore = new SubscriptionsManagementStore('SUPERUSER');
resetAllStore.addResetMethod(superSubscriptionsManagementStore.setDefaultValues);

export {
  adminSubscriptionsManagementStore,
  superSubscriptionsManagementStore,
  SubscriptionsManagementStore,
};
