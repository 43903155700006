import React, { useEffect } from 'react';

import { angularize } from 'react-in-angularjs';
import { Container } from '@mui/material';
import { observer } from 'mobx-react';

import classes from './NgzEntityFlow2024Setup.module.scss';
import { LbStepper, StepOption } from '../../../shared/components/LbStepper/LbStepper';
import { SystemProviders } from '../../../shared/providers';
import { setupEntityStore } from '../../stores/setupEntityStore';
import { BusinessInfoStep } from './containers/BusinessInfoStep/BusinessInfoStep';
import { InBodySetupStep } from './containers/InBodySetupStep/InBodySetupStep';
import { SubscriptionStep } from './containers/SubscriptionStep/SubscriptionStep';
import { SetupLoginInfo } from './containers/SetupLoginInfo/SetupLoginInfo';

const NgzEntityFlow2024Setup = observer(() => {
  const { stepperMethods, setDefaultValues } = setupEntityStore;
  const { activeStep } = stepperMethods;

  useEffect(() => {
    return () => setDefaultValues();
  }, []);

  const stepsOptions: StepOption[] = [
    {
      label: 'Business Info',
      step: <BusinessInfoStep />,
    },
    {
      label: 'InBody',
      step: <InBodySetupStep />,
    },
    {
      label: 'Fees & Subscriptions',
      step: <SubscriptionStep />,
    },
    {
      label: 'Complete setup',
      step: <SetupLoginInfo />,
    },
  ];

  return (
    <SystemProviders>
      <Container>
        <h2 className={classes.title}>Add a New Entity</h2>
        <LbStepper
          stepsArr={stepsOptions}
          activeStep={activeStep}
          className={classes.stepper}
        />
      </Container>
    </SystemProviders>
  );
});

angularize(
  NgzEntityFlow2024Setup,
  'ngzEntityFlow2024Setup',
  angular.module('app.entityManagement'),
  {},
);

export { NgzEntityFlow2024Setup };
