const autoNgTemplateLoaderTemplate1 = require('./sharing.template.html');

'use strict';

(function () {
    angular
        .module('app.sharing', [])
        .config(sharingConfig);

    sharingConfig.$inject = ['$routeProvider'];

    function sharingConfig($routeProvider) {
        $routeProvider
            .when('/sharing/:token', {
                disableCache: true,
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'SharingController',
                controllerAs: 'vm',
                resolve: {
                    loginRequired: ['AuthService', function (AuthService) {
                        return AuthService.loginRequired();
                    }]
                }
            })
    }
})();
