import { makeAutoObservable } from 'mobx';
import { resetAllStore } from '../../shared/stores/resetAllStore';

class RegistrationStepperStore {
  constructor() {
    this.setDefaultValues();
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.activeStep = 0;
    this.userId = null;
    this.isMedicalTestsSend = false;
    this.isActiveCalendarSent = false;
    this.isWaterGoalSent = false;
  }

  goToNextStep() {
    this.activeStep += 1;
  }

  goToStep(num) {
    if (this.activeStep > num) {
      this.activeStep = num;
    }
  }

  skipStep() {
    this.activeStep += 2;
  }

  setUserId(userId) {
    this.userId = userId;
  }

  setIsMedicalTestsSend() {
    this.isMedicalTestsSend = true;
  }

  setIsActiveCalendarSent() {
    this.isActiveCalendarSent = true;
  }

  setIsWaterGoalSent() {
    this.isWaterGoalSent = true;
  }
}

const registrationStepperStore = new RegistrationStepperStore();

resetAllStore.addResetMethod(registrationStepperStore.setDefaultValues);

export { RegistrationStepperStore, registrationStepperStore };
