const autoNgTemplateLoaderTemplate1 = require('./subscriptions.template.html');

'use strict';

angular
  .module('app.entityProfile')
  .directive('subscriptions', subscriptions);

subscriptions.$inject = [];

function subscriptions() {
  return {
    restrict: 'E',
    scope: {
      entityId: '<?'
    },
    templateUrl: autoNgTemplateLoaderTemplate1,
    controller: 'SubscriptionsController',
    controllerAs: 'vm'
  };
}
