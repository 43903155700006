'use strict';

angular.module('app').controller('CustomerSetupController', CustomerSetupController);

CustomerSetupController.$inject = ['$scope', '$location', 'CustomerService', 'CoachService'];

function CustomerSetupController($scope, $location, CustomerService, CoachService) {
    var vm = this;
    vm.spinner = {active: false};

    // ALL STEPS
    vm.createUser = {
        customer: {},
        prefillReading: {},
        user: {},
        weeklyWorkouts: {
            bandWorkouts: 0,
            baseball: 0,
            basketball: 0,
            bodyBuilding: 0,
            bootCamps: 0,
            boxing: 0,
            circuitTraining: 0,
            crossCountrySkiing: 0,
            crossfit: 0,
            figureSkating: 0,
            football: 0,
            golf: 0,
            handBall: 0,
            hiit: 0,
            hockey: 0,
            jogging: 0,
            kettleBells: 0,
            kickBoxing: 0,
            lacrosse: 0,
            mma: 0,
            p90x: 0,
            plyoMetrics: 0,
            pilates: 0,
            powerlifting: 0,
            racketBall: 0,
            running: 0,
            swimming: 0,
            inLineSkating: 0,
            tennis: 0,
            volleyball: 0,
            walking: 0,
            wrestling: 0,
            yoga: 0,
            cycling: 0,
            bodyShaping: 0,
            cardio: 0,
            soccer: 0,
            softball: 0,
            other: 0,
            defaultMealsPerDay: '5'
        }
    };
    vm.step = {name: 'step1'};
    vm.done = [false, false, false, false, false];

    // STEP 1
    vm.userGroup = {name: ''};
    vm.inbodyDataList = {
        weight: null,
        height: null,
        skeletalMuscleMass: null,
        dryLeanMass: null,
        inWatter: null,
        exWatter: null,
        basalMetabolicRate: null,
        scanDate: null,
        scanTime: null,
        bodyFatMass: 0,
        leanBodyMass: 0,
        totalBodyWatter: 0,
        ecwtbw: 0
    };

    // STEP 3
    vm.prePostWorkout = {
        preWorkout: {prt: 15, crb: 30, fat: 0, fibers: 0, sugar: 0},
        postWorkout: {prt: 20, crb: 30, fat: 0, fibers: 0, sugar: 0}
    };

    // STEP 4
    vm.script = {id: null};
    vm.goals = [
        {id: 1, tag: 'Lose Fat / Gain or Maintain Muscle'},
        {id: 2, tag: 'Lose Weight - Lose minimal muscle and Fat'},
        {id: 3, tag: 'Gain Muscle - Minimal Gain in Fat'},
        {id: 4, tag: 'Gain Weight - Muscle and Fat at 3:2 or better ratio of Muscle to Fat gain'}
    ];
    vm.userGoal = {
        goal: vm.goals[0],
        activityLevel: null,
        targetDate: null,
        weight: null,
        bodyFat: null
    };

    // STEP 5-6
    vm.user = {id: null};

    vm.changeTab = changeTab;
    vm.getTabState = getTabState;
    vm.getActivities = getActivities;
    vm.getCurrentEntity = getCurrentEntity;
    vm.next = next;

    vm.getActivities();
    vm.getCurrentEntity();

    window.onbeforeunload = function () {
        if ($location.path() === '/customer-setup') {
            return "Are you sure you want to leave this page?";
        }
    };

    function changeTab(tabIndex) {
        var lastTab = vm.done.indexOf(false);

        if (lastTab >= tabIndex - 1) {
            vm.step.name = 'step' + tabIndex;
            vm.done.fill(false, tabIndex - 1, lastTab);
        }
    }

    function getTabState(index) {
        if (index < 6 && vm.done[index - 1]) {
            return 'completed';
        }
        return vm.step.name === 'step' + index ? 'active' : '';
    }

    function getActivities() {
        CustomerService.getActivities().then(function (result) {
            if (result.status === 200) {
                vm.activityIndices = result.data;
            }
        });
    }

    function getCurrentEntity() {
        CoachService.getCurrentEntityInfo().then(function (result) {
            if (result.status === 200) {
                vm.currentEntity = result.data;
            }
        })
    }

    function next(stepNumber) {
        vm.step.name = 'step' + (stepNumber + 2);
        vm.done[stepNumber] = true;
    }
}
