import React, { useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { angularize } from 'react-in-angularjs';
import { observer } from 'mobx-react';
import { Box, Typography } from '@mui/material';

import classes from './Registration.module.scss';
import { registrationStepperStore } from '../../stores/registrationStepperStore';
import { LbStepper } from '../../../shared/components/LbStepper/LbStepper';
import { TestStep } from './containers/TestStep/TestStep';
import { ActiveCalendarStep } from './containers/ActiveCalendarStep/ActiveCalendarStep';
import { InitialOrderStep } from './containers/InitialOrderStep/InitialOrderStep';
import { UserProfileStep } from './containers/UserProfileStep/UserProfileStep';
import { SystemProviders } from '../../../shared/providers';
import { medicalTestsStore } from '../../stores/medicalTestsStore';

const formFieldsName = {
  userProfile: 'userProfile',
  testStep: 'testStep',
  activeCalendar: 'activeCalendar',
  initialOrder: 'initialOrder',
};

const stepsOptions = [
  {
    step: <UserProfileStep fieldName={formFieldsName.userProfile} />,
    label: 'User Profile',
  },
  { step: <TestStep fieldName={formFieldsName.testStep} />, label: 'Lab Tests' },
  {
    step: <ActiveCalendarStep fieldName={formFieldsName.activeCalendar} />,
    label: 'Select Supplements',
  },
  {
    step: <InitialOrderStep fieldName={formFieldsName.initialOrder} />,
    label: 'Set Beginning Inventory',
  },
];

const NgzRegistration = observer(() => {
  const [steps, setSteps] = useState(stepsOptions);
  const { activeStep, goToStep, setDefaultValues } = registrationStepperStore;
  const { isTestsDisabled, getTests } = medicalTestsStore;

  useEffect(() => {
    getTests();

    return setDefaultValues;
  }, []);

  useEffect(() => {
    if (isTestsDisabled) {
      deleteStep('Lab Tests');
    }
  }, [isTestsDisabled]);

  const deleteStep = (label) => {
    const newSteps = steps.filter((step) => step.label !== label);
    setSteps(newSteps);
  };

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      [formFieldsName.userProfile]: {},
      [formFieldsName.testStep]: [],
      [formFieldsName.activeCalendar]: {},
      [formFieldsName.initialOrder]: {},
    },
  });

  return (
    <SystemProviders>
      <FormProvider {...methods}>
        <Box className={classes.box}>
          <Typography className={classes.typography}>Add a new member</Typography>
          <LbStepper activeStep={activeStep} stepsArr={steps} goToStep={goToStep} />
        </Box>
      </FormProvider>
    </SystemProviders>
  );
});

angularize(NgzRegistration, 'ngzRegistration', angular.module('app.active-calendar'), {});

export default NgzRegistration;
