import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { Grid } from '@mui/material';

import classes from './ResultRow.module.scss';
import { InfoCell } from './InfoCell/InfoCell';
import { CheckboxInfoCell } from './CheckboxInfoCell/CheckboxInfoCell';
import { MED_TEST_RESULT_STATUSES_KEYS } from '../../../../../../constants/MED_TEST_RESULT_STATUSES_KEYS';
import { LabTestInfoTooltip } from '../../../../../../components/lab-test-components';

const ResultRow = ({ resultInfo }) => {
  const { testDate, notes, appointmentCompleted, testCompleted, ROF } = resultInfo;
  const {
    APPOINTMENT_COMPLETED,
    TEST_COMPLETED,
    ROF: ROF_KEY,
  } = MED_TEST_RESULT_STATUSES_KEYS;

  const getFormattedDate = (date) => date && moment(date).format('MMM DD YYYY');

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={3}>
        <div className={classes.resultDateCell}>
          <LabTestInfoTooltip testDate={testDate} />
          <InfoCell label="Test Date" text={getFormattedDate(testDate)} />
        </div>
      </Grid>
      <Grid item xs={3}>
        <InfoCell label="Notes" text={notes} />
      </Grid>
      <Grid item xs={1}>
        <CheckboxInfoCell
          isChecked={appointmentCompleted}
          fieldName={APPOINTMENT_COMPLETED}
        />
      </Grid>
      <Grid item xs={1}>
        <CheckboxInfoCell isChecked={testCompleted} fieldName={TEST_COMPLETED} />
      </Grid>
      <Grid item xs={1}>
        <CheckboxInfoCell isChecked={ROF} fieldName={ROF_KEY} />
      </Grid>
    </Grid>
  );
};

ResultRow.propTypes = {
  resultInfo: PropTypes.shape({
    testDate: PropTypes.string,
    notes: PropTypes.string,
    appointmentCompleted: PropTypes.bool,
    testCompleted: PropTypes.bool,
    ROF: PropTypes.bool,
  }),
};

export { ResultRow };
