import { Components } from '@mui/material';

export const MuiContainerTheme: Components['MuiContainer'] = {
  defaultProps: {
    maxWidth: 'lg',
  },
  styleOverrides: {
    root: {
      margin: '24px auto',
      '@media (width > 0px)': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
};
