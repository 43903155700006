import { Chip } from '@mui/material';
import React from 'react';

import classes from './FileBox.module.scss';

type FileBoxProps = {
  files: File[];
  onDeleteFile: (index: number) => void;
};

const FileBox = ({ files, onDeleteFile }: FileBoxProps) => {
  return (
    <div className={classes.fileBox}>
      {files.map((file, index) => (
        <Chip
          label={file.name}
          key={file.lastModified}
          onDelete={() => onDeleteFile(index)}
          variant="file"
        />
      ))}
    </div>
  );
};

export { FileBox };
