import React from 'react';

import classes from './DailyMealHeadRow.module.scss';
import { NUTRIENTS_ENUM } from '../../../../shared/constants/NUTRIENTS_ENUM';
import { GridTableRow } from '../../../../shared/components/table-components';
import { getEnumArray } from '../../../../shared/utils/jsUtils';

const DailyMealHeadRow = () => {
  const fullNutrients = getEnumArray(NUTRIENTS_ENUM);

  return (
    <GridTableRow variant="subRow" className={classes.headRow}>
      <span />
      {fullNutrients.map((nutrient) => (
        <span key={nutrient}>{nutrient}</span>
      ))}
    </GridTableRow>
  );
};

export { DailyMealHeadRow };
