import { entityTypeOptions } from '../../../shared/constants/user-info/ENTITY_TYPES';
import { getOptionsByValues } from '../../../shared/utils/arrayUtils';

export const availableEntityTypeOptions = getOptionsByValues(entityTypeOptions, [
  'GYM',
  'TRAINER',
  'CHIROPRACTOR',
  'NUTRITION_STORE',
  'OTHER',
]);
