import React, { ReactNode } from 'react';

import { MenuItem } from '@mui/material';

import classes from './UserMenuItem.module.scss';

type UserMenuItemProps = {
  label: ReactNode;
  onClick: () => void;
  startIcon: ReactNode;
  endIcon?: ReactNode;
};

const UserMenuItem = ({ label, onClick, startIcon, endIcon }: UserMenuItemProps) => {
  return (
    <MenuItem onClick={onClick} className={classes.menuItem}>
      {startIcon}
      <span>{label}</span>
      {endIcon}
    </MenuItem>
  );
};

export { UserMenuItem };
