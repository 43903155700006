import React, { Fragment } from 'react';

import { Button } from '@mui/material';

import classes from './AdditionalLinks.module.scss';
import { MenuItemSetting } from '../../../../interfaces/system/NavLinkConfig';

type AdditionalLinksProps = {
  links: MenuItemSetting[];
};

const AdditionalLinks = ({ links }: AdditionalLinksProps) => {
  return (
    <div className={classes.block}>
      {links.map((link, index) => (
        <Fragment key={link.label}>
          {index !== 0 && <div className={classes.separationLine} />}
          <Button variant="text" href={link.path} target="_blank">
            {link.label}
          </Button>
        </Fragment>
      ))}
    </div>
  );
};

export { AdditionalLinks };
