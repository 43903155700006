import { localDataFormat } from '../../../../../../shared/constants/apiDateFormat';
import { getFormattedDate } from '../../../../../../shared/utils/dateUtils';

export const getFormattedRegistrationDate = (registrationDate?: string): string => {
  if (!registrationDate) {
    return 'MMMM DD YYYY';
  }

  return getFormattedDate(registrationDate, localDataFormat);
};
