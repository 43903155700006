import React from 'react';

import { Button } from '@mui/material';

import { PageTitle } from '../../../../../../shared/components/ui-components';
import { corporateAccountsStore } from '../../../../../stores/corporateAccountsStore';

const CorporateAccountsTabTitle = () => {
  const { openModal } = corporateAccountsStore;

  return (
    <PageTitle title="Corporate Accounts">
      <Button onClick={openModal} variant="contained">
        Add Corporate Account
      </Button>
    </PageTitle>
  );
};

export { CorporateAccountsTabTitle };
