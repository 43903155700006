const autoNgTemplateLoaderTemplate1 = require('./superdashboard.template.html');

'use strict';

(function () {
    angular
        .module('app.superDashboard', [])
        .config(superDashboardConfig);

    superDashboardConfig.$inject = ['$routeProvider'];

    function superDashboardConfig($routeProvider) {
        $routeProvider
            .when('/superuser-dashboard', {
                disableCache: true,
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'SuperDashboardController',
                controllerAs: 'vm',
                resolve: {
                    loginRequired: ['AuthService', function (AuthService) {
                        return AuthService.loginRequired();
                    }]
                }
            })
    }
})();
