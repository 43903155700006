import React from 'react';
import PropTypes from 'prop-types';

import { ComplianceDayRow } from './ComplianceDayRow/ComplianceDayRow';
import { ComplianceAverageRow } from './ComplianceAverageRow/ComplianceAverageRow';
import { ComplianceInfoModal } from '../ComplianceInfoModal/ComplianceInfoModal';

const CustomerComplianceHistoryList = ({
  complianceHistory,
  averageComplianceInfo,
  customerId,
}) => {
  if (!averageComplianceInfo) {
    return null;
  }

  return (
    <>
      {complianceHistory.map((complianceDayInfo) => (
        <ComplianceDayRow
          complianceDayInfo={complianceDayInfo}
          key={complianceDayInfo.date}
          customerId={customerId}
        />
      ))}
      <ComplianceAverageRow
        averageComplianceInfo={averageComplianceInfo}
        daysCount={complianceHistory.length}
      />
      <ComplianceInfoModal />
    </>
  );
};

const complianceInfoProps = {
  breakfast: PropTypes.number,
  daily: PropTypes.number,
  dinner: PropTypes.number,
  lunch: PropTypes.number,
  snack1: PropTypes.number,
  snack2: PropTypes.number,
  snack3: PropTypes.number,
  water: PropTypes.shape({ current: PropTypes.number, goal: PropTypes.number }),
  sodium: PropTypes.shape({ current: PropTypes.number, goal: PropTypes.number }),
};

CustomerComplianceHistoryList.propTypes = {
  complianceHistory: PropTypes.arrayOf(PropTypes.shape(complianceInfoProps)),
  averageComplianceInfo: PropTypes.shape(complianceInfoProps),
  customerId: PropTypes.number,
};

export { CustomerComplianceHistoryList };
