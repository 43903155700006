import {
  SubscriptionSettings,
  SubscriptionSettingsFormValues,
} from '../../../interfaces/subscriptionSettings';

export const processSettingsFormValues = (
  settings: SubscriptionSettings | null,
): SubscriptionSettingsFormValues | undefined => {
  if (!settings) {
    return undefined;
  }

  const { id, billingPeriodType, billingPeriodValue, ...subscriptionSettings } = settings;

  return { subscriptionSettings };
};
