'use strict';

angular.module('app').controller('CustomerSetupStep2Controller', CustomerSetupStep2Controller);

CustomerSetupStep2Controller.$inject = ['$scope', 'CustomerService'];

function CustomerSetupStep2Controller($scope, CustomerService) {
    var vm = this;
    vm.createUser = $scope.createUser;

    vm.calculateWorkoutHours = calculateWorkoutHours;
    vm.getWorkoutNumber = getWorkoutNumber;

    function calculateWorkoutHours() {
        return CustomerService.calculateWorkoutHours(vm.createUser.weeklyWorkouts);
    }

    function getWorkoutNumber(name) {
        var value = vm.createUser.weeklyWorkouts[name];
        return value === null ? 0 : value;
    }
}
