const autoNgTemplateLoaderTemplate1 = require('./customer-setup-step-5.template.html');

'use strict';

angular
    .module('app')
    .directive('customerSetupStep5', customerSetupStep5);

customerSetupStep5.$inject = [];

function customerSetupStep5() {
    return {
        restrict: 'E',
        scope: {
            createUser: '=',
            userGoal: '=',
            inbodyDataList: '=',
            userGroup: '=',
            user: '=',
            spinner: '=',
            next: '&onNext'
        },
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: 'CustomerSetupStep5Controller',
        controllerAs: 'vm'
    };
}
