import { customFilterStore } from '../../../../stores/customFilterStore';
import { CustomFilterFormValues } from '../AddCustomFilterForm.settings';

export const validateIsFilterNameExist = (
  name: string,
  filterData: CustomFilterFormValues,
) => {
  const { customFilters } = customFilterStore;

  const isNameExist = customFilters.some((filter) => {
    const isNotEditedFilter = filter.id !== filterData.id;
    const isSameName = filter.name === name;
    return isSameName && isNotEditedFilter;
  });
  return isNameExist ? 'Filter with current name already exist' : true;
};
