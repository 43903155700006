import { makeAutoObservable } from 'mobx';

class ApiStatusStore {
  constructor() {
    this.setDefaultValues();
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.isLoadingGlobal = false;
    this.errorMessage = '';
    this.isLoading = false;
    this.fetchCallsCount = 0;
  }

  loadingStart(isRunGlobalSpinner) {
    this.increaseFetchCallsCount();

    if (isRunGlobalSpinner) {
      this.globalLoadingStart();
    } else {
      this.localLoadingStart();
    }
  }

  increaseFetchCallsCount() {
    this.fetchCallsCount++;
  }

  globalLoadingStart() {
    this.isLoadingGlobal = true;
  }

  localLoadingStart() {
    this.isLoading = true;
  }

  loadingEnd() {
    this.decreaseFetchCallsCount();

    if (!this.fetchCallsCount) {
      this.stopLoading();
    }
  }

  decreaseFetchCallsCount() {
    this.fetchCallsCount--;
  }

  stopLoading() {
    this.isLoading = false;
    this.isLoadingGlobal = false;
  }

  addErrorMessage(errorMessage) {
    this.errorMessage = errorMessage;
  }

  cleanErrorMessage() {
    this.errorMessage = '';
  }
}

const apiStatusStore = new ApiStatusStore();

export { ApiStatusStore, apiStatusStore };
