import React from 'react';

import { observer } from 'mobx-react';

import classes from './IsKnownCoachStep.module.scss';
import { UseStepperReturn } from '../../../../../shared/hooks/useStepper';
import { ButtonRow } from '../../../../components/customer-self-setup-components';
import { RadioCard } from '../../components/RadioCard/RadioCard';
import knownCoachImage from '../../../../../../../assets/images/customer-self-setup/known-coach.svg';
import unknownCoachImage from '../../../../../../../assets/images/customer-self-setup/unknown-coach.svg';
import { coachStore } from '../../../../stores/customerSelfSetupStores/coachStore';
import { useScrollToTop } from '../../../../../shared/hooks/useScrollToTop';

type IsKnownCoachStepProps = {
  stepperMethods: UseStepperReturn;
};

const IsKnownCoachStep = observer(({ stepperMethods }: IsKnownCoachStepProps) => {
  useScrollToTop();

  const { isKnownCoach, setIsKnownCoach } = coachStore;

  const handleNext = () => {
    stepperMethods.goToNextStep();
  };

  return (
    <div>
      <div className={classes.cardsContainer}>
        <RadioCard onClick={setIsKnownCoach} value checked={!!isKnownCoach}>
          <div className={classes.box}>
            <img src={knownCoachImage} alt="Known coach" />
            <h5 className={classes.cardTitle}>Yes, I know</h5>
          </div>
        </RadioCard>
        <RadioCard
          onClick={setIsKnownCoach}
          value={false}
          checked={isKnownCoach === false}
        >
          <div className={classes.box}>
            <img src={unknownCoachImage} alt="Unknown coach" />
            <h5 className={classes.cardTitle}>No, I don&apos;t know</h5>
          </div>
        </RadioCard>
      </div>
      <ButtonRow
        yesHandler={handleNext}
        yesDisabled={isKnownCoach === null}
        noHandler={stepperMethods.goToPreviousStep}
      />
    </div>
  );
});

export { IsKnownCoachStep };
