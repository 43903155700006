import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox, Paper } from '@mui/material';
import { observer } from 'mobx-react';

import classes from './CustomerRow.module.scss';
import { UserInfoCell } from '../../../../shared/components/table-components';
import { InventoryCell } from './InventoryCell/InventoryCell';
import { StatusCell } from './StatusCell/StatusCell';
import { ContactCell } from './ContactCell/ContactCell';
import { useModalToggle } from '../../../../shared/hooks/useModalToggle';
import { CustomerDetailBox } from '../CustomerDetailBox/CustomerDetailBox';
import { activeUsersCommunicationStore } from '../../../stores/activeUsersCommunicationStore';
import { LabTestCell } from './LabTestCell/LabTestCell';
import { AppointmentCell } from './AppointmentCell/AppointmentCell';
import { getFormattedDate } from '../../../../shared/utils/dateUtils';
import { localDataFormat } from '../../../../shared/constants/apiDateFormat';

const CustomerRow = observer(({ customer, isChecked }) => {
  const { checkCustomer, openSendMessageDialog } = activeUsersCommunicationStore;

  const [isDetailOpen, DetailOpenHandler] = useModalToggle();
  const {
    id,
    firstName,
    lastName,
    email,
    phone,
    inventoryStatus,
    registrationDate,
    activationStatus,
    labTestStartDate,
    labTestCount,
    appointmentStatus,
    testId,
  } = customer;

  const formattedRegistrationDate = getFormattedDate(registrationDate, localDataFormat);

  const customerRowClasses = isChecked
    ? `${classes.customerRow} ${classes.checked}`
    : classes.customerRow;

  return (
    <>
      <Paper
        variant="roundedBox"
        className={customerRowClasses}
        data-test-id="customer-row"
      >
        <Checkbox
          checked={isChecked}
          className={classes.checkbox}
          onChange={() => checkCustomer(id)}
        />
        <div className={classes.infoBox}>
          <UserInfoCell
            firstName={firstName}
            lastName={lastName}
            email={email}
            phone={phone}
            href={`#!/customer-profile/${id}`}
          />
          <LabTestCell
            customerId={id}
            labTestStartDate={labTestStartDate}
            labTestCount={labTestCount}
          />
          <AppointmentCell
            customerId={id}
            appointmentStatus={appointmentStatus}
            labTestStartDate={labTestStartDate}
            testResultId={testId}
          />
          <InventoryCell inventory={inventoryStatus} onClick={DetailOpenHandler} />
          <div data-test-id="registration-date">{formattedRegistrationDate}</div>
          <StatusCell activationStatus={activationStatus} />
        </div>
        <ContactCell disabled={!isChecked} onClick={openSendMessageDialog} />
      </Paper>
      {isDetailOpen && <CustomerDetailBox customerId={id} />}
    </>
  );
});

CustomerRow.propTypes = {
  customer: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    inventoryStatus: PropTypes.string,
    activationStatus: PropTypes.string,
    registrationDate: PropTypes.string,
    id: PropTypes.number,
    testId: PropTypes.number,
  }),
  isChecked: PropTypes.bool,
};

export { CustomerRow };
