import React from 'react';

import { observer } from 'mobx-react';

import {
  FilterButton,
  SortButtons,
} from '../../../../../../../shared/components/buttons';
import { complianceStore } from '../../../../../../stores/complianceStore';
import {
  GridTableCell,
  GridTableRow,
} from '../../../../../../../shared/components/table-components';
import { ComplianceTitleCells } from '../../../../../../components/ComplianceTitleCells/ComplianceTitleCells';

const TitleRow = observer(() => {
  const { filterMethod } = complianceStore;

  const { isShowFilterRow, toggleShowFilterRow, handleSort, getIsSortedBy } =
    filterMethod;

  return (
    <GridTableRow variant="header">
      <GridTableCell justify="start" dataTestId="name-cell">
        <SortButtons fieldName="name" onSort={handleSort} getIsActive={getIsSortedBy}>
          Name
        </SortButtons>
      </GridTableCell>
      <ComplianceTitleCells />
      <GridTableCell justify="end" dataTestId="filter-btn-cell">
        <FilterButton isActive={isShowFilterRow} onClick={toggleShowFilterRow} />
      </GridTableCell>
    </GridTableRow>
  );
});

export { TitleRow };
