import { getIsNullish } from '../../jsUtils';

export const pluralize = (
  count: number | null | undefined,
  noun: string,
  suffix = 's',
): string => {
  if (getIsNullish(count)) {
    return '';
  }

  return `${count} ${noun}${count !== 1 ? suffix : ''}`;
};
