import { NUTRIENTS } from '../../../shared/constants/NUTRIENTS';
import { getRoundedNumber } from '../../../shared/utils/mathUtils';

export const getFoodsWithRoundedNutrients = (foods) => {
  const foodsWithRoundedNutrients = foods.map((food) => {
    const foodKeys = Object.keys(food);

    foodKeys.forEach((key) => {
      if (NUTRIENTS.includes(key)) {
        food[key] = getRoundedNumber(food[key], 1);
      }
    });

    return food;
  });

  return foodsWithRoundedNutrients;
};
