import React from 'react';

import { Chip, ChipProps } from '@mui/material';

type LbChipProps = { selected?: boolean } & ChipProps;

const LbChip = ({ selected, variant, ...props }: LbChipProps) => {
  const chipStatusVariant = selected ? 'selected' : variant;

  return <Chip variant={chipStatusVariant} {...props} />;
};

export { LbChip };
