import type { ChartData, ChartDataset } from 'chart.js';
import { getRoundedNumber } from '../../../../shared/utils/mathUtils';
import {
  PerformanceScoreInfo,
  PerformanceNutrient,
} from '../../../interfaces/PerformanceScoreInfo';
import { palette } from '../../../../shared/theme/palette';

const chartSections = [
  { label: 'Proteins', dbName: 'protein' },
  { label: 'Carbs', dbName: 'carbs' },
  { label: 'Fat', dbName: 'fat' },
  { label: 'Sugar', dbName: 'sugar' },
  { label: 'Veggies', dbName: 'veggies' },
] as const;

const getNutrientData = (nutrientScoreInfo: PerformanceNutrient): number[] =>
  chartSections.map(({ dbName }) => getRoundedNumber(nutrientScoreInfo[dbName], 1));

export const getChartData = (
  performanceScoreInfo: PerformanceScoreInfo,
): ChartData<'bar'> => {
  const maxValue = 5;
  const myClients = getNutrientData(performanceScoreInfo.myClients);
  const entireSystem = getNutrientData(performanceScoreInfo.entireSystem);
  const labels = chartSections.map((cartInfo) => cartInfo.label);
  const backgroundBarData = chartSections.map(() => maxValue);

  const barSizeOptions = {
    barThickness: 22,
    maxBarThickness: 10,
  };

  const backgroundBarOptions: ChartDataset<'bar'> = {
    ...barSizeOptions,
    data: backgroundBarData,
    backgroundColor: palette.background['50-light'],
    borderSkipped: 'bottom',
    datalabels: { display: false },
    animation: false,
  };

  return {
    labels,
    datasets: [
      {
        ...barSizeOptions,
        backgroundColor: palette.mainBlack,
        data: entireSystem,
        stack: 'allSystem',
        datalabels: {
          color: palette.mainBlack,
        },
      },
      {
        ...backgroundBarOptions,
        stack: 'allSystem',
      },
      {
        ...barSizeOptions,
        backgroundColor: palette.primary[500],
        data: myClients,
        stack: 'myClient',
        datalabels: {
          color: '#ff9900',
        },
      },
      {
        ...backgroundBarOptions,
        stack: 'myClient',
      },
    ],
  };
};
