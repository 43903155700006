import React, { useEffect, useState } from 'react';

import classes from './WaterConsumptionWidget.module.scss';
import {
  InfoBox,
  LbLinearProgress,
} from '../../../../../../shared/components/ui-components';
import { TitleWithDescription } from '../../../../../../shared/components/text-formation-components';
import { WaterConsumption } from '../../../../../interfaces/performance-metrics/WaterConsumption';
import { PerformanceMetricsParams } from '../../../../../interfaces/performance-metrics/PerformanceMetricsParams';
import { getPercentages } from '../../../../../../shared/utils/mathUtils';
import { performanceMetricsService } from '../../../../../services/performanceMetricsService';
import { getGapToGoalDescription } from './utils/getGapToGoalDescription';

const defaultWaterConsumption: WaterConsumption = {
  consumed: 0,
  total: 0,
};

type WaterConsumptionWidgetProps = {
  customerId: number;
  params: PerformanceMetricsParams;
};

const WaterConsumptionWidget = ({ customerId, params }: WaterConsumptionWidgetProps) => {
  const [waterConsumption, setWaterConsumption] = useState<WaterConsumption>(
    defaultWaterConsumption,
  );

  const getWaterConsumption = async () => {
    const data = await performanceMetricsService.getWaterConsumption(customerId, params);

    setWaterConsumption(data);
  };

  useEffect(() => {
    getWaterConsumption();
  }, [customerId, params]);

  const { total, consumed } = waterConsumption;
  const consumedPercent = getPercentages(consumed, total, 0);
  const description = getGapToGoalDescription(total, consumed);

  return (
    <InfoBox
      title={
        <TitleWithDescription
          title="Water Consumption"
          description="The standard water consumption is approx 0.5 oz per 1 lbs of body weight, but it can vary based on factors like exercise and propensity to sweat."
          titleFont="title-16-bold"
          withoutGap
        />
      }
    >
      <div className={classes.infoRow}>
        <div>Goal: {total} oz</div>
        <div className={classes.consumedCell}>
          <span>
            Consumed: {consumed} oz ({consumedPercent}%)
          </span>
          <div className={classes.divider} />
          <span className={classes.description}>{description}</span>
        </div>
      </div>
      <LbLinearProgress value={consumedPercent} color="info" />
    </InfoBox>
  );
};

export { WaterConsumptionWidget };
