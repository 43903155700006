import { ResponseType } from 'axios';
import { DownloadedFileExtension } from '../../../constants/file/FILE_EXTENSIONS';

export const getResponseTypeForFile = (fileExtension: DownloadedFileExtension) => {
  return responseTypeForFile[fileExtension];
};

const responseTypeForFile: Record<DownloadedFileExtension, ResponseType> = {
  pdf: 'arraybuffer',
  csv: 'text',
};
