const autoNgTemplateLoaderTemplate1 = require('./customer-setup-step-3.template.html');

'use strict';

angular
    .module('app')
    .directive('customerSetupStep3', customerSetupStep3);

customerSetupStep3.$inject = [];

function customerSetupStep3() {
    return {
        restrict: 'E',
        scope: {
            createUser: '=',
            prePostWorkout: '=',
            next: '&onNext'
        },
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: 'CustomerSetupStep3Controller',
        controllerAs: 'vm'
    };
}
