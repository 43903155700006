import { Option } from '../../../../general-types';
import { ValueOfKey } from '../../../../utils-types';

export const COMPARISON = {
  LESS: 'LESS',
  MORE: 'MORE',
  BETWEEN: 'BETWEEN',
  EQUAL: 'EQUAL',
  LESS_AND_EQUAL: 'LESS_AND_EQUAL',
  MORE_AND_EQUAL: 'MORE_AND_EQUAL',
} as const;

export type ComparisonType = ValueOfKey<typeof COMPARISON>;

export const comparisonOptions: Option<ComparisonType>[] = [
  { label: 'Less', value: 'LESS' },
  { label: 'More', value: 'MORE' },
  { label: 'Between', value: 'BETWEEN' },
  { label: 'Equal', value: 'EQUAL' },
  { label: 'Less and Equal', value: 'LESS_AND_EQUAL' },
  { label: 'More and Equal', value: 'MORE_AND_EQUAL' },
];
