import React from 'react';

import { InputAdornment, TextField } from '@mui/material';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

import classes from './SupplementSearch.module.scss';
import { renderInputStyle } from './SupplementSearch.styled.sx';
import {
  getFirstLetter,
  sortArrAlphabetically,
} from '../../../../shared/utils/sortingAndGroupingUtil';

export const renderOption = (props, option) => (
  <li {...props} key={option.id} className={classes.supplementItem}>
    <h4>{option.name_only}</h4>
    {option.brand && <p>{option.brand}</p>}
  </li>
);

export const renderInput = (params) => {
  const newParams = params;
  newParams.InputProps.startAdornment = (
    <>
      <InputAdornment position="start">
        <SearchRoundedIcon fontSize="large" />
      </InputAdornment>
      {newParams.InputProps.startAdornment}
    </>
  );
  return (
    <TextField
      {...newParams}
      placeholder="Start search Supplement"
      variant="outlined"
      helperText={null}
      sx={renderInputStyle}
    />
  );
};

export const prepSupplementsToRender = (supplements, lastAdded) => {
  const prepSupplements = addFirstLetterField(supplements);
  sortArrAlphabetically(prepSupplements, 'firstLetter');

  if (lastAdded.length) {
    const prepLastAdded = addFirstLetterField(lastAdded, 'Last Added');
    return [...prepLastAdded, ...prepSupplements];
  }

  return prepSupplements;
};

const addFirstLetterField = (supplements, letterName) =>
  supplements.map((supplement) => ({
    ...supplement,
    firstLetter: letterName || getFirstLetter(supplement.name_only),
  }));
