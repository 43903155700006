import { apiService } from '../../shared/services/apiService';
import { getApiUrlRole } from '../../shared/utils/apiUtils';

const getUsers = async (params) => {
  const apiUrlRole = getApiUrlRole();

  const resp = await apiService(`api/${apiUrlRole}/customers/active_calendar/filters`, {
    params,
  });
  return resp.data;
};

const getCustomersReport = async (params) => {
  const resp = await apiService.get('api/reports/active-calendar/customers', {
    params,
  });

  return resp.data;
};

export const customerService = { getUsers, getCustomersReport };
