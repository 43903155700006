import { makeAutoObservable } from 'mobx';
import { resetAllStore } from '../../shared/stores/resetAllStore';

import { chatService } from '../services/chatService';

class ChatStore {
  constructor() {
    this.setDefaultValues();
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.user = {};
    this.userToken = '';
    this.chatClient = null;
    this.isOpenChat = false;
    this.activeChannelId = null;
  }

  async getChatInfo() {
    const { userInfo, token } = await chatService.getChatInfo();
    this.setUser(userInfo);
    this.setUserToken(token);
  }

  setUser(user) {
    this.user = user;
  }

  setUserToken(userToken) {
    this.userToken = userToken;
  }

  setChatClient(chatClient) {
    this.chatClient = chatClient;
  }

  setIsOpenChat(isOpenChat) {
    this.isOpenChat = isOpenChat;
  }

  setActiveChannelId(activeChannelId) {
    this.activeChannelId = activeChannelId;
  }

  resetChat() {
    if (this.chatClient) {
      this.chatClient.disconnectUser();
      this.setDefaultValues();
    }
  }
}

const chatStore = new ChatStore();

resetAllStore.addResetMethod(chatStore.resetChat);

export { chatStore, ChatStore };
