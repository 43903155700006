import React from 'react';

import { Button } from '@mui/material';
import { enqueueSnackbar } from 'notistack';

import classes from './SendMagicLinkRow.module.scss';
import { TitleWithDescription } from '../../../../../../../../shared/components/text-formation-components';
import { passwordService } from '../../../../../../../services/passwordService';

type SendMagicLinkRowProps = {
  entityId: number;
};

const SendMagicLinkRow = ({ entityId }: SendMagicLinkRowProps) => {
  const handleSendMagicLink = async () => {
    await passwordService.sendPasswordResetLink(entityId);
    enqueueSnackbar(
      'The magic link has been successfully sent to the user`s email address.',
      { variant: 'success' },
    );
  };

  return (
    <div className={classes.row}>
      <TitleWithDescription
        title="Send a magic link to the user's email"
        description="The user will receive a link, allowing them to create a new password"
        titleFont="body-14-bold"
      />
      <Button variant="outlined" size="small" onClick={handleSendMagicLink}>
        Send Recovery Link
      </Button>
    </div>
  );
};

export { SendMagicLinkRow };
