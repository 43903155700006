import React from 'react';
import ArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDownRounded';

import classes from './ScrollDownBadge.module.scss';

const ScrollDownBadge = () => {
  return (
    <div className={classes.container}>
      <p>Scroll down</p>
      <ArrowDownIcon className={classes.icon} fontSize="large" />
    </div>
  );
};

export { ScrollDownBadge };
