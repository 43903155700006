import React from 'react';

import { PersonalInfoBlock } from '../../components/PersonalInfoBlock/PersonalInfoBlock';
import { ButtonsRow } from '../../components/ButtonsRow/ButtonsRow';

const PersonalInfo = () => (
  <>
    <PersonalInfoBlock />
    <ButtonsRow />
  </>
);

export { PersonalInfo };
