'use strict';

angular
    .module('app')
    .controller('CheckInTabController', CheckInTabController);

// TODO: Remove inject of InbodyKeysService when inbody scans implementation will be understood

CheckInTabController.$inject = ['$scope', '$routeParams', 'ChartService', 'CustomerService', 'DateTimeUtil', 'utilService', 'InbodyKeysService', 'constants'];

function CheckInTabController($scope, $routeParams, ChartService, CustomerService, DateTimeUtil, utilService, InbodyKeysService, constants) {
    var vm = this;
    vm.dataEntryFields = constants.inbodyData;
    vm.graphView = false;
    vm.entryFields = JSON.parse(localStorage.getItem('entryFields'));
    vm.spinner = { active: false};
    vm.chartData = [];

    vm.selectedScanDate = null;
    vm.currentDate = DateTimeUtil.now();
    vm.minDate = DateTimeUtil.minusYears(vm.currentDate, 100);
    vm.scanDateIsOpen = false;

    vm.selectedDateChart = null;

    vm.scanOrder = 'asc';
    vm.valuesCount = 14;

    vm.isFieldAvailable = isFieldAvailable;
    vm.setScanDate = setScanDate;
    vm.clearScanDate = clearScanDate;
    vm.blurDatepicker = blurDatepicker;
    vm.filterIndodyScans = filterIndodyScans;
    vm.isActiveSort = isActiveSort;
    vm.sortByScanDate = sortByScanDate;
    vm.compareScanDates = compareScanDates;
    vm.changeView = changeView;
    vm.initInbodyScans = initInbodyScans;
    vm.buildCharts = buildCharts;
    vm.updateInitialList = updateInitialList;
    vm.editInbodyScan = editInbodyScan;
    vm.cancelItemEditing = cancelItemEditing;
    vm.changeScanDate = changeScanDate;


    function isFieldAvailable(field) {
        for (var prop in vm.entryFields) {
            if (vm.entryFields[prop] && prop === field && prop !== 'gender' && prop !== 'height') {
                return true;
            }
        }
        return false;
    }

    function setScanDate() {
        vm.scanDateIsOpen = false;
        vm.selectedScanDateFormat = utilService.formatDate(vm.selectedScanDate, 'M/D/YYYY');
        vm.inbodyScans = vm.filterIndodyScans();
    }

    function clearScanDate() {
        vm.selectedScanDate = null;
        vm.selectedScanDateFormat = null;
        vm.inbodyScans = angular.copy(vm.initialInbodyScans);
    }

    function blurDatepicker() {
        $scope.$evalAsync(function () {
            vm.scanDateIsOpen = false;
        });
    }

    function isActiveSort(order) {
        return vm.scanOrder === order;
    }

    function sortByScanDate(order) {
        vm.scanOrder = order;
        vm.inbodyScans.sort(vm.compareScanDates);
        if (order === 'desc') vm.inbodyScans.reverse();
    }

    function compareScanDates (a, b) {
        var d1 = Date.parse(a.scanOn);
        var d2 = Date.parse(b.scanOn);

        if (d1 < d2) return -1;
        if (d1 > d2) return 1;
        return 0;
    }

    function filterIndodyScans() {
        return vm.initialInbodyScans.filter(function (value) {
            return value.scanOn === vm.selectedScanDateFormat;
        })
    }

    function changeView() {
        vm.graphView = !vm.graphView;
        window.scrollTo(0, 0);
    }

    function initInbodyScans() {
        vm.chartData = [];
        for (var property in vm.entryFields) {
            if (vm.entryFields[property] && vm.dataEntryFields[property] && property !== 'gender' && property !== 'height') {
                vm.chartData.push({chartId: property, title: vm.dataEntryFields[property].title, data: [], instance: null});
            }
        }
        vm.spinner.active = true;
        CustomerService.getUserInbodyMetric($routeParams.id).then(function (res) {
            vm.spinner.active = false;
            if (res.status === 200) {
                vm.inbodyScans = res.data.data;
                vm.initialInbodyScans = angular.copy(vm.inbodyScans);
                vm.scanDates = res.data.dates;
                vm.selectedDateChart = vm.scanDates.length > 0 ? vm.scanDates.length - 1 : 0;

                for (var chartIndex in vm.chartData) {
                    for (var i in vm.inbodyScans) {
                        var dateArr = vm.inbodyScans[i].scanOn.split('/');
                        var currDate = moment(new Date(dateArr[2], dateArr[0] - 1, dateArr[1])).format('MM.DD.YYYY');
                        var propertyName = vm.dataEntryFields[vm.chartData[chartIndex].chartId].dbName;
                        vm.inbodyScans[i].body_comp_bodyFatMass = vm.inbodyScans[i].body_comp_weight * vm.inbodyScans[i].obesity_percentBodyFat / 100 ;
                        vm.inbodyScans[i].body_comp_lbs = Math.round((vm.inbodyScans[i].body_comp_weight -  vm.inbodyScans[i].body_comp_bodyFatMass) * 100) / 100;
                        vm.chartData[chartIndex].data.push({
                            date: currDate,
                            value: vm.inbodyScans[i][propertyName]
                        });
                    }
                }

                vm.changeScanDate();
            }
        });
    }

    function changeScanDate() {
        var startIndex = vm.selectedDateChart - vm.valuesCount - 1;
        startIndex >= 0 ?
            vm.buildCharts(startIndex, vm.valuesCount - 1) : vm.buildCharts(0, vm.valuesCount + startIndex - 1)
    }

    function buildCharts(startIndex, activeIndex) {
        for (var i = 0; i < vm.chartData.length; i++) {
            vm.chartData[i].instance = ChartService.createBodyCompositionChart(
                vm.chartData[i], startIndex, activeIndex);
        }
    }

    function updateInitialList(index) {
        index ? vm.initialInbodyScans[index] = angular.copy(vm.inbodyScans[index]) :
            vm.initialInbodyScans = angular.copy(vm.inbodyScans);
    }

    function editInbodyScan(scan) {
        scan.editable = true;
    }


    // TODO: There is no implemetation for editing inbody scans in InbodyKeysService. Should understand usage first.
    // function updateInbodyScanItem(index) {
    //     vm.spinner.active = true;
    //     InbodyKeysService.updateInbodyScan(vm.inbodyScans[index]).then(function (response) {
    //         vm.spinner.active = false;
    //         if (response.status === 200) {
    //             vm.inbodyScans[index] = response.data;
    //             vm.updateInitialList(index);
    //         }
    //     });
    // }

    function cancelItemEditing(index) {
        vm.inbodyScans[index] = angular.copy(vm.initialInbodyScans[index]);
    }
}
