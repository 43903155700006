import React from 'react';
import PropTypes from 'prop-types';

import { Button, Paper } from '@mui/material';

import classes from './FiltersRow.module.scss';
import { LbDatePicker } from '../../../../../../shared/components/form-components/LbDatePicker/LbDatePicker';
import { LbInput } from '../../../../../../shared/components/form-components/LbInput/LbInput';
import { LbSelect } from '../../../../../../shared/components/form-components/LbSelect/LbSelect';
import { InputWithArrow } from '../../../components/InputWithArrow/InputWithArrow';
import { Row } from '../../../components/Row/Row';
import { defaultInputProps, statusOption } from './FiltersRow.settings';

const FiltersRow = ({ control, onSubmit, changeOrderHandler }) => {
  defaultInputProps.control = control;

  const ascOrderHandler = (field) => changeOrderHandler(field, 'ASC');
  const descOrderHandler = (field) => changeOrderHandler(field, 'DESC');

  return (
    <Paper variant="roundedBox" className={classes.filtersBox}>
      <form>
        <div className={classes.userInfoBox}>
          <InputWithArrow
            upArrowClickHandler={() => ascOrderHandler('first_name')}
            downArrowClickHandler={() => descOrderHandler('first_name')}
          >
            <LbInput {...defaultInputProps} name="fullName" placeholder="Full Name" />
          </InputWithArrow>
          <LbInput {...defaultInputProps} name="phone" placeholder="Phone" />
        </div>
        <Row className={classes.inventoryInputsBox}>
          <LbInput {...defaultInputProps} name="supplement" placeholder="Supplement" />
          <LbInput
            {...defaultInputProps}
            name="serving"
            placeholder="Inventory Balance"
            disabled
          />
          <LbInput
            {...defaultInputProps}
            name="orderAmount"
            placeholder="Amount of Order"
            type="number"
          />
          <LbDatePicker
            {...defaultInputProps}
            name="orderDate"
            placeholder="Order Date"
          />
          <LbSelect
            {...defaultInputProps}
            name="status"
            options={statusOption}
            label="Status"
            displayEmpty
          />
          <Button variant="outlined" type="submit" onClick={onSubmit}>
            Apply
          </Button>
        </Row>
      </form>
    </Paper>
  );
};

FiltersRow.propTypes = {
  control: PropTypes.object,
  onSubmit: PropTypes.func,
  changeOrderHandler: PropTypes.func,
};

export { FiltersRow };
