import React from 'react';

import { Button } from '@mui/material';
import { observer } from 'mobx-react';

import { goTo } from '../../../../../shared/utils/angularUtils';
import classes from './TabTitleRow.module.scss';
import { loggedInUserDataStore } from '../../../../../shared/stores/loggedInUserDataStore';

const TabTitleRow = observer(() => {
  const { isCoach } = loggedInUserDataStore;

  return (
    <div className={classes.box}>
      <h6>Active Calendar Users</h6>
      {isCoach && (
        <Button variant="contained" onClick={() => goTo('/active-calendar/registration')}>
          + Add Active Calendar Member
        </Button>
      )}
    </div>
  );
});

export { TabTitleRow };
