import React, { ReactNode } from 'react';

import classNames from 'classnames';

import classes from './Nutrient.module.scss';
import { NutrientType } from '../../../constants/NUTRIENTS_ENUM';

type NutrientProps = {
  children?: ReactNode;
  nutrient: NutrientType;
  className?: string;
  coloredText?: boolean;
  iconShown?: boolean;
};

const Nutrient = ({
  children,
  nutrient,
  className,
  coloredText,
  iconShown = true,
}: NutrientProps) => {
  const nutrientBoxClasses = classNames(classes.nutrientBox, className);
  const nutrientClasses = classNames(classes.nutrientIcon, classes[nutrient]);
  const textClasses = classNames({ [classes[nutrient]]: coloredText });

  return (
    <div className={nutrientBoxClasses}>
      {iconShown && <div className={nutrientClasses} />}
      {children && <div className={textClasses}>{children}</div>}
    </div>
  );
};

export { Nutrient };
