import { Components } from '@mui/material';

export const MuiCheckboxTheme: Components['MuiCheckbox'] = {
  defaultProps: {
    color: 'secondary',
    disableRipple: true,
    size: 'large',
  },
  styleOverrides: {
    root: {
      padding: '0',
      flexShrink: '0',
      '.MuiSvgIcon-root': {
        fill: 'var(--natural-300)',
      },
      '&.Mui-disabled': {
        '.MuiSvgIcon-root': {
          fill: 'var(--natural-200)',
        },
      },
      '&:focus-within .MuiSvgIcon-root, &:hover .MuiSvgIcon-root ': {
        fill: 'currentColor',
      },
      '&.Mui-checked': {
        '.MuiSvgIcon-root': {
          fill: 'currentColor',
        },
      },
    },
  },
  variants: [
    {
      props: { color: 'secondary' },
      style: {
        color: 'var(--main-black)',
      },
    },
    {
      props: { color: 'primary' },
      style: {
        color: 'var(--primary-500)',
        '&.Mui-disabled': {
          color: 'var(--primary-200)',
        },
      },
    },
  ],
};

declare module '@mui/material/Checkbox' {
  interface CheckboxPropsSizeOverrides {
    large: true; // get large svg size
  }
}
