'use strict';

angular.module('app').factory('MealPlanService', MealPlanService);

MealPlanService.$inject = ['ApiService', 'utilService'];

function MealPlanService(ApiService, utilService) {
    return {
        getMealPlan: function (id, prms) {
            var user = utilService.getLoggedInUserData();
            return ApiService.get(user && user.role === 'CUSTOMER' ?
                'api/user/mealplans' : 'api/coach/user/' + id + '/mealplan', prms)
                .then(function (res) {
                    return res;
                }, function (err) {
                    return err;
                });
        },
        updateMealPlan: function (id, prms) {
            return ApiService.put('api/coach/user/' + id + '/mealplan', prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getGeneratedMealPlan: function (id, prms) {
            return ApiService.get('api/coach/user/' + id + '/mealplan/build', prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        generateMealPlanByUserInfo: function (prms) {
            return ApiService.get('api/coach/mealplan/build', prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        switchAmountOfMeals: function (id, data) {
            return ApiService.put('api/coach/user/' + id + '/switchmeal', null, data).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getMealAdjustments: function(userId) {
            return ApiService.get('api/coach/meal_adjustments/' + userId).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        deleteMealPlan: function (userId, planId) {
            return ApiService.delete('​/api​/coach​/user​/' + userId + '​/mealplan​/' + planId).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        }
    };
}
