import { useEffect, useState } from 'react';

import { EntityStatus } from '../../../constants/entityStatus';
import { entityStatusService } from '../../../services/entityStatusService';

export const useEntityStatusInfo = (entityId: number) => {
  const [status, setStatus] = useState<EntityStatus | null>(null);

  const getStatusDetails = async () => {
    const statusDetails = await entityStatusService.getStatusDetails(entityId);

    setStatus(statusDetails.status);
  };

  useEffect(() => {
    getStatusDetails();
  }, [entityId]);

  return { status };
};
