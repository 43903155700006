import React from 'react';

import classes from './TitleSection.module.scss';
import goHighLevelLogo from '../../../../shared/assets/image/goHighLevelLogo.webp';
import { TitleWithDescription } from '../../../../shared/components/text-formation-components';
import { LbChip } from '../../../../shared/components/ui-components';
import { GoHighLevelConnectionInfo } from '../../../interfaces/goHighLevelAccount';

type TitleSectionProps = { connectionInfo: GoHighLevelConnectionInfo };

const TitleSection = ({ connectionInfo }: TitleSectionProps) => {
  const { isConnected, accountInfo } = connectionInfo;

  const title = isConnected ? (
    <div className={classes.idTitleBox}>
      <span>GoHighLevel ID:</span>
      <LbChip label={accountInfo.locationId} color="default" size="small" />
    </div>
  ) : (
    <span>GoHighLevel</span>
  );

  return (
    <div className={classes.infoBox}>
      <img src={goHighLevelLogo} alt="GoHighLevel logo" />
      <TitleWithDescription
        title={title}
        description="Elevate Marketing and Sales with HighLevel's All-in-One Platform!"
      />
    </div>
  );
};

export { TitleSection };
