import React from 'react';
import PropTypes from 'prop-types';

import { IconButton } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

import classes from './SupplementFormHeader.module.scss';

const SupplementFormHeader = ({ supplementName, brand, copyHandler, deleteHandler }) => {
  return (
    <div className={classes.topRow}>
      <div className={classes.titleBox}>
        <h4>{supplementName}</h4>
        <p>{brand}</p>
      </div>
      <div className={classes.btnBox}>
        <IconButton onClick={copyHandler}>
          <FileCopyIcon fontSize="medium" />
        </IconButton>
        <IconButton onClick={deleteHandler}>
          <DeleteRoundedIcon fontSize="medium" />
        </IconButton>
      </div>
    </div>
  );
};

SupplementFormHeader.propTypes = {
  supplementName: PropTypes.string,
  brand: PropTypes.string,
  deleteHandler: PropTypes.func.isRequired,
  copyHandler: PropTypes.func.isRequired,
};

export { SupplementFormHeader };
