const autoNgTemplateLoaderTemplate1 = require('./coach-notes.template.html');

'use strict';

(function () {
    angular
        .module('app.coachNotes', [])
        .config(coachNotesConfig);

    coachNotesConfig.$inject = ['$routeProvider'];

    function coachNotesConfig($routeProvider) {
        $routeProvider
            .when('/coach-notes', {
                disableCache: true,
                templateUrl: autoNgTemplateLoaderTemplate1,
                resolve: {
                    loginRequired: ['AuthService', function (AuthService) {
                        return AuthService.loginRequired();
                    }]
                }
            })
    }
})();
