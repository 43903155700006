import { SpringPagination } from '../../shared/interfaces/SpringPagination';
import { apiService } from '../../shared/services/apiService';
import { getFilteredTruthyFieldsObj } from '../../shared/utils/sortingAndGroupingUtil';
import {
  EntitySubscriptionInfo,
  CreateEntitySubscriptionInfo,
  EntitySubscriptionPaymentInfo,
  InitialPlanPricingPaymentDetail,
  MonthlyRenewalPaymentDetail,
} from '../interfaces/entitySubscription';
import { EntitySubscriptionsFilters } from '../interfaces/entitySubscriptionFilters';

type GetCalculatedPaymentDetailsResponse = {
  subscriptionInfo: EntitySubscriptionPaymentInfo;
  paymentDetails: {
    initialPlanPricing?: InitialPlanPricingPaymentDetail;
    monthlyRenewal: MonthlyRenewalPaymentDetail;
  };
};

type GetFilteredEntitySubscriptionsResponse = SpringPagination<EntitySubscriptionInfo[]>;

export const entitySubscriptionService = {
  async getEntitySubscriptions(entityId: number) {
    const resp = await apiService.get<EntitySubscriptionInfo[]>(
      `/api/entity/${entityId}/subscriptions`,
    );

    return resp.data;
  },

  async getFilteredEntitySubscriptions(
    entityId: number,
    params: EntitySubscriptionsFilters,
  ) {
    const filteredParams = getFilteredTruthyFieldsObj(params);

    const resp = await apiService.post<GetFilteredEntitySubscriptionsResponse>(
      `/api/entity/${entityId}/subscriptions`,
      filteredParams,
    );

    return resp.data;
  },

  async createEntitySubscription(
    entityId: number,
    subscription: CreateEntitySubscriptionInfo,
    isRunGlobalSpinner: boolean = false,
  ) {
    const resp = await apiService.post<EntitySubscriptionInfo>(
      `/api/entity/${entityId}/subscription`,
      subscription,
      { isRunGlobalSpinner },
    );

    return resp.data;
  },

  async getCalculatedPaymentDetails(
    entityId: number,
    paymentInfo: EntitySubscriptionPaymentInfo,
    isRunGlobalSpinner: boolean = false,
  ) {
    const resp = await apiService.post<GetCalculatedPaymentDetailsResponse>(
      `/api/entity/${entityId}/subscription/payment-details`,
      paymentInfo,
      { isRunGlobalSpinner },
    );

    return resp.data;
  },

  async changeActivationStatus(
    entityId: number,
    subscriptionId: number,
    activate: boolean,
  ) {
    const params = { activate };

    const resp = await apiService.put<EntitySubscriptionInfo>(
      `/api/entity/${entityId}/subscription/${subscriptionId}/activate`,
      undefined,
      { params },
    );

    return resp.data;
  },

  async changeDisplayToUserStatus(
    entityId: number,
    subscriptionId: number,
    display: boolean,
  ) {
    const params = { display };

    const resp = await apiService.put<EntitySubscriptionInfo>(
      `/api/entity/${entityId}/subscription/${subscriptionId}/display-to-user`,
      undefined,
      { params },
    );

    return resp.data;
  },
};
