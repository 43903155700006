const autoNgTemplateLoaderTemplate1 = require('./check-in-tab.template.html');

'use strict';

angular
    .module('app')
    .directive('checkInTab', checkInTab);

checkInTab.$inject = [];

function checkInTab() {
    return {
        restrict: 'E',
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: 'CheckInTabController',
        controllerAs: 'vm'
    };
}
