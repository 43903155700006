import React from 'react';

import { observer } from 'mobx-react';

import {
  GridTableCell,
  GridTableRow,
} from '../../../../shared/components/table-components';
import { FilterButton, SortButtons } from '../../../../shared/components/buttons';
import { scholasticCustomersListStore } from '../../../stores/ScholasticCustomersListStore';

const TitleRow = observer(() => {
  const { isShowLocationColumn, isShowCoachColumn, filterMethod } =
    scholasticCustomersListStore;
  const { isShowFilterRow, toggleShowFilterRow, handleSort, getIsSortedBy } =
    filterMethod;

  return (
    <GridTableRow variant="header">
      <GridTableCell>
        <SortButtons
          fieldName="FULL_NAME"
          onSort={handleSort}
          getIsActive={getIsSortedBy}
        >
          Customer
        </SortButtons>
      </GridTableCell>
      <GridTableRow variant="unstyled">
        {isShowLocationColumn && (
          <GridTableCell>
            <SortButtons
              fieldName="LOCATION"
              onSort={handleSort}
              getIsActive={getIsSortedBy}
            >
              Location
            </SortButtons>
          </GridTableCell>
        )}
        <GridTableCell>School</GridTableCell>
        {isShowCoachColumn && <GridTableCell>Coach</GridTableCell>}
        <GridTableCell>
          <SortButtons
            fieldName="VALID_FROM"
            onSort={handleSort}
            getIsActive={getIsSortedBy}
          >
            Start Date
          </SortButtons>
        </GridTableCell>
        <GridTableCell>Status</GridTableCell>
        <div />
      </GridTableRow>
      <GridTableCell justify="end">
        <FilterButton isActive={isShowFilterRow} onClick={toggleShowFilterRow} />
      </GridTableCell>
    </GridTableRow>
  );
});

export { TitleRow };
