const autoNgTemplateLoaderTemplate1 = require('./customer-profile-tab.template.html');

'use strict';

angular
    .module('app')
    .directive('customerProfileTab', customerProfileTab);

customerProfileTab.$inject = [];

function customerProfileTab() {
    return {
        restrict: 'E',
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: 'CustomerProfileTabController',
        controllerAs: 'vm',
        scope: {
            entityId: '<',
            customerAvailableModules: '<'
        },
    };
}
