import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { FormProvider, useForm } from 'react-hook-form';

import { MedicalTestFormCard } from '../../../../../components/lab-test-components';
import { customerMedicalTestsStore } from '../../../../../stores/customerMedicalTestsStore';

const MedicalTestForm = ({ defaultValues, onCancel, onSubmit, isInitialEdit }) => {
  const [autoFocusFieldKey, setAutoFocusFieldKey] = useState('');

  const {
    setMedTestResultIdForInitialEdit,
    setMedTestFieldNameForInitialFocus,
    medTestResultIdForInitialEdit,
    medTestFieldNameForInitialFocus,
  } = customerMedicalTestsStore;

  const methods = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  const { handleSubmit } = methods;

  useEffect(() => {
    if (isInitialEdit && medTestFieldNameForInitialFocus) {
      const focusFieldKey = getAutoFocusFieldKey();
      setAutoFocusFieldKey(focusFieldKey);
    }

    return clearInitialEditAndFocusProps;
  }, [isInitialEdit]);

  const clearInitialEditAndFocusProps = () => {
    if (medTestResultIdForInitialEdit) {
      setMedTestResultIdForInitialEdit(null);
      setMedTestFieldNameForInitialFocus(null);
    }
  };

  const getAutoFocusFieldKey = () => {
    const resultIndex = getResultIndexForEditing();

    return `results.${resultIndex}.${medTestFieldNameForInitialFocus}`;
  };

  const getResultIndexForEditing = () => {
    return defaultValues?.results.findIndex(
      (result) => result.id === medTestResultIdForInitialEdit,
    );
  };

  return (
    <FormProvider {...methods}>
      <MedicalTestFormCard
        nameIndex=""
        isProfileCard
        onSubmit={handleSubmit(onSubmit)}
        onCancel={onCancel}
        autoFocusFieldKey={autoFocusFieldKey}
        focusResultId={medTestResultIdForInitialEdit}
      />
    </FormProvider>
  );
};

MedicalTestForm.propTypes = {
  defaultValues: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  isInitialEdit: PropTypes.bool,
};

export { MedicalTestForm };
