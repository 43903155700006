import { InbodyType } from '../../shared/constants/inbody-info/InbodyType';
import { EntryFieldConfig } from '../interfaces/entityInbodyData';

const disabledWaterFields = {
  ecw: false,
  icw: false,
  tbw: false,
  ecwTbw: false,
} as const;

const enabledWaterFields = {
  ecw: true,
  icw: true,
  tbw: true,
  ecwTbw: true,
} as const;

const generalFieldsConfig = {
  height: true,
  weight: true,
  percentBodyFat: true,
  bmr: true,
  bmi: true,
  dlm: true,
  phaseAngle: false, // input not exist
  smm: true,
  gender: false, // input not exist
  lbm: true,
  poundOfFat: true,
  bodyFatMethod: false, // not used in inbody
  measureUnit: 'INCHES',
  // disabled Measure Fields
  chest: false,
  hip: false,
  thigh: false,
  navel: false,
  bicep: false,
  neck: false,
} as const;

export const inbodyEntryFieldConfig: Record<
  Exclude<InbodyType, 'NON_INBODY'>,
  EntryFieldConfig
> = {
  INBODY_270: {
    ...generalFieldsConfig,
    ...disabledWaterFields,
  },
  INBODY_370: {
    ...generalFieldsConfig,
    ...disabledWaterFields,
  },
  INBODY_380H: {
    ...generalFieldsConfig,
    ...enabledWaterFields,
  },
  INBODY_570: {
    ...generalFieldsConfig,
    ...enabledWaterFields,
  },
  INBODY_580H: {
    ...generalFieldsConfig,
    ...enabledWaterFields,
  },
  INBODY_770: {
    ...generalFieldsConfig,
    ...enabledWaterFields,
  },
  INBODY_970: {
    ...generalFieldsConfig,
    ...enabledWaterFields,
  },
};
