import { FeeModule } from '../../../../constants/feeModule';
import { FeeSchedule } from '../../../../interfaces/paymentBundleInfo';
import { FeeFormModule } from '../../FeeModuleWidget.settings';

export const processFeeScheduleToFeeFormModules = (
  feeSchedule: FeeSchedule,
): FeeFormModule[] =>
  Object.entries(feeSchedule).map(([key, value]) => {
    const fixedKey = key as FeeModule;

    return {
      ...value,
      formKey: `feeModules.${fixedKey}`,
    };
  });
