import React from 'react';
import { Nutrient } from '../../../../../../../shared/components/ui-components';
import { MealNutrientInfo } from '../../../../../../interfaces/MealPlan';

type MealNutrientsProps = {
  nutrientsInfo: MealNutrientInfo;
};
const MealNutrients = ({ nutrientsInfo }: MealNutrientsProps) => {
  const { prt, crb, fat, calories } = nutrientsInfo;

  return (
    <>
      <Nutrient nutrient="proteins">{prt}</Nutrient>
      <Nutrient nutrient="carbs">{crb}</Nutrient>
      <Nutrient nutrient="fats">{fat}</Nutrient>
      <Nutrient nutrient="calories">{calories}</Nutrient>
    </>
  );
};

export { MealNutrients };
