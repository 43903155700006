import React from 'react';

import { Button } from '@mui/material';

import classes from './ScanButtonsRow.module.scss';
import { TitleWithDescription } from '../../../../../../../shared/components/text-formation-components';

type ScanButtonsRowProps = {
  onLoadScan: () => void;
  onFillInDataManually: () => void;
  hideFillInDataManually?: boolean;
};

const ScanButtonsRow = ({
  onFillInDataManually,
  onLoadScan,
  hideFillInDataManually,
}: ScanButtonsRowProps) => {
  return (
    <div className={classes.box}>
      <TitleWithDescription
        title="Body Comp Indicators"
        description="Please choose one of the options to input the data.
        If there are any issues with loading the scan from InBody, please fill in the information manually."
        titleFont="body-14-bold"
        className={classes.title}
      />
      <div className={classes.buttonGroup}>
        <Button variant="contained" size="small" onClick={onLoadScan}>
          Load scan from InBody
        </Button>
        {!hideFillInDataManually && (
          <Button variant="outlined" size="small" onClick={onFillInDataManually}>
            Fill in data manually
          </Button>
        )}
      </div>
    </div>
  );
};

export { ScanButtonsRow };
