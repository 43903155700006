import { useEffect } from 'react';

import { Path, useFormContext } from 'react-hook-form';

import { getFieldFormName } from '../utils/getFieldFormName';
import { InbodyConfigInfo } from '../../../../../../../shared/constants/inbody-info/inbodyData';
import { EntityInbodyDataFormValue } from '../../../../EntityInbodyDataConfig.settings';

export type UseRequiredFieldsReturn = {
  disabled?: boolean;
};

export const useRequiredFields = (
  field: Path<EntityInbodyDataFormValue>,
  requiredFields: InbodyConfigInfo['requiredFields'] = [],
): UseRequiredFieldsReturn => {
  const { setValue, watch } = useFormContext<EntityInbodyDataFormValue>();

  const requiredFieldsFormName = requiredFields.map(getFieldFormName);
  const requiredFieldsValue = requiredFieldsFormName.length
    ? watch(requiredFieldsFormName)
    : [];
  const isUnSelected = requiredFieldsValue.some((fieldValue) => !fieldValue);

  // un select field if any required field is unselected
  useEffect(() => {
    if (isUnSelected) {
      setValue(field, false);
    }
  }, [isUnSelected]);

  return { disabled: isUnSelected };
};
