import React from 'react';
import PropTypes from 'prop-types';

import { FILE_EXTENSION } from '../../../constants/file/FILE_EXTENSIONS';
import { ReactComponent as PDFFileIco } from '../../../assets/icons/file-extensions/pdf-file.svg';
import { ReactComponent as XLSFileIco } from '../../../assets/icons/file-extensions/xls-file.svg';
import { ReactComponent as TXTFileIco } from '../../../assets/icons/file-extensions/txt-file.svg';
import { ReactComponent as PNGFileIco } from '../../../assets/icons/file-extensions/png-file.svg';
import { ReactComponent as JPGFileIco } from '../../../assets/icons/file-extensions/jpg-file.svg';
import { ReactComponent as CSVFileIco } from '../../../assets/icons/file-extensions/csv-file.svg';
import { ReactComponent as DOCFileIco } from '../../../assets/icons/file-extensions/doc-file.svg';

const extensionsMap = {
  [FILE_EXTENSION.PDF]: PDFFileIco,
  [FILE_EXTENSION.XLS]: XLSFileIco,
  [FILE_EXTENSION.TXT]: TXTFileIco,
  [FILE_EXTENSION.PNG]: PNGFileIco,
  [FILE_EXTENSION.JPG]: JPGFileIco,
  [FILE_EXTENSION.CSV]: CSVFileIco,
  [FILE_EXTENSION.DOC]: DOCFileIco,
};

const FileExtensionImage = ({ fileExtension }) => {
  const ExtensionImage = extensionsMap[fileExtension];

  if (ExtensionImage) {
    return <ExtensionImage />;
  }

  return <div>{fileExtension}</div>;
};

FileExtensionImage.propTypes = {
  fileExtension: PropTypes.string,
};

export { FileExtensionImage };
