import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';

import classes from './PagesNavBox.module.scss';
import { NavLink } from './NavLink/NavLink';
import { NavMenu } from './NavMenu/NavMenu';
import { systemService } from '../../../services/systemService';
import { AdditionalLinks } from './AdditionalLinks/AdditionalLinks';
import { HeaderNavSetting } from '../../../interfaces/system/NavLinkConfig';
import { NAV_ITEM_TYPE } from '../../../constants/system/headerSettings';

const PagesNavBox = () => {
  const [userNavLinks, setUserNavLinks] = useState<HeaderNavSetting[]>([]);

  const getUserNavLink = async () => {
    const { headerSettings } = await systemService.getUserNavLinks();

    setUserNavLinks(headerSettings);
  };

  useEffect(() => {
    getUserNavLink();
  }, []);

  return (
    <Box className={classes.box}>
      {userNavLinks.map((page) => {
        switch (page.type) {
          case NAV_ITEM_TYPE.LINK:
            return <NavLink label={page.label} path={page.path} key={page.path} />;
          case NAV_ITEM_TYPE.MENU:
            return <NavMenu label={page.label} menuItems={page.items} key={page.label} />;
          case NAV_ITEM_TYPE.ADDITIONAL_LINKS:
            return <AdditionalLinks links={page.items} key={page.label} />;
          default:
            return null;
        }
      })}
    </Box>
  );
};

export { PagesNavBox };
