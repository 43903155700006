import { Components } from '@mui/material';
import type {} from '@mui/x-date-pickers/themeAugmentation';

export const MuiDateCalendarTheme: Components['MuiDateCalendar'] = {
  styleOverrides: {
    root: {
      button: {
        minWidth: '0',
        '&:disabled': {
          backgroundColor: 'transparent',
        },
      },
      '.MuiPickersCalendarHeader-labelContainer': {
        font: 'var(--title-18-bold)',
      },
      '.MuiDayCalendar-weekDayLabel': {
        font: 'var(--body-14-bold)',
        color: 'var(--main-black)',
      },
      '.MuiPickersDay-root': {
        font: 'var(--body-14-regular)',
        color: 'var(--main-black)',
      },
      '.MuiPickersDay-dayOutsideMonth': {
        color: 'var(--natural-400)',
      },
      '& button.MuiPickersDay-root': {
        '&:focus, &:hover': {
          backgroundColor: 'var(--natural-100)',
        },
        '&.Mui-selected': {
          backgroundColor: 'var(--primary-500)',
          font: 'var(--body-14-bold)',
        },
      },
      '& button.MuiPickersMonth-monthButton': {
        '&:focus, &:hover': {
          backgroundColor: 'var(--natural-100)',
        },
        '&.Mui-selected': {
          backgroundColor: 'var(--primary-500)',
          font: 'var(--body-14-bold)',
        },
      },
      '& button.MuiPickersYear-yearButton': {
        '&:focus, &:hover': {
          backgroundColor: 'var(--natural-100)',
        },
        '&.Mui-selected': {
          backgroundColor: 'var(--primary-500)',
          font: 'var(--body-14-bold)',
        },
      },
    },
  },
};
