import React from 'react';

import classes from './TotalAmountChart.module.scss';
import { SegmentedBar } from './SegmentedBar/SegmentedBar';
import { SummaryDetails } from './SummaryDetails/SummaryDetails';
import { getPriceView } from '../../../../../shared/utils/stringUtils';
import { useTotalAmountChart } from './hooks/useTotalAmountChart';
import { RevenueDateRangeFilterValues } from '../../DetailedRevenue.settings';
import { getFormattedDate } from '../../../../../shared/utils/dateUtils';
import { localDateTimeFormat } from '../../../../../shared/constants/apiDateFormat';

type TotalAmountChartProps = {
  entityId: number;
  dateRange: RevenueDateRangeFilterValues;
};

const TotalAmountChart = ({ entityId, dateRange }: TotalAmountChartProps) => {
  const { loadTime, totalAmount, totalAmountChartConfig } = useTotalAmountChart(
    entityId,
    dateRange,
  );

  return (
    <div>
      <div className={classes.titleBlock}>
        <h5 className={classes.title}>Total Amount</h5>
        <p className={classes.total}>{getPriceView(totalAmount)}</p>
        <p className={classes.description}>
          Data is accurate on {getFormattedDate(loadTime, localDateTimeFormat)}
        </p>
      </div>
      <SegmentedBar totalAmount={totalAmount} config={totalAmountChartConfig} />
      <SummaryDetails config={totalAmountChartConfig} />
    </div>
  );
};

export { TotalAmountChart };
