'use strict';

export const authManagementModule = angular
  .module('app.authManagement', [])
  .config(authManagementConfig);

authManagementConfig.$inject = ['$routeProvider'];

function authManagementConfig($routeProvider) {
  $routeProvider
    .when('/set-new-password', { // header nav bar hide for this route
      template: '<ngz-set-new-password></ngz-set-new-password>',
    })
    .when('/', {
      disableCache: true,
      template: '<ngz-sign-in />',
      resolve: {
          skipIfLoggedIn: ['AuthService', function (AuthService) {
              return AuthService.skipIfLoggedIn();
          }]
      }
  })
  .when('/reset-password', {
    disableCache: true,
    template: '<ngz-forgot-password />',
    resolve: {
        skipIfLoggedIn: ['AuthService', function (AuthService) {
            return AuthService.skipIfLoggedIn();
        }]
    }
})
}
