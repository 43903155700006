import React from 'react';
import PropTypes from 'prop-types';

import { ComboBox } from '../../../../../../shared/components/form-components';
import { useSelector } from './hooks/useSelector';

const Selector = ({ selectorSettings, parentSelectorSettings, reportType }) => {
  const { control, options, label, parameterKey, rules, isShowSelector } = useSelector({
    selectorSettings,
    parentSelectorSettings,
    reportType,
  });

  return (
    isShowSelector && (
      <ComboBox
        control={control}
        name={parameterKey}
        label={label}
        options={options}
        rules={rules}
        valueKey="id"
      />
    )
  );
};

const selectorSettingsProps = {
  paymentType: PropTypes.string.isRequired,
  selectAllAvailable: PropTypes.bool.isRequired,
  selectorType: PropTypes.string.isRequired,
  parameterKey: PropTypes.string.isRequired,
  selectorLabel: PropTypes.string.isRequired,
};

Selector.propTypes = {
  selectorSettings: PropTypes.shape(selectorSettingsProps),
  parentSelectorSettings: PropTypes.shape(selectorSettingsProps),
  reportType: PropTypes.string,
};

export { Selector };
