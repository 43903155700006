import React, { ChangeEvent } from 'react';

import { observer } from 'mobx-react';

import classes from './AttachFileOption.module.scss';
import { scholasticCustomerService } from '../../../../services/scholasticCustomerService';
import { scholasticCustomersListStore } from '../../../../stores/ScholasticCustomersListStore';

const AttachFileOption = observer(() => {
  const { getCustomerList } = scholasticCustomersListStore;

  const handleUploadFile = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }

    const selectedFile = event.target.files[0];

    await scholasticCustomerService.uploadUsersInfo(selectedFile);
    getCustomerList();
  };

  return (
    <label htmlFor="files" className={classes.option}>
      <input onChange={handleUploadFile} id="files" type="file" accept=".csv" />
      Attach Files
    </label>
  );
});

export { AttachFileOption };
