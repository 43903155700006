'use strict';

angular.module('app').factory('CustomerService', CustomerService);

CustomerService.$inject = ['ApiService', 'DateTimeUtil', 'constants'];

function CustomerService(ApiService, DateTimeUtil, constants) {
    return {
        markUserAsSeen: function (id) {
            return ApiService.post('/api/coach/inbodydata/' + id +'/markseen').then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getUserInbodyMetric: function (id) {
            return ApiService.get('api/coach/inbodydata/' + id).then(function (res) {
                res.data.dates = res.data.dates.filter(function (value) {
                    return value !== null;
                });

                res.data.data = res.data.data.filter(function (value) {
                    return value.scanOn !== null;
                });

                res.data.dates = res.data.dates.map(function (value) {
                    var dateArr = value.split('/');
                    var newDate = new Date(dateArr[2], dateArr[0] - 1, dateArr[1]);
                    return moment(newDate).format('MM.DD.YYYY');
                });

                res.data.data.forEach(function (value) {
                    for(var i in value) {
                        if (typeof value[i] === 'number' && i !== 'ecw_tbw')
                            value[i] = value[i].toFixed(1);
                    }
                });

                return res;
            }, function (err) {
                return err;
            })
        },
        getUserGoalsWithScanDates: function (id) {
            return ApiService.get('api/coach/inbodydata/' + id + '/mealplan').then(function (res) {
                return res;
            }, function (err) {
                return err;
            })
        },
        getCustomerDetails: function (userId, customerId) {
            return ApiService.get('api/coach/' + userId + '/customer/' + customerId).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getInbodyData: function (value) {
            return ApiService.get('api/coach/inbody/' + value).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getUserScanDates: function (userId) {
            return ApiService.get('api/coach/user/' + userId + '/stats/user-scans').then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getCurrentUser: function () {
            return ApiService.get('api/user').then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        changePushNotificationStatus: function (id, prms) {
            return ApiService.put('api/pushnotifications/' + id, null, prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getInbodyDataForUser: function (id) {
            return ApiService.put('api/coach/inbody/update/' + id).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getActivities: function () {
            return ApiService.get('api/profile/activities').then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        calculateWorkoutHours: function (workouts) {
            var totalWorkouts = 0;
            Object.keys(workouts).forEach(function (key) {
                if (key !== 'defaultMealsPerDay') totalWorkouts += workouts[key];
            });
            return totalWorkouts;
        },
        createCustomer: function (data) {
            var registerDate = DateTimeUtil.formatDate(DateTimeUtil.now(), 'YYYY-MM-DD');
            data.customer.dob = DateTimeUtil.formatDate(data.customer.dob, 'MM/DD/YYYY');
            data.user.roles = ['CUSTOMER'];

            return ApiService.post('api/coach/user', data, {date: registerDate}).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        createScanManually: function (id, data) {
            data.datetimes = DateTimeUtil.formatToDatetime(data.scanDate, data.scanTime);

            delete data.scanTime;
            delete data.scanDate;

            return ApiService.post('api/coach/inbody/manual/' + id, data).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        checkInbodyData: function (inbodyDataList) {
            for (var prop in inbodyDataList) {
                if (!(prop === 'bodyFatMass' || prop === 'leanBodyMass' || prop === 'totalBodyWatter'
                    || prop === 'ecwtbw' || prop === 'inWatter' || prop === 'exWatter') &&
                    !inbodyDataList[prop]) {
                    return false;
                }
            }
            return true;
        },
        filterSubscriptions: function (subscriptions, customerInbodyType) {
            return subscriptions.filter(subscription => {
                const subscriptionTypes = constants.enums.subscriptionTypes;
                const NON_INBODY = constants.inbodyTypes.NON_INBODY.value;

                if (!subscription.active) {
                    return;
                }

                switch (subscription.subscriptionType) {
                    case subscriptionTypes.SUPPLEMENT:
                        return;
                    case subscriptionTypes.NUTRITION:
                        return customerInbodyType !== NON_INBODY;
                    case subscriptionTypes.NON_INBODY:
                        return customerInbodyType === NON_INBODY;
                    default:
                        return true;
                }
            });
        }
    };
}
