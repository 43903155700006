import { makeAutoObservable } from 'mobx';
import { getService } from 'react-in-angularjs';

import { paginationListLimit } from '../../shared/constants/systemConstants';
import { resetAllStore } from '../../shared/stores/resetAllStore';
import { printFile } from '../../shared/utils/apiUtils';
import { customerService } from '../services/customerService';

class ActiveUsersCommunicationStore {
  constructor() {
    this.setDefaultValues();
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.customers = [];
    this.customerIds = [];
    this.checkedCustomerIds = [];
    this.isInitialList = false;
    this.pagesCount = null;
    this.currentPage = 1;
    this.paginationParams = { limit: paginationListLimit, offset: 0 };
    this.filterParams = {};
  }

  async getCustomers() {
    const resp = await customerService.getUsers({
      ...this.filterParams,
      ...this.paginationParams,
    });

    if (!this.isInitialList) {
      this.setIsInitialList(!!resp.users.length);
    }

    this.setCustomerIds(resp.ids);
    this.setPagination(resp.ids.length);
    this.setCustomers(resp.users);
  }

  setPagination(usersCount) {
    if (usersCount > paginationListLimit) {
      const newPagesCount = Math.ceil(usersCount / paginationListLimit);
      this.setPagesCount(newPagesCount);
    } else {
      this.setPagesCount(null);
    }
  }

  getFilteredCustomers(filteredParams) {
    this.setFilterParams(filteredParams);
    this.setCheckedCustomerIds([]);
    this.setCurrentPage(1);
    this.getCustomers();
  }

  pageChangeHandler(event, value) {
    const offset = (value - 1) * paginationListLimit;

    this.setOffset(offset);
    this.setCurrentPage(value);
    this.getCustomers();
  }

  getIsAllCustomersChecked() {
    return (
      !!this.checkedCustomerIds.length &&
      this.customerIds.length === this.checkedCustomerIds.length
    );
  }

  checkCustomer(id) {
    const idIndex = this.checkedCustomerIds.indexOf(id);

    const newCheckedCustomerIds = [...this.checkedCustomerIds];
    if (idIndex !== -1) {
      newCheckedCustomerIds.splice(idIndex, 1);
    } else {
      newCheckedCustomerIds.push(id);
    }

    this.setCheckedCustomerIds(newCheckedCustomerIds);
  }

  checkAllCustomers() {
    const isAllCustomersChecked = this.getIsAllCustomersChecked();

    if (isAllCustomersChecked) {
      this.setCheckedCustomerIds([]);
    } else {
      this.setCheckedCustomerIds(this.customerIds);
    }
  }

  openSendMessageDialog() {
    const ModalService = getService('ModalService');

    ModalService.sendMessage(
      {
        via: 'push',
        userIds: this.checkedCustomerIds,
      },
      this.customerIds,
      this.checkedCustomerIds,
      true,
    );
  }

  async printReport() {
    const reportParams = this.getParamsForReport();

    const { fileUrl } = await customerService.getCustomersReport(reportParams);

    printFile(fileUrl);
  }

  getParamsForReport() {
    const allCustomersNotChecked = !this.getIsAllCustomersChecked();
    const customersChecked = !!this.checkedCustomerIds.length;

    const reportParams = { ...this.filterParams };

    if (allCustomersNotChecked && customersChecked) {
      reportParams.customerList = this.checkedCustomerIds;
    }

    return reportParams;
  }

  setCustomers(customers) {
    this.customers = customers;
  }

  setCustomerIds(customerIds) {
    this.customerIds = customerIds;
  }

  setCheckedCustomerIds(checkedCustomerIds) {
    this.checkedCustomerIds = checkedCustomerIds;
  }

  setIsInitialList(isInitialList) {
    this.isInitialList = isInitialList;
  }

  setPagesCount(pagesCount) {
    this.pagesCount = pagesCount;
  }

  setCurrentPage(currentPage) {
    this.currentPage = currentPage;
  }

  setFilterParams(filterParams) {
    this.filterParams = filterParams;
  }

  setOffset(offset) {
    this.paginationParams = { limit: paginationListLimit, offset };
  }
}

const activeUsersCommunicationStore = new ActiveUsersCommunicationStore();

resetAllStore.addResetMethod(activeUsersCommunicationStore.setDefaultValues);

export { activeUsersCommunicationStore, ActiveUsersCommunicationStore };
