const autoNgTemplateLoaderTemplate1 = require('./coach-setup.template.html');
const autoNgTemplateLoaderTemplate2 = require('./coach-setup.template.html');

'use strict';

(function () {
    angular
        .module('app.coachSetup', [])
        .config(coachSetupConfig);

    coachSetupConfig.$inject = ['$routeProvider'];

    function coachSetupConfig($routeProvider) {
        $routeProvider
            .when('/coach-setup', {
                disableCache: true,
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'CoachSetupController',
                controllerAs: 'vm',
                resolve: {
                    loginRequired: ['AuthService', function (AuthService) {
                        return AuthService.loginRequired();
                    }]
                }
            })
            .when('/coach-setup/:id', {
                disableCache: true,
                templateUrl: autoNgTemplateLoaderTemplate2,
                controller: 'CoachSetupController',
                controllerAs: 'vm',
                resolve: {
                    loginRequired: ['AuthService', function (AuthService) {
                        return AuthService.loginRequired();
                    }]
                }
            })
    }
})();
