import React from 'react';

import { useForm } from 'react-hook-form';
import { useAsyncFn } from 'react-use';

import { LbModal } from '../../../../../shared/components/modals';
import { LbInput } from '../../../../../shared/components/form-components';
import { validateOnlyInteger } from '../../../../../shared/utils/reactHookFormUtils';

type ManageSeatsFormValues = {
  freeSeatsLimit: number;
};

type ManageSeatsModalProps = {
  freeSeatsDefaultValue: number;
  freeSeatsMinValue: number;
  onClose: () => void;
  onSubmit: (freeSeatsLimit: number) => Promise<void>;
};
const ManageSeatsModal = ({
  freeSeatsDefaultValue,
  freeSeatsMinValue,
  onClose,
  onSubmit,
}: ManageSeatsModalProps) => {
  const { control, handleSubmit } = useForm<ManageSeatsFormValues>({
    defaultValues: {
      freeSeatsLimit: freeSeatsDefaultValue,
    },
    mode: 'onChange',
    shouldUnregister: true,
  });
  const [state, handleFromSubmit] = useAsyncFn(
    handleSubmit(async ({ freeSeatsLimit }) => {
      await onSubmit(freeSeatsLimit);

      onClose();
    }),
  );

  return (
    <LbModal
      open
      title="Manage Seats"
      text="Enter the maximum number of people who can use the free access subscription"
      primaryBtnConfig={{ text: 'Save', onClick: handleFromSubmit }}
      secondaryBtnConfig={{ text: 'Cancel', onClick: onClose }}
      isLoading={state.loading}
    >
      <LbInput
        control={control}
        label="Free access user limit"
        name="freeSeatsLimit"
        type="number"
        rules={{
          required: 'Free access user limit is required',
          validate: { validateOnlyInteger },
          min: {
            value: freeSeatsMinValue,
            message: `You cannot remove seats that are already occupied`,
          },
        }}
        variant="outlined"
        size="small"
      />
    </LbModal>
  );
};

export { ManageSeatsModal };
