import React from 'react';

import { Button } from '@mui/material';
import { useToggle, useUnmount } from 'react-use';
import { observer } from 'mobx-react';

import { InfoBox } from '../../../shared/components/ui-components';
import { useEntitySubscriptions } from './hooks/useEntitySubscriptions';
import { useSort } from '../../../shared/hooks/useSort';
import { CreateEntitySubscriptionModal } from '../../components/CreateEntitySubscriptionModal/CreateEntitySubscriptionModal';
import { EntitySubscriptionList } from '../../components/EntitySubscriptionList/EntitySubscriptionList';
import { useGetNecessaryData } from '../../../shared/hooks/useGetNecessaryData';
import { taxStore } from '../../stores/taxStore';
import { useAppliedTaxUpdate } from '../../hooks/useAppliedTaxUpdate';
import { subscriptionSettingsStore } from '../../stores/subscriptionSettingsStore';

type SetupEntitySubscriptionTableProps = {
  entityId: number;
};

const SetupEntitySubscriptionTable = observer(
  ({ entityId }: SetupEntitySubscriptionTableProps) => {
    const [isShowCreateSubscriptionModal, toggleShowCreateSubscriptionModal] =
      useToggle(false);

    const { appliedTaxRateInfo } = taxStore;
    const { settings } = subscriptionSettingsStore;

    const {
      entitySubscriptions,
      createEntitySubscription,
      changeActivationStatus,
      changeDisplayToUserStatus,
      getEntitySubscriptions,
    } = useEntitySubscriptions(entityId);

    const { sortedData: sortedEntitySubscriptions, sortMethods } = useSort({
      data: entitySubscriptions,
      sortTypeConfig: {
        name: 'STRING',
        subscriptionType: 'STRING',
      },
      defaultSortInfo: { fieldName: 'name', direction: 'ASC' },
    });

    useGetNecessaryData(async () => {
      await Promise.all([
        subscriptionSettingsStore.getSettings(entityId),
        taxStore.getTaxInfo(entityId),
      ]);
    }, [entityId]);
    useUnmount(() => {
      subscriptionSettingsStore.setDefaultValues();
      taxStore.setDefaultValues();
    });

    useAppliedTaxUpdate(() => {
      getEntitySubscriptions();
    });

    return (
      <InfoBox
        title="Subscription management"
        icon={
          <Button
            onClick={toggleShowCreateSubscriptionModal}
            variant="contained"
            size="small"
          >
            New Subscription
          </Button>
        }
        customContentPadding
      >
        <EntitySubscriptionList
          subscriptionsList={sortedEntitySubscriptions}
          changeActivationStatus={changeActivationStatus}
          changeDisplayToUserStatus={changeDisplayToUserStatus}
          sortMethods={{
            getIsActive: sortMethods.getIsSortedBy,
            onSort: sortMethods.handleSort,
            nameKey: 'name',
            subscriptionTypeKey: 'subscriptionType',
          }}
          appliedTaxRateInfo={appliedTaxRateInfo}
        />
        {isShowCreateSubscriptionModal && (
          <CreateEntitySubscriptionModal
            onClose={toggleShowCreateSubscriptionModal}
            onSubmit={createEntitySubscription}
            entityId={entityId}
            subscriptionSettings={settings}
          />
        )}
      </InfoBox>
    );
  },
);

export { SetupEntitySubscriptionTable };
