import React from 'react';

import { useFormContext } from 'react-hook-form';

import { LbInput, LbRadioGroup } from '../../../../../../../components/form-components';
import { scanOptions } from './Scans.settings';
import { CustomFilterFormValues } from '../../AddCustomFilterForm.settings';
import { ModalInputBox, ModalInputGroup } from '../../../../../../../components/modals';

const Scans = () => {
  const { control, register } = useFormContext<CustomFilterFormValues>();

  return (
    <ModalInputBox title="Scans">
      <input {...register('filters.SCANS.filterType', { value: 'SCANS' })} hidden />
      <ModalInputGroup>
        <LbRadioGroup
          row
          control={control}
          name="filters.SCANS.hasScans"
          options={scanOptions}
          defaultValue=""
        />
        <LbInput
          control={control}
          size="small"
          variant="outlined"
          name="filters.SCANS.lastDays"
          label="Last Days"
          type="number"
          rules={{
            min: {
              value: 0,
              message: "Can't be less than 0",
            },
            max: {
              value: 30,
              message: "Can't be more than 30",
            },
          }}
        />
      </ModalInputGroup>
    </ModalInputBox>
  );
};

export { Scans };
