import React from 'react';

import { Button, Checkbox, Paper } from '@mui/material';
import { observer } from 'mobx-react';

import classes from './FiltersRow.module.scss';
import { LbSelect } from '../../../../shared/components/form-components/LbSelect/LbSelect';
import { LbDatePicker } from '../../../../shared/components/form-components/LbDatePicker/LbDatePicker';
import { InputWithArrow } from '../../../pages/customers-dashboard/components/InputWithArrow/InputWithArrow';
import { LbInput } from '../../../../shared/components/form-components/LbInput/LbInput';
import {
  activationStatusOption,
  appointmentOptions,
  defaultInputProps,
  inventoryStatusOptions,
  labTestDateOptions,
} from './FiltersRow.settings';
import { activeUsersCommunicationStore } from '../../../stores/activeUsersCommunicationStore';
import { useFilterRow } from './FiltersRow.hooks';

const FiltersRow = observer(() => {
  const { getIsAllCustomersChecked, checkAllCustomers } = activeUsersCommunicationStore;

  const { control, ascOrderHandler, descOrderHandler, filterCustomer } = useFilterRow();

  const isAllCustomersChecked = getIsAllCustomersChecked();

  defaultInputProps.control = control;

  return (
    <Paper variant="roundedBox" className={classes.filtersBox}>
      <form onSubmit={filterCustomer}>
        <Checkbox
          className={classes.checkbox}
          onChange={checkAllCustomers}
          checked={isAllCustomersChecked}
        />
        <div className={classes.inputBox}>
          <InputWithArrow
            upArrowClickHandler={() => ascOrderHandler('fullName')}
            downArrowClickHandler={() => descOrderHandler('fullName')}
          >
            <LbInput {...defaultInputProps} name="name" placeholder="Full Name" />
          </InputWithArrow>
          <InputWithArrow
            upArrowClickHandler={() => ascOrderHandler('labTestStartDate')}
            downArrowClickHandler={() => descOrderHandler('labTestStartDate')}
          >
            <LbSelect
              {...defaultInputProps}
              name="labTestDateFilter"
              options={labTestDateOptions}
              label="Lab Test Date"
              defaultValue="ALL"
            />
          </InputWithArrow>
          <LbSelect
            {...defaultInputProps}
            name="appointmentStatus"
            options={appointmentOptions}
            label="Appointment Status"
            displayEmpty
          />
          <LbSelect
            {...defaultInputProps}
            name="inventoryStatus"
            options={inventoryStatusOptions}
            label="Inventory"
            displayEmpty
          />
          <InputWithArrow
            upArrowClickHandler={() => ascOrderHandler('startDate')}
            downArrowClickHandler={() => descOrderHandler('startDate')}
          >
            <LbDatePicker
              {...defaultInputProps}
              name="startDate"
              placeholder="Start date"
            />
          </InputWithArrow>
          <LbSelect
            {...defaultInputProps}
            name="activationStatus"
            options={activationStatusOption}
            label="Status"
            displayEmpty
          />
        </div>
        <Button type="submit" variant="outlined" className={classes.submitBtn}>
          Apply
        </Button>
      </form>
    </Paper>
  );
});

export { FiltersRow };
