import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useFieldArray, useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react';

import { supplementsStore } from '../../stores/supplementsStore';
import { SupplementSearch } from './SupplementSearch/SupplementSearch';
import { SupplementForm } from './SupplementForm/SupplementForm';

const ActiveCalendarForm = observer(({ formArrName, setInitValue }) => {
  const { setToLastAdded } = supplementsStore;

  const [selectedSupplements, setSelectedSupplements] = useState([]);

  const { control, getValues } = useFormContext();

  const { fields, prepend, remove, insert, replace } = useFieldArray({
    control,
    name: formArrName,
  });

  const getInitValue = async () => {
    if (setInitValue) {
      const value = await setInitValue();
      replace(value);
    }
  };

  useEffect(() => {
    getInitValue();
  }, []);

  const copyHandler = (index) => {
    const fieldObj = getValues(`${formArrName}.${index}`);
    insert(index, fieldObj);
  };

  const addBtnHandler = () => {
    setToLastAdded(selectedSupplements);
    const newSupplementsArr = selectedSupplements.map((supplement) => ({
      supplementId: supplement.id,
      daysOfWeekJson: {
        friday: true,
        monday: true,
        saturday: true,
        sunday: true,
        thursday: true,
        tuesday: true,
        wednesday: true,
      },
      newSupplementScheduleInfoJsons: [
        { amount: supplement.servingSize || '', takeTime: '', withFood: '' },
      ],
    }));
    prepend(newSupplementsArr);
    setSelectedSupplements([]);
  };

  return (
    <>
      <SupplementSearch
        selectedSupplements={selectedSupplements}
        onChange={setSelectedSupplements}
        addBtnHandler={addBtnHandler}
      />
      {fields.map((supplement, index) => (
        <SupplementForm
          key={supplement.id}
          supplementId={supplement.supplementId}
          control={control}
          nameIndex={`${formArrName}.${index}.`}
          copyHandler={() => copyHandler(index)}
          deleteHandler={() => remove(index)}
        />
      ))}
    </>
  );
});

ActiveCalendarForm.propTypes = {
  formArrName: PropTypes.string,
  setInitValue: PropTypes.func,
};

export { ActiveCalendarForm };
