import React, { useState } from 'react';

import { observer } from 'mobx-react';
import { PersonalInfoBlock } from '../../components/PersonalInfoBlock/PersonalInfoBlock';
import { CreditCardComponent } from './CreditCardComponent/CreditCardComponent';
import { ButtonsRow } from '../../components/ButtonsRow/ButtonsRow';
import { stepperStore } from '../../../../stores/stepperStore';
import { childInformationStore } from '../../../../stores/childInformationStore';
import { preCheckInService } from '../../../../services/preCheckInService';

const CreditCardInfo = observer(() => {
  const [isUpdateCardInfo, setIsUpdateCardInfo] = useState(false);
  const { nextStep } = stepperStore;
  const { paymentMethodId } = childInformationStore;

  const finishHandler = async () => {
    await preCheckInService.attachPaymentMethod(paymentMethodId);
    nextStep();
  };

  return (
    <>
      <PersonalInfoBlock />
      <CreditCardComponent
        isUpdateCardInfo={isUpdateCardInfo}
        onSetIsUpdateCardInfo={setIsUpdateCardInfo}
      />
      <ButtonsRow
        yesText="Finish"
        isShowDecline={!paymentMethodId}
        yesDisabled={!paymentMethodId}
        yesHandler={finishHandler}
      />
    </>
  );
});

export { CreditCardInfo };
