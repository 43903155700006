import React from 'react';
import { Checkbox } from '@mui/material';

import classes from './TermsAcceptCheckbox.module.scss';
import { LbFormControlLabel } from '../../../../shared/components/form-components';
import { TermsLinks } from '../../../../shared/components/TermsLinks/TermsLinks';

type TermsAcceptCheckboxProps = {
  isTermsAccepted: boolean;
  setIsTermsAccepted: (value: boolean) => void;
};

const TermsAcceptCheckbox = ({
  isTermsAccepted,
  setIsTermsAccepted,
}: TermsAcceptCheckboxProps) => {
  return (
    <div className={classes.terms}>
      <LbFormControlLabel
        label={
          "By submitting the form, you agree to receive SMS and Email communications from LifeBase Solutions and it's affiliated entities and coaches"
        }
        control={
          <Checkbox
            checked={isTermsAccepted}
            onChange={(event, checked) => {
              setIsTermsAccepted(checked);
            }}
            color="primary"
          />
        }
      />
      <TermsLinks />
    </div>
  );
};

export { TermsAcceptCheckbox, TermsAcceptCheckboxProps };
