import { useEffect, useMemo, useState } from 'react';

import { ConsumptionInfo } from '../../../interfaces/consumption/ConsumptionInfo';
import { ConsumptionParams } from '../../../interfaces/consumption/ConsumptionParams';
import { complianceService } from '../../../services/complianceService';
import { defaultConsumptionInfo } from '../../../constants/defaultConsumptionInfo';
import { getFormattedDate } from '../../../../shared/utils/dateUtils';
import { localDataFormat } from '../../../../shared/constants/apiDateFormat';
import { getConsumptionDates } from '../utils/getConsumptionDates';

export const useConsumptionInfo = (customerId: number, scanDates: string[]) => {
  const [consumptionInfo, setConsumptionInfo] =
    useState<ConsumptionInfo>(defaultConsumptionInfo);

  const { consumptionEndDate, consumptionStartDate } = useMemo(
    () => getConsumptionDates(scanDates),
    [scanDates],
  );
  const isLessTwoScansExist = !consumptionEndDate || !consumptionStartDate;

  const formattedFrom = isLessTwoScansExist
    ? localDataFormat
    : getFormattedDate(consumptionStartDate, localDataFormat);

  const formattedTo = isLessTwoScansExist
    ? localDataFormat
    : getFormattedDate(consumptionEndDate, localDataFormat);

  const getConsumptionInfo = async () => {
    if (isLessTwoScansExist) {
      setConsumptionInfo(defaultConsumptionInfo);
      return;
    }

    const consumptionParams: ConsumptionParams = {
      startDate: consumptionStartDate,
      endDate: consumptionEndDate,
    };

    const info = await complianceService.getCustomerConsumptionInfo(
      customerId,
      'SCAN',
      consumptionParams,
    );

    setConsumptionInfo(info);
  };

  useEffect(() => {
    getConsumptionInfo();
  }, [customerId, isLessTwoScansExist]);

  return {
    consumptionInfo,
    formattedFrom,
    formattedTo,
    isLessTwoScansExist,
  };
};
