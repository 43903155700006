import React from 'react';
import PropTypes from 'prop-types';

import { IconButton } from '@mui/material';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

import classes from './InventoryBalanceCell.module.scss';

const InventoryBalanceCell = ({
  amountUnit,
  fullPackageSize,
  servingActual,
  openServingModal,
  hideAmountUnit,
}) => {
  const servingInfo = `${servingActual} / ${fullPackageSize}`;
  const servingText = `${servingInfo} ${hideAmountUnit ? '' : amountUnit}`;

  return (
    <div className={classes.serving}>
      <p>{servingText}</p>
      <IconButton aria-label="delete" onClick={openServingModal}>
        <EditRoundedIcon fontSize="medium" />
      </IconButton>
    </div>
  );
};

InventoryBalanceCell.propTypes = {
  amountUnit: PropTypes.string,
  fullPackageSize: PropTypes.number,
  servingActual: PropTypes.number,
  openServingModal: PropTypes.func,
  hideAmountUnit: PropTypes.bool,
};

export { InventoryBalanceCell };
