import React from 'react';

import { observer } from 'mobx-react';

import {
  ButtonRow,
  PersonalInfoForm,
  PersonalInfoFormFormValues,
  TermsAcceptCheckbox,
} from '../../../../components/customer-self-setup-components';
import { UseStepperReturn } from '../../../../../shared/hooks/useStepper';
import { personalInfoStore } from '../../../../stores/customerSelfSetupStores/personalInfoStore';
import { useScrollToTop } from '../../../../../shared/hooks/useScrollToTop';

type PersonalInfoStepProps = {
  stepperMethods: UseStepperReturn;
};

const personalInfoFormId = 'personalInfoForm';

const PersonalInfoStep = observer(({ stepperMethods }: PersonalInfoStepProps) => {
  useScrollToTop();

  const { personalInfo, isTermsAccepted, setIsTermsAccepted } = personalInfoStore;

  const handleFormSubmit = async (data: PersonalInfoFormFormValues) => {
    personalInfoStore.setPersonalInfo(data);

    stepperMethods.goToNextStep();
  };

  return (
    <div>
      <PersonalInfoForm
        formConfig={{ defaultValues: personalInfo }}
        onSubmit={handleFormSubmit}
        formId={personalInfoFormId}
      />
      <TermsAcceptCheckbox
        isTermsAccepted={isTermsAccepted}
        setIsTermsAccepted={setIsTermsAccepted}
      />
      <ButtonRow
        yesDisabled={!isTermsAccepted}
        noHandler={stepperMethods.goToPreviousStep}
        formId={personalInfoFormId}
      />
    </div>
  );
});

export { PersonalInfoStep };
