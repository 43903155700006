import { MomentFormatSpecification } from 'moment';
import { getSortedArrByDate } from '../getSortedArrByDate/getSortedArrByDate';

export const getSortedArrFromDateGroupedObj = (
  schedule: Record<any, any>,
  timeFormat: MomentFormatSpecification,
  formOldToNew?: boolean,
) => {
  const scheduleArr = Object.entries(schedule);

  return getSortedArrByDate(scheduleArr, 0, timeFormat, formOldToNew);
};
