import React from 'react';

import { observer } from 'mobx-react';
import { angularize } from 'react-in-angularjs';
import { Container } from '@mui/material';

import { SystemProviders } from '../../../../../shared/providers';
import { CorporateAccountsTable } from './CorporateAccountsTable/CorporateAccountsTable';
import { CorporateAccountsModal } from './CorporateAccountsModal/CorporateAccountsModal';
import { CorporateAccountsTabTitle } from './CorporateAccountsTabTitle/CorporateAccountsTabTitle';
import { corporateAccountsStore } from '../../../../stores/corporateAccountsStore';

const NgzCorporateAccountsControlTab = observer(() => {
  const { isModalOpened } = corporateAccountsStore;

  return (
    <SystemProviders>
      <Container>
        <CorporateAccountsTabTitle />
        <CorporateAccountsTable />
        {isModalOpened && <CorporateAccountsModal />}
      </Container>
    </SystemProviders>
  );
});

angularize(
  NgzCorporateAccountsControlTab,
  'ngzCorporateAccountsControlTab',
  angular.module('app'),
  {},
);

export { NgzCorporateAccountsControlTab };
