import React from 'react';
import PropTypes from 'prop-types';

import { Chip } from '@mui/material';

import classes from './FileRow.module.scss';

const FileRow = ({ uploadedTestFiles }) => {
  if (!uploadedTestFiles?.length) {
    return null;
  }

  return (
    <div className={classes.fileBox}>
      {uploadedTestFiles.map((file) => (
        <Chip
          variant="fileLink"
          component="a"
          href={file.fileUrl}
          label={file.originFileName}
          key={file.id}
        />
      ))}
    </div>
  );
};

FileRow.propTypes = {
  uploadedTestFiles: PropTypes.array,
};

export { FileRow };
