import { makeAutoObservable } from 'mobx';
import { StepperStore } from '../../shared/util-stores';
import { resetAllStore } from '../../shared/stores/resetAllStore';
import { EntityType } from '../../shared/constants/user-info/ENTITY_TYPES';

class SetupEntityStore {
  stepperMethods: StepperStore;
  entityId: number | null = null;
  entityType: EntityType | null = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    this.stepperMethods = new StepperStore();
  }

  setDefaultValues() {
    this.stepperMethods.setDefaultValues();
    this.entityId = null;
    this.entityType = null;
  }

  setEntityId(entityId: number) {
    this.entityId = entityId;
  }

  setEntityType(entityType: EntityType) {
    this.entityType = entityType;
  }
}

const setupEntityStore = new SetupEntityStore();

resetAllStore.addResetMethod(setupEntityStore.setDefaultValues);

export { setupEntityStore, SetupEntityStore };
