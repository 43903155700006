import { ValueOfKey } from '../../utils-types';

export const REPORT_SELECTORS_TYPES = {
  NO_SELECTOR: 'NO_SELECTOR',
  ENTITIES: 'ENTITIES',
  SALES_REPS: 'SALES_REPS',
  COACHES: 'COACHES',
} as const;

export type ReportSelectorsTypes = ValueOfKey<typeof REPORT_SELECTORS_TYPES>;
