import { Option } from '../../../general-types';

// deprecated not all workout options are included, use useActivitiesList to get all workout options
export const workoutOptions: Option[] = [
  { value: 'BAND_WORKOUTS', label: 'Band Workouts' },
  { value: 'BASEBALL', label: 'Baseball' },
  { value: 'BASKETBALL', label: 'Basketball' },
  { value: 'CIRCUIT_TRAINING', label: 'Circuit Training' },
  { value: 'CROSS_COUNTRY_SKIING', label: 'Cross Country Skiing' },
  { value: 'FOOTBALL', label: 'Football' },
  { value: 'GOLF', label: 'Golf' },
  { value: 'JOGGING', label: 'Jogging' },
  { value: 'KETTLE_BELLS', label: 'Kettle Bells' },
  { value: 'KICK_BOXING', label: 'Kick Boxing' },
  { value: 'LACROSSE', label: 'Lacrosse' },
  { value: 'P90X', label: 'P-90X or similar' },
  { value: 'PLYO_METRICS', label: 'Plyo-Metrics' },
  { value: 'VOLLEYBALL', label: 'Volleyball' },
  { value: 'CYCLING', label: 'Cycling' },
  { value: 'BODY_BUILDING', label: 'Isolation Weight Lifting' },
  { value: 'FIGURE_SKATING', label: 'Figure Skating' },
  { value: 'MMA', label: 'MMA training' },
  { value: 'RUNNING', label: 'Sprinting' },
  { value: 'WALKING', label: 'Walking' },
  { value: 'WRESTLING', label: 'Wrestling' },
  { value: 'BOOT_CAMPS', label: 'Boot Camps' },
  { value: 'HAND_BALL', label: 'Hand Ball' },
  { value: 'PILATES', label: 'Pilates' },
  { value: 'SWIMMING', label: 'Swimming' },
  { value: 'YOGA', label: 'Yoga' },
  { value: 'BOXING', label: 'Boxing' },
  { value: 'HIIT', label: 'HIIT Training' },
  { value: 'POWER_LIFTING', label: 'Power Lifting' },
  { value: 'IN_LINE_SKATING', label: 'Track' },
  { value: 'CROSSFIT', label: 'Crossfit Training' },
  { value: 'HOCKEY', label: 'Hockey' },
  { value: 'RACKET_BALL', label: 'Racquet Ball' },
  { value: 'TENNIS', label: 'Tennis' },
];
