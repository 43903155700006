import React from 'react';

import { Button, Link, SvgIcon } from '@mui/material';

import classes from './ConnectStripeWidget.module.scss';
import { InfoBox, LbChip } from '../../../shared/components/ui-components';
import { ReactComponent as StripeIcon } from '../../../shared/assets/icons/stripe.svg';
import { stripeConnectInstructionLink } from '../../constants/externalLinks';
import { stripeService } from '../../services/stripeService';
import { redirectTo } from '../../../shared/utils/windowUtils';
import { StripeInfoChip } from '../../components/StripeInfoChip/StripeInfoChip';

type ConnectStripeWidgetProps = {
  entityId: number;
  isStripeConnected: boolean;
};

const ConnectStripeWidget = ({
  entityId,
  isStripeConnected,
}: ConnectStripeWidgetProps) => {
  const handleConnectStripe = async () => {
    const { stripeUrl } = await stripeService.getStripeConnectLinkByEntity(entityId);

    redirectTo(stripeUrl);
  };

  const CompleteSetupChip = <LbChip label="Complete setup" size="small" color="error" />;

  return (
    <InfoBox
      title="Connect Payment with Stripe"
      icon={!isStripeConnected && CompleteSetupChip}
    >
      <div>
        <p className={classes.description}>
          Use Stripe not only to accept payments but also to quickly support new markets,
          upgrade existing systems and tools, go direct-to-consumer, and engage customers
          with subscriptions and marketplaces.
        </p>
        <div className={classes.stripeBlock}>
          {isStripeConnected ? (
            <StripeInfoChip isStripeConnected={isStripeConnected} />
          ) : (
            <Button
              variant="outlined"
              size="small"
              startIcon={<SvgIcon component={StripeIcon} inheritViewBox />}
              onClick={handleConnectStripe}
            >
              Create or Connect a Stripe Account
            </Button>
          )}
        </div>
        <p className={classes.linkTitle}>Need assistance with your Stripe account?</p>
        <Link href={stripeConnectInstructionLink} target="_blank">
          Getting started with Stripe: create or connect an account
        </Link>
      </div>
    </InfoBox>
  );
};

export { ConnectStripeWidget };
