import { makeAutoObservable } from 'mobx';
import { resetAllStore } from '../../shared/stores/resetAllStore';
import { corporateAccountsService } from '../services/corporateAccountsService';
import { deleteItemByField, updateItemByField } from '../../shared/utils/arrayUtils';

class CorporateAccountsStore {
  constructor() {
    this.setDefaultValues();
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.isModalOpened = false;
    this.formDefaultValues = null;
    this.currentModalAccountId = null;
    this.corporateAccounts = [];
  }

  async getCorporateAccounts() {
    const response = await corporateAccountsService.getCorporateAccounts();
    this.setAccounts(response.accounts);
  }

  async deleteAccount(id) {
    await corporateAccountsService.deleteCorporateAccount(id);

    const accountsWithoutDeleted = deleteItemByField(this.corporateAccounts, { id });
    this.setAccounts(accountsWithoutDeleted);
  }

  async addNewAccount(data) {
    const newAccount = await corporateAccountsService.createCorporateAccount(data);
    this.setAccounts([...this.corporateAccounts, newAccount]);
  }

  async editCorporateAccount(data, id) {
    const editedAccount = await corporateAccountsService.editCorporateAccount(data, id);
    const accounts = updateItemByField(this.corporateAccounts, editedAccount, { id });

    this.setAccounts(accounts);
  }

  setAccounts(accounts) {
    this.corporateAccounts = accounts;
  }

  closeModal() {
    this.isModalOpened = false;
    this.formDefaultValues = null;
    this.currentModalAccountId = null;
  }

  openModal(formDefaultValues, id) {
    const isEditAccountModal = formDefaultValues && id;
    if (isEditAccountModal) {
      this.setFormDefaultValues(formDefaultValues);
      this.setCurrentModalAccountId(id);
    }
    this.setIsModalOpened(true);
  }

  setIsModalOpened(value) {
    this.isModalOpened = value;
  }

  setCurrentModalAccountId(id) {
    this.currentModalAccountId = id;
  }

  setFormDefaultValues(data) {
    this.formDefaultValues = data;
  }
}

const corporateAccountsStore = new CorporateAccountsStore();

resetAllStore.addResetMethod(corporateAccountsStore.setDefaultValues);

export { corporateAccountsStore, CorporateAccountsStore };
