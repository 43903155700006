import React from 'react';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { InfoBox } from '../../../shared/components/ui-components';
import { ScoreLegend } from './ScoreLegend/ScoreLegend';
import { chartOption } from './AvgPerformanceScoreChart.settings';
import { usePerformanceScoreChart } from './hooks/usePerformanceScoreChart';

ChartJS.register(CategoryScale, LinearScale, BarElement);

const AvgPerformanceScoreChart = () => {
  const chartData = usePerformanceScoreChart();

  return (
    <InfoBox title="Avg Performance Score" icon={<ScoreLegend />} customMargin>
      <Bar data={chartData} plugins={[ChartDataLabels]} options={chartOption} />
    </InfoBox>
  );
};

export { AvgPerformanceScoreChart };
