import { makeAutoObservable } from 'mobx';
import { resetAllStore } from '../../shared/stores/resetAllStore';
import { CurrentSubscriptionInfo } from '../interfaces/customerSubscription';
import { customerSubscriptionService } from '../services/customerSubscriptionService';
import {
  SUBSCRIPTION,
  SubscriptionType,
  SubscriptionTypeFlags,
} from '../../shared/constants/user-info/subscription';
import { getFlagsObject } from '../../shared/utils/sortingAndGroupingUtil';
import {
  PAYMENT_STATUS,
  PaymentStatus,
  PaymentStatusFlags,
} from '../constants/paymentStatus';

class CurrentSubscriptionPlanStore {
  customerId: number | null = null;
  currentSubscriptionPlan: CurrentSubscriptionInfo | null = null;
  subscriptionTypeFlags: SubscriptionTypeFlags = {};
  paymentStatusFlag: PaymentStatusFlags = {};

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.customerId = null;
    this.currentSubscriptionPlan = null;
    this.subscriptionTypeFlags = {};
    this.paymentStatusFlag = {};
  }

  async getPaymentDetails(customerId: number) {
    const subscriptionPlan =
      await customerSubscriptionService.getCurrentSubscriptionInfo(customerId);

    this.setCustomerId(customerId);
    this.setCurrentSubscriptionPlan(subscriptionPlan);
    this.setPaymentStatusFlag(subscriptionPlan.lastTransaction.status);

    if (subscriptionPlan.subscription) {
      this.setSubscriptionTypeFlags(subscriptionPlan.subscription.subscriptionType);
    }
  }

  async setupExplorePlan(subscriptionId: number) {
    if (!this.customerId) {
      throw new Error('setupExplorePlan miss necessary data');
    }

    await customerSubscriptionService.setupExplorePlan(this.customerId, subscriptionId);

    this.getPaymentDetails(this.customerId);
  }

  async cancelSubscription() {
    if (!this.currentSubscriptionPlan?.lastTransaction || !this.customerId) {
      throw new Error('cancelSubscription miss necessary data');
    }

    await customerSubscriptionService.cancelSubscription(
      this.currentSubscriptionPlan.lastTransaction.subscriptionTransactionId,
    );

    this.getPaymentDetails(this.customerId);
  }

  async cancelSetupExplorePlan() {
    if (!this.customerId) {
      throw new Error('cancelSetupExplorePlan miss necessary data');
    }

    await customerSubscriptionService.cancelSetupExplorePlan(this.customerId);

    this.getPaymentDetails(this.customerId);
  }

  async retryPayment() {
    if (!this.customerId) {
      throw new Error('retryPayment miss necessary data');
    }

    await customerSubscriptionService.retryPayment(this.customerId);
    this.getPaymentDetails(this.customerId);
  }

  private setCustomerId(customerId: number) {
    this.customerId = customerId;
  }

  private setCurrentSubscriptionPlan(currentPlan: CurrentSubscriptionInfo) {
    this.currentSubscriptionPlan = currentPlan;
  }

  private setSubscriptionTypeFlags(subscriptionType: SubscriptionType) {
    const subscriptionTypeFlags = getFlagsObject(SUBSCRIPTION, [subscriptionType]);

    this.subscriptionTypeFlags = subscriptionTypeFlags;
  }

  private setPaymentStatusFlag(paymentStatus: PaymentStatus) {
    this.paymentStatusFlag = getFlagsObject(PAYMENT_STATUS, [paymentStatus]);
  }
}

const currentSubscriptionPlanStore = new CurrentSubscriptionPlanStore();
resetAllStore.addResetMethod(currentSubscriptionPlanStore.setDefaultValues);

export { currentSubscriptionPlanStore, CurrentSubscriptionPlanStore };
