import { apiService } from '../../shared/services/apiService';
import {
  AddPromoCodeRequest,
  PromoCodeInfo,
  PromoCodeSubscription,
} from '../interfaces/promoCodeInfo';

export const promoCodesService = {
  async getPromoCodes(entityId: number) {
    const response = await apiService.get<PromoCodeInfo[]>(
      `/api/entity/${entityId}/promocodes`,
    );

    return response.data;
  },

  async getSubscriptions(entityId: number) {
    const response = await apiService.get<PromoCodeSubscription[]>(
      `/api/entity/${entityId}/promocodes/subscriptions`,
    );

    return response.data;
  },

  async addPromoCode(
    entityId: number,
    promoCodeRequest: AddPromoCodeRequest,
    isRunGlobalSpinner = false,
  ) {
    const response = await apiService.post<PromoCodeInfo>(
      `/api/entity/${entityId}/promocode`,
      promoCodeRequest,
      { isRunGlobalSpinner },
    );

    return response.data;
  },

  async togglePromoCode(entityId: number, promoCodeId: number, pause: boolean) {
    const response = await apiService.put<PromoCodeInfo>(
      `/api/entity/${entityId}/promocode/${promoCodeId}/pause`,
      undefined,
      { params: { pause } },
    );

    return response.data;
  },
};
