import { paidModuleTypes } from '../../../../../shared/constants/paidModuleTypes';

export const prepDataToSubmit = (date) => {
  const userData = JSON.parse(JSON.stringify(date));

  userData.customer.paymentModules = [
    { paidServiceType: paidModuleTypes.ACTIVE_CALENDAR },
  ];

  return userData;
};
