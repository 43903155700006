const autoNgTemplateLoaderTemplate1 = require('./customer-overview-tab.template.html');

'use strict';

angular
    .module('app')
    .directive('customerOverviewTab', customerOverviewTab);

customerOverviewTab.$inject = [];

function customerOverviewTab() {
    return {
        restrict: 'E',
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: 'CustomerOverviewTabController',
        controllerAs: 'vm',
    };
}
