import { useEffect, useState } from 'react';

import { EntityType } from '../../../../shared/constants/user-info/ENTITY_TYPES';
import { FeeFormModule, FeeModuleSelectFormValues } from '../FeeModuleWidget.settings';
import { paymentBundleService } from '../../../services/paymentBundleService';
import { getCreatedPaymentBundleInfo } from '../utils/getCreatedPaymentBundleInfo/getCreatedPaymentBundleInfo';
import { processFeeScheduleToFeeFormModules } from '../utils/processFeeScheduleToFeeFormModules/processFeeScheduleToFeeFormModules';
import { processFeeScheduleToFeeModuleValues } from '../utils/processFeeScheduleToFeeModuleValues/processFeeScheduleToFeeModuleValues';

/*
It tries to fetch entity payment bundle, if it fails with 404 error code, 
it means that payment bundle is not created yet, and can be created.

DefaultPaymentBundle used like a structure and entityPaymentBundle (if exist) used like a value.
Can not use entityPaymentBundle as a structure (if exist), because it not contain disabled info.

The paid module use different endpoints for creation and editing; 
With the current implementation, it is difficult to edit payment modules after creation 
(it is planned to add this functionality in the next iteration).
*/
export const useFeeFormModules = (entityType: EntityType, entityId: number) => {
  const [feeFormModules, setFeeFormModules] = useState<FeeFormModule[]>([]);
  const [feeModuleValues, setFeeModuleValues] = useState<
    FeeModuleSelectFormValues | undefined
  >(undefined);
  const [isCreated, setIsCreated] = useState<boolean | null>(null);

  const getDefaultPaymentBundle = async () => {
    const { feeSchedule } = await paymentBundleService.getDefaultPaymentBundle(
      entityType,
      'NEW_USER_FLOW_2024',
    );

    setFeeFormModules(processFeeScheduleToFeeFormModules(feeSchedule));
  };

  const getEntityPaymentBundle = async () => {
    try {
      const { feeSchedule } = await paymentBundleService.getEntityPaymentBundle(
        entityId,
        { ignoreErrorCodes: [404] },
      );

      setFeeModuleValues(processFeeScheduleToFeeModuleValues(feeSchedule));
      setIsCreated(true);
    } catch (error: any) {
      if (error.response?.status !== 404) {
        throw error;
      }
      setIsCreated(false);
    }
  };

  const createPaymentBundle = async (
    feeModules: FeeModuleSelectFormValues['feeModules'],
  ) => {
    const createdPaymentBundleInfo = getCreatedPaymentBundleInfo(feeModules);
    await paymentBundleService.createPaymentBundle(entityId, createdPaymentBundleInfo);

    setIsCreated(true);
  };

  useEffect(() => {
    getDefaultPaymentBundle();
    getEntityPaymentBundle();
  }, []);

  return { feeFormModules, feeModuleValues, isCreated, createPaymentBundle };
};
