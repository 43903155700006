import React from 'react';

import { angularize } from 'react-in-angularjs';
import { Container, Paper } from '@mui/material';
import moment from 'moment';

import classes from './NgzCustomerConsumptionBetweenScan.module.scss';
import { SystemProviders } from '../../../../../shared/providers';
import { ConsumptionBetweenTable } from '../../../../components/consumption/ConsumptionBetweenTable/ConsumptionBetweenTable';
import { DateRangePicker } from '../../../../../shared/components/form-components';
import { getRouteParam } from '../../../../../shared/utils/angularUtils';
import { useConsumptionBetweenScan } from './hooks/useConsumptionBetweenScan';

const NgzCustomerConsumptionBetweenScan = () => {
  const customerId = getRouteParam('id');

  const { consumptionInfo, customerScansInfo, formMethods } =
    useConsumptionBetweenScan(customerId);
  const { control } = formMethods;
  const { firstScanDay, lastScanDay, customerScanDays } = customerScansInfo;

  const datePikerValidationProps = {
    availableDates: customerScanDays,
    minDate: moment(firstScanDay),
    maxDate: moment(lastScanDay),
    rules: { required: 'Scan date is required' },
  };

  const isLessTwoScansExist = customerScanDays.length < 2;

  return (
    <SystemProviders>
      <Container className={classes.container}>
        <Paper variant="roundedBox">
          <div className={classes.title}>
            <h5>Consumption Between Scans</h5>
            <form noValidate>
              <DateRangePicker
                control={control}
                startPickerProps={{
                  name: 'startDate',
                  ...datePikerValidationProps,
                }}
                endPickerProps={{
                  name: 'endDate',
                  ...datePikerValidationProps,
                }}
                size="small"
                variant="outlined"
              />
            </form>
          </div>
          <ConsumptionBetweenTable
            goal={consumptionInfo.goal}
            actual={consumptionInfo.actual}
          />
        </Paper>
        {isLessTwoScansExist && (
          <div className={classes.infoBox}>
            <p>User should have at least 2 scans to compare the data</p>
          </div>
        )}
      </Container>
    </SystemProviders>
  );
};

angularize(
  NgzCustomerConsumptionBetweenScan,
  'ngzCustomerConsumptionBetweenScan',
  angular.module('app'),
  {},
);

export { NgzCustomerConsumptionBetweenScan };
