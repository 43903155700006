import { Option } from '../../general-types';
import { ValueOfKey } from '../../utils-types';

export const ENTITY_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  INCOMPLETE: 'INCOMPLETE',
} as const;

export type EntityStatus = ValueOfKey<typeof ENTITY_STATUS>;

export const entityStatusOption: Option<EntityStatus | ''>[] = [
  { label: 'All', value: '' },
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Inactive', value: 'INACTIVE' },
  { label: 'Incomplete', value: 'INCOMPLETE' },
];
