import React from 'react';

import classNames from 'classnames';

import classes from './SeparatorLine.module.scss';

type SeparatorLineProps = {
  className?: string;
};

const SeparatorLine = ({ className }: SeparatorLineProps) => {
  const separatorClasses = classNames(classes.separatorLine, className);

  return <div className={separatorClasses} />;
};

export { SeparatorLine };
