import { invert } from 'lodash';

import { apiService } from '../../shared/services/apiService';
import { renameFields } from '../../shared/utils/sortingAndGroupingUtil';
import {
  DefaultPrePostWorkoutMealInfo,
  CreateWorkoutMealPlan,
  MealPlansMacros,
} from '../interfaces/MealPlan';
import { NUTRIENTS_ENUM } from '../../shared/constants/NUTRIENTS_ENUM';
import { REGULAR_MEAL_NAME } from '../../shared/constants/mealNames';

const fixFieldsNameConfig = {
  // backend field name: frontend field name
  crb: NUTRIENTS_ENUM.CARBS,
  fat: NUTRIENTS_ENUM.FATS,
  prt: NUTRIENTS_ENUM.PROTEINS,
  breakFast: REGULAR_MEAL_NAME.breakfast,
} as const;

// Replace to separate module nutrition or meal-plan
export const mealPlanService = {
  async getDefaultPrePostWorkoutMeals() {
    const resp = await apiService.get<DefaultPrePostWorkoutMealInfo>(
      `api/coach/meal-plan/pre-post-workout`,
    );

    return resp.data;
  },

  async createWorkoutMealPlan(
    customerId: number,
    workoutMealPlan: CreateWorkoutMealPlan,
  ) {
    const workoutMealPlanData = renameFields(
      workoutMealPlan,
      invert(fixFieldsNameConfig),
    );

    const resp = await apiService.post(
      `api/coach/user/${customerId}/pre-post-workout`,
      workoutMealPlanData,
    );

    return renameFields(resp.data, fixFieldsNameConfig) as MealPlansMacros;
  },

  async getMealPlansMacros(customerId: number) {
    const resp = await apiService.get(`api/coach/user/${customerId}/mealplan`);

    return renameFields(resp.data, fixFieldsNameConfig) as MealPlansMacros;
  },

  async updateMealPlansMacros(customerId: number, mealPlansMacros: MealPlansMacros) {
    const mealPlansMacrosData = renameFields(
      mealPlansMacros,
      invert(fixFieldsNameConfig),
    );

    const resp = await apiService.put(
      `/api/coach/user/${customerId}/mealplan`,
      mealPlansMacrosData,
    );

    return renameFields(resp.data, fixFieldsNameConfig) as MealPlansMacros;
  },
};
