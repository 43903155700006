import React from 'react';

import classes from './InbodyDataFieldGroup.module.scss';
import {
  InbodyDataGroup,
  inbodyDataConfig,
} from '../../../../../shared/constants/inbody-info/inbodyData';
import { InbodyDataCheckbox } from './InbodyDataCheckbox/InbodyDataCheckbox';

type InbodyDataFieldGroupProps = {
  group: InbodyDataGroup;
};

const InbodyDataFieldGroup = ({ group }: InbodyDataFieldGroupProps) => {
  const groupInbodyData = Object.values(inbodyDataConfig).filter(
    (fieldInfo) => fieldInfo.group === group,
  );

  return (
    <div className={classes.box}>
      {groupInbodyData.map((fieldInfo) => (
        <InbodyDataCheckbox key={fieldInfo.dbName} fieldInfo={fieldInfo} />
      ))}
    </div>
  );
};

export { InbodyDataFieldGroup };
