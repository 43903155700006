import React from 'react';

import classes from './MembersTable.module.scss';
import { FreeAccessMember } from '../../../../../interfaces/freeAccess';
import { EmptyList } from '../../../../../../shared/components/ui-components';
import { MemberRow } from './MemberRow/MemberRow';

type MembersTableProps = {
  members: FreeAccessMember[];
  onDelete: (memberId: number) => void;
};

const MembersTable = ({ members, onDelete }: MembersTableProps) => {
  return (
    <>
      <table className={classes.table}>
        <thead>
          <tr>
            <th>Email</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {!!members.length &&
            members.map((member) => (
              <MemberRow key={member.id} member={member} onDelete={onDelete} />
            ))}
        </tbody>
      </table>
      <div className={classes.emptyContainer}>
        <EmptyList show={!members.length} description="No Users with Free Access" />
      </div>
    </>
  );
};

export { MembersTable };
