import { apiService } from '../../shared/services/apiService';
import { loggedInUserDataStore } from '../../shared/stores/loggedInUserDataStore';

const { getEntityId } = loggedInUserDataStore;

const getTests = async () => {
  const resp = await apiService(`api/medical_tests/entity/${getEntityId()}`);
  return resp.data;
};

const createTest = async (testName) => {
  const resp = await apiService.post('api/medical_tests/entity', {
    testName,
    entityId: getEntityId(),
  });
  return resp.data;
};

const editTest = async (test, testName) => {
  const resp = await apiService.post('api/medical_tests/entity', {
    ...test,
    testName,
  });
  return resp.data;
};

const deactivateTest = async (testId) => {
  const resp = await apiService.put(
    `api/medical_tests/entity/${getEntityId()}/test/${testId}/activation?active=false`,
  );
  return resp.data;
};

const activateTest = async (testId) => {
  const resp = await apiService.put(
    `api/medical_tests/entity/${getEntityId()}/test/${testId}/activation?active=true`,
  );
  return resp.data;
};

export const medicalTestsService = {
  getTests,
  createTest,
  editTest,
  deactivateTest,
  activateTest,
};
