'use strict';

import { getLoginRequiredResolveObj } from '../shared/utils/angularUtils';

export const activeCalendarModule = angular
  .module('app.active-calendar', [])
  .config(activeCalendarConfig);

activeCalendarConfig.$inject = ['$routeProvider'];

function activeCalendarConfig($routeProvider) {
  $routeProvider
    .when('/active-calendar/registration', {
      template: `<ngz-registration></ngz-registration>`,
      resolve: getLoginRequiredResolveObj(),
    })
    .when('/active-calendar/users', {
      template: `<ngz-active-calendar-users></ngz-active-calendar-users>`,
      resolve: getLoginRequiredResolveObj(),
    });
}
