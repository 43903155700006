import { apiService } from '../../shared/services/apiService';
import { GoalInfo, CreateGoalInfo } from '../interfaces/goal';
import { getProcessedCreateGoalInfo, getProcessedGoalInfo } from '../utils/goalsUtils';

export const goalService = {
  async createGoal(
    customerId: number,
    goalInfo: CreateGoalInfo,
    allowToCreateWithoutScans?: boolean,
  ) {
    const processedGoalInfo = getProcessedCreateGoalInfo(goalInfo);

    const resp = await apiService.post<GoalInfo>(
      `api/coach/${customerId}/goals`,
      processedGoalInfo,
      { params: { allowToCreateWithoutScans } },
    );

    return getProcessedGoalInfo(resp.data);
  },
};
