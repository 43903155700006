'use strict';

angular.module('app').factory('CustomerGroupsService', CustomerGroupsService);

CustomerGroupsService.$inject = ['ApiService'];

function CustomerGroupsService(ApiService) {
    return {
        createCustomerGroup: function (data) {
            return ApiService.post('api/coach/groups/create', data).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        assignCustomersToCustomerGroup: function (groupId, data) {
            return ApiService.put('api/coach/groups/' + groupId + '/assign', data).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        }
    };
}
