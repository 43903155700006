import { getHumanizeDayByCount } from '../../../../shared/utils/dateUtils';

export const getDaysToTestTitle = (daysToTest) =>
  getHumanizeDayByCount(daysToTest, getDaysToTestHandler);

export const getDaysToTestHandler = (daysToTest) => {
  if (daysToTest > 0) {
    return `${daysToTest} Days Left`;
  } else if (daysToTest < 0) {
    const absoluteDaysNumber = Math.abs(daysToTest);
    return `${absoluteDaysNumber} Days Overdue`;
  }

  return 'No Tests';
};
