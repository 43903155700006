import React from 'react';

import { observer } from 'mobx-react';
import classes from './TimerBlock.module.scss';
import { Timer } from './Timer/Timer';
import { checkoutStore } from '../../../../stores/checkoutStore';

const TimerBlock = observer(() => {
  const { checkoutInfo } = checkoutStore;

  if (!checkoutInfo) {
    return null;
  }

  return (
    <div className={classes.container}>
      <p className={classes.title}>Complete payment before the specified time expires</p>
      <Timer expirationEpoch={checkoutInfo.checkoutDetails.expirationEpoch} />
    </div>
  );
});

export { TimerBlock };
