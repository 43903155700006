// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../assets/images/vectors/login_picture_dark.svg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<treasure-overlay-spinner active=\"vm.spinner.active\"> <div class=\"sign-in-auth0-form-wrapper\" layout=\"row\" layout-align=\"space-between center\"> <form name=\"form\" class=\"sign-in-auth0-form\" novalidate ng-submit=\"vm.signIn(form)\"> <div class=\"sign-in-auth0-form__title\">Sanford sign in</div> <div ng-if=\"vm.mfaLogin\" class=\"mfa-explanation\"> <p>A one-time MFA (two-factor authentication) security code has been emailed to you at the email address connected to your account.</p> <p>Please enter this six (6) digit code and click the submit button.</p> </div> <md-button class=\"sign-in-auth0-form__submit\" type=\"button\" ng-click=\"vm.singInByAuthO()\">Login with Auth0</md-button> <div ng-if=\"vm.mfaLogin\" layout=\"column\"> <md-input-container class=\"sign-in-auth0-form__password full-input\"> <label>Code</label> <input name=\"code\" ng-model=\"vm.password\" required> <div ng-messages=\"form.code.$error\"> <p ng-message=\"required\">Code is required</p> </div> </md-input-container> <a class=\"sign-in-auth0-form__link\" ng-click=\"vm.back()\">Return to previous screen</a> <md-button class=\"sign-in-auth0-form__submit\" type=\"submit\">Submit</md-button> </div> </form> <img class=\"sign-in-auth0-image\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"> </div> </treasure-overlay-spinner> ";
// Exports
var _module_exports = code;;
var path = 'app/components/sign-in-auth0/sign-in-auth0.template.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;