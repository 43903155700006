import { Option } from '../../../general-types';
import { Flags, ValueOfKey } from '../../../utils-types';

// use getHumanizeEntityType utils to get showed name

export const ENTITY_TYPES = {
  GYM: 'GYM',
  TRAINER: 'TRAINER',
  CHIROPRACTOR: 'CHIROPRACTOR',
  CORPORATE_ACCOUNT: 'CORPORATE_ACCOUNT',
  UNIVERSITY: 'UNIVERSITY',
  SPORTS_ORGANIZATION: 'SPORTS_ORGANIZATION',
  NUTRITION_STORE: 'NUTRITION_STORE',
  SCHOOL: 'SCHOOL',
  TEAM: 'TEAM',
  SCHOLASTIC: 'SCHOLASTIC',
  OTHER: 'OTHER',
} as const;

export type EntityType = ValueOfKey<typeof ENTITY_TYPES>;
export type EntityTypeKey = keyof typeof ENTITY_TYPES; // use for flags only
export type EntityTypeFlags = Flags<EntityTypeKey>;

export const entityTypeOptions: Option<EntityType>[] = [
  { label: 'Gym', value: 'GYM' },
  { label: 'Trainer', value: 'TRAINER' },
  { label: 'Clinician', value: 'CHIROPRACTOR' },
  { label: 'Corporate Account', value: 'CORPORATE_ACCOUNT' },
  { label: 'University', value: 'UNIVERSITY' },
  { label: 'Sports Organization', value: 'SPORTS_ORGANIZATION' },
  { label: 'Nutrition Store', value: 'NUTRITION_STORE' },
  { label: 'School', value: 'SCHOOL' },
  { label: 'Team', value: 'TEAM' },
  { label: 'Scholastic', value: 'SCHOLASTIC' },
  { label: 'Other', value: 'OTHER' },
];

export const entityTypeOptionsWithAll: Option<EntityType | ''>[] = [
  { label: 'All', value: '' },
  ...entityTypeOptions,
];

export const humanizeEntityType: Record<EntityType, string> = {
  GYM: 'Gym',
  TRAINER: 'Trainer',
  CHIROPRACTOR: 'Clinician',
  CORPORATE_ACCOUNT: 'Corporate Account',
  UNIVERSITY: 'University',
  SPORTS_ORGANIZATION: 'Sports Organization',
  NUTRITION_STORE: 'Nutrition Store',
  SCHOOL: 'School',
  TEAM: 'Team',
  SCHOLASTIC: 'Scholastic',
  OTHER: 'Other',
};
