import React from 'react';
import PropTypes from 'prop-types';

import classes from './GoalFromRow.module.scss';
import { getFormattedDate } from '../../../../../../../shared/utils/dateUtils';

const GoalFromRow = ({ targetGoal, startDate }) => {
  const formatDate = getFormattedDate(startDate, 'MMM DD YYYY');

  return (
    <div className={classes.row}>
      <p>Goal - {targetGoal} oz/day</p>
      <p>(from {formatDate})</p>
    </div>
  );
};

GoalFromRow.propTypes = {
  targetGoal: PropTypes.number,
  startDate: PropTypes.string,
};

export { GoalFromRow };
