import React from 'react';
import PropTypes from 'prop-types';

import { Paper } from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

import classes from './DropzoneOpenCard.module.scss';
import { DashedBorder } from '../../../../../../../shared/components/ui-components/DashedBorder/DashedBorder';

const DropzoneOpenCard = ({ onClick, getDropzoneInputProps }) => {
  return (
    <Paper
      onClick={onClick}
      className={classes.dropzoneOpenCard}
      data-test-id="dropzoneOpenCard"
    >
      <input {...getDropzoneInputProps()} />
      <DashedBorder />
      <div className={classes.textContainer}>
        <FileUploadOutlinedIcon
          className={classes.uploadIcon}
          color="primary"
          fontSize="extraLarge"
        />
        <p>Upload New Insurance</p>
        <h6>Click on this card and choose certificate file on your desktop</h6>
      </div>
    </Paper>
  );
};

DropzoneOpenCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  getDropzoneInputProps: PropTypes.func.isRequired,
};

export { DropzoneOpenCard };
