'use strict';

angular.module('app.entityDashboard').controller('EntityDashboardController', EntityDashboardController);

EntityDashboardController.$inject = ['utilService', 'ModalService', 'EntityService', 'StatsService', 'PosService'];

function EntityDashboardController(utilService, ModalService, EntityService, StatsService, PosService) {
    var vm = this;
    vm.loggedInUser = utilService.getLoggedInUserData();
    vm.paymentFlowType = utilService.getPaymentFlowType();
    vm.spinner = {active: false};
    vm.entityDetails = {};
    vm.currentEntity = null;
    vm.posStatus = localStorage.getItem('posStatus');
    vm.posErrors = JSON.parse(localStorage.getItem('posErrors'));
    vm.posProgress = 0;
    vm.isPOSVisible = false;

    vm.getCurrentEntity = getCurrentEntity;
    vm.getEntityDetails = getEntityDetails;
    vm.disableStripeConnectPlatformAgreement = disableStripeConnectPlatformAgreement;
    vm.syncToPOSManually = syncToPOSManually;
    vm.connectManually = connectManually;
    vm.getSyncStatus = getSyncStatus;

    getCurrentEntity();

    function getCurrentEntity() {
        EntityService.getEntitiesOfAdmin().then(function (res) {
            if (res.status === 200) {
                vm.currentEntity = res.data.entities[0];
                vm.getEntityDetails(vm.currentEntity.id);
            }
        });
    }

    function getEntityDetails(id) {
        StatsService.getEntityDetailsById(id).then(function (res) {
            if (res.status === 200) {
                res.data.data.push(res.data.total);
                vm.entityDetails = res.data.data;
                vm.getSyncStatus();
            }
        });
    }

    function disableStripeConnectPlatformAgreement() {
        if (vm.currentEntity.accepted_stripe_terms) {
            vm.spinner.active = true;
            EntityService.acceptStripeTerms(vm.currentEntity.id).then(function (res) {
                vm.spinner.active = false;
                if (res.status === 200) {
                    ModalService.alert('You successfully accepted Stripe terms and conditions for entity ' + vm.loggedInUser.firstName, 'Stripe Terms and conditions');
                }
            });
        } else {
            vm.currentEntity.accepted_stripe_terms = !vm.currentEntity.accepted_stripe_terms;
        }
    }

    function syncToPOSManually() {
        if (vm.posStatus === 'FAILED') {
            ModalService.posErrors(vm);
        } else if (vm.posStatus !== 'IN_PROGRESS') {
            vm.connectManually();
        }
    }

    function connectManually() {
        PosService.connectManually().then(function (res) {
            if (res.status === 200) {
                vm.getSyncStatus();
            }
        });
    }

    function getSyncStatus() {
        PosService.getSyncStatus().then(function (res) {
            vm.isPOSVisible = res.status === 200;
            if (vm.isPOSVisible) {
                vm.posStatus = res.data.status;
                vm.lastSyncDate = res.data.lastSyncDate;
                vm.posErrors = res.data.errors[0];

                if (vm.posStatus === 'IN_PROGRESS') {
                    vm.posProgress = res.data.processedUsersPercentage;
                    setTimeout(vm.getSyncStatus, 10000);
                }
            }
        });
    }
}
