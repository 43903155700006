'use strict';

angular
    .module('app')
    .controller('LocationMetricsController', LocationMetricsController);

LocationMetricsController.$inject = ['ChartService', 'StatsService', 'EntityService'];

function LocationMetricsController(ChartService, StatsService, EntityService) {
    var vm = this;
    vm.spinner = {active: true};
    vm.chartLimit = 5;
    vm.metricsTabName = 'fat';
    vm.gymStatistics = [];
    vm.availableEntities = [];
    vm.selectedEntity = {id: null, business_name: 'LifeBase Solutions'};
    vm.metricsParams = { entityId: null, definition: 'month' };
    vm.blueLineChart = null;

    vm.initCharts = initCharts;
    vm.createLocationMetricsChart = createLocationMetricsChart;
    vm.processedGymStatistics = processedGymStatistics;
    vm.decrementValue = decrementValue;
    vm.incrementValue = incrementValue;
    vm.getAllEntities = getAllEntities;

    initCharts(null, 'month', 'fat');
    getAllEntities();

    function initCharts(entity, definition, metrics) {
        vm.metricsTabName = metrics;
        vm.metricsParams = {
            entityId: entity,
            definition: definition
        };
        vm.spinner.active = true;

        StatsService.getLocationMetrics(metrics, vm.metricsParams).then(function (result) {
            if (result.status === 200) {
                vm.gymStatistics = result.data.data.length > 0 ? result.data.data.map(function (item) {
                    return {
                        range: item.range,
                        value: metrics === 'fat' ? -item.value : item.value
                    }
                }) : [];

                var size = vm.gymStatistics.length;
                if (vm.chartLimit > size) vm.chartLimit = size;
                vm.activeIndex = size - 1;
                vm.firstIndex = size - vm.chartLimit;

                vm.locationMetricsTotal = metrics === 'fat' ? -result.data.total : result.data.total;
                vm.createLocationMetricsChart();
            }
        });
    }

    function processedGymStatistics() {
        var gymValues = [];
        vm.locationMetricsLabels = [];

        for (var i = vm.firstIndex; i < vm.firstIndex + vm.chartLimit; i++) {
            gymValues.push(vm.gymStatistics[i].value);
            vm.locationMetricsLabels.push({
                index: i,
                date: vm.gymStatistics[i].range
            });
        }

        return gymValues;
    }

    function createLocationMetricsChart() {
        var gymValues = vm.processedGymStatistics();
        vm.blueLineChart = ChartService.createBlueLineChart(
            'gymChart',
            gymValues,
            vm.blueLineChart
        );
        vm.spinner.active = false;
    }

    function incrementValue() {
        if (vm.activeIndex !== vm.gymStatistics.length - 1) {
            vm.activeIndex++;
            if (vm.activeIndex === vm.firstIndex + vm.chartLimit - 1 && vm.activeIndex !== vm.gymStatistics.length - 1) {
                vm.firstIndex++;
            }
        }

        vm.createLocationMetricsChart();
    }

    function decrementValue() {
        if (vm.activeIndex !== 0) {
            vm.activeIndex--;
            if (vm.activeIndex === vm.firstIndex && vm.activeIndex !== 0) {
                vm.firstIndex--;
            }
        }

        vm.createLocationMetricsChart();
    }

    function getAllEntities() {
        EntityService.getEntitiesBasics().then(function (result) {
            if (result.status === 200) {
                vm.availableEntities = angular.copy(result.data.entities);
                vm.availableEntities.unshift({id: null, business_name: 'LifeBase Solutions'});
            }
        });
    }
}
