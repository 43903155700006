import React, { useState } from 'react';

import { LbBigModal } from '../../modals';
import { StripeSetCardForm } from './StripeSetCardForm/StripeSetCardForm';
import { StripeElementsProvider } from '../StripeElementsProvider/StripeElementsProvider';

type StripeSetCardModalProps = {
  open: boolean;
  title: string;
  onClose: () => void;
  onSubmit: (paymentMethodId: string) => Promise<void>;
  publishableKey?: string;
  connectedAccountId?: string;
};

const StripeSetCardModal = ({
  open,
  title,
  onClose,
  onSubmit,
  publishableKey,
  connectedAccountId,
}: StripeSetCardModalProps) => {
  const [isLoading, setLoading] = useState(false);

  const formId = 'stripe-set-card-form-id';

  if (!publishableKey || !connectedAccountId) {
    return null;
  }

  return (
    <LbBigModal
      open={open}
      onClose={onClose}
      title={title}
      isLoading={isLoading}
      buttonOptions={{
        yesText: 'Save',
        noText: 'Cancel',
        formId,
        noHandler: onClose,
        yesDisabled: isLoading,
      }}
    >
      <StripeElementsProvider
        publishableKey={publishableKey}
        connectedAccountId={connectedAccountId}
        elementsOptions={{
          currency: 'usd',
          paymentMethodCreation: 'manual',
          mode: 'setup',
        }}
      >
        <StripeSetCardForm formId={formId} onSubmit={onSubmit} setLoading={setLoading} />
      </StripeElementsProvider>
    </LbBigModal>
  );
};

export { StripeSetCardModal, type StripeSetCardModalProps };
