'use strict';

angular.module('app').factory('SupplementService', SupplementService);

SupplementService.$inject = ['ApiService', 'ModalService', 'utilService'];

function SupplementService(ApiService, ModalService, utilService) {
    return {
        getAllSupplements: function (prms) {
            return ApiService.get('api/coach/supplement-users/filter', prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getUserById: function (id) {
            return ApiService.get('api/supplement-user/' + id).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        updateSupplementInfoAsAdmin: function (id, data) {
            var url = utilService.getLoggedInUserData().role === 'SUPERUSER' ? 'api/super/entity/' + id + '/supplement-users' : 'api/admin/entity/supplement-users';
            return ApiService.put(url, data).then(function (res) {
                ModalService.savedChanges();
                return res;
            }, function (err) {
                return err;
            });
        },
        editSupplementUser: function (id, data) {
            switch (utilService.getLoggedInUserData().role) {
                case 'SUPERUSER':
                    var type = 'admin';
                    break;
                case 'ADMIN':
                    var type = 'admin';
                    break;
                default:
                    var type = 'coach';
                    break;
            }

            return ApiService.put('api/' + type + '/supplement-user/' + id, data).then(function (res) {
                ModalService.savedChanges();
                return res;
            }, function (err) {
                return err;
            });
        },
        createSupplementUser: function (data) {
            switch (utilService.getLoggedInUserData().role) {
                case 'ADMIN':
                    var type = 'admin';
                    break;
                default:
                    var type = 'coach';
                    break;
            }

            if (type === 'coach') {
                delete data.coach;
            }

            data.dob = moment(data.dob).format('MM/DD/YYYY');
            data.gender = data.gender.toLowerCase();

            return ApiService.post('api/' + type + '/supplement-user', data).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        }
    };
}

