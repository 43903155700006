// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../../assets/images/percent.png");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<treasure-overlay-spinner active=\"vm.spinner\"> <div class=\"tax\"> <div class=\"container\"> <div class=\"img\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"> </div> <p class=\"title\">Looks like you need to add Sales <span>Tax</span></p> <p class=\"text\">To get started with subscriptions, please enter Sales Tax value </p> <form class=\"form\" name=\"taxForm\" ng-submit=\"vm.onSubmit()\"> <md-input-container class=\"input-container\"> <label class=\"input-container__label\">Tax Values (%)</label> <input ng-model=\"vm.tax\" type=\"number\" step=\"0.01\" min=\"0\" max=\"100\" required> </md-input-container> <button class=\"button button--wide\" ng-click=\"\" ng-disabled=\"taxForm.$invalid\" type=\"submit\">Continue </button> </form> </div> </div> </treasure-overlay-spinner> ";
// Exports
var _module_exports = code;;
var path = 'app/components/entity-profile/tax/tax.template.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;