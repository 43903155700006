const autoNgTemplateLoaderTemplate1 = require('./terms-and-conditions.template.html');
const autoNgTemplateLoaderTemplate2 = require('./terms-and-conditions.template.html');

'use strict';

(function () {
    angular
        .module('app.termsAndConditions', [])
        .config(termsAndConditionsConfig);

    termsAndConditionsConfig.$inject = ['$routeProvider'];
    function termsAndConditionsConfig($routeProvider) {
        $routeProvider
            .when('/terms-and-conditions', {
                disableCache: true,
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'TermsAndConditionsController',
                controllerAs: 'vm',
                resolve: {
                    termsRequired: ['AuthService', function (AuthService) {
                        return AuthService.termsRequired();
                    }]
                }
            })
            .when('/supplement-user-terms-and-conditions/:accessData', {
                disableCache: true,
                templateUrl: autoNgTemplateLoaderTemplate2,
                controller: 'TermsAndConditionsController',
                controllerAs: 'vm'
            })
    }
})();
