import React from 'react';

import { LbAlert } from '../../../../../../shared/components/ui-components';

import { getFormattedDate } from '../../../../../../shared/utils/dateUtils';
import { localDataFormat } from '../../../../../../shared/constants/apiDateFormat';

type SubscriptionCancelAlertProps = {
  setupNewSubscriptionDate: string | null;
};

const SubscriptionCancelAlert = ({
  setupNewSubscriptionDate,
}: SubscriptionCancelAlertProps) => {
  if (!setupNewSubscriptionDate) {
    return null;
  }

  const formattedSetupSubscriptionDate = getFormattedDate(
    setupNewSubscriptionDate,
    localDataFormat,
  );

  return (
    <LbAlert severity="info" size="small">
      Subscription canceled. You can set up a new one on {formattedSetupSubscriptionDate}
    </LbAlert>
  );
};

export { SubscriptionCancelAlert };
