import React, { useState } from 'react';

import { observer } from 'mobx-react';

import { useModalToggle } from '../../../../../../shared/hooks/useModalToggle';
import { childInformationStore } from '../../../../../stores/childInformationStore';
import { PaperWithTitle } from '../../../components/PaperWithTitle/PaperWithTitle';
import { preCheckInService } from '../../../../../services/preCheckInService';
import { CardContainer } from '../CardContainer/CardContainer';
import { StripeSetCardModal } from '../../../../../../shared/components/stripe';

const CreditCardComponent = observer(() => {
  const [modalToggle, modalToggleHandler] = useModalToggle();
  const [isRequestApproved, setIsRequestApproved] = useState(false);
  const { savePaymentMethodId, paymentMethodId, childInformation } =
    childInformationStore;
  const { setChildRegisterRequestStatus } = preCheckInService;

  const onSuccessStripeHandler = async (paymentMethod) => {
    modalToggleHandler();

    if (!isRequestApproved) {
      await setChildRegisterRequestStatus(true);
      setIsRequestApproved(true);
    }

    savePaymentMethodId(paymentMethod);
  };

  const cardContainer = (
    <CardContainer
      modalToggleHandler={modalToggleHandler}
      isCardAdded={!!paymentMethodId}
    />
  );

  return (
    <>
      <PaperWithTitle title="Add credit card info" content={cardContainer} hasTopMargin />
      <StripeSetCardModal
        title="Add credit card info"
        open={modalToggle}
        onClose={modalToggleHandler}
        onSubmit={onSuccessStripeHandler}
        connectedAccountId={childInformation.connectedAccountID}
        publishableKey={childInformation.stripePublicKey}
      />
    </>
  );
});

export { CreditCardComponent };
