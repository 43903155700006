import { apiService } from '../../shared/services/apiService';
import { CustomersTotalLostFatInfo } from '../interfaces/CustomersTotalLostFatInfo';
import { PerformanceScoreInfo } from '../interfaces/PerformanceScoreInfo';

export const statisticsService = {
  async getCustomersTotalLostFatInfo() {
    const resp = await apiService.get<CustomersTotalLostFatInfo>(
      '/api/coach/stats/scan-metrics',
    );

    return resp.data;
  },

  async getPerformanceScore() {
    const resp = await apiService.get<PerformanceScoreInfo>(
      'api/coach/stats/performance-score',
    );

    return resp.data;
  },
};
