import React from 'react';

import { observer } from 'mobx-react';

import { PaperWithTitle } from '../PaperWithTitle/PaperWithTitle';
import classes from './PersonalInfoBlock.module.scss';
import { childInformationStore } from '../../../../stores/childInformationStore';

const PersonalInfoBlock = observer(() => {
  const {
    childInformation: { fullName, dob, kidEmail, phone },
  } = childInformationStore;

  const infoList = [
    { id: 1, title: 'Full Name', value: fullName },
    { id: 2, title: 'Birth Date', value: dob },
    { id: 3, title: 'Email Kid', value: kidEmail },
    { id: 4, title: 'Phone', value: phone },
  ];

  const personalInfoContainer = (
    <div className={classes.container}>
      {infoList.map((info) => (
        <div key={info.id}>
          <div className={classes.title}>{info.title}</div>
          <div className={classes.info}>{info.value}</div>
        </div>
      ))}
    </div>
  );

  return (
    <PaperWithTitle
      title="Check and confirm personal info of your child"
      content={personalInfoContainer}
    />
  );
});

export { PersonalInfoBlock };
