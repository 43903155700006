/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classes from './TimeRow.module.scss';
import { getSupplementsSortedByDay } from './TimeRow.settings';
import { UnderlineRow } from './UnderlineRow/UnderlineRow';
import { DayColumn } from './DayColumn/DayColumn';
import { getFormattedDate } from '../../../../../../../shared/utils/dateUtils';
import {
  localTimeFormat,
  timeFormat,
} from '../../../../../../../shared/constants/apiDateFormat';

const TimeRow = ({ time, schedules }) => {
  const [isShowMoreBtn, setIsShowMoreBtn] = useState(false);
  const [isShowMore, setIsShowMore] = useState(false);

  const supplementsSortedByDay = getSupplementsSortedByDay(schedules);

  const checkIsShowMoreBtn = (supplementsForDay) => {
    if (supplementsForDay.length > 3 && !isShowMoreBtn) {
      setIsShowMoreBtn(true);
    }
  };

  const showMoreHandler = () => setIsShowMore(!isShowMore);

  const supplementsForDays = supplementsSortedByDay.map((supplementsForDay, index) => {
    checkIsShowMoreBtn(supplementsForDay);
    return (
      <DayColumn
        supplementsForDay={supplementsForDay}
        isShowMore={isShowMore}
        key={index}
      />
    );
  });

  const formattedTime = getFormattedDate(time, localTimeFormat, timeFormat);

  return (
    <div className={classes.timeRow}>
      <div className={classes.time}>{formattedTime}</div>
      <div>
        <div className={classes.scheduleBox}>{supplementsForDays}</div>
        <UnderlineRow
          isShowMoreBtn={isShowMoreBtn}
          isShowMore={isShowMore}
          showMoreHandler={showMoreHandler}
        />
      </div>
    </div>
  );
};

TimeRow.propTypes = {
  time: PropTypes.string,
  schedules: PropTypes.array,
};

export { TimeRow };
