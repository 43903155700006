import { getScanDateTimes, scanServices } from '../../../../../../inbody';
import { InbodyType } from '../../../../../../shared/constants/inbody-info/InbodyType';
import { UserProfileFromValue } from '../UserProfileStep.settings';

export const processCreateInbodyScan = async (
  customerId: number,
  inbodyType: InbodyType,
  manualScanData: UserProfileFromValue['scanData'],
) => {
  if (!manualScanData || !inbodyType) {
    await scanServices.updateScansFromInbody(customerId);
    return;
  }

  const { scanDate, scanTime, ...scanInfo } = manualScanData;

  const datetimes = getScanDateTimes(scanDate, scanTime);

  await scanServices.createScanManually(customerId, {
    ...scanInfo,
    datetimes,
    scanType: inbodyType,
  });
};
