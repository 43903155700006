'use strict';

angular.module('app.entitySetup').controller('EntitySetupController', EntitySetupController);

EntitySetupController.$inject = [];

function EntitySetupController() {
    var vm = this;
    vm.spinner = {active: false};
    vm.createEntity = {};
    vm.step = {name: 'step1'};
    vm.done = [false, false, false, false, false, false];

    vm.getTabState = getTabState;
    vm.next = next;

    function getTabState(index) {
        if (index < 6 && vm.done[index - 1]) {
            return 'completed';
        }
        return vm.step.name === 'step' + index ? 'active' : '';
    }

    function next(stepNumber) {
        vm.step.name = 'step' + (stepNumber + 2);
        vm.done[stepNumber] = true;
    }
}
