import React, { useEffect } from 'react';

import { Container } from '@mui/material';
import { angularize } from 'react-in-angularjs';
import { FormProvider, useForm } from 'react-hook-form';
import { observer } from 'mobx-react';

import classes from './NgzScholasticUserSetup.module.scss';
import { SystemProviders } from '../../../shared/providers';
import {
  LbStepper,
  type StepOption,
} from '../../../shared/components/LbStepper/LbStepper';
import { UserProfileStep } from './containers/UserProfileStep/UserProfileStep';
import { SelectMealPlanStep } from './containers/SelectMealPlanStep/SelectMealPlanStep';
import { FinalizeOrderStep } from './containers/FinalizeOrderStep/FinalizeOrderStep';
import { ScholasticCustomerSetupFormValue } from './NgzScholasticUserSetup.settings';
import { setupScholasticCustomerStore } from '../../stores/setupScholasticCustomerStore';
import { getRouteParam } from '../../../shared/utils/angularUtils';
import { scholasticCustomerService } from '../../services/scholasticCustomerService';
import { getFilteredTruthyFieldsObj } from '../../../shared/utils/sortingAndGroupingUtil';
// import { EditMealPlanStep } from './containers/EditMealPlanStep/EditMealPlanStep';

const NgzScholasticUserSetup = observer(() => {
  const methods = useForm<ScholasticCustomerSetupFormValue>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const { reset } = methods;

  const { stepperMethods, setDefaultValues } = setupScholasticCustomerStore;
  const { activeStep, goToStep } = stepperMethods;

  const userId = getRouteParam('id');

  const stepsOptions: StepOption[] = [
    {
      step: <UserProfileStep />,
      label: 'User Profile',
    },
    { step: <SelectMealPlanStep />, label: 'Select Plan' },
    // {
    //   step: <EditMealPlanStep />,
    //   label: 'Finalize Plan',
    // },
    {
      step: <FinalizeOrderStep />,
      label: 'Finalize Order',
    },
  ];

  const initContinueUserCreation = async () => {
    if (!userId) {
      return;
    }

    // TODO set more info when form need, add function to delate unused field
    const userData = await scholasticCustomerService.getUserData(userId);
    const clearedUserData = getFilteredTruthyFieldsObj(userData);

    reset({ userData: clearedUserData });
  };

  useEffect(() => {
    initContinueUserCreation();
    return setDefaultValues;
  }, []);

  return (
    <SystemProviders>
      <Container>
        <FormProvider {...methods}>
          <h2 className={classes.title}>Add Scholastic Member</h2>
          <LbStepper
            stepsArr={stepsOptions}
            activeStep={activeStep}
            goToStep={goToStep}
            className={classes.stepper}
          />
        </FormProvider>
      </Container>
    </SystemProviders>
  );
});

angularize(
  NgzScholasticUserSetup,
  'ngzScholasticUserSetup',
  angular.module('app.scholastic'),
  {},
);

export { NgzScholasticUserSetup };
