import { UnconfiguredSettingsType } from '../../../constants/unconfiguredSettings';

export const getNeedToSetupInfo = (
  unconfiguredSetting: UnconfiguredSettingsType[],
): string =>
  unconfiguredSetting
    .map((field) => unconfiguredSettingsDescription[field])
    .filter(Boolean)
    .join(' and ');

const unconfiguredSettingsDescription: Record<UnconfiguredSettingsType, string> = {
  ENTITY_TAX: 'set up tax',
  STRIPE_CONNECT: 'connect Stripe account',
};
