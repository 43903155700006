import { UnleashClient } from 'unleash-proxy-client';

const config = {
  url: process.env.APP_UNLEASH_URL,
  clientKey: process.env.APP_UNLEASH_KEY,
  refreshInterval: process.env.APP_UNLEASH_REFRESH_INTERVAL,
  appName: 'LifeBase', // required but not used, used like env in gitLab
  environment: process.env.APP_ENV || 'develop',
};

const unleashClient = new UnleashClient(config);

export { unleashClient };
