import React from 'react';
import PropTypes from 'prop-types';

import { IconButton, Tooltip } from '@mui/material';

import classes from './OptionButton.module.scss';

const OptionButton = ({ tooltipText, onClick, MuiIcon }) => {
  return (
    <Tooltip title={tooltipText}>
      <IconButton className={classes.iconBtn} onClick={onClick}>
        <MuiIcon fontSize="medium" />
      </IconButton>
    </Tooltip>
  );
};

OptionButton.propTypes = {
  tooltipText: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  MuiIcon: PropTypes.elementType,
};

export { OptionButton };
