// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../assets/images/vectors/pin-code-image-dark.svg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<form name=\"checkPinCodeForm\" class=\"pin-code\"> <treasure-overlay-spinner active=\"vm.spinner.active\"> <div class=\"app-card\" layout=\"row\" layout-align=\"space-between center\"> <div layout=\"column\" class=\"pin-code-container\" flex=\"50\"> <h2 class=\"pin-code__title\">Pin Code</h2> <p class=\"pin-code__subtitle\">Please type pin-code to verify login to</p> <md-input-container class=\"pin-code-input-container\"> <label>Pin code</label> <input ng-model=\"vm.pinCode\" ng-change=\"vm.changePinCode()\" name=\"pin\" type=\"password\" maxlength=\"4\"> <div ng-messages=\"checkPinCodeForm.pin.$error\"> <div ng-message=\"required\">Pin code is required</div> </div> </md-input-container> <div class=\"pin-code-numbers-container\" align=\"end\"> <button ng-click=\"vm.addValueToPin(1)\">1</button> <button ng-click=\"vm.addValueToPin(2)\">2</button> <button ng-click=\"vm.addValueToPin(3)\">3</button> <button ng-click=\"vm.addValueToPin(4)\">4</button> <button ng-click=\"vm.addValueToPin(5)\">5</button> <button ng-click=\"vm.addValueToPin(6)\">6</button> <button ng-click=\"vm.addValueToPin(7)\">7</button> <button ng-click=\"vm.addValueToPin(8)\">8</button> <button ng-click=\"vm.addValueToPin(9)\">9</button> <button ng-click=\"vm.addValueToPin(0)\">0</button> <button class=\"remove-pin-number\" ng-click=\"vm.removeValueFromPin()\"> <md-icon md-svg-icon=\"remove-pin-code\"></md-icon> </button> </div> </div> <img class=\"pin-code__image\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"> </div> </treasure-overlay-spinner> </form> ";
// Exports
var _module_exports = code;;
var path = 'app/components/pin-code/pin-code.template.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;