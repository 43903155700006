import React from 'react';

import { Paper, Button } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

import classes from './ExplanationSetupSteps.module.scss';
import { BlackBgRow } from '../../../../shared/components/ui-components';

type ExplanationSetupStepsProps = {
  onStart: () => void;
};

const ExplanationSetupSteps = ({ onStart }: ExplanationSetupStepsProps) => {
  const setupTodo = [
    'Tell us about yourself and your goals',
    'Choose your nutrition coach',
    'Select payment plan',
  ];

  return (
    <BlackBgRow bgRowHeight="180px">
      <h1 className={classes.title}>Welcome to LifeBase!</h1>
      <Paper variant="boxWithPadding">
        <h5 className={classes.todoTitle}>In the next step, you will:</h5>
        <ul className={classes.todoBox}>
          {setupTodo.map((todo) => (
            <li className={classes.todoItem} key={todo}>
              <CheckCircleRoundedIcon color="success" />
              <span>{todo}</span>
            </li>
          ))}
        </ul>
      </Paper>
      <div className={classes.buttonRow}>
        <Button variant="contained" size="large" onClick={onStart}>
          Click Here to Get Started!
        </Button>
      </div>
    </BlackBgRow>
  );
};

export { ExplanationSetupSteps };
