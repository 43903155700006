import React from 'react';
import { Chip, Switch } from '@mui/material';
import { observer } from 'mobx-react';

import {
  GridStickyGroup,
  GridTableCell,
  GridTableRow,
} from '../../../../../shared/components/table-components';
import { PromoCodeInfo } from '../../../../interfaces/promoCodeInfo';
import { getFormattedDate } from '../../../../../shared/utils/dateUtils';
import { localDataFormat } from '../../../../../shared/constants/apiDateFormat';
import { loggedInUserDataStore } from '../../../../../shared/stores/loggedInUserDataStore';
import { CodeStatus } from './CodeStatus/CodeStatus';
import { PromoCodeCell } from './PromoCodeCell/PromoCodeCell';
import { SubscriptionTypeCell } from './SubscriptionTypeCell/SubscriptionTypeCell';

type PromoCodeRowProps = {
  promoCodeInfo: PromoCodeInfo;
  onPromoCodeToggle: (promoCodeId: number, pause: boolean) => void;
};

const PromoCodeRow = observer(
  ({ promoCodeInfo, onPromoCodeToggle }: PromoCodeRowProps) => {
    const { isEntity } = loggedInUserDataStore;

    const isToggleDisabled = !isEntity || promoCodeInfo.status === 'INACTIVE';

    const isPromoCodeActive = promoCodeInfo.status === 'ACTIVE';

    const handlePromoCodeStatus = () => {
      onPromoCodeToggle(promoCodeInfo.id, isPromoCodeActive);
    };

    return (
      <GridTableRow variant="row">
        <GridStickyGroup>
          <GridTableCell variant="title">{promoCodeInfo.name}</GridTableCell>
        </GridStickyGroup>
        <GridTableCell>
          <PromoCodeCell promoCode={promoCodeInfo.code} />
        </GridTableCell>
        <GridTableCell>
          <Chip label={`${promoCodeInfo.discountPercent}%`} size="small" />
        </GridTableCell>
        <GridTableCell>{promoCodeInfo.usageLimit}</GridTableCell>
        <GridTableCell>{promoCodeInfo.activations}</GridTableCell>
        <GridTableCell>
          <SubscriptionTypeCell
            promoCodeName={promoCodeInfo.name}
            subscriptionSetupList={promoCodeInfo.subscriptionSetupList}
            applyType={promoCodeInfo.applyType}
          />
        </GridTableCell>
        <GridTableCell>
          {promoCodeInfo.endDate
            ? getFormattedDate(promoCodeInfo.endDate, localDataFormat)
            : '—'}
        </GridTableCell>
        <GridTableCell>
          <CodeStatus
            status={promoCodeInfo.status}
            statusDescription={promoCodeInfo.statusDescription}
          />
        </GridTableCell>
        <GridTableCell>
          <Switch
            checked={isPromoCodeActive}
            onChange={handlePromoCodeStatus}
            disabled={isToggleDisabled}
          />
        </GridTableCell>
      </GridTableRow>
    );
  },
);

export { PromoCodeRow };
