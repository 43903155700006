import React from 'react';
import PropTypes from 'prop-types';

import classes from './Spinner.module.scss';

export const testIds = { spinnerBox: 'spinnerBox' };

const Spinner = ({ isLoading, isGlobal = false }) => {
  const loadingClass = isLoading ? classes.loading : '';
  const globalClass = isGlobal ? classes.global : '';
  const spinnerClasses = `${classes.spinnerContainer} ${loadingClass} ${globalClass}`;

  return (
    <div className={spinnerClasses} data-test-id={testIds.spinnerBox}>
      <div className={classes.spinner} />
    </div>
  );
};

Spinner.propTypes = {
  isLoading: PropTypes.bool,
  isGlobal: PropTypes.bool,
};

export { Spinner };
