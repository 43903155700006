import React from 'react';

import { useFormContext } from 'react-hook-form';

import { MED_TEST_RESULT_STATUSES_KEYS } from '../../../../constants/MED_TEST_RESULT_STATUSES_KEYS';
import { MED_TEST_RESULT_STATUSES_LABELS } from '../../../../constants/MED_TEST_RESULT_STATUSES_LABELS';
import { LbCheckbox } from '../../../../../shared/components/form-components';

const TestResultStatusesCheckboxes = ({ nameIndex }) => {
  const { control, watch } = useFormContext();

  const { APPOINTMENT_COMPLETED, TEST_COMPLETED, ROF } = MED_TEST_RESULT_STATUSES_KEYS;

  const watchConfirmed = watch(`${nameIndex}${APPOINTMENT_COMPLETED}`);
  const watchCompleted = watch(`${nameIndex}${TEST_COMPLETED}`);
  const watchROF = watch(`${nameIndex}${ROF}`);

  const testResultStatuses = [
    {
      nameSuffix: APPOINTMENT_COMPLETED,
      label: MED_TEST_RESULT_STATUSES_LABELS[APPOINTMENT_COMPLETED].true,
      disabled: watchCompleted,
    },
    {
      nameSuffix: TEST_COMPLETED,
      label: MED_TEST_RESULT_STATUSES_LABELS[TEST_COMPLETED].true,
      disabled: !watchConfirmed || watchROF,
    },
    {
      nameSuffix: ROF,
      label: MED_TEST_RESULT_STATUSES_LABELS[ROF].true,
      disabled: !watchCompleted,
    },
  ];

  return testResultStatuses.map((status) => (
    <LbCheckbox
      control={control}
      name={`${nameIndex}${status.nameSuffix}`}
      label={status.label}
      disabled={status.disabled}
      key={status.nameSuffix}
    />
  ));
};

export { TestResultStatusesCheckboxes };
