import { apiService } from '../../shared/services/apiService';

const urlBase = '/api/admin/corp';

export const corporateAccountsService = {
  async getCorporateAccounts() {
    const resp = await apiService.get(`${urlBase}/get-all-accounts`);

    return resp.data;
  },
  async deleteCorporateAccount(id) {
    const resp = await apiService.delete(`${urlBase}/account/${id}`);

    return resp.data;
  },
  async createCorporateAccount(data) {
    const resp = await apiService.post(`${urlBase}/account`, data);

    return resp.data;
  },
  async editCorporateAccount(data, id) {
    const resp = await apiService.put(`${urlBase}/account/${id}`, data);

    return resp.data;
  },
};
