// Note: This hook is disable auto-scaling when user focuses on inputs on iOS devices
// We should set the font size of inputs to 16px to prevent the auto-scaling issue

import { useEffect } from 'react';

export const useAutoScaling = () => {
  useEffect(() => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

    if (isIOS) {
      document
        .querySelector('[name=viewport]')
        ?.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1');
    }
  }, []);
};
