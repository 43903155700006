import moment, { MomentFormatSpecification } from 'moment';

export const getSortedArrByDate = <TItem extends Record<any, any>>(
  arr: TItem[],
  field: keyof TItem,
  format?: MomentFormatSpecification,
  formOldToNew: boolean = true,
) =>
  arr.sort((a, b) => {
    const dataA = moment(a[field], format);
    const dataB = moment(b[field], format);

    if (formOldToNew) {
      return dataA > dataB ? 1 : -1;
    } else {
      return dataA < dataB ? 1 : -1;
    }
  });
