import { getService } from 'react-in-angularjs';

import { goTo } from '../../../../../../utils/angularUtils';
import { setUserInfo } from '../../../../../../utils/userInfoUtils';
import { loggedInUserDataStore } from '../../../../../../stores/loggedInUserDataStore';

export const logout = () => {
  const { corporateSignInStatuses, loggedInUser } = loggedInUserDataStore;
  const { corporateAccountInfo } = loggedInUser;
  const AuthService = getService('AuthService');

  if (corporateSignInStatuses.FINISHED_SING_IN) {
    setUserInfo({ corporateAccountInfo });
    goTo('/pin-code');
  } else {
    AuthService.signOut();
  }
};
