import { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useToggle } from 'react-use';

import { loggedInUserDataStore } from '../../../../../../shared/stores/loggedInUserDataStore';
import { SchoolInfo } from '../../../../../interfaces/SchoolInfo';
import { AddSchool, schoolService } from '../../../../../services/schoolService';
import { updateItemByField } from '../../../../../../shared/utils/arrayUtils';

// TODO look maybe better reset function to store, look at used CorporateAccountsModal
export const useScholasticSchool = () => {
  const [schoolsList, setSchoolList] = useState<SchoolInfo[]>([]);
  const [isOpenModalSchool, toggleOpenModalSchool] = useToggle(false);
  const [editSchoolId, setEditSchoolId] = useState<number | null>(null);

  const entityId = loggedInUserDataStore.getEntityId();

  const { control, handleSubmit, setValue } = useForm<AddSchool>({
    shouldUnregister: true,
  });

  const onSubmit = async (shoolInfo: AddSchool) => {
    if (editSchoolId) {
      editSchool(shoolInfo);
    } else {
      addNewSchool(shoolInfo);
    }

    handleCloseModalSchool();
  };

  const addNewSchool = async (shoolInfo: AddSchool) => {
    const newSchool = await schoolService.addNewSchool(entityId, shoolInfo);

    setSchoolList([newSchool, ...schoolsList]);
  };

  const editSchool = async (shoolInfo: AddSchool) => {
    const schoolInfo = await schoolService.editSchool(entityId, editSchoolId!, shoolInfo);

    updateSchool(schoolInfo);
  };

  const handleSchoolActiveStatusChange = async (schoolId: number, status: boolean) => {
    const resp = await schoolService.changeShoolStatus(entityId, schoolId, status);

    updateSchool(resp);
  };

  const updateSchool = (newSchoolInfo: SchoolInfo) => {
    const editedSchoolList = updateItemByField(schoolsList, newSchoolInfo, {
      id: newSchoolInfo.id,
    });

    setSchoolList(editedSchoolList);
  };

  const openAddNewSchoolModal = () => {
    toggleOpenModalSchool();
  };

  const openEditSchoolModal = (schoolInfo: SchoolInfo) => {
    const { id, name } = schoolInfo;

    setEditSchoolId(id);
    setValue('name', name);
    toggleOpenModalSchool();
  };

  const handleCloseModalSchool = () => {
    setEditSchoolId(null);
    toggleOpenModalSchool();
  };

  const getSchoolList = async () => {
    const { customerSchoolGroups } = await schoolService.getSchoolList(entityId);

    setSchoolList(customerSchoolGroups);
  };

  useEffect(() => {
    getSchoolList();
  }, []);

  return {
    schoolsList,
    isOpenModalSchool,
    editSchoolId,
    control,
    handleFormSubmit: handleSubmit(onSubmit),
    openAddNewSchoolModal,
    openEditSchoolModal,
    handleCloseModalSchool,
    handleSchoolActiveStatusChange,
  };
};
