import { Option } from '../../../general-types';
// Only for school entity, to get sport for general user use useActivitiesList
export const sportOptions: Option[] = [
  { label: 'Basketball', value: 'Basketball' },
  { label: 'Football', value: 'Football' },
  { label: 'Lacrosse', value: 'Lacrosse' },
  { label: 'Baseball', value: 'Baseball' },
  { label: 'Softball', value: 'Softball' },
  { label: 'Hockey', value: 'Hockey' },
  { label: 'Volleyball', value: 'Volleyball' },
  { label: 'Track', value: 'Track' },
  { label: 'Golf', value: 'Golf' },
  { label: 'Cycling', value: 'Cycling' },
  { label: 'Swimming', value: 'Swimming' },
  { label: 'Competitive Cheer', value: 'Competitive Cheer' },
  { label: 'Running', value: 'Running' },
  { label: 'Weight Lifting', value: 'Weight Lifting' },
  { label: 'Soccer', value: 'Soccer' },
  { label: 'Wrestling', value: 'Wrestling' },
];

export type SportOptionValues = (typeof sportOptions)[number]['value'];

export const sportPositionOptions: Record<SportOptionValues, Option[]> = {
  Basketball: [
    { label: 'Coach', value: 'Coach' },
    { label: 'Guard', value: 'Guard' },
    { label: 'Forward', value: 'Forward' },
    { label: 'Center', value: 'Center' },
    { label: 'Student', value: 'Student' },
  ],
  Football: [
    { label: 'Coach', value: 'Coach' },
    { label: 'Offensive Line', value: 'Offensive Line' },
    { label: 'Defensive Line', value: 'Defensive Line' },
    { label: 'Quarterback', value: 'Quarterback' },
    { label: 'Running Back', value: 'Running Back' },
    { label: 'Wide Receiver', value: 'Wide Receiver' },
    { label: 'Tight End', value: 'Tight End' },
    { label: 'Defensive Back', value: 'Defensive Back' },
    { label: 'LineBacker', value: 'LineBacker' },
    { label: 'Kicker', value: 'Kicker' },
    { label: 'Student', value: 'Student' },
  ],
  Baseball: [
    { label: 'Coach', value: 'Coach' },
    { label: 'Pitcher', value: 'Pitcher' },
    { label: 'Catcher', value: 'Catcher' },
    { label: 'Infielder', value: 'Infielder' },
    { label: 'Outfielder', value: 'Outfielder' },
    { label: 'Student', value: 'Student' },
  ],
  Softball: [
    { label: 'Coach', value: 'Coach' },
    { label: 'Pitcher', value: 'Pitcher' },
    { label: 'Catcher', value: 'Catcher' },
    { label: 'Infielder', value: 'Infielder' },
    { label: 'Outfielder', value: 'Outfielder' },
    { label: 'Student', value: 'Student' },
  ],
  Volleyball: [
    { label: 'Coach', value: 'Coach' },
    { label: 'Outside Hitter', value: 'Outside Hitter' },
    { label: 'Middle Blocker', value: 'Middle Blocker' },
    { label: 'Setter', value: 'Setter' },
    { label: 'Libero', value: 'Libero' },
    { label: 'Student', value: 'Student' },
  ],
  Lacrosse: [
    { label: 'Coach', value: 'Coach' },
    { label: 'Offense', value: 'Offense' },
    { label: 'Defense', value: 'Defense' },
    { label: 'Goalie', value: 'Goalie' },
    { label: 'Student', value: 'Student' },
  ],
  Hockey: [
    { label: 'Coach', value: 'Coach' },
    { label: 'Forward', value: 'Forward' },
    { label: 'Center', value: 'Center' },
    { label: 'Defensemen', value: 'Defensemen' },
    { label: 'Goalie', value: 'Goalie' },
    { label: 'Student', value: 'Student' },
  ],
  Track: [
    { label: 'Coach', value: 'Coach' },
    { label: 'Sprinter', value: 'Sprinter' },
    { label: 'Medium Distance', value: 'Medium Distance' },
    { label: 'Long Distance', value: 'Long Distance' },
    { label: 'Shot Put/Discuss', value: 'Shot Put/Discuss' },
    { label: 'Hurdler', value: 'Hurdler' },
    { label: 'Pole Vaulter', value: 'Pole Vaulter' },
    { label: 'Steeple Chase', value: 'Steeple Chase' },
    { label: 'Jumper', value: 'Jumper' },
    { label: 'Student', value: 'Student' },
  ],
  Golf: [
    { label: 'Coach', value: 'Coach' },
    { label: 'Student', value: 'Student' },
  ],
  Cycling: [
    { label: 'Coach', value: 'Coach' },
    { label: 'Student', value: 'Student' },
  ],
  Swimming: [{ label: 'Student', value: 'Student' }],
  'Competitive Cheer': [{ label: 'Student', value: 'Student' }],
  Running: [{ label: 'Student', value: 'Student' }],
  'Weight Lifting': [{ label: 'Student', value: 'Student' }],
  Soccer: [
    { label: 'Center', value: 'Center' },
    { label: 'Forward', value: 'Forward' },
    { label: 'Defense', value: 'Defense' },
    { label: 'Goalie', value: 'Goalie' },
  ],
  Wrestling: [
    { label: '125 lbs', value: '125 lbs' },
    { label: '133 lbs', value: '133 lbs' },
    { label: '141 lbs', value: '141 lbs' },
    { label: '149 lbs', value: '149 lbs' },
    { label: '157 lbs', value: '157 lbs' },
    { label: '165 lbs', value: '165 lbs' },
    { label: '174 lbs', value: '174 lbs' },
    { label: '184 lbs', value: '184 lbs' },
    { label: '197 lbs', value: '197 lbs' },
    { label: '285 lbs', value: '285 lbs' },
  ],
};
