import { ChartArea } from 'chart.js';

import { palette } from '../../../../../../shared/theme/palette';

type DrawDashLineOptions = {
  pointX: number;
  pointY: number;
  gapFromStart: number;
};

export const drawDashLine = (
  ctx: CanvasRenderingContext2D,
  chartArea: ChartArea,
  options: DrawDashLineOptions,
) => {
  const { bottom } = chartArea;
  const { pointX, pointY, gapFromStart } = options;

  const isInChartArea = pointY + gapFromStart < bottom;

  if (isInChartArea) {
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(pointX, pointY + gapFromStart);
    ctx.lineTo(pointX, bottom);
    ctx.lineWidth = 1;
    // bag in linter
    // eslint-disable-next-line prefer-destructuring
    ctx.strokeStyle = palette.natural[400];
    ctx.setLineDash([5, 5]);
    ctx.stroke();
    ctx.restore();
  }
};
