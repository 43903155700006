import React from 'react';

import { useForm } from 'react-hook-form';

import { LbModal } from '../../../../../../../../shared/components/modals';
import { LbInput } from '../../../../../../../../shared/components/form-components';
import {
  validateLoginId,
  validateLoginIdMassage,
} from '../../../../../../../utils/reactHookFormUtils';

type ChangeLoginIdFromValue = {
  loginId: string;
};

type ChangeLoginIdModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (loginId: string) => Promise<void>;
};

const ChangeLoginIdModal = ({ open, onClose, onSubmit }: ChangeLoginIdModalProps) => {
  const { control, handleSubmit } = useForm<ChangeLoginIdFromValue>();

  const handleFormSubmit = handleSubmit(({ loginId }) => {
    onSubmit(loginId);
    onClose();
  });
  return (
    <LbModal
      open={open}
      title="Change Login ID"
      primaryBtnConfig={{ text: 'Save', onClick: handleFormSubmit }}
      secondaryBtnConfig={{ text: 'Cancel', onClick: onClose }}
    >
      <form>
        <LbInput
          control={control}
          size="small"
          label="New Login ID"
          name="loginId"
          rules={{ required: 'Login ID is required', validate: { validateLoginId } }}
          helperText={validateLoginIdMassage}
        />
      </form>
    </LbModal>
  );
};

export { ChangeLoginIdModal };
