import React from 'react';
import PropTypes from 'prop-types';

import { InputAdornment } from '@mui/material';

import Water from '../../assets/image/water.png';
import { LbModal } from '../../../shared/components/modals/LbModal/LbModal';
import { LbInput } from '../../../shared/components/form-components';
import { validateOnlyInteger } from '../../../shared/utils/reactHookFormUtils';

const AddWaterGoalModal = ({ open, control, name, yesHandler, noHandler, isLoading }) => {
  return (
    <LbModal
      open={open}
      imgSrc={Water}
      title="Please add a water consumption goal per day. "
      text="Water is best set between 30 and 35ml per kg of body weight.  Simple Rule of thumb one can use is to take body weight in pounds, divide by 2 and that is how many ounces to drink. "
      noHandler={noHandler}
      isLoading={isLoading}
      primaryBtnConfig={{ text: 'Continue', onClick: yesHandler }}
      secondaryBtnConfig={{ onClick: noHandler }}
    >
      <LbInput
        control={control}
        label="Water Goal"
        name={name}
        type="number"
        rules={{
          required: 'Water Goal is required',
          validate: validateOnlyInteger,
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">oz</InputAdornment>,
        }}
      />
    </LbModal>
  );
};

AddWaterGoalModal.propTypes = {
  open: PropTypes.bool.isRequired,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  yesHandler: PropTypes.func.isRequired,
  noHandler: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export { AddWaterGoalModal };
