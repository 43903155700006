import React from 'react';

import { SvgIcon } from '@mui/material';
import { observer } from 'mobx-react';

import { UserMenuItem } from '../UserMenuItem/UserMenuItem';
import { ReactComponent as logoutIcon } from '../../../../../../../../assets/images/material-icons/logout.svg';
import { loggedInUserDataStore } from '../../../../../stores/loggedInUserDataStore';
import { backToChildEntityList } from './utils/backToChildEntityList';
import { useIsShowBackToHomeBtn } from './hooks/useIsShowBackToHomeBtn';

const BackToHomeItem = observer(() => {
  const { entityType } = loggedInUserDataStore;
  const isShowBackToHomeBtn = useIsShowBackToHomeBtn();

  if (!isShowBackToHomeBtn) {
    return null;
  }

  return (
    <UserMenuItem
      label="Back to Home"
      onClick={() => backToChildEntityList(entityType)}
      startIcon={<SvgIcon component={logoutIcon} inheritViewBox />}
    />
  );
});

export { BackToHomeItem };
