import React from 'react';
import PropTypes from 'prop-types';

import classes from './Row.module.scss';

const Row = ({ children, className }) => {
  const rowClasses = className ? `${classes.row} ${className}` : classes.row;

  return <div className={rowClasses}>{children}</div>;
};

Row.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export { Row };
