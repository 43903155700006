const autoNgTemplateLoaderTemplate1 = require('./users-fees-card.template.html');

UsersFeesCardController.$inject = [];

function UsersFeesCardController() {
  const vm = this;
  vm.isAllowedAddBundle = true;

  vm.onUsersCountToBlur = (index) => {
    const bundleArr = vm.usersFees;
    const bundle = vm.usersFees[index];

    const resultBoolArr = [bundle.usersCountTo > bundle.usersCountFrom];
    if (bundleArr[index - 1]) {
      resultBoolArr.push(bundle.usersCountTo > bundleArr[index - 1].usersCountTo);
    }
    if (bundleArr[index + 1]) {
      resultBoolArr.push(bundle.usersCountTo === bundleArr[index + 1].usersCountFrom - 1);
    }

    const isValidity = vm.isAllowedAddBundle = resultBoolArr.every(item => item);
    vm.form['vm.form.usersCountTo' + index].$setValidity('usersCountRange', isValidity);
  }

  vm.getIsNeedTrash = (index) => {
    return vm.usersFees.length > 1 && vm.usersFees.length - 1 === index && !vm.isEditDisabled;
  }

  vm.removeBundle = (index) => {
    vm.usersFees.splice(index, 1);
    vm.onUsersCountToBlur(index - 1);
  }

  vm.getNewBundleUsersCountRange = () => {
    const defaultUsersCount = 99;
    const usersCountRange = {};
    usersCountRange.usersCountFrom = vm.usersFees[vm.usersFees.length - 1].usersCountTo + 1;
    usersCountRange.usersCountTo = usersCountRange.usersCountFrom + defaultUsersCount;
    return usersCountRange;
  }

  vm.addBundle = () => {
    const newBundle = Object.assign({name: vm.usersFees[0].name}, vm.getNewBundleUsersCountRange());
    vm.usersFees.push(newBundle);
  }
}

angular.module('app').component('usersFeesCard', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: UsersFeesCardController,
  controllerAs: 'vm',
  bindings: {
    usersFees: '=',
    cardTitle: '<',
    isEditDisabled: '<'
  }
});
