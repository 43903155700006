import { SubscriptionType } from '../../../shared/constants/user-info/subscription';
import { EntitySubscriptionPaymentInfo } from '../../interfaces/entitySubscription';

export type CreateEntitySubscriptionFormValues = {
  name: string;
  subscriptionType: SubscriptionType;
  subscriptionPrice: Omit<
    EntitySubscriptionPaymentInfo,
    'billingPeriodType' | 'subscriptionType'
  >;
};

export const generalInputProps = { variant: 'outlined', size: 'small' } as const;
export const generalPriceInputProps = {
  ...generalInputProps,
  prefix: '$',
  allowNegative: false,
  decimalScale: 2,
  fixedDecimalScale: true,
  selectValueOnFocus: true,
} as const;
