import { getIsNullish } from '../../jsUtils';
import { getPercentages } from '../getPercentages/getPercentages';

export const getPercentagesOrDash = (
  partialValue: number | undefined | null,
  totalValue: number | undefined | null,
  roundDigits?: number,
) => {
  const isPartialValueNotExist = getIsNullish(partialValue);
  const isTotalValueNotExistOrZero = !totalValue;

  const isShowDash = isPartialValueNotExist || isTotalValueNotExistOrZero;

  return isShowDash ? '—' : getPercentages(partialValue!, totalValue, roundDigits);
};
