import React, { useState } from 'react';

import classes from './SubscriptionStep.module.scss';
import { ButtonsRow } from '../../components/ButtonsRow/ButtonsRow';
import { setupEntityStore } from '../../../../stores/setupEntityStore';
import {
  FeeModuleWidget,
  SetupEntitySubscriptionTable,
  FreeAccessWidget,
  TaxWidget,
  SubscriptionSettingsWidget,
} from '../../../../../payment';

const SubscriptionStep = () => {
  const [isFeeModuleCreated, setIsFeeModuleCreated] = useState(false);
  const [isSubscriptionSettingsCreated, setIsSubscriptionSettingsCreated] =
    useState(false);

  const { entityId, entityType, stepperMethods } = setupEntityStore;
  const { goToNextStep } = stepperMethods;

  if (!entityId || !entityType) {
    throw new Error('Entity id or entity type is not defined');
  }

  const isNextBtnDisabled = !isSubscriptionSettingsCreated || !isFeeModuleCreated;

  return (
    <div>
      <div className={classes.row}>
        <div className={classes.column}>
          <FeeModuleWidget
            entityType={entityType}
            className={classes.moduleFormBlock}
            entityId={entityId}
            onSuccessSubmit={() => {
              setIsFeeModuleCreated(true);
            }}
          />
          <TaxWidget entityId={entityId} />
        </div>
        <SubscriptionSettingsWidget
          entityId={entityId}
          defaultMode="EDIT"
          onSuccessSubmit={() => {
            setIsSubscriptionSettingsCreated(true);
          }}
        />
      </div>
      <SetupEntitySubscriptionTable entityId={entityId} />
      <FreeAccessWidget entityId={entityId} />
      <ButtonsRow onNext={goToNextStep} yesDisabled={isNextBtnDisabled} />
    </div>
  );
};

export { SubscriptionStep };
