import React from 'react';

import { observer } from 'mobx-react';

import classes from './SuccessSentMessageModal.module.scss';
import { LbModal } from '../../../../shared/components/modals/LbModal/LbModal';
import { ReactComponent as SuccessSendMessageIcon } from '../../../../shared/assets/image/successSendMessage.svg';
import { communicationModalStore } from '../../../stores/communicationModalStore';

const SuccessSentMessageModal = observer(() => {
  const { customersCount, isSentSuccess, closeCommunicationModal } =
    communicationModalStore;

  return (
    <LbModal
      open={isSentSuccess}
      title="Success!"
      text={`${customersCount} messages were sent successfully`}
      secondaryBtnConfig={{ text: 'Close', onClick: closeCommunicationModal }}
    >
      <div className={classes.iconBox}>
        <SuccessSendMessageIcon />
      </div>
    </LbModal>
  );
});

export { SuccessSentMessageModal };
