import React from 'react';
import PropTypes from 'prop-types';

import Radio from '@mui/material/Radio';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { observer } from 'mobx-react';

import { PaperWithTitle } from '../../../components/PaperWithTitle/PaperWithTitle';
import { subscriptionProps } from '../Subscription.setting';
import classes from './SubscriptionBlock.module.scss';
import { SubscriptionBlockBody } from '../SubscriptionBlockBody/SubscriptionBlockBody';
import { childInformationStore } from '../../../../../stores/childInformationStore';

const SubscriptionBlock = observer(({ subscription }) => {
  const { title, initialDaysOfSubscription } = subscription;
  const { isEntityWithStripe } = childInformationStore;

  const subscriptionDuration = `${initialDaysOfSubscription} day${
    initialDaysOfSubscription > 1 ? 's' : ''
  }`;

  const paperTitle = (
    <div className={classes.headerContent}>
      <Radio className={classes.radio} checked />
      <div className={classes.headerTitle}>{title}</div>
      <InfoOutlinedIcon className={classes.icon} fontSize="small" />
      <div className={classes.headerDay}>{subscriptionDuration}</div>
    </div>
  );

  const cardBody = <SubscriptionBlockBody subscription={subscription} />;
  const content = isEntityWithStripe ? cardBody : null;

  return <PaperWithTitle hasTopMargin customTitle={paperTitle} content={content} />;
});

SubscriptionBlock.propTypes = {
  subscription: PropTypes.shape(subscriptionProps),
};

export { SubscriptionBlock };
