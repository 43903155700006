import { useEffect, useState } from 'react';
import { insuranceCertificatesService } from '../../../../../services/insuranceCertificatesService';
import { loggedInUserDataStore } from '../../../../../../shared/stores/loggedInUserDataStore';
import { getRouteParam } from '../../../../../../shared/utils/angularUtils';
import { deleteItemByField } from '../../../../../../shared/utils/arrayUtils/deleteItemByField/deleteItemByField';

export const useInsuranceCertificatesTabData = () => {
  const [insuranceCertificates, setInsuranceCertificates] = useState([]);

  const getEntityId = () => {
    if (loggedInUserDataStore.isSuperAdmin) {
      return getRouteParam('id');
    }
    return loggedInUserDataStore.loggedInUser.gymId;
  };

  const entityId = getEntityId();

  useEffect(() => {
    getInsuranceCertificates();
  }, []);

  const getInsuranceCertificates = async () => {
    const { insurances } =
      await insuranceCertificatesService.getInsuranceCertificates(entityId);

    setInsuranceCertificates(insurances ?? []);
  };

  const uploadInsuranceCertificate = async (files) => {
    const file = files[0];

    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append('file', file, file.name);

    const certificate = await insuranceCertificatesService.uploadInsuranceCertificate(
      entityId,
      formData,
    );

    setInsuranceCertificates((prevState) => [...prevState, certificate]);
  };

  const deleteInsuranceCertificate = async (id) => {
    await insuranceCertificatesService.deleteInsuranceCertificate(entityId, id);

    const newInsuranceCertificates = deleteItemByField(insuranceCertificates, { id });
    setInsuranceCertificates(newInsuranceCertificates);
  };

  return {
    insuranceCertificates,
    uploadInsuranceCertificate,
    deleteInsuranceCertificate,
  };
};
