'use strict';

angular.module('app').controller('ViewVideoController', ViewVideoController);

ViewVideoController.$inject = ['$scope', 'VideoService', 'ModalService'];

function ViewVideoController($scope, VideoService, ModalService) {
    var vm = this;

    vm.title = $scope.title;
    vm.videos = $scope.videos;

    vm.showDialogWithVideo = showDialogWithVideo;

    function showDialogWithVideo(video) {
        if (video.id) {
            ModalService.showDialogWithVideo(video);
        }
    }
}
