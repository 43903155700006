import { cloneDeep } from 'lodash';

import { CustomFilterData, CustomFilter } from '../../../../interfaces/CustomFilterData';
import { getFilteredTruthyFieldsObj } from '../../../../../../utils/sortingAndGroupingUtil';
import { CustomFilterFormValues } from '../AddCustomFilterForm.settings';

export const transformCustomFiltersFormValuesToData = (
  customFilter: CustomFilterFormValues,
): CustomFilterData => {
  const { filters, ...args } = cloneDeep(customFilter);

  const usedFilters = Object.values(filters).reduce<CustomFilter[]>(
    (filtersList, filter) => {
      const { filterType, ...filterData } = filter;
      const usedFields = getFilteredTruthyFieldsObj(filterData) as any;

      if (usedFields) {
        filtersList.push({ filterType, ...usedFields });
      }

      return filtersList;
    },
    [],
  );

  return {
    ...args,
    filters: usedFilters,
  };
};
