import React from 'react';
import { IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { enqueueSnackbar } from 'notistack';

import classes from './PromoCodeCell.module.scss';
import { copyTextToClipboard } from '../../../../../../shared/utils/apiUtils';
import { TextTruncate } from '../../../../../../shared/components/ui-components';

type PromoCodeCellProps = {
  promoCode: string;
};

const PromoCodeCell = ({ promoCode }: PromoCodeCellProps) => {
  const handleCopy = () => {
    copyTextToClipboard(promoCode);
    enqueueSnackbar('Promo code copied to the clipboard', {
      variant: 'success',
      autoHideDuration: 2000,
    });
  };

  return (
    <div className={classes.container}>
      <TextTruncate text={promoCode} />
      <IconButton color="tertiary" size="small" onClick={handleCopy}>
        <ContentCopyIcon />
      </IconButton>
    </div>
  );
};

export { PromoCodeCell };
