import { useLayoutEffect, useRef, useState } from 'react';

export const useScroll = (maxHeight: number) => {
  const [isScrollShown, setIsScrollShown] = useState(false);
  const boxContentRef = useRef<HTMLDivElement>(null);

  const checkScrollHeight = () => {
    const isScrollHeight = boxContentRef.current?.offsetHeight! >= maxHeight;

    setIsScrollShown(!!maxHeight && isScrollHeight);
  };

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      checkScrollHeight();
    });

    if (boxContentRef.current) {
      resizeObserver.observe(boxContentRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return { isScrollShown, boxContentRef };
};
