import React from 'react';
import PropTypes from 'prop-types';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';

import classes from './StatusCell.module.scss';

const StatusCell = ({ status }) => {
  const icon = status ? (
    <CheckCircleRoundedIcon fontSize="medium" sx={{ fill: 'var(--success-300)' }} />
  ) : (
    <RadioButtonUncheckedRoundedIcon
      fontSize="medium"
      sx={{ fill: 'var(--natural-300)' }}
    />
  );
  return <div className={classes.statusCell}>{icon}</div>;
};

StatusCell.propTypes = {
  status: PropTypes.bool,
};

export { StatusCell };
