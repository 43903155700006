import React from 'react';

import { observer } from 'mobx-react';
import {
  Chat,
  ChannelList,
  Channel,
  ChannelHeader,
  MessageInput,
  MessageList,
  Thread,
  Window,
  LoadingIndicator,
} from 'stream-chat-react';
import { EmojiPicker } from 'stream-chat-react/emojis';
import 'stream-chat-react/dist/css/index.css';

import './LbChat.scss';
import { chatStore } from '../../stores/chatStore';

const LbChat = observer(() => {
  const { user, chatClient, activeChannelId } = chatStore;

  const filters = { type: 'messaging', members: { $in: [user.id] } };
  const sort = { last_message_at: -1 } as const;

  if (!chatClient) return <LoadingIndicator />;

  const customStyles = {
    '--primary-color': 'var(--primary-500)',
  };

  return (
    <Chat client={chatClient} theme="messaging light" customStyles={customStyles}>
      <ChannelList filters={filters} sort={sort} customActiveChannel={activeChannelId} />
      <Channel EmojiPicker={EmojiPicker}>
        <Window>
          <ChannelHeader />
          <MessageList />
          <MessageInput />
        </Window>
        <Thread />
      </Channel>
    </Chat>
  );
});

export { LbChat };
