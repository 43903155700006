(function () {
    'use strict';

    angular.module('app.scoreTable')
        .controller('ScoreTableController', ScoreTableController);

    ScoreTableController.$inject = [
        '$scope',
        'FoodService',
        'ModalService'
    ];

    function ScoreTableController($scope, FoodService, ModalService) {
        var vm = this;

        vm.scoreTypes = [
            {name: "by Day", value: "BY_DAY"},
            {name: "by Meal", value: "BY_MEAL"}
        ];
        vm.nutrientTypes = [
            'All',
            'Carbohydrates',
            'Fats',
            'Protein',
            'Sugar',
            'Sugar_Breakfast',
            'Sugar_Lunch/Dinner',
            'Sugar_Snacks',
            'Fiber',
            'Veggies'
        ];
        vm.selectedScoreType = vm.scoreTypes[0];
        vm.filterNutrient = vm.nutrientTypes[0];
        vm.editedRows = [];
        vm.createdRows = [];
        vm.deletedRows = [];
        vm.scores = [];
        vm.scoreTableForRequest = {
            delete: [],
            insert: [],
            update: []
        };
        vm.isChangedData = false;
        vm.optionsState = false;
        vm.isEditMode = false;
        vm.spinner = {
            active: false
        };

        $scope.$on('$locationChangeStart', function (event) {
            if (vm.isChangedData) {
                var answer = confirm("Are you sure you want to leave this page?");
                if (answer) {
                    vm.isChangedData = false;
                } else {
                    event.preventDefault();
                }
            }
        });

        setTimeout(function () {
            document.getElementsByTagName('md-content')[0].onscroll = function () {
                var header = document.getElementById("score-table-header"),
                    headerElements = document.getElementsByClassName("score-element"),
                    scroll = document.getElementsByTagName('md-content')[0].scrollTop,
                    body = document.getElementsByTagName('body')[0];

                if (scroll > 150) {
                    body.classList.add('for-select-in-header');
                    header.classList.add("fixed-header");
                    for (var i = 0; i < headerElements.length; i++) {
                        headerElements[i].classList.add("visible");
                    }
                } else {
                    body.classList.remove('for-select-in-header');
                    header.classList.remove("fixed-header");
                    for (var j = 0; j < headerElements.length; j++) {
                        headerElements[j].classList.remove("visible");
                    }
                }
            };
        });

        vm.markAllOptionsPanelAsUnchecked = markAllOptionsPanelAsUnchecked;
        vm.markAsEdited = markAsEdited;
        vm.markAsDeleted = markAsDeleted;
        vm.undoDeleting = undoDeleting;
        vm.addNewScore = addNewScore;
        vm.displayAddNewScorePopup = displayAddNewScorePopup;
        vm.displayEditScorePopup = displayEditScorePopup;
        vm.saveScoreTable = saveScoreTable;
        vm.isChangeModeAvailable = isChangeModeAvailable;
        vm.markAsEditInEditMode = markAsEditInEditMode;
        vm.checkErrorInTable = checkErrorInTable;
        vm.checkErrorInRow = checkErrorInRow;
        vm.getScoreObject = getScoreObject;
        vm.getComplianceScoreTable = getComplianceScoreTable;
        vm.compareByNutrientAndRanges = compareByNutrientAndRanges;
        vm.groupByNutrientAndRangeLow = groupByNutrientAndRangeLow;
        vm.calculateSupposedComplianceScores = calculateSupposedComplianceScores;
        vm.findScoreRow = findScoreRow;

        window.onbeforeunload = function () {
            if (vm.isChangedData) {
                return "Are you sure you want to leave this page?";
            }
        };

        getComplianceScoreTable();

        function markAllOptionsPanelAsUnchecked() {
            vm.optionsState = false;

            for (var i in vm.scores) {
                vm.scores[i].checked = false;
            }
        }

        function markAsEdited() {
            if (vm.currentScore.id) {
                vm.currentScore.isEdited = true;
                vm.scores[vm.complianceScoreIndex] = angular.copy(vm.currentScore);
                vm.scores[vm.complianceScoreIndex].checked = false;
            }

            vm.isChangedData = true;
            vm.optionsState = false;
            vm.calculateSupposedComplianceScores(angular.copy(vm.currentScore));
        }

        function markAsDeleted(score, index) {
            score.isDeleted = true;
            vm.isChangedData = true;
            vm.optionsState = false;

            if (!score.id) {
                vm.scores.splice(index, 1);
            }

            vm.calculateSupposedComplianceScores();
        }

        function undoDeleting(score) {
            score.isDeleted = false;
            score.checked = false;

            vm.calculateSupposedComplianceScores();
        }

        function addNewScore() {
            vm.scores.push(vm.currentScore);

            vm.isChangedData = true;
            vm.calculateSupposedComplianceScores(vm.currentScore);
        }

        function displayAddNewScorePopup() {
            vm.currentScore = {
                nutrient: 'Carbohydrates',
                goal_range_low: 0,
                goal_range_high: 0,
                over_under_goal_low: 0,
                over_under_goal_high: 0,
                stars: 0
            };
            ModalService.addNewComplianceScoreDialog(false, vm.currentScore, vm.addNewScore);
        }

        function displayEditScorePopup(score, index) {
            vm.complianceScoreIndex = index;
            vm.currentScore = JSON.parse(JSON.stringify(score));
            ModalService.addNewComplianceScoreDialog(true, vm.currentScore, vm.markAsEdited);
        }

        function saveScoreTable() {
            vm.spinner.active = true;

            for (var i in vm.scores) {
                var currentScore = vm.scores[i];
                if (!currentScore.id) {
                    vm.scoreTableForRequest.insert.push(vm.getScoreObject(currentScore));
                } else if (currentScore.isEdited && !currentScore.isDeleted) {
                    vm.scoreTableForRequest.update.push(vm.getScoreObject(currentScore));
                } else if (currentScore.isDeleted) {
                    vm.scoreTableForRequest.delete.push(currentScore.id);
                }
            }

            FoodService.updateTableForScores(vm.selectedScoreType.value, vm.scoreTableForRequest).then(function (res) {
                vm.spinner.active = false;

                if (res.status === 200) {
                    vm.isChangedData = false;
                    vm.scoreTableForRequest = {
                        delete: [],
                        insert: [],
                        update: []
                    };
                    ModalService.savedChanges(vm.getComplianceScoreTable)
                }
            });
        }

        function isChangeModeAvailable() {
            if (vm.isChangedData) {
                ModalService.confirm('Do you want to save changes?', vm.saveScoreTable, null, null, null, 'Save')
            }
        }

        function markAsEditInEditMode(score, name) {
            if (vm.isEditMode) {
                score.isEdited = true;
                vm.isChangedData = true;

                switch (name) {
                    case 'goal_range_low':
                        score.goal_range_low_error = !(score.goal_range_low >= -1000 && score.goal_range_low <= 1000) || !score.goal_range_low;
                        break;
                    case 'goal_range_high':
                        score.goal_range_high_error = !(score.goal_range_high >= -1000 && score.goal_range_high <= 1000) || !score.goal_range_high;
                        break;
                    case 'over_under_goal_low':
                        score.over_under_goal_low_error = !(score.over_under_goal_low >= -1000 && score.over_under_goal_low <= 1000) || !score.over_under_goal_low;
                        break;
                    case 'over_under_goal_high':
                        score.over_under_goal_high_error = !(score.over_under_goal_high >= -1000 && score.over_under_goal_high <= 1000) || !score.over_under_goal_high;
                        break;
                    case 'stars':
                        score.stars_error = !(score.stars >= 0 && score.stars <= 5) || !score.stars;
                        break;
                    case 'nutrient':
                        score.nutrient_error = !score.nutrient;
                        break;
                }
            }
        }

        function checkErrorInTable() {
            for (var i in vm.scores) {
                if (vm.checkErrorInRow(vm.scores[i])) return true;
            }

            return false;
        }

        function checkErrorInRow(score) {
            return score.nutrient_error || score.goal_range_low_error || score.goal_range_high_error ||
                score.over_under_goal_low_error || score.over_under_goal_high_error || score.stars_error;
        }

        function getScoreObject(score) {
            var res = {
                nutrient: score.nutrient,
                goalRangeLow: score.goal_range_low,
                goalRangeHigh: score.goal_range_high,
                overUnderGoalLow: score.over_under_goal_low,
                overUnderGoalHigh: score.over_under_goal_high,
                stars: score.stars
            };

            if (score.id) {
                res.id = score.id;
            }

            return res;
        }

        function getComplianceScoreTable() {
            vm.spinner.active = true;
            FoodService.getTableForScores(vm.selectedScoreType.value).then(function (res) {
                vm.spinner.active = false;
                if (res.status === 200) {
                    vm.scores = res.data.scores;
                    vm.calculateSupposedComplianceScores(0);
                }
            });
        }

        function compareByNutrientAndRanges(a, b) {
            if (a.nutrient > b.nutrient) return 1;
            if (a.nutrient < b.nutrient) return -1;
            if (a.goal_range_low > b.goal_range_low) return 1;
            if (a.goal_range_low < b.goal_range_low) return -1;
            if (a.goal_range_high > b.goal_range_high) return 1;
            if (a.goal_range_high < b.goal_range_high) return -1;
            if (a.over_under_goal_low > b.over_under_goal_low) return 1;
            if (a.over_under_goal_low < b.over_under_goal_low) return -1;
            if (a.over_under_goal_high > b.over_under_goal_high) return 1;
            if (a.over_under_goal_high < b.over_under_goal_high) return -1;
            return 0;
        }

        function groupByNutrientAndRangeLow(data) {
            var res = {};
            var i = 0;
            data.map(function (value, index) {
                var o = value.nutrient + '-' + value.goal_range_low + '-' + value.goal_range_high;
                if (!(o in res)) {
                    res[o] = [];
                    i++;
                }
                if (!data[index].isDeleted) {
                    res[o].push(data[index]);
                }
            });
            return res;
        }

        function calculateSupposedComplianceScores(score) {
            vm.scores = vm.scores.sort(vm.compareByNutrientAndRanges);

            var groupedScores = vm.groupByNutrientAndRangeLow(vm.scores);
            var scoreKeys = Object.keys(groupedScores);

            scoreKeys.forEach(function (key, i) {
                var value = groupedScores[key];
                var scoresSize = value.length;

                value.map(function (element, i) {
                    element.supposed_range_low = element.goal_range_low;
                    element.supposed_range_high = element.goal_range_high;
                });

                if (i !== 0) {
                    var prevElem = groupedScores[scoreKeys[i - 1]][0];
                    if (prevElem && value[0] && value[0].nutrient === prevElem.nutrient) {
                        value.map(function (element) {
                            element.supposed_range_low = prevElem.goal_range_high + 0.001;
                        });
                    }
                }

                if (scoreKeys.length !== i + 1) {
                    var nextElem = groupedScores[scoreKeys[i + 1]][0];
                    if (nextElem && value[0] && value[0].nutrient === nextElem.nutrient) {
                        value.map(function (element) {
                            element.supposed_range_high = Math.ceil(nextElem.goal_range_low - 0.001);
                        });
                    }
                }

                value.map(function (currentScore, index) {
                    if (index === 0) {
                        if (currentScore.over_under_goal_low >= 0 && currentScore.over_under_goal_high >= 0) {
                            currentScore.supposed_goal_low = currentScore.over_under_goal_low;
                        } else {
                            currentScore.supposed_goal_high = currentScore.over_under_goal_high;
                        }
                    } else {
                        var prevScore = value[index - 1];
                        if (currentScore.over_under_goal_low > 0 && currentScore.over_under_goal_high >= 0) {
                            currentScore.supposed_goal_low = prevScore.over_under_goal_high + 0.001;
                        } else if (currentScore.over_under_goal_low === 0 && currentScore.over_under_goal_high > 0) {
                            currentScore.supposed_goal_low = prevScore.over_under_goal_low + 0.001;
                        } else {
                            currentScore.supposed_goal_high = parseFloat((prevScore.over_under_goal_low + 0.001).toFixed(2));
                        }
                    }

                    if (scoresSize === index + 1) {
                        if (currentScore.over_under_goal_low >= 0 && currentScore.over_under_goal_high >= 0) {
                            currentScore.supposed_goal_high = currentScore.over_under_goal_high;
                        } else {
                            currentScore.supposed_goal_low = currentScore.over_under_goal_low;
                        }
                    } else {
                        var nextScore = value[index + 1];
                        if (currentScore.over_under_goal_low >= 0 && currentScore.over_under_goal_high >= 0) {
                            currentScore.supposed_goal_high = parseFloat((nextScore.over_under_goal_low - 0.001).toFixed(2));
                        } else if (currentScore.over_under_goal_low === -0.001 && currentScore.over_under_goal_high < 0) {
                            currentScore.supposed_goal_low = nextScore.over_under_goal_low - 0.001;
                        } else {
                            currentScore.supposed_goal_low = nextScore.over_under_goal_high - 0.001;
                        }
                    }
                })
            });

            if (score) {
                var rowIndex = findScoreRow(score.nutrient, score.goal_range_low, score.goal_range_high, score.over_under_goal_low, score.over_under_goal_high, score.stars),
                    scoreRow = document.getElementById('scoreRow' + (rowIndex === 0 ? rowIndex : rowIndex - 1));

                if (scoreRow) {
                    var rowPosition = scoreRow.offsetTop - 200;

                    setTimeout(function () {
                        window.scrollTo(0, rowPosition)
                    }, 500);
                }
            } else {
                setTimeout(function () {
                    window.scrollTo(0, 0)
                }, 500);
            }
        }

        function findScoreRow(nutrient, rangeLow, rangeHigh, goalLow, goalHigh, score) {
            for (var i = 0; i < vm.scores.length; i++) {
                var element = vm.scores[i];

                if (element.nutrient === nutrient && element.goal_range_low === rangeLow &&
                    element.goal_range_high === rangeHigh && element.over_under_goal_low === goalLow &&
                    element.over_under_goal_high === goalHigh && element.stars === score) {
                    return i;
                }
            }

            return -1;
        }
    }
})();
