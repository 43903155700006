import { Option } from '../../../general-types';
import { ValueOfKey } from '../../../utils-types';
// replace to module
// use getHumanizeInbodyType utils to get showed name

export const INBODY_TYPES = {
  INBODY_270: 'INBODY_270',
  INBODY_370: 'INBODY_370',
  INBODY_380H: 'INBODY_380H',
  INBODY_570: 'INBODY_570',
  INBODY_580H: 'INBODY_580H',
  INBODY_770: 'INBODY_770',
  INBODY_970: 'INBODY_970',
  NON_INBODY: 'NON_INBODY',
} as const;

export type InbodyType = ValueOfKey<typeof INBODY_TYPES>;

// used on old api (entity info supportedInbodyTypes) prefer use InbodyType
export type InbodyTypeKey =
  | 'inbody_270'
  | 'inbody_370'
  | 'inbody_380H'
  | 'inbody_570'
  | 'inbody_580H'
  | 'inbody_770'
  | 'inbody_970';

export const inbodyTypeOptions: Option<InbodyType>[] = [
  { value: 'INBODY_270', label: 'Inbody Machine 270' },
  { value: 'INBODY_370', label: 'Inbody Machine 370' },
  { value: 'INBODY_380H', label: 'Inbody Machine 380H' },
  { value: 'INBODY_570', label: 'Inbody Machine 570' },
  { value: 'INBODY_580H', label: 'Inbody Machine 580H' },
  { value: 'INBODY_770', label: 'Inbody Machine 770' },
  { value: 'INBODY_970', label: 'Inbody Machine 970' },
  { value: 'NON_INBODY', label: 'Non-Inbody' },
];

export const inbodyTypeOptionsWithAll: Option<InbodyType | ''>[] = [
  { value: '', label: 'All' },
  ...inbodyTypeOptions,
];

export const humanizeInbodyType: Record<InbodyType, string> = {
  INBODY_270: 'Inbody Machine 270',
  INBODY_370: 'Inbody Machine 370',
  INBODY_380H: 'Inbody Machine 380H',
  INBODY_570: 'Inbody Machine 570',
  INBODY_580H: 'Inbody Machine 580H',
  INBODY_770: 'Inbody Machine 770',
  INBODY_970: 'Inbody Machine 970',
  NON_INBODY: 'Non Inbody',
};
