import { PAYMENT_TYPES } from '../../../../../../constants/PAYMENT_TYPES';

export const getSelectorLabel = (paymentType, selectorLabel) => {
  const { OLD, NEW } = PAYMENT_TYPES;
  switch (paymentType) {
    case OLD:
      return `Select Old Flow ${selectorLabel}`;
    case NEW:
      return `Select New Flow ${selectorLabel}`;
    default:
      return `Select ${selectorLabel}`;
  }
};
