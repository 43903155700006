import React from 'react';
import UpdateRoundedIcon from '@mui/icons-material/UpdateRounded';
import { observer } from 'mobx-react';

import { UserMenuItem } from '../UserMenuItem/UserMenuItem';
import { goTo } from '../../../../../utils/angularUtils';
import { releasesInfoStore } from '../../../../../stores/releasesInfoStore';
import { loggedInUserDataStore } from '../../../../../stores/loggedInUserDataStore';

const ReleasesItem = observer(() => {
  const { corporateSignInStatuses } = loggedInUserDataStore;

  if (corporateSignInStatuses.UNFINISHED_SING_IN) {
    return null;
  }

  const openReleasesPage = () => {
    goTo('/releases');
    releasesInfoStore.closeReleasesInfo();
  };

  return (
    <UserMenuItem
      label="Releases"
      onClick={openReleasesPage}
      startIcon={<UpdateRoundedIcon />}
    />
  );
});

export { ReleasesItem };
