import React from 'react';

import { Control } from 'react-hook-form';

import classes from './FeeModulesCheckboxGroup.module.scss';
import { FeeModuleType } from '../../../interfaces/paymentBundleInfo';
import { LbCheckbox } from '../../../../shared/components/form-components';
import { FeeFormModule, FeeModuleSelectFormValues } from '../FeeModuleWidget.settings';
import { Mode } from '../../../../general-types';

type FeeModulesCheckboxGroupProps = {
  control: Control<FeeModuleSelectFormValues>;
  modules: FeeFormModule[];
  moduleType: FeeModuleType;
  mode: Mode;
};

const FeeModulesCheckboxGroup = ({
  control,
  modules,
  moduleType,
  mode,
}: FeeModulesCheckboxGroupProps) => {
  const modulesGroup = modules.filter((module) => {
    const isSelectedModuleType = module.type === moduleType;
    const isHide = module.disabled && !module.selected;

    return isSelectedModuleType && !isHide;
  });

  return (
    <div className={classes.checkboxGroup}>
      {modulesGroup.map((module) => (
        <LbCheckbox
          control={control}
          key={module.formKey}
          name={module.formKey}
          disabled={module.disabled || mode === 'VIEW'}
          defaultChecked={module.selected}
          label={module.text}
          color="primary"
        />
      ))}
    </div>
  );
};

export { FeeModulesCheckboxGroup };
