const autoNgTemplateLoaderTemplate1 = require('./coach-profile.template.html');
const autoNgTemplateLoaderTemplate2 = require('./coach-profile.template.html');

'use strict';

(function () {
    angular
        .module('app.coachProfile', [])
        .config(coachProfileConfig);

    coachProfileConfig.$inject = ['$routeProvider'];

    function coachProfileConfig($routeProvider) {
        $routeProvider
            .when('/coach-profile', {
                disableCache: true,
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'CoachProfileController',
                controllerAs: 'vm',
                resolve: {
                    loginRequired: ['AuthService', function (AuthService) {
                        return AuthService.loginRequired();
                    }]
                }
            })
            .when('/coach-profile/notes', {
                disableCache: true,
                templateUrl: autoNgTemplateLoaderTemplate2,
                controller: 'CoachProfileController',
                controllerAs: 'vm',
                resolve: {
                    loginRequired: ['AuthService', function (AuthService) {
                        return AuthService.loginRequired();
                    }]
                }
            })
    }
})();
