import { ValueOfKey } from '../../../utils-types';

// TODO replace to inbody module
// TODO update delete unused fields delete unused fields on inbodyDataConfig
const INBODY_DATA_FIELD = {
  BMR: 'bmr',
  HEIGHT: 'height',
  WEIGHT: 'weight',
  GENDER: 'gender', // not used confirm to delete
  PERCENT_BODY_FAT: 'percentBodyFat',
  POUND_OF_FAT: 'poundOfFat',
  BODY_FAT_METHOD: 'bodyFatMethod',
  LBM: 'lbm',
  ICW: 'icw',
  ECW: 'ecw',
  TBW: 'tbw',
  DLM: 'dlm',
  PHASE_ANGLE: 'phaseAngle',
  SMM: 'smm',
  BMI: 'bmi',
  CHEST: 'chest',
  HIP: 'hip',
  THIGH: 'thigh',
  NAVEL: 'navel',
  BICEP: 'bicep',
  NECK: 'neck',
  ECW_TBW: 'ecwTbw',
} as const;

export type InbodyDataField = ValueOfKey<typeof INBODY_DATA_FIELD>;

const INBODY_DATA_GROUP = {
  REQUIRED: 'REQUIRED',
  OPTIONAL: 'OPTIONAL',
  MEASUREMENTS: 'MEASUREMENTS',
} as const;

export type InbodyDataGroup = ValueOfKey<typeof INBODY_DATA_GROUP>;

export type InbodyConfigInfo = {
  dbName: InbodyDataField;
  name: string;
  fullName: string;
  shortName: string;
  title: string;
  group: InbodyDataGroup;
  required?: boolean;
  requiredFields?: InbodyDataField[];
  defaultChecked?: boolean;
};

export const inbodyDataConfig: Record<InbodyDataField, InbodyConfigInfo> = {
  bmr: {
    dbName: 'bmr',
    name: 'BMR (Basal Metabolic Rate)',
    fullName: 'Basal metabolic rate (kcal)',
    shortName: 'BMR (kcal)',
    title: 'Basal Metabolic Rate (kcal)',
    group: 'OPTIONAL',
  },
  height: {
    dbName: 'height',
    name: 'Height',
    fullName: 'Height (inches)',
    shortName: 'Height (in)',
    title: 'Height (inches)',
    required: true,
    group: 'REQUIRED',
  },
  weight: {
    dbName: 'weight',
    name: 'Weight',
    fullName: 'Weight (lbs)',
    shortName: 'Weight (lbs)',
    title: 'Weight (lbs)',
    required: true,
    group: 'REQUIRED',
  },
  gender: {
    dbName: 'gender',
    name: 'Gender',
    fullName: 'Gender',
    shortName: 'Gender',
    title: 'Gender',
    required: true,
    group: 'REQUIRED',
  },
  percentBodyFat: {
    dbName: 'percentBodyFat',
    name: 'Body Fat %',
    fullName: 'Pct body fat (%)',
    shortName: 'Body fat (%)',
    title: 'Percent Body Fat (%)',
    required: true,
    group: 'REQUIRED',
  },
  bodyFatMethod: {
    dbName: 'bodyFatMethod',
    name: 'Body Fat Method',
    fullName: 'Body Fat Method',
    shortName: 'Body Fat Method',
    title: 'Body Fat Method',
    group: 'REQUIRED',
    requiredFields: ['percentBodyFat'],
  },
  poundOfFat: {
    dbName: 'poundOfFat',
    title: 'Pound of Fat',
    fullName: 'Pound of Fat',
    shortName: 'Pound of Fat',
    name: 'Pound of Fat',
    group: 'REQUIRED',
    defaultChecked: true,
  },
  lbm: {
    dbName: 'lbm',
    name: 'LBM',
    fullName: 'Lean body mass (lbs)',
    shortName: 'LBM (lbs)',
    title: 'Lean Body Mass (lbs)',
    group: 'REQUIRED',
    defaultChecked: true,
  },
  icw: {
    dbName: 'icw',
    name: 'ICW (Intracellular Water)',
    fullName: 'Intracellular water (lbs)',
    shortName: 'ICW (lbs)',
    title: 'Intracellular Water (lbs)',
    group: 'OPTIONAL',
  },
  ecw: {
    dbName: 'ecw',
    name: 'ECW (Extracellular Water)',
    fullName: 'Extracellular water (lbs)',
    shortName: 'ECW (lbs)',
    title: 'Extracellular Water (lbs)',
    group: 'OPTIONAL',
  },
  tbw: {
    dbName: 'tbw',
    name: 'TBW (Total Body Water)',
    fullName: 'Total body water (lbs)',
    shortName: 'TBW (lbs)',
    title: 'Total Body Water (lbs)',
    group: 'OPTIONAL',
    requiredFields: ['icw', 'ecw'],
  },
  ecwTbw: {
    dbName: 'ecwTbw',
    name: 'ECW/TBW',
    fullName: 'ECW/TBW (ratio)',
    shortName: 'ECW/TBW (r)',
    title: 'ECW/TBW (ratio)',
    group: 'OPTIONAL',
    requiredFields: ['ecw', 'tbw'],
  },
  dlm: {
    dbName: 'dlm',
    name: 'DLM (Dry Lean Mass)',
    fullName: 'Dry lean mass (lbs)',
    shortName: 'DLM (lbs)',
    title: 'Dry Lean Mass (lbs)',
    group: 'OPTIONAL',
  },
  phaseAngle: {
    dbName: 'phaseAngle',
    name: 'Phase Angle',
    fullName: 'Phase Angle',
    shortName: 'Phase Angle',
    title: 'Phase Angle',
    group: 'OPTIONAL',
  },
  smm: {
    dbName: 'smm',
    name: 'SMM (Skeletal Muscle Mass)',
    fullName: 'Skeletal muscle mass (lbs)',
    shortName: 'SMM (lbs)',
    title: 'Skeletal Muscle Mass (lbs)',
    group: 'OPTIONAL',
  },
  bmi: {
    dbName: 'bmi',
    name: 'BMI (Body Mass Index)',
    fullName: 'Body mass index (kg/m²)',
    shortName: 'BMI (kg/m²)',
    title: 'Body Mass Index (kg/m²)',
    group: 'OPTIONAL',
  },
  chest: {
    dbName: 'chest',
    name: 'Chest',
    fullName: 'Chest circumference (inches)',
    shortName: 'Chest (in)',
    title: 'Chest Circumference (inches)',
    group: 'MEASUREMENTS',
  },
  hip: {
    dbName: 'hip',
    name: 'Hip',
    fullName: 'Hip circumference (inches)',
    shortName: 'Hip (in)',
    title: 'Hip Circumference (inches)',
    group: 'MEASUREMENTS',
  },
  thigh: {
    dbName: 'thigh',
    name: 'Thigh',
    fullName: 'Thigh circumference (inches)',
    shortName: 'Thigh (in)',
    title: 'Thigh Circumference (inches)',
    group: 'MEASUREMENTS',
  },
  navel: {
    dbName: 'navel',
    name: 'Navel (Waist)',
    fullName: 'Navel circumference (inches)',
    shortName: 'Navel (in)',
    title: 'Navel Circumference (inches)',
    group: 'MEASUREMENTS',
  },
  bicep: {
    dbName: 'bicep',
    name: 'Bicep (Arm)',
    fullName: 'Bicep circumference (inches)',
    shortName: 'Bicep (in)',
    title: 'Bicep Circumference (inches)',
    group: 'MEASUREMENTS',
  },
  neck: {
    dbName: 'neck',
    name: 'Neck',
    fullName: 'Neck',
    shortName: 'Neck',
    title: 'Neck',
    group: 'MEASUREMENTS',
  },
} as const;
