import {
  MainNutrientMetricsInfo,
  NutrientMetrics,
  SecondaryNutrientMetricsInfo,
  FiberMetricsInfo,
  MainNutrientMealsInfo,
  SecondaryNutrientMealsInfo,
  FiberMealsInfo,
} from '../../../../../interfaces/performance-metrics/NutrientMetrics';

const mainNutrientInfo: MainNutrientMetricsInfo = {
  avgGoal: 0,
  avgTracked: 0,
  avgTrackedPercent: 0,
};

const secondaryNutrientInfo: SecondaryNutrientMetricsInfo = {
  avgPerMeal: 0,
};

const mainMealInfo: MainNutrientMealsInfo = {
  breakfast: mainNutrientInfo,
  snack1: mainNutrientInfo,
  lunch: mainNutrientInfo,
  snack2: mainNutrientInfo,
  dinner: mainNutrientInfo,
  snack3: mainNutrientInfo,
  avgOnMealsTracked: mainNutrientInfo,
};

const secondaryMealInfo: SecondaryNutrientMealsInfo = {
  breakfast: secondaryNutrientInfo,
  snack1: secondaryNutrientInfo,
  lunch: secondaryNutrientInfo,
  snack2: secondaryNutrientInfo,
  dinner: secondaryNutrientInfo,
  snack3: secondaryNutrientInfo,
  avgOnMealsTracked: secondaryNutrientInfo,
};

const fiberInfo: FiberMetricsInfo = {
  avgPerMeal: 0,
  totalMeals: 0,
  completedMeals: 0,
  completedMealsPercent: 0,
};

const fiberMealInfo: FiberMealsInfo = {
  breakfast: fiberInfo,
  snack1: fiberInfo,
  lunch: fiberInfo,
  snack2: fiberInfo,
  dinner: fiberInfo,
  snack3: fiberInfo,
  avgOnMealsTracked: fiberInfo,
};

export const defaultNutrientMetrics: NutrientMetrics = {
  proteins: mainMealInfo,
  carbs: mainMealInfo,
  fats: mainMealInfo,
  sodium: secondaryMealInfo,
  sugar: secondaryMealInfo,
  veggies: secondaryMealInfo,
  calories: secondaryMealInfo,
  fiber: fiberMealInfo,
};
