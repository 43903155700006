import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import classes from './GoalCell.module.scss';
import { getGoalStatus } from './settings/getGoalStatus';
import { getRoundedNumberOrDash } from '../../../../shared/utils/mathUtils';

const GoalCell = ({ actual, goal, goalIsLess, isInteractive }) => {
  const actualStatus = getGoalStatus(actual, goal, goalIsLess);
  const actualClasses = classNames({ [classes[actualStatus]]: isInteractive });

  return (
    <p className={classes.cellBox}>
      <span className={actualClasses}>{getRoundedNumberOrDash(actual)}</span>
      <span> / {getRoundedNumberOrDash(goal)}</span>
    </p>
  );
};

GoalCell.propTypes = {
  actual: PropTypes.number,
  goal: PropTypes.number,
  goalIsLess: PropTypes.bool,
  isInteractive: PropTypes.bool,
};

export { GoalCell };
