'use strict';

angular.module('app.addVideo').controller('AddVideoController', AddVideoController);

AddVideoController.$inject = [
    'VideoService',
    'ModalService'
];

function AddVideoController(VideoService, ModalService) {
    var vm = this;
    vm.videoTypeTab = 'Success factors';
    vm.allowedVideoTypes = ['Explainer videos', 'Training videos', 'Success factors', 'FAQ'];
    vm.isPositionChanged = false;
    vm.spinner = {active: false};

    vm.getAllVideos = getAllVideos;
    vm.processAllVideos = processAllVideos;
    vm.deleteVideoById = deleteVideoById;
    vm.saveVideos = saveVideos;
    vm.formVideoResultArray = formVideoResultArray;
    vm.addNewVideo = addNewVideo;
    vm.makeVideoEditable = makeVideoEditable;
    vm.isSaveBtnActive = isSaveBtnActive;
    vm.changeVideoState = changeVideoState;
    vm.showDialogWithVideo = showDialogWithVideo;

    function getAllVideos() {
        vm.videos = [];
        VideoService.getAllVideos().then(function (res) {
            if (res.status === 200) {
                vm.processAllVideos(res);
            }
        });
    }

    function processAllVideos(result) {
        var categories = result.data.categories;
        for (var i in categories) {
            if (vm.videoTypeTab === categories[i].name) {
                switch (vm.videoTypeTab) {
                    case 'Success factors':
                    case 'Explainer videos':
                    case 'Training videos':
                    case 'FAQ':
                        vm.videos = categories[i].videos.sort(VideoService.compareVideos);
                        break;
                }
            }
        }

        for (var j in vm.videos) {
            vm.videos[j].isActiveOptions = false;
            vm.videos[j].isEditable = false;
            if (!vm.videos[j].position) {
                vm.videos[j].position = j;
            }
        }
    }

    function deleteVideoById(index) {
        if (vm.videos[index].id) {
            VideoService.deleteVideoById(vm.videos[index].id).then(function (res) {
                if (res.status === 200) {
                    vm.videos.splice(index, 1);
                }
            })
        } else {
            vm.videos.splice(index, 1);
        }
    }

    function saveVideos() {
        vm.spinner.active = true;
        VideoService.saveVideos(vm.formVideoResultArray()).then(function (res) {
            vm.isPositionChanged = false;
            vm.spinner.active = false;
            if (res.status === 200) {
                vm.processAllVideos(res);
            }
        })
    }

    function formVideoResultArray() {
        var res = [];

        for (var i in vm.videos) {
            var currVideo = vm.videos[i];

            if (currVideo.isEditable || vm.isPositionChanged) {
                var newVideo = {
                    position: parseInt(i),
                    title: currVideo.title,
                    image: currVideo.image,
                    youtubevideo: currVideo.youtubevideo,
                    category: vm.videoTypeTab,
                    isDisplayed: currVideo.isDisplayed
                };

                if (currVideo.id) {
                    newVideo.id = currVideo.id;
                }

                res.push(newVideo);
            }
        }

        return {videos: res};
    }

    function addNewVideo() {
        var indicator = false;

        for (var i in vm.videos) {
            if (vm.videos[i].youtubevideo === '' || vm.videos[i].title === '') {
                indicator = true;
            }
        }

        if (!indicator) {
            vm.videos.push({
                position: vm.videos.length,
                title: '',
                image: '../images/vectors/empty-preview-small.png',
                youtubevideo: '',
                isEditable: true,
                isDisplayed: false,
                isDisplayedChange: false
            });
        }

        setTimeout(function () {
            window.scrollTo(0, document.body.scrollHeight);
        }, 200);
    }

    function makeVideoEditable(video) {
        video.isEditable = true;
    }

    function isSaveBtnActive() {
        for (var i in vm.videos) {
            if (vm.videos[i].isEditable || vm.isPositionChanged) {
                return true;
            }
        }

        return false;
    }

    function changeVideoState(video) {
        video.isEditable = true;
    }

    function showDialogWithVideo(video) {
        if (video.id) {
            ModalService.showDialogWithVideo(video);
        }
    }
}
