(function () {
  'use strict';

  angular.module('app.childEntityList').controller('ChildEntityListController', ChildEntityListController);

  ChildEntityListController.$inject = ['EntityService', 'utilService', 'AuthService', '$rootScope', 'localStorageService'];

  function ChildEntityListController(EntityService, utilService, AuthService, $rootScope, localStorageService) {
    const vm = this;

    vm.spinner = { active: false };
    vm.entitiesList = [];

    vm.init = init;
    vm.getChildEntityList = getChildEntityList;
    vm.selectEntity = selectEntity;
    vm.openEntity = openEntity;
    vm.openChildEntity = openChildEntity;

    vm.init();

    function init() {
      vm.getChildEntityList();
    }

    function getChildEntityList() {
      vm.spinner.active = true;
      EntityService.getChildEntitiesInfo()
        .then(res => {
          if (res.status === 200) {
            vm.entitiesList = res.data;
          }
        })
        .finally(() => vm.spinner.active = false);
    }

    function selectEntity(index) {
      const prevIndex = vm.entitiesList.findIndex(item => item.selected);
      if (prevIndex !== -1 && prevIndex !== index) {
        vm.entitiesList[prevIndex].selected = false;
      }
      vm.entitiesList[index].selected = !vm.entitiesList[index].selected;
    }
    
    function openEntity(entity) {
      if (entity.parentEntityId) {
        vm.openChildEntity(entity.adminId);
      } else {
        utilService.goTo('/entity-dashboard').replace();
      }
    }

    function openChildEntity(adminId) {
      vm.spinner.active = true;
      AuthService.parentToChildLogin(adminId).finally(() => vm.spinner.active = false)
    }
  }
})();
