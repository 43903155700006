import { ValueOfKey } from '../../utils-types';

export const QUESTION_TYPE = {
  ACTIVITY_INDEX: 'ACTIVITY_INDEX',
  WORKOUTS_PER_WEEK: 'WORKOUTS_PER_WEEK',
  MULTIPLYING_FACTOR_1: 'MULTIPLYING_FACTOR_1',
  MULTIPLYING_FACTOR_2: 'MULTIPLYING_FACTOR_2',
  COMMON: 'COMMON',
  GOAL: 'GOAL',
  OPTIONAL: 'OPTIONAL',
} as const;

export type QuestionType = ValueOfKey<typeof QUESTION_TYPE>;

export const GOAL_ANSWER_TYPE = {
  FAT_LOSS_AND_MUSCLE_GAIN: 'A',
  FAT_LOSS_AND_MUSCLE_LOSS: 'B',
  GAIN_WEIGHT_HEAVY_BULK: 'C',
  GAIN_WEIGHT_LEAN_BULK: 'D',
} as const;
