import React from 'react';

import { angularize } from 'react-in-angularjs';

import { SystemProviders } from '../../../../../../shared/providers';
import {
  BodyMetrics,
  getMetrics,
} from '../../../../customer-setup-flow-2024/containers/GoalsStep/utils/getMetrics';
import { BodyMetricsTable } from '../../../../customer-setup-flow-2024/containers/GoalsStep/BodyMetricsTable/BodyMetricsTable';

const NgzBodyCompositionMetrics = ({
  currentMetrics,
  goalMetrics,
}: {
  currentMetrics: BodyMetrics;
  goalMetrics: BodyMetrics;
}) => {
  const metrics = getMetrics(currentMetrics, goalMetrics);

  return (
    <SystemProviders>
      <BodyMetricsTable {...metrics} />
    </SystemProviders>
  );
};

angularize(
  NgzBodyCompositionMetrics,
  'ngzBodyCompositionMetrics',
  angular.module('app.customerManagement'),
  {
    currentMetrics: '<',
    goalMetrics: '<',
  },
);

export { NgzBodyCompositionMetrics };
