import { Components } from '@mui/material';

export const MuiAppBarTheme: Components['MuiAppBar'] = {
  defaultProps: {
    position: 'relative',
  },
  styleOverrides: {
    colorPrimary: {
      backgroundColor: 'var(--shades-900)',
      // fix hide heder when spinner shows
      position: 'relative',
      zIndex: 3,
      boxShadow: 'none',
    },
  },
};

export const MuiToolbarTheme: Components['MuiToolbar'] = {
  defaultProps: {
    variant: 'header',
  },
  variants: [
    {
      props: { variant: 'header' },
      style: {
        minHeight: 'var(--header-height)',
        justifyContent: 'space-between',
      },
    },
  ],
};

declare module '@mui/material/Toolbar' {
  interface ToolbarPropsVariantOverrides {
    header: true;
  }
}
