import React, { MouseEvent } from 'react';
import PropTypes from 'prop-types';

import { getService } from 'react-in-angularjs';
import { Button, SvgIcon } from '@mui/material';

import { ReactComponent as ChatIcon } from '../../../../../../assets/images/tabs/communication.svg';
import { communicationModalStore } from '../../../../chat/stores/communicationModalStore';
import { SendMessageResourceType } from '../../../../chat/interfaces/SendMessageData';

type CustomerContactLinkProps = {
  customerId: number;
  className?: string;
  useReactModal?: boolean;
} & {
  useReactModal: true;
  resourceType: SendMessageResourceType;
};

const CustomerContactLink = ({
  customerId,
  className,
  useReactModal,
  resourceType,
}: CustomerContactLinkProps) => {
  const openAngularModal = () => {
    const ModalService = getService('ModalService');

    ModalService.sendMessage(
      { via: 'push', userIds: [customerId] },
      [customerId],
      [customerId],
      true,
    );
  };

  const openReactModal = () => {
    communicationModalStore.openCommunicationModal({
      customerIds: [customerId],
      customersCount: 1,
      resourceType,
    });
  };

  const handelOpenSendMessageDialog = (event: MouseEvent) => {
    event.stopPropagation();

    if (useReactModal) {
      openReactModal();
    } else {
      openAngularModal();
    }
  };

  return (
    <Button
      className={className}
      variant="link"
      disableRipple
      onClick={handelOpenSendMessageDialog}
    >
      <SvgIcon component={ChatIcon} inheritViewBox fontSize="extraLarge" />
      <span>Contact</span>
    </Button>
  );
};

CustomerContactLink.propTypes = {
  customerId: PropTypes.number,
  className: PropTypes.string,
};

export { CustomerContactLink };
