import React from 'react';

import classes from './UserList.module.scss';
import {
  OpenCloseArrow,
  ScrollBox,
} from '../../../../../shared/components/ui-components';
import { NotGotMessageUser } from '../../../../interfaces/NotGotMessageUser';

type UserListProps = {
  isShowUserList: boolean;
  onInfoClick: () => void;
  reason: string;
  users: NotGotMessageUser[];
};

const UserList = ({ isShowUserList, onInfoClick, reason, users }: UserListProps) => {
  return (
    <div>
      <div className={classes.errorInfo} onClick={onInfoClick}>
        <OpenCloseArrow open={isShowUserList} />
        <p>
          {reason} ({users.length})
        </p>
      </div>
      <ScrollBox variant="inBox" maxHeight={300}>
        {isShowUserList && (
          <ul>
            {users.map((user) => (
              <li className={classes.userInfo} key={user.userId}>
                {user.firstName} {user.lastName}
              </li>
            ))}
          </ul>
        )}
      </ScrollBox>
    </div>
  );
};

export { UserList };
