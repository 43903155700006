'use strict';

import { getLoginRequiredResolveObj } from '../shared/utils/angularUtils';

export const foodScoreModule = angular
  .module('app.foodScore', [])
  .config(foodScoreConfig);

foodScoreConfig.$inject = ['$routeProvider'];

function foodScoreConfig($routeProvider) {
  $routeProvider.when('/food-score', {
    template: `<ngz-food-score></ngz-food-score>`,
    resolve: getLoginRequiredResolveObj(),
  });
}
