import React from 'react';

import { observer } from 'mobx-react';
import { useController, useFormContext } from 'react-hook-form';
import { RadioGroup } from '@mui/material';

import classes from './SelectMealPlanStep.module.scss';
import { GeneralInfoRow } from './GeneralInfoRow/GeneralInfoRow';
import { MealPlanTitleRow } from './MealPlanTitleRow/MealPlanTitleRow';
import { MealRow } from './MealRow/MealRow';
import { setupScholasticCustomerStore } from '../../../../stores/setupScholasticCustomerStore';
import { ScholasticCustomerSetupFormValue } from '../../NgzScholasticUserSetup.settings';
import { mealPlanService } from '../../../../services/mealPlanService';
import { ButtonsRow } from '../../components/ButtonRow/ButtonRow';

const SelectMealPlanStep = observer(() => {
  const { control, handleSubmit } = useFormContext<ScholasticCustomerSetupFormValue>();

  const { userId, stepperMethods, predefinedMealPlansInfo } =
    setupScholasticCustomerStore;
  const { goToNextStep } = stepperMethods;

  const { predefinedMealPlanDTO, userDataMealPlanDTO } = predefinedMealPlansInfo!;

  const firstMealPlanId = predefinedMealPlanDTO[0].id;

  const { field } = useController({
    control,
    name: 'predefinedMealPlan.predefinedMealPlanId',
    defaultValue: firstMealPlanId,
    rules: { required: true },
  });

  const onSubmit = async ({ predefinedMealPlan }: ScholasticCustomerSetupFormValue) => {
    const { predefinedMealPlanId } = predefinedMealPlan;

    await mealPlanService.setPredefinedMealPlans(userId!, predefinedMealPlanId);

    goToNextStep();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GeneralInfoRow customerGeneralInfo={userDataMealPlanDTO} />
      <div className={classes.mealPlanTable}>
        <MealPlanTitleRow />
        <RadioGroup {...field}>
          {predefinedMealPlanDTO.map((mealPlan) => (
            <MealRow mealPlan={mealPlan} key={mealPlan.id} />
          ))}
        </RadioGroup>
      </div>
      <ButtonsRow showBack />
    </form>
  );
});

export { SelectMealPlanStep };
