import React, { ReactNode } from 'react';

import classNames from 'classnames';

import classes from './GridTableCell.module.scss';

type GridTableCellProps = {
  children: ReactNode;
  className?: string;
  justify?: 'center' | 'end' | 'start' | 'stretch' | 'auto';
  variant?: 'title' | 'cell';
  dataTestId?: string;
};

const GridTableCell = ({
  children,
  className,
  justify = 'auto',
  variant = 'cell',
  dataTestId,
}: GridTableCellProps) => {
  const cellClasses = classNames(
    classes[`justify-${justify}`],
    classes[variant],
    className,
  );

  return (
    <div className={cellClasses} data-test-id={dataTestId}>
      {children}
    </div>
  );
};

export { GridTableCell };
