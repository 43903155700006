import { Button } from '@mui/material';
import React from 'react';
import { useToggle } from 'react-use';

import { observer } from 'mobx-react';
import { ManageSeatsModal } from './ManageSeatsModal/ManageSeatsModal';
import { freeAccessStore } from '../../../stores/freeAccessStore';

type ManageSeatsButtonProps = {
  entityId: number;
};

const ManageSeatsButton = observer(({ entityId }: ManageSeatsButtonProps) => {
  const [showManageSeatsModal, toggleShowManageSeatsModal] = useToggle(false);

  const { freeSeatsInfo, setFreeSeatsLimit } = freeAccessStore;

  const { freeSeatsCount, freeSeatsUsed } = freeSeatsInfo;

  const handleSetFreeSeatsLimit = async (seatsLimit: number) => {
    await setFreeSeatsLimit(seatsLimit, entityId);
  };

  return (
    <div>
      <Button variant="outlined" size="small" onClick={toggleShowManageSeatsModal}>
        Manage seats
      </Button>
      {showManageSeatsModal && (
        <ManageSeatsModal
          freeSeatsDefaultValue={freeSeatsCount}
          freeSeatsMinValue={freeSeatsUsed}
          onClose={toggleShowManageSeatsModal}
          onSubmit={handleSetFreeSeatsLimit}
        />
      )}
    </div>
  );
});

export { ManageSeatsButton };
