import { useLayoutEffect, useRef, useState } from 'react';

export const useGridRowHeight = () => {
  const cellRef = useRef<HTMLDivElement>(null);

  const [rowHeight, setRowHeight] = useState<number>(0);

  useLayoutEffect(() => {
    if (!cellRef.current) {
      throw new Error('Ref is not connected to an element');
    }

    const parentElement = cellRef.current.closest('.tableGridLayout');

    if (!parentElement) {
      throw new Error('GridStickyGroup should be placed inside a grid row');
    }

    const resizeObserver = new ResizeObserver(() => {
      setRowHeight(parentElement?.clientHeight ?? 0);
    });

    resizeObserver.observe(parentElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return { rowHeight, cellRef };
};
