import { makeAutoObservable } from 'mobx';

import { CustomerQuestionReply } from '../interfaces/QuestionnaireInfo';
import { questionnaireService } from '../services/questionnaireService';
import { resetAllStore } from '../../shared/stores/resetAllStore';
import { QuestionType } from '../constants/question';

class QuestionnaireReplyStore {
  questionReply: CustomerQuestionReply[] = [];
  createdOn: string | null = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.questionReply = [];
    this.createdOn = null;
  }

  async getCustomerQuestionnaire(customerId: number) {
    const { createdOn, userQuestionaryAnswers } =
      await questionnaireService.getCustomerQuestionnaire(customerId);

    this.setQuestionReply(userQuestionaryAnswers);
    this.setCreatedOn(createdOn);
  }

  getQuestionReplyByType(questionType: QuestionType) {
    return this.questionReply.find((question) => question.questionType === questionType);
  }

  private setQuestionReply(questionReply: CustomerQuestionReply[]) {
    this.questionReply = questionReply;
  }

  private setCreatedOn(createdOn: string) {
    this.createdOn = createdOn;
  }
}

const questionnaireReplyStore = new QuestionnaireReplyStore();
resetAllStore.addResetMethod(questionnaireReplyStore.setDefaultValues);

export { questionnaireReplyStore, QuestionnaireReplyStore };
