import React from 'react';

import classNames from 'classnames';

import classes from './FailedUploadScan.module.scss';
import DisconnectedImg from '../../../shared/assets/image/disconnected.svg';

type FailedUploadScanProps = {
  className?: string;
};

const FailedUploadScan = ({ className }: FailedUploadScanProps) => {
  const boxClasses = classNames(classes.box, className);

  return (
    <div className={boxClasses}>
      <img className={classes.img} src={DisconnectedImg} alt="Disconnected" />
      <h3 className={classes.title}>Scan upload failed</h3>
      <p className={classes.description}>Please, load scan again or fill in manually</p>
    </div>
  );
};

export { FailedUploadScan };
