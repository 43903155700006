import React from 'react';

import { Button } from '@mui/material';

import { ResultTitle } from '../components/ResultTitle/ResultTitle';
import { InfoContainer } from '../components/InfoContainer/InfoContainer';

type ErrorInfoProps = {
  retryPath?: string;
};

const ErrorInfo = ({ retryPath }: ErrorInfoProps) => {
  return (
    <InfoContainer>
      <ResultTitle
        isSuccessful={false}
        title="Something went wrong"
        description={
          <span>
            Sorry, we&apos;re having some trouble completing your request right now.
            <br />
            Please try again
          </span>
        }
      />
      {retryPath && (
        <Button variant="outlined" href={`#!${retryPath}`}>
          Start filling out the form again
        </Button>
      )}
    </InfoContainer>
  );
};

export { ErrorInfo };
