import React from 'react';

import { Path, UseControllerProps } from 'react-hook-form';

import { LbNumericFormatInput } from '../../../../../shared/components/form-components';
import { ViewSubscriptionInput } from './ViewSubscriptionInput/ViewSubscriptionInput';
import { SubscriptionSettingsFormValues } from '../../../../interfaces/subscriptionSettings';
import { Mode } from '../../../../../general-types';

type SubscriptionSettingsInputProps<TName extends Path<SubscriptionSettingsFormValues>> =
  {
    mode: Mode;
    label: string;
    helperText?: string;
    prefix?: string;
    suffix?: string;
    decimalScale?: number;
    allowNegative?: boolean;
  } & Pick<
    UseControllerProps<SubscriptionSettingsFormValues, TName>,
    'control' | 'name' | 'rules'
  >;

const SubscriptionSettingsInput = <TName extends Path<SubscriptionSettingsFormValues>>({
  mode,
  rules,
  ...props
}: SubscriptionSettingsInputProps<TName>) => {
  return mode === 'EDIT' ? (
    <LbNumericFormatInput
      {...props}
      rules={rules}
      variant="outlined"
      size="small"
      fixedDecimalScale
    />
  ) : (
    <ViewSubscriptionInput {...props} />
  );
};

export { SubscriptionSettingsInput };
