'use strict';

angular
    .module('app')
    .directive('phoneValidation', phoneValidation);

phoneValidation.$inject = ['GeneralService'];

function phoneValidation(GeneralService) {
    return {
        restrict: 'A',
        link: function(scope, element) {
            element[0].addEventListener('blur', function () {
                var parentFormCtrl = element.parent().controller('form');
                GeneralService.checkCellPhone(parentFormCtrl, element[0].name, element[0].value);
            });
        }
    };
}
