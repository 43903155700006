import React from 'react';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';

import classes from './SupplementBox.module.scss';
import { customerActiveCalendarStore } from '../../../../../../../stores/customerActiveCalendarStore';

const SupplementBox = observer(({ amount, supplementId, withFood }) => {
  const { customerSupplements } = customerActiveCalendarStore;

  const { supplementName, amountUnit } = customerSupplements[supplementId];

  const food = withFood ? '+ food' : '';

  return (
    <div className={classes.supplementBox}>
      <h6>{supplementName}</h6>
      <p>{`${amount} ${amountUnit} ${food}`}</p>
    </div>
  );
});

SupplementBox.propTypes = {
  amount: PropTypes.number,
  supplementId: PropTypes.number,
  withFood: PropTypes.bool,
};

export { SupplementBox };
