import React from 'react';

import { useForm } from 'react-hook-form';
import { angularize } from 'react-in-angularjs';
import { Button, Container, Paper } from '@mui/material';
import { enqueueSnackbar } from 'notistack';

import { SystemProviders } from '../../../shared/providers';
import { LbInput } from '../../../shared/components/form-components';
import loginPicture from '../../assets/image/login-picture.svg';
import classes from './NgzForgotPassword.module.scss';
import { authService } from '../../services/authService';
import { goTo } from '../../../shared/utils/angularUtils';
import { validateEmail } from '../../../shared/utils/reactHookFormUtils';

type FormData = {
  email: string;
};

const NgzForgotPassword = () => {
  const { control, handleSubmit } = useForm<FormData>({
    mode: 'onBlur',
  });

  const onSubmit = async (data: FormData) => {
    await authService.resetPassword(data.email);
    enqueueSnackbar('Message with reset link sent to email.', { variant: 'success' });
    goTo('/');
  };

  return (
    <SystemProviders>
      <Container className={classes.container}>
        <Paper variant="roundedBox" className={classes.paper}>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
            <h2 className={classes.title}>Forgot Password</h2>
            <p className={classes.description}>
              Enter the email you used to register with LifeBase. A &quot;Forgot
              Password&quot; email with instructions will be sent to you and return to
              login
            </p>
            <LbInput
              className={classes.input}
              control={control}
              label="Email"
              name="email"
              type="email"
              rules={{
                required: 'Email is required',
                validate: { validateEmail },
              }}
            />
            <Button
              className={classes.button}
              variant="contained"
              fullWidth
              type="submit"
            >
              Send
            </Button>
            <Button variant="outlined" href="/" fullWidth>
              Back to Login
            </Button>
          </form>
          <img className={classes.image} src={loginPicture} alt="" />
        </Paper>
      </Container>
    </SystemProviders>
  );
};

angularize(
  NgzForgotPassword,
  'ngzForgotPassword',
  angular.module('app.authManagement'),
  {},
);

export default NgzForgotPassword;
