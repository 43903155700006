import { paginationListLimit } from '../../shared/constants/systemConstants';
import { apiService } from '../../shared/services/apiService';

const orderURL = 'api/coach/supplement-orders';

const createOrder = async (orders, userId) => {
  const params = { userId };
  const resp = await apiService.post(`${orderURL}/initial`, orders, {
    params,
  });
  return resp;
};

const recordOrder = async (orders, userId, isRunGlobalSpinner = false) => {
  const params = { userId };
  const resp = await apiService.post(`${orderURL}/reorder`, orders, {
    params,
    isRunGlobalSpinner,
  });
  return resp.data;
};

const editOrder = async (order, isRunGlobalSpinner = false) => {
  const resp = await apiService.patch(orderURL, order, {
    isRunGlobalSpinner,
  });
  return resp.data;
};

const confirmOrder = async (orderId) => {
  const resp = await apiService.put(`${orderURL}/${orderId}`);
  return resp.data;
};

const deleteOrder = async (orderId) => {
  const resp = await apiService.delete(`${orderURL}/${orderId}`);
  return resp;
};

const getOrderInventory = async (userId, isRunGlobalSpinner) => {
  const resp = await apiService(`/api/coach/inventory/user/${userId}`, {
    isRunGlobalSpinner,
  });
  return resp.data;
};

const getSupplementOrdersHistory = async (customerID, isRunGlobalSpinner = false) => {
  const params = { customerID };
  const resp = await apiService(`${orderURL}/history`, {
    params,
    isRunGlobalSpinner,
  });
  return resp.data;
};

const getUsersInventory = async (data) => {
  const params = { size: paginationListLimit, ...data };

  const resp = await apiService('/api/coach/inventory', { params });

  return resp.data;
};

const updateInventoriesServingSizes = async (
  data,
  userID,
  isRunGlobalSpinner = false,
) => {
  const params = { userID };
  const resp = await apiService.patch('api/coach/inventory', data, {
    params,
    isRunGlobalSpinner,
  });
  return resp.data;
};

export const orderSupplementsService = {
  createOrder,
  recordOrder,
  editOrder,
  confirmOrder,
  deleteOrder,
  getOrderInventory,
  getSupplementOrdersHistory,
  getUsersInventory,
  updateInventoriesServingSizes,
};
