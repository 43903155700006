import React from 'react';

import classes from './PriceInfo.module.scss';
import { getPriceView } from '../../../shared/utils/stringUtils';
import { getIsNullish } from '../../../shared/utils/jsUtils';

type PriceInfoProps = {
  price?: number | null;
  finalPrice?: number | null;
  duration?: string;
  hidePrice?: boolean;
};

const PriceInfo = ({ price, finalPrice, duration, hidePrice }: PriceInfoProps) => {
  if (getIsNullish(finalPrice) || hidePrice) {
    // class need do add same font
    return <div className={classes.pricesContainer}>—</div>;
  }

  const isDiscountUsed = price !== finalPrice;

  return (
    <div className={classes.pricesContainer}>
      {isDiscountUsed && <p className={classes.oldPrice}>{getPriceView(price)}</p>}
      <p className={classes.priceBox}>
        <span>{getPriceView(finalPrice)}</span>
        {duration && (
          <>
            <span>/</span>
            <span className={classes.description}>{duration}</span>
          </>
        )}
      </p>
    </div>
  );
};

export { PriceInfo, type PriceInfoProps };
