import React from 'react';
import { Typography } from '@mui/material';
import classed from './Header.module.scss';

const Header = () => {
  return (
    <div className={classed.container}>
      <Typography className={classed.title}>Subscription details</Typography>
    </div>
  );
};

export { Header };
