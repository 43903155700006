import React from 'react';

import classNames from 'classnames';

import classes from './CoachRow.module.scss';
import { GridTableRow } from '../../../../../../../shared/components/table-components';
import { Coach } from '../../../../../../interfaces/coachList/CoachListInfo';
import { CoachInfoDesktop } from './CoachInfoDesktop/CoachInfoDesktop';
import { CoachInfoMobile } from './CoachInfoMobile/CoachInfoMobile';

type CoachRowProps = {
  coach: Coach;
  selectedCoachId?: number | null;
  onClick: (coach: Coach) => void;
  isMobile: boolean;
};

const CoachRow = ({ coach, selectedCoachId, onClick, isMobile }: CoachRowProps) => {
  const checked = selectedCoachId === coach.coachId;

  const rowClasses = classNames(classes.row, {
    [classes.checkedRow]: checked,
  });

  const handleClick = () => {
    onClick(coach);
  };

  return (
    <GridTableRow
      className={rowClasses}
      customLayout={isMobile}
      variant="row"
      onClick={handleClick}
      dataTestId="coach-row"
    >
      {isMobile ? (
        <CoachInfoMobile coach={coach} checked={checked} />
      ) : (
        <CoachInfoDesktop coach={coach} checked={checked} />
      )}
    </GridTableRow>
  );
};

export { CoachRow };
