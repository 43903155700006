import React, { useEffect } from 'react';

import { angularize } from 'react-in-angularjs';
import { observer } from 'mobx-react';

import { customerMedicalTestsStore } from '../../../../stores/customerMedicalTestsStore';
import { SystemProviders } from '../../../../../shared/providers';
import { MedicalTestsPlaceholder } from './MedicalTestsPlaceholder/MedicalTestsPlaceholder';
import { CardBox } from './CardBox/CardBox';
import { AddTestBlock } from './AddTestBlock/AddTestBlock';
import { getRouteParam } from '../../../../../shared/utils/angularUtils';

const NgzActiveCalendarMedicalTestsTab = observer(() => {
  const { medicalTests, medicalTestForm, initDataToRender, setDefaultValues } =
    customerMedicalTestsStore;

  const customerId = getRouteParam('id');

  useEffect(() => {
    initDataToRender(customerId);

    return setDefaultValues;
  }, []);

  const isShowPlaceholder = !medicalTests.length && !medicalTestForm;

  return (
    <SystemProviders>
      <AddTestBlock />
      {medicalTests.map((test, index) => (
        <CardBox test={test} testIndex={index} key={test.testID} />
      ))}
      <MedicalTestsPlaceholder isShow={isShowPlaceholder} />
    </SystemProviders>
  );
});

angularize(
  NgzActiveCalendarMedicalTestsTab,
  'ngzActiveCalendarMedicalTestsTab',
  angular.module('app.active-calendar'),
  {},
);
