import { loggedInUserDataStore } from '../../../../../shared/stores/loggedInUserDataStore';
import { ScholasticCustomersStatusType } from '../../../../constants/scholasticCustomersStatus';

export const getLinkForCustomerInfo = (
  userId: number,
  scholasticStatus: ScholasticCustomersStatusType,
) => {
  const { isCoach } = loggedInUserDataStore;
  const isNotCreated = scholasticStatus === 'INCOMPLETE';

  if (isNotCreated && isCoach) {
    return `#!/scholastic-setup/${userId}`;
  }
  return `#!/customer-profile/${userId}`;
};
