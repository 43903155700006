import { ValueOfKey } from '../../utils-types';

export const DAYS = {
  sunday: 'sunday',
  monday: 'monday',
  tuesday: 'tuesday',
  wednesday: 'wednesday',
  thursday: 'thursday',
  friday: 'friday',
  saturday: 'saturday',
} as const;

export type Day = ValueOfKey<typeof DAYS>;

export const daysOfWeek = Object.values(DAYS);
