import React from 'react';

import classes from './OverviewPlaceholder.module.scss';
import overviewPlaceholderImg from '../../../../../assets/image/overviewPlaceholder.png';

const OverviewPlaceholder = () => {
  return (
    <div className={classes.box}>
      <img src={overviewPlaceholderImg} alt="placeholder img" />
      <p className={classes.text}>User doesn&apos;t have any statistics</p>
    </div>
  );
};

export { OverviewPlaceholder };
