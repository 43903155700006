const autoNgTemplateLoaderTemplate1 = require('./pre-approval-setup.template.html');

'use strict';

import { getLoginRequiredResolveObj } from '../../modules/shared/utils/angularUtils';
import { preCheckInService } from '../../modules/pre-check-in/services/preCheckInService';

PreApprovalSetupController.$inject = ['ModalService', 'DateTimeUtil', 'PreCheckInService',
    'utilService', 'constants', '$scope'];

function PreApprovalSetupController(ModalService, DateTimeUtil, PreCheckInService,
                                    utilService, constants, $scope) {
    var vm = this;
    vm.spinner = {active: false};
    vm.preCheckInGroup = [{}];
    vm.minDate = new Date(moment(new Date()).subtract(+100, 'years'));
    vm.maxDate = new Date();
    vm.patterns = constants.enums.patterns;
    vm.subscriptions = [];
    vm.entityId = utilService.getLoggedInUserData().gymId;

    vm.addNewMember = addNewMember;
    vm.removeMember = removeMember;
    vm.cancel = cancel;
    vm.goTo = goTo;
    vm.setDOB = setDOB;
    vm.sendRequest = sendRequest;
    vm.getSubscriptionDetailsText = getSubscriptionDetailsText;

    vm.$onInit = async () => {
        await setSubscriptions();
        $scope.$apply();
    };

    async function setSubscriptions() {
        const resp = await preCheckInService.getSubscriptions(vm.entityId);

        vm.subscriptions = resp.subscriptions.map((subscription) => {
            const id = subscription.subscriptionID;
            const description = vm.getSubscriptionDetailsText(subscription);

            return { id, description };
        });
    }

    function addNewMember() {
        vm.preCheckInGroup.push({});
    }

    function removeMember(index) {
        vm.preCheckInGroup.splice(index, 1);
    }

    function cancel() {
        ModalService.confirm('Are you sure you want to leave this page?', vm.goTo, '/customers-dashboard');
    }

    function setDOB(form, index) {
        vm.preCheckInGroup[index].dob = moment(vm.preCheckInGroup[index].fullDob).format('MM.DD.YYYY');
        form['dob' + index].$setValidity("invalidDateValue",
            !(vm.preCheckInGroup[index].fullDob.getFullYear() < 1900 || (new Date().getTime() - vm.preCheckInGroup[index].fullDob.getTime()) / 31536000000 < 12));
    }

    function goTo(path) {
        utilService.goTo(path);
    }

    function sendRequest() {
        var data = vm.preCheckInGroup.map(function(item) {
            return {
                fullName: item.fullName,
                dob: DateTimeUtil.formatDate(item.fullDob, 'YYYY-MM-DD'),
                kidEmail: item.kidEmail,
                phone: item.phone,
                fullNameParent: item.fullNameParent,
                parentEmail: item.parentEmail,
                entityId: vm.entityId,
                subscriptionID: item.subscriptionId,
            };
        });

        vm.spinner.active = true;
        PreCheckInService.addUsers(data).then(function (res) {
            vm.spinner.active = false;
            if (res.status === 200) {
                ModalService.choose(
                    data.length + ' Request' + (data.length > 1 ? 's' : '') + ' were sent to the parents',
                    'You can check the result on the Pre Check-in page.',
                    'Open Pre Check-in page',
                    'Got it',
                    vm.goTo.bind(vm, '/pre-check-in'),
                    vm.goTo.bind(vm, '/customers-dashboard')
                );
            }
        });
    }

    function getSubscriptionDetailsText(subscription) {
        let autoRenew, renewalDetails = '';

        if (subscription.autoRenewal) {
            autoRenew = 'yes';
            renewalDetails = `; ${subscription.renewDaysOfSubscription} days; 
                $${subscription.renewalSubscriptionAmount}`;
        } else {
            autoRenew = 'no';
        }

        return `${subscription.title} - $${subscription.initialSubscriptionAmount} 
            + sales tax - ${subscription.renewDaysOfSubscription} days (auto-renew: 
            ${autoRenew} ${renewalDetails})`;
    }
}

const preApprovalSetupModule = angular.module('app.preApprovalSetup', []);

preApprovalSetupModule.component('preApprovalSetup', {
    templateUrl: autoNgTemplateLoaderTemplate1,
    controller: PreApprovalSetupController,
    controllerAs: 'vm',
});

preApprovalSetupModule.config(['$routeProvider', function($routeProvider) {
    $routeProvider.when('/pre-approval-setup', {
        template: '<pre-approval-setup></pre-approval-setup>',
        resolve: getLoginRequiredResolveObj(),
    });
}]);
