import React from 'react';

import classes from './TitleBlock.module.scss';
import Stars from '../../../../../../shared/assets/image/stars.png';

const TitleBlock = () => {
  return (
    <div className={classes.titleBlock}>
      <h3>Almost done!</h3>
      <p>It remains to create Password for quick login</p>
      <img src={Stars} alt="star" />
    </div>
  );
};

export { TitleBlock };
