import React from 'react';

import { observer } from 'mobx-react';

import classes from './DetailsTable.module.scss';
import { getPriceView } from '../../../../../shared/utils/stringUtils';
import { checkoutStore } from '../../../../stores/checkoutStore';

const DetailsTable = observer(() => {
  const { checkoutInfo, subscriptionTypeFlags } = checkoutStore;

  if (!checkoutInfo) {
    return null;
  }

  const { subscription, coach, entity, promocode, checkoutDetails, tax } = checkoutInfo;

  const isPromocodeUsedToCurrentPayment =
    promocode && checkoutDetails.discountAmount !== 0;

  return (
    <table className={classes.table}>
      <tbody>
        <tr className={classes.regularRow}>
          <td>
            <p>{subscription.name}</p>
            <table className={classes.nestedTable}>
              <tbody>
                <tr>
                  <td>Coach</td>
                  <td>
                    {coach.firstName} {coach.lastName}
                  </td>
                </tr>
                <tr>
                  <td>Business Name</td>
                  <td>{entity.businessName}</td>
                </tr>
                {coach.collaborationType === 'REMOTE' && (
                  <tr>
                    <td>Collaboration</td>
                    <td>To work with a coach remotely</td>
                  </tr>
                )}
              </tbody>
            </table>
          </td>
          {subscriptionTypeFlags.REGULAR && (
            <td align="right">{getPriceView(subscription.initialPlanPrice)}</td>
          )}
          {subscriptionTypeFlags.REACTIVATION && (
            <td align="right">{getPriceView(subscription.monthlyRenewalPrice)}</td>
          )}
        </tr>
        {isPromocodeUsedToCurrentPayment && (
          <tr className={classes.regularRow}>
            <td>Discount {promocode.discountPercent}%</td>
            <td align="right">-{getPriceView(checkoutDetails.discountAmount)}</td>
          </tr>
        )}
        {tax && tax.taxRateType === 'ENTITY_TAX_RATE' && (
          <tr className={classes.regularRow}>
            <td>Tax, {tax.taxRatePercent}%</td>
            <td align="right">{getPriceView(checkoutDetails.taxAmount)}</td>
          </tr>
        )}
        <tr className={classes.totalRow}>
          <td>Total</td>
          <td align="right">{getPriceView(checkoutDetails.totalAmount)}</td>
        </tr>
      </tbody>
    </table>
  );
});

export { DetailsTable };
