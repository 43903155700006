import { useState } from 'react';

import { Mode } from '../../../../general-types';

type UseModeReturn = {
  mode: Mode;
  setEdit: () => void;
  setView: () => void;
  setMode: (mode: Mode) => void;
};

export const useMode = (defaultMode: Mode = 'VIEW'): UseModeReturn => {
  const [mode, setMode] = useState<Mode>(defaultMode);

  const setEdit = () => setMode('EDIT');
  const setView = () => setMode('VIEW');

  return {
    mode,
    setEdit,
    setView,
    setMode,
  };
};
