const autoNgTemplateLoaderTemplate1 = require('../panel/invoice-calendar.panel.html');

'use strict';

angular.module('app.entityProfile').factory('InvoicesAndPricingService', InvoicesAndPricingService);

InvoicesAndPricingService.$inject = ['ApiService', '$mdPanel', '$rootScope', 'localStorageService'];

function InvoicesAndPricingService(ApiService, $mdPanel, $rootScope, localStorageService) {
  const baseUrl = 'api/tariff/invoices-and-pricing/';

  const invoicesAndPricingService =  {
    getCurrentBillingPeriod: entityId => {
      return ApiService.get(`${baseUrl}detailed/current-billing-period`, { entityID: entityId });
    },
    getBillingPeriodById: paymentId => ApiService.get(`${baseUrl}detailed/${paymentId}`),
    getInvoiceCalendarData: entityId => ApiService.get(`${baseUrl}summary`, { entityID: entityId }),
    openInvoiceCalendarPanel: entityId => {
      const position = $mdPanel.newPanelPosition()
        .relativeTo('#nextBillingDate')
        .addPanelPosition($mdPanel.xPosition.ALIGN_START, $mdPanel.yPosition.ALIGN_TOPS);

      const config = {
        controller: InvoiceCalendarCtrl,
        controllerAs: 'vm',
        disableParentScroll: true,
        templateUrl: autoNgTemplateLoaderTemplate1,
        hasBackdrop: true,
        panelClass: 'invoice-calendar',
        position: position,
        trapFocus: true,
        zIndex: 150,
        clickOutsideToClose: true,
        escapeToClose: true,
        focusOnOpen: true,
        locals: { entityId }
      };

      $mdPanel.open(config);
    },
  };

  InvoiceCalendarCtrl.$inject = ['mdPanelRef', 'entityId'];
  function InvoiceCalendarCtrl(mdPanelRef, entityId) {
    this._mdPanelRef = mdPanelRef;
    this.spinner = false;
    this.invoiceCalendarData = localStorageService.get('invoiceCalendarData');
    this.selectedPayment = localStorageService.get('selectedPayment');

    this.$onInit = () => {
      if (!this.invoiceCalendarData) {
        this.getInvoiceCalendarData();
      }
    }

    this.getInvoiceCalendarData = () => {
      this.spinner = true;
      invoicesAndPricingService.getInvoiceCalendarData(entityId)
        .then(res => {
          if (res.status === 200) {
            this.invoiceCalendarData = res.data;
            localStorageService.set('invoiceCalendarData', this.invoiceCalendarData);
          }
        })
        .finally(() => this.spinner = false);
    }

    this.openPaymentDetails = (payment) => {
      if (payment !== this.selectedPayment) {
        this.selectedPayment = payment;
        localStorageService.set('selectedPayment', this.selectedPayment);
        $rootScope.$broadcast('paymentSelect', { selectedPayment: this.selectedPayment });
      }
      this.closeDialog();
    }
  }

  InvoiceCalendarCtrl.prototype.closeDialog = function() {
    const panelRef = this._mdPanelRef;
    panelRef && panelRef.close().then(function() {
      angular.element(document.querySelector('#invoice-calendar')).focus();
      panelRef.destroy();
    });
  };

  return invoicesAndPricingService;
}
