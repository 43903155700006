import React from 'react';

import { Button, Paper } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useWindowSize } from 'react-use';
import classNames from 'classnames';

import classes from './FiltersRow.module.scss';
import { LbInput } from '../../../../../../../shared/components/form-components';
import { CoachListFilterFormValues } from '../../../../../../interfaces/coachList/CoachListFilters';

const FiltersRow = () => {
  const { control, reset } = useFormContext<CoachListFilterFormValues>();
  const { width } = useWindowSize();
  const isMobile = width < 768;

  const inputGeneralProps = { control, variant: 'outlined', size: 'small' } as const;

  const handleReset = () => {
    reset();
  };

  const filterClasses = classNames(classes.container, {
    [classes.mobileLayout]: isMobile,
  });

  return (
    <Paper variant="roundedBox" className={filterClasses}>
      <LbInput {...inputGeneralProps} name="coachName" label="Coach Name" />
      <Button variant="outlined" fullWidth size="small" onClick={handleReset}>
        Clear All
      </Button>
    </Paper>
  );
};

export { FiltersRow };
