import React from 'react';

import { observer } from 'mobx-react';
import { UseStepperReturn } from '../../../../../shared/hooks/useStepper';
import {
  ButtonRow,
  CoachCard,
} from '../../../../components/customer-self-setup-components';
import { SelectSubscriptionBlock } from '../../../../../payment';
import { coachStore } from '../../../../stores/customerSelfSetupStores/coachStore';
import { personalInfoStore } from '../../../../stores/customerSelfSetupStores/personalInfoStore';
import { getPriceView } from '../../../../../shared/utils/stringUtils';
import { useScrollToTop } from '../../../../../shared/hooks/useScrollToTop';
import { useSignUpSubscriptions } from '../../../../hooks/customerSelfSetupHooks/useSignUpSubscriptions';

type SelectSubscriptionStepProps = {
  stepperMethods: UseStepperReturn;
};

const SelectSubscriptionStep = observer(
  ({ stepperMethods }: SelectSubscriptionStepProps) => {
    useScrollToTop();

    const { selectedCoach, filterMethods } = coachStore;
    const { personalInfo } = personalInfoStore;

    const {
      subscriptions,
      discountPercent,
      selectedSubscription,
      isFreeAccessSubscription,
      applyPromoCode,
      setSelectedSubscriptionId,
      handleSubmit,
    } = useSignUpSubscriptions(selectedCoach!.entityId, personalInfo!.email);

    const freeAccessYesButtonText = 'Submit Form';

    const yesButtonText = `Submit Form & Pay ${
      selectedSubscription ? getPriceView(selectedSubscription.initialPlanPriceFinal) : ''
    }`;

    return (
      <div>
        {selectedCoach && (
          <CoachCard
            coachInfo={selectedCoach}
            coachType={filterMethods.filterData.coachType}
          />
        )}
        <SelectSubscriptionBlock
          subscriptions={subscriptions}
          discountPercent={discountPercent}
          onApplyPromoCode={applyPromoCode}
          selectedSubscriptionId={selectedSubscription?.id || null}
          setSelectedSubscriptionId={setSelectedSubscriptionId}
          titleDescription="Access to the LifeBase app starts when your coach finalizes your setup and custom macro plan"
        />
        <ButtonRow
          yesHandler={handleSubmit}
          yesText={isFreeAccessSubscription ? freeAccessYesButtonText : yesButtonText}
          yesDisabled={!selectedSubscription}
          noHandler={stepperMethods.goToPreviousStep}
        />
      </div>
    );
  },
);

export { SelectSubscriptionStep };
