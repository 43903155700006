import React from 'react';

import { useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react';

import { InbodyConfigInfo } from '../../../../../../shared/constants/inbody-info/inbodyData';
import { LbCheckbox } from '../../../../../../shared/components/form-components';
import { getFieldFormName } from './utils/getFieldFormName';
import { useRequiredFields } from './hooks/useRequiredFields';
import { loggedInUserDataStore } from '../../../../../../shared/stores/loggedInUserDataStore';
import { EntityInbodyDataFormValue } from '../../../EntityInbodyDataConfig.settings';

type InbodyDataCheckboxProps = {
  fieldInfo: InbodyConfigInfo;
};

const InbodyDataCheckbox = observer(({ fieldInfo }: InbodyDataCheckboxProps) => {
  const { control } = useFormContext<EntityInbodyDataFormValue>();
  const { isSuperAdmin } = loggedInUserDataStore;

  const { dbName, name, required, defaultChecked, requiredFields } = fieldInfo;

  const fieldName = getFieldFormName(dbName);
  const { disabled } = useRequiredFields(fieldName, requiredFields);

  return (
    <LbCheckbox
      key={dbName}
      control={control}
      name={fieldName}
      defaultChecked={required || defaultChecked}
      disabled={required || disabled || !isSuperAdmin}
      label={name}
      color="primary"
    />
  );
});

export { InbodyDataCheckbox };
