import React, { useEffect } from 'react';

import { observer } from 'mobx-react';

import classes from './ReportTable.module.scss';
import { ReportRow } from './ReportRow/ReportRow';
import { ReportsTableHead } from './ReportsTableHead/ReportsTableHead';
import { salesReportStore } from '../../../../stores/salesReportStore';

const ReportsTable = observer(() => {
  const { reports, getReports } = salesReportStore;

  useEffect(() => {
    getReports();
  }, []);

  return (
    <div className={classes.reportTable}>
      <ReportsTableHead />
      {reports.map((report) => (
        <ReportRow report={report} key={report.reportType} />
      ))}
    </div>
  );
});
export { ReportsTable };
