/* eslint-disable react/no-array-index-key */
import React from 'react';

import { observer } from 'mobx-react';

import classes from './WaterTable.module.scss';
import { TableHeader } from './TableHeader/TableHeader';
import { DayRow } from './DayRow/DayRow';
import { GoalFromRow } from './GoalFromRow/GoalFromRow';
import { customerWaterGoalStore } from '../../../../../stores/customerWaterGoalStore';

const WaterTable = observer(() => {
  const { waterCalendar, getTargetGoalById } = customerWaterGoalStore;

  const waterTable = waterCalendar.map((day, index) => {
    const targetGoal = getTargetGoalById(day.dailyGoalID);

    const GoalFromRowComp = (
      <GoalFromRow startDate={day.startDate} targetGoal={day.targetGoal} key={index} />
    );

    const DayRowComp = (
      <DayRow day={day} id={day.date} targetGoal={targetGoal} key={index} />
    );

    return day.isGoalRow ? GoalFromRowComp : DayRowComp;
  });

  return (
    <div>
      <TableHeader />
      <div className={classes.tableBox}>{waterTable}</div>
    </div>
  );
});

export { WaterTable };
