'use strict';

angular.module('app').factory('MealPlanMacroService', MealPlanMacroService);

MealPlanMacroService.$inject = ['ApiService'];

function MealPlanMacroService(ApiService) {
    return {
        getMealsForDay: function (prms) {
            return ApiService.get('api/daymeals', prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getNutrients: function (prms) {
            return ApiService.get('api/v.1.3.2/nutrients', prms).then(function (res) {
                var countFibers = 0;
                for (var j in res.data.fiber.currentValue) {
                    if (res.data.fiber.currentValue[j] > 0) {
                        countFibers++;
                    }
                }
                res.data.fiber.currentValue = countFibers;
                res.data.fiber.beyond = countFibers - res.data.fiber.maxValue;
                res.data.nutrients.splice(4, 0, res.data.fiber);
                return res;
            }, function (err) {
                return err;
            });
        },
        saveMeal: function (data, prms) {
            return ApiService.put('api/daymeals', data, prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        }
    };
}
