(function () {
    'use strict';

    angular.module('app.coachView').controller('CoachViewController', CoachViewController);
    CoachViewController.$inject = [
        '$routeParams',
        'CoachService',
        'utilService'
    ];

    function CoachViewController($routeParams, CoachService, utilService) {
        var vm = this;

        vm.createCoach = {};

        vm.goToEditPage = goToEditPage;
        vm.getCoachForEntity = getCoachForEntity;

        vm.getCoachForEntity();

        function goToEditPage() {
            utilService.goTo('/coach-edit/' + vm.createCoach.id);
        }

        function getCoachForEntity() {
            CoachService.getCoachForEntity($routeParams.id).then(function (res) {
                if (res.status === 200) {
                    vm.createCoach = res.data;
                }
            });
        }
    }
})();
