(function () {
    'use strict';

    angular.module('app.operatorSetup').controller('OperatorSetupController', OperatorSetupController);
    OperatorSetupController.$inject = [
        'OperatorService',
        'EntityService',
        'constants',
        'utilService'
    ];

    function OperatorSetupController(OperatorService, EntityService, constants, utilService) {
        var vm = this;

        vm.patterns = constants.enums.patterns;
        vm.createOperator = {};
        vm.searchEntity = null;
        vm.entitiesList = [];
        vm.allEntitiesList = [];
        vm.managedEntitiesList = [];

        vm.searchInputEntity = searchInputEntity;
        vm.onDrop = onDrop;
        vm.createOperatorUser = createOperatorUser;
        vm.getAllEntities = getAllEntities;
        vm.goTo = utilService.goTo;

        getAllEntities();

        function searchInputEntity() {
            vm.entitiesList = vm.allEntitiesList.filter(function (value) {
                return value.name.toLowerCase().match(vm.searchEntity.toLowerCase());
            });
        }

        function onDrop(srcList, srcIndex, targetList, targetIndex) {
            targetList.splice(targetIndex, 0, srcList[srcIndex]);
            if (srcList === targetList && targetIndex <= srcIndex) {
                srcIndex++;
            }
            srcList.splice(srcIndex, 1);
            return true;
        }

        function createOperatorUser() {
            vm.createOperator.entities = vm.managedEntitiesList;
            OperatorService.createOperatorAccount(vm.createOperator).then(function (res) {
                if (res.status === 200) {
                    vm.goTo('/superuser-dashboard');
                }
            });
        }

        function getAllEntities() {
            EntityService.getAllEntities().then(function (res) {
                if (res.status === 200) {
                    vm.entitiesList = res.data.entities;
                    vm.allEntitiesList = res.data.entities;
                }
            });
        }
    }
})();
