import { updateUserInfo } from "../../modules/shared/utils/userInfoUtils";

(function () {
    'use strict';

    angular.module('app').controller('UserProfileTabController', UserProfileTabController);
    UserProfileTabController.$inject = [
        '$rootScope',
        '$location',
        'utilService',
        'CorporateUsersService',
        'ModalService',
        'GeneralService',
        'PaymentService',
        'CustomerService',
        'StripeService'
    ];

    function UserProfileTabController($rootScope, $location, utilService, CorporateUsersService, ModalService,
                                      GeneralService, PaymentService, CustomerService, StripeService) {
        var vm = this;
        vm.loggedInUser = utilService.getLoggedInUserData();
        vm.profileTabName = 'userProfile';
        vm.customerPasswords = {};
        vm.profileSpinner = {active: false};
        vm.subscriptionsSpinner = { active: false };
        vm.paymentFlowType = {};
        vm.isStripeUsed = false;

        vm.init = init;
        vm.calculateExpirationPeriod = calculateExpirationPeriod;
        vm.getEntitiesPaymentTransaction = getEntitiesPaymentTransaction;
        vm.formatExpireDate = formatExpireDate;
        vm.changeUserProfile = changeUserProfile;
        vm.updatePassword = updatePassword;
        vm.checkRenewalSubscriptions = checkRenewalSubscriptions;
        vm.cancelRenewal = cancelRenewal;
        vm.setPushNotificationsState = setPushNotificationsState;
        vm.showUpdateCardDialog = showUpdateCardDialog;
        vm.showNewUpdateCardDialog = showNewUpdateCardDialog;
        vm.showOldUpdateCardDialog = showOldUpdateCardDialog;
        vm.getStripePublicKey = getStripePublicKey;
        vm.updateUserPaymentSource = updateUserPaymentSource;
        vm.updateUserInfo = updateUserInfo;

        init();

        function init() {
            vm.spinner = vm.profileSpinner;
            // set value for vm.paymentFlowType and vm.isStripeUsed
            PaymentService.getPaymentFlowTypeAndIsStripeUsed(vm);
            vm.getEntitiesPaymentTransaction();
            vm.getStripePublicKey();
        }

        function getStripePublicKey() {
            GeneralService.getStripePublicKey().then(function (res) {
                if (res.status === 200) {
                    var key = res.data.key;
                    if (key) {
                        vm.updatePaymentConf = window.StripeCheckout.configure({
                            key: key,
                            image: 'https://content.lifebase.solutions/static-content/logo.png',
                            locale: 'auto',
                            panelLabel: 'Update',
                            source: vm.updateUserPaymentSource
                        });
                    }
                }
            });
        }

        function updateUserPaymentSource(src) {
            vm.subscriptionsSpinner.active = true;
            PaymentService.updateUserPaymentSource(vm.loggedInUser.id, {src: src.id}).then(function () {
                vm.subscriptionsSpinner.active = false;
            });
        }

        function updatePassword(form) {
            vm.profileSpinner.active = true;
            GeneralService.changePassword(vm.customerPasswords).then(function (res) {
                vm.profileSpinner.active = false;
                if (res.data.message === 'Current password is wrong') {
                    form['currentPassword'].$setValidity('wrong', false);
                }
            });
        }

        function setPushNotificationsState() {
            CustomerService.changePushNotificationStatus(vm.loggedInUser.id, {
                push_reminder: vm.loggedInUser.pushReminder,
                push_ekin: vm.loggedInUser.pushEkin
            }).then(function (res) {
                if (res.status === 200) {
                    localStorage.setItem('loggedInUser', JSON.stringify(vm.loggedInUser));
                }
            });
        }

        function cancelRenewal() {
            var planIndex = vm.checkRenewalSubscriptions();

            if (planIndex) {
                var canceledPlan = vm.loggedInUser.subscriptions[planIndex];
                vm.subscriptionsSpinner.active = true;
                PaymentService.cancelRenewalSubscriptionByCustomer(canceledPlan.id).then(function (res) {
                    vm.subscriptionsSpinner.active = false;
                    if (res.status === 200) {
                        ModalService.canceledPlanInfo(canceledPlan, vm.loggedInUser, vm.getEntitiesPaymentTransaction);
                    }
                });
            }
        }

        function changeUserProfile(form) {
            vm.profileSpinner.active = true;

            const userInfo = {
                firstName: vm.loggedInUser.firstName,
                lastName: vm.loggedInUser.lastName,
                email: vm.loggedInUser.email
            };

            GeneralService.updateUserInfo(vm.loggedInUser.id, {
                user: userInfo
            }).then(function (res) {
                vm.profileSpinner.active = false;
                if (res.status === 200) {
                    vm.updateUserInfo(userInfo)

                    vm.customerPasswords.currentPassword && vm.customerPasswords.password && vm.customerPasswords.repeatPassword ?
                        vm.updatePassword(form) : ModalService.savedChanges();
                }
            });
        }

        function getEntitiesPaymentTransaction() {
            if (vm.loggedInUser.customer.corporateSubscription) {
                vm.subscriptionsSpinner.active = true;

                CorporateUsersService.getEntitiesPaymentTransaction().then(function (res) {
                    vm.subscriptionsSpinner.active = false;
                    if (res.status === 200) {
                        vm.loggedInUser.subscriptions = res.data.transactions;
                    }
                })
            }
        }

        function checkRenewalSubscriptions() {
            if (vm.loggedInUser.subscriptions && !vm.loggedInUser.customer.corporateSubscription) {
                for (var i in vm.loggedInUser.subscriptions) {
                    var value = vm.loggedInUser.subscriptions[i];
                    if (value.renewal_active && value.is_finished) {
                        return i;
                    }
                }
            }
            return null;
        }

        function calculateExpirationPeriod(date) {
            return PaymentService.calculateExpirationPeriod(date);
        }

        function formatExpireDate(date) {
            return utilService.formatExpireDate(date);
        }

        function showUpdateCardDialog() {
            if (!vm.paymentFlowType.OLD) {
                vm.showNewUpdateCardDialog();
            } else {
                vm.showOldUpdateCardDialog();
            }
        }

        function showNewUpdateCardDialog() {
            StripeService.updateCustomerCardDetailsByCustomer();
        }

        function showOldUpdateCardDialog() {
            vm.updatePaymentConf.open({
                email: vm.loggedInUser.email,
                name: vm.loggedInUser.firstName + ' ' + vm.loggedInUser.lastName,
                description: 'Update payment source',
                currency: 'usd'
            });
        }
    }
})();
