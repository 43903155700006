import React from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { observer } from 'mobx-react';

import { LbModal } from '../../../../../shared/components/modals/LbModal/LbModal';
import { ModalInputs } from './ModalInputs/ModalInputs';
import { apiStatusStore } from '../../../../../shared/stores/apiStatusStore';
import { salesReportStore } from '../../../../stores/salesReportStore';

const DownloadReportModal = observer(() => {
  const { isLoading } = apiStatusStore;
  const { selectedReport, isShowDownloadModal, closeDownloadModal, downloadReport } =
    salesReportStore;
  const { reportType, title, settings } = selectedReport;

  const methods = useForm();

  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <LbModal
        open={isShowDownloadModal}
        title={`"${title}" Report`}
        isLoading={isLoading}
        primaryBtnConfig={{ text: 'Download', onClick: handleSubmit(downloadReport) }}
        secondaryBtnConfig={{ onClick: closeDownloadModal }}
      >
        <ModalInputs selectorsSettings={settings} reportType={reportType} />
      </LbModal>
    </FormProvider>
  );
});

export { DownloadReportModal };
