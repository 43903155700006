import { isAllFieldExist } from '../../../../../../shared/utils/jsUtils';

export type BodyMetricInfo = {
  current: number | null; // not used but returned by getMetrics
  goal: number | null;
  change: number | null;
};

export type BodyMetrics = {
  weight: BodyMetricInfo;
  percentBodyFat: BodyMetricInfo;
  bodyFatMass: BodyMetricInfo;
  lbm: BodyMetricInfo;
};

export type ValidationResult = {
  isValid: boolean;
  message: string;
};

export const validateGoal = (metrics: BodyMetrics): ValidationResult => {
  if (!isAllFieldExist(metrics)) {
    return {
      isValid: false,
      message: 'Please fill out all the fields.',
    };
  }

  const { weight, percentBodyFat, bodyFatMass, lbm } = metrics;

  const isLossMuscle = lbm.change < 0;
  const isGainMuscle = lbm.change > 0;

  const isLossFat = bodyFatMass.change < 0;
  const isGainFat = bodyFatMass.change > 0;

  if (isLossMuscle) {
    return {
      isValid: false,
      message: `Lean Body Mass Decrease is not allowed.
              Please adjust your body fat % goal and weight goals.`,
    };
  } else if (isGainFat) {
    return {
      isValid: false,
      message: `You selected a Body Fat ${percentBodyFat.goal} % and Weight ${weight.goal} that results in gaining FAT. Please readjust your target Weight and Body Fat.`,
    };
  } else if (isLossFat && isGainMuscle) {
    return {
      isValid: true,
      message: `You selected a Goal to Lose ${Math.abs(
        bodyFatMass.change,
      )} lbs of Fat and gain ${lbm.change} lbs of lean body mass!'`,
    };
  }

  return {
    isValid: true,
    message: '',
  };
};
