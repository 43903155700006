import React from 'react';

import classes from './ActiveCalendarPlaceholder.module.scss';
import activeCalendarPlaceholderImg from '../../../../../assets/image/activeCalendarPlaceholder.png';

const ActiveCalendarPlaceholder = () => {
  return (
    <div className={classes.box}>
      <img src={activeCalendarPlaceholderImg} alt="placeholder" />
      <p className={classes.text}>Active calendar is not created for the user</p>
    </div>
  );
};

export { ActiveCalendarPlaceholder };
