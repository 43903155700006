'use strict';

angular
    .module('app')
    .factory('AnalyticService', AnalyticService);

AnalyticService.$inject = ['$window'];

function AnalyticService($window) {
    return {
        /**
         * @param data {object}
         */
        pushData: function (data) {
            $window.dataLayer.push(data);
        },

        /**
         * @param event {string}
         * @param eventId {string}
         * @param eventCat {string}
         * @param eventName {string}
         * @param props {object}
         */
        pushEvent: function (event, eventId, eventCat, eventName, props) {
            const eventData = {
                'event': event,
                'event_id': eventId,
                'event_cat': eventCat,
                'event_name': eventName,
            };

            if (!angular.equals(props, {})) {
                Object.entries(props).forEach(item => eventData[item[0]] = JSON.stringify(item[1]));
            }

            $window.dataLayer.push(eventData);
        },

        /**
         *
         * @param event {string}
         * @param userPropertyId {string}
         * @param userPropertyName {string}
         * @param userPropertyValue
         */
        pushProperty: function (event, userPropertyId, userPropertyName, userPropertyValue) {
            const dataForPushing = {
                'event': event,
                'user_property_id': userPropertyId,
                'user_property_name': userPropertyName
            }
            dataForPushing[userPropertyName] = JSON.stringify(userPropertyValue)

            $window.dataLayer.push(dataForPushing);
        }
    };
}
