const autoNgTemplateLoaderTemplate1 = require('./cancel-renewal-subscription.template.html');

CancelRenewalSubscriptionController.$inject = ['constants', 'utilService', 'PaymentService', 'ModalService',
  'CancelSubscriptionService'];

function CancelRenewalSubscriptionController(constants, utilService, PaymentService, ModalService,
                                             CancelSubscriptionService) {
  const vm = this;
  vm.spinner = false;
  vm.isAgent = utilService.getLoggedInUserData().role === constants.enums.userRoles.AGENT;
  vm.activeSubscription = null;

  vm.setActiveSubscription = setActiveSubscription;
  vm.cancelRenewalOnServer = cancelRenewalOnServer;
  vm.onCancelRenewal = onCancelRenewal;

  vm.$onChanges = (changesObj) => {
    if (changesObj?.customerDetails.currentValue?.subscriptions) {
      vm.setActiveSubscription();
    }
  };

  function setActiveSubscription() {
    vm.activeSubscription = vm.customerDetails.subscriptions.find(item => item.renewal_active && item.is_finished);
  }

  function onCancelRenewal(ev) {
   CancelSubscriptionService.openCancelSubscriptionModal(ev, vm.customerDetails.id, vm.activeSubscription.id,
     vm.cancelRenewalOnServer);
  }

  function cancelRenewalOnServer() {
    vm.spinner = true;
    PaymentService.cancelRenewalSubscription(vm.activeSubscription.id)
      .then(res => {
        if (res.status === 200) {
          ModalService.canceledPlanInfo(vm.activeSubscription, vm.customerDetails, vm.initSubscriptions, true);
        }
      })
      .finally(() => vm.spinner = false);
  }
}

angular.module('app').component('cancelRenewalSubscription', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: CancelRenewalSubscriptionController,
  controllerAs: 'vm',
  bindings: {
    customerDetails: '<',
    initSubscriptions: '<'
  }
});
