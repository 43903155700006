const autoNgTemplateLoaderTemplate1 = require('./child-entity-list.template.html');

(function () {
  'use strict';

  angular
    .module('app.childEntityList', [])
    .config(childEntityListConfig);

  childEntityListConfig.$inject = ['$routeProvider'];

  function childEntityListConfig($routeProvider) {
    $routeProvider
      .when('/child-entity-list', {
        disableCache: true,
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: 'ChildEntityListController',
        controllerAs: 'vm',
        reloadOnSearch: false,
        resolve: {
          loginRequired: ['AuthService', function (AuthService) {
            return AuthService.loginRequired();
          }]
        }
      })
  }
})();
