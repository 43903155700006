import { useForm } from 'react-hook-form';

import { useAutoSubmitForm } from '../../../../../../../shared/hooks/useAutoSubmitForm';

export const useTabHeaderSubmit = (getAdaptedMealReports) => {
  const { control, handleSubmit, watch } = useForm();

  const onSubmit = ({ mealReportsDate }) => {
    getAdaptedMealReports(mealReportsDate);
  };

  useAutoSubmitForm(handleSubmit(onSubmit), watch);

  return { control };
};
