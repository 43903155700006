import React, { ReactNode } from 'react';

import classes from './InfoCell.module.scss';

type InfoCellProps = {
  title: ReactNode;
  info: ReactNode;
};

const InfoCell = ({ title, info }: InfoCellProps) => {
  return (
    <div className={classes.infoCell}>
      <p className={classes.title}>{title}</p>
      <p className={classes.info}>{info}</p>
    </div>
  );
};
export { InfoCell };
