import { useEffect, useState } from 'react';
import { useIsActivePage } from '../../../../../../hooks/useIsActivePage';
import { loggedInUserDataStore } from '../../../../../../stores/loggedInUserDataStore';

export const useIsShowBackToHomeBtn = () => {
  const { loggedInUser, isEntityHasChild } = loggedInUserDataStore;
  const [isShowBackToHomeBtn, setIsShowBackToHomeBtn] = useState(false);

  const isChildEntityListPage = useIsActivePage({ url: '/child-entity-list' });

  useEffect(() => {
    const { isLoggedByParent } = loggedInUser;

    setIsShowBackToHomeBtn(
      (isLoggedByParent || isEntityHasChild) && !isChildEntityListPage,
    );
  }, [isChildEntityListPage, loggedInUser]);

  return isShowBackToHomeBtn;
};
