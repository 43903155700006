import { useMemo } from 'react';
import { keyBy } from 'lodash';

type UseAutocompleteOneValueProps<
  T extends Record<string, string | number>,
  TValueKey extends keyof T,
  TLabelKey extends keyof T,
> = {
  userOptions: T[];
  valueKey: TValueKey;
  labelKey: TLabelKey;
};

export const useAutocompleteOneValue = <
  T extends Record<string, string | number>,
  TValueKey extends keyof T,
  TLabelKey extends keyof T,
>({
  userOptions,
  valueKey,
  labelKey,
}: UseAutocompleteOneValueProps<T, TValueKey, TLabelKey>) => {
  const optionsByValue = useMemo(() => keyBy(userOptions, valueKey), [userOptions]);

  const options = useMemo(
    () => userOptions.map((option) => option[valueKey]),
    [userOptions],
  );

  const getOptionLabel = (value: T[TValueKey]): string =>
    optionsByValue[value]?.[labelKey].toString() ?? '';

  return { options, getOptionLabel };
};
