import React from 'react';

import classes from './InfoCell.module.scss';
import { getRoundedNumberOrDash } from '../../../../shared/utils/mathUtils';

type InfoCellProps = {
  count: number;
  description: string;
};

const InfoCell = ({ count, description }: InfoCellProps) => {
  return (
    <div className={classes.infoCell}>
      <p className={classes.data}>{getRoundedNumberOrDash(count)}</p>
      <p className={classes.description}>{description}</p>
    </div>
  );
};

export { InfoCell };
