const autoNgTemplateLoaderTemplate1 = require('./user-profile-tab.template.html');

'use strict';

angular
    .module('app')
    .directive('userProfileTab', userProfileTab);

userProfileTab.$inject = [];

function userProfileTab() {
    return {
        restrict: 'E',
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: 'UserProfileTabController',
        controllerAs: 'vm',
    };
}
