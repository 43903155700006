import React, { useCallback } from 'react';

import classNames from 'classnames';

import classes from './NutrientValues.module.scss';
import { NUTRIENTS_ENUM } from '../../../shared/constants/NUTRIENTS_ENUM';
import { Nutrient } from '../../../shared/components/ui-components';
import { getRoundedNumber } from '../../../shared/utils/mathUtils';
import { getEnumArray } from '../../../shared/utils/jsUtils';

const NutrientValues = ({ content, iconShown, coloredText, excludeNutrients = [] }) => {
  const nutrientValueClassName = classNames({ [classes.nutrientGoal]: coloredText });
  const nutrients = useCallback(getEnumArray(NUTRIENTS_ENUM, excludeNutrients), []);

  return nutrients.map((nutrient) => {
    const roundedNutrientValue = getRoundedNumber(content[nutrient], 1);

    return (
      <Nutrient
        key={nutrient}
        coloredText={coloredText}
        iconShown={iconShown}
        nutrient={nutrient}
        className={nutrientValueClassName}
      >
        {roundedNutrientValue}
      </Nutrient>
    );
  });
};

export { NutrientValues };
