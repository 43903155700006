import React from 'react';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';

import classes from './ScoreForm.module.scss';
import { LbRadioGroup } from '../../../../../shared/components/form-components/LbRadioGroup/LbRadioGroup';
import { getDefaultValue } from './ScoreForm.settings';
import { YesNoBtnRow } from '../../../../../shared/components/buttons';
import { foodScoreStore } from '../../../../stores/foodScoreStore';
import { transformToOptions } from '../../../../../shared/utils/arrayUtils';

const ScoreForm = observer(
  ({ foodScoreResultList, cancelHandler, control, onSubmit }) => {
    const { foodScoreTypes } = foodScoreStore;

    const radioForm = foodScoreTypes.map((type) => {
      const options = transformToOptions({
        list: type.settings,
        labelKey: 'foodScoreLabel',
        valueKey: 'foodScoreValue',
      });

      const defaultValue = getDefaultValue(foodScoreResultList, type.foodScoreType);

      return (
        <LbRadioGroup
          key={type.foodScoreType}
          control={control}
          name={type.foodScoreType}
          label={type.typeLabel}
          options={options}
          rules={{ required: 'Select score is required' }}
          defaultValue={defaultValue}
        />
      );
    });

    return (
      <form onSubmit={onSubmit}>
        <YesNoBtnRow
          yesText="Save"
          noText="Cancel"
          className={classes.btnRow}
          noHandler={cancelHandler}
        />
        <div className={classes.descriptionRow}>
          <h4>Description</h4>
          <p>Empty</p>
        </div>
        <div className={classes.formBox}>{radioForm}</div>
      </form>
    );
  },
);

ScoreForm.propTypes = {
  food: PropTypes.shape({
    foodScoreResultList: PropTypes.array,
  }),
  cancelHandler: PropTypes.func,
  control: PropTypes.object,
  onSubmit: PropTypes.func,
};

export { ScoreForm };
