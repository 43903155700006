import { getService } from 'react-in-angularjs';

// same as in utilService.goTo
type GoToConfig = {
  keepQueryParams?: boolean;
  replaceHistory?: boolean;
  params?: Record<string, any>;
};

export const goTo = (path: string, config?: GoToConfig) => {
  const utilService = getService('utilService');
  const $rootScope = getService('$rootScope');

  utilService.goTo(path, config);

  $rootScope.$apply();
};
