import { apiService } from './apiService';

type VersionInfo = {
  build: string;
  date: string;
  type: string;
};

export const releasesService = {
  async getReleaseStatus() {
    const resp = await apiService.get('api/coach/update-status');

    return resp.data;
  },

  async getApiVersion() {
    const resp = await apiService.get<VersionInfo>('version', {
      isRunGlobalSpinner: false,
    });

    return resp.data;
  },

  async getUiVersion(): Promise<VersionInfo | undefined> {
    const resp = await fetch('version.json');
    if (resp.ok) {
      const data = await resp.json();

      return data;
    }

    return undefined;
  },
};
