import React from 'react';

import classes from './PaymentDetailsRow.module.scss';

type PaymentDetailsRowProps = {
  title: string;
  value: string;
};

const PaymentDetailsRow = ({ title, value }: PaymentDetailsRowProps) => {
  return (
    <div className={classes.container}>
      <p className={classes.title}>{title}</p>
      <p className={classes.value}>{value}</p>
    </div>
  );
};

export { PaymentDetailsRow };
