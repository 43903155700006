import React from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { observer } from 'mobx-react';
import { LbBigModal } from '../../../../../shared/components/modals';
import { renderReportSetting } from './utils/renderReportSetting';
import { reportStore } from '../../../../stores/reportStore';
import { reportService } from '../../../../services/reportService';

const ReportDownloadModal = observer(() => {
  const {
    isShowDownloadModal,
    selectedReport,
    selectedFileExtension,
    closeDownloadModal,
  } = reportStore;
  const formMethods = useForm({ shouldUnregister: true });
  const { handleSubmit } = formMethods;

  const { settings, reportType, title } = selectedReport;

  const onSubmit = (reportConfig: any) => {
    if (!selectedFileExtension) {
      throw new Error('selectedFileExtension not exist');
    }

    reportService.downloadReport(reportType, selectedFileExtension, reportConfig);
    closeDownloadModal();
  };

  return (
    <LbBigModal
      open={isShowDownloadModal}
      title={`Create Report “${title}”`}
      buttonOptions={{
        noHandler: closeDownloadModal,
        yesHandler: handleSubmit(onSubmit),
        yesText: 'Download',
      }}
    >
      <FormProvider {...formMethods}>
        <form>{renderReportSetting({ settings })}</form>
      </FormProvider>
    </LbBigModal>
  );
});

export { ReportDownloadModal };
