import React from 'react';

import { Button } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import classes from './AddNewFilterButton.module.scss';
import { customFilterStore } from '../../../stores/customFilterStore';

const AddNewFilterButton = () => {
  return (
    <Button
      variant="text"
      color="secondary"
      size="small"
      fullWidth
      startIcon={<AddRoundedIcon />}
      className={classes.addFilterBtn}
      onClick={() => customFilterStore.openAddFilterModal()}
    >
      Create New ....
    </Button>
  );
};

export { AddNewFilterButton };
