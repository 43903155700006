/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

import classes from './SupplementRows.module.scss';
import { Cell } from '../Cell/Cell';
import { StatusCell } from '../StatusCell/StatusCell';

const notFoundSupplement = { supplementName: 'Supplement not found' };

const SupplementRows = ({ schedule, customerSupplements }) => {
  return (
    <div>
      {schedule.map((item, index) => {
        const { supplementId, currentServing, totalServing, amount, status } = item;

        const supplement = customerSupplements[supplementId] ?? notFoundSupplement;

        const { supplementName, amountUnit } = supplement;

        const left = `${currentServing} / ${totalServing}`;

        return (
          <div className={classes.supplementRow} key={index}>
            <Cell text={supplementName} />
            <Cell text={amount} adornment={amountUnit} />
            <Cell text={left} adornment={amountUnit} />
            <StatusCell status={status} />
          </div>
        );
      })}
    </div>
  );
};

SupplementRows.propTypes = {
  schedule: PropTypes.array,
  customerSupplements: PropTypes.object,
};

export { SupplementRows };
