import React from 'react';

import classes from './WaterPlaceholder.module.scss';
import waterPlaceholderImg from '../../../../../assets/image/waterPlaceholder.png';

const WaterPlaceholder = () => {
  return (
    <div className={classes.box}>
      <p className={classes.text}>User doesn&apos;t have Water statistics</p>
      <img src={waterPlaceholderImg} alt="placeholder" />
    </div>
  );
};

export { WaterPlaceholder };
