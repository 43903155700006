import React, { useEffect, useState } from 'react';

import { angularize } from 'react-in-angularjs';
import { observer } from 'mobx-react';
import { Button, Paper } from '@mui/material';
import moment from 'moment';

import classes from './NgzActiveCalendarWaterTab.module.scss';
import { SystemProviders } from '../../../../../shared/providers';
import { CalendarColumn } from '../../components/CalendarColumn/CalendarColumn';
import { TabTitleRow } from '../../components/TabTitleRow/TabTitleRow';
import { WaterTable } from './WaterTable/WaterTable';
import { getRouteParam } from '../../../../../shared/utils/angularUtils';
import { scrollToDay } from './NgzActiveCalendarWaterTab.settings';
import { EditWaterGoalModal } from './EditWaterGoalModal/EditWaterGoalModal';
import {
  getMonthFirstDay,
  getMonthLastDayOrToday,
} from '../../../../../shared/utils/dateUtils';
import { useModalToggle } from '../../../../../shared/hooks/useModalToggle';
import { customerWaterGoalStore } from '../../../../stores/customerWaterGoalStore';
import { WaterPlaceholder } from './WaterPlaceholder/WaterPlaceholder';
import { CreateWaterGoalModal } from './CreateWaterGoalModal/CreateWaterGoalModal';

const NgzActiveCalendarWaterTab = observer(() => {
  const [selectedDay, setSelectedDay] = useState(moment());
  const [selectedMonth, setSelectedMonth] = useState(getMonthFirstDay());
  const [isEditGoalModalOpen, editGoalModalHandler] = useModalToggle();
  const [isCreateGoalModalOpen, createGoalModalHandler] = useModalToggle();

  const {
    successfulDays,
    startGoalDate,
    monthScore,
    initDateToRender,
    setDefaultValues,
  } = customerWaterGoalStore;
  const isWaterGoalCreated = startGoalDate;

  const customerId = getRouteParam('id');

  useEffect(() => {
    initDateToRender(customerId, selectedMonth);
  }, [selectedMonth]);

  useEffect(() => setDefaultValues, []);

  const onMonthChange = (firstMonthDay) => {
    setSelectedMonth(firstMonthDay);

    const day = getMonthLastDayOrToday(firstMonthDay);
    setSelectedDay(day);
  };

  const onDataChange = (date) => {
    scrollToDay(date, selectedDay);
    setSelectedDay(date);
  };

  const onTabTitleBtnClick = () => {
    if (isWaterGoalCreated) {
      editGoalModalHandler();
    } else {
      createGoalModalHandler();
    }
  };

  const scoreInfo = [{ title: 'Total score per month', score: monthScore }];

  const modalBtnText = isWaterGoalCreated ? 'Change goal' : 'Create Goal';

  const WaterTabComponent = (
    <Paper variant="roundedBox" className={classes.box}>
      <CalendarColumn
        color="blue"
        date={selectedDay}
        setDate={onDataChange}
        onMonthChange={onMonthChange}
        successfulDays={successfulDays}
        scoreInfo={scoreInfo}
        minDate={moment(startGoalDate)}
        descriptionText="Days with 4.5 and 5 ratings"
      />
      <WaterTable />
    </Paper>
  );

  return (
    <SystemProviders>
      <TabTitleRow title="Water">
        <Button variant="outlined" onClick={onTabTitleBtnClick}>
          {modalBtnText}
        </Button>
      </TabTitleRow>
      {!isWaterGoalCreated ? <WaterPlaceholder /> : WaterTabComponent}
      <EditWaterGoalModal
        open={isEditGoalModalOpen}
        closeHandler={editGoalModalHandler}
        customerId={customerId}
        selectedMonth={selectedMonth}
      />
      <CreateWaterGoalModal
        open={isCreateGoalModalOpen}
        closeHandler={createGoalModalHandler}
        customerId={customerId}
      />
    </SystemProviders>
  );
});

angularize(
  NgzActiveCalendarWaterTab,
  'ngzActiveCalendarWaterTab',
  angular.module('app.active-calendar'),
  {},
);
