import React from 'react';

import {
  GridTableCell,
  GridTableRow,
} from '../../../../../../shared/components/table-components';

const ReportsTableHead = () => {
  return (
    <GridTableRow variant="header">
      <GridTableCell>Title</GridTableCell>
      <GridTableCell>Description</GridTableCell>
      <GridTableCell>Download</GridTableCell>
    </GridTableRow>
  );
};

export { ReportsTableHead };
