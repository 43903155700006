import { makeAutoObservable } from 'mobx';
import { resetAllStore } from '../../shared/stores/resetAllStore';
import { medicalTestsService } from '../services/medicalTestsService';

class MedicalTestsStore {
  constructor() {
    this.setDefaultValues();
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.tests = [];
    this.isTestsDisabled = false;
  }

  getTests() {
    if (!this.tests.length) {
      this.downloadTests();
    }
  }

  async downloadTests() {
    const tests = await medicalTestsService.getTests();
    this.setTests(tests);
    this.setIsTestsDisabled(!tests.length);
  }

  setIsTestsDisabled(isDisabled) {
    this.isTestsDisabled = isDisabled;
  }

  async createTest(testName) {
    const newTest = await medicalTestsService.createTest(testName);
    this.setTests([...this.tests, newTest]);
    this.setIsTestsDisabled(false);
  }

  async editTest(test, testName) {
    const editedTest = await medicalTestsService.editTest(test, testName);
    this.changeTest(editedTest);
  }

  async activateTest(testId) {
    const activatedTest = await medicalTestsService.activateTest(testId);
    this.changeTest(activatedTest);
  }

  async deactivateTest(testId) {
    const deactivatedTest = await medicalTestsService.deactivateTest(testId);
    this.changeTest(deactivatedTest);
  }

  changeTest(newTest) {
    const testIndex = this.tests.findIndex((test) => test.id === newTest.id);
    const newTests = [...this.tests];
    newTests[testIndex] = newTest;
    this.setTests(newTests);
  }

  setTests(tests) {
    this.tests = tests;
  }
}

const medicalTestsStore = new MedicalTestsStore();

resetAllStore.addResetMethod(medicalTestsStore.setDefaultValues);

export { medicalTestsStore, MedicalTestsStore };
