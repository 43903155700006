import { userRoles } from '../../../../constants/userRoles';
import { LoggedInUser } from '../../../../interfaces/LoggedInUser';

export const getUserName = (loggedInUser: LoggedInUser) => {
  const { firstName, corporateAccountInfo, role } = loggedInUser;

  if (role === userRoles.ADMIN) {
    return loggedInUser.gymName;
  }

  return firstName || corporateAccountInfo?.account_name;
};
