import React from 'react';
import classNames from 'classnames';

import classes from './AvatarIcon.module.scss';

type AvatarIconProps = {
  userName?: string;
  avatarImgLink?: string | null;
  size?: 'small' | 'large';
  className?: string;
};

const AvatarIcon = ({
  userName,
  avatarImgLink,
  size = 'small',
  className,
}: AvatarIconProps) => {
  const userInitial = userName ? userName[0].toUpperCase() : undefined;

  const iconBoxClasses = classNames(classes.iconBox, classes[size], className);

  return (
    <div className={iconBoxClasses}>
      {avatarImgLink ? <img src={avatarImgLink} alt="avatar" /> : userInitial}
    </div>
  );
};

export { AvatarIcon };
