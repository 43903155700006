import React from 'react';

import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react';
import { Button } from '@mui/material';

import { SelectedInfoRow } from '../../../../shared/components/table-components';
import { CustomerCommunicationFilterFormValues } from '../../../interfaces/CustomerCommunicationFilters';
import { GeneralFilters } from './GeneralFilters/GeneralFilters';
import { customerCommunicationStore } from '../../../stores/customerCommunicationStore';
import { loggedInUserDataStore } from '../../../../shared/stores/loggedInUserDataStore';
import { ReassignCustomersButton } from './ReassignCustomersButton/ReassignCustomersButton';
import { useAutoSubmitForm } from '../../../../shared/hooks/useAutoSubmitForm';
import { filterDefaultValues } from './FilterRow.settings';

const FilterRow = observer(() => {
  const { isEntity } = loggedInUserDataStore;
  const { selectMethod, filterMethod, downloadReport, sendMessage } =
    customerCommunicationStore;
  const { getSelectedItemCount } = selectMethod;
  const { applyFilters, filterData } = filterMethod;
  const { control, handleSubmit, reset, watch } =
    useForm<CustomerCommunicationFilterFormValues>({
      defaultValues: filterData,
    });

  useAutoSubmitForm(handleSubmit(applyFilters), watch, [
    'generalFilter.fullName',
    'generalFilter.entityName',
    'generalFilter.coachName',
  ]);

  const handleReset = () => {
    reset(filterDefaultValues);
  };

  const handleDownloadReportForSelected = () => {
    downloadReport(false);
  };

  const handleContactToSelected = () => {
    sendMessage(false);
  };

  return (
    <>
      <GeneralFilters control={control} onReset={handleReset} />
      <SelectedInfoRow selectedItemCount={getSelectedItemCount()} isSubGridRow>
        <Button size="small" variant="contained" onClick={handleContactToSelected}>
          Contact Selected
        </Button>
        {isEntity && <ReassignCustomersButton />}
        <Button
          size="small"
          variant="outlined"
          className="wightBg"
          onClick={handleDownloadReportForSelected}
        >
          Export in CSV
        </Button>
      </SelectedInfoRow>
    </>
  );
});

export { FilterRow };
