import React from 'react';
import PropTypes from 'prop-types';

import {
  GridTableCell,
  GridTableRow,
} from '../../../../../../../shared/components/table-components';
import { ComplianceTitleCells } from '../../../../../../components/ComplianceTitleCells/ComplianceTitleCells';
import { SortButtons } from '../../../../../../../shared/components/buttons';

const TitleRow = ({ sortMethods }) => {
  const { handleSort, getIsSortedBy } = sortMethods;

  return (
    <GridTableRow variant="header">
      <GridTableCell justify="start">
        <SortButtons
          fieldName="date"
          onSort={handleSort}
          getIsActive={getIsSortedBy}
          tooltipTitle={{ asc: 'Sort Oldest to Newest', desc: 'Sort Newest to Oldest' }}
        >
          Date
        </SortButtons>
      </GridTableCell>
      <ComplianceTitleCells />
    </GridTableRow>
  );
};

TitleRow.propTypes = {
  sortMethods: PropTypes.object,
};

export { TitleRow };
