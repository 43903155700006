import React from 'react';

import classes from './MealInfoRow.module.scss';
import { RegularMealName } from '../../../../../../../../shared/constants/mealNames';
import { processMealName } from '../../../../../../../../shared/utils/mealUtils';
import { MainNutrientMetricsInfo } from '../../../../../../../interfaces/performance-metrics/NutrientMetrics';

type MealInfoRowProps = {
  meal: RegularMealName;
  mealInfo: MainNutrientMetricsInfo | null;
};

const MealInfoRow = ({ meal, mealInfo }: MealInfoRowProps) => {
  return (
    <div className={classes.row}>
      <span className={classes.meal}>{processMealName(meal)}</span>
      <span>{mealInfo ? `${mealInfo.avgTrackedPercent}%` : '—'}</span>
    </div>
  );
};

export { MealInfoRow };
