import { Option } from '../../../../../../../../general-types';

export const nutrientOptions: Option[] = [
  { label: 'Proteins', value: 'PROTEINS' },
  { label: 'Carbs', value: 'CARBS' },
  { label: 'Fats', value: 'FATS' },
  { label: 'Sugar', value: 'SUGAR' },
  { label: 'Fiber', value: 'FIBER' },
  { label: 'Sodium', value: 'SODIUM' },
  { label: 'Veggies', value: 'VEGGIES' },
  { label: 'Water', value: 'WATER' },
  { label: 'Calories', value: 'CALORIES' },
];
