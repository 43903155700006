import React, { useEffect } from 'react';

import { observer } from 'mobx-react';

import classes from './CorporateAccountsTable.module.scss';
import { GridTableRow } from '../../../../../../shared/components/table-components';
import { corporateAccountsStore } from '../../../../../stores/corporateAccountsStore';
import { CorporateAccountsTableRow } from './CorporateAccountsTableRow/CorporateAccountsTableRow';

const CorporateAccountsTable = observer(() => {
  const { openModal, corporateAccounts, getCorporateAccounts, deleteAccount } =
    corporateAccountsStore;

  useEffect(() => {
    getCorporateAccounts();
  }, []);

  return (
    <div className={classes.corporateAccountsTable}>
      <GridTableRow variant="header">
        <span>Login</span>
        <span>Password</span>
      </GridTableRow>
      {corporateAccounts.map((account) => (
        <CorporateAccountsTableRow
          key={account.id}
          account={account}
          openModal={openModal}
          deleteAccount={deleteAccount}
        />
      ))}
    </div>
  );
});

export { CorporateAccountsTable };
