import { IconButton, InputAdornment } from '@mui/material';
import React, { MouseEvent, MouseEventHandler } from 'react';

import { VisibilityOffRounded, VisibilityRounded } from '@mui/icons-material';

type ShowPasswordButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  isShowPassword: boolean;
};

const ShowPasswordButton = ({ onClick, isShowPassword }: ShowPasswordButtonProps) => {
  const handlePreventFocusLoss = (event: MouseEvent) => {
    event.preventDefault();
  };

  return (
    <InputAdornment position="end">
      <IconButton onClick={onClick} onMouseDown={handlePreventFocusLoss}>
        {isShowPassword ? (
          <VisibilityOffRounded fontSize="large" />
        ) : (
          <VisibilityRounded fontSize="large" />
        )}
      </IconButton>
    </InputAdornment>
  );
};

export { ShowPasswordButton };
