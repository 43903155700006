import { ANGULAR_EVENT_KEYS } from '../../../constants/system/ANGULAR_EVENT_KEYS';
import { clearUserInfo } from '../clearUserInfo/clearUserInfo';
import { initUserInfo } from '../initUserInfo/initUserInfo';
import { LOCAL_STORAGE_KEYS } from '../../../constants/system/LOCAL_STORAGE_KEYS';
import { broadcastEvent } from '../../angularUtils';

export const setUserInfo = async (userData) => {
  clearUserInfo();

  localStorage.setItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER, JSON.stringify(userData));

  await initUserInfo();

  broadcastEvent(ANGULAR_EVENT_KEYS.UPDATE_USER);
};
