export const getGoalStatus = (actual, goal, goalIsLess) => {
  const lessThanGoal = actual < goal;

  if (!actual) {
    return 'goalNotExist';
  } else if (lessThanGoal) {
    return goalIsLess ? 'goodGoal' : 'badGoal';
  } else {
    return goalIsLess ? 'badGoal' : 'goodGoal';
  }
};
