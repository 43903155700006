import React from 'react';

import { useFormContext } from 'react-hook-form';

import { InfoBox } from '../../../../../../shared/components/ui-components';
import { LbRadioGroup } from '../../../../../../shared/components/form-components';
import { RadioOption } from '../../../../../../general-types';

type MealsPerDayFormValues = { defaultMealsPerDay: number };

const MealsPerDay = () => {
  const { control } = useFormContext<MealsPerDayFormValues>();

  const options: RadioOption[] = [
    { label: '3 Meals & 3 Snacks', value: 6 },
    { label: '3 Meals & 2 Snacks', value: 5 },
  ];

  return (
    <InfoBox title="Choose the number of meals per day for the meal plan">
      <LbRadioGroup control={control} name="defaultMealsPerDay" options={options} row />
    </InfoBox>
  );
};

export { MealsPerDay, MealsPerDayFormValues };
