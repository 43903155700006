import { useEffect, useMemo, useState } from 'react';

import { max } from 'lodash';

import { PerformanceSummaryGraph } from '../../../../../interfaces/performanceSummary';
import { RevenueDateRangeFilterValues } from '../../../DetailedRevenue.settings';
import { detailedRevenueService } from '../../../../../services/detailedRevenueService';
import { getDateLabel } from '../utils/getDateLabel';
import { getMiddleDate, isFuture } from '../../../../../../shared/utils/dateUtils';

type GraphInfo = {
  labels: string[];
  values: (number | null)[];
};

type UseTotalAmountGraphInfoReturn = GraphInfo & {
  maxAmount: number | undefined;
};

export const useTotalAmountGraphInfo = (
  entityId: number,
  dateRange: RevenueDateRangeFilterValues,
): UseTotalAmountGraphInfoReturn => {
  const [performanceSummary, setPerformanceSummary] =
    useState<PerformanceSummaryGraph | null>(null);

  const getPerformanceSummary = async () => {
    const data = await detailedRevenueService.getPerformanceSummaryGraph(entityId, {
      generalFilter: { ...dateRange },
    });

    setPerformanceSummary(data);
  };

  useEffect(() => {
    getPerformanceSummary();
  }, [entityId, dateRange]);

  const getGraphInfo = (): GraphInfo => {
    if (!performanceSummary) {
      return { labels: [], values: [] };
    }

    return performanceSummary.data.reduce<GraphInfo>(
      (acc, item) => {
        const date = getMiddleDate(item.periodFrom, item.periodTo);
        const label = getDateLabel(date, performanceSummary.groupBy);
        const value = isFuture(date) ? null : item.totalAmount;

        acc.labels.push(label);
        acc.values.push(value);

        return acc;
      },
      { labels: [], values: [] },
    );
  };

  const graphInfo = useMemo(() => getGraphInfo(), [performanceSummary]);

  const maxAmount = max(graphInfo.values) ?? undefined;

  return {
    ...graphInfo,
    maxAmount,
  };
};
