import React from 'react';

import { observer } from 'mobx-react';

import {
  CheckboxCell,
  GridTableCell,
  GridTableRow,
  UserInfoCell,
} from '../../../../shared/components/table-components';
import { CustomerContactLink } from '../../../../shared/components/buttons';
import { ScholasticCustomerInfo } from '../../../interfaces/scholastic-list/ScholasticCustomerInfo';
import { scholasticCustomersListStore } from '../../../stores/ScholasticCustomersListStore';
import { StatusCell } from './StatusCell/StatusCell';
import { getLinkForCustomerInfo } from './utils/getLinkForCustomerInfo';
import { getStartDayInfo } from './utils/getStartDay';

type CustomerRowProps = {
  userInfo: ScholasticCustomerInfo;
};

const CustomerRow = observer(({ userInfo }: CustomerRowProps) => {
  const { isShowLocationColumn, isShowCoachColumn, selectMethod, filterMethod } =
    scholasticCustomersListStore;
  const { getIsSelected, selectItem } = selectMethod;
  const { isShowFilterRow } = filterMethod;

  const coachName = `${userInfo.coachFirstName} ${userInfo.coachLastName}`;

  const isChecked = getIsSelected(userInfo.id);
  const handleCheck = () => {
    selectItem(userInfo.id);
  };

  const link = getLinkForCustomerInfo(userInfo.id, userInfo.scholasticStatus);

  const startDayInfo = getStartDayInfo(userInfo.subscriptionStart);

  return (
    <GridTableRow
      variant="row"
      highlight={userInfo.newScansAvailable}
      dataTestId="customer-row"
    >
      <CheckboxCell
        showCheckbox={isShowFilterRow}
        onCheck={handleCheck}
        isChecked={isChecked}
      >
        <UserInfoCell
          firstName={userInfo.firstName}
          lastName={userInfo.lastName}
          email={userInfo.email}
          phone={userInfo.phone}
          href={link}
          dataTestId="scholastic-info-cell"
        />
      </CheckboxCell>
      <GridTableRow variant="unstyled">
        {isShowLocationColumn && (
          <GridTableCell dataTestId="location-cell">{userInfo.gymName}</GridTableCell>
        )}
        <GridTableCell dataTestId="school-cell">{userInfo.school}</GridTableCell>
        {isShowCoachColumn && (
          <GridTableCell dataTestId="coach-cell">{coachName}</GridTableCell>
        )}
        <GridTableCell dataTestId="start-cell">{startDayInfo}</GridTableCell>
        <StatusCell status={userInfo.scholasticStatus} />
        <div />
      </GridTableRow>
      <GridTableCell justify="end" dataTestId="contact-cell">
        <CustomerContactLink
          customerId={userInfo.id}
          useReactModal
          resourceType="scholastic"
        />
      </GridTableCell>
    </GridTableRow>
  );
});

export { CustomerRow };
