import { palette } from '../../../../../../shared/theme/palette';

type DrawPointOptions = {
  pointX: number;
  pointY: number;
};

export const drawPoint = (ctx: CanvasRenderingContext2D, options: DrawPointOptions) => {
  const { pointX, pointY } = options;

  ctx.save();
  ctx.beginPath();

  // bag in linter
  // eslint-disable-next-line prefer-destructuring
  ctx.strokeStyle = palette.info[400];
  ctx.fillStyle = palette.white;
  ctx.lineWidth = 2;
  ctx.arc(pointX, pointY, 4, 0, Math.PI * 2);
  // stroke after fill to avoid the line being covered by the circle
  ctx.fill();
  ctx.stroke();

  ctx.restore();
};
