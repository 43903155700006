import { AxiosRequestConfig } from 'axios';
import { UploadFileTypes } from '../constants/uploadFileTypes';
import { LoadedFile } from '../interfaces/LoadedFile';
import { apiService } from './apiService';

type RequestConfig = Pick<AxiosRequestConfig, 'isRunGlobalSpinner'>;

// TODO update test and rewrite to ts
export const fileService = {
  async uploadFile(uploadType: UploadFileTypes, file: File, config?: RequestConfig) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const params = { upload_type: uploadType };

    const resp = await apiService.post<LoadedFile>(`api/upload_file`, formData, {
      params,
      ...config,
    });

    return resp.data;
  },

  async uploadFiles(uploadType: UploadFileTypes, files: File[]) {
    return Promise.all(files.map((file) => fileService.uploadFile(uploadType, file)));
  },

  async deleteFile(fileId: number) {
    const resp = await apiService.delete(`api/upload_file/${fileId}`);

    return resp.data;
  },
};
