import { makeAutoObservable } from 'mobx';
import { PersonalInfo } from '../../interfaces/Customer';
import { resetAllStore } from '../../../shared/stores/resetAllStore';

class PersonalInfoStore {
  customerId?: number;
  personalInfo?: PersonalInfo;
  isTermsAccepted: boolean = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.customerId = undefined;
    this.personalInfo = undefined;
    this.isTermsAccepted = false;
  }

  setPersonalInfo(personalInfo: PersonalInfo) {
    this.personalInfo = personalInfo;
  }

  setCustomerId(customerId: number) {
    this.customerId = customerId;
  }

  setIsTermsAccepted(isTermsAccepted: boolean) {
    this.isTermsAccepted = isTermsAccepted;
  }
}

const personalInfoStore = new PersonalInfoStore();
resetAllStore.addResetMethod(personalInfoStore.setDefaultValues);

export { personalInfoStore, PersonalInfoStore };
