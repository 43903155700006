import { getFormattedToday } from '../../shared/utils/dateUtils';
import {
  ConsumptionInfo,
  ConsumptionNutrition,
} from '../interfaces/consumption/ConsumptionInfo';

const defaultNutritionInfo: ConsumptionNutrition = {
  proteins: 0,
  carbs: 0,
  fats: 0,
  sugar: 0,
  fiber: 0,
  sodium: 0,
};

const dbDateFormat = 'MM.DD.YYYY';

export const defaultConsumptionInfo: ConsumptionInfo = {
  actual: defaultNutritionInfo,
  goal: defaultNutritionInfo,
  lastScanDate: getFormattedToday(dbDateFormat),
  previousScanDate: getFormattedToday(dbDateFormat),
  startDate: getFormattedToday(),
  endDate: getFormattedToday(),
};
