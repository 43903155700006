import { useState } from 'react';

import { getService } from 'react-in-angularjs';

import { closeNgModal } from '../../../shared/utils/angularUtils';
import { chatService } from '../../services/chatService';
import { fileService } from '../../../shared/services/fileService';
import { uploadFileTypes } from '../../../shared/constants/uploadFileTypes';

export const useSendViaChatForm = (userIdList, openSendingMessageModal) => {
  const [files, setFiles] = useState([]);
  const $rootScope = getService('$rootScope');

  const onSubmit = async (data) => {
    openSendingMessageModal();
    const { message } = data;

    try {
      await sendMessage(message);
    } catch (error) {
      closeNgModal();
    }
  };

  const sendMessage = async (message) => {
    const attachmentFileIds = await getAttachmentFileIds();
    await chatService.sendMessage(message, userIdList, attachmentFileIds);
    $rootScope.$broadcast('finishSending');
  };

  const getAttachmentFileIds = async () => {
    const uploadFilesResults = await uploadFiles();
    return uploadFilesResults.map((item) => item.id);
  };

  const uploadFiles = async () => {
    return Promise.all(
      files.map((file) => fileService.uploadFile(uploadFileTypes.CHAT_ATTACHMENT, file)),
    );
  };

  return {
    files,
    setFiles,
    onSubmit,
  };
};
