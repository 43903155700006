import React, { useEffect, useState } from 'react';

import { angularize } from 'react-in-angularjs';
import { observer } from 'mobx-react';
import { Box } from '@mui/material';

import { SystemProviders } from '../../../shared/providers';
import { Header } from './components/Header/Header';
import { DeclineRequestModal } from './components/DeclineRequestModal/DeclineRequestModal';
import { stepperStore } from '../../stores/stepperStore';
import { childInformationStore } from '../../stores/childInformationStore';
import { CreditCardInfo } from './containers/CreditCardInfo/CreditCardInfo';
import classes from './NgzProceedRegistration.module.scss';
import { Terms } from './containers/Terms/Terms';
import { PersonalInfo } from './containers/PersonalInfo/PersonalInfo';
import { Subscription } from './containers/Subscription/Subscription';
import { Finish } from './containers/Finish/Finish';

const NgzProceedRegistration = observer(() => {
  const defaultSteps = [
    { step: <Terms />, label: 'Term' },
    { step: <PersonalInfo />, label: 'Personal Information' },
    { step: <Subscription />, label: 'Subscription' },
    { step: <Finish />, label: 'Finish' },
  ];
  const [steps, setSteps] = useState(defaultSteps);
  const { activeStep, setStepsCount, stepsCount } = stepperStore;
  const { loadChildInfo, isEntityWithStripe } = childInformationStore;

  useEffect(() => {
    loadChildInfo();
  }, []);

  useEffect(() => {
    processSteps();
  }, [isEntityWithStripe]);

  const processSteps = () => {
    if (isEntityWithStripe) {
      addCreditCardStep();
    }
  };

  const addCreditCardStep = () => {
    const creditCardInfoStepIndex = stepsCount - 1;
    const creditCardInfoStep = { step: <CreditCardInfo />, label: 'Credit card info' };
    const newSteps = [...steps];
    newSteps.splice(creditCardInfoStepIndex, 0, creditCardInfoStep);
    setSteps(newSteps);
    setStepsCount(newSteps.length);
  };

  const notLastStep = activeStep !== stepsCount;

  return (
    <SystemProviders>
      <Box className={classes.container}>
        {notLastStep && <Header />}
        {steps[activeStep - 1].step}
        <DeclineRequestModal />
      </Box>
    </SystemProviders>
  );
});

angularize(
  NgzProceedRegistration,
  'ngzProceedRegistration',
  angular.module('app.preCheckIn'),
  {},
);

export default NgzProceedRegistration;
