import type { ChartOptions } from 'chart.js';

import { palette } from '../../../../../../shared/theme/palette';
import {
  PerformanceScore,
  PerformanceScoreInfo,
} from '../../../../../interfaces/performance-metrics/PerformanceScore';

const scoreInfo: PerformanceScoreInfo = {
  avgComplianceScoreTotal: 0,
  avgComplianceScoreTracked: 0,
};

export const defaultPerformanceScore: PerformanceScore = {
  ...scoreInfo,
  breakfast: scoreInfo,
  snack1: scoreInfo,
  lunch: scoreInfo,
  snack2: scoreInfo,
  dinner: scoreInfo,
  snack3: scoreInfo,
};

export const chartOption: ChartOptions<'bar'> = {
  maintainAspectRatio: false,
  animation: false,

  plugins: {
    tooltip: { enabled: false },
    legend: { display: false },
    datalabels: {
      font: { weight: 500, family: 'Lato', size: 12 },
      anchor: 'end',
      offset: 1,
      align: 'top',
      color: palette.mainBlack,
    },
  },
  elements: {
    bar: {
      borderRadius: 5,
      borderSkipped: false,
    },
  },
  events: [],
  scales: {
    y: {
      stacked: false,
      max: 5.8, // max value is 5 (5 stars) + 0.8 (space for labels)
      min: 0,
      ticks: { display: false, count: 3 },
      border: { display: false, dash: [4, 4] },
      grid: { color: palette.natural[200] },
    },
    x: {
      border: { display: false },
      grid: { display: false },
      ticks: {
        color: palette.mainBlack,
        font: { weight: 500, size: 14, family: 'Lato' },
        padding: 0,
      },
    },
  },
};
