import React from 'react';

import classNames from 'classnames';

import classes from './PriceLabelRow.module.scss';
import { getIsNullish } from '../../../../../shared/utils/jsUtils';
import { getPriceView } from '../../../../../shared/utils/stringUtils';

type PriceLabelRowProps = {
  label: string;
  value: number | string;
  className?: string;
};

const PriceLabelRow = ({ label, value, className }: PriceLabelRowProps) => {
  const rowClasses = classNames(classes.labelRow, className);

  const valueInfo = getIsNullish(value) ? 'No Info' : getPriceView(value);

  return (
    <div className={rowClasses}>
      <span className={classes.label}>{label}</span>
      <span className={classes.value}>{valueInfo}</span>
    </div>
  );
};

export { PriceLabelRow, PriceLabelRowProps };
