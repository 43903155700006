import { ValueOfKey } from '../../../../utils-types';

export const RESOURCE_TYPE = {
  CUSTOMERS: 'customers',
  SCHOLASTIC: 'scholastic',
} as const;

export type ResourceType = ValueOfKey<typeof RESOURCE_TYPE>;

export type ResourceTypeKey = keyof typeof RESOURCE_TYPE;

export type SelectedResourceType = Partial<Record<ResourceTypeKey, boolean>>;
