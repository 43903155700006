import React from 'react';

import classes from './InputBox.module.scss';
import { InfoBox, InfoBoxProps } from '../../ui-components';

type InputBoxProps = Omit<InfoBoxProps, 'customContentPadding'>;

const InputBox = ({ children, ...props }: InputBoxProps) => {
  return (
    <InfoBox {...props} customContentPadding>
      <div className={classes.inputBox}>{children}</div>
    </InfoBox>
  );
};

export { InputBox };
