import React, { useEffect, useState } from 'react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import classes from './RegistrationQrCodeModal.module.scss';
import { LbModal } from '../../../../../../../../shared/components/modals';
import { entityCustomerSetupService } from '../../../../../../../services/entityCustomerSetupService';
import { downloadFile } from '../../../../../../../../shared/utils/apiUtils';

type RegistrationQrCodeModalProps = {
  entityId: number;
  onClose: () => void;
};

const RegistrationQrCodeModal = ({ entityId, onClose }: RegistrationQrCodeModalProps) => {
  const [qrCodeUrl, setQrCodeUrl] = useState<string | undefined>(undefined);

  const getQrCodeUrl = async () => {
    const { fileUrl } = await entityCustomerSetupService.getRegistrationQrCode(entityId);

    setQrCodeUrl(fileUrl);
  };

  useEffect(() => {
    getQrCodeUrl();
  }, []);

  const handleDownload = () => {
    downloadFile(qrCodeUrl, 'registration-qr-code');
  };

  return (
    <LbModal
      open={!!qrCodeUrl}
      title="Scan QR code"
      text="Scan the code to access the registration form"
      primaryBtnConfig={{
        text: 'Download',
        startIcon: <FileDownloadOutlinedIcon />,
        onClick: handleDownload,
      }}
      secondaryBtnConfig={{
        text: 'Close',
        onClick: onClose,
      }}
    >
      <img src={qrCodeUrl} alt="qr code" className={classes.qrCode} />
    </LbModal>
  );
};

export { RegistrationQrCodeModal };
