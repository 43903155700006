import { apiService } from '../../shared/services/apiService';
import { AccessParams } from '../../shared/interfaces/AccessParams';

export const passwordServices = {
  async setPassword(accessParams: AccessParams, password: string) {
    const { hash, timestamp, token } = accessParams;

    const resp = await apiService.post(
      `/api/public/setpassword/${token}/${timestamp}/${hash}`,
      { password },
    );

    return resp.data;
  },
};
