import React, { useMemo } from 'react';

import { angularize } from 'react-in-angularjs';

import classes from './NgzPerformanceMetricsOverviewModal.module.scss';
import { SystemProviders } from '../../../shared/providers';
import { LbBigModal } from '../../../shared/components/modals';
import { TitleWithDescription } from '../../../shared/components/text-formation-components';
import { ConsumptionBetweenTable } from '../../components/consumption';
import { CheckList } from './CheckList/CheckList';
import { askList, checkList } from './NgzPerformanceMetricsOverviewModal.settings';
import { useConsumptionInfo } from './hooks/useConsumptionInfo';
import { getFormattedDate } from '../../../shared/utils/dateUtils';
import { dateFormat } from '../../../shared/constants/apiDateFormat';

const scanDateFormat = 'MM.DD.YYYY';

type NgzPerformanceMetricsOverviewModalProps = {
  customerId: number;
  scanDates: string[]; // MM.DD.YYYY
  onReview: () => void;
  onClose: () => void;
};

const NgzPerformanceMetricsOverviewModal = ({
  customerId,
  scanDates,
  onReview,
  onClose,
}: NgzPerformanceMetricsOverviewModalProps) => {
  const transformedScanDates = useMemo(
    () =>
      scanDates.map((scanDay) => getFormattedDate(scanDay, dateFormat, scanDateFormat)),
    [scanDates],
  );

  const { consumptionInfo, formattedFrom, formattedTo, isLessTwoScansExist } =
    useConsumptionInfo(customerId, transformedScanDates);

  return (
    <SystemProviders>
      <LbBigModal
        open
        title="Performance Metrics Overview"
        buttonOptions={{ yesText: 'Next', yesHandler: onReview }}
        onClose={onClose}
      >
        <div>
          <div className={classes.consumptionContainer}>
            <TitleWithDescription
              title={`Consumption Between ${formattedFrom} - ${formattedTo}`}
              description="Analyze the customer's progress by comparing two scans"
              titleFont="body-14-bold"
              className={classes.title}
            />
            <ConsumptionBetweenTable
              goal={consumptionInfo.goal}
              actual={consumptionInfo.actual}
            />
            {isLessTwoScansExist && (
              <div className={classes.infoBox}>
                <p>User should have at least 2 scans to compare the data</p>
              </div>
            )}
          </div>
          <TitleWithDescription
            title="Recommendations"
            description="Unless your client is doing these things, the scan summaries may not be accurate. 
            No matter what, look for linear trends over time and consider all factors after speaking with your client."
            titleFont="body-14-bold"
            className={classes.title}
          />
          <div className={classes.listBox}>
            <CheckList title="Check these things:" iconColor="success" list={checkList} />
            <CheckList title="Ask these questions:" iconColor="warning" list={askList} />
          </div>
        </div>
      </LbBigModal>
    </SystemProviders>
  );
};

angularize(
  NgzPerformanceMetricsOverviewModal,
  'ngzPerformanceMetricsOverviewModal',
  angular.module('app.nutrition'),
  {
    customerId: '<',
    scanDates: '<',
    onReview: '&',
    onClose: '&',
  },
);

export { NgzPerformanceMetricsOverviewModal };
