import React from 'react';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { Components } from '@mui/material';

export const MuiChipTheme: Components['MuiChip'] = {
  defaultProps: {
    deleteIcon: <ClearRoundedIcon />,
    size: 'large',
  },
  styleOverrides: {
    root: {
      '&:active': {
        boxShadow: 'none',
      },
    },
  },
  variants: [
    {
      props: { size: 'extraSmall' },
      style: {
        height: '18px',
        borderRadius: '16px',
        font: 'var(--body-12-medium)',
        padding: '2px 8px',

        '.MuiChip-label': {
          padding: '0',
        },
      },
    },
    {
      props: { size: 'small' },
      style: {
        height: '24px',
        borderRadius: '16px',
        font: 'var(--body-14-medium)',
        padding: '4px 12px',

        '.MuiChip-label': {
          padding: '0',
        },
        '.MuiChip-deleteIcon': {
          margin: '0 -4px 0 4px',
        },
      },
    },
    {
      props: { size: 'medium' },
      style: {
        height: '30px',
        borderRadius: '16px',
        font: 'var(--body-14-medium)',
        padding: '7px 12px',

        '.MuiChip-label': {
          padding: '0',
        },
        '.MuiChip-deleteIcon': {
          margin: '0 -4px 0 4px',
        },
      },
    },
    {
      props: { size: 'large' },
      style: {
        height: '45px',
        borderRadius: '4px',
        font: 'var(--title-16-regular)',
        '.MuiChip-label': {
          paddingLeft: '16px',
        },
        '.MuiChip-deleteIcon': {
          marginRight: '16px',
        },
      },
    },
    {
      props: { color: 'default' },
      style: {
        color: 'var(--main-black)',
        backgroundColor: 'var(--natural-100)',
        '.MuiSvgIcon-root': {
          fill: 'var(--natural-600)',
        },
        '.MuiSvgIcon-root:hover': {
          fill: 'var(--primary-500)',
        },
      },
    },
    {
      props: { color: 'default', clickable: true },
      style: {
        '&:hover': {
          backgroundColor: 'var(--natural-100)',
        },
        // fix hover effect on icon hover
        '&:not(:has(.MuiSvgIcon-root:hover)):hover': {
          color: 'var(--white)',
          backgroundColor: 'var(--info-300)',

          '.MuiSvgIcon-root': {
            fill: 'var(--white)',
          },
        },

        '.MuiSvgIcon-root:hover': {
          fill: 'var(--primary-500)',
        },
      },
    },
    {
      props: { color: 'default', variant: 'selected' },
      style: {
        color: 'var(--white)',
        backgroundColor: 'var(--info-400) !important',
        '.MuiSvgIcon-root': {
          fill: 'var(--white)',
        },
        '.MuiSvgIcon-root:hover': {
          fill: 'var(--primary-500)',
        },
      },
    },
    {
      props: { color: 'error' },
      style: {
        color: 'var(--error-600)',
        backgroundColor: 'var(--error-50)',
        // add when icon will be added on design
        // '.MuiSvgIcon-root': {
        //   fill: 'var(--natural-600)',
        // },
        // '.MuiSvgIcon-root:hover': {
        //   fill: 'var(--primary-500)',
        // },
      },
    },
    {
      props: { variant: 'supplement' },

      style: {
        borderRadius: '6px',
        backgroundColor: 'var(--natural-100)',
        font: 'var(--body-14-bold)',
        color: 'var(--main-black)',
        '.MuiSvgIcon-root': {
          fill: 'var(--natural-300)',
        },
        '.MuiSvgIcon-root:hover': {
          fill: 'var(--natural-400)',
        },
      },
    },
    {
      props: { variant: 'fileLink' },

      style: {
        border: '1px solid rgba(94, 134, 223, 0.3)',
        backgroundColor: 'rgba(119, 160, 254, 0.3)',
        font: 'var(--title-16-medium)',
        color: 'var(--info-300) !important',

        '&:hover': {
          cursor: 'pointer',
        },
        '.MuiChip-label': {
          padding: '0 16px',
        },
      },
    },
    {
      props: { variant: 'file' },

      style: {
        color: 'var(--white)',
        font: 'var(--title-16-regular)',
        backgroundColor: 'var(--info-300)',
        '.MuiSvgIcon-root': {
          fill: 'var(--natural-300)',
        },
        '.MuiSvgIcon-root:hover': {
          fill: 'var(--natural-400)',
        },
      },
    },
  ],
};

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    supplement: true;
    fileLink: true;
    file: true;
    selected: true;
  }
  interface ChipPropsSizeOverrides {
    large: true;
    extraSmall: true;
  }
}
