import { apiService } from '../../shared/services/apiService';
import { MealStatistic } from '../interfaces/performance-metrics/MealStatistic';
import { NutrientMetrics } from '../interfaces/performance-metrics/NutrientMetrics';
import { PerformanceMetricsParams } from '../interfaces/performance-metrics/PerformanceMetricsParams';
import { PerformanceScore } from '../interfaces/performance-metrics/PerformanceScore';
import { WaterConsumption } from '../interfaces/performance-metrics/WaterConsumption';

export const performanceMetricsService = {
  async getMealStatistic(customerId: number, params: PerformanceMetricsParams) {
    const resp = await apiService.post<MealStatistic>(
      `/api/users/${customerId}/performance-metrics/meal-statistic`,
      params,
    );

    return resp.data;
  },

  async getPerformanceScore(customerId: number, params: PerformanceMetricsParams) {
    const resp = await apiService.post<PerformanceScore>(
      `/api/users/${customerId}/performance-metrics/performance-score`,
      params,
    );

    return resp.data;
  },

  async getNutrientMetrics(customerId: number, params: PerformanceMetricsParams) {
    const resp = await apiService.post<NutrientMetrics>(
      `/api/users/${customerId}/performance-metrics/nutrient-metrics`,
      params,
    );

    return resp.data;
  },

  async getWaterConsumption(customerId: number, params: PerformanceMetricsParams) {
    const resp = await apiService.post<WaterConsumption>(
      `/api/users/${customerId}/performance-metrics/water-consumption`,
      params,
    );

    return resp.data;
  },
};
