import React from 'react';

import { IconButton, Modal } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import classes from './YoutubeVideoModal.module.scss';

type YoutubeVideoModalProps = {
  open: boolean;
  onClose: () => void;
  videoSrc: string | null;
};

const YoutubeVideoModal = ({ onClose, open, videoSrc }: YoutubeVideoModalProps) => {
  if (!videoSrc) {
    return null;
  }

  return (
    <Modal open={open} onClose={onClose} className={classes.videoModal}>
      <div className={classes.videoBox}>
        <iframe title="Youtube Video" src={videoSrc} allow="autoplay" allowFullScreen />
        <IconButton className={classes.closeBtn} color="white" onClick={onClose}>
          <CloseRoundedIcon fontSize="extraLarge" />
        </IconButton>
      </div>
    </Modal>
  );
};

export { YoutubeVideoModal };
