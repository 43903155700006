import React from 'react';

import { angularize } from 'react-in-angularjs';
import { Container } from '@mui/material';

import classes from './NgzScholasticCustomerSelfSetup.module.scss';
import { SystemProviders } from '../../../shared/providers';
import { getRouteParam } from '../../../shared/utils/angularUtils';
import { CompleteCreateInfo } from './containers/CompleteCreateInfo/CompleteCreateInfo';
import { useStepper } from '../../../shared/hooks/useStepper';
import { CreateScholasticCustomer } from './containers/CreateScholasticCustomer/CreateScholasticCustomer';
import { NgzPageNotFound } from '../../../shared/containers/NgzPageNotFound/NgzPageNotFound';

const NgzScholasticCustomerSelfSetup = () => {
  const { activeStep, goToNextStep } = useStepper(0);

  const entityId = getRouteParam('sub1');
  const token = getRouteParam('sub2');

  if (!entityId || !token) {
    return <NgzPageNotFound />;
  }

  const stepsComponent = [
    <CreateScholasticCustomer
      entityId={entityId}
      token={token}
      goToNextStep={goToNextStep}
    />,
    <CompleteCreateInfo />,
  ];

  return (
    <SystemProviders>
      <div className={classes.background}>
        <Container className={classes.container}>{stepsComponent[activeStep]}</Container>
      </div>
    </SystemProviders>
  );
};

angularize(
  NgzScholasticCustomerSelfSetup,
  'ngzScholasticCustomerSelfSetup',
  angular.module('app.scholastic'),
  {},
);

export { NgzScholasticCustomerSelfSetup };
