import React from 'react';

import { Paper } from '@mui/material';

import classes from './NutrientInfoBlock.module.scss';
import { GridTableRow } from '../../../../../../../shared/components/table-components';
import { NutrientInfoGroup } from './NutrientInfoGroup/NutrientInfoGroup';
import { useTotalSugar } from './hooks/useTotalSugar';

type NutrientInfoBlockProps = {
  nameIndex: number;
};

const NutrientInfoBlock = ({ nameIndex }: NutrientInfoBlockProps) => {
  useTotalSugar(nameIndex);

  return (
    <Paper variant="roundedBox" className={classes.nutrientTable}>
      <GridTableRow variant="subRow">
        <NutrientInfoGroup
          label="Sugar per day"
          name={`mealPlans.${nameIndex}.sugarTotal`}
          nutrient="sugar"
        />
        <NutrientInfoGroup
          label="Sodium per day (mg)"
          name={`mealPlans.${nameIndex}.sodiumTotal`}
          nutrient="sodium"
        />
      </GridTableRow>
      <GridTableRow variant="subRow">
        <NutrientInfoGroup
          label="Fiber per meal"
          name={`mealPlans.${nameIndex}.fiberPerMeal`}
          nutrient="fiber"
        />
        <NutrientInfoGroup
          label="Vegetables per day (cups)"
          name={`mealPlans.${nameIndex}.cupsVeggies`}
          nutrient="veggies"
        />
      </GridTableRow>
    </Paper>
  );
};

export { NutrientInfoBlock };
