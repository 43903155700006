import React from 'react';

import classes from './HeaderRow.module.scss';
import {
  GridTableCell,
  GridTableRow,
} from '../../../../../../../shared/components/table-components';

const HeaderRow = () => {
  return (
    <GridTableRow variant="unstyled" className={classes.tableHeader}>
      <GridTableCell>Metric name</GridTableCell>
      <GridTableCell>Current Metrics</GridTableCell>
      <GridTableCell>Goal Metrics</GridTableCell>
      <GridTableCell>Changes required</GridTableCell>
    </GridTableRow>
  );
};

export { HeaderRow };
