import React from 'react';

import { Control } from 'react-hook-form';

import classes from './MonthlyRenewalPricingForm.module.scss';
import { InputGroup } from '../../components/InputGroup/InputGroup';
import { PaymentDetailsCell } from '../../components/PaymentDetailsCell/PaymentDetailsCell';
import { LbNumericFormatInput } from '../../../../../shared/components/form-components';
import {
  CreateEntitySubscriptionFormValues,
  generalPriceInputProps,
} from '../../CreateEntitySubscriptionModal.settings';
import { MonthlyRenewalPaymentDetail } from '../../../../interfaces/entitySubscription';
import { validateMinimalPrice } from '../../utils/validateMinimalPrice';
import { ViewPriceInput } from '../../components/ViewPriceInput/ViewPriceInput';

type MonthlyRenewalPricingFormProps = {
  control: Control<CreateEntitySubscriptionFormValues>;
  paymentDetails: MonthlyRenewalPaymentDetail | null;
  isLoadingPaymentDetails?: boolean;
  feesEditMode?: boolean;
};

const MonthlyRenewalPricingForm = ({
  control,
  paymentDetails,
  isLoadingPaymentDetails,
  feesEditMode,
}: MonthlyRenewalPricingFormProps) => {
  return (
    <InputGroup
      title="Monthly Renewal"
      description="This will be a recurring monthly payment with no initial plan"
    >
      <div className={classes.inputBox}>
        <LbNumericFormatInput
          {...generalPriceInputProps}
          control={control}
          name="subscriptionPrice.monthlyRenewalPrice"
          label="Monthly Renewal"
          rules={{
            required: 'Monthly Renewal is required',
            validate: (value) =>
              validateMinimalPrice(value, paymentDetails?.minMonthlyRenewalPrice),
          }}
        />
        {feesEditMode ? (
          <LbNumericFormatInput
            {...generalPriceInputProps}
            control={control}
            name="subscriptionPrice.monthlyRenewalFees"
            label="Monthly Renewal Fees"
            rules={{ required: 'Monthly Renewal Fees is required' }}
          />
        ) : (
          <ViewPriceInput
            control={control}
            label="Monthly Renewal Fees"
            name="subscriptionPrice.monthlyRenewalFees"
          />
        )}
      </div>
      <div className={classes.paymentDetailsBox}>
        {paymentDetails?.applicationTax && (
          <PaymentDetailsCell
            paymentDetail={paymentDetails?.applicationTax}
            isLoading={isLoadingPaymentDetails}
          />
        )}
        <PaymentDetailsCell
          paymentDetail={paymentDetails?.stripeFees}
          isLoading={isLoadingPaymentDetails}
          showDivider
        />
        <PaymentDetailsCell
          paymentDetail={paymentDetails?.totalFees}
          isLoading={isLoadingPaymentDetails}
        />
        <PaymentDetailsCell
          paymentDetail={paymentDetails?.entityDeposit}
          isLoading={isLoadingPaymentDetails}
        />
      </div>
    </InputGroup>
  );
};

export { MonthlyRenewalPricingForm };
