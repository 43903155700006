'use strict';

import { updateUserInfo } from "../modules/shared/utils/userInfoUtils";

angular.module('app').factory('PaymentService', PaymentService);

PaymentService.$inject = ['ApiService', 'ModalService', '$rootScope', 'utilService', 'constants'];

function PaymentService(ApiService, ModalService, $rootScope, utilService, constants) {
    return {
        getEntityExpirationCardDate: function () {
            return ApiService.get('api/admin/entity-expire-card').then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        cancelRenewalSubscriptionByCustomer: function (id) {
            return ApiService.put('api/cancelrenewal/' + id).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        calculateExpirationPeriod: function (date) {
            var dateArr = date ? date.split('.') : null;
            return dateArr ? ((new Date(dateArr[2], dateArr[0] - 1, dateArr[1]) - new Date()) / 3600000) + 24 : -1;
        },
        getUserPaymentTransactions: function (id) {
            return ApiService.get('api/coach/user/' + id + '/subscription-transactions').then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        updateUserPaymentSource: function (id, prms) {
            switch (utilService.getLoggedInUserData().role) {
                case 'CUSTOMER':
                    var url = 'api/payment-source';
                    break;
                case 'ADMIN':
                case constants.enums.userRoles.SUPERUSER:
                    var url = 'api/admin/payment-source/' + id + '/entity';
                    break;
                default:
                    var url = 'api/coach/' + id + '/payment-source';
            }

            return ApiService.put(url, null, prms).then(function (res) {
                ModalService.alert('CC Information successfully updated', 'Success');
                return res;
            }, function (err) {
                return err;
            });
        },
        updateEntitySubscriptionPriceFromPlan: function (entityId, planId) {
            return ApiService.put('api/super/entity/' + entityId + '/subscription-price/' + planId).then(function (res) {
                ModalService.savedChanges();
                return res;
            }, function (err) {
                return err;
            });
        },
        cancelRenewalSubscription: function (id) {
            return ApiService.put('api/coach/cancelrenewal/' + id).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        updateEntitySubscriptions: function (id, data) {
            var url = utilService.getLoggedInUserData().role === 'SUPERUSER' ? 'api/super/entity/' + id + '/subscriptions' : 'api/admin/entity/subscriptions';

            return ApiService.put(url, data).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        entityPayForSubscription: function (id, prms) {

            return ApiService.post('api/admin/entity/' + id + '/subscription', null, prms).then((res) => {
                var user = utilService.getLoggedInUserData();
                if (user.role === 'ADMIN') {
                    user.entity.activeSubscription = true;

                    this.updateUserInfo(user);
                }
                return res;
            }, function (err) {
                return err;
            });
        },
        paySubscriptionByUser: function (id, prms) {
            return ApiService.post('api/coach/charge/user/' + id, null, prms).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getEntitysPaymentTransaction: function (id) {
            return ApiService.get('api/admin/entity/' + id + '/subscriptions').then(function (res) {
                for (var i in res.data.transactions) {
                    res.data.transactions[i].price = res.data.transactions[i].price / 100;
                }
                return res;
            }, function (err) {
                return err;
            });
        },
        entityPaymentIntent: function (entityId) {
            return ApiService.post('api/admin/entity-payment-intent', {entityId}).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        createPaymentIntent: function (body) {
            return ApiService.post('api/coach/create-payment-intent', body).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        getConnectedAccountId: (entityId) => ApiService.get(`/api/super/entity/${entityId}/stripe-account-id`),
        getPublishApiKey: function () {
            return ApiService.get('api/publish-api-key').then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        processEntityPayment: function (body) {
            return ApiService.post('api/super/process-entity-payment', body).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        processPayment: function (orderData) {
            return ApiService.post('api/coach/process-payment', orderData).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        updateEntityCardDetails: function (entityId, paymentMethodId) {
            const params = { paymentMethodId };
            return ApiService.put(`api/admin/card-details/${entityId}`, null, params).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        updateCustomerCardDetailsByCoach: function (customerId, paymentMethodId) {
            const params = { paymentMethodId };
            return ApiService.put(`api/coach/card-details/${customerId}`, null, params).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        updateCustomerCardDetailsByCustomer: function (paymentMethodId) {
            const params = { paymentMethodId };
            return ApiService.put('api/card-details', null, params).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },

        getPaymentFlowType: function (entityId) {
            return ApiService.get(`api/entity/${entityId}/payment-flow`).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },

        getPaymentFlowTypeFlags: function (entityId) {
            return this.getPaymentFlowType(entityId).then((res) => {
                if (res.status !== 200) {
                    return;
                };

                const paymentFlowTypeConfig = constants.enums.paymentFlowType;
                const paymentFlowType = res.data.paymentFlowType;

                return utilService.getFlagsObject(paymentFlowTypeConfig, [paymentFlowType]);
            });
        },

        getIsStripeUsed: function (customerId) {
            return ApiService.get('api/is-stripe-used', { customerId }).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },

        // TODO: need to refactor this method hard change because used this on function runFuncDependOnFlow
        getPaymentFlowTypeAndIsStripeUsed: function (vm) {
          vm.spinner.active = true;
          const entityId = this.processGetEntityId(vm);
          this.getPaymentFlowTypeFlags(entityId)
            .then(paymentFlowType => {
              vm.paymentFlowType = paymentFlowType;
              this.paymentFlowType = paymentFlowType;

              if (!paymentFlowType.OLD) {
                this.processGettingIsStripeUsed(vm);
              }
            })
            .finally(() => vm.spinner.active = false);
        },

        processGetEntityId: function (vm) {
            if (vm.entityId) {
                return vm.entityId;
            }

            const loggedInUser = utilService.getLoggedInUserData();
            if (!loggedInUser.gymId) {
                console.error('PaymentService: processGetEntityId: gymId is not defined');
                return;
            }
            return loggedInUser.gymId;
        },

        processGettingIsStripeUsed: function (vm) {
            vm.spinner.active = true;
            this.getIsStripeUsed(vm.customerId)
              .then(res => {
                  if (res.status === 200) {
                      vm.isStripeUsed = res.data;
                      this.isStripeUsed = res.data;
                  }
              })
              .finally(() => vm.spinner.active = false);
        },
        getIsNeedStripePayForSubscription: function(selectedSubscriptionType) {
            const corporate = constants.enums.subscriptionTypes.CORPORATE;
            const scholarship = constants.enums.subscriptionTypes.SCHOLARSHIP;
            return this.isStripeUsed && selectedSubscriptionType !== corporate && selectedSubscriptionType !== scholarship;
        },
        createManualTransaction: function (subscriptionID, userID) {
            return ApiService.post('api/subscription-transaction/manual', null, { subscriptionID, userID }).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        createFreeTrial: function (body) {
            return ApiService.post('api/subscription-transaction/initial/trial', body).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        // TODO: need rewrite but used to many places
        runFuncDependOnFlow: function (newFlowFunction, oldFlowFunction) {
            if (!this.paymentFlowType.OLD) {
                newFlowFunction();
            } else {
                oldFlowFunction();
            }
        },
        updateUserInfo: function (user) {
            updateUserInfo(user)
        },
    };
}

