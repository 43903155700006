import React from 'react';

import { angularize } from 'react-in-angularjs';
import { observer } from 'mobx-react';

import classes from './NgzPaymentTab.module.scss';
import { SystemProviders } from '../../../../shared/providers';
import { ConnectStripeWidget } from '../../../containers/ConnectStripeWidget/ConnectStripeWidget';
import { loggedInUserDataStore } from '../../../../shared/stores/loggedInUserDataStore';
import { TaxWidget } from '../../../containers/TaxWidget/TaxWidget';
import { FreeAccessWidget } from '../../../containers/FreeAccessWidget/FreeAccessWidget';
import { SubscriptionSettingsWidget } from '../../../containers/SubscriptionSettingsWidget/SubscriptionSettingsWidget';
import { EntitySubscriptionManagement } from '../../../containers/EntitySubscriptionManagement/EntitySubscriptionManagement';
import { FeeModuleWidget } from '../../../containers/FeeModuleWidget/FeeModuleWidget';
import { EntityType } from '../../../../shared/constants/user-info/ENTITY_TYPES';

type NgzPaymentTabProps = {
  entityId: number;
  isStripeConnected: boolean;
  entityType: EntityType;
};

const NgzPaymentTab = observer(
  ({ entityId, isStripeConnected, entityType }: NgzPaymentTabProps) => {
    const { isEntity, isSuperAdmin } = loggedInUserDataStore;

    return (
      <SystemProviders>
        <div className={classes.row}>
          <div className={classes.column}>
            {isEntity && (
              <ConnectStripeWidget
                entityId={entityId}
                isStripeConnected={isStripeConnected}
              />
            )}
            {isSuperAdmin && (
              <FeeModuleWidget entityId={entityId} entityType={entityType} />
            )}
            <TaxWidget entityId={entityId} />
          </div>
          <SubscriptionSettingsWidget entityId={entityId} />
        </div>
        <EntitySubscriptionManagement entityId={entityId} />
        <FreeAccessWidget entityId={entityId} />
      </SystemProviders>
    );
  },
);

angularize(NgzPaymentTab, 'ngzPaymentTab', angular.module('app'), {
  entityId: '<',
  isStripeConnected: '<',
  entityType: '<',
});

export { NgzPaymentTab };
