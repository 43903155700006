import PropTypes from 'prop-types';

export const inputValidation = {
  required: "Can't be empty",
  min: {
    value: 0,
    message: 'Value must be greater than or equal zero',
  },
  pattern: {
    value: /^\d+$/,
    message: 'Only integer',
  },
};

export const inputPropsTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
};
