import React, {
  ComponentProps,
  HTMLAttributes,
  ReactNode,
  forwardRef,
  useEffect,
  useRef,
} from 'react';

import { useVirtualizer } from '@tanstack/react-virtual';

const defaultListHeight = 38;

type VirtualizedOption = {
  props: HTMLAttributes<HTMLLIElement>;
  selected: boolean;
  label: string | ReactNode;
};

type VirtualizedListProps = { children: VirtualizedOption[] } & Omit<
  ComponentProps<'div'>,
  'children'
>;

const VirtualizedList = forwardRef<HTMLUListElement, VirtualizedListProps>(
  ({ children: options, ...props }, ref) => {
    const parentRef = useRef<HTMLDivElement>(null);

    const virtualizer = useVirtualizer({
      count: options.length,
      getScrollElement: () => parentRef.current,
      estimateSize: () => defaultListHeight,
      overscan: 5,
    });

    useEffect(() => {
      const selectedOptionIndex = options.findIndex((option) => option.selected);

      if (selectedOptionIndex) {
        virtualizer.scrollToIndex(selectedOptionIndex, { align: 'center' });
      }
    }, []);

    const virtualItems = virtualizer.getVirtualItems();

    return (
      <div {...props} ref={parentRef}>
        <ul
          ref={ref}
          style={{
            height: `${virtualizer.getTotalSize()}px`,
            position: 'relative',
          }}
        >
          {virtualItems.map((virtualItem) => {
            const option = options[virtualItem.index];

            return (
              <li
                {...option.props}
                key={virtualItem.index}
                data-index={virtualItem.index}
                ref={virtualizer.measureElement}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  transform: `translateY(${virtualItem.start}px)`,
                }}
              >
                {option.label}
              </li>
            );
          })}
        </ul>
      </div>
    );
  },
);

export { VirtualizedList };
