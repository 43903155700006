import { Flags, ValueOfKey } from '../../utils-types';
// combination of customer asses, subscription and payment status
export const PAYMENT_STATUS = {
  ACTIVE: 'ACTIVE',
  CANCELED: 'CANCELED',
  INCOMPLETE: 'INCOMPLETE',
  INCOMPLETE_EXPIRED: 'INCOMPLETE_EXPIRED',
  PAYMENT_ISSUE: 'PAYMENT_ISSUE',
} as const;

export type PaymentStatus = ValueOfKey<typeof PAYMENT_STATUS>;
export type PaymentStatusFlags = Flags<PaymentStatus>;

// payment history status
export const STRIPE_PAYMENT_STATUS = {
  SUCCEEDED: 'SUCCEEDED',
  PAYMENT_ISSUE: 'PAYMENT_ISSUE',
} as const;

export type StripePaymentStatus = ValueOfKey<typeof STRIPE_PAYMENT_STATUS>;
