import { getService } from 'react-in-angularjs';

export const getIsActivePageByUrl = (url) => {
  const $location = getService('$location');

  const currentPage = $location.path();
  const pagePattern = new RegExp(`\\${url}($|\\/|\\?)`);

  return pagePattern.test(currentPage);
};
