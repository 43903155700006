import { Option } from '../../../../../../../../general-types';

export const mealOptions: Option[] = [
  { label: 'Breakfast', value: 'BREAKFAST' },
  { label: 'Snack-1', value: 'SNACK1' },
  { label: 'Lunch', value: 'LUNCH' },
  { label: 'Snack-2', value: 'SNACK2' },
  { label: 'Dinner', value: 'DINNER' },
  { label: 'Snack-3', value: 'SNACK3' },
];
