import { makeAutoObservable } from 'mobx';
import { resetAllStore } from '../../shared/stores/resetAllStore';
import { getFormattedToday } from '../../shared/utils/dateUtils';
import { complianceService } from '../services/complianceService';
import { FilterStore } from '../../shared/util-stores';

class ComplianceStore {
  complianceBoard = [];
  showHistoryCustomerId = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    this.filterMethod = new FilterStore(this.handleGetCustomerList);
  }

  setDefaultValues() {
    this.complianceBoard = [];
    this.showHistoryCustomerId = null;
    this.filterMethod.setDefaultValues();
  }

  async getComplianceBoard() {
    return this.filterMethod.getData();
  }

  async handleGetCustomerList(filterData) {
    const { filters, requestedPage, sort } = filterData;
    const date = getFormattedToday();

    const { items: complianceBoard, page } = await complianceService.getComplianceBoard(
      date,
      {
        page: requestedPage.page,
        field: sort?.fieldName,
        order: sort?.direction,
        ...filters,
      },
    );

    this.setComplianceBoard(complianceBoard);

    return {
      empty: !complianceBoard.length,
      totalPages: page.count,
      number: page.currentPage,
      content: complianceBoard,
      totalElements: 0,
    };
  }

  setComplianceBoard(complianceBoard) {
    this.complianceBoard = complianceBoard;
  }

  handleShowComplianceHistory(customerId) {
    if (this.showHistoryCustomerId === customerId) {
      this.setShowHistoryCustomerId(null);
    } else {
      this.setShowHistoryCustomerId(customerId);
    }
  }

  setShowHistoryCustomerId(showHistoryCustomerId) {
    this.showHistoryCustomerId = showHistoryCustomerId;
  }
}

const complianceStore = new ComplianceStore();

resetAllStore.addResetMethod(complianceStore.setDefaultValues);

export { complianceStore, ComplianceStore };
