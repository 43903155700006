import {
  CreateSubscriptionSettingsInfo,
  SubscriptionSettings,
} from '../../../interfaces/subscriptionSettings';
import { CreateEntitySubscriptionFormValues } from '../CreateEntitySubscriptionModal.settings';

export const defaultFormValue: CreateEntitySubscriptionFormValues = {
  name: '',
  subscriptionType: '',
  subscriptionPrice: {
    initialPlanPrice: '',
    initialPlanDuration: '',
    initialPlanUserFee: '',
    initialPlanOneTimeFee: '',
    monthlyRenewalPrice: '',
    monthlyRenewalFees: '',
  },
} as any as CreateEntitySubscriptionFormValues;

export const processFormDefaultValues = (
  subscriptionSettings?: SubscriptionSettings | CreateSubscriptionSettingsInfo | null,
): CreateEntitySubscriptionFormValues => {
  if (!subscriptionSettings) {
    return defaultFormValue;
  }

  const { name, subscriptionType, subscriptionPrice } = defaultFormValue;
  const { initialPlanFees, onboardingFees, renewalFees } = subscriptionSettings;

  return {
    name,
    subscriptionType,
    subscriptionPrice: {
      ...subscriptionPrice,
      initialPlanUserFee: initialPlanFees,
      initialPlanOneTimeFee: onboardingFees,
      monthlyRenewalFees: renewalFees,
    },
  };
};
