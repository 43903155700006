import React from 'react';

import { useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react';

import { InbodyDataFieldGroup } from './InbodyDataFieldGroup/InbodyDataFieldGroup';
import { LbSwitch } from '../../../../shared/components/form-components';
import { TitleWithDescription } from '../../../../shared/components/text-formation-components';
import { InfoBox } from '../../../../shared/components/ui-components';

import { MeasurementUnitRow } from './MeasurementUnitRow/MeasurementUnitRow';
import { EntityInbodyDataFormValue } from '../EntityInbodyDataConfig.settings';
import { loggedInUserDataStore } from '../../../../shared/stores/loggedInUserDataStore';

const NoInbodyWidget = observer(() => {
  const { control, watch } = useFormContext<EntityInbodyDataFormValue>();
  const { isSuperAdmin } = loggedInUserDataStore;

  const isAllowNonInbodyUsers = watch('allowNonInbodyUsers');

  const SwitchTitle = (
    <LbSwitch
      control={control}
      name="allowNonInbodyUsers"
      label={
        <TitleWithDescription
          title="Non-InBody"
          description="Turn on switcher and set up user metrics"
        />
      }
      align="start"
      disabled={!isSuperAdmin}
    />
  );
  return (
    <InfoBox title={SwitchTitle} hideConfig={{ show: isAllowNonInbodyUsers }}>
      <InfoBox title="Required Fields" variant="innerBox">
        <InbodyDataFieldGroup group="REQUIRED" />
      </InfoBox>
      {/* client asc to hide for NEW_FLOW_2024 if use for NEW flow asc is need to show */}
      {/* <InfoBox title="Optional" variant="innerBox">
        <InbodyDataFieldGroup group="OPTIONAL" />
      </InfoBox> */}
      <InfoBox title="Measurement" variant="innerBox" customMargin>
        <MeasurementUnitRow />
        <InbodyDataFieldGroup group="MEASUREMENTS" />
      </InfoBox>
    </InfoBox>
  );
});

export { NoInbodyWidget };
