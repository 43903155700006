import React, { useEffect } from 'react';

import { angularize } from 'react-in-angularjs';

import { Container } from '@mui/material';
import { observer } from 'mobx-react';

import { SystemProviders } from '../../../shared/providers';
import { PageTitle } from '../../../shared/components/ui-components';
import { DownloadReportModal } from './containers/DownloadReportModal/DownloadReportModal';
import { ReportsTable } from './containers/ReportsTable/ReportsTable';
import { salesReportStore } from '../../stores/salesReportStore';
import { loggedInUserDataStore } from '../../../shared/stores/loggedInUserDataStore';
import { NgzPageUnderConstruction } from '../../../shared/containers/NgzPageUnderConstruction/NgzPageUnderConstruction';

const NgzSalesReports = observer(() => {
  const { setDefaultValues } = salesReportStore;
  const { paymentFlowType } = loggedInUserDataStore;

  useEffect(() => setDefaultValues, []);

  return paymentFlowType.NEW_USER_FLOW_2024 ? (
    <NgzPageUnderConstruction
      description="Reports are currently under construction"
      imageType="reports"
    />
  ) : (
    <SystemProviders>
      <Container>
        <PageTitle title="Reports" />
        <ReportsTable />
        <DownloadReportModal />
      </Container>
    </SystemProviders>
  );
});

angularize(NgzSalesReports, 'ngzSalesReports', angular.module('app.reports'), []);

export { NgzSalesReports };
