import { Components } from '@mui/material';

export const MuiBadgeTheme: Components['MuiBadge'] = {
  styleOverrides: {
    colorInfo: {
      backgroundColor: 'var(--info-300)',
    },
    colorError: {
      backgroundColor: 'var(--error-300)',
    },
  },
  variants: [
    {
      props: { variant: 'small' },
      style: {
        '& .MuiBadge-badge': {
          font: 'var(--body-10-regular)',
          minWidth: '14px',
          height: '14px',
          padding: '0px 4px',
        },
      },
    },
    {
      props: { variant: 'small', overlap: 'circular' },
      style: {
        '& .MuiBadge-badge': {
          top: '15%',
          right: '0%',
        },
      },
    },
  ],
};

declare module '@mui/material/Badge' {
  interface BadgePropsVariantOverrides {
    small: true;
  }
}
