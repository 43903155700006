import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
import { angularize } from 'react-in-angularjs';

import classes from './NGZCheckout.module.scss';
import { OrderDetailsBlock } from './OrderDetailsBlock/OrderDetailsBlock';
import { PaymentBlock } from './PaymentBlock/PaymentBlock';
import { SystemProviders } from '../../../shared/providers';
import { getRouteParam } from '../../../shared/utils/angularUtils';
import { checkoutStore } from '../../stores/checkoutStore';
import { NgzPageNotFound } from '../../../shared/containers/NgzPageNotFound/NgzPageNotFound';
import { ScrollDownBadge } from './ScrollDownBadge/ScrollDownBadge';

const NgzCheckout = observer(() => {
  const { createSubscription, setDefaultValues } = checkoutStore;

  const entityId = getRouteParam('entityId');
  const email = getRouteParam('email');
  const subscriptionId = getRouteParam('selectedSubscriptionId');

  if (!entityId || !email || !subscriptionId) {
    return <NgzPageNotFound />;
  }

  useEffect(() => {
    createSubscription(entityId, email, subscriptionId);

    return setDefaultValues;
  }, []);

  return (
    <SystemProviders>
      <div className={classes.container}>
        <OrderDetailsBlock />
        <ScrollDownBadge />
        <PaymentBlock />
      </div>
    </SystemProviders>
  );
});

angularize(NgzCheckout, 'ngzCheckout', angular.module('app.payment'), {});
