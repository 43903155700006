import React from 'react';

import CompareArrowsRoundedIcon from '@mui/icons-material/CompareArrowsRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { useForm } from 'react-hook-form';

import classes from './ConnectGoHighLevelModal.module.scss';
import goHighLevelLogo from '../../../../shared/assets/image/goHighLevelLogo.webp';
import { LbModal } from '../../../../shared/components/modals';
import { ReactComponent as LogoSmallIcon } from '../../../../shared/assets/icons/logoSmall.svg';
import { LbInput } from '../../../../shared/components/form-components';
import { validateLocationId, validationMassage } from '../../../utils/goHighLevelUtils';

type ConnectGoHighLevelFormValues = {
  locationId: string;
};

type ConnectGoHighLevelModalProps = {
  onClose: () => void;
  onSubmit: (locationId: string) => Promise<void>;
};

const ConnectGoHighLevelModal = ({ onClose, onSubmit }: ConnectGoHighLevelModalProps) => {
  const { control, handleSubmit } = useForm<ConnectGoHighLevelFormValues>();

  const integrationAllowsList: string[] = [
    'Streamline campaigns across email, SMS, and social media',
    'Track interactions and gain insights',
    'Centralize communication for better service',
    'Visualize and manage deals efficiently',
  ];

  const handleFormSubmit = handleSubmit(async ({ locationId }) => {
    await onSubmit(locationId);

    onClose();
  });

  return (
    <LbModal
      open
      title="Connect GoHighLevel to the LifeBase"
      text="Elevate Marketing and Sales with HighLevel's All-in-One Platform!"
      primaryBtnConfig={{ onClick: handleFormSubmit, text: 'Confirm' }}
      secondaryBtnConfig={{ onClick: onClose, text: 'Cancel' }}
    >
      <div className={classes.iconBox}>
        <img src={goHighLevelLogo} alt="GoHighLevel logo" />
        <CompareArrowsRoundedIcon fontSize="large" color="tertiary" />
        <LogoSmallIcon width="48px" />
      </div>
      <div className={classes.infoBox}>
        <h5 className={classes.allowsTitle}>GoHighLevel integration allows:</h5>
        <ul className={classes.allowsList}>
          {integrationAllowsList.map((allowInfo) => (
            <li key={allowInfo} className={classes.allowsItem}>
              <CheckRoundedIcon fontSize="medium" color="success" />
              <span>{allowInfo}</span>
            </li>
          ))}
        </ul>
      </div>
      <LbInput
        control={control}
        name="locationId"
        label="GoHighLevel ID"
        variant="outlined"
        size="small"
        helperText={validationMassage}
        rules={{
          required: 'GoHighLevel ID is required',
          validate: {
            validateLocationId,
          },
        }}
      />
    </LbModal>
  );
};

export { ConnectGoHighLevelModal };
