import React from 'react';

import { observer } from 'mobx-react';

import classes from './CustomerCommunicationPageTitle.module.scss';
import { PageTitle } from '../../../../shared/components/ui-components';
import {
  OptionsMenu,
  AddCustomerMenu,
} from '../../../../shared/components/menu-components';
import { customerCommunicationStore } from '../../../stores/customerCommunicationStore';
import { MenuOptions } from '../../../../general-types';
import { loggedInUserDataStore } from '../../../../shared/stores/loggedInUserDataStore';
import { AddNewFlow2024CustomerButton } from './AddNewFlow2024CustomerButton/AddNewFlow2024CustomerButton';

const CustomerCommunicationPageTitle = observer(() => {
  const { paymentFlowType } = loggedInUserDataStore;
  const { downloadReport, sendMessage, selectMethod } = customerCommunicationStore;
  const { itemCount } = selectMethod;

  const handleDownloadReportForAll = () => {
    downloadReport(true);
  };

  const handleContactToAll = () => {
    sendMessage(true);
  };

  const menuOptions: MenuOptions[] = [
    { id: 1, label: 'Export in CSV', onClick: handleDownloadReportForAll },
    { id: 2, label: 'Contact All', onClick: handleContactToAll },
  ];

  return (
    <PageTitle title={`Customer Communication (${itemCount})`}>
      <div className={classes.titleBtnBox}>
        {paymentFlowType.NEW_USER_FLOW_2024 ? (
          <AddNewFlow2024CustomerButton />
        ) : (
          <AddCustomerMenu />
        )}
        <OptionsMenu options={menuOptions} />
      </div>
    </PageTitle>
  );
});

export { CustomerCommunicationPageTitle };
