import React from 'react';

import { useFormContext } from 'react-hook-form';
import { Button } from '@mui/material';

import { LbSelect } from '../../../../../../../components/form-components';
import { nutrientOptions } from './Nutrient.settings';
import { ComparisonInputs } from '../../ComparisonInputs/ComparisonInputs';
import { comparisonOptions } from '../../../../../constants/comparison';
import { CustomFilterFormValues } from '../../AddCustomFilterForm.settings';
import { getComparisonFilterValidateRule } from '../../utils/getComparisonFilterValidateRule';
import { useClearFilter } from '../../hooks/useClearFilter';
import { ModalInputBox, ModalInputGroup } from '../../../../../../../components/modals';

const Nutrient = () => {
  const { control, register } = useFormContext<CustomFilterFormValues>();

  const { disabled, handleClearFields } = useClearFilter('NUTRIENT', {
    comparison: '',
    from: '',
    lastDays: '',
    nutrient: '',
    to: '',
  });

  const validate = getComparisonFilterValidateRule('NUTRIENT');

  return (
    <ModalInputBox
      title="Nutrient"
      adornment={
        <Button
          size="small"
          variant="text"
          color="secondary"
          onClick={handleClearFields}
          disabled={disabled}
        >
          Clear
        </Button>
      }
    >
      <input {...register('filters.NUTRIENT.filterType', { value: 'NUTRIENT' })} hidden />
      <ModalInputGroup>
        <ModalInputGroup>
          <LbSelect
            control={control}
            size="small"
            variant="outlined"
            name="filters.NUTRIENT.nutrient"
            label="Nutrient"
            options={nutrientOptions}
            rules={{ validate }}
          />
          <LbSelect
            control={control}
            size="small"
            variant="outlined"
            name="filters.NUTRIENT.comparison"
            options={comparisonOptions}
            label="Condition"
            rules={{ validate }}
          />
        </ModalInputGroup>
        <ComparisonInputs filterType="NUTRIENT" />
      </ModalInputGroup>
    </ModalInputBox>
  );
};

export { Nutrient };
