import { CircularProgressProps } from '@mui/material';

export const getProgressColor = (value: number): CircularProgressProps['color'] => {
  if (value < 50) {
    return 'error';
  } else if (value < 75) {
    return 'warning';
  } else {
    return 'success';
  }
};
