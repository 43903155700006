const autoNgTemplateLoaderTemplate1 = require('./leaderboard.template.html');

'use strict';

angular
    .module('app')
    .directive('leaderboard', leaderboard);

leaderboard.$inject = [];

function leaderboard() {
    return {
        restrict: 'E',
        scope: {
            isCurrentGoalActive: '='
        },
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: 'LeaderboardController',
        controllerAs: 'vm'
    };
}
