'use strict';

import { baseUserService } from "../modules/shared/services/baseUserService";
import { updateUserInfo } from "../modules/shared/utils/userInfoUtils";

angular.module('app').factory('BaseUserUtils', BaseUserUtils);

BaseUserUtils.$inject = [];

function BaseUserUtils() {
    return {
        updateAvatar(avatarFile, userData) {
            if (avatarFile) {
                this.processAvatarFile(avatarFile, userData);
            }
        }, 
        processAvatarFile(avatarFile, userData) {
            this.baseUserService.updateAvatar(avatarFile, userData.id).then(({ avatar }) => {
                userData.avatar = avatar;
                this.updateUserInfo({ avatar });
            });
        },
        baseUserService: baseUserService,
        updateUserInfo: updateUserInfo,
    }
}
