export const calculateTimeLeft = (expirationEpoch: number) => {
  let minutes = 0;
  let seconds = 0;

  const difference = expirationEpoch - Math.round(Date.now() / 1000);

  if (difference > 0) {
    minutes = Math.floor(difference / 60);
    seconds = difference - minutes * 60;
  }

  return { minutes, seconds };
};
