const autoNgTemplateLoaderTemplate1 = require('./supplement-list.template.html');

'use strict';

(function () {
    angular
        .module('app.supplementList', [])
        .config(supplementListConfig);

    supplementListConfig.$inject = ['$routeProvider'];

    function supplementListConfig($routeProvider) {
        $routeProvider
            .when('/supplement-customers-list', {
                disableCache: true,
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'SupplementListController',
                controllerAs: 'vm',
                reloadOnSearch: false,
                resolve: {
                    loginRequired: ['AuthService', function (AuthService) {
                        return AuthService.loginRequired();
                    }]
                }
            })
    }
})();
