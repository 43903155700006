const autoNgTemplateLoaderTemplate1 = require('./add-food.template.html');
const autoNgTemplateLoaderTemplate2 = require('./add-food.template.html');

'use strict';

(function () {
    angular
        .module('app.addFood', [])
        .config(addFoodConfig);

    addFoodConfig.$inject = ['$routeProvider'];

    function addFoodConfig($routeProvider) {
        $routeProvider
            .when('/add-food/:type', {
                disableCache: true,
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'AddFoodController',
                controllerAs: 'vm',
                resolve: {
                    loginRequired: ['AuthService', function (AuthService) {
                        return AuthService.loginRequired();
                    }]
                }
            })
            .when('/edit-food/:type/:id', {
                disableCache: true,
                templateUrl: autoNgTemplateLoaderTemplate2,
                controller: 'AddFoodController',
                controllerAs: 'vm',
                resolve: {
                    loginRequired: ['AuthService', function (AuthService) {
                        return AuthService.loginRequired();
                    }]
                }
            })
    }
})();
