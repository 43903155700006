import React, { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { angularize } from 'react-in-angularjs';
import { useToggle } from 'react-use';
import { observer } from 'mobx-react';

import classes from './NgzScholasticCustomerProfile.module.scss';
import { SystemProviders } from '../../../../../../shared/providers';
import { ScholasticProfileInputs } from '../../../../../components/ScholasticProfileInputs/ScholasticProfileInputs';
import { useSchoolList } from '../../../../../hooks/school';
import { YesNoBtnRow } from '../../../../../../shared/components/buttons';
import { getRouteParam } from '../../../../../../shared/utils/angularUtils';
import { ScholasticUserDataFormValue } from '../../../../../interfaces/ScholasticUserProfileFormValue';
import { scholasticCustomerService } from '../../../../../services/scholasticCustomerService';
import { getFilteredTruthyFieldsObj } from '../../../../../../shared/utils/sortingAndGroupingUtil';
import { LbModal } from '../../../../../../shared/components/modals/LbModal/LbModal';

const NgzScholasticCustomerProfile = observer(() => {
  const [isShowSuccessModal, toggleShowSuccessModal] = useToggle(false);
  const { schoolList } = useSchoolList({ onlyActive: true });
  const methods = useForm<ScholasticUserDataFormValue>();
  const { reset, handleSubmit, formState } = methods;

  const userId = getRouteParam('id');
  const initUserInfo = async () => {
    // TODO set more info when form need, add function to delate unused field
    const userData = await scholasticCustomerService.getUserData(userId);
    const clearedUserData = getFilteredTruthyFieldsObj(userData);

    reset({ userData: clearedUserData });
  };

  const onSubmit = async ({ userData }: ScholasticUserDataFormValue) => {
    await scholasticCustomerService.sendUser(userData);

    toggleShowSuccessModal();
  };

  useEffect(() => {
    initUserInfo();
  }, []);

  return (
    <SystemProviders>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <ScholasticProfileInputs
            schoolList={schoolList}
            isShowAddress
            className={classes.inputBlock}
          />
          <YesNoBtnRow yesText="Save" yesDisabled={!formState.isDirty} />
        </form>
      </FormProvider>
      <LbModal
        open={isShowSuccessModal}
        title="Changes have been saved successfully"
        onClose={toggleShowSuccessModal}
        primaryBtnConfig={{ text: 'Ok', onClick: toggleShowSuccessModal }}
      />
    </SystemProviders>
  );
});

angularize(
  NgzScholasticCustomerProfile,
  'ngzScholasticCustomerProfile',
  angular.module('app.scholastic'),
  {},
);

export { NgzScholasticCustomerProfile };
