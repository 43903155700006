import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useFormContext } from 'react-hook-form';

import classes from './SupplementDayCheckbox.module.scss';
import { LbCheckbox } from '../../../../../shared/components/form-components';
import { daysOfWeek } from '../../../../../shared/constants/daysOfWeek';

const SupplementDayCheckbox = ({ control, nameIndex }) => {
  const fieldName = `${nameIndex}daysOfWeekJson`;
  const [isShowError, setIsShowError] = useState(false);

  const { getValues, setValue, trigger } = useFormContext();

  const cancelAllHandler = () => {
    const daysOfWeekJson = daysOfWeek.reduce((obj, day) => {
      obj[day] = false;
      return obj;
    }, {});

    setValue(fieldName, daysOfWeekJson);
    trigger(fieldName);
  };

  const isAtLeastOneChecked = () => {
    const days = getValues(fieldName);
    const isAtLeastOneTrue = Object.values(days).some((day) => day);

    if (isAtLeastOneTrue && isShowError) {
      setIsShowError(false);
    } else if (!isAtLeastOneTrue) {
      setIsShowError(true);
    }

    return isAtLeastOneTrue;
  };

  return (
    <div className={classes.box}>
      <div className={classes.checkboxContainer}>
        {daysOfWeek.map((day) => (
          <LbCheckbox
            control={control}
            name={`${fieldName}.${day}`}
            label={day}
            key={day}
            className={classes.dayCheckbox}
            defaultChecked
            rules={{
              validate: isAtLeastOneChecked,
            }}
          />
        ))}
        <div className={classes.cancelDayBtn} onClick={cancelAllHandler}>
          Cancel All
        </div>
      </div>
      {isShowError && <p className={classes.error}> At least one day mast be checked</p>}
    </div>
  );
};

SupplementDayCheckbox.propTypes = {
  control: PropTypes.object.isRequired,
  nameIndex: PropTypes.string.isRequired,
};

export { SupplementDayCheckbox };
