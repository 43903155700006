'use strict';

angular.module('app').controller('CustomerSetupStep6Controller', CustomerSetupStep6Controller);

CustomerSetupStep6Controller.$inject = ['$scope', 'utilService', 'MealPlanService'];

function CustomerSetupStep6Controller($scope, utilService, MealPlanService) {
    var vm = this;
    vm.createUser = $scope.createUser;
    vm.mealScript = $scope.mealScript;
    vm.prePostWorkout = $scope.prePostWorkout;
    vm.user = $scope.user;
    vm.updateMealPlan = {};
    vm.NAValue = 'n/a';
    vm.tabName = 'mealPlan';
    vm.mealPlanObject = {
        breakFast: {id: 1, name: 'Breakfast'},
        snack1: {id: 2, name: 'Snack'},
        lunch: {id: 3, name: 'Lunch'},
        snack2: {id: 4, name: 'Snack'},
        dinner: {id: 5, name: 'Dinner'},
        snack3: {id: 6, name: 'Bedtime snack'},
    };

    vm.getMealPlansByUser = getMealPlansByUser;
    vm.initMealPlan = initMealPlan;
    vm.calculateTotal = calculateTotal;
    vm.goToRecommendedSteps = goToRecommendedSteps;
    vm.calculateCalories = calculateCalories;

    vm.initMealPlan();

    function getMealPlansByUser() {
        return MealPlanService.getMealPlan(vm.user.id).then(function (result) {
            if (result.status === 200) {
                vm.currentMealPlanId = result.data.mealPlans[0].originId;
            }
        })
    }

    function calculateCalories(type) {
        var plan = vm.updateMealPlan[type];
        vm.updateMealPlan[type].calories = (plan.prt * 4) + (plan.fat * 9) + (plan.crb * 4);
    }

    function initMealPlan() {
        vm.getMealPlansByUser().then(function () {
            MealPlanService.getGeneratedMealPlan(vm.user.id, {scriptId: vm.mealScript.id}).then(function (results) {
                if (results.status === 200) {
                    var mealPlansInfo = results.data;
                    vm.updateMealPlan = {
                        mealPlans: angular.copy(mealPlansInfo.mealPlans),
                        preWorkout: {
                            prt: vm.prePostWorkout.preWorkout.prt,
                            crb: vm.prePostWorkout.preWorkout.crb,
                            fat: vm.prePostWorkout.preWorkout.fat,
                            enabled: true // TODO unused field set only in this step
                        },
                        postWorkout: {
                            prt: vm.prePostWorkout.postWorkout.prt,
                            crb: vm.prePostWorkout.postWorkout.crb,
                            fat: vm.prePostWorkout.postWorkout.fat,
                            enabled: true // TODO unused field set only in this step
                        }
                    };
                    vm.calculateCalories('preWorkout');
                    vm.calculateCalories('postWorkout');
                    vm.updateMealPlan.mealPlans[0].originId = vm.currentMealPlanId;
                    vm.updateMealPlan.mealPlans[0].name = 'Meal Plan A';
                    vm.updateMealPlan.mealPlans[0].totalWater = +vm.updateMealPlan.mealPlans[0].totalWater.toFixed(1);
                }
            });
        });
    }

    function calculateTotal(plan) {
        plan.total.crb = 0;
        plan.total.fat = 0;
        plan.total.prt = 0;
        plan.total.calories = 0;
        Object.keys(plan).forEach(function (key) {
            if (plan[key] && plan[key].calories) {
                if (key !== 'total') {
                    Object.keys(plan[key]).forEach(function (subkey) {
                        switch (subkey) {
                            case 'crb':
                                plan.total.crb += parseFloat(plan[key][subkey] || 0);
                                break;
                            case 'fat':
                                plan.total.fat += parseFloat(plan[key][subkey] || 0);
                                break;
                            case 'prt':
                                plan.total.prt += parseFloat(plan[key][subkey] || 0);
                                break;
                            case 'calories':
                                plan.total.calories += parseFloat(plan[key][subkey] || 0);
                                break;
                        }
                    });
                }

                plan[key].calories =
                    ((plan[key].prt || 0) * 4) +
                    ((plan[key].fat || 0) * 9) +
                    ((plan[key].crb || 0) * 4);
            }
        });

        plan.sugarTotal = Math.round(plan.total.crb * 0.2);
    }

    function goToRecommendedSteps() {
        vm.updateMealPlan.isImmediately = true;
        MealPlanService.updateMealPlan(vm.user.id, vm.updateMealPlan).then(function (results) {
            if (results.status === 200) {
                utilService.goTo("/recommended-steps/" + vm.user.id);
            }
        });
    }
}

