import React from 'react';

import { Radio } from '@mui/material';

import classes from './CoachInfoMobile.module.scss';
import { AvatarIcon } from '../../../../../../../../shared/components/ui-components';
import { TitleWithDescription } from '../../../../../../../../shared/components/text-formation-components';
import { Coach } from '../../../../../../../interfaces/coachList/CoachListInfo';
import { getIsNullish } from '../../../../../../../../shared/utils/jsUtils';

type CoachInfoMobileProps = {
  coach: Coach;
  checked: boolean;
};

const CoachInfoMobile = ({ coach, checked }: CoachInfoMobileProps) => {
  return (
    <div className={classes.box}>
      <Radio checked={checked} />
      <AvatarIcon userName={coach.coachName} avatarImgLink={coach.coachAvatar} />
      <div className={classes.infoBox}>
        <TitleWithDescription
          title={coach.coachName}
          description={coach.entityName}
          titleFont="body-14-bold"
          descriptionColor="main-black"
        />
        {!getIsNullish(coach.distance) && <div>{coach.distance} miles</div>}
      </div>
    </div>
  );
};

export { CoachInfoMobile };
