import React, { useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';
import { useToggle } from 'react-use';

import { UserProfileFromValue } from '../UserProfileStep.settings';
import {
  AddScanManualForm,
  EntityInbodyData,
  EntryFieldConfig,
  FailedUploadScan,
  inbodyEntryFieldConfig,
  ScanInfoTable,
  UseCustomerScanReturn,
} from '../../../../../../inbody';
import { INBODY_TYPES } from '../../../../../../shared/constants/inbody-info/InbodyType';
import { ScanButtonsRow } from './ScanButtonsRow/ScanButtonsRow';

type AddInbodyScanProps = {
  entityInbodyData: EntityInbodyData | null;
} & UseCustomerScanReturn;

const AddInbodyScan = ({
  entityInbodyData,
  scanData,
  isScanUnavailable,
  getScanData,
}: AddInbodyScanProps) => {
  const [isShowAddScanForm, toggleShowAddScanForm] = useToggle(false);
  const [addScanConfig, setAddScanConfig] = useState<EntryFieldConfig | null>(null);

  const { trigger, getValues, watch } = useFormContext<UserProfileFromValue>();

  const inbodyType = watch('inbodyInfo.inbodyType');

  const handleLoadScan = async () => {
    const isValid = await trigger('inbodyInfo.inbodyId', { shouldFocus: true });
    const inbodyId = getValues('inbodyInfo.inbodyId');

    if (inbodyId && isValid) {
      await getScanData(inbodyId);
      toggleShowAddScanForm(false);
    }
  };

  useEffect(() => {
    if (!inbodyType) {
      toggleShowAddScanForm(false);
      setAddScanConfig(null);
    } else if (inbodyType === INBODY_TYPES.NON_INBODY) {
      toggleShowAddScanForm(true);
      setAddScanConfig(entityInbodyData?.nonInbodyData!);
    } else {
      toggleShowAddScanForm(false);
      setAddScanConfig(inbodyEntryFieldConfig[inbodyType]);
    }
  }, [inbodyType]);

  const isScanExist = !!scanData;
  const isShowFailedUploadScan = isScanUnavailable && !isShowAddScanForm;
  const isInbodyType = inbodyType && inbodyType !== INBODY_TYPES.NON_INBODY;

  return (
    <>
      {isInbodyType && (
        <ScanButtonsRow
          onLoadScan={handleLoadScan}
          onFillInDataManually={toggleShowAddScanForm}
          hideFillInDataManually={isScanExist}
        />
      )}
      {isShowFailedUploadScan && <FailedUploadScan />}
      {isScanExist && !isShowAddScanForm && <ScanInfoTable scanData={scanData} />}
      {isShowAddScanForm && addScanConfig && (
        <AddScanManualForm entryFieldConfig={addScanConfig} />
      )}
    </>
  );
};

export { AddInbodyScan };
