import React from 'react';

import { useToggle } from 'react-use';
import { Button } from '@mui/material';
import { observer } from 'mobx-react';

import { scholasticCustomersListStore } from '../../../../stores/ScholasticCustomersListStore';
import { ReassignCoachForCustomersModal } from '../../../../../shared/components/modals';

const ReassignCustomersButton = observer(() => {
  const [isShowReassignModal, toggleShowReassignModal] = useToggle(false);

  const { selectMethod, getCustomerList } = scholasticCustomersListStore;
  const { selectedItems } = selectMethod;

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        className="wightBg"
        onClick={toggleShowReassignModal}
      >
        Reassign to
      </Button>
      <ReassignCoachForCustomersModal
        open={isShowReassignModal}
        onSubmit={getCustomerList}
        onClose={toggleShowReassignModal}
        customerIds={selectedItems}
      />
    </>
  );
});

export { ReassignCustomersButton };
