import React, { MouseEventHandler } from 'react';

import FilterListIcon from '@mui/icons-material/FilterList';
import { Button } from '@mui/material';
import classNames from 'classnames';

import classes from './FilterButton.module.scss';

type FilterButtonProps = {
  isActive: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  className?: string;
};

const FilterButton = ({ isActive, onClick, className }: FilterButtonProps) => {
  const filterButtonClasses = classNames(className, { [classes.active]: isActive });
  return (
    <Button
      className={filterButtonClasses}
      variant="text"
      color="secondary"
      size="small"
      startIcon={<FilterListIcon />}
      disableRipple
      onClick={onClick}
    >
      Filter
    </Button>
  );
};

export { FilterButton };
