import { debounce } from 'lodash';
import { useEffect, useMemo } from 'react';
import { FieldValues, Path, UseFormWatch } from 'react-hook-form';

export const useAutoSubmitForm = <TFieldValues extends FieldValues>(
  handleFormSubmit: () => void,
  watch: UseFormWatch<TFieldValues>,
  debouncedFields: Path<TFieldValues>[] = [],
) => {
  const debouncedSubmit = useMemo(
    () => debounce(handleFormSubmit, 800),
    [handleFormSubmit],
  );

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      // when name undefined it means that form is reset need ran handleFormSubmit
      const isDebouncedField = name ? debouncedFields.includes(name) : false;

      if (isDebouncedField) {
        debouncedSubmit();
      } else {
        handleFormSubmit();
      }
    });
    return () => {
      subscription.unsubscribe();
      debouncedSubmit.cancel();
    };
  }, [watch, debouncedFields, debouncedSubmit]);
};
