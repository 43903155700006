import React from 'react';

import classes from './HeaderRow.module.scss';
import {
  GridTableCell,
  GridTableRow,
} from '../../../../../../../shared/components/table-components';

const HeaderRow = () => {
  return (
    <GridTableRow variant="unstyled" className={classes.header}>
      <GridTableCell>Metrics</GridTableCell>
      <GridTableCell>Start Scan</GridTableCell>
      <GridTableCell>End Scan</GridTableCell>
      <GridTableCell>Progress</GridTableCell>
    </GridTableRow>
  );
};

export { HeaderRow };
