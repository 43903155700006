const autoNgTemplateLoaderTemplate1 = require('../modals/stripe-update-card.modal.html');
const autoNgTemplateLoaderTemplate2 = require('../modals/stripe-update-card.modal.html');
const autoNgTemplateLoaderTemplate3 = require('../modals/stripe-update-card.modal.html');
const autoNgTemplateLoaderTemplate4 = require('../modals/stripe-update-card.modal.html');

'use strict';

angular.module('app').factory('StripeService', StripeService);

StripeService.$inject = ['$mdDialog', 'PaymentService'];

function StripeService($mdDialog, PaymentService) {
  const paymentService = PaymentService;

  const stripeService = {
    updateEntityCardDetails: function (entityId) {
      return $mdDialog.show({
        controller: ['$scope', '$mdDialog', function ($scope, $mdDialog) {
          $scope.updateCardDetailsHandler = updateCardDetailsHandler;
          $scope.onOkBtnClick = onOkBtnClick;
          $scope.isEntity = true;

          getPublishApiKey($scope);

          function updateCardDetailsHandler() {
            updateCardDetails($scope, updateEntityCardDetails);
          }

          function updateEntityCardDetails(paymentMethodId) {
            paymentService.updateEntityCardDetails(entityId, paymentMethodId).then(res => {
              changeStripeView(res.status === 200);
            });
          }

          function onOkBtnClick() {
            $mdDialog.cancel();
          }
        }],
        templateUrl: autoNgTemplateLoaderTemplate1,
        parent: angular.element(document.body),
        clickOutsideToClose: true
      });
    },
    updateCustomerCardDetailsByCoach: function (customerId) {
      return $mdDialog.show({
        controller: ['$scope', '$mdDialog', 'PaymentService', function ($scope, $mdDialog, PaymentService) {
          $scope.updateCardDetailsHandler = updateCardDetailsHandler;
          $scope.onOkBtnClick = onOkBtnClick;

          getPublishApiKey($scope);

          function updateCardDetailsHandler() {
            updateCardDetails($scope, updateCustomerCardDetails);
          }

          function updateCustomerCardDetails(paymentMethodId) {
            PaymentService.updateCustomerCardDetailsByCoach(customerId, paymentMethodId).then(res => {
              changeStripeView(res.status === 200);
            });
          }

          function onOkBtnClick() {
            $mdDialog.cancel();
          }
        }],
        templateUrl: autoNgTemplateLoaderTemplate2,
        parent: angular.element(document.body),
        clickOutsideToClose: true
      });
    },
    updateCustomerCardDetailsBySuperAdmin: function (entityId, customerId) {
      return $mdDialog.show({
        controller: ['$scope', '$mdDialog', function ($scope, $mdDialog) {
          $scope.updateCardDetailsHandler = updateCardDetailsHandler;
          $scope.onOkBtnClick = onOkBtnClick;
          const getConnectedAccountIdPromise = paymentService.getConnectedAccountId(entityId);
          const getPublishApiKeyPromise = paymentService.getPublishApiKey();

          Promise.all([getConnectedAccountIdPromise, getPublishApiKeyPromise])
            .then(values => {
              if (values.every(value => value.status === 200)) {
                const [
                  { data: { stripeAccountID: connectedAccountId } },
                  { data: { key: publishApiKey } }
                ] = values;

                $scope.stripeData = { connectedAccountId, publishApiKey };
                $scope.$apply();
                setupElements($scope);
              }
            });

          function updateCardDetailsHandler() {
            updateCardDetails($scope, updateCustomerCardDetails);
          }

          function updateCustomerCardDetails(paymentMethodId) {
            PaymentService.updateCustomerCardDetailsByCoach(customerId, paymentMethodId).then(res => {
              changeStripeView(res.status === 200);
            });
          }

          function onOkBtnClick() {
            $mdDialog.cancel();
          }
        }],
        templateUrl: autoNgTemplateLoaderTemplate3,
        parent: angular.element(document.body),
        clickOutsideToClose: true
      });
    },
    updateCustomerCardDetailsByCustomer: function () {
      return $mdDialog.show({
        controller: ['$scope', '$mdDialog', 'PaymentService', function ($scope, $mdDialog, PaymentService) {
          $scope.updateCardDetailsHandler = updateCardDetailsHandler;
          $scope.onOkBtnClick = onOkBtnClick;

          getPublishApiKey($scope);

          function updateCardDetailsHandler() {
            updateCardDetails($scope, updateCustomerCardDetails);
          }

          function updateCustomerCardDetails(paymentMethodId) {
            PaymentService.updateCustomerCardDetailsByCustomer(paymentMethodId).then(res => {
              changeStripeView(res.status === 200);
            });
          }

          function onOkBtnClick() {
            $mdDialog.cancel();
          }
        }],
        templateUrl: autoNgTemplateLoaderTemplate4,
        parent: angular.element(document.body),
        clickOutsideToClose: true
      });
    },
  };

  function getPublishApiKey($scope) {
    paymentService.getPublishApiKey().then(res => {
      if (res.status === 200) {
        setStripeData($scope, res.data);
        setupElements($scope);
      }
    });
  }

  function setStripeData($scope, response) {
    $scope.stripeData = { publishApiKey: response.key };
    if (!$scope.isEntity) {
      $scope.stripeData.connectedAccountId = response.connectedAccountId;
    }
  }

  function setupElements($scope) {
    $scope.stripe = Stripe($scope.stripeData.publishApiKey, { stripeAccount: $scope.stripeData.connectedAccountId });
    const elements = $scope.stripe.elements();
    $scope.card = elements.create("card");
    $scope.card.mount("#card-element");
  }

  function updateCardDetails($scope, successResultHandler) {
    const createPaymentMethodData = {
      type: 'card',
      card: $scope.card,
    };
    changeLoadingState(true);
    $scope.stripe.createPaymentMethod(createPaymentMethodData)
      .then(function (result) {
        if (result.error) {
          // Handle result.error or result.paymentMethod
          handleError(result.error)
        } else {
          successResultHandler(result.paymentMethod.id);
        }
      });
  }

  function changeLoadingState(isLoading) {
    if (isLoading) {
      document.querySelector(".stripe-modal button").disabled = true;
      document.querySelector("#spinner").classList.remove("hidden");
      document.querySelector("#button-text").classList.add("hidden");
    } else {
      document.querySelector(".stripe-modal button").disabled = false;
      document.querySelector("#spinner").classList.add("hidden");
      document.querySelector("#button-text").classList.remove("hidden");
    }
  }

  function handleError(error) {
    changeLoadingState(false);
    var errorMsg = document.querySelector(".sr-field-error");
    errorMsg.textContent = error.message;
    setTimeout(function () {
      errorMsg.textContent = "";
    }, 4000);
  }

  function changeStripeView(isSucceed) {
    document.querySelectorAll(".payment-view").forEach(function (view) {
      view.classList.add("hidden");
    });
    document.querySelectorAll(".completed-view").forEach(function (view) {
      view.classList.remove("hidden");
    });
    document.querySelector(".status").textContent =
      isSucceed ? "succeeded" : "did not complete";
  }

  return stripeService;
}
