import React from 'react';

import classes from './DaysRow.module.scss';
import { daysOfWeek } from '../../../../../../../shared/constants/daysOfWeek';

const DaysRow = () => {
  return (
    <div className={classes.dayRow}>
      {daysOfWeek.map((day) => (
        <p key={day}>{day}</p>
      ))}
    </div>
  );
};

export { DaysRow };
