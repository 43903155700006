const autoNgTemplateLoaderTemplate1 = require('./pagination.template.html');

'use strict';

angular
    .module('app')
    .directive('pagination', pagination);

pagination.$inject = [];

function pagination() {
    return {
        restrict: 'E',
        scope: {
            pagesCount: '=',
            pagination: '=',
            onChange:'&onChange'
        },
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: 'PaginationController',
        controllerAs: 'vm'
    };
}
