import React from 'react';

import classes from './WorkoutMeals.module.scss';
import { MealRow } from './MealRow/MealRow';
import { HeaderRow } from './HeaderRow/HeaderRow';
import { InfoBox } from '../../../../../../shared/components/ui-components';

const WorkoutMeals = () => {
  return (
    <InfoBox title="Pre/Post Workouts" customContentPadding>
      <div className={classes.mealTable}>
        <HeaderRow />
        <MealRow title="Pre Workout" mealFromKey="preWorkout" />
        <MealRow title="Post Workout" mealFromKey="postWorkout" />
      </div>
    </InfoBox>
  );
};

export { WorkoutMeals };
