import React from 'react';

import CircleIcon from '@mui/icons-material/Circle';

import classes from './ScoreLegend.module.scss';
import { Status } from '../../../../../../../shared/components/ui-components';

const ScoreLegend = () => {
  return (
    <div className={classes.scoreRow}>
      <Status icon={<CircleIcon fontSize="small" color="secondary" />}>All Meals</Status>
      <Status icon={<CircleIcon fontSize="small" color="primary" />}>Only Tracked</Status>
    </div>
  );
};

export { ScoreLegend };
