import React from 'react';

import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { Button } from '@mui/material';
import { observer } from 'mobx-react';

import { AddCustomFilterForm } from './AddCustomFilterForm/AddCustomFilterForm';
import { apiStatusStore } from '../../../../stores/apiStatusStore';
import { customFilterStore } from '../../stores/customFilterStore';
import { openConfirmationModal } from '../../../confirmation';
import { LbBigModal } from '../../../../components/modals';

const AddCustomFilterModal = observer(() => {
  const { isLoading } = apiStatusStore;
  const {
    isShowCustomFilterModal,
    editedFilterId,
    editedFilterInfo,
    closeFilterModal,
    deleteCustomFilter,
  } = customFilterStore;

  const isEditMode = !!editedFilterId;

  const title = isEditMode ? 'Edit Custom Filter' : 'Add Custom Filter';

  const formId = 'customFilterForm';

  const handleDeleteFilter = () => {
    closeFilterModal();

    openConfirmationModal({
      title: `Do you want to delete custom filter “${editedFilterInfo?.name}”`,
      confirmBtnConfig: {
        text: 'Confirm',
        onClick: () => deleteCustomFilter(editedFilterId!),
      },
    });
  };

  const DeleteFilterButton = (
    <Button
      variant="outlined"
      startIcon={<DeleteOutlineRoundedIcon />}
      onClick={handleDeleteFilter}
      size="medium"
    >
      Delete
    </Button>
  );

  return (
    <LbBigModal
      open={isShowCustomFilterModal}
      title={title}
      buttonOptions={{ yesText: 'Save', formId, noHandler: closeFilterModal }}
      extraButton={isEditMode && DeleteFilterButton}
      isLoading={isLoading}
    >
      <AddCustomFilterForm formId={formId} />
    </LbBigModal>
  );
});

export { AddCustomFilterModal };
