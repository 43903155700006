import { apiService } from '../../shared/services/apiService';
import { GoHighLevelAccountInfo } from '../interfaces/goHighLevelAccount';

export const goHighLevelAccountService = {
  async getConnectedAccountInfo(entityId: number) {
    const resp = await apiService.get<GoHighLevelAccountInfo | undefined>(
      `/api/entity/${entityId}/ghl/account`,
    );

    return resp.data;
  },

  async createConnection(entityId: number, locationId: string) {
    const params = { locationId };

    const resp = await apiService.post<GoHighLevelAccountInfo>(
      `/api/entity/${entityId}/ghl/account`,
      undefined,
      { params },
    );

    return resp.data;
  },

  async updateConnectedAccountInfo(entityId: number, locationId: string) {
    const params = { locationId };

    const resp = await apiService.put(`/api/entity/${entityId}/ghl/account`, undefined, {
      params,
    });

    return resp.data;
  },

  async removeConnection(entityId: number) {
    const resp = await apiService.delete<undefined>(
      `/api/entity/${entityId}/ghl/account`,
    );

    return resp.data;
  },

  async refreshConnectionInfo(entityId: number) {
    const resp = await apiService.post<GoHighLevelAccountInfo | undefined>(
      `/api/entity/${entityId}/ghl/refresh`,
    );

    return resp.data;
  },
};
