import { Flags, StringKey } from '../../../../utils-types';

export const getFlagsObject = <TConfig extends Record<string, string>>(
  config: TConfig,
  values: (string | undefined)[],
): Flags<StringKey<TConfig>> => {
  const flags = {} as Flags<StringKey<TConfig>>;

  if (!Array.isArray(values)) {
    const massage = `getFlagsObject utils values must be an array but got ${values}`;
    console.error(massage); // fix show error in console for angular
    throw new Error(massage);
  }

  Object.entries(config).forEach(([key, value]) => {
    const isValue = values.includes(value);
    const fixedKey = key as StringKey<TConfig>;

    flags[fixedKey] = isValue;
  });

  return flags;
};
