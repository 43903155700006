const autoNgTemplateLoaderTemplate1 = require('./videos-tab.template.html');

'use strict';

angular
    .module('app')
    .directive('videosTab', videosTab);

videosTab.$inject = [];

function videosTab() {
    return {
        restrict: 'E',
        scope: {},
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: 'VideosTabController',
        controllerAs: 'vm'
    };
}
