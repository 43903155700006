import { FILE_EXTENSION } from '../../shared/constants/file/FILE_EXTENSIONS';
import { apiReportService } from '../../shared/services/apiService';
import { getApiUrlRole } from '../../shared/utils/apiUtils';
import { downloadReportFile } from '../utils';

const { PDF, CSV } = FILE_EXTENSION;

export const salesReportService = {
  async getReports() {
    const apiUrlRole = getApiUrlRole();

    const resp = await apiReportService.get(`/api/${apiUrlRole}/sales-reports/list`);

    return resp.data;
  },

  async downloadReportPDF(reportType, params) {
    const apiUrlRole = getApiUrlRole();

    const resp = await apiReportService.get(
      `api/${apiUrlRole}/sales-report/download/${reportType}`,
      { params, responseType: 'arraybuffer' },
    );

    downloadReportFile(resp.data, reportType, PDF);
  },

  async downloadReportCSV(reportType, params) {
    const apiUrlRole = getApiUrlRole();

    const resp = await apiReportService.get(
      `api/${apiUrlRole}/sales-report/download-csv/${reportType}`,
      { params, responseType: 'text' },
    );

    downloadReportFile(resp.data, reportType, CSV);
  },
};
