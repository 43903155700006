import { makeAutoObservable } from 'mobx';
import { CheckoutInfo } from '../interfaces/checkoutInfo';
import { resetAllStore } from '../../shared/stores/resetAllStore';
import { checkoutService } from '../services/checkoutService';
import {
  SUBSCRIPTION,
  SubscriptionType,
  SubscriptionTypeFlags,
} from '../../shared/constants/user-info/subscription';
import { getFlagsObject } from '../../shared/utils/sortingAndGroupingUtil';

class CheckoutStore {
  checkoutInfo: CheckoutInfo | null = null;
  subscriptionTypeFlags: SubscriptionTypeFlags = {};

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.checkoutInfo = null;
  }

  async createSubscription(entityId: number, email: string, subscriptionId: number) {
    const response = await checkoutService.createSubscription(
      entityId,
      email,
      subscriptionId,
    );

    this.setCheckoutInfo(response);
    this.setSubscriptionTypeFlags(response.subscription.subscriptionType);
  }

  private setCheckoutInfo(checkoutInfo: CheckoutInfo) {
    this.checkoutInfo = checkoutInfo;
  }

  private setSubscriptionTypeFlags(subscriptionType: SubscriptionType) {
    const subscriptionTypeFlags = getFlagsObject(SUBSCRIPTION, [subscriptionType]);

    this.subscriptionTypeFlags = subscriptionTypeFlags;
  }
}

const checkoutStore = new CheckoutStore();
resetAllStore.addResetMethod(checkoutStore.setDefaultValues);

export { checkoutStore, CheckoutStore };
