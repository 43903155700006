import { Components } from '@mui/material';

export const MuiSwitchTheme: Components['MuiSwitch'] = {
  defaultProps: {
    disableRipple: false,
    color: 'success',
  },
  styleOverrides: {
    root: {
      width: '42px',
      height: '24px',
      padding: '2px 4px',
    },
    input: {
      margin: 0,
    },
    switchBase: {
      padding: '2px',
      marginTop: '-10px', // center circle 50% of width + padding
      top: '50%',
      left: '4px', // 2px box padding + 2px item padding

      '&.Mui-checked': {
        transform: 'translateX(14px)', // track width - (thumb width + thumb padding)
        '& + .MuiSwitch-track': {
          opacity: 1,
        },
      },
    },
    thumb: {
      width: '16px',
      height: '16px',
    },
    track: {
      borderRadius: '12px',
    },
  },
  variants: [
    {
      props: { color: 'secondary' },
      style: {
        '.MuiSwitch-track ': {
          backgroundColor: 'var(--natural-400)',
        },
        '.MuiSwitch-switchBase': {
          '&.Mui-checked': {
            color: 'var(--white)',
            '& + .MuiSwitch-track': {
              backgroundColor: 'var(--main-black)',
            },
          },
        },
      },
    },
    {
      props: { color: 'success' },
      style: {
        '.MuiSwitch-track ': {
          backgroundColor: 'var(--natural-400)',
        },
        '.MuiSwitch-switchBase': {
          '&.Mui-checked': {
            color: 'var(--white)',
            '& + .MuiSwitch-track': {
              backgroundColor: 'var(--success-300)',
            },
          },
        },
        '.Mui-disabled': {
          '& + .MuiSwitch-track ': {
            backgroundColor: 'var(--natural-200)',
            opacity: '1 !important',
          },
          '&.MuiSwitch-switchBase': {
            color: 'var(--white)',
            '&.Mui-checked': {
              color: 'var(--white)',
              '& + .MuiSwitch-track': {
                backgroundColor: 'var(--success-100)',
              },
            },
          },
        },
      },
    },
  ],
};
