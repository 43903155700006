import React, { ChangeEventHandler } from 'react';

import PhotoCameraRoundedIcon from '@mui/icons-material/PhotoCameraRounded';

import classes from './UploadAvatar.module.scss';

type UploadAvatarProps = {
  onAvatarChange: ChangeEventHandler<HTMLInputElement>;
};

const UploadAvatar = ({ onAvatarChange }: UploadAvatarProps) => {
  return (
    <label htmlFor="avatar-file" className={classes.avatarUpload}>
      <PhotoCameraRoundedIcon color="white" fontSize="medium" />
      <span>Upload</span>
      <input onChange={onAvatarChange} id="avatar-file" type="file" accept="image/*" />
    </label>
  );
};

export { UploadAvatar };
