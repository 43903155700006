import React from 'react';
import PropTypes from 'prop-types';

import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import PendingIcon from '@mui/icons-material/Pending';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';

import classes from './StatusCell.module.scss';
import { inventoryStatus } from '../../../../../constants/inventoryStatus';

const StatusCell = ({ status }) => {
  const PendingCell = (
    <div className={`${classes.cell} ${classes.yellow}`}>
      <PendingIcon fontSize="medium" />
      <p>Pending</p>
    </div>
  );

  const LowCell = (
    <div className={`${classes.cell} ${classes.red}`}>
      <WarningRoundedIcon fontSize="medium" />
      <p>Low Inventory</p>
    </div>
  );

  const SufficientCell = (
    <div className={`${classes.cell} ${classes.green}`}>
      <CheckBoxRoundedIcon fontSize="medium" />
      <p>Sufficient Inventory</p>
    </div>
  );

  switch (status) {
    case inventoryStatus.PENDING: {
      return PendingCell;
    }
    case inventoryStatus.LOW_INVENTORY: {
      return LowCell;
    }
    case inventoryStatus.SUFFICIENT_INVENTORY: {
      return SufficientCell;
    }
    default: {
      return <div className={classes.cell}>Empty status</div>;
    }
  }
};

StatusCell.propTypes = {
  status: PropTypes.string,
};

export { StatusCell };
