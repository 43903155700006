import React from 'react';
import CheckCircleRounedIcon from '@mui/icons-material/CheckCircleRounded';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';

import classes from './CodeStatus.module.scss';
import { Status } from '../../../../../../shared/components/ui-components';
import { PromoCodeStatus } from '../../../../../constants/promoCodes';

type PromoCodeStatusProps = {
  status: PromoCodeStatus;
  statusDescription?: string | null;
};

const CodeStatus = ({ status, statusDescription }: PromoCodeStatusProps) => {
  let icon;
  let statusText;

  switch (status) {
    case 'ACTIVE':
      icon = <CheckCircleRounedIcon color="success" />;
      statusText = 'Active';
      break;
    case 'PAUSED':
      icon = <PauseCircleOutlineIcon color="warning" />;
      statusText = 'Paused';
      break;
    case 'INACTIVE':
      icon = <BlockOutlinedIcon color="error" />;
      statusText = 'Inactive';
      break;
    default:
      statusText = 'No info';
  }

  return (
    <Status icon={icon}>
      <p>{statusText}</p>
      <p className={classes.reasonText}>{statusDescription}</p>
    </Status>
  );
};

export { CodeStatus };
