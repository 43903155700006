import { calculateBodyFatMass, calculateLbm } from '../../../../../../inbody';
import { getIsNullish } from '../../../../../../shared/utils/jsUtils';
import { getRoundedNumber } from '../../../../../../shared/utils/mathUtils';

export type BodyMetrics = {
  weight: number | null;
  percentBodyFat: number | null;
};

const getChange = (current: number | null, goal: number | null) => {
  if (getIsNullish(current) || getIsNullish(goal)) {
    return null;
  }

  return getRoundedNumber(goal - current, 2);
};

export const getMetrics = (current: BodyMetrics, goal: BodyMetrics) => {
  const currentBodyFatMass = calculateBodyFatMass(current.weight, current.percentBodyFat);
  const goalBodyFatMass = calculateBodyFatMass(goal.weight, goal.percentBodyFat);

  const currentLbm = calculateLbm(current.weight, currentBodyFatMass);
  const goalLbm = calculateLbm(goal.weight, goalBodyFatMass);

  return {
    weight: {
      current: current.weight,
      goal: goal.weight,
      change: getChange(current.weight, goal.weight),
    },
    percentBodyFat: {
      current: current.percentBodyFat,
      goal: goal.percentBodyFat,
      change: getChange(current.percentBodyFat, goal.percentBodyFat),
    },
    bodyFatMass: {
      current: currentBodyFatMass,
      goal: goalBodyFatMass,
      change: getChange(currentBodyFatMass, goalBodyFatMass),
    },
    lbm: {
      current: currentLbm,
      goal: goalLbm,
      change: getChange(currentLbm, goalLbm),
    },
  };
};
