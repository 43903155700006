import React from 'react';

import { observer } from 'mobx-react';
import { SvgIcon } from '@mui/material';
import CallMadeRoundedIcon from '@mui/icons-material/CallMadeRounded';

import { UserMenuItem } from '../UserMenuItem/UserMenuItem';
import { loggedInUserDataStore } from '../../../../../stores/loggedInUserDataStore';
import { ReactComponent as GoHighLevelIcon } from '../../../../../assets/icons/goHighLevelIcon.svg';
import { systemService } from '../../../../../services/systemService';
import { redirectTo } from '../../../../../utils/windowUtils';

const GoHighLevelRefreshItem = observer(() => {
  const { isSuperAdmin } = loggedInUserDataStore;

  if (!isSuperAdmin) {
    return null;
  }

  const handleGoHighLevelRefresh = async () => {
    const { redirectUrl } = await systemService.getGoHighLevelAuthorizationLink();

    redirectTo(redirectUrl);
  };

  return (
    <UserMenuItem
      onClick={handleGoHighLevelRefresh}
      label="GoHighLevel Refresh"
      startIcon={<SvgIcon component={GoHighLevelIcon} inheritViewBox />}
      endIcon={<CallMadeRoundedIcon color="primary" fontSize="small" />}
    />
  );
});

export { GoHighLevelRefreshItem };
