import { makeAutoObservable } from 'mobx';

import { inbodyKeysService } from '../services/inbodyKeysService';
import { resetAllStore } from '../../shared/stores/resetAllStore';
import { deleteItemByField, updateItemByField } from '../../shared/utils/arrayUtils';

class InbodyKeysStore {
  constructor() {
    this.setDefaultValues();
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.inbodyKeys = [];
    this.entities = [];
    this.isAddModalOpened = false;
    this.isAddNewButtonAvailable = true;
    this.editableKeysCount = 0;
  }

  async constructPageWithInbodyKeys() {
    const [{ items }, { entities }] = await Promise.all([
      inbodyKeysService.getInbodyKeys(),
      inbodyKeysService.getEntitiesBasics(),
    ]);
    this.setEntities(entities);
    this.prepareInbodyKeysInfo(items);
  }

  prepareInbodyKeysInfo(keys) {
    const inbodyKeysWithEntityNames = keys.map((inbodyKey) =>
      this.addEntityNameToInbodyKey(inbodyKey),
    );
    this.setKeys(inbodyKeysWithEntityNames);
  }

  addEntityNameToInbodyKey(inbodyKey) {
    const itemEntity = this.findEntityRelatedToKey(inbodyKey);
    return {
      ...inbodyKey,
      entityName: itemEntity?.business_name || '',
    };
  }

  setKeys(keys) {
    this.inbodyKeys = keys;
  }

  setEntities(entities) {
    this.entities = entities;
  }

  async addKey(inbodyKey) {
    const responseData = await inbodyKeysService.addInbodyKey(inbodyKey);
    this.addKeyLocally(responseData);
  }

  findEntityRelatedToKey(key) {
    return this.entities.find((entity) => entity.id === key.entityId);
  }

  addKeyLocally(inbodyKey) {
    const keyWithEntityName = this.addEntityNameToInbodyKey(inbodyKey);
    this.setKeys([keyWithEntityName, ...this.inbodyKeys]);
  }

  setUpdatedKey(id, inbodyKey) {
    const inbodyKeyWithEntityName = this.addEntityNameToInbodyKey(inbodyKey);
    const inbodyKeys = updateItemByField(this.inbodyKeys, inbodyKeyWithEntityName, {
      id,
    });

    this.setKeys(inbodyKeys);
  }

  async editKey(id, data) {
    const responseData = await inbodyKeysService.updateInbodyKey(id, data);
    this.setUpdatedKey(id, responseData);
  }

  async deleteKey(id) {
    await inbodyKeysService.deleteInbodyKey(id);

    const inbodyKeys = deleteItemByField(this.inbodyKeys, { id });
    this.setKeys(inbodyKeys);
  }

  changeModalOpenState() {
    this.isAddModalOpened = !this.isAddModalOpened;
  }

  setAddNewButtonIsAvailableState(isAvailable) {
    this.isAddNewButtonAvailable = isAvailable;
  }

  increaseEditableKeysCount() {
    this.editableKeysCount++;
    this.setAddNewButtonIsAvailableState(!this.editableKeysCount);
  }

  decreaseEditableKeysCount() {
    this.editableKeysCount--;
    this.setAddNewButtonIsAvailableState(!this.editableKeysCount);
  }
}

const inbodyKeysStore = new InbodyKeysStore();

resetAllStore.addResetMethod(inbodyKeysStore.setDefaultValues);

export { inbodyKeysStore, InbodyKeysStore };
