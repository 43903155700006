import React, { useEffect, useState } from 'react';

import { Button, Paper, RadioGroup } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react';

import classes from './FinalizeOrderStep.module.scss';
import { InputBox } from './InputBox/InputBox';
import { SubscriptionCell } from './SubscriptionCell/SubscriptionCell';
import { LbDatePicker } from '../../../../../shared/components/form-components';
import { TitleBlock } from './TitleBlock/TitleBlock';
import { getFormattedRegistrationDate } from './utils/getFormatedRegistrationDate';
import { setupScholasticCustomerStore } from '../../../../stores/setupScholasticCustomerStore';
import { ScholasticCustomerSetupFormValue } from '../../NgzScholasticUserSetup.settings';
import { subscriptionService } from '../../../../services/subscriptionService';
import { Subscription } from '../../../../interfaces/Subscription';
import { loggedInUserDataStore } from '../../../../../shared/stores/loggedInUserDataStore';
import { goTo } from '../../../../../shared/utils/angularUtils';

const FinalizeOrderStep = observer(() => {
  const [subscriptions, setSubscription] = useState<Subscription[]>([]);
  const { userId } = setupScholasticCustomerStore;

  const { control, watch, handleSubmit, setValue } =
    useFormContext<ScholasticCustomerSetupFormValue>();
  const { field } = useController({
    control,
    name: 'setupSubscription.subscriptionID',
    defaultValue: '' as unknown as number,
    rules: { required: true },
  });

  const prepareSubscriptions = async () => {
    const entityId = loggedInUserDataStore.getEntityId();

    const { subscriptions: subscriptionsList } =
      await subscriptionService.getSubscriptionList(entityId, true, 'SCHOLASTIC');

    const firstSubscriptionId = subscriptionsList[0].subscriptionID;

    setValue('setupSubscription.subscriptionID', firstSubscriptionId);
    setSubscription(subscriptionsList);
  };

  useEffect(() => {
    prepareSubscriptions();
  }, []);

  const registrationDateValue = watch('setupSubscription.startFrom');

  const formattedRegistrationDate = getFormattedRegistrationDate(registrationDateValue);

  const onSubmit = async ({ setupSubscription }: ScholasticCustomerSetupFormValue) => {
    await subscriptionService.setSubscription({ userID: userId!, ...setupSubscription });

    goTo('/');
  };

  return (
    <Paper variant="roundedBox" className={classes.box}>
      <TitleBlock />
      <div className={classes.inputsBlock}>
        <InputBox title="Subscriptions">
          <RadioGroup {...field} className={classes.subscriptionsBox}>
            {subscriptions.map((subscription) => (
              <SubscriptionCell
                name={subscription.title}
                subscriptionId={subscription.subscriptionID}
                key={subscription.subscriptionID}
              />
            ))}
          </RadioGroup>
        </InputBox>
        <InputBox title="Registration Date">
          <LbDatePicker
            control={control}
            name="setupSubscription.startFrom"
            placeholder="MMMM DD YYYY"
            disablePast
            rules={{ required: 'Registration Date is required' }}
          />
        </InputBox>
      </div>
      <Button variant="contained" fullWidth onClick={handleSubmit(onSubmit)}>
        Send Magic Link on {formattedRegistrationDate}
      </Button>
    </Paper>
  );
});

export { FinalizeOrderStep };
