import React from 'react';

import { angularize } from 'react-in-angularjs';
import { observer } from 'mobx-react';
import { AppBar, Toolbar } from '@mui/material';

import classes from './NgzHeader.module.scss';
import { SystemProviders } from '../../providers';
import { LogoLink } from './LogoLink/LogoLink';
import { PagesNavBox } from './PagesNavBox/PagesNavBox';
import { UserMenu } from './UserMenu/UserMenu';
import { loggedInUserDataStore } from '../../stores/loggedInUserDataStore';
import { useIsActivePage } from '../../hooks/useIsActivePage';

type NgzHeaderProps = {
  isHideNavBar?: boolean;
};

const pagesWithoutNavBar = [
  '/customer-self-setup',
  '/set-new-password',
  '/select-subscription',
];
const pagesWithoutHeader = [
  '/checkout',
  '/payment-result',
  '/customer-self-setup/to-entity',
];

// TODO LMP-2471 delete isHideNavBar props
const NgzHeader = observer(({ isHideNavBar = false }: NgzHeaderProps) => {
  const { loggedInUser } = loggedInUserDataStore;

  const hideNavBar = isHideNavBar || useIsActivePage({ urls: pagesWithoutNavBar });

  const isPageWithoutHeader = useIsActivePage({ urls: pagesWithoutHeader });

  if (isPageWithoutHeader === null || isPageWithoutHeader) {
    return null;
  }

  return (
    <SystemProviders>
      <AppBar className={classes.header}>
        <Toolbar component="nav">
          <LogoLink />
          {!hideNavBar && (
            <>
              {loggedInUser?.id && <PagesNavBox />}
              {loggedInUser && <UserMenu />}
            </>
          )}
        </Toolbar>
      </AppBar>
    </SystemProviders>
  );
});

angularize(NgzHeader, 'ngzReactHeader', angular.module('app'), { isHideNavBar: '<' });

export { NgzHeader };
