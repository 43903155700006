import React from 'react';
import PropTypes from 'prop-types';

import {
  LbAutocomplete,
  LbDatePicker,
  LbInput,
  LbSelect,
} from '../../../shared/components/form-components';
import { patterns } from '../../../shared/constants/patterns';
import { states } from '../../../shared/constants/states';
import { validateMinAge } from '../../../shared/utils/reactHookFormUtils';
import { genderOptions } from '../../../shared/constants/user-info/gender';

const UserProfileInputs = ({ control, fieldName = '' }) => {
  const wrongPhonePatternMessage = 'Invalid format: (752) 000 0000';

  return (
    <>
      <LbInput
        control={control}
        name={`${fieldName}.user.firstName`}
        label="First name"
        required
        rules={{
          required: 'First name is required',
          pattern: {
            value: patterns.ONLY_LETTERS,
            message: 'First name must contain only letters',
          },
        }}
      />
      <LbInput
        control={control}
        name={`${fieldName}.user.lastName`}
        label="Last name"
        required
        rules={{
          required: 'Last name is required',
          pattern: {
            value: patterns.ONLY_LETTERS,
            message: 'Last name must contain only letters',
          },
        }}
      />
      <LbInput
        control={control}
        name={`${fieldName}.user.email`}
        label="Email address"
        required
        rules={{
          required: 'Email is required',
          pattern: {
            value: patterns.EMAIL,
            message: 'Invalid email address',
          },
        }}
      />
      <LbInput
        control={control}
        name={`${fieldName}.customer.cellPhone`}
        label="Cell phone"
        type="tel"
        rules={{
          required: 'Cell phone is required',
          pattern: {
            value: patterns.PHONE,
            message: wrongPhonePatternMessage,
          },
        }}
        required
      />
      <LbInput
        control={control}
        name={`${fieldName}.customer.cellPhoneOpt`}
        label="Cell phone - opt in texting"
        type="tel"
        rules={{
          pattern: {
            value: patterns.PHONE,
            message: wrongPhonePatternMessage,
          },
        }}
      />
      <LbDatePicker
        control={control}
        name={`${fieldName}.customer.dob`}
        label="Date of birth (M.D.Y.)"
        required
        rules={{
          required: 'Date of birth is required',
          validate: { validateMinAge },
        }}
      />
      <LbSelect
        control={control}
        name={`${fieldName}.customer.gender`}
        options={genderOptions}
        label="Gender"
        rules={{ required: 'Gender is required' }}
        required
      />
      <LbInput
        control={control}
        name={`${fieldName}.customer.address1`}
        label="Address"
        rules={{ required: 'Address is required' }}
        required
      />
      <LbInput
        control={control}
        name={`${fieldName}.customer.address2`}
        label="Address 2"
      />
      <LbInput
        control={control}
        name={`${fieldName}.customer.city`}
        label="City"
        rules={{ required: 'City is required' }}
        required
      />
      <LbAutocomplete
        control={control}
        name={`${fieldName}.customer.state`}
        options={states}
        label="State"
        rules={{ required: 'State is required' }}
        required
      />
      <LbInput
        control={control}
        name={`${fieldName}.customer.zipCode`}
        label="Zip Code"
        rules={{ required: 'Zip Code is required' }}
        type="number"
        required
      />
    </>
  );
};

UserProfileInputs.propTypes = {
  control: PropTypes.object.isRequired,
  fieldName: PropTypes.string,
};

export { UserProfileInputs };
