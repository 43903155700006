import { apiService } from '../../shared/services/apiService';
import { CoachListFilters } from '../interfaces/coachList/CoachListFilters';
import { Coach } from '../interfaces/coachList/CoachListInfo';
import { SpringPagination } from '../../shared/interfaces/SpringPagination';
import { getFilteredTruthyFieldsObj } from '../../shared/utils/sortingAndGroupingUtil';

type CoachListResponse = SpringPagination<Coach[]>;

export const coachService = {
  async getCoachList(params: CoachListFilters) {
    const filteredParams = getFilteredTruthyFieldsObj(params);

    const response = await apiService.post<CoachListResponse>(
      '/api/sign-up/coach-list',
      filteredParams,
    );

    return response.data;
  },
};
