import React, { ChangeEventHandler } from 'react';

import { Button } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import classes from './UploadFileButton.module.scss';

type UploadFileButtonProps = {
  onFilesUpload: ChangeEventHandler<HTMLInputElement>;
};

const UploadFileButton = ({ onFilesUpload }: UploadFileButtonProps) => {
  return (
    <label htmlFor="files">
      <Button
        startIcon={<AttachFileIcon />}
        variant="outlined"
        className={classes.uploadBtn}
        component="span"
      >
        <input onChange={onFilesUpload} id="files" type="file" multiple />
        Attach Files
      </Button>
    </label>
  );
};

export { UploadFileButton };
