(function () {
    'use strict';

    angular.module('app.preCheckIn').controller('PreCheckInController', PreCheckInController);

    PreCheckInController.$inject = [
        'utilService',
        'ModalService',
        'PreCheckInService',
        'DateTimeUtil',
        'localStorageService',
        'constants',
        '$scope',
    ];

    function PreCheckInController( utilService, ModalService, PreCheckInService,
                                   DateTimeUtil, localStorageService, constants, $scope) {
        const vm = this;
        vm.isFilterApply = false;
        vm.numberOfSelectedUsers = 0;
        vm.isDeleteItems = false;
        vm.spinner = {active: false};
        vm.filter = {};
        vm.currentDate = new Date();
        vm.pagesCount = 0;
        vm.pagination = {};
        vm.statusList = [
            { name: 'All' },
            { value: 'completed', name: 'Completed' },
            { value: 'pending', name: 'Pending' },
            { value: 'declined', name: 'Declined' },
        ];
        vm.storageKeys = constants.enums.storageKeys;
        vm.isCopmlianceCoach = utilService.getComplianceCoachIndicator();

        vm.init = init;
        vm.getUsers = getUsers;
        vm.formatDate = formatDate;
        vm.onUserCheckboxClick = onUserCheckboxClick;
        vm.onDelete = onDelete;
        vm.presentDeleteConfirm = presentDeleteConfirm;
        vm.goTo = goTo;
        vm.deleteUsers = deleteUsers;
        vm.presentDeleteAllConfirm = presentDeleteAllConfirm;
        vm.deleteAllUsers = deleteAllUsers;
        vm.onSuccessUsersDelete = onSuccessUsersDelete;
        vm.resend = resend;
        vm.getParams = getParams;
        vm.changeOrder = changeOrder;
        vm.toggleAllUsersSelection = toggleAllUsersSelection;
        vm.onPaginationPageChange = onPaginationPageChange;
        vm.processPagination = processPagination;
        vm.onCancel = onCancel;
        vm.onProceed = onProceed;
        vm.showSubscriptionInfo = showSubscriptionInfo;

        vm.init();

        function init() {
            vm.getUsers();
        }

        function getUsers() {
            vm.spinner.active = true;
            const params = vm.getParams();
            PreCheckInService.getUsers(params).then((res) => {
                vm.spinner.active = false;
                if (res.status === 200) {
                    vm.isFilterApply = Object.keys(params).some( param => !['order_field', 'order', 'page'].includes(param));
                    vm.users = res.data.users.map(user => {
                        user.dob = vm.formatDate(user.dob, 'MM.DD.YYYY');
                        user.sendingDate = vm.formatDate(user.sendingDate, 'MM.DD.YYYY');
                        return user;
                    });
                    vm.toggleAllUsersSelection();
                    vm.processPagination(res);
                }
            });
        }

        function getParams() {
            const params = {};
            for (let key in vm.filter) {
                if (vm.filter.hasOwnProperty(key) && vm.filter[key]) {
                    if (key === 'by_dob' || key === 'by_sending_date') {
                        params[key] = vm.formatDate(vm.filter[key], 'YYYY-MM-DD', moment.ISO_8601);
                    } else {
                        params[key] = vm.filter[key];
                    }
                }
            }
            return params;
        }

        function formatDate(date, finalFormat, initialFormat) {
            return DateTimeUtil.convertDateFormat(date, initialFormat || 'YYYY-MM-DD', finalFormat);
        }

        function processPagination(response) {
            if (!vm.filter.page) {
                vm.pagesCount = response.data.pages;
                vm.pagination.reset(vm.pagesCount);
            }
            vm.filter.page = 0;
        }

        function toggleAllUsersSelection() {
            if (vm.selectedAll) {
                vm.users.forEach(function(item) {
                    item.selected = true;
                });
                vm.numberOfSelectedUsers = vm.users.length;
            } else {
                vm.users.forEach(function(item) {
                    item.selected = false;
                });
                vm.numberOfSelectedUsers = 0;
                vm.selectedAll = false;
            }
        }

        function onUserCheckboxClick(user) {
            if (user.selected) {
                vm.numberOfSelectedUsers++;
            } else {
                vm.numberOfSelectedUsers--;
            }
            vm.selectedAll = vm.numberOfSelectedUsers === vm.users.length;
        }

        function onDelete(user) {
            if (!user.selected) {
                user.selected = true;
                vm.numberOfSelectedUsers++;
            }
            vm.presentDeleteConfirm();
        }

        function presentDeleteConfirm() {
            const message = 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet';
            ModalService.confirm(`Do you want to delete ${vm.numberOfSelectedUsers} requests for parents?`,
                vm.deleteUsers, null, message, 'Cancel', 'Yes');
        }

        function goTo(path) {
            utilService.goTo(path);
        }

        function deleteUsers() {
            vm.spinner.active = true;
            const selectedUsers = vm.users.filter(user => user.selected);
            const selectedUsersIdArr = selectedUsers.map(user => user.id);
            PreCheckInService.deleteUsers(selectedUsersIdArr).then((res) => {
                vm.spinner.active = false;
                if (res.status === 200) {
                    vm.onSuccessUsersDelete();
                }
            })
        }

        function presentDeleteAllConfirm() {
            const message = 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet';
            ModalService.confirm(`Do you want to delete all requests for parents?`,
              vm.deleteAllUsers, null, message, 'Cancel', 'Yes');
        }

        async function deleteAllUsers() {
            vm.spinner.active = true;
            try {
                const resp = await PreCheckInService.deleteAllUsers();
                if (resp.status === 204) {
                   await vm.onSuccessUsersDelete();
                }
            } finally {
                vm.spinner.active = false;
                $scope.$apply();
            }
        }

        async function onSuccessUsersDelete() {
            vm.isDeleteItems = false;
            await vm.getUsers();
        }

        function resend(user) {
            vm.spinner.active = true;
            PreCheckInService.resendRequest(user.id).then(res => {
                vm.spinner.active = false;
                if (res.status === 200) {
                    user.isDisabled = true;
                }
            });
        }

        function changeOrder(orderField, order) {
            vm.filter.order_field = orderField;
            vm.filter.order = order;
            vm.getUsers();
        }

        function onPaginationPageChange(page) {
            vm.filter.page = page;
            vm.getUsers();
        }

        function onCancel() {
            vm.selectedAll = false;
            vm.toggleAllUsersSelection();
            vm.isDeleteItems = false
        }

        function onProceed(user) {
            localStorageService.set(vm.storageKeys.PRE_CHECK_IN_USER_DATA, user);
            vm.goTo('/customer-setup');
        }

        function showSubscriptionInfo(subscription) {
            const {
                title,
                initialDaysOfSubscription,
                initialSubscriptionAmount,
                autoRenewal,
                renewDaysOfSubscription,
                renewalSubscriptionAmount,
              } = subscription;

            const subscriptionInfo = `${initialDaysOfSubscription} days -
                                     $${initialSubscriptionAmount}`;

            let renewInfo = 'no';

            if (autoRenewal) {
                renewInfo = `yes; ${renewDaysOfSubscription} days - $${renewalSubscriptionAmount}`;
            }

            const info = `<p>
                            Initial Subscription:
                            ${subscriptionInfo};
                        </p>
                        <p>
                            Renew: ${renewInfo};
                        </p>`;

            ModalService.getSubscriptionInfo({title, info});
        }
    }
})();
