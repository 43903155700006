import React from 'react';

import { Button } from '@mui/material';
import { FallbackProps } from 'react-error-boundary';

import classes from './ErrorFallback.module.scss';
import errorFallbackImg from '../../assets/image/errorFallbackImg.png';
import { goToHomePageWithReload } from '../../utils/windowUtils';

const ErrorFallback = ({ error }: Pick<FallbackProps, 'error'>) => {
  return (
    <div className={classes.box}>
      <img src={errorFallbackImg} alt="error img" />
      <div className={classes.textBox}>
        <h3>Oops! It looks like something goes wrong.</h3>
        <p>Problem: {error.message}</p>
        <p>Maybe try to refresh the page or return to the home page?</p>
      </div>
      <Button variant="contained" onClick={goToHomePageWithReload}>
        Return to the home page
      </Button>
    </div>
  );
};

export { ErrorFallback };
