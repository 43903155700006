import React from 'react';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '@mui/material';
import moment from 'moment';

import classes from './EditActiveCalendar.module.scss';
import { dateFormat } from '../../../../../../shared/constants/apiDateFormat';
import { useModalToggle } from '../../../../../../shared/hooks/useModalToggle';
import { ActiveCalendarForm } from '../../../../../components/ActiveCalendarForm/ActiveCalendarForm';
import { activeCalendarService } from '../../../../../services/activeCalendarService';
import { supplementsStore } from '../../../../../stores/supplementsStore';
import { TabTitleRow } from '../../../components/TabTitleRow/TabTitleRow';
import { SubmitModal } from './SubmitModal/SubmitModal';
import { LbDatePicker } from '../../../../../../shared/components/form-components';
import { customerActiveCalendarStore } from '../../../../../stores/customerActiveCalendarStore';

const formArrName = 'newSupplementScheduleJsons';

const { getCustomerScheduleForEdit, sendActiveCalendar } = activeCalendarService;

const EditActiveCalendar = observer(({ showEditHandler, customerId }) => {
  const [isSubmitModalOpen, submitModalHandler] = useModalToggle();

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const { getSupplements } = supplementsStore;
  const { isActiveCalendarCreate } = customerActiveCalendarStore;

  const { control, handleSubmit, watch } = methods;

  const initActiveCalendarForm = async () => {
    const [editActiveCalendar] = await Promise.all([
      getCustomerScheduleForEdit(customerId),
      getSupplements(),
    ]);

    return editActiveCalendar;
  };

  const onSubmit = async (data) => {
    if (isActiveCalendarCreate) {
      data.validFrom = moment().add(1, 'days').format(dateFormat);
    }

    await sendActiveCalendar(data, customerId);
    submitModalHandler();
  };

  const onCloseModalHandler = () => {
    submitModalHandler();
    showEditHandler();
  };

  const isBtnDisabled = !watch(formArrName)?.length;

  const submitBtnText = isActiveCalendarCreate ? 'Apply Tomorrow' : 'Create';

  return (
    <>
      <FormProvider {...methods}>
        <TabTitleRow title="Active Calendar">
          <div className={classes.btnBox}>
            <Button variant="outlined" onClick={showEditHandler}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={isBtnDisabled}
            >
              {submitBtnText}
            </Button>
          </div>
        </TabTitleRow>
        {!isActiveCalendarCreate && (
          <LbDatePicker
            control={control}
            name="validFrom"
            disablePast
            defaultToday
            fullWidth={false}
          />
        )}
        <ActiveCalendarForm
          formArrName={formArrName}
          setInitValue={initActiveCalendarForm}
        />
      </FormProvider>
      <SubmitModal open={isSubmitModalOpen} onCloseHandler={onCloseModalHandler} />
    </>
  );
});

EditActiveCalendar.propTypes = {
  showEditHandler: PropTypes.func,
  customerId: PropTypes.string,
};

export { EditActiveCalendar };
