/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

import StarIcon from '@mui/icons-material/Star';

import classes from './ScoreBox.module.scss';

const ScoreBox = ({ scoreInfo = [] }) => {
  return (
    <div>
      {scoreInfo.map((row, index) => (
        <div className={classes.scoreRow} key={index}>
          <p>{row.title}</p>
          <p className={classes.result}>
            {row.score} <StarIcon fontSize="large" />
          </p>
        </div>
      ))}
    </div>
  );
};

ScoreBox.propTypes = {
  scoreInfo: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      score: PropTypes.string,
    }),
  ),
};

export { ScoreBox };
