export const chartsDataList = [
    {
      chartId: 'weightChart',
      title: 'Weight (lbs)',
      dbName: 'body_comp_weight',
      data: [],
      instance: null,
      isShow: true,
    },
    {
      chartId: 'smmChart',
      title: 'Skeletal Muscle Mass (lbs)',
      dbName: 'muscle_fat_sceleton_mass',
      data: [],
      instance: null,
      isShow: true,
    },
    {
      chartId: 'lbmChart',
      title: 'Lean Body Mass (lbs)',
      dbName: 'body_comp_lbs',
      data: [],
      instance: null,
      isShow: true,
    },
    {
      chartId: 'bfmChart',
      title: 'Body Fat Mass (lbs)',
      dbName: 'body_comp_bodyFatMass',
      data: [],
      instance: null,
      isShow: true,
    },
    {
      chartId: 'pbfChart',
      title: 'Percent Body Fat (%)',
      dbName: 'obesity_percentBodyFat',
      data: [],
      instance: null,
      isShow: true,
    },
    {
      chartId: 'tbwWeightChart',
      title: 'TBW/Weight (%)',
      dbName: 'tbw_weight',
      data: [],
      instance: null,
      isShow: true,
    },
    {
      chartId: 'ecwTbwChart',
      title: 'ECW/TBW',
      dbName: 'ecw_tbw',
      data: [],
      instance: null,
      isShow: true,
    },
    {
      chartId: 'totalWaterChart',
      title: 'Total Body Water (lbs)',
      dbName: 'body_comp_bodyWater',
      data: [],
      instance: null,
      isShow: true,
    },
    {
      chartId: 'icwChart',
      title: 'Intracellular Water (lbs)',
      dbName: 'body_comp_intracellularWater',
      data: [],
      instance: null,
      isShow: true,
    },
    {
      chartId: 'ecwChart',
      title: 'Extracellular Water (lbs)',
      dbName: 'body_comp_extracellularWater',
      data: [],
      instance: null,
      isShow: true,
    },
    {
      chartId: 'dlmChart',
      title: 'Dry Lean Mass (lbs)',
      dbName: 'body_comp_dryLeanMass',
      data: [],
      instance: null,
      isShow: true,
    },
    {
      chartId: 'bmiChart',
      title: 'BMI (kg/m²)',
      dbName: 'body_health_condition_bmi',
      data: [],
      instance: null,
      isShow: true,
    },
    {
      chartId: 'bmrChart',
      title: 'Basal Metabolic Rate (kcal)',
      dbName: 'body_health_condition_bmr',
      data: [],
      instance: null,
      isShow: true,
    },
];