import { ScholasticCommunicationFilterFormValue } from '../../../interfaces/scholastic-list/ScholasticCommunicationFilters';

export const filterDefaultValues: ScholasticCommunicationFilterFormValue = {
  customFilterId: null,
  generalFilter: {
    fullName: '',
    schoolId: '',
    validFrom: null,
    status: '',
    coachName: '',
    entityName: '',
  },
};
