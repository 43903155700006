import { useEffect, useState } from 'react';
import { EntityInbodyKeyFormValue, InbodyKeyInfo } from '../interfaces/inbodyKey';

import { inbodyKeysService } from '../services/inbodyKeysService';
import { deleteItemByField } from '../../shared/utils/arrayUtils';

export type UseEntityInbodyKeyReturn = {
  inbodyKeys: InbodyKeyInfo[];
  addInbodyKey: (inbodyKeyInfo: EntityInbodyKeyFormValue) => Promise<void>;
  deleteInbodyKey: (id: number) => Promise<void>;
};

export const useEntityInbodyKey = (entityId: number): UseEntityInbodyKeyReturn => {
  const [inbodyKeys, setInbodyKeys] = useState<InbodyKeyInfo[]>([]);

  const getEntityInbodyKeys = async () => {
    const { inbodyKeys: entityInbodyKeys } =
      await inbodyKeysService.getEntityInbodyKeys(entityId);

    setInbodyKeys(entityInbodyKeys);
  };

  useEffect(() => {
    getEntityInbodyKeys();
  }, []);

  const addInbodyKey = async (inbodyKeyInfo: EntityInbodyKeyFormValue) => {
    const newInbodyKey = await inbodyKeysService.addInbodyKey({
      ...inbodyKeyInfo,
      entityId,
    });

    setInbodyKeys((prevInbodyKey) => [...prevInbodyKey, newInbodyKey]);
  };

  const deleteInbodyKey = async (id: number) => {
    await inbodyKeysService.deleteInbodyKey(id);

    const newInbodyKey = deleteItemByField(inbodyKeys, { id });

    setInbodyKeys(newInbodyKey);
  };

  return { inbodyKeys, addInbodyKey, deleteInbodyKey };
};
