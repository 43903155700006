import React from 'react';
import PropTypes from 'prop-types';

import { Button, Modal, Paper } from '@mui/material';

import classes from './CongratulationsModal.module.scss';
import Stars from '../../../../../shared/assets/image/stars.png';

const CongratulationsModal = ({ open, onCloseHandler }) => {
  return (
    <Modal open={open} onBackdropClick={onCloseHandler}>
      <Paper variant="box" className={classes.modalBox}>
        <img src={Stars} alt="star" />
        <h4>Congratulations </h4>
        <p>Your Registration User of Active Calendar is Successful</p>
        <Button variant="contained" fullWidth onClick={onCloseHandler}>
          Finish
        </Button>
      </Paper>
    </Modal>
  );
};

CongratulationsModal.propTypes = {
  open: PropTypes.bool,
  onCloseHandler: PropTypes.func,
};

export { CongratulationsModal };
