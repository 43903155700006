import React from 'react';

import { Control } from 'react-hook-form';

import classes from './InitialPlanPricingForm.module.scss';
import { InputGroup } from '../../components/InputGroup/InputGroup';
import { LbNumericFormatInput } from '../../../../../shared/components/form-components';
import { PaymentDetailsCell } from '../../components/PaymentDetailsCell/PaymentDetailsCell';
import {
  CreateEntitySubscriptionFormValues,
  generalInputProps,
  generalPriceInputProps,
} from '../../CreateEntitySubscriptionModal.settings';
import { InitialPlanPricingPaymentDetail } from '../../../../interfaces/entitySubscription';
import { validateMinimalPrice } from '../../utils/validateMinimalPrice';
import { ViewPriceInput } from '../../components/ViewPriceInput/ViewPriceInput';

type InitialPlanPricingFormProps = {
  control: Control<CreateEntitySubscriptionFormValues>;
  paymentDetails: InitialPlanPricingPaymentDetail | null;
  isLoadingPaymentDetails?: boolean;
  feesEditMode?: boolean;
};

const InitialPlanPricingForm = ({
  control,
  paymentDetails,
  isLoadingPaymentDetails,
  feesEditMode,
}: InitialPlanPricingFormProps) => {
  return (
    <InputGroup
      title="Initial Plan Pricing"
      description="LifeBase recommends to create subscription with a duration of at least three months"
    >
      <div className={classes.inputBox}>
        <LbNumericFormatInput
          {...generalPriceInputProps}
          control={control}
          name="subscriptionPrice.initialPlanPrice"
          label="Initial Plan"
          rules={{
            required: 'Initial Plan Pricing is required',
            validate: (value) =>
              validateMinimalPrice(value, paymentDetails?.minInitialPlanPrice),
          }}
        />
        <LbNumericFormatInput
          {...generalInputProps}
          control={control}
          name="subscriptionPrice.initialPlanDuration"
          label="Duration, months"
          rules={{
            required: 'Duration is required',
            min: { value: 1, message: 'Duration should be at least 1 months' },
          }}
          allowNegative={false}
          decimalScale={0}
        />
        {feesEditMode ? (
          <LbNumericFormatInput
            {...generalPriceInputProps}
            control={control}
            name="subscriptionPrice.initialPlanUserFee"
            label="Initial Plan Fees, per month"
            rules={{ required: 'Initial Plan Fees is required' }}
          />
        ) : (
          <ViewPriceInput
            control={control}
            label="Initial Plan Fees, per month"
            name="subscriptionPrice.initialPlanUserFee"
          />
        )}
        {feesEditMode ? (
          <LbNumericFormatInput
            {...generalPriceInputProps}
            control={control}
            name="subscriptionPrice.initialPlanOneTimeFee"
            label="One-Time Fee"
            rules={{ required: 'One-Time Fee is required' }}
          />
        ) : (
          <ViewPriceInput
            control={control}
            label="One-Time Fee"
            name="subscriptionPrice.initialPlanOneTimeFee"
          />
        )}
      </div>
      <div className={classes.paymentDetailsBox}>
        {paymentDetails?.applicationTax && (
          <PaymentDetailsCell
            paymentDetail={paymentDetails?.applicationTax}
            isLoading={isLoadingPaymentDetails}
          />
        )}
        <PaymentDetailsCell
          paymentDetail={paymentDetails?.stripeFees}
          isLoading={isLoadingPaymentDetails}
          showDivider
        />
        <PaymentDetailsCell
          paymentDetail={paymentDetails?.totalFees}
          isLoading={isLoadingPaymentDetails}
        />
        <PaymentDetailsCell
          paymentDetail={paymentDetails?.entityDeposit}
          isLoading={isLoadingPaymentDetails}
        />
      </div>
    </InputGroup>
  );
};

export { InitialPlanPricingForm };
