import React from 'react';

import { Skeleton } from '@mui/material';
import { observer } from 'mobx-react';

import classes from './UploadRegistrationLogoModal.module.scss';
import { LbBigModal } from '../../../../../../../../shared/components/modals';
import { PreviewLogoCard } from './PreviewLogoCard/PreviewLogoCard';
import { UploadLogoCard } from './UploadLogoCard/UploadLogoCard';
import { useCompanyLogo } from './hooks/useCompanyLogo';

type UploadRegistrationLogoModalProps = {
  entityId: number;
  onClose: () => void;
};

const UploadRegistrationLogoModal = observer(
  ({ entityId, onClose }: UploadRegistrationLogoModalProps) => {
    const {
      logo,
      isLoading,
      isChanged,
      handleUploadLogo,
      handleRemoveLogo,
      handleSaveChanges,
    } = useCompanyLogo(entityId);

    const handleSubmit = () => {
      handleSaveChanges();
      onClose();
    };

    const isShowPreview = !!logo;

    return (
      <LbBigModal
        open
        title="Upload Business Logo"
        description="The logo of your business will be placed next to the LifeBase logo in the header of the user registration form. Please ensure that your logo looks good against a dark background."
        buttonOptions={{
          noText: 'Cancel',
          noHandler: onClose,
          yesText: 'Save',
          yesHandler: handleSubmit,
          yesDisabled: !isChanged,
        }}
      >
        {isLoading ? (
          <Skeleton variant="rounded" className={classes.skeleton} />
        ) : (
          <>
            {isShowPreview && (
              <PreviewLogoCard logoUrl={logo.fileUrl} onClear={handleRemoveLogo} />
            )}
            {!isShowPreview && <UploadLogoCard onUpload={handleUploadLogo} />}
          </>
        )}
      </LbBigModal>
    );
  },
);

export { UploadRegistrationLogoModal };
