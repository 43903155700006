import React, { ReactNode } from 'react';

import { Checkbox, CheckboxProps } from '@mui/material';

import classes from './CheckboxCell.module.scss';
import { GridTableCell } from '../GridTableCell/GridTableCell';

type CheckboxCellProps = {
  children: ReactNode;
  showCheckbox: boolean;
  isChecked?: boolean;
  onCheck: CheckboxProps['onChange'];
} & Pick<CheckboxProps, 'color' | 'indeterminate'>;

const CheckboxCell = ({
  children,
  showCheckbox,
  isChecked,
  indeterminate,
  onCheck,
  color = 'primary',
}: CheckboxCellProps) => {
  return (
    <GridTableCell className={classes.box} justify="start">
      {showCheckbox && (
        <Checkbox
          checked={isChecked}
          indeterminate={indeterminate}
          onChange={onCheck}
          color={color}
        />
      )}
      {children}
    </GridTableCell>
  );
};

export { CheckboxCell, CheckboxCellProps };
