'use strict';

angular.module('app').factory('PaidModulesUtil', PaidModulesUtil);

PaidModulesUtil.$inject = ['localStorageService','constants'];

function PaidModulesUtil(localStorageService, constants) {
    const { ACTIVE_CALENDAR, NUTRITION, SUPPLEMENT_MEMBER, SCHOLASTIC } =  constants.enums.paidServiceTypes;

    const PaidModulesUtil = {
        getEnabledModules() {
            const ENABLED_MODULES = constants.enums.storageKeys.ENABLED_MODULES;
    
            return localStorageService.get(ENABLED_MODULES) ?? {};
        },

        getIsModuleEnabled(moduleName) {
            const enabledModules = this.getEnabledModules();
                
            return enabledModules?.[moduleName];
        },
    
        getIsActiveCalendarEnabled () {
            return this.getIsModuleEnabled(ACTIVE_CALENDAR);
        }, 
    
        getIsNutritionEnabled() {
           return this.getIsModuleEnabled(NUTRITION);
        },

        getIsSupplementMemberEnabled() {
           return this.getIsModuleEnabled(SUPPLEMENT_MEMBER);
        },

        getIsScholasticEnabled() {
            return this.getIsModuleEnabled(SCHOLASTIC);
         },
    };

    return PaidModulesUtil; 
}
