import { patterns } from '../../../constants/patterns';
import { validationService } from '../../../services/validationService';

export const validateZipCodeFormat = (zipCode: string): true | string => {
  return patterns.ZIP_CODE.test(zipCode) || 'Invalid Zip Code format';
};

export const validateZipCode = async (
  zipCode: string,
  state: string,
): Promise<true | string> => {
  const isValidZipCode = validateZipCodeFormat(zipCode);

  if (isValidZipCode !== true) {
    return isValidZipCode;
  }

  try {
    await validationService.validateZipCode(zipCode, state, {
      isRunGlobalSpinner: false,
    });
    return true;
  } catch (error: any) {
    const message =
      error.response?.data?.message || 'Invalid Zip Code for the provided state';

    return message;
  }
};
