import { Components } from '@mui/material';

export const MuiTooltipTheme: Components['MuiTooltip'] = {
  defaultProps: { placement: 'top' },
  styleOverrides: {
    tooltip: {
      padding: '7px 9px',
      font: 'var(--body-12-bold)',
      borderRadius: '7px',
      background: 'var(--natural-500)',
    },
    tooltipPlacementTop: {
      marginBottom: '5px',
    },
  },
};
