import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import classes from './TestRow.module.scss';
import { RowTemplate } from '../RowTemplate/RowTemplate';
import { useModalToggle } from '../../../../../shared/hooks/useModalToggle';
import { LbModal } from '../../../../../shared/components/modals/LbModal/LbModal';
import { LbInput } from '../../../../../shared/components/form-components';
import { openConfirmationModal } from '../../../../../shared/features/confirmation';

// TODO add number of test used coaches
const TestRow = ({ nameOfTest, date, editTest, deactivateTest }) => {
  const [isEditModalOpen, editModalHandler] = useModalToggle();

  const { control, handleSubmit } = useForm({ mode: 'onBlur' });

  const onSubmit = ({ testName }) => {
    editTest(testName);
    editModalHandler();
  };

  const handleDeactivate = () => {
    openConfirmationModal({
      title: 'Do you want to deactivate this Lab Test?',
      text: 'This Lab Test is used by 5 coaches. After deactivating coaches will not be able to update it further. You can find deactivated tests at the bottom of the list and restore it if necessary',
      confirmBtnConfig: {
        text: 'Deactivate',
        onClick: deactivateTest,
      },
    });
  };

  const btnCell = (
    <div className={classes.btnCell}>
      <Button variant="outlined" size="small" onClick={editModalHandler}>
        Edit
      </Button>
      <Button variant="text" color="secondary" size="small" onClick={handleDeactivate}>
        Deactivate
      </Button>
    </div>
  );

  return (
    <>
      <RowTemplate
        cellOne={nameOfTest}
        cellTwo={moment(date).format('DD MMM YYYY')}
        cellThree={btnCell}
        dataTestId="test-row"
      />
      <LbModal
        open={isEditModalOpen}
        title="Edit Lab Test"
        noHandler={editModalHandler}
        onClose={editModalHandler}
        primaryBtnConfig={{ text: 'Save', onClick: handleSubmit(onSubmit) }}
        secondaryBtnConfig={{ onClick: editModalHandler }}
      >
        <LbInput
          name="testName"
          control={control}
          label="Name of Lab Test"
          defaultValue={nameOfTest}
          rules={{ required: "Name of Lab Test can't be empty" }}
          shouldUnregister
        />
      </LbModal>
    </>
  );
};

TestRow.propTypes = {
  nameOfTest: PropTypes.string,
  date: PropTypes.any,
  editTest: PropTypes.func,
  deactivateTest: PropTypes.func,
};

export { TestRow };
