import React from 'react';

import classes from './InsuranceCertificatesDropzoneView.module.scss';
import { ReactComponent as ChooseFilesImage } from '../../../../../../../shared/assets/image/files-folder.svg';
import { DashedBorder } from '../../../../../../../shared/components/ui-components/DashedBorder/DashedBorder';

const InsuranceCertificatesDropzoneView = () => {
  return (
    <div className={classes.dropzone}>
      <div className={classes.background} />
      <DashedBorder color="natural-700" />
      <div className={classes.imgBox}>
        <ChooseFilesImage className={classes.chooseFileImage} />
        <p className={classes.chooseFileText}>
          Drag the certificate to the designated area to upload it to your profile
        </p>
      </div>
    </div>
  );
};

export { InsuranceCertificatesDropzoneView };
