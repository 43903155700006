import { apiService } from '../../shared/services/apiService';
import { getFormattedDate } from '../../shared/utils/dateUtils';
import { getFilteredTruthyFieldsObj } from '../../shared/utils/sortingAndGroupingUtil';
import {
  GetScanDateByInbodyIdResponse,
  ManualScanData,
} from '../interfaces/customerInbodyData';

type GetScanDaysResponse = {
  scanDates: string[];
};

export const scanServices = {
  async getScanDays(customerId: number) {
    const resp = await apiService.get<GetScanDaysResponse>(
      `api/coach/user/${customerId}/stats/user-scans`,
    );

    const transformedScanDates = resp.data.scanDates.map((scanDay) =>
      getFormattedDate(scanDay, undefined, 'MM.DD.YYYY'),
    );

    return { ...resp.data, scanDates: transformedScanDates };
  },

  async getScanDateByInbodyId(inbodyId: number | string, ignoreErrorCodes?: number[]) {
    const resp = await apiService.get<GetScanDateByInbodyIdResponse>(
      `api/coach/inbody/${inbodyId}`,
      { ignoreErrorCodes },
    );

    return resp.data;
  },

  async createScanManually(customerId: number, scanDate: ManualScanData) {
    const filteredScanDate = getFilteredTruthyFieldsObj(scanDate);

    const resp = await apiService.post(
      `api/coach/inbody/manual/${customerId}`,
      filteredScanDate,
    );

    return resp.data;
  },

  // connect scan from inbody to customer by inbody id, get new scan from inbody
  async updateScansFromInbody(customerId: number) {
    const resp = await apiService.put<{ status: 'SUCCESS' | 'FAILED' }>(
      `/api/coach/inbody/update/${customerId}`,
    );

    return resp.data;
  },
};
