import { apiService } from '../../shared/services/apiService';
import { VideoCategory } from '../interface/video';

type GetVideosResponse = {
  categories: VideoCategory[];
};

export const videosServices = {
  async getAllVideos() {
    const resp = await apiService.get<GetVideosResponse>(`/api/devices/videos`);

    return resp.data;
  },
};
