'use strict';

angular.module('app').factory('PreCheckInService', PreCheckInService);

PreCheckInService.$inject = ['ApiService', 'ModalService'];

function PreCheckInService(ApiService, ModalService) {
    return {
        getUsers: function (params) {
            return ApiService.get('api/coach/pre-check-in', params).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        addUsers: function (data) {
            return ApiService.post('api/coach/pre-check-in', data).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        deleteUsers: function (data) {
            return ApiService.delete('api/coach/pre-check-in', data).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        resendRequest: function (id) {
            return ApiService.post('api/coach/pre-check-in/' + id + '/resend').then(function (res) {
                ModalService.alert(null, 'Request has been sent');
                return res;
            }, function (err) {
                return err;
            });
        },
        approveUser: function (token) {
            return ApiService.post('api/coach/pre-check-in/' + token).then(function (res) {
                ModalService.alert('User has been approved');
                return res;
            }, function (err) {
                return err;
            });
        },
        deleteAllUsers: () => ApiService.delete('api/coach/pre-check-in/all-by-logged-coach')
    }
}
