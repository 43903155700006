import { Components } from '@mui/material';

export const MuiPaperTheme: Components['MuiPaper'] = {
  variants: [
    {
      props: { variant: 'box' },
      style: {
        boxShadow: 'var(--panel-shadow)',
        borderRadius: '7px',
        padding: '28px 32px 12px',
      },
    },
    {
      props: { variant: 'roundedBox' },
      style: {
        boxShadow: 'var(--panel-shadow)',
        borderRadius: '8px',
        overflow: 'hidden',
      },
    },
    {
      props: { variant: 'boxWithPadding' },
      style: {
        boxShadow: 'var(--panel-shadow)',
        borderRadius: '8px',
        padding: '16px 20px',
      },
    },
  ],
};

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    roundedBox: true;
    box: true;
    boxWithPadding: true;
  }
}
