import { ValueOfKey } from '../../utils-types';

export const uploadFileTypes = {
  MEDICAL_TEST: 'MEDICAL_TEST',
  CHAT_ATTACHMENT: 'CHAT_ATTACHMENT',
  AVATAR: 'AVATAR',
  ENTITY_LOGO: 'ENTITY_LOGO',
} as const;

export type UploadFileTypes = ValueOfKey<typeof uploadFileTypes>;
