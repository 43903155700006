import { Option } from '../../../../general-types';
import { KeysOfType } from '../../../../utils-types';

type TransformToOptionsType<TList extends Record<string, any>> = {
  list: TList[];
  valueKey: KeysOfType<TList, string | number>;
  labelKey: KeysOfType<TList, string>;
};

export const transformToOptions = <TList extends Record<string, any>>({
  list,
  valueKey,
  labelKey,
}: TransformToOptionsType<TList>): Option[] =>
  list.map((item) => ({
    value: item[valueKey],
    label: item[labelKey],
  }));
