'use strict';

import { getLoginRequiredResolveObj } from '../shared/utils/angularUtils';

export const nutritionModule = angular
  .module('app.nutrition', [])
  .config(nutritionConfig);

nutritionConfig.$inject = ['$routeProvider'];

function nutritionConfig($routeProvider) {
  $routeProvider
    // .when('/some-url', {
    //   template: '<some-component></some-component>',
    //   resolve: getLoginRequiredResolveObj(),
    // })
}
