import React from 'react';

import { observer } from 'mobx-react';

import classes from './ButtonsRow.module.scss';
import { stepperStore } from '../../../../stores/stepperStore';
import { YesNoBtnRow } from '../../../../../shared/components/buttons';

const ButtonsRow = observer(
  ({ yesDisabled, yesText = 'Continue', children, yesHandler, isShowDecline = true }) => {
    const { openDeclineModal, nextStep } = stepperStore;
    const yesBtnHandler = yesHandler || nextStep;

    return (
      <YesNoBtnRow
        yesDisabled={yesDisabled}
        yesText={yesText}
        noText="Decline"
        noHandler={openDeclineModal}
        yesHandler={yesBtnHandler}
        isShowNo={isShowDecline}
        className={classes.btnBox}
      >
        {children}
      </YesNoBtnRow>
    );
  },
);

ButtonsRow.propTypes = {};

export { ButtonsRow };
