import React from 'react';

import { Paper } from '@mui/material';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';

import classes from './VideoList.module.scss';
import { Video } from '../../../../../interface/video';

type VideoListProps = {
  videos: Video[];
  onVideoClick: (videoSrc: string) => void;
};

const VideoList = ({ videos, onVideoClick }: VideoListProps) => {
  return (
    <Paper variant="roundedBox" className={classes.videoBox}>
      {videos.map((video) => (
        <div className={classes.video} key={video.id}>
          <div
            className={classes.imgBox}
            onClick={() => onVideoClick(video.youtubevideo)}
          >
            <img src={video.image} alt="video" />
            <div className={classes.hoverBg}>
              <div className={classes.playIconBox}>
                <PlayArrowRoundedIcon color="white" fontSize="extraLarge" />
              </div>
            </div>
          </div>
          <p>{video.title}</p>
        </div>
      ))}
    </Paper>
  );
};

export { VideoList };
