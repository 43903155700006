import { Control, useWatch } from 'react-hook-form';

import { customerSetupStore } from '../../../../../stores/customerSetupStore';
import { CreateGoalInfo } from '../../../../../interfaces/goal';
import { getMetrics } from '../utils/getMetrics';

export const useGoalMetrics = (control: Control<CreateGoalInfo>) => {
  const { customerBodyMetrics } = customerSetupStore;
  const [targetWeight, targetBodyFatPercent] = useWatch({
    control,
    name: ['targetWeight', 'targetBodyFatPercent'],
  });

  if (!customerBodyMetrics) {
    throw new Error('Customer body metrics are not available');
  }

  const metrics = getMetrics(customerBodyMetrics, {
    weight: targetWeight,
    percentBodyFat: targetBodyFatPercent,
  });

  return { metrics };
};
