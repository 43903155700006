import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';

import classes from './TabHeader.module.scss';
import { LbDatePicker } from '../../../../../../shared/components/form-components';
import { PageTitle } from '../../../../../../shared/components/ui-components';
import { useTabHeaderSubmit } from './hooks/useTabHeaderSubmit';

const TabHeader = ({ getAdaptedMealReports }) => {
  const { control } = useTabHeaderSubmit(getAdaptedMealReports);

  return (
    <PageTitle title="Meal Reports">
      <div className={classes.controlReports}>
        <LbDatePicker
          className={classes.datePicker}
          disableFuture
          defaultToday
          variant="outlined"
          size="small"
          name="mealReportsDate"
          control={control}
        />
        <Button
          variant="contained"
          size="small"
          className={classes.printBtn}
          onClick={() => window.print()}
        >
          Print all
        </Button>
      </div>
    </PageTitle>
  );
};

TabHeader.propTypes = {
  getAdaptedMealReports: PropTypes.func.isRequired,
};

export { TabHeader };
