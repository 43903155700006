const autoNgTemplateLoaderTemplate1 = require('./users-fees.template.html');

UsersFeesController.$inject = [];

function UsersFeesController() {}

angular.module('app').component('usersFees', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: UsersFeesController,
  controllerAs: 'vm',
  bindings: {
    usersFees: '<',
    isEditDisabled: '<'
  }
});
