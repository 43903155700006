(function () {
    'use strict';

    angular.module('app.salesIncomeTransactions')
        .controller('SalesIncomeTransactionsController', SalesIncomeTransactionsController);

    SalesIncomeTransactionsController.$inject = [
        '$routeParams',
        'utilService',
        'SalesService'
    ];

    function SalesIncomeTransactionsController($routeParams, utilService, SalesService) {
        var vm = this;

        vm.loggedInUser = utilService.getLoggedInUserData();
        vm.prevPage = null;
        vm.nextPage = null;

        vm.selectedEntityTab = {
            id: vm.loggedInUser.id,
            business_name: vm.loggedInUser.gymName
        };

        if (vm.loggedInUser.role === 'SUPERUSER') {
            vm.selectedEntityTab = JSON.parse(localStorage.getItem('gym'));
        }

        vm.goTo = utilService.goTo;
        vm.getTransactions = getTransactions;

        vm.getTransactions(null, null);

        function getTransactions(prev, next) {
            SalesService.getTransactions({
                prev_page: prev,
                next_page: next,
                from: $routeParams.from,
                to: $routeParams.to,
                limit: 20,
                entity_id: vm.loggedInUser.role === 'SUPERUSER' ? vm.selectedEntityTab.id : undefined,
            }).then(function (res) {
                if (res.status === 200) {
                    vm.incomeTransactions = res.data.deposits;
                    vm.prevPage = res.data.prev_page;
                    vm.nextPage = vm.incomeTransactions.length < 20 ? null : res.data.next_page;
                }
            });
        }
    }
})();
