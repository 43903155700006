import React, { MouseEventHandler } from 'react';

import { Button, ButtonProps } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import classes from './DownloadReportButtons.module.scss';

type DownloadReportButtonsProps = {
  onPdfDownload: MouseEventHandler<HTMLButtonElement>;
  onCsvDownload: MouseEventHandler<HTMLButtonElement>;
} & Pick<ButtonProps, 'size'>;

const DownloadReportButtons = ({
  onPdfDownload,
  onCsvDownload,
  size = 'small',
}: DownloadReportButtonsProps) => {
  return (
    <div className={classes.btnBox}>
      <Button
        variant="contained"
        size={size}
        startIcon={<FileDownloadOutlinedIcon />}
        onClick={onPdfDownload}
      >
        PDF
      </Button>
      <Button
        variant="outlined"
        size={size}
        startIcon={<FileDownloadOutlinedIcon />}
        onClick={onCsvDownload}
      >
        CSV
      </Button>
    </div>
  );
};

export { DownloadReportButtons };
