import React, { ReactNode } from 'react';

import classNames from 'classnames';

import classes from './ScrollBox.module.scss';
import { useScroll } from './hooks/useScroll';

type ScrollBoxProps = {
  children: ReactNode;
  maxHeight: number;
  className?: string;
  variant?: 'outBox' | 'inBox';
};

const ScrollBox = ({
  children,
  maxHeight,
  className,
  variant = 'inBox',
}: ScrollBoxProps) => {
  const { isScrollShown, boxContentRef } = useScroll(maxHeight);

  const boxWrapperClassNames = classNames(className, classes[variant], {
    [classes.withScrollIndent]: isScrollShown,
  });

  return (
    <div className={boxWrapperClassNames}>
      <div className={classes.scrollBox} style={{ maxHeight: `${maxHeight}px` }}>
        <div ref={boxContentRef} className={classes.contentWrapper}>
          {children}
        </div>
      </div>
    </div>
  );
};

export { ScrollBox };
