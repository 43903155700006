import React from 'react';

import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { observer } from 'mobx-react';

import { UserMenuItem } from '../UserMenuItem/UserMenuItem';
import { goTo } from '../../../../../utils/angularUtils';
import { loggedInUserDataStore } from '../../../../../stores/loggedInUserDataStore';

const ProfileItem = observer(() => {
  const { isEntity, isCoach } = loggedInUserDataStore;

  const isShowProfile = isEntity || isCoach;

  if (!isShowProfile) {
    return null;
  }

  const navigateToProfile = () => {
    if (isCoach) {
      goTo('/coach-profile');
    } else if (isEntity) {
      goTo('/entity-profile');
    }
  };

  return (
    <UserMenuItem
      onClick={navigateToProfile}
      label="Profile"
      startIcon={<AccountCircleRoundedIcon />}
    />
  );
});

export { ProfileItem };
