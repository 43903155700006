import { Components } from '@mui/material';

export const MuiButtonBaseTheme: Components['MuiButtonBase'] = {
  styleOverrides: {
    root: {
      minWidth: 'auto',
      height: 'auto',
      width: 'auto',
    },
  },
};
// '&[href]' used to rewrite style for button with "a" tag ":not(.MuiButton-link)" to omit add this style for variant link
export const MuiButtonTheme: Components['MuiButton'] = {
  defaultProps: {
    size: 'large',
  },
  styleOverrides: {
    root: {
      textTransform: 'none',
      minWidth: 'auto',

      '&[href]:not(.MuiButton-link)': {
        textDecoration: 'none',
      },
    },
    contained: {
      backgroundColor: 'var(--primary-500)',
      boxShadow: 'none',
      // fix ancor tag style from link.scss
      '&[href]': {
        color: 'var(--white) !important',
      },
      '&:hover': {
        backgroundColor: 'var(--primary-500)',
        opacity: '0.5',
        boxShadow: 'none',
      },
      '&:disabled': {
        backgroundColor: 'var(--primary-500)',
        color: 'var(--white)',
        opacity: '0.5',
      },
    },
    outlined: {
      color: 'var(--main-black)',
      border: '1px solid ',
      borderColor: 'var(--natural-400)',

      '&:hover': {
        background: 'transparent',
        borderColor: 'var(--primary-400)',
        opacity: '1',
      },

      '&:disabled': {
        background: 'transparent',
        color: 'var(--main-black)',
        borderColor: 'var(--natural-400)',
        opacity: '0.5',
      },
    },
    outlinedError: {
      color: 'var(--error-300)',
      borderColor: 'var(--error-200)',

      '&:hover': {
        opacity: 0.5,
        backgroundColor: 'transparent',
        borderColor: 'var(--error-200)',
      },
    },
    text: {
      backgroundColor: 'transparent',
      // increase specificity for size styles rewrite
      '&.MuiButton-root': {
        padding: 0,
        height: 'auto',
      },

      '&:hover': {
        backgroundColor: 'transparent',
      },

      '&:disabled': {
        backgroundColor: 'transparent',
      },

      '.MuiTouchRipple-root': {
        display: 'none',
      },
    },
    textPrimary: {
      color: 'var(--primary-500)',

      '&[href]': {
        color: 'var(--primary-500) !important',
      },

      '&:hover': {
        color: 'var(--primary-700)',

        '&[href]': {
          color: 'var(--primary-700) !important',
          opacity: '1',
        },
      },
      '&:disabled': {
        color: 'var(--main-black)',
        opacity: '0.5',
      },
    },
    textSecondary: {
      color: 'var(--main-black)',

      '&[href]': {
        color: 'var(--main-black) !important',
      },

      '&:hover': {
        color: 'var(--primary-700)',

        '&[href]': {
          color: 'var(--primary-700) !important',
          opacity: '1',
        },
      },

      '&:disabled': {
        color: 'var(--main-black)',
        opacity: '0.5',
      },
    },
    sizeLarge: {
      font: 'var(--title-16-bold)',
      height: '45px',
      padding: '0 20px',
    },
    sizeMedium: {
      font: 'var(--body-14-bold)',
      height: '40px',
      padding: '0 20px',
    },
    sizeSmall: {
      font: 'var(--body-14-bold)',
      height: '34px',
      padding: '0 16px',
    },
    startIcon: {
      marginLeft: 0,
      '&.MuiButton-iconSizeLarge': {
        marginRight: '8px',
      },
      '&.MuiButton-iconSizeSmall, &.MuiButton-iconSizeMedium': {
        marginRight: '4px',
      },
    },
    endIcon: {
      marginRight: 0,
      '&.MuiButton-iconSizeLarge': {
        marginLeft: '8px',
      },
      '&.MuiButton-iconSizeSmall, &.MuiButton-iconSizeMedium': {
        marginLeft: '4px',
      },
    },
    iconSizeLarge: {
      '&>*:nth-of-type(1)': {
        fontSize: '20px',
      },
    },
    iconSizeMedium: {
      '&>*:nth-of-type(1)': {
        fontSize: '16px',
      },
    },
    iconSizeSmall: {
      '&>*:nth-of-type(1)': {
        fontSize: '16px',
      },
    },
  },
  variants: [
    {
      props: { variant: 'link' },
      style: {
        textDecoration: 'underline',
        textUnderlineOffset: '4px',
        textDecorationColor: 'var(--natural-400)',
        font: 'inherit',
        padding: 0,
        height: 'auto',

        '&:hover': {
          backgroundColor: 'transparent',
          opacity: '0.5',
        },

        '&:disabled, &.Mui-disabled': {
          color: 'inherit',
          textDecoration: 'none',
          backgroundColor: 'transparent',
        },
      },
    },
    {
      props: { className: 'onlyIcon' },
      style: {
        padding: 0,
        aspectRatio: '1 / 1',
      },
    },
    {
      props: { className: 'wightBg', variant: 'outlined' },
      style: {
        backgroundColor: 'var(--white)',
        '&:hover': {
          backgroundColor: 'var(--white)',
        },
      },
    },
    {
      props: { variant: 'unstyled' },
      style: {
        padding: 0,
        font: 'inherit',
        height: 'auto',

        '.MuiTouchRipple-root': {
          display: 'none',
        },

        '&:hover': {
          backgroundColor: 'transparent',
        },

        '&:disabled': {
          backgroundColor: 'transparent',
          color: 'inherit',
        },
      },
    },
  ],
};

export const MuiIconButtonTheme: Components['MuiIconButton'] = {
  defaultProps: {
    disableRipple: true,
    color: 'tertiary',
  },
  styleOverrides: {
    root: {
      padding: '0',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  variants: [
    {
      props: { color: 'secondary' },
      style: {
        color: 'var(--main-black)',
        '&:hover, &:focus': {
          color: 'var(--primary-700)',
        },
        '&:disabled': {
          color: 'var(--main-black)',
          opacity: '0.5',
        },
      },
    },
    {
      props: { color: 'error' },
      style: {
        color: 'var(--error-300)',
        '&:hover, &:focus': {
          color: 'var(--main-black)',
        },
        '&:disabled': {
          color: 'var(--main-black)',
          opacity: '0.5',
        },
      },
    },
    {
      props: { color: 'tertiary' },
      style: {
        color: 'var(--natural-400)',
        '&:hover, &:focus': {
          color: 'var(--primary-500)',
        },
      },
    },
  ],
};

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    link: true;
    unstyled: true;
  }
}
