import { Button } from '@mui/material';
import { observer } from 'mobx-react';
import React, { MouseEventHandler } from 'react';

import classes from './ButtonRow.module.scss';
import { goTo } from '../../../../../shared/utils/angularUtils';
import { YesNoBtnRow } from '../../../../../shared/components/buttons';
import { setupScholasticCustomerStore } from '../../../../stores/setupScholasticCustomerStore';

type ButtonsRowProps = {
  showCancel?: boolean;
  showBack?: boolean;
  onNext?: MouseEventHandler<HTMLButtonElement>;
};

const ButtonsRow = observer(({ showBack, showCancel, onNext }: ButtonsRowProps) => {
  const { stepperMethods } = setupScholasticCustomerStore;
  const { goToPreviousStep } = stepperMethods;

  return (
    <YesNoBtnRow yesText="Next" className={classes.buttonRow} yesHandler={onNext}>
      {showCancel && (
        <Button variant="outlined" onClick={() => goTo('/')}>
          Cancel
        </Button>
      )}
      {showBack && (
        <Button variant="outlined" onClick={goToPreviousStep}>
          Back
        </Button>
      )}
    </YesNoBtnRow>
  );
});

export { ButtonsRow };
