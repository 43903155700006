'use strict';

import { getLoginRequiredResolveObj } from "../shared/utils/angularUtils";

export const guidesAndAdditionalInfoModule = angular
  .module('app.guidesAndAdditionalInfo', [])
  .config(guidesAndAdditionalInfoConfig);

guidesAndAdditionalInfoConfig.$inject = ['$routeProvider'];

function guidesAndAdditionalInfoConfig($routeProvider) {
  $routeProvider
    .when('/resources', {
      template: `<ngz-resources></ngz-resources>`,
      resolve: getLoginRequiredResolveObj(),
    });
}
