const autoNgTemplateLoaderTemplate1 = require('./sign-in-auth0.template.html');

'use strict';

(function () {
    angular
        .module('app.signInAuth0', [])
        .config(signInAuth0Config);

    signInAuth0Config.$inject = ['$routeProvider'];
    function signInAuth0Config($routeProvider) {
        $routeProvider
            .when('/sign-in-auth0', {
                disableCache: true,
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'SignInAuth0Controller',
                controllerAs: 'vm',
                resolve: {
                    skipIfLoggedIn: ['AuthService', function (AuthService) {
                        return AuthService.skipIfLoggedIn();
                    }]
                }
            })
    }
})();
