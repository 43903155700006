import React from 'react';

import classes from './BodyMetricsTable.module.scss';
import { InfoBox } from '../../../../../../shared/components/ui-components';
import { HeaderRow } from './HeaderRow/HeaderRow';
import { BodyMetricRow } from './BodyMetricRow/BodyMetricRow';

type BodyMetrics = {
  current: number | null;
  goal: number | null;
  change: number | null;
};

type BodyMetricsTableProps = {
  weight: BodyMetrics;
  percentBodyFat: BodyMetrics;
  bodyFatMass: BodyMetrics;
  lbm: BodyMetrics;
};

const BodyMetricsTable = ({
  weight,
  percentBodyFat,
  lbm,
  bodyFatMass,
}: BodyMetricsTableProps) => {
  return (
    <InfoBox title="Body Composition Metrics" customContentPadding>
      <div className={classes.bodyMetricsTable}>
        <HeaderRow />
        <BodyMetricRow title="Weight, lbs" {...weight} />
        <BodyMetricRow title="Body fat, %" measureUnit="%" {...percentBodyFat} />
        <BodyMetricRow title="Lean Body Mass, lbs" {...lbm} />
        <BodyMetricRow title="Body Fat, lbs" {...bodyFatMass} />
      </div>
    </InfoBox>
  );
};

export { BodyMetricsTable };
