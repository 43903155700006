import React from 'react';

import { angularize } from 'react-in-angularjs';

import { SystemProviders, LbSnackbarProvider } from '../../providers';

/**
 * Use to create one instance of the snackbar notification provider, to show toast notification in the app
 * Not use useSneakbar hook, use enqueueSnackbar and closeSnackbar from notistack
 */
const NgzSnackbarNotification = () => {
  return (
    <SystemProviders>
      <LbSnackbarProvider />
    </SystemProviders>
  );
};

angularize(NgzSnackbarNotification, 'ngzSnackbarNotification', angular.module('app'), {});

export { NgzSnackbarNotification };
