import React from 'react';

import classes from './GoalValidationModal.module.scss';
import { LbModal } from '../../../../../../shared/components/modals';
import { ReactComponent as SuccessStateIcon } from '../../../../../../shared/assets/image/successState.svg';
import { ReactComponent as ErrorStateIcon } from '../../../../../../shared/assets/image/errorState.svg';

type GoalValidationModalProps = {
  open: boolean;
  onClose: () => void;
  onProceed: () => Promise<void>;
  onErrorAdjust: () => void;
  isValid: boolean;
  explanation: string;
};

const GoalValidationModal = ({
  open,
  onClose,
  onProceed,
  onErrorAdjust,
  isValid,
  explanation,
}: GoalValidationModalProps) => {
  const handleProceed = async () => {
    await onProceed();
    onClose();
  };

  const handleAdjust = () => {
    if (isValid) {
      onClose();
    } else {
      onErrorAdjust();
      onClose();
    }
  };

  return (
    <LbModal
      open={open}
      title={isValid ? 'Great! ' : 'Error!'}
      text={explanation}
      primaryBtnConfig={isValid ? { onClick: handleProceed, text: 'Proceed' } : undefined}
      secondaryBtnConfig={{ onClick: handleAdjust, text: 'Adjust Goal Targets' }}
    >
      <div className={classes.imgBox}>
        {isValid ? <SuccessStateIcon /> : <ErrorStateIcon />}
      </div>
    </LbModal>
  );
};

export { GoalValidationModal, GoalValidationModalProps };
