import { isEmptyArray, isEmptyValue, isObject } from '../../jsUtils';

export const getFilteredTruthyFieldsObj = <TObj extends Record<any, any>>(
  obj: TObj,
): Partial<TObj> | undefined => {
  const filteredEntries = Object.entries(obj)
    .filter(([, value]) => !isEmptyValue(value))
    .map(([field, value]) => {
      const objectValue = isObject(value) ? getFilteredTruthyFieldsObj(value) : value;

      return [field, objectValue];
    });

  if (isEmptyArray(filteredEntries)) {
    return undefined;
  } else {
    return Object.fromEntries(filteredEntries);
  }
};
