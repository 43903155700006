import React from 'react';

import classes from './CoachNameBlock.module.scss';
import { AvatarIcon } from '../../../../../shared/components/ui-components';
import { TitleWithDescription } from '../../../../../shared/components/text-formation-components';

type CoachNameBlockProps = {
  avatarUrl: string | null;
  name: string;
  distance?: number;
};

const CoachNameBlock = ({ avatarUrl, name, distance }: CoachNameBlockProps) => {
  return (
    <div className={classes.container}>
      <AvatarIcon userName={name} avatarImgLink={avatarUrl} />
      <TitleWithDescription
        title={name}
        description={distance ? `${distance} miles` : null}
      />
    </div>
  );
};

export { CoachNameBlock };
