import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from '@mui/material';

import classes from './ContactCell.module.scss';
import { ReactComponent as ChatIcon } from '../../../../../../../assets/images/tabs/communication.svg';
import { ReactComponent as editOptionIcon } from '../../../../../../../assets/images/material-icons/edit-option.svg';

const ContactCell = ({ disabled, onClick }) => {
  return (
    <button
      type="button"
      className={classes.contactCell}
      disabled={disabled}
      onClick={onClick}
    >
      <SvgIcon component={ChatIcon} inheritViewBox fontSize="extraLarge" />
      <p>Contact</p>
      <span />
      <SvgIcon component={editOptionIcon} inheritViewBox fontSize="small" />
    </button>
  );
};

ContactCell.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export { ContactCell };
