import { useEffect } from 'react';

import { LOCAL_STORAGE_KEYS } from '../../../constants/system/LOCAL_STORAGE_KEYS';
import { reloadPage } from '../../../utils/windowUtils';

export const useTabSynchronization = () => {
  const checkLoggedUser = (event) => {
    const isValueChanged = event.newValue !== event.oldValue;
    const isUserLoggedIn =
      event.key === LOCAL_STORAGE_KEYS.LOGGED_IN_USER && isValueChanged;
    const isClearedStorage = !event.key;

    if (isUserLoggedIn || isClearedStorage) {
      reloadPage();
    }
  };

  useEffect(() => {
    window.addEventListener('storage', checkLoggedUser);
    return () => {
      window.removeEventListener('storage', checkLoggedUser);
    };
  });
};
