import { ChangeEvent } from 'react';

import { isEqual } from 'lodash';
import { makeAutoObservable } from 'mobx';

import { paginationListLimit } from '../../constants/systemConstants';
import {
  SpringFiltration,
  SpringFiltrationSort,
} from '../../interfaces/SpringFiltration';
import { SpringPagination } from '../../interfaces/SpringPagination';
import { SortDirection } from '../../../general-types';
import { getFilteredTruthyFieldsObj } from '../../utils/sortingAndGroupingUtil';

// only needed fields to create pagination from SpringPagination
export type HandleGetDataReturn = Pick<
  SpringPagination<unknown[]>,
  'totalPages' | 'number' | 'content' | 'totalElements'
>;

type HandleGetData<TFilter, TSortField extends string> = (
  filterInfo: SpringFiltration<TFilter, TSortField>,
) => Promise<HandleGetDataReturn>;

type FilterStoreConfig<TFilter> = {
  elementsPerPage?: number;
  defaultFilterData?: TFilter;
};

export class FilterStore<
  TFilter extends Record<string, any>,
  TSortField extends string = string,
> {
  pagesCount: number = 0;
  currentPage: number = 0;
  elementsPerPage: number;
  filterData: TFilter;
  defaultFilterData: TFilter;
  sortData?: SpringFiltrationSort<TSortField> = undefined;
  isShowFilterRow: boolean = true;
  isShowPagination: boolean = false;
  isShowPlaceholder: boolean = true;
  isSearchUsed: boolean | null = null;
  totalElements: number = 0;
  handleGetData: HandleGetData<TFilter, TSortField>;

  constructor(
    handleGetData: HandleGetData<TFilter, TSortField>,
    {
      elementsPerPage = paginationListLimit,
      defaultFilterData = {} as TFilter,
    }: FilterStoreConfig<TFilter> = {},
  ) {
    this.handleGetData = handleGetData;
    this.elementsPerPage = elementsPerPage;
    this.defaultFilterData = { ...defaultFilterData };
    this.filterData = { ...defaultFilterData };
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.pagesCount = 0;
    this.currentPage = 0;
    this.filterData = this.defaultFilterData;
    this.sortData = undefined;
    this.isShowFilterRow = true;
    this.isShowPagination = false;
    this.isShowPlaceholder = true;
    this.isSearchUsed = null;
    this.totalElements = 0;
  }

  async getData() {
    const filterInfo = {
      filters: this.filterData,
      requestedPage: { page: this.currentPage, size: this.elementsPerPage },
      sort: this.sortData,
    };

    const data = await this.handleGetData(filterInfo);

    const { totalPages, number, content, totalElements } = data;

    this.processIsSearchUsed();
    this.setPagesCount(totalPages);
    this.setCurrentPage(number);
    this.setIsShowPagination(totalPages > 1);
    this.setIsShowPlaceholder(!content.length);
    this.setTotalElements(totalElements);
  }

  private processIsSearchUsed() {
    const filteredFilterData = getFilteredTruthyFieldsObj(this.filterData);
    const filteredDefaultFilterData = getFilteredTruthyFieldsObj(this.defaultFilterData);

    const isSearchUsed = !isEqual(filteredFilterData, filteredDefaultFilterData);

    this.setIsSearchUsed(isSearchUsed);
  }

  applyFilters(filterData: TFilter) {
    this.setCurrentPage(0);
    this.setFilterData(filterData);

    this.getData();
  }

  handleSort(fieldName: TSortField, direction: SortDirection) {
    this.setSortData({ fieldName, direction });

    this.getData();
  }

  getIsSortedBy(fieldName: TSortField, direction: SortDirection) {
    const isSortedByFieldName = fieldName === this.sortData?.fieldName;
    const isSortedByDirection = direction === this.sortData?.direction;

    return isSortedByFieldName && isSortedByDirection;
  }

  pageChangeHandler(event: ChangeEvent<unknown>, page: number) {
    this.setCurrentPage(page - 1);
    this.getData();
  }

  toggleShowFilterRow() {
    this.isShowFilterRow = !this.isShowFilterRow;
  }
  // TODO update this method to use the new pagination component "LbPagination" instead of the old one "Pagination" after use the new component in the all project
  getPaginationProps() {
    return {
      count: this.pagesCount,
      onChange: this.pageChangeHandler,
      page: this.currentPage + 1,
      // totalElements: this.totalElements,
      // elementsPerPage: this.elementsPerPage,
    };
  }

  /**
   * update default filter data to new one
   * use with reset from form library
   */
  resetFilterData(data: TFilter) {
    // add options when needed more control
    this.defaultFilterData = { ...data };
  }

  private setSortData(sortData: SpringFiltrationSort<TSortField>) {
    this.sortData = sortData;
  }

  private setFilterData(filterData: TFilter) {
    this.filterData = filterData;
  }

  private setPagesCount(pagesCount: number) {
    this.pagesCount = pagesCount;
  }

  private setCurrentPage(currentPage: number) {
    this.currentPage = currentPage;
  }

  private setIsSearchUsed(isSearchUsed: boolean) {
    this.isSearchUsed = isSearchUsed;
  }

  private setIsShowPagination(isShowPagination: boolean) {
    this.isShowPagination = isShowPagination;
  }

  private setIsShowPlaceholder(isShowPlaceholder: boolean) {
    this.isShowPlaceholder = isShowPlaceholder;
  }

  private setTotalElements(totalElements: number) {
    this.totalElements = totalElements;
  }
}
