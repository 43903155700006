import moment from 'moment';
import { FieldValues, Path, UseControllerProps } from 'react-hook-form';

import { addValidateRules } from '../../../utils/reactHookFormUtils';

export const addTimeIsValidRule = <T extends FieldValues, TName extends Path<T>>(
  rules: UseControllerProps<T, TName>['rules'],
  format: string,
) => {
  const isValid = (value: string) =>
    moment(value, format).isValid() || "Time isn't valid";
  return addValidateRules(rules, { isValid });
};
