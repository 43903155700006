import { inbodyDataService } from '../../../services/inbodyDataService';
import { EntityInbodyDataFormValue } from '../EntityInbodyDataConfig.settings';

// fix that UNDEFINED is not exist in InbodyTypeOptions
const getSupportedInbodyType = (
  supportedInbodyType: EntityInbodyDataFormValue['supportedInbodyType'],
) => {
  return (
    supportedInbodyType === 'UNDEFINED' ? '' : supportedInbodyType
  ) as EntityInbodyDataFormValue['supportedInbodyType'];
};

export const getInbodyConfigFormValue = async (
  entityId: number,
): Promise<EntityInbodyDataFormValue> => {
  const inbodyData = await inbodyDataService.getEntityInbodyData(entityId);

  const supportedInbodyType = getSupportedInbodyType(inbodyData.supportedInbodyType);

  return { ...inbodyData, supportedInbodyType };
};
