import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';

import classes from './HeaderBtnBox.module.scss';

const HeaderBtnBox = ({ onDelete, onCancel, onSubmit, isProfileCard }) => {
  const deleteBtn = (
    <Button variant="text" color="secondary" onClick={onDelete}>
      Delete
    </Button>
  );

  const profileBtn = (
    <>
      <Button variant="outlined" size="small" onClick={onCancel}>
        Cancel
      </Button>
      <Button variant="contained" size="small" onClick={onSubmit}>
        Save
      </Button>
    </>
  );

  return <div className={classes.btnBox}>{isProfileCard ? profileBtn : deleteBtn}</div>;
};

HeaderBtnBox.propTypes = {
  isProfileCard: PropTypes.bool,
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export { HeaderBtnBox };
