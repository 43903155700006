export const getDefaultValue = (foodScoreResultList, foodScoreType) => {
  if (!foodScoreResultList?.length) {
    return null;
  }

  const foodScore = foodScoreResultList.find(
    (score) => score.foodScoreType === foodScoreType,
  );

  return foodScore?.foodScoreValue;
};
