'use strict';

angular.module('app')
    .controller('CustomerMealTimeTabController', CustomerMealTimeTabController);

CustomerMealTimeTabController.$inject = [
    '$mdDialog',
    '$routeParams',
    'MealScheduleService',
    'MealPlanService',
    'ModalService',
    'DateTimeUtil',
    'utilService',
    'constants',
    '$location'
];
// this is crucial to send UTC date to the server LMP-3141
function CustomerMealTimeTabController($mdDialog, $routeParams, MealScheduleService, MealPlanService, ModalService,
                                       DateTimeUtil, utilService, constants, $location) {
    var vm = this;
    vm.isRecommendedSteps = $location.url().includes('/recommended-steps/');
    vm.loggedInUser = utilService.getLoggedInUserData();
    vm.userId = vm.loggedInUser.role === 'CUSTOMER' ? vm.loggedInUser.id : $routeParams.id;
    vm.complianceCoach = utilService.getComplianceCoachIndicator();
    vm.allDaysOfWeek = constants.daysOfWeek;
    vm.allActiveMealTypes = constants.mealTypes;
    vm.mealTimeDay = constants.mealTimeDay;

    vm.tomorrowMealPlanInfo = {};
    vm.currentMealPlanInfo = {};

    vm.currentDate = DateTimeUtil.now();
    vm.tomorrowDate = DateTimeUtil.minusDays(DateTimeUtil.now(), -1);

    vm.isTabOpen = false;
    vm.isComplete = false;
    vm.workoutDays = [];
    vm.selectedMealPlans = [];
    vm.spinner = {active: false};

    vm.init = init;
    vm.setIsTabOpen = setIsTabOpen;
    vm.onCardHeaderClick = onCardHeaderClick;
    vm.getWeeklyMealSchedules = getWeeklyMealSchedules;
    vm.getMealScheduleByDate = getMealScheduleByDate;
    vm.getMealPlansInfoByDate = getMealPlansInfoByDate;
    vm.initMealTimeTable = initMealTimeTable;
    vm.setMealTimeData = setMealTimeData;
    vm.setMealPlans = setMealPlans;
    vm.isTimeFieldNonActive = isTimeFieldNonActive;
    vm.isTimeFieldDisabled = isTimeFieldDisabled;
    vm.changeWorkoutDays = changeWorkoutDays;
    vm.showEditTimeDialog = showEditTimeDialog;
    vm.onSave = onSave;
    vm.formatDateForRequest = formatDateForRequest;

    vm.init();

    function init() {
        vm.setIsTabOpen();
        vm.getWeeklyMealSchedules();
    }
    
    function setIsTabOpen() {
        vm.isTabOpen = !vm.isRecommendedSteps;
    }

    function onCardHeaderClick() {
        vm.isTabOpen = !vm.isTabOpen;
    }

    function getWeeklyMealSchedules() {
        vm.getMealPlansInfoByDate(vm.mealTimeDay.TODAY).then(function () {
            vm.getMealPlansInfoByDate(vm.mealTimeDay.TOMORROW).then(function () {
                vm.getMealScheduleByDate(vm.mealTimeDay.TODAY).then(function () {
                    vm.getMealScheduleByDate(vm.mealTimeDay.TOMORROW).then(function () {
                        vm.mealTimeTab = vm.currentMealTime.validTo !== null ?
                            vm.mealTimeDay.TOMORROW : vm.mealTimeDay.TODAY;
                        vm.setMealTimeData(vm.tomorrowMealTime, vm.tomorrowMealPlanInfo);
                    });
                });
            });
        });
    }

    function getMealScheduleByDate(dateStr) {
        var date = dateStr === vm.mealTimeDay.TODAY ? vm.currentDate : vm.tomorrowDate;

        vm.spinner.active = true;
        return MealScheduleService.getWeeklyMealSchedule(vm.userId, {date: vm.formatDateForRequest(date)})
            .then(function (res) {
                vm.spinner.active = false;
                if (res.status === 200) {
                    var mealTime = dateStr === vm.mealTimeDay.TODAY ? 'currentMealTime' : 'tomorrowMealTime';
                    vm[mealTime] = res.data;
                }
            });
    }

    function getMealPlansInfoByDate(dateStr) {
        var date = dateStr === vm.mealTimeDay.TODAY ? vm.currentDate : vm.tomorrowDate;

        vm.spinner.active = true;
        return MealPlanService.getMealPlan(vm.userId, {date: vm.formatDateForRequest(date)})
            .then(function (res) {
                vm.spinner.active = false;
                if (res.status === 200) {
                    var mealPlan = dateStr === vm.mealTimeDay.TODAY ? 'currentMealPlanInfo' : 'tomorrowMealPlanInfo';
                    vm[mealPlan] = res.data;
                }
            });
    }

    function initMealTimeTable(value) {
        vm.mealTimeTab = value;
        vm.setMealTimeData(
            value === vm.mealTimeDay.TODAY ? vm.currentMealTime : vm.tomorrowMealTime,
            value === vm.mealTimeDay.TODAY ? vm.currentMealPlanInfo : vm.tomorrowMealPlanInfo
        );
    }

    function setMealTimeData(results, mealPlan) {
        vm.mealTime = [
            {
                mealType: vm.allActiveMealTypes[0],
                time: results.breakfast
            }, {
                mealType: vm.allActiveMealTypes[1],
                time: results.snack1
            }, {
                mealType: vm.allActiveMealTypes[2],
                time: results.lunch
            }, {
                mealType: vm.allActiveMealTypes[3],
                time: results.snack2
            }, {
                mealType: vm.allActiveMealTypes[4],
                time: results.dinner
            }, {
                mealType: vm.allActiveMealTypes[5],
                time: results.snack3
            }, {
                mealType: vm.allActiveMealTypes[6],
                time: results.preWorkout
            }, {
                mealType: vm.allActiveMealTypes[7],
                time: results.postWorkout
            }
        ];
        vm.workoutDays = results.workoutDays;
        vm.setMealPlans(results, mealPlan);
    }

    function setMealPlans(mealTime, mealPlan) {
        vm.selectedMealPlans = [];
        vm.mealPlansInfo = mealPlan;
        mealTime.mealPlans.forEach(function (value) {
            vm.selectedMealPlans.push(vm.mealPlansInfo.mealPlans.find(function (plan) {
                return plan.originId === value.origin;
            }));
        });
    }

    // same exist in ModalService.changeMealTime if change here change there
    // mealIndex is index of row, 0 = Breakfast, 1 = Snack....
    // dayIndex is index of column, impotent sunday is first column in ui but index is 6, 0 = monday, 1 = tuesday...
    function isTimeFieldNonActive(mealIndex, dayIndex) {
        const isInMealPlanOnlyTwoSnacks = vm.selectedMealPlans[dayIndex].mealPlan === 5;
        const isFirstSnackMeal = mealIndex === 1;
        const isDisabledFirstSnackMeal = isInMealPlanOnlyTwoSnacks && isFirstSnackMeal;

        const isNotWorkoutDay = !vm.workoutDays[dayIndex];
        const isPreOrPostWorkoutMeal = mealIndex === 6 || mealIndex === 7;
        const isDisabledWorkoutMeal = isNotWorkoutDay && isPreOrPostWorkoutMeal;

        return isDisabledFirstSnackMeal || isDisabledWorkoutMeal;   
    }

    function isTimeFieldDisabled() {
        return vm.currentMealTime.validTo !== null && vm.mealTimeTab === vm.mealTimeDay.TODAY;
    }

    function onSave() {
        if (vm.loggedInUser.role !== 'CUSTOMER') {
            ModalService.saveConfirmModal(vm.changeWorkoutDays, 'How would you like to change the meal schedule?');
        } else {
            vm.changeWorkoutDays(null, false);
        }
    }

    /**
     * @param empty - first param need for compatibility with ModalService.saveConfirmModal()
     * @param immediately - boolean or undefined
     */
    function changeWorkoutDays(empty, immediately) {
        var todayDate = vm.formatDateForRequest(vm.currentDate);
        var schedule = vm.mealTimeTab === vm.mealTimeDay.TODAY ? vm.currentMealTime : vm.tomorrowMealTime;

        schedule.breakfast = vm.mealTime[0].time;
        schedule.snack1 = vm.mealTime[1].time;
        schedule.lunch = vm.mealTime[2].time;
        schedule.snack2 = vm.mealTime[3].time;
        schedule.dinner = vm.mealTime[4].time;
        schedule.snack3 = vm.mealTime[5].time;
        schedule.preWorkout = vm.mealTime[6].time;
        schedule.postWorkout = vm.mealTime[7].time;
        schedule.workoutDays = vm.workoutDays;
        schedule.setImmediately = !!immediately;
        schedule.mealPlans = vm.selectedMealPlans.map(function (value) {
            return { origin: value.originId, name: value.name };
        });

        vm.spinner.active = true;
        MealScheduleService.updateWeeklyMealSchedule(vm.userId, schedule, {date: todayDate})
            .then(function (res) {
                vm.spinner.active = false;
                if (res.status === 200) {
                    vm.isComplete = true;
                    vm.setIsTabOpen();
                    if (immediately) {
                        ModalService.getInfoMessage('Meal schedule changes saved', vm.getWeeklyMealSchedules());
                    } else {
                        ModalService.getInfoMessage('Meal schedule changes will be override by new schedule from ' +
                            moment(DateTimeUtil.minusDays(DateTimeUtil.now(), -1))
                                .format('MM.DD.YYYY'), vm.getWeeklyMealSchedules());
                    }
                }
        });
    }

    function showEditTimeDialog(mealIndex) {
        ModalService.changeMealTime(
            vm.allActiveMealTypes[mealIndex],
            vm.workoutDays,
            vm.selectedMealPlans,
            vm.mealTime,
            mealIndex
        );
    }

    function formatDateForRequest(date) {
        return moment(date).utc().format('YYYY-MM-DD');
    }
}
