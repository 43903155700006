import React from 'react';

import { Button } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';

import classes from './UploadLogoCard.module.scss';
import { getBytesFromMb } from '../../../../../../../../../shared/utils/mathUtils';

const maxFileSizeMb = 5;
const maxSize = getBytesFromMb(maxFileSizeMb);

type UploadLogoCardProps = {
  onUpload: (acceptedFiles: File[]) => void;
};

const UploadLogoCard = ({ onUpload }: UploadLogoCardProps) => {
  const {
    open,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    noClick: true,
    multiple: false,
    noKeyboard: true,
    maxSize,
    accept: { 'image/*': ['.png', '.svg', '.jpeg', '.jpg'] },
    onDrop: onUpload,
  });

  const isUploadError = isDragReject || fileRejections.length > 0;

  const dropzoneBoxClasses = classNames(classes.dropzoneBox, {
    [classes.dragging]: isDragActive && !isUploadError,
    [classes.error]: isUploadError,
  });

  const getErrorText = () => {
    const rejectedFile = fileRejections[0];

    if (!rejectedFile) {
      return 'Invalid file';
    }

    const [{ code }] = rejectedFile.errors;

    switch (code) {
      case 'file-invalid-type':
        return 'Unsupported format type. Please use PNG, SVG, JPEG';
      case 'file-too-large':
        return `File size exceeds ${maxFileSizeMb} MB`;
      default:
        return 'Invalid file';
    }
  };

  return (
    <div
      {...getRootProps({
        className: dropzoneBoxClasses,
        'data-test-id': 'dropzone',
      })}
    >
      <input {...getInputProps()} />
      <div className={classes.infoBox}>
        <h6>{isUploadError ? getErrorText() : 'Click to Browse File or Drag & Drop'}</h6>
        <p>Supports formats: PNG, SVG, JPEG up to {maxFileSizeMb} MB</p>
      </div>
      <Button variant="contained" size="small" onClick={open}>
        Browse File
      </Button>
    </div>
  );
};

export { UploadLogoCard };
