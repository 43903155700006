import React from 'react';
import PropTypes from 'prop-types';

const OrderCell = ({ orderAmount }) => {
  return <div>{orderAmount || 0} bottles</div>;
};

OrderCell.propTypes = {
  orderAmount: PropTypes.number,
};

export { OrderCell };
