import React, { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';

import classes from './ReportDateRange.module.scss';
import { DateRangePicker } from '../../../../../../../shared/components/form-components';
import { ReportSetting } from '../../../../../../interfaces/ReportSetting';
import { getRequiredRules } from '../../utils/getRequiredRules';

type ReportDateRangeProps = {
  label: ReactNode;
  pickerSettings: ReportSetting[];
};

const ReportDateRange = ({ label, pickerSettings }: ReportDateRangeProps) => {
  const { control } = useFormContext();

  const [startPicker, endPicker] = pickerSettings;

  const startPickerRules = getRequiredRules(
    startPicker.selectAllAvailable,
    startPicker.selectorLabel!,
  );

  const endPickerRules = getRequiredRules(
    endPicker.selectAllAvailable,
    endPicker.selectorLabel!,
  );

  return (
    <div className={classes.dateRangeRow}>
      {!!label && <p className={classes.label}>{label}</p>}
      <DateRangePicker
        control={control}
        size="small"
        variant="outlined"
        openTo="month"
        className={classes.dateRangePicker}
        startPickerProps={{
          name: startPicker.parameterKey!,
          label: startPicker.selectorLabel,
          rules: startPickerRules,
        }}
        endPickerProps={{
          name: endPicker.parameterKey!,
          label: endPicker.selectorLabel,
          rules: endPickerRules,
        }}
      />
    </div>
  );
};

export { ReportDateRange };
