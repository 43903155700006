const autoNgTemplateLoaderTemplate1 = require('./add-recipe.template.html');
const autoNgTemplateLoaderTemplate2 = require('./add-recipe.template.html');
const autoNgTemplateLoaderTemplate3 = require('./add-recipe.template.html');

'use strict';

(function () {
    angular
        .module('app.addRecipe', [])
        .config(addRecipeConfig);

    addRecipeConfig.$inject = ['$routeProvider'];

    function addRecipeConfig($routeProvider) {
        $routeProvider
            .when('/add-recipe', {
                disableCache: true,
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'AddRecipeController',
                controllerAs: 'vm',
                resolve: {
                    loginRequired: ['AuthService', function (AuthService) {
                        return AuthService.loginRequired();
                    }]
                }
            })
            .when('/add-recipe/:id', {
                disableCache: true,
                templateUrl: autoNgTemplateLoaderTemplate2,
                controller: 'AddRecipeController',
                controllerAs: 'vm',
                resolve: {
                    loginRequired: ['AuthService', function (AuthService) {
                        return AuthService.loginRequired();
                    }]
                }
            })
            .when('/edit-recipe/:id', {
                disableCache: true,
                templateUrl: autoNgTemplateLoaderTemplate3,
                controller: 'AddRecipeController',
                controllerAs: 'vm',
                resolve: {
                    loginRequired: ['AuthService', function (AuthService) {
                        return AuthService.loginRequired();
                    }]
                }
            })
    }
})();
