import React from 'react';

import { Button } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useToggle } from 'react-use';

import classes from './RegistrationFormAccessWidget.module.scss';
import { InfoBox } from '../../../../../../../shared/components/ui-components';
import { TitleWithDescription } from '../../../../../../../shared/components/text-formation-components';
import { ReactComponent as QrCodeIcon } from '../../../../../../assets/icons/qr-code.svg';
import { ReactComponent as WebsiteLinkIcon } from '../../../../../../assets/icons/website-link.svg';
import { entityCustomerSetupService } from '../../../../../../services/entityCustomerSetupService';
import { copyTextToClipboard } from '../../../../../../../shared/utils/apiUtils';
import { RegistrationQrCodeModal } from './RegistrationQrCodeModal/RegistrationQrCodeModal';

type RegistrationFormAccessWidgetProps = {
  entityId: number;
};

const RegistrationFormAccessWidget = ({
  entityId,
}: RegistrationFormAccessWidgetProps) => {
  const [isQrCodeModalOpen, toggleQrCodeModalOpen] = useToggle(false);

  const handleCopyLink = async () => {
    const { url } = await entityCustomerSetupService.getRegistrationLink(entityId);

    copyTextToClipboard(url);
    enqueueSnackbar('The link to the user registration form was successfully copied', {
      variant: 'success',
      autoHideDuration: 2000,
    });
  };

  return (
    <>
      <InfoBox title="Access to Registration Form">
        <div className={classes.row}>
          <div className={classes.box}>
            <div className={classes.info}>
              <TitleWithDescription
                title="QR code access"
                description="A QR code has already been generated and assigned to your entity"
                titleFont="body-14-bold"
              />
              <Button variant="contained" size="small" onClick={toggleQrCodeModalOpen}>
                Preview and Download
              </Button>
            </div>
            <div className={classes.icon}>
              <QrCodeIcon />
            </div>
          </div>
          <div className={classes.box}>
            <div className={classes.info}>
              <TitleWithDescription
                title="Access to the registration form via the link"
                description="Copy the permanent link to share with your customers"
                titleFont="body-14-bold"
              />
              <Button variant="contained" size="small" onClick={handleCopyLink}>
                Copy link
              </Button>
            </div>
            <div className={classes.icon}>
              <WebsiteLinkIcon />
            </div>
          </div>
        </div>
      </InfoBox>
      {isQrCodeModalOpen && (
        <RegistrationQrCodeModal entityId={entityId} onClose={toggleQrCodeModalOpen} />
      )}
    </>
  );
};

export { RegistrationFormAccessWidget };
