import React, { useEffect, useState } from 'react';

import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import {
  PaymentRequest,
  StripePaymentRequestButtonElementOptions,
} from '@stripe/stripe-js';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import classes from './PaymentButton.module.scss';
import { checkoutStore } from '../../../../../stores/checkoutStore';

type PaymentButtonType = 'applePay' | 'googlePay';

type PaymentButtonProps = {
  type: PaymentButtonType;
  returnUrl: string;
  className?: string;
};

const PaymentButton = observer(({ type, returnUrl, className }: PaymentButtonProps) => {
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null);

  const stripe = useStripe();

  const { checkoutInfo } = checkoutStore;

  const initPaymentRequest = async () => {
    const request = stripe?.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: checkoutInfo!.subscription.name,
        // We should specify amount in cents
        amount: checkoutInfo!.checkoutDetails.totalAmount * 100,
      },
      requestPayerName: true,
      requestPayerEmail: true,
      disableWallets: ['link', type === 'googlePay' ? 'applePay' : 'googlePay'],
    });

    if (request && (await request.canMakePayment())) {
      setPaymentRequest(request);
    }
  };

  useEffect(() => {
    if (stripe && checkoutInfo) {
      initPaymentRequest();
    }
  }, [stripe, checkoutInfo]);

  if (!paymentRequest) {
    return null;
  }

  const buttonOptions: StripePaymentRequestButtonElementOptions = {
    paymentRequest,
    style: {
      paymentRequestButton: {
        type: 'buy',
        height: '45px',
      },
    },
  };

  paymentRequest.on('paymentmethod', async (event) => {
    if (stripe && checkoutInfo) {
      const { error } = await stripe.confirmPayment({
        clientSecret: checkoutInfo.paymentIntent.clientSecret,
        confirmParams: {
          payment_method: event.paymentMethod.id,
          return_url: returnUrl,
        },
      });

      event.complete(error ? 'fail' : 'success');
    }
  });

  const containerClassName = classNames(classes.container, className);

  return (
    <div className={containerClassName}>
      <PaymentRequestButtonElement className={classes.button} options={buttonOptions} />
    </div>
  );
});

export { PaymentButton };
