import { inventoryStatus } from '../../../../constants/inventoryStatus';

export const getStatus = (currentStatus, actual, packageSize, isDeleteOrder) => {
  if (currentStatus === inventoryStatus.PENDING && !isDeleteOrder) {
    return inventoryStatus.PENDING;
  }

  const interest = (actual * 100) / packageSize;
  return interest < 20
    ? inventoryStatus.LOW_INVENTORY
    : inventoryStatus.SUFFICIENT_INVENTORY;
};
