import { useEffect, useState } from 'react';

import { loggedInUserDataStore } from '../../../../../../shared/stores/loggedInUserDataStore';
import { stripeService } from '../../../../../services/stripeService';

export type StripeConnectedInfo = {
  publishableKey: string;
  connectedAccountId: string;
};

/**
 *  required observer
 */
export const useStripeConnectedInfo = (entityId: number) => {
  const [stripeConnectedInfo, setStripeConnectedInfo] =
    useState<StripeConnectedInfo | null>(null);

  const { isSuperAdmin } = loggedInUserDataStore;

  const getPaymentInfoBySuperAdmin = async () => {
    const [{ key }, { stripeAccountID }] = await Promise.all([
      stripeService.getPublishApiKey(),
      stripeService.getConnectedAccountId(entityId),
    ]);

    setStripeConnectedInfo({ publishableKey: key, connectedAccountId: stripeAccountID });
  };

  const getPaymentInfo = async () => {
    const { key, connectedAccountId } = await stripeService.getPublishApiKey();

    setStripeConnectedInfo({ publishableKey: key, connectedAccountId });
  };

  useEffect(() => {
    if (isSuperAdmin) {
      getPaymentInfoBySuperAdmin();
    } else {
      getPaymentInfo();
    }
  }, []);

  return stripeConnectedInfo;
};
