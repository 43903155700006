import React from 'react';

import { angularize } from 'react-in-angularjs';
import { Container } from '@mui/material';

import { SystemProviders } from '../../../../../shared/providers';
import { ComplianceTable } from './ComplianceTable/ComplianceTable';
import {
  CheckCircleLabel,
  PageTitle,
} from '../../../../../shared/components/ui-components';

const NgzComplianceBoardTab = () => {
  return (
    <SystemProviders>
      <Container>
        <PageTitle title="Today's Compliance Board">
          <CheckCircleLabel>Only active users</CheckCircleLabel>
        </PageTitle>
        <ComplianceTable />
      </Container>
    </SystemProviders>
  );
};

angularize(NgzComplianceBoardTab, 'ngzComplianceBoardTab', angular.module('app'), {});

export default NgzComplianceBoardTab;
