const autoNgTemplateLoaderTemplate1 = require('./customer-profile.template.html');

'use strict';

(function () {
    angular
        .module('app.customerProfile', [])
        .component('customerProfile', {
            templateUrl: autoNgTemplateLoaderTemplate1,
            controller: 'CustomerProfileController',
            controllerAs: 'vm'
        })
        .config(customerProfileConfig);

    customerProfileConfig.$inject = ['$routeProvider'];

    function customerProfileConfig($routeProvider) {
        $routeProvider
            .when('/customer-profile/:id', {
                disableCache: true,
                template: '<customer-profile></customer-profile>',
                resolve: {
                    loginRequired: ['AuthService', function (AuthService) {
                        return AuthService.loginRequired();
                    }]
                }
            })
    }
})();
