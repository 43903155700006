import React from 'react';

import {
  GoHighLevelAccountStatus,
  GO_HIGH_LEVEL_ACCOUNT_STATUS,
} from '../../../../constants/goHighLevelAccountStatus';
import { LbChip } from '../../../../../shared/components/ui-components';

type AccountStatusChipProps = {
  status: GoHighLevelAccountStatus;
};

const AccountStatusChip = ({ status }: AccountStatusChipProps) => {
  switch (status) {
    case GO_HIGH_LEVEL_ACCOUNT_STATUS.ACTIVE:
      return <LbChip label="Active" color="success" size="medium" />;
    case GO_HIGH_LEVEL_ACCOUNT_STATUS.NOT_AVAILABLE:
      return <LbChip label="Not available" color="error" size="medium" />;
    default:
      return <div>No Info</div>;
  }
};

export { AccountStatusChip };
