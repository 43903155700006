import React from 'react';

import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { SelectProps } from '@mui/material';

export const TextFieldIcon: SelectProps['IconComponent'] = (props) => (
  // MuiSelect-icon set color need use sx={{ fill: 'var(--natural-400)' }}
  <ArrowDropDownRoundedIcon
    {...props}
    fontSize="large"
    sx={{ fill: 'var(--natural-400)' }}
  />
);
