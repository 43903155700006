import React from 'react';
import PropTypes from 'prop-types';

import classes from './RowTemplate.module.scss';

const RowTemplate = ({ cellOne, cellTwo, cellThree, className, dataTestId }) => {
  return (
    <div className={`${classes.rowTemplate} ${className}`} data-test-id={dataTestId}>
      <div>{cellOne}</div>
      <div>{cellTwo}</div>
      <div>{cellThree}</div>
    </div>
  );
};

RowTemplate.propTypes = {
  cellOne: PropTypes.any,
  cellTwo: PropTypes.any,
  cellThree: PropTypes.any,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};

export { RowTemplate };
