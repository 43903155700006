import { useEffect, useState } from 'react';
import { VideoCategory } from '../../../../../interface/video';
import { videosServices } from '../../../../../services/videosServices';

export const useVideoTab = () => {
  const [videoData, setVideoData] = useState<VideoCategory[]>([]);
  const [showedVideoSrc, setShowedVideoSrc] = useState<string | null>(null);

  const handleShowVideoModal = (videoSrc: string) => {
    setShowedVideoSrc(videoSrc);
  };

  const handleCloseVideoModal = () => {
    setShowedVideoSrc(null);
  };

  const getVideos = async () => {
    const { categories } = await videosServices.getAllVideos();
    setVideoData(categories);
  };

  useEffect(() => {
    getVideos();
  }, []);

  return { videoData, showedVideoSrc, handleShowVideoModal, handleCloseVideoModal };
};
