import { getService } from 'react-in-angularjs';
import { resetAllStore } from '../../../stores/resetAllStore';
import { ANGULAR_EVENT_KEYS } from '../../../constants/system/ANGULAR_EVENT_KEYS';
import { broadcastEvent } from '../../angularUtils';

export const clearUserInfo = () => {
  const localStorageService = getService('localStorageService');

  localStorageService.clearAll();
  localStorage.clear();
  resetAllStore.reset();

  broadcastEvent(ANGULAR_EVENT_KEYS.UPDATE_USER);
};
