import React from 'react';

import classes from './CustomersFatLostWidget.module.scss';
import { InfoBox, SeparatorLine } from '../../../shared/components/ui-components';
import { InfoCell } from './InfoCell/InfoCell';
import { useCustomersFatLost } from './hooks/useCustomersFatLost';

const CustomersFatLostWidget = () => {
  const { members, total } = useCustomersFatLost();

  return (
    <InfoBox title="Analytics" customMargin>
      <div className={classes.analyticsBox}>
        <InfoCell count={members} description="Total members" />
        <SeparatorLine className={classes.separator} />
        <InfoCell count={total} description="Total lbs of Fat Lost" />
      </div>
    </InfoBox>
  );
};

export { CustomersFatLostWidget };
