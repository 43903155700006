import React from 'react';
import PropTypes from 'prop-types';

import { IconButton, InputAdornment } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

import {
  LbInput,
  LbTimePicker,
  LbSelect,
} from '../../../../../shared/components/form-components';
import classes from './SupplementFormRow.module.scss';

const withFoodOption = [
  { value: true, label: 'With Food' },
  { value: false, label: 'Without food' },
];

const SupplementFormRow = ({
  control,
  nameIndex,
  deleteHandler,
  showDeleteBtn,
  amountUnit,
}) => {
  return (
    <div className={classes.supplementFormRow}>
      <LbInput
        control={control}
        name={`${nameIndex}amount`}
        label="Amount to take"
        InputProps={{
          endAdornment: <InputAdornment position="end">{amountUnit}</InputAdornment>,
        }}
        type="number"
        rules={{
          required: 'Amount to take is required',
          pattern: {
            value: /^\d*\.?\d{0,2}$/,
            message: 'Cannot type in more than two digits after the decimal point',
          },
        }}
      />
      <LbTimePicker
        control={control}
        name={`${nameIndex}takeTime`}
        label="Time to take"
        rules={{ required: 'Time to take is required' }}
      />
      <LbSelect
        control={control}
        name={`${nameIndex}withFood`}
        label="With or without food"
        options={withFoodOption}
        rules={{
          validate: (value) =>
            value || value === false || 'With or without food is required',
        }}
      />
      {showDeleteBtn && (
        <IconButton onClick={deleteHandler}>
          <CancelRoundedIcon fontSize="large" />
        </IconButton>
      )}
    </div>
  );
};
SupplementFormRow.propTypes = {
  control: PropTypes.object.isRequired,
  nameIndex: PropTypes.string.isRequired,
  deleteHandler: PropTypes.func.isRequired,
  showDeleteBtn: PropTypes.bool,
  amountUnit: PropTypes.string,
};

export { SupplementFormRow };
