import React from 'react';

import { angularize } from 'react-in-angularjs';
import { Link } from '@mui/material';

import classes from './NgzFooter.module.scss';
import { SystemProviders } from '../../providers';
import { InfoCell } from './components/InfoCell/InfoCell';
import { VersionInfoTable } from './containers/VersionInfoTable/VersionInfoTable';
import { useIsActivePage } from '../../hooks/useIsActivePage';

const pagesWithoutFooter = ['/checkout', '/payment-result', '/customer-self-setup'];

const NgzFooter = () => {
  const isPageWithoutFooter = useIsActivePage({ urls: pagesWithoutFooter });

  if (isPageWithoutFooter === null || isPageWithoutFooter) {
    return null;
  }

  return (
    <SystemProviders>
      <footer className={classes.footer}>
        <VersionInfoTable />

        <InfoCell
          title="Developed by:"
          info={
            <Link
              href="https://www.ekreative.com/?utm_source=backend&utm_medium=footer&utm_campaign=lifebase"
              rel="noopener"
              target="_blank"
            >
              eKreative.com
            </Link>
          }
        />
      </footer>
    </SystemProviders>
  );
};

angularize(NgzFooter, 'ngzFooter', angular.module('app'), {});

export { NgzFooter };
