import React from 'react';
import PropTypes from 'prop-types';

import classes from './SupplementCell.module.scss';

const SupplementCell = ({ supplementName, supplementBrand }) => {
  return (
    <div className={classes.supBox}>
      <p>{supplementName}</p>
      <span>{supplementBrand}</span>
    </div>
  );
};

SupplementCell.propTypes = {
  supplementName: PropTypes.string,
  supplementBrand: PropTypes.string,
};

export { SupplementCell };
