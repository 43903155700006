import { getService } from 'react-in-angularjs';
import { goTo } from '../../../../../../utils/angularUtils';

export const backToChildEntityList = (entityType) => {
  if (entityType.SCHOOL) {
    const AuthService = getService('AuthService');

    AuthService.childToParentLogin();
  } else {
    goTo('/child-entity-list', { replaceHistory: true });
  }
};
