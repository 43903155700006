import React from 'react';

import classes from './HeaderRow.module.scss';
import {
  GridTableRow,
  GridStickyGroup,
  GridTableCell,
} from '../../../../shared/components/table-components';
import { SortButtons } from '../../../../shared/components/buttons';
import { TitleWithDescription } from '../../../../shared/components/text-formation-components';
import { SortMethods } from '../EntitySubscriptionList.settings';
import { TaxRateInfo } from '../../../interfaces/tax';
import { getTaxDescription } from './utils/getTaxDescription';

type HeaderRowProps<TSubscriptionTypeKey extends string, THameKey extends string> = {
  sortMethods: SortMethods<TSubscriptionTypeKey, THameKey>;
  appliedTaxRateInfo?: TaxRateInfo | null;
};

const HeaderRow = <TSubscriptionTypeKey extends string, THameKey extends string>({
  sortMethods,
  appliedTaxRateInfo,
}: HeaderRowProps<TSubscriptionTypeKey, THameKey>) => {
  const { getIsActive, onSort, nameKey, subscriptionTypeKey } = sortMethods;

  const titleGeneralProps = { titleFont: 'inherit', withoutGap: true } as const;

  return (
    <GridTableRow variant="unstyled" className={classes.header}>
      <GridStickyGroup>
        <GridTableCell justify="center">Active</GridTableCell>
        <SortButtons fieldName={nameKey} onSort={onSort} getIsActive={getIsActive}>
          <GridTableCell>Name</GridTableCell>
        </SortButtons>
      </GridStickyGroup>
      <SortButtons
        fieldName={subscriptionTypeKey}
        onSort={onSort}
        getIsActive={getIsActive}
      >
        <GridTableCell>Type</GridTableCell>
      </SortButtons>
      <GridTableCell>
        <TitleWithDescription
          {...titleGeneralProps}
          title="Duration"
          description="months"
        />
      </GridTableCell>
      <GridTableCell>
        <TitleWithDescription
          {...titleGeneralProps}
          title="Initial Plan"
          description="no tax"
        />
      </GridTableCell>
      <GridTableCell>
        <TitleWithDescription
          {...titleGeneralProps}
          title="Total Initial Plan"
          description={getTaxDescription(appliedTaxRateInfo?.taxRateType)}
        />
      </GridTableCell>
      <GridTableCell>Total Initial Plan Fees</GridTableCell>
      <GridTableCell>
        <TitleWithDescription
          {...titleGeneralProps}
          title="Monthly Renewal"
          description="no tax"
        />
      </GridTableCell>
      <GridTableCell>
        <TitleWithDescription
          {...titleGeneralProps}
          title="Total Monthly Renewal"
          description={getTaxDescription(appliedTaxRateInfo?.taxRateType)}
        />
      </GridTableCell>
      <GridTableCell>Total Monthly Renewal Fees</GridTableCell>
      <GridTableCell justify="center">Display to user</GridTableCell>
    </GridTableRow>
  );
};

export { HeaderRow };
