import React from 'react';
import PropTypes from 'prop-types';

import { Button, SvgIcon } from '@mui/material';

import classes from './InventoryCell.module.scss';
import { ReactComponent as TabletIcon } from '../../../../../../../assets/images/material-icons/tablets.svg';
import { getInventoryStatus } from './InventoryCell.settings';

const InventoryCell = ({ inventory, onClick }) => {
  const { iconClass, text, isDisabled } = getInventoryStatus(inventory);

  return (
    <Button
      variant="link"
      disabled={isDisabled}
      className={classes.inventoryCell}
      onClick={onClick}
      disableRipple
    >
      <SvgIcon
        component={TabletIcon}
        inheritViewBox
        className={iconClass}
        fontSize="large"
      />
      <span>{text}</span>
    </Button>
  );
};

InventoryCell.propTypes = {
  inventory: PropTypes.string,
  onClick: PropTypes.func,
};

export { InventoryCell };
