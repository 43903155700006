 'use strict';

angular.module('app').controller('CustomerSetupStep4Controller', CustomerSetupStep4Controller);

CustomerSetupStep4Controller.$inject = ['$scope', '$mdDialog', 'GoalService', 'MealPlanService', 'CustomerService', 'DateTimeUtil', 'ModalService'];

function CustomerSetupStep4Controller($scope, $mdDialog, GoalService, MealPlanService, CustomerService, DateTimeUtil, ModalService) {
    var vm = this;
    vm.createUser = $scope.createUser;
    vm.goals = $scope.goals;
    vm.userGoal = $scope.userGoal;
    vm.mealScript = $scope.mealScript;
    vm.activityIndices = $scope.activityIndices;

    vm.metrics = null;
    vm.maintenanceMacroBreakdown = null;
    vm.newInitialMacroBreakdown = null;
    vm.minTargetDate = DateTimeUtil.now();
    vm.maxTargetDate = DateTimeUtil.minusYears(vm.minTargetDate, -100);
    vm.bodyFatGoalError = true;
    vm.goalError = true;

    vm.initMetrics = initMetrics;
    vm.getGeneratedMealPlan = getGeneratedMealPlan;
    vm.getAdjustments = getAdjustments;
    vm.isGoalExplanationPopupAvailable = isGoalExplanationPopupAvailable;
    vm.calculateTotalMealPlan = calculateTotalMealPlan;
    vm.setDate = setDate;
    vm.activityExplanationPopup = activityExplanationPopup;
    vm.checkErrors = checkErrors;
    vm.goToNextStep = goToNextStep;

    vm.initMetrics();

    function initMetrics(form) {
        vm.bodyFatGoalError = false;
        vm.goalError = false;

        if (form) {
            form['bodyFatGoal'].$setValidity('bodyFatError', !vm.bodyFatGoalError);
            form['goal'].$setValidity('goalError', !vm.goalError);
        }

        if (vm.userGoal.targetDate) {
            vm.goalTargetDate = DateTimeUtil.formatDate(vm.userGoal.targetDate, 'MM.DD.YYYY');
        }

        if (vm.userGoal.bodyFat !== null && vm.userGoal.weight > 0 && vm.userGoal.activityLevel !== null && vm.userGoal.goal) {
            vm.createUser.customer.activityLevelId = vm.userGoal.activityLevel.id;
            vm.metrics = {current: null, goal: null, changes: null};

            var userWeight = vm.createUser.customer.weight;
            var userBodyFat = vm.createUser.customer.bodyFat;

            vm.metrics.current = {
                weight: userWeight ? userWeight.toFixed(1) : 0,
                percentBodyFat: userBodyFat ? userBodyFat.toFixed(1) : 0,
                leanBodyMass: (userWeight - (userWeight * userBodyFat / 100)).toFixed(1),
                bodyFat: (userWeight * userBodyFat / 100).toFixed(1)
            };

            vm.metrics.goal = {
                weight: vm.userGoal.weight.toFixed(1),
                percentBodyFat: vm.userGoal.bodyFat ? vm.userGoal.bodyFat.toFixed(1) : 0 ,
                leanBodyMass: (vm.userGoal.weight - (vm.userGoal.weight * vm.userGoal.bodyFat / 100)).toFixed(1),
                bodyFat: (vm.userGoal.weight * vm.userGoal.bodyFat / 100).toFixed(1)
            };

            vm.metrics.changes = {
                weight: (vm.metrics.goal.weight - vm.metrics.current.weight).toFixed(1),
                percentBodyFat: (vm.metrics.goal.percentBodyFat - vm.metrics.current.percentBodyFat).toFixed(1),
                leanBodyMass: (vm.metrics.goal.leanBodyMass - vm.metrics.current.leanBodyMass).toFixed(1),
                bodyFat: (vm.metrics.goal.bodyFat - vm.metrics.current.bodyFat).toFixed(1)
            };

            vm.getGeneratedMealPlan();
        } else {
            vm.metrics = null;
            vm.maintenanceMacroBreakdown = null;
            vm.newInitialMacroBreakdown = null;
        }
    }

    function getGeneratedMealPlan() {
        var gender = vm.createUser.customer.gender.toLowerCase();
        var totalWorkouts = CustomerService.calculateWorkoutHours(vm.createUser.weeklyWorkouts);

        MealPlanService.generateMealPlanByUserInfo({
            weight: parseFloat(vm.metrics.current.weight),
            height: parseFloat(vm.createUser.customer.height),
            dateOfBirth: DateTimeUtil.formatDate(vm.createUser.customer.dob, 'MM-DD-YYYY'),
            isMale: gender === 'male',
            bodyFat: parseInt(vm.metrics.current.percentBodyFat),
            activityLevel: parseFloat(vm.userGoal.activityLevel.bmrPercentage),
            totalWorkouts: totalWorkouts,
            mealsPerDay: vm.createUser.weeklyWorkouts.defaultMealsPerDay
        }).then(function (result) {
            if (result.status === 200) {
                vm.getAdjustments(gender, result.data.mealPlans[0]);
            }
        });
    }

    function getAdjustments(gender, mealPlan) {
        var planTotal = mealPlan.total;

        GoalService.getAdjustments({
            goal: vm.userGoal.goal.id,
            gender: gender,
            bodyFat: vm.metrics.goal.percentBodyFat,
            weight: vm.metrics.goal.weight
        }).then(function (result) {
            if (result.status === 200) {
                vm.newInitialMacroBreakdown = vm.calculateTotalMealPlan(mealPlan, result.data);
                vm.maintenanceMacroBreakdown = {
                    proteins: planTotal.prt,
                    carbs: planTotal.crb,
                    fats: planTotal.fat,
                    calories: planTotal.prt * 4 + planTotal.crb * 4 + planTotal.fat * 9
                };
                vm.mealScript.id = vm.newInitialMacroBreakdown.scriptId;
            } else {
                vm.maintenanceMacroBreakdown = null;
                vm.newInitialMacroBreakdown = null;
            }
        });
    }

    function isGoalExplanationPopupAvailable() {
        return (vm.userGoal.goal.id === 1 && vm.metrics.changes.leanBodyMass < 0) ||
            (vm.userGoal.goal.id === 1 && vm.metrics.changes.leanBodyMass > 0 && vm.metrics.changes.bodyFat < 0) ||
            (vm.userGoal.goal.id === 1 && vm.metrics.changes.bodyFat > 0) ||
            (vm.userGoal.goal.id === 2 && vm.metrics.changes.leanBodyMass < 0) ||
            vm.userGoal.goal.id === 3 || vm.userGoal.goal.id === 4;
    }

    function calculateTotalMealPlan(mealPlan, adjustments) {
        var result = {
            scriptId: adjustments.scriptId,
            proteins: mealPlan.total.prt + adjustments.proteins,
            carbs: mealPlan.total.crb + adjustments.carbs,
            fats: mealPlan.total.fat + adjustments.fats
        };
        result.calories = result.proteins * 4 + result.carbs * 4 + result.fats * 9;
        return result;
    }

    function setDate() {
        vm.goalTargetDate = DateTimeUtil.formatDate(vm.userGoal.targetDate, 'MM.DD.YYYY');
        $scope.$evalAsync();
    }

    function activityExplanationPopup(description) {
        ModalService.getDetailInfo(description);
    }

    function checkErrors(form, goal, bodyFat) {
        vm.goalError = goal;
        vm.bodyFatGoalError = bodyFat;

        if (vm.bodyFatGoalError || vm.goalError) {
            form['bodyFatGoal'].$setValidity('bodyFatError', !vm.bodyFatGoalError);
            form['goal'].$setValidity('goalError', !vm.goalError);
        } else {
            $scope.next();
        }
    }

    function goToNextStep(form) {
        if (vm.isGoalExplanationPopupAvailable()) {
            ModalService.getGoalExplanation(vm.userGoal, vm.metrics, vm.goalError, vm.bodyFatGoalError, form, vm.checkErrors);
        } else {
            vm.bodyFatGoalError = false;
            vm.goalError = false;
            form['bodyFatGoal'].$setValidity('bodyFatError', !vm.bodyFatGoalError);
            form['goal'].$setValidity('goalError', !vm.goalError);

            $scope.next();
        }
    }
}
