import React from 'react';

import classes from './PriceCellWithDescription.module.scss';
import { GridTableCell } from '../../../../../shared/components/table-components';
import { getPriceOrDash } from '../../../../utils/getPriceOrDash/getPriceOrDash';
import { InfoTooltip } from '../../../../../shared/components/ui-components';
import { getPriceView } from '../../../../../shared/utils/stringUtils';
import { getIsNullish } from '../../../../../shared/utils/jsUtils';

type DescriptionOption = {
  label: string;
  value: number | null;
};

type PriceCellWithDescriptionProps = {
  price: number;
  description: DescriptionOption[];
};

const PriceCellWithDescription = ({
  price,
  description,
}: PriceCellWithDescriptionProps) => {
  return (
    <GridTableCell className={classes.cell}>
      <span>{getPriceOrDash(price)}</span>
      {!!price && (
        <InfoTooltip>
          {description.map(
            (item) =>
              !getIsNullish(item.value) && (
                <span key={item.label}>
                  {item.label}: {getPriceView(item.value)}
                  <br />
                </span>
              ),
          )}
        </InfoTooltip>
      )}
    </GridTableCell>
  );
};

export { PriceCellWithDescription, PriceCellWithDescriptionProps, DescriptionOption };
