import React, { ReactNode } from 'react';

import { Control, useWatch } from 'react-hook-form';

import classes from './GeneralDataFrom.module.scss';
import {
  InputBox,
  LbInput,
  LbSelect,
} from '../../../../../../shared/components/form-components';
import { UserProfileFromValue } from '../UserProfileStep.settings';
import { Option } from '../../../../../../general-types';
import { validateOnlyInteger } from '../../../../../../shared/utils/reactHookFormUtils';
import { INBODY_TYPES } from '../../../../../../shared/constants/inbody-info/InbodyType';

type GeneralDataFromProps = {
  control: Control<UserProfileFromValue>;
  supportedInbodyTypeOptions: Option[];
  children?: ReactNode;
};

const GeneralDataFrom = ({
  control,
  supportedInbodyTypeOptions,
  children,
}: GeneralDataFromProps) => {
  const generalInputProps = {
    control,
    size: 'small',
    variant: 'standard',
  } as const;

  const inbodyType = useWatch({ control, name: 'inbodyInfo.inbodyType' });
  const isInbodyType = inbodyType && inbodyType !== INBODY_TYPES.NON_INBODY;

  return (
    <InputBox title="General User Setup">
      <LbSelect
        {...generalInputProps}
        name="inbodyInfo.inbodyType"
        label="User Type"
        options={supportedInbodyTypeOptions}
        rules={{ required: 'User Type is required' }}
      />
      {isInbodyType && (
        <LbInput
          {...generalInputProps}
          name="inbodyInfo.inbodyId"
          label="Inbody ID"
          rules={{
            required: 'Inbody ID is required',
            validate: { validateOnlyInteger },
          }}
        />
      )}
      {children && <div className={classes.scanData}>{children}</div>}
    </InputBox>
  );
};

export { GeneralDataFrom };
