import React from 'react';
import { LbChip } from '../../../shared/components/ui-components';

type ActivationStatusChipProps = {
  active: boolean | undefined;
};

const ActivationStatusChip = ({ active }: ActivationStatusChipProps) => {
  return active ? (
    <LbChip label="active" size="extraSmall" color="success" />
  ) : (
    <LbChip label="inactive" size="extraSmall" color="default" />
  );
};

export { ActivationStatusChip };
