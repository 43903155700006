'use strict';
import {
    Chart,
    LineController,
    LineElement,
    PointElement,
    LinearScale,
    CategoryScale,
    BarController,
    BarElement,
    Filler,
  } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(
    LineController,
    LineElement,
    PointElement,
    LinearScale,
    CategoryScale,
    BarController,
    BarElement,
    Filler,
);

angular.module('app').factory('ChartService', ChartService);

ChartService.$inject = [];

function ChartService() {
    const COLORS = {
        black: '#000',
        white: '#fff',
    };

    return {
        destroyChart(chartInstance) {
            if (chartInstance) {
                chartInstance.destroy();
            }
        },
        createBodyCompositionChart(chartInfo, startIndex, activeIndex) {
            this.destroyChart(chartInfo.instance);
            const chartElement = document.getElementById(chartInfo.chartId);

            let labels = [];
            let values = [];
            let pointRadius = [];
            let numOfDigits = 1;

            if (chartInfo.chartId === 'ecwTbwChart') {
                numOfDigits = 3;
            }

            chartInfo.data.forEach((item, index) => {
                if (index > startIndex - 1 && index <= startIndex + 15) {
                    processLabel(item);
                    processValue(item);
                }
            });

            function processLabel(item) {
                if (chartInfo.chartId === 'weightChart') {
                    const weightChartLabel = [
                        item.date,
                        "prot.   " + item.proteins,
                        "carbs  " + item.carbs,
                        "fats    " + item.fats
                    ];
                
                    labels.push(weightChartLabel);
                } else {
                    labels.push(item.date);
                }
            }

            function processValue(item) {
                const value = parseFloat(item.value).toFixed(numOfDigits);
                values.push(value);
            }

            pointRadius = values.map((item, index) => {
                const isActivePoint = index === activeIndex + 2;
                
                return isActivePoint ? 6 : 3;
            });

            function getDataLabelPosition(context) {
                const avgValue = getAvgValue();
                const index = context.dataIndex;
                const value = context.dataset.data[index];

                return value < avgValue ? 'top' : 'bottom';
            }

            function getAvgValue() {
                const maxValue = Math.max(...values);
                const minValue = Math.min(...values);

                return  (maxValue + minValue) / 2;
            }

            return new Chart(chartElement, {
                type: 'line',
                data: {
                    labels,
                    datasets: [{ data: values, pointRadius }],
                },
                plugins: [ChartDataLabels],
                options: {
                    events: [],
                    animation: false,
                    elements: {
                        line: {
                            borderColor: COLORS.black,
                            borderWidth: 2,
                        },
                        point: {
                            backgroundColor: COLORS.black,
                        },
                    },
                    plugins: {
                        legend: false,
                        tooltip: false,
                        datalabels: {
                            color: COLORS.black,
                            font: { weight: 700 },
                            clamp: true,
                            offset: 10,
                            align: getDataLabelPosition,
                        }
                    },
                    maintainAspectRatio: false,
                    scales: {
                        y: { display: false },
                        x: {
                            offset: true,
                            border: { dash: [3, 3] }
                        }
                    }
                }
            });
        },
        createLineFilledChart(chartID, values, activeElement, instance) {
            this.destroyChart(instance);
            const chartElement = document.getElementById(chartID);

            const gradientStroke = chartElement.getContext('2d').createLinearGradient(0, 0, 560, 0);
            gradientStroke.addColorStop(0, 'rgba(255, 153, 0, 0)');
            gradientStroke.addColorStop(0.5, 'rgba(255, 153, 0, 0.8)');
            gradientStroke.addColorStop(1, 'rgba(255, 153, 0, 0)');

            const gradientBackground = chartElement.getContext('2d').createLinearGradient(0, 0, 0, 170);
            gradientBackground.addColorStop(0, 'rgba(255, 153, 0, 0.8)');
            gradientBackground.addColorStop(1, 'rgba(255, 153, 0, 0)');

            let gridColors = [];
            let pointRadius = [];

            if (values.length === 1) {
                values = [{ value: '' }, values[0], { value: '' }];
            }

            values.forEach((item) => {
                if (activeElement === item) {
                    gridColors.push('rgba(122, 129, 163, 0.4)');
                    pointRadius.push(12);
                } else {
                    gridColors.push(COLORS.white);
                    pointRadius.push(0);
                }
            });
            
            values = values.map(function (item) {
                return item.value / 100;
            });

            function addSymbolToLabel(value) {
                return '$' + this.getLabelForValue(value);
            }

            return new Chart(chartElement, {
                type: 'line',
                data: {
                    labels: values,
                    datasets: [{ data: values }]
                },
                options: {
                    plugins: {
                        legend: false,
                        tooltip: false 
                    },
                    events: [],
                    elements: {
                        line: {
                            backgroundColor: gradientBackground,
                            borderColor: gradientStroke,
                            borderWidth: 4,
                            fill: 'origin',
                            tension: 0.4
                        },
                        point: {
                            borderWidth: 9,
                            borderColor: '#fff6d8',
                            backgroundColor: '#FF9900',
                            radius: pointRadius,
                        }
                    },
                    animation: false,
                    maintainAspectRatio: false,
                    scales: {
                        y: { display: false },
                        x: {
                            ticks: {
                                maxTicksLimit: 7,
                                color: COLORS.black,
                                font: {
                                    weight: 700,
                                    size: 14,
                                },
                                callback: addSymbolToLabel,
                            },
                            border: {
                                display: false,
                                dash: [3, 2],
                            },
                            grid: {
                                color: gridColors,
                            }
                        }
                    }
                }
            });
        },
        createBlueLineChart(chartID, values, instance) {
            this.destroyChart(instance)
            const chartElement = document.getElementById(chartID);

            const gradientStroke = chartElement.getContext('2d').createLinearGradient(0, 0, 590, 0);
            gradientStroke.addColorStop(0, 'rgba(216, 216, 216, 0)');
            gradientStroke.addColorStop(0.5, '#000000');
            gradientStroke.addColorStop(1, 'rgba(216, 216, 216, 0)');

            return new Chart(chartElement, {
                type: 'line',
                data: {
                    labels: values,
                    datasets: [{ data: values }],
                },
                options: {
                    plugins: {
                        legend: false,
                        tooltip: false,
                    },
                    elements: {
                        line: {
                            borderColor: gradientStroke,
                            borderWidth: 4,
                            tension: 0.4,
                        },
                        point: {
                            radius: 0
                        },
                    },
                    animation: false,
                    maintainAspectRatio: false,
                    scales: {
                        y: {
                            ticks: {
                                maxTicksLimit: 3,
                                color: '#666666',
                                padding: 20
                            },
                            border: {
                                display: false,
                                dash: [3, 2],
                            },
                        },
                        x: { display: false }
                    }
                }
            });
        },
    };
}
