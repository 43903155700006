import { useEffect } from 'react';

import {
  checkoutService,
  pathQueryParams,
  PAYMENT_RESULT_TYPE,
  signUpSubscriptionsService,
  useSelectSubscription,
  UseSelectSubscriptionsReturn,
} from '../../../payment';
import { getCurrentUrl, goTo } from '../../../shared/utils/angularUtils';

type UseSignUpSubscriptionsReturn = UseSelectSubscriptionsReturn & {
  handleSubmit: () => void;
};

export const useSignUpSubscriptions = (
  entityId: number,
  customerEmail: string,
): UseSignUpSubscriptionsReturn => {
  const onApplyPromoCode = async (promoCode: string) => {
    return signUpSubscriptionsService.applyPromoCode(customerEmail, entityId, promoCode);
  };

  const selectSubscriptionInfo = useSelectSubscription({ onApplyPromoCode });
  const { selectedSubscription, isFreeAccessSubscription, setSubscriptions } =
    selectSubscriptionInfo;

  const getSubscriptions = async () => {
    const response = await signUpSubscriptionsService.getSubscriptions(
      entityId,
      customerEmail,
    );

    setSubscriptions(response);
  };

  useEffect(() => {
    getSubscriptions();
  }, []);

  const handleSubmit = async () => {
    if (!selectedSubscription || !entityId || !customerEmail) {
      throw new Error('Not all required data is set');
    }

    const currentUrl = getCurrentUrl();

    if (isFreeAccessSubscription) {
      const result = await checkoutService.createFreeSubscription(
        entityId,
        customerEmail,
        selectedSubscription.id,
      );

      goTo('/payment-result', {
        params: {
          [pathQueryParams.redirectStatus]: 'succeeded',
          [pathQueryParams.subscriptionType]: selectedSubscription.subscriptionType,
          [pathQueryParams.paymentTimeEpoch]: result.paymentTimeEpoch,
          [pathQueryParams.senderName]: result.senderName,
          [pathQueryParams.paymentResultType]: PAYMENT_RESULT_TYPE.REGISTRATION,
        },
      });
    } else {
      goTo('/checkout', {
        params: {
          [pathQueryParams.entityId]: entityId,
          [pathQueryParams.email]: customerEmail,
          [pathQueryParams.selectedSubscriptionId]: selectedSubscription.id,
          [pathQueryParams.retryPath]: currentUrl,
          [pathQueryParams.paymentResultType]: PAYMENT_RESULT_TYPE.REGISTRATION,
        },
      });
    }
  };

  return {
    ...selectSubscriptionInfo,
    handleSubmit,
  };
};
