import React from 'react';

import { StatusCell } from '../StatusCell/StatusCell';
import { FreeAccessMember } from '../../../../../../interfaces/freeAccess';

type MemberRowProps = {
  member: FreeAccessMember;
  onDelete: (memberId: number) => void;
};

const MemberRow = ({ member, onDelete }: MemberRowProps) => {
  const handleDelete = () => {
    onDelete(member.id);
  };

  return (
    <tr>
      <td>{member.email}</td>
      <StatusCell status={member.status} onDeleteClick={handleDelete} />
    </tr>
  );
};

export { MemberRow };
