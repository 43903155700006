import React from 'react';

import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';

import { InbodyKeysModalForm } from './InbodyKeysModalForm/InbodyKeysModalForm';
import { LbModal } from '../../../../../shared/components/modals/LbModal/LbModal';
import { inbodyKeysStore } from '../../../../stores/inbodyKeysStore';

const InbodyKeysModal = observer(() => {
  const { entities, isAddModalOpened, addKey, changeModalOpenState } = inbodyKeysStore;
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({ mode: 'onBlur', shouldUnregister: true });

  const onFormSubmit = (data) => {
    changeModalOpenState();
    addKey(data);
  };

  return (
    <LbModal
      open={isAddModalOpened}
      title="Add new inbody key"
      primaryBtnConfig={{
        text: 'Add new',
        onClick: handleSubmit(onFormSubmit),
        startIcon: <AddIcon />,
        disabled: !isValid,
      }}
      secondaryBtnConfig={{ onClick: changeModalOpenState }}
    >
      <InbodyKeysModalForm control={control} entities={entities} />
    </LbModal>
  );
});

export { InbodyKeysModal };
