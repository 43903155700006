const autoNgTemplateLoaderTemplate1 = require('./customer-setup-step-4.template.html');

'use strict';

angular
    .module('app')
    .directive('customerSetupStep4', customerSetupStep4);

customerSetupStep4.$inject = [];

function customerSetupStep4() {
    return {
        restrict: 'E',
        scope: {
            createUser: '=',
            goals: '=',
            userGoal: '=',
            mealScript: '=',
            activityIndices: '=',
            next: '&onNext'
        },
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: 'CustomerSetupStep4Controller',
        controllerAs: 'vm'
    };
}
