/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */

export const getReceptionsSupplements = (schedule) =>
  schedule.reduce(
    (receptions, [_, scheduleByHour]) => {
      scheduleByHour.forEach((batch) => {
        receptions.total++;

        if (batch.status) {
          receptions.success++;
        }
      });
      return receptions;
    },
    { total: 0, success: 0 },
  );
