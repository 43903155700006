import { Tick } from 'chart.js';

export const getShowFirstAndLastLabelsParser =
  (parser: (value: number | string) => string) =>
  (tickValue: string | number, index: number, ticks: Tick[]) => {
    const isFirst = index === 0;
    const isLast = index === ticks.length - 1;

    return isFirst || isLast ? parser(tickValue) : '';
  };
