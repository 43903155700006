'use strict';

import { getLoginRequiredResolveObj } from '../shared/utils/angularUtils';

export const nutritionModule = angular
  .module('app.scholastic', [])
  .config(nutritionConfig);

nutritionConfig.$inject = ['$routeProvider'];

function nutritionConfig($routeProvider) {
  $routeProvider
    .when('/scholastic-setup', {
      template: `<ngz-scholastic-user-setup></ngz-scholastic-user-setup>`,
      resolve: getLoginRequiredResolveObj(),
    })
    .when('/scholastic-setup/:id', {
      template: `<ngz-scholastic-user-setup></ngz-scholastic-user-setup>`,
      resolve: getLoginRequiredResolveObj(),
    })
    .when('/scholastic-customers-list', {
      template: `<ngz-scholastic-customer-list></ngz-scholastic-customer-list>`,
      resolve: getLoginRequiredResolveObj(),
    })
}
