import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import classes from './DescriptionRow.module.scss';

const DescriptionRow = ({ color, descriptionText }) => {
  const colorBoxClasses = classNames(classes.colorBox, classes[color]);

  return (
    <div className={classes.infoRow}>
      <div className={colorBoxClasses} />
      <p>{descriptionText}</p>
    </div>
  );
};

DescriptionRow.propTypes = {
  color: PropTypes.oneOf(['green', 'blue']).isRequired,
  descriptionText: PropTypes.string,
};

export { DescriptionRow };
