import React from 'react';

import classNames from 'classnames';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { Moment } from 'moment';

import classes from './RenderDay.module.scss';
import { dateFormat } from '../../../../../../../shared/constants/apiDateFormat';

export type RenderDayProps = {
  color: 'green' | 'blue';
  successfulDays?: string[];
};

const RenderDay = ({
  successfulDays = [],
  color,
  ...props
}: PickersDayProps<Moment> & RenderDayProps) => {
  const isGoodDay = successfulDays.some((day) => day === props.day.format(dateFormat));

  const pickersClasses = classNames(classes[color], {
    [classes.selected]: props.selected,
    [classes.goodDay]: isGoodDay,
  });

  return <PickersDay {...props} className={pickersClasses} />;
};

export { RenderDay };
