import React from 'react';

import { Radio } from '@mui/material';
import classNames from 'classnames';

import classes from './SubscriptionCard.module.scss';
import { InitialPaymentInfo } from './InitialPaymentInfo/InitialPaymentInfo';
import { RenewalPaymentInfo } from './RenewalPaymentInfo/RenewalPaymentInfo';
import { CustomerSubscription } from '../../interfaces/customerSubscription';
import { DISCOUNT_TYPE } from '../../constants/discountType';
import { DiscountChip } from '../DiscountChip/DiscountChip';

type SubscriptionCardProps = {
  subscription: CustomerSubscription;
  discountPercent?: number;
  checked: boolean;
  onClick: (subscriptionId: number) => void;
};

const SubscriptionCard = ({
  subscription,
  discountPercent,
  checked,
  onClick,
}: SubscriptionCardProps) => {
  const containerClasses = classNames(classes.container, {
    [classes.checkedContainer]: checked,
  });

  const shouldShowDiscount = subscription.discountType === DISCOUNT_TYPE.PROMOCODE;

  const handleClick = () => {
    onClick(subscription.id);
  };

  return (
    <div className={containerClasses} onClick={handleClick}>
      <Radio value={subscription.id} />
      <div className={classes.subscriptionInfoContainer}>
        <div className={classes.titleContainer}>
          <h5 className={classes.title}>{subscription.name}</h5>
          <DiscountChip show={shouldShowDiscount} discountPercent={discountPercent} />
        </div>
        <InitialPaymentInfo
          price={subscription.initialPlanPrice}
          finalPrice={subscription.initialPlanPriceFinal}
          initialPlanDuration={subscription.initialPlanDuration}
        />
        <RenewalPaymentInfo
          monthlyRenewalPrice={subscription.monthlyRenewalPrice}
          monthlyRenewalPriceFinal={subscription.monthlyRenewalPriceFinal}
        />
      </div>
    </div>
  );
};

export { SubscriptionCard };
