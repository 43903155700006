import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';

import { MedicalTestForm } from '../MedicalTestForm/MedicalTestForm';
import { MedicalTestCard } from '../MedicalTestCard/MedicalTestCard';
import { useModalToggle } from '../../../../../../shared/hooks/useModalToggle';
import { customerMedicalTestsStore } from '../../../../../stores/customerMedicalTestsStore';

const CardBox = observer(({ test, testIndex }) => {
  const [isEditTest, toggleIsEditTest] = useModalToggle();
  const [isInitialEdit, setIsInitialEdit] = useState(false);

  const { medTestResultIdForInitialEdit, sendTest, setIsFormOpen, deleteTest } =
    customerMedicalTestsStore;

  useEffect(() => {
    if (medTestResultIdForInitialEdit) {
      initialEditHandler();
    }
  }, []);

  const initialEditHandler = () => {
    const isOpenEditMode = getIsOpenEditMode();

    if (isOpenEditMode) {
      handleEditTest();
      setIsInitialEdit(true);
    }
  };

  const getIsOpenEditMode = () => {
    return test.results.some((result) => result.id === medTestResultIdForInitialEdit);
  };

  const handleSubmit = async (testData) => {
    await sendTest(testData, testIndex);
    toggleIsEditTest();
  };

  const handleEditTest = () => {
    toggleIsEditTest();
    setIsFormOpen(true);
  };

  const handelCancel = () => {
    setIsFormOpen(false);
    toggleIsEditTest();
  };

  const handleDeleteTest = () => deleteTest(test.testID, testIndex);

  const MedicalTestFormComponent = (
    <MedicalTestForm
      defaultValues={test}
      onCancel={handelCancel}
      onSubmit={handleSubmit}
      isInitialEdit={isInitialEdit}
    />
  );

  const MedicalTestCardComponent = (
    <MedicalTestCard
      testName={test.testName}
      results={test.results}
      onDeleteTest={handleDeleteTest}
      onEditTest={handleEditTest}
      key={test.testID}
    />
  );

  return isEditTest ? MedicalTestFormComponent : MedicalTestCardComponent;
});

CardBox.propTypes = {
  testIndex: PropTypes.number,
  test: PropTypes.shape({
    testName: PropTypes.string,
    testID: PropTypes.number,
    results: PropTypes.array,
  }),
};

export { CardBox };
