'use strict';

angular.module('app').controller('CustomerSetupStep3Controller', CustomerSetupStep3Controller);

CustomerSetupStep3Controller.$inject = ['$scope', 'VideoService'];

function CustomerSetupStep3Controller($scope, VideoService) {
    var vm = this;
    vm.createUser = $scope.createUser;
    vm.prePostWorkout = $scope.prePostWorkout;

    vm.loadVideo = loadVideo;

    function loadVideo() {
        VideoService.getAllVideos().then(function (res) {
            if (res.status === 200) {
                for (var i in res.data.categories) {
                    var currentCategory = res.data.categories[i];
                    var sortedVideos = currentCategory.videos.sort(VideoService.compareVideos);

                    if (currentCategory.name === 'Explainer videos') {
                        vm.explainerVideo = sortedVideos;
                    }
                }
            }
        })
    }
}
