import { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import {
  getDaysAgo,
  getFormattedDate,
  getFormattedToday,
  isDayAfter,
} from '../../../../shared/utils/dateUtils';
import { complianceService } from '../../../services/complianceService';
import { useSort } from '../../../../shared/hooks/useSort';

export const useCustomerComplianceHistory = ({
  customerId,
  customerRegistrationDate,
}) => {
  const [averageComplianceInfo, setAverageComplianceInfo] = useState(null);
  const [complianceHistory, setComplianceHistory] = useState([]);
  const [consumptionInfo, setConsumptionInfo] = useState(null);
  const [isShowConsumptionWidget, setShowConsumptionWidget] = useState(false);
  const { sortedData: sortedComplianceHistory, sortMethods } = useSort({
    data: complianceHistory,
    sortTypeConfig: { date: 'DATE' },
    dateFormat: 'MM.DD.YYYY',
    defaultSortInfo: { fieldName: 'date', direction: 'DESC' },
  });

  const getStartDate = () => {
    const dayOneWeekAgo = getDaysAgo(7);

    if (customerRegistrationDate) {
      const isRegisteredThisWeek = isDayAfter(customerRegistrationDate, dayOneWeekAgo);

      const startDate = isRegisteredThisWeek ? customerRegistrationDate : dayOneWeekAgo;
      return getFormattedDate(startDate);
    }

    return getFormattedDate(dayOneWeekAgo);
  };

  const defaultValues = {
    endDate: getFormattedToday(),
    startDate: getStartDate(),
  };

  const formMethods = useForm({ defaultValues });
  const { handleSubmit, getValues } = formMethods;

  const getCustomerComplianceHistory = async (params) => {
    const { avg, items } = await complianceService.getCustomerComplianceHistory(
      customerId,
      params,
    );

    setAverageComplianceInfo(avg);
    setComplianceHistory(items);
  };

  const getConsumptionInfo = async (consumptionParams) => {
    const info = await complianceService.getCustomerConsumptionInfo(
      customerId,
      'DATE',
      consumptionParams,
    );

    setConsumptionInfo(info);
  };

  const handleOpenConsumptionWidget = async () => {
    const data = getValues();
    await getConsumptionInfo(data);

    setShowConsumptionWidget(true);
  };

  const handleCloseConsumptionWidget = () => {
    setConsumptionInfo(null);
    setShowConsumptionWidget(false);
  };

  const toggleShowConsumptionWidget = () => {
    if (isShowConsumptionWidget) {
      handleCloseConsumptionWidget();
    } else {
      handleOpenConsumptionWidget();
    }
  };

  useEffect(() => {
    getCustomerComplianceHistory(defaultValues);
  }, []);

  const onSubmit = (data) => {
    getCustomerComplianceHistory(data);
    if (isShowConsumptionWidget) {
      getConsumptionInfo(data);
    }
  };

  const complianceFields = {
    sortedComplianceHistory,
    averageComplianceInfo,
    sortMethods,
  };
  const consumptionFields = {
    consumptionInfo,
    isShowConsumptionWidget,
    toggleShowConsumptionWidget,
  };

  return {
    formMethods,
    handleSubmit: handleSubmit(onSubmit),
    complianceFields,
    consumptionFields,
  };
};
