(function () {
  'use strict';

  angular.module('app.entityProfile').controller('TaxController', TaxController);
  TaxController.$inject = ['$scope', 'EntityService', 'ModalService'];

  function TaxController($scope, EntityService, ModalService) {
    const vm = this;
    vm.spinner = false;
    vm.entityId = $scope.entityId;
    vm.tax = $scope.tax;

    vm.onSubmit = onSubmit;

    function onSubmit() {
      vm.spinner = true;
      EntityService.setTax(vm.entityId, vm.tax)
        .then(res => {
          if (res.status === 200) {
            $scope.tax = res.data;
            ModalService.savedChanges();
          }
        })
        .finally(() => vm.spinner = false);
    }
  }
})();