import React, { ReactNode } from 'react';
import { Button } from '@mui/material';
import { useToggle } from 'react-use';

type ShowMoreContainerProps<T> = {
  items: T[];
  filter: (value: T, index: number, array: T[]) => boolean;
  renderItem: (item: T) => ReactNode;
  showMoreText: string;
  showLessText: string;
  className?: string;
};

/**
 * Use to show less/more items based on filtering
 * Should show all items and hide button when after filtering short list is empty or already contains all items
 */
const ShowMoreContainer = <T,>({
  items,
  filter,
  renderItem,
  showMoreText,
  showLessText,
  className,
}: ShowMoreContainerProps<T>) => {
  const [showMore, toggleShowMore] = useToggle(false);

  const shortList = items.filter(filter);

  const moreItemsExist = items.length > shortList.length;
  const shortListIsEmpty = !shortList.length;

  const shouldShowButton = moreItemsExist && !shortListIsEmpty;

  const itemsToRender = showMore || shortListIsEmpty ? items : shortList;

  return (
    <div className={className}>
      {itemsToRender.map(renderItem)}
      {shouldShowButton && (
        <Button onClick={toggleShowMore}>{showMore ? showLessText : showMoreText}</Button>
      )}
    </div>
  );
};

export { ShowMoreContainer, ShowMoreContainerProps };
