import React from 'react';

import { observer } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';

import { useStripeConnectedInfo } from './hooks/useStripeConnectedInfo';
import { StripeSetCardModal } from '../../../../../shared/components/stripe';
import { stripeService } from '../../../../services/stripeService';

type UpdateCardModalProps = {
  customerId: number;
  entityId: number;
  onClose: () => void;
};

const UpdateCardModal = observer(
  ({ customerId, entityId, onClose }: UpdateCardModalProps) => {
    const stripeConnectedInfo = useStripeConnectedInfo(entityId);

    const handleUpdateCard = async (paymentMethodId: string) => {
      onClose();

      await stripeService.updateCustomerCard(customerId, paymentMethodId);
      enqueueSnackbar('The card has been updated', {
        variant: 'success',
        autoHideDuration: 5000,
      });
    };

    return (
      <StripeSetCardModal
        open={!!stripeConnectedInfo}
        {...stripeConnectedInfo}
        title="Update Card"
        onClose={onClose}
        onSubmit={handleUpdateCard}
      />
    );
  },
);

export { UpdateCardModal };
