import React from 'react';

import { LbChip } from '../../../../../shared/components/ui-components';

type AccountStatusChipProps = {
  isConnected: boolean;
};

const AccountStatusChip = ({ isConnected }: AccountStatusChipProps) => {
  if (isConnected) {
    return <LbChip label="Contact is created" color="success" size="medium" />;
  } else {
    return <LbChip label="Contact is not created" color="error" size="medium" />;
  }
};

export { AccountStatusChip };
