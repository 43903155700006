import React from 'react';

import { angularize } from 'react-in-angularjs';
import { Button } from '@mui/material';

import classes from './NgzInbodyIndicatorsOptions.module.scss';
import { OptionsMenu } from '../../../../../shared/components/menu-components';
import { SystemProviders } from '../../../../../shared/providers';
import { MenuOptions } from '../../../../../general-types';

type NgzInbodyIndicatorsOptionsProps = {
  showScanSummary: boolean;
  onScanSummary: () => void;
  onPrintReport: () => void;
  onCompareScanMetrics: () => void;
};

const NgzInbodyIndicatorsOptions = ({
  showScanSummary,
  onScanSummary,
  onPrintReport,
  onCompareScanMetrics,
}: NgzInbodyIndicatorsOptionsProps) => {
  const menuOptions: MenuOptions[] = [
    { id: 1, label: 'Compare Scan Metrics', onClick: onCompareScanMetrics },
    { id: 2, label: 'Print Reports', onClick: onPrintReport },
  ];
  return (
    <SystemProviders>
      <div className={classes.box}>
        {showScanSummary && (
          <Button variant="outlined" onClick={onScanSummary}>
            Scan Summary
          </Button>
        )}
        <OptionsMenu options={menuOptions} />
      </div>
    </SystemProviders>
  );
};

angularize(
  NgzInbodyIndicatorsOptions,
  'ngzInbodyIndicatorsOptions',
  angular.module('app.inbody'),
  {
    showScanSummary: '<',
    onScanSummary: '&',
    onPrintReport: '&',
    onCompareScanMetrics: '&',
  },
);

export { NgzInbodyIndicatorsOptions };
