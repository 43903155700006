import React, { useMemo } from 'react';

import classes from './NutrientMetricsWidget.module.scss';
import { InfoBox } from '../../../../../../../shared/components/ui-components';
import { TitleWithDescription } from '../../../../../../../shared/components/text-formation-components';

import { HeaderRow } from './HeaderRow/HeaderRow';
import { getEnumArray } from '../../../../../../../shared/utils/jsUtils';
import { MealRow } from './MealRow/MealRow';
import { processMealName } from '../../../../../../../shared/utils/mealUtils';
import {
  REGULAR_MEAL_NAME,
  RegularMealName,
} from '../../../../../../../shared/constants/mealNames';
import { NutrientMetrics } from '../../../../../../interfaces/performance-metrics/NutrientMetrics';
import { getNutrientMetricsGroupByMeal } from './utils/getNutrientMetricsGroupByMeal';
import { AvgMealRow } from './AvgMealRow/AvgMealRow';

const mealsList: RegularMealName[] = getEnumArray(REGULAR_MEAL_NAME);

type NutrientMetricsWidgetProps = {
  nutrientMetrics: NutrientMetrics;
};

const NutrientMetricsWidget = ({ nutrientMetrics }: NutrientMetricsWidgetProps) => {
  const nutrientMetricsGroupByMeal = useMemo(
    () => getNutrientMetricsGroupByMeal(nutrientMetrics),
    [nutrientMetrics],
  );

  return (
    <InfoBox
      title={
        <TitleWithDescription
          title="Nutrient Metrics"
          description="Average % of goal tracked meals"
          titleFont="title-16-bold"
          withoutGap
        />
      }
      customContentPadding
    >
      <div className={classes.nutrientTable}>
        <HeaderRow />
        {mealsList.map((meal) => (
          <MealRow
            key={meal}
            title={processMealName(meal)}
            mealInfo={nutrientMetricsGroupByMeal[meal]}
          />
        ))}
        <AvgMealRow avgInfo={nutrientMetricsGroupByMeal.avgOnMealsTracked} />
      </div>
    </InfoBox>
  );
};

export { NutrientMetricsWidget };
