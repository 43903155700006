import { useEffect } from 'react';

import { useToggle } from 'react-use';

import { setupFinalizationChecklistService } from '../../../services/setupFinalizationChecklistService';

type UseSetupFinalizationChecklistReturn = {
  isShowWidget: boolean;
  handleFinishChecklist: () => void;
};

export const useSetupFinalizationChecklist = (
  customerId: number,
): UseSetupFinalizationChecklistReturn => {
  const [isShowWidget, toggleIsShowWidget] = useToggle(false);

  const getIsShowChecklist = async () => {
    const { showChecklist } =
      await setupFinalizationChecklistService.getIsShowChecklist(customerId);
    toggleIsShowWidget(showChecklist);
  };

  const handleFinishChecklist = async () => {
    const { showChecklist } =
      await setupFinalizationChecklistService.finishChecklist(customerId);

    toggleIsShowWidget(showChecklist);
  };

  useEffect(() => {
    getIsShowChecklist();
  }, []);

  return {
    isShowWidget,
    handleFinishChecklist,
  };
};
