import React from 'react';

import { useForm } from 'react-hook-form';
import { angularize } from 'react-in-angularjs';
import { Button, Container, Paper } from '@mui/material';
import axios, { AxiosError } from 'axios';

import classes from './NgzSignIn.module.scss';
import { SystemProviders } from '../../../shared/providers';
import { LbInput, PasswordInput } from '../../../shared/components/form-components';
import loginPicture from '../../assets/image/login-picture.svg';
import { authService } from '../../services/authService';
import { analyticService } from '../../../shared/services/analiticService';
import { RESPONSE_STATUSES } from '../../../shared/constants/RESPONSE_STATUSES';
import { openConfirmationModal } from '../../../shared/features/confirmation';
import { LoggedInUser } from '../../../shared/interfaces/LoggedInUser';
import { apiStatusStore } from '../../../shared/stores/apiStatusStore';

type FormData = {
  email: string;
  password: string;
};

const NgzSignIn = () => {
  const { control, handleSubmit, setError } = useForm<FormData>({
    mode: 'all',
  });

  const onSubmit = async (data: FormData) => {
    try {
      const user = await authService.signIn(data);

      if ('id' in user) {
        pushUserDataToAnalytic(user);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        switch (error.response?.status) {
          case RESPONSE_STATUSES.UNAUTHORIZED:
            handleUnauthorizedError(error);
            break;
          case RESPONSE_STATUSES.SESSION_EXIST:
            handleSessionExistError(error, data);
            break;

          default:
            apiStatusStore.addErrorMessage(error.response?.data?.message);
        }
      }
    }
  };

  const handleUnauthorizedError = (error: AxiosError<any>) => {
    const errorMessage = error.response?.data?.message;

    if (errorMessage === 'User not found') {
      setError('email', {
        type: 'manual',
        message: 'Not existing email/login or wrong password',
      });
    } else {
      apiStatusStore.addErrorMessage(errorMessage);
    }
  };

  const handleSessionExistError = (error: AxiosError<any>, loginInfo: FormData) => {
    const { title, message } = error.response?.data || {};

    const handleForceLogin = () => {
      const params = { deleteOtherSessions: true };
      authService.signIn(loginInfo, params);
    };

    openConfirmationModal({
      title,
      text: message,
      confirmBtnConfig: {
        onClick: handleForceLogin,
        text: 'Force Login',
      },
    });
  };

  const pushUserDataToAnalytic = (user: LoggedInUser) => {
    analyticService.pushData({
      event: 'setUserId',
      user_id: JSON.stringify(user.id),
    });
    analyticService.pushData({
      event: 'login-complete',
      'user-id': user.id,
      'user-type': user.role,
    });
    analyticService.pushProperty({
      event: 'addUserProperties',
      userPropertyId: 'd-v1-up2',
      userPropertyName: 'user_role',
      userPropertyValue: user.role,
    });
  };

  return (
    <SystemProviders>
      <Container className={classes.container}>
        <Paper variant="roundedBox" className={classes.paper}>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
            <h2 className={classes.title}>Sign In</h2>
            <p className={classes.description}>
              We miss you, please enter account credentials
            </p>
            <div className={classes.inputsWrapper}>
              <LbInput
                control={control}
                label="Email/Login"
                name="email"
                rules={{ required: 'Email/Login is required' }}
              />
              <PasswordInput
                control={control}
                label="Password"
                name="password"
                rules={{ required: 'Password is required' }}
                omitValidation
              />
            </div>
            <Button
              className={classes.button}
              variant="contained"
              fullWidth
              type="submit"
            >
              Sign In
            </Button>
            <div className={classes.resetBox}>
              <span>Forgot your password?</span>
              <Button
                variant="text"
                color="primary"
                size="small"
                href="#!/reset-password"
              >
                Reset Password
              </Button>
            </div>
          </form>
          <img className={classes.image} src={loginPicture} alt="" />
        </Paper>
      </Container>
    </SystemProviders>
  );
};

angularize(NgzSignIn, 'ngzSignIn', angular.module('app.authManagement'), {});

export default NgzSignIn;
