import React from 'react';

import { observer } from 'mobx-react';

import classes from './PriceInfo.module.scss';
import { getPriceView } from '../../../../../shared/utils/stringUtils';
import { checkoutStore } from '../../../../stores/checkoutStore';
import { CurrentPrice } from './CurrentPrice/CurrentPrice';
import { DiscountDescription } from './DiscountDescription/DiscountDescription';

const PriceInfo = observer(() => {
  const { checkoutInfo, subscriptionTypeFlags } = checkoutStore;

  if (!checkoutInfo) {
    return null;
  }

  const { subscription, promocode } = checkoutInfo;

  const isMonthlyDiscountUsed =
    subscription.monthlyRenewalPriceFinal !== subscription.monthlyRenewalPrice &&
    promocode;

  return (
    <div className={classes.container}>
      <p className={classes.title}>Subscribe to {subscription.name}</p>
      {subscriptionTypeFlags.REACTIVATION && (
        <CurrentPrice
          price={subscription.monthlyRenewalPriceFinal}
          description="per month"
        />
      )}
      {subscriptionTypeFlags.REGULAR && (
        <div>
          <CurrentPrice
            price={subscription.initialPlanPriceFinal}
            description={`initial payment for ${subscription.initialPlanDuration} months`}
          />
          <p className={classes.title}>
            Then {getPriceView(subscription.monthlyRenewalPriceFinal)}/month
            {isMonthlyDiscountUsed && (
              <span>
                ,&nbsp;
                <DiscountDescription
                  discountPercent={promocode.discountPercent}
                  oldPrice={subscription.monthlyRenewalPrice}
                />
              </span>
            )}
          </p>
        </div>
      )}
    </div>
  );
});

export { PriceInfo };
