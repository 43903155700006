'use strict';

angular.module('app.entityProfile').factory('SubscriptionsService', SubscriptionsService);

SubscriptionsService.$inject = ['ApiService', 'constants'];

function SubscriptionsService(ApiService) {
  const baseUrl = 'api/subscription/';
  const service = {
    getSubscriptions: function (entityID) {
      return ApiService.get(`${baseUrl}entity/${entityID}`).then(function (res) {
        return res;
      }, function (err) {
        return err;
      });
    },
    getSubscriptionTypes: function (entityID) {
      return ApiService.get(baseUrl + 'types', {entityID}).then(function (res) {
        return res;
      }, function (err) {
        return err;
      });
    },
    addSubscription: function (entityID, data) {
      return ApiService.post(baseUrl, data, {entityID}).then(function (res) {
        return res;
      }, function (err) {
        return err;
      });
    },
    changeSubscriptionStatus: function (subscriptionID) {
      return ApiService.patch(baseUrl + subscriptionID + '/status').then(function (res) {
        return res;
      }, function (err) {
        return err;
      });
    }
  };

  return service;
}
