import React from 'react';
import PropTypes from 'prop-types';

import { localDataFormat } from '../../../../../../shared/constants/apiDateFormat';
import { getFormattedDate } from '../../../../../../shared/utils/dateUtils';
import {
  OrderCell,
  SupplementCell,
} from '../../../../../pages/customers-dashboard/components/Cells';
import { Row } from '../../../../../pages/customers-dashboard/components/Row/Row';

const OrderRow = ({ date, isShowDate, order }) => {
  const { supplementBrand, supplementName, amount } = order;

  const formattedDate = isShowDate ? getFormattedDate(date, localDataFormat) : ' ';

  return (
    <Row>
      <div>{formattedDate}</div>
      <SupplementCell supplementBrand={supplementBrand} supplementName={supplementName} />
      <OrderCell orderAmount={amount} />
    </Row>
  );
};

OrderRow.propTypes = {
  date: PropTypes.string,
  isShowDate: PropTypes.bool,
  order: PropTypes.shape({
    supplementBrand: PropTypes.string,
    supplementName: PropTypes.string,
    amount: PropTypes.number,
  }),
};

export { OrderRow };
