import { Components } from '@mui/material';

export const MuiMenuTheme: Components['MuiMenu'] = {
  variants: [
    {
      props: { variant: 'menu' },
      style: {
        '& .MuiMenu-paper': {
          borderRadius: '7px',
          padding: '8px',
          boxShadow: 'var(--shadow-main-1)',
          '& .MuiList-root': {
            paddingTop: 0,
            paddingBottom: 0,
          },

          '& .MuiMenuItem-root ': {
            color: 'var(--main-black)',
            font: 'var(--body-14-bold)',
            padding: '10px',
            '&[href]': {
              color: 'var(--main-black) !important',
            },
          },
          '& .MuiMenuItem-root:hover ': {
            color: 'var(--primary-500)',
            backgroundColor: 'var(--warning-50)',
            borderRadius: '5px',
            '&[href]': {
              color: 'var(--primary-500) !important',
              opacity: 1,
            },
          },
          '& .Mui-selected.MuiMenuItem-root': {
            color: 'var(--primary-500)',
            backgroundColor: 'inherit',
            '&[href]': {
              color: 'var(--primary-500) !important',
            },
          },
          '& .Mui-selected.MuiMenuItem-root:hover ': {
            backgroundColor: 'var(--warning-50)',
          },
        },
      },
    },
  ],
};
