import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { getAllOption } from '../settings/getAllOption';
import { getRules } from '../settings/getRules';
import { getSelectorLabel } from '../settings/getSelectorLabel';
import { salesReportStore } from '../../../../../../stores/salesReportStore';
import { reportService } from '../../../../../../services/reportService';

export const useSelector = ({
  selectorSettings,
  parentSelectorSettings = { parameterKey: '', selectorType: '' },
  reportType,
}) => {
  const [options, setOptions] = useState([]);
  const { setValue, control } = useFormContext();

  const { selectorType, selectAllAvailable, parameterKey, paymentType, selectorLabel } =
    selectorSettings;

  const parentValue = useWatch({
    name: parentSelectorSettings.parameterKey,
    defaultValue: null,
  });

  const getOptions = async () => {
    const selectorParams = {
      reportType,
      selectorType,
      parentSelectorType: parentSelectorSettings.selectorType,
      parentResourceId: parentValue,
    };

    try {
      const { data } = await reportService.getReportSelectorOptions(selectorParams);
      processOptions(data);
    } catch (error) {
      salesReportStore.closeDownloadModal();
      throw error;
    }
  };

  const processOptions = (optionsList) => {
    if (selectAllAvailable) {
      const allOption = getAllOption(selectorLabel);
      setOptions([allOption, ...optionsList]);
      setValue(parameterKey, '');
    } else {
      setOptions(optionsList);
      setValue(parameterKey, null);
    }
  };

  useEffect(() => {
    if (!parentSelectorSettings.parameterKey || parentValue) {
      getOptions();
    }
  }, [parentValue]);

  const label = getSelectorLabel(paymentType, selectorLabel);
  const rules = getRules(selectAllAvailable, selectorLabel);
  const isShowSelector = !!options.length;

  return {
    control,
    options,
    label,
    parameterKey,
    rules,
    isShowSelector,
  };
};
