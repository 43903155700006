import React from 'react';

import { angularize } from 'react-in-angularjs';
import { Link } from '@mui/material';
import { observer } from 'mobx-react';

import classes from './NgzEntityStatusInfo.module.scss';
import { SystemProviders } from '../../../shared/providers';
import { useStatusDetails } from './hooks/useStatusDetails';
import { useEntityTaxUpdate } from '../../../payment';
import { getNeedToSetupInfo } from './utils/getNeedToSetupInfo';
import { pathQueryParams } from '../../../shared/constants/pathQueryParams';
import { ENTITY_PROFILE_TAB_NAME } from '../../../shared/constants/tabNames/ENTITY_PROFILE_TAB_NAME';

const NgzEntityStatusInfo = observer(() => {
  const { unconfigured, getStatusDetails } = useStatusDetails();

  useEntityTaxUpdate(() => getStatusDetails());

  const isHideStatusInfo = !unconfigured.length;

  if (isHideStatusInfo) {
    return null;
  }

  const needToSetupInfo = getNeedToSetupInfo(unconfigured);

  return (
    <SystemProviders>
      <div className={classes.box}>
        <p>
          To activate your profile and make your coaching services available to potential
          clients, please {needToSetupInfo}
        </p>
        <Link
          href={`#!/entity-profile?${pathQueryParams.initialTabName}=${ENTITY_PROFILE_TAB_NAME.PAYMENT}`}
          className={classes.link}
        >
          Open Profile
        </Link>
      </div>
    </SystemProviders>
  );
});

angularize(NgzEntityStatusInfo, 'ngzEntityStatusInfo', angular.module('app'), {});

export { NgzEntityStatusInfo };
