import React from 'react';
import PropTypes from 'prop-types';

import {
  GridTableCell,
  GridTableRow,
} from '../../../../shared/components/table-components';
import { BeyondNutrients } from '../BeyondNutrients/BeyondNutrients';

const BeyondInfoRow = ({ mealInfo }) => {
  return (
    <GridTableRow variant="subRow">
      <GridTableCell variant="title">Over | Under</GridTableCell>
      <BeyondNutrients beyond={mealInfo} />
    </GridTableRow>
  );
};

BeyondInfoRow.propTypes = {
  mealInfo: PropTypes.object.isRequired,
};

export { BeyondInfoRow };
