import React from 'react';

import classes from './CompareMetricRow.module.scss';
import {
  GridTableCell,
  GridTableRow,
} from '../../../../../../../shared/components/table-components';
import { getRoundedNumber } from '../../../../../../../shared/utils/mathUtils';

type CompareMetricRowProps = { metric: string; startValue: number; endValue: number };

const CompareMetricRow = ({ metric, startValue, endValue }: CompareMetricRowProps) => {
  return (
    <GridTableRow variant="unstyled" className={classes.row}>
      <GridTableCell variant="title">{metric}</GridTableCell>
      <GridTableCell>{startValue}</GridTableCell>
      <GridTableCell>{endValue}</GridTableCell>
      <GridTableCell>{getRoundedNumber(endValue - startValue, 3)}</GridTableCell>
    </GridTableRow>
  );
};

export { CompareMetricRow };
