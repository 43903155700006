import { apiService } from '../../shared/services/apiService';

const getMealReports = async (userId, date) => {
  const params = { date };

  const resp = await apiService.get(`/api/user/${userId}/meal-report`, { params });

  return resp.data;
};

export const reportService = { getMealReports };
