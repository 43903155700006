import moment from 'moment';

import { PerformanceMetricsParams } from '../../../../../interfaces/performance-metrics/PerformanceMetricsParams';
import {
  getDaysAgo,
  getFormattedDate,
  isDataBefore,
} from '../../../../../../shared/utils/dateUtils';

export const getDefaultDateRange = (
  registrationDate: string,
): PerformanceMetricsParams => {
  const oneDayAgo = getDaysAgo(1);
  const eightDaysAgo = getDaysAgo(8);
  const registrationDateMoment = moment(registrationDate);

  const isRegisteredBeforeStartDay = isDataBefore(registrationDateMoment, eightDaysAgo);

  const startDate = isRegisteredBeforeStartDay ? eightDaysAgo : registrationDateMoment;

  const isRegisteredBeforeEndDay = isDataBefore(registrationDateMoment, oneDayAgo);

  const endDate = isRegisteredBeforeEndDay ? oneDayAgo : registrationDateMoment;

  return {
    dateFrom: getFormattedDate(startDate),
    dateTo: getFormattedDate(endDate),
  };
};
