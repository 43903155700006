import React, { useMemo } from 'react';

import { Paper } from '@mui/material';

import classes from './MealPlan.module.scss';
import { NutrientInfoBlock } from './NutrientInfoBlock/NutrientInfoBlock';
import { MealHeaderRow, MealRow } from '../meal-table-components';
import { REGULAR_MEAL_NAME } from '../../../../../../shared/constants/mealNames';
import { TotalRow } from './TotalRow/TotalRow';
import { WaterSetupBlock } from './WaterSetupBlock/WaterSetupBlock';
import { getEnumArray } from '../../../../../../shared/utils/jsUtils';

type MealPlanProps = {
  nameIndex: number;
  mealsPerDay: number;
};

const MealPlan = ({ nameIndex, mealsPerDay }: MealPlanProps) => {
  // TODO update when change meals per day become available
  const mealsList = useMemo(() => {
    switch (mealsPerDay) {
      case 5:
        return getEnumArray(REGULAR_MEAL_NAME, [REGULAR_MEAL_NAME.snack1]);
      case 6:
      default:
        return getEnumArray(REGULAR_MEAL_NAME);
    }
  }, [mealsPerDay]);

  return (
    <div>
      <Paper variant="roundedBox" className={classes.mealTable}>
        <MealHeaderRow />
        {mealsList.map((meal) => (
          <MealRow
            meal={meal}
            mealFormKey={`mealPlans.${nameIndex}.${meal}`}
            key={meal}
          />
        ))}
        <TotalRow nameIndex={nameIndex} />
      </Paper>
      <NutrientInfoBlock nameIndex={nameIndex} />
      <WaterSetupBlock nameIndex={nameIndex} />
    </div>
  );
};

export { MealPlan };
