import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';
import { useForm } from 'react-hook-form';

import classes from './CreateTest.module.scss';
import { LbInput } from '../../../../../../shared/components/form-components';
import { LbModal } from '../../../../../../shared/components/modals/LbModal/LbModal';
import { medicalTestsStore } from '../../../../../stores/medicalTestsStore';

const CreateTest = ({ isModalOpen, modalHandler }) => {
  const { createTest } = medicalTestsStore;

  const { control, handleSubmit } = useForm({ mode: 'onBlur' });

  const onSubmit = ({ testName }) => {
    createTest(testName);
    modalHandler();
  };

  return (
    <>
      <div className={classes.btnRow}>
        <Button variant="contained" onClick={modalHandler}>
          Create new Lab Test
        </Button>
      </div>
      <LbModal
        open={isModalOpen}
        title="Create New Lab Test"
        onClose={modalHandler}
        primaryBtnConfig={{ text: 'Create', onClick: handleSubmit(onSubmit) }}
        secondaryBtnConfig={{ onClick: modalHandler }}
      >
        <LbInput
          name="testName"
          control={control}
          label="Name of Lab Test"
          rules={{ required: "Name of Lab Test can't be empty" }}
          shouldUnregister
        />
      </LbModal>
    </>
  );
};

CreateTest.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  modalHandler: PropTypes.func.isRequired,
};

export { CreateTest };
