import React from 'react';

import classes from './RenewalPaymentInfo.module.scss';
import { getPriceView } from '../../../../shared/utils/stringUtils';

type RenewalPaymentInfoProps = {
  monthlyRenewalPrice: number | null;
  monthlyRenewalPriceFinal: number | null;
};

const RenewalPaymentInfo = ({
  monthlyRenewalPrice,
  monthlyRenewalPriceFinal,
}: RenewalPaymentInfoProps) => {
  return (
    <div className={classes.container}>
      <p className={classes.title}>Auto-Renew:</p>&nbsp;
      <div className={classes.priceContainer}>
        {monthlyRenewalPrice !== monthlyRenewalPriceFinal && (
          <p className={classes.oldPrice}>{getPriceView(monthlyRenewalPrice)}</p>
        )}
        <p className={classes.description}>
          <span className={classes.price}>{getPriceView(monthlyRenewalPriceFinal)}</span>
          &nbsp;/&nbsp;per month
        </p>
      </div>
    </div>
  );
};

export { RenewalPaymentInfo };
