import React, { ReactNode } from 'react';

import classes from './InputGroup.module.scss';
import { TitleWithDescription } from '../../../../../shared/components/text-formation-components';

type InputGroupProps = {
  title: string;
  description: string;
  children: ReactNode;
};

const InputGroup = ({ title, description, children }: InputGroupProps) => {
  return (
    <div className={classes.groupBox}>
      <TitleWithDescription
        title={title}
        description={description}
        titleFont="body-14-bold"
      />
      <div>{children}</div>
    </div>
  );
};

export { InputGroup };
