import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Button, Paper } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';

import classes from './MedicalTestFormCard.module.scss';
import { HeaderBtnBox } from './HeaderBtnBox/HeaderBtnBox';
import { scrollToAndFocusElement } from '../../../../shared/utils/htmlElementsUtils';
import { Result } from './Result/Result';
import { openConfirmationModal } from '../../../../shared/features/confirmation';

const MedicalTestFormCard = ({
  onDeleteTest,
  nameIndex,
  isProfileCard,
  onSubmit,
  onCancel,
  autoFocusFieldKey,
  focusResultId,
}) => {
  const formCardRef = useRef();

  const { control, register } = useFormContext();

  const formArrName = `${nameIndex}results`;
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: formArrName,
    keyName: 'formId',
  });

  useEffect(() => {
    if (autoFocusFieldKey) {
      autoFocusHandler();
    }
  }, [autoFocusFieldKey]);

  const autoFocusHandler = () => {
    const focusField = formCardRef.current[autoFocusFieldKey];
    scrollToAndFocusElement(focusField);
  };

  const handleAddResult = () => {
    append({ uploadedTestFiles: [] });
  };

  const handleDeleteTest = () => {
    openConfirmationModal({
      title: 'Do you want to delete this Lab Test?',
      text: 'You will lose the added Information',
      confirmBtnConfig: {
        text: 'Delete',
        onClick: onDeleteTest,
      },
    });
  };

  return (
    <Paper
      className={classes.testBox}
      variant="roundedBox"
      component="form"
      ref={formCardRef}
    >
      <div className={classes.cardHeader}>
        <input {...register(`${nameIndex}testName`)} disabled />
        <HeaderBtnBox
          isProfileCard={isProfileCard}
          onDelete={handleDeleteTest}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </div>
      <div className={classes.resultBox}>
        {fields.map((result, index) => (
          <Result
            key={result.formId}
            result={result}
            formArrName={formArrName}
            update={update}
            remove={remove}
            resultIndex={index}
            showDeleteBtn={fields.length > 1}
            focusResultId={focusResultId}
          />
        ))}
      </div>
      <div className={classes.cardFooter}>
        <Button
          className={classes.addTestResultBtn}
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={handleAddResult}
        >
          Add Follow-up Test
        </Button>
      </div>
    </Paper>
  );
};

MedicalTestFormCard.propTypes = {
  onDeleteTest: PropTypes.func,
  nameIndex: PropTypes.string.isRequired,
  isProfileCard: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  autoFocusFieldKey: PropTypes.string,
  focusResultId: PropTypes.number,
};

export { MedicalTestFormCard };
