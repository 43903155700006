'use strict';

angular.module('app').factory('SalesRepsService', SalesRepsService);

SalesRepsService.$inject = ['ApiService', 'ModalService'];

function SalesRepsService(ApiService, ModalService) {
    return {
        getSalesReps: function () {
            return ApiService.get('api/super/salesreps').then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        addSalesReps: function (data) {
            return ApiService.post('api/super/salesreps', data).then(function (res) {
                ModalService.savedChanges();
                return res;
            }, function (err) {
                return err;
            });
        },
        updateSalesReps: function (data) {
            return ApiService.put('api/super/salesreps/' + data.id, {
                name: data.name,
                phone: data.phone,
                email: data.email,
                jobTitle: data.jobTitle
            }).then(function (res) {
                ModalService.savedChanges();
                return res;
            }, function (err) {
                return err;
            });
        },
        deleteSalesReps: function (id) {
            return ApiService.delete('api/super/salesreps/' + id).then(function (res) {
                ModalService.savedChanges();
                return res;
            }, function (err) {
                return err;
            });
        },
        getEntitySalesRepList: function (id) {
            return ApiService.get('api/super/entities/' + id + '/sales-representatives').then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        },
        setEntitySalesRep: function (id, data) {
            return ApiService.post('api/super/entities/' + id + '/salesrep', data).then(function (res) {
                ModalService.savedChanges();
                return res;
            }, function (err) {
                return err;
            });
        },
        deleteEntitySalesRep: function (entityId, salesRepId) {
            return ApiService.delete('api/super/entities/' + entityId + '/salesrep/' + salesRepId).then(function (res) {
                ModalService.savedChanges();
                return res;
            }, function (err) {
                return err;
            });
        },
    }
}