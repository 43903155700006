const autoNgTemplateLoaderTemplate1 = require('./customer-setup.template.html');

'use strict';

(function () {
    angular
        .module('app.customerSetup', [])
        .config(customerSetupConfig);

    customerSetupConfig.$inject = ['$routeProvider'];

    function customerSetupConfig($routeProvider) {
        $routeProvider
            .when('/customer-setup', {
                disableCache: true,
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'CustomerSetupController',
                controllerAs: 'vm',
                resolve: {
                    loginRequired: ['AuthService', function (AuthService) {
                        return AuthService.loginRequired();
                    }]
                }
            })
    }
})();
