'use strict';

import { PAYMENT_FLOW_TYPE } from "../modules/shared/constants/user-info/paymentFlowType";
import { loggedInUserDataStore } from "../modules/shared/stores/loggedInUserDataStore";
import { getEnabledModules, getAvailableModules } from "../modules/shared/utils/paidModulesUtils";
import { getFlagsObject } from "../modules/shared/utils/sortingAndGroupingUtil";


angular.module('app').factory('InitUtil', InitUtil);

InitUtil.$inject = ['PaymentService', 'localStorageService', 'constants', 'utilService', '$rootScope', 'CoachService', 'EntityService'];

function InitUtil(PaymentService, localStorageService, constants, utilService, $rootScope, CoachService, EntityService) {
  const storageKeys = constants.enums.storageKeys;
  const eventKeys = constants.enums.eventKeys;
  const { AGENT, ADMIN } = constants.enums.userRoles;

  return {
    initGeneralAppLogic() {
      const loggedInUser = utilService.getLoggedInUserData();
      // it is not run for super admin because his gym id is 0
      if (loggedInUser?.gymId) {
        return this.initForGeneralUser(loggedInUser);
      }
    },

    initForGeneralUser(loggedInUser) {
      return Promise.all([
        this.setPaymentFlowType(loggedInUser.gymId),
        this.setEnabledModules(loggedInUser.gymId),
        this.setAvailableModules(loggedInUser.entityType),
        this.setComplianceCoachInfo(loggedInUser),
        this.setIsEntityHasChild(loggedInUser),
      ]).then(() => {
        $rootScope.$broadcast(eventKeys.INIT_USER_INFO_FINISHED);
      });
    },

    setPaymentFlowType(entityId) {
      return PaymentService.getPaymentFlowTypeFlags(entityId).then((paymentFlowTypeFlags) => {
          localStorageService.set(storageKeys.PAYMENT_FLOW_TYPE, paymentFlowTypeFlags);
          this.loggedInUserDataStore.setPaymentFlowType(paymentFlowTypeFlags);
         }
       );
    },

    setEnabledModules(entityId) {
      return getEnabledModules(entityId).then(enabledModules => {
        localStorageService.set(storageKeys.ENABLED_MODULES, enabledModules);
        this.loggedInUserDataStore.setEnabledModules(enabledModules);
      });
    },

    setAvailableModules(entityType) {
      return getAvailableModules(entityType).then(availableModules => {
        localStorageService.set(storageKeys.AVAILABLE_MODULES, availableModules);
        this.loggedInUserDataStore.setAvailableModules(availableModules);
      });
    },

    setComplianceCoachInfo(loggedInUser) {
      if (loggedInUser.role !== AGENT) {
        return;
      }

      return CoachService.getCoachAccountSettings(loggedInUser.id).then((res) => {
        if (res.status === 200) {
          const complianceCoach = res.data.additional.complianceCoach;

          localStorage.setItem(storageKeys.COMPLIANCE_COACH, JSON.stringify(complianceCoach));
          this.loggedInUserDataStore.setIsComplianceCoach(complianceCoach);
        }
      });
    },
    
    setIsEntityHasChild(loggedInUser) { 
      if (loggedInUser.role !== ADMIN) {
        return;
      }

      return EntityService.getIsEntityHasChildren(loggedInUser.gymId).then((res) => {
        if (res.status === 200) {
          const isEntityHasChild = res.data;

          localStorageService.set(storageKeys.IS_ENTITY_HAS_CHILD, isEntityHasChild);
          this.loggedInUserDataStore.setIsEntityHasChild(isEntityHasChild);
        }
      });
    },
    loggedInUserDataStore: loggedInUserDataStore,
  }
}
