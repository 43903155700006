import React from 'react';

import { angularize } from 'react-in-angularjs';
import { Paper, Button } from '@mui/material';
import { useToggle } from 'react-use';

import classes from './NgzGoHighLevelWidget.module.scss';
import { SystemProviders } from '../../../shared/providers';
import { ConnectGoHighLevelModal } from './ConnectGoHighLevelModal/ConnectGoHighLevelModal';
import { openConfirmationModal } from '../../../shared/features/confirmation';
import { EditGoHighLevelModal } from './EditGoHighLevelModal/EditGoHighLevelModal';
import { useGoHighLevelConnection } from './hooks/useGoHighLevelConnection';
import { ConnectionManagement } from './ConnectionManagement/ConnectionManagement';
import { TitleSection } from './TitleSection/TitleSection';

type NgzGoHighLevelWidgetProps = {
  entityId: number;
};

const NgzGoHighLevelWidget = ({ entityId }: NgzGoHighLevelWidgetProps) => {
  const [isShowConnectGoHighLevelModal, toggleShowConnectGoHighLevelModal] =
    useToggle(false);
  const [isShowEditGoHighLevelModal, toggleShowEditGoHighLevelModal] = useToggle(false);

  const {
    connectionInfo,
    createConnection,
    updateAccountInfo,
    refreshConnectionInfo,
    removeConnection,
  } = useGoHighLevelConnection(entityId);

  const { isConnected, accountInfo, accountStatusFlags } = connectionInfo;

  const handleRemoveConnection = () => {
    openConfirmationModal({
      title: 'Do you want to remove GoHighLevel integration?',
      confirmBtnConfig: {
        text: 'Confirm',
        onClick: removeConnection,
      },
    });
  };

  return (
    <SystemProviders>
      <Paper variant="boxWithPadding" className={classes.box}>
        <TitleSection connectionInfo={connectionInfo} />
        {!isConnected ? (
          <Button
            variant="contained"
            size="small"
            onClick={toggleShowConnectGoHighLevelModal}
          >
            Connect
          </Button>
        ) : (
          <ConnectionManagement
            accountInfo={accountInfo}
            accountStatusFlags={accountStatusFlags}
            onRefresh={refreshConnectionInfo}
            onEdit={toggleShowEditGoHighLevelModal}
            onRemove={handleRemoveConnection}
          />
        )}
      </Paper>
      {isShowConnectGoHighLevelModal && (
        <ConnectGoHighLevelModal
          onClose={toggleShowConnectGoHighLevelModal}
          onSubmit={createConnection}
        />
      )}
      {isShowEditGoHighLevelModal && (
        <EditGoHighLevelModal
          defaultLocationId={accountInfo?.locationId}
          onClose={toggleShowEditGoHighLevelModal}
          onSubmit={updateAccountInfo}
        />
      )}
    </SystemProviders>
  );
};

angularize(NgzGoHighLevelWidget, 'ngzGoHighLevelWidget', angular.module('app'), {
  entityId: '<',
});

export { NgzGoHighLevelWidget };
