import React from 'react';

import {
  Chart as ChartJS,
  LineController,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Filler,
  Tooltip,
  Title,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { palette } from '../../../../../shared/theme/palette';
import { RevenueDateRangeFilterValues } from '../../DetailedRevenue.settings';
import { useTotalAmountGraphInfo } from './hooks/useTotalAmountGraphInfo';
import { getShowFirstAndLastLabelsParser } from './utils/getShowFirstAndLastLabelsParser';
import { getTooltipLabel } from './utils/getTooltipLabel';
import { drawDashLine } from './utils/drawDashLine';
import { drawPoint } from './utils/drawPoint';
import { drawLabel } from './utils/drawLabel';
import { getPriceView } from '../../../../../shared/utils/stringUtils';

ChartJS.register(
  LineController,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Filler,
  Tooltip, // required for the tooltip plugin
);

type TotalAmountGraphProps = {
  entityId: number;
  dateRange: RevenueDateRangeFilterValues;
};

const TotalAmountGraph = ({ entityId, dateRange }: TotalAmountGraphProps) => {
  const { labels, values, maxAmount } = useTotalAmountGraphInfo(entityId, dateRange);

  const fontBody14Medium = { family: "'Lato', sans-serif", size: 14, weight: 500 };
  const fontCaption10Bold = { family: "'Lato', sans-serif", size: 10, weight: 700 };

  const ticksStyle = {
    color: palette.natural[700],
    font: fontBody14Medium,
  } as const;

  return (
    <div>
      <Line
        data={{
          labels,
          datasets: [
            {
              data: values, // only numbers or string converted to numbers
              label: 'Total Amount ',
              borderColor: palette.info[400],
              backgroundColor: 'rgba(40, 108, 217, 0.06)', // palette.info[400] with 0.06 opacity
              fill: true,
              tension: 0.1, // check if need with big changes
            },
          ],
        }}
        options={{
          animation: false,
          maintainAspectRatio: false,
          elements: {
            point: {
              // hide points on the line, draw custom because chart cut point near to line point on hover when initial radius is 0
              radius: 0,
              hoverRadius: 0,
            },
          },
          // show tooltip on hover
          interaction: {
            intersect: false,
            mode: 'nearest',
          },
          scales: {
            x: {
              display: true,
              grid: { display: false },
              border: { color: palette.natural[300], width: 1 },
              ticks: {
                ...ticksStyle,
                maxRotation: 0,
                autoSkip: false, // show all labels
                align: 'inner',
                callback: getShowFirstAndLastLabelsParser(
                  (value) => labels[value as number],
                ),
                padding: 0,
              },
            },
            y: {
              max: maxAmount,
              beginAtZero: true,
              border: { dash: [3, 3], display: false },
              grid: {
                tickColor: 'transparent', // create space between label and line
                color: palette.natural[300],
              },
              ticks: {
                ...ticksStyle,
                maxTicksLimit: 4,
                callback: getShowFirstAndLastLabelsParser(getPriceView),
              },
            },
          },
          plugins: {
            title: {
              display: true,
              text: 'Total Amount Graph',
              font: fontBody14Medium,
              align: 'start',
              color: palette.mainBlack,
              padding: { bottom: 34 },
            },
            tooltip: {
              displayColors: false, // hide color box on line (datasets)
              backgroundColor: 'rgba(110, 110, 121, 0.84)', // not from palate
              titleFont: fontCaption10Bold,
              bodyFont: fontCaption10Bold,
              caretPadding: 6,
              callbacks: {
                label: getTooltipLabel,
              },
            },
          },
        }}
        plugins={[
          {
            id: 'valueOfPoint',
            // before to avoid draw over the tooltip (label for 0 value)
            beforeTooltipDraw(chart, args) {
              const { ctx, chartArea } = chart;
              const { tooltip } = args;

              const pointX = tooltip.caretX;
              const pointY = tooltip.caretY;

              drawPoint(ctx, {
                pointX,
                pointY,
              });

              drawDashLine(ctx, chartArea, {
                pointX,
                pointY,
                gapFromStart: 6,
              });

              drawLabel(ctx, chartArea, {
                label: tooltip.dataPoints[0].label,
                pointX,
                gapFromChartArea: 17, // control position like general labels
              });
            },
          },
        ]}
      />
    </div>
  );
};

export { TotalAmountGraph };
