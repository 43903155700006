import React from 'react';

import { useFormContext } from 'react-hook-form';
import { InputAdornment } from '@mui/material';
import { observer } from 'mobx-react';

import classes from './BodyCompositionGoalForm.module.scss';
import {
  LbInput,
  InputView,
  LbDatePicker,
  HiddenInput,
} from '../../../../../../shared/components/form-components';
import { TitleWithDescription } from '../../../../../../shared/components/text-formation-components';
import { InfoBox } from '../../../../../../shared/components/ui-components';
import { customerSetupStore } from '../../../../../stores/customerSetupStore';
import { CreateGoalInfo } from '../../../../../interfaces/goal';
import { questionnaireReplyStore } from '../../../../../stores/questionnaireReplyStore';
import { getFormattedToday } from '../../../../../../shared/utils/dateUtils';

const BodyCompositionGoalForm = observer(() => {
  const { control, register } = useFormContext<CreateGoalInfo>();

  const { customerBodyMetrics } = customerSetupStore;
  const { getQuestionReplyByType } = questionnaireReplyStore;
  const { answer } = getQuestionReplyByType('GOAL') || {};

  const generalInputProps = {
    variant: 'standard',
    size: 'small',
    control,
  } as const;

  return (
    <InfoBox title="Body Composition Goal Targets">
      <div className={classes.row}>
        <InputView
          label="Current weight (lbs)"
          value={customerBodyMetrics?.weight}
          endAdornment="lbs"
        />
        <InputView
          label="Current Body fat"
          value={customerBodyMetrics?.percentBodyFat}
          endAdornment="%"
        />
      </div>
      <div className={classes.row}>
        <HiddenInput register={register} name="name" value={answer?.name} />
        <HiddenInput register={register} name="startDate" value={getFormattedToday()} />
        <LbInput
          {...generalInputProps}
          name="targetWeight"
          label="Weight goal"
          type="number"
          rules={{
            required: 'Weight goal is required',
            min: { value: 1, message: 'Weight must be greater than 1' },
            max: { value: 600, message: 'Weight must be less than 600' },
            deps: ['targetBodyFatPercent'], // clear errors on both fields after goal validation
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
          }}
        />
        <LbInput
          {...generalInputProps}
          name="targetBodyFatPercent"
          label="Body fat goal"
          type="number"
          rules={{
            required: 'Body fat goal is required',
            min: { value: 0, message: 'Body fat must be greater than 0' },
            max: { value: 100, message: 'Body fat must be less than 100' },
            deps: ['targetWeight'], // clear errors on both fields after goal validation
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </div>
      <div className={classes.row}>
        <TitleWithDescription
          title="Target Date"
          description="Select a deadline to evaluate the result"
          titleFont="body-14-bold"
        />
        <LbDatePicker
          {...generalInputProps}
          name="targetDate"
          margin="normal"
          rules={{ required: 'Target Date is required' }}
          disablePast
        />
      </div>
    </InfoBox>
  );
});

export { BodyCompositionGoalForm };
