'use strict';

import { getLoginRequiredResolveObj } from '../shared/utils/angularUtils';

export const reportsModule = angular
  .module('app.customerManagement', [])
  .config(reportsConfig);

reportsConfig.$inject = ['$routeProvider'];


function reportsConfig($routeProvider) {
  $routeProvider
    .when('/customer-self-setup', {
      template: '<ngz-customer-self-setup></ngz-customer-self-setup>',
    })
    .when('/customer-self-setup/to-entity', {
      template: '<ngz-customer-self-setup-to-entity></ngz-customer-self-setup-to-entity>',
    })
    .when('/customer-setup/flow-2024/:id', {
      template: '<ngz-customer-setup-flow-2024></ngz-customer-setup-flow-2024>',
      resolve: getLoginRequiredResolveObj(),
    })
}
