import classes from './DayRow.module.scss';
import {
  getFormattedDate,
  getHumanizeDay,
  isToday,
} from '../../../../../../../shared/utils/dateUtils';
import { localTimeFormat } from '../../../../../../../shared/constants/apiDateFormat';

export const getDataToRender = (day, targetGoal) => {
  const { date, lastUpdate, totalScore, consumed } = day;

  const dayClasses = isToday(date) ? classes.todayRow : '';

  const dayText = getHumanizeDay(date);

  const goal = `${consumed} / ${targetGoal} oz`;

  const gap = +(consumed - targetGoal).toFixed(2);

  const lastUpdateTime = lastUpdate
    ? getFormattedDate(lastUpdate, localTimeFormat)
    : '-- --';

  const score = totalScore.toFixed(1);

  return { dayClasses, dayText, goal, gap, lastUpdateTime, score };
};
