'use strict';

import { entityPaymentService } from '../../../modules/shared/services/entity/entityPaymentService';

angular.module('app').controller('LifeBaseFeesController', LifeBaseFeesController);

LifeBaseFeesController.$inject = ['$scope', 'ModalService', 'EntityService', '$location', 'StripeService', 'constants',
    'utilService'];

function LifeBaseFeesController($scope, ModalService, EntityService, $location, StripeService, constants, utilService) {
    const vm = this;
    vm.spinner = $scope.spinner;
    vm.enums = constants.enums;
    vm.ENTITY_TYPES = constants.enums.entityTypes;
    vm.isSuperAdmin = utilService.getIsSuperAdmin();
    vm.isEntityProfile = false;
    vm.disabledFeeScheduleKeys = ['baseSystemPrice', 'marketingModule'];
    vm.defaultLifeBaseFees = {};
    vm.isInbodySelected = false;
    vm.isActiveCalendarAvailable = $scope.isActiveCalendarAvailable;
    vm.isOnlyEnabledAC = false;

    vm.initAtEntityProfile = initAtEntityProfile;
    vm.initAtEntitySetup = initAtEntitySetup;
    vm.setDefaultLifeBaseFees = setDefaultLifeBaseFees;
    vm.runGeneralInitLogic = runGeneralInitLogic;
    vm.setIsOnlyEnabledAC = setIsOnlyEnabledAC;
    vm.setNutritionAndACModules = setNutritionAndACModules;
    vm.checkIsChildEntity = checkIsChildEntity;
    vm.getLifeBaseFees = getLifeBaseFees;
    vm.setUsersFees = setUsersFees;
    vm.setUsersFeesConditionProp = setUsersFeesConditionProp;
    vm.onCheckboxChange = onCheckboxChange;
    vm.setCoachSelector = setCoachSelector;
    vm.showCorporatePlansInfo = showCorporatePlansInfo;
    vm.removeBundle = removeBundle;
    vm.addBundle = addBundle;
    vm.setOneTimePaymentTotal = setOneTimePaymentTotal;
    vm.initFeeScheduleItemsStatuses = initFeeScheduleItemsStatuses;
    vm.getIsInbodySelected = getIsInbodySelected;
    vm.initFeeScheduleSelectedUsersFees = initFeeScheduleSelectedUsersFees;
    vm.setFeeScheduleItemStatus = setFeeScheduleItemStatus;
    vm.getIsNeedTrash = getIsNeedTrash;
    vm.switchEdit = switchEdit;
    vm.onCancelEdit = onCancelEdit;
    vm.getIsInitialLifeBaseFeesChanged = getIsInitialLifeBaseFeesChanged;
    vm.resetLifeBaseFees = resetLifeBaseFees;
    vm.saveLifeBaseFeesChanges = saveLifeBaseFeesChanges;
    vm.getRequestData = getRequestData;
    vm.onSubmit = onSubmit;
    vm.processCard = processCard;
    vm.pay = pay;
    vm.setInbodyTypeAndPaymentBundle = setInbodyTypeAndPaymentBundle;
    vm.changeCreditCardDetails = changeCreditCardDetails;
    vm.setInitialLifeBaseFees = setInitialLifeBaseFees;
    vm.retryExistingCard = retryExistingCard;
    vm.payManually = entityPaymentService.payManually;
    vm.processFeeScheduleDisabledStatuses = processFeeScheduleDisabledStatuses;

    vm.$onInit = function() {
        if ($location.url().includes('entity-profile')) {
            vm.initAtEntityProfile();
        } else {
            vm.initAtEntitySetup();
        }
    }

    async function initAtEntityProfile() {
        await vm.setDefaultLifeBaseFees($scope.entityType);
        vm.entityId = $scope.entityId;
        vm.getLifeBaseFees();
        vm.isEntityProfile = true;
    }

    async function initAtEntitySetup() {
        await vm.setDefaultLifeBaseFees($scope.createEntity.entityType);
        vm.lifeBaseFees = Object.assign($scope.createEntity, vm.defaultLifeBaseFees);
        $scope.$apply();
        vm.entityId = vm.lifeBaseFees.entityID;
        vm.setNutritionAndACModules();
        vm.runGeneralInitLogic();
    }

    async function setDefaultLifeBaseFees(entityType) {
        const entityTypeForBack = utilService.findObjKeyByValue(vm.enums.entityTypes, entityType);
        const params = {
            vm,
            func: EntityService.getDefaultPaymentBundle,
            args: [entityTypeForBack],
        };

        vm.defaultLifeBaseFees = await utilService.getAsyncResult(params);
    }

    function setNutritionAndACModules() {
        // hot fix, need research correct work or rewrite functionality
        if ($scope.createEntity.entityType === vm.ENTITY_TYPES.SCHOLASTIC) {
            return;
        }

        vm.isInbodySelected = vm.getIsInbodySelected();
        const isInbodyTypeSelected = vm.isInbodySelected || vm.lifeBaseFees.allowNonInbodyUsers;
        vm.setFeeScheduleItemStatus('nutritionModule', isInbodyTypeSelected,
          !isInbodyTypeSelected || !vm.isActiveCalendarAvailable);
        vm.setFeeScheduleItemStatus('activeCalendarModule', vm.isActiveCalendarAvailable);

        if (!isInbodyTypeSelected) {
            vm.setFeeScheduleItemStatus('activeCalendarModule', !isInbodyTypeSelected, true);
        }
    }

    function runGeneralInitLogic() {
        vm.checkIsChildEntity();
        vm.initFeeScheduleItemsStatuses();
        vm.setUsersFees();
        vm.setOneTimePaymentTotal();
        vm.lifeBaseFees.feeSchedule.activeCalendarModule.disabled = !vm.isActiveCalendarAvailable;
        vm.setIsOnlyEnabledAC();
    }

    function setIsOnlyEnabledAC() {
        vm.isOnlyEnabledAC = vm.lifeBaseFees.feeSchedule.activeCalendarModule.selected &&
          !vm.lifeBaseFees.feeSchedule.nutritionModule.selected;
    }

    function switchEdit() {
        vm.isEditDisabled = !vm.isEditDisabled;
    }

    function getLifeBaseFees() {
        vm.spinner.active = true;
        EntityService.getPaymentBundle(vm.entityId)
          .then(res => {
              if (res.status === 200) {
                  vm.lifeBaseFees = vm.processFeeScheduleDisabledStatuses(res.data);
                  vm.isInbodySelected = vm.getIsInbodySelected();
                  vm.runGeneralInitLogic();
                  vm.setInitialLifeBaseFees();
                  vm.switchEdit();
              }
          })
          .finally(() => vm.spinner.active = false);
    }

    function processFeeScheduleDisabledStatuses(currentLifeBaseFees) {
        // if some module becomes blocked by default for this type of entity, but this module is selected, makes it possible to exclude it
        Object.entries(vm.defaultLifeBaseFees.feeSchedule).forEach(([feeScheduleKey, feeScheduleItem]) => {
            const currentFee = currentLifeBaseFees.feeSchedule[feeScheduleKey];
            const isDisabled = feeScheduleItem.disabled;
            const isAlreadySelected = currentFee.selected;

            currentFee.disabled = isDisabled && !isAlreadySelected;
        });
        // always locked modules
        vm.disabledFeeScheduleKeys.forEach(key => currentLifeBaseFees.feeSchedule[key].disabled = true);

        return currentLifeBaseFees;   
    }

    function initFeeScheduleItemsStatuses() {
        vm.setFeeScheduleItemStatus('inbodyConnection', vm.isInbodySelected);
        vm.setFeeScheduleItemStatus('subscriptionManagementModule', vm.lifeBaseFees.isSubscriptionOn);
        vm.initFeeScheduleSelectedUsersFees();
    }

    function getIsInbodySelected() {
        if (vm.lifeBaseFees?.supportedInbodyTypes) {
            return Object.keys(vm.lifeBaseFees.supportedInbodyTypes).some(key => vm.lifeBaseFees.supportedInbodyTypes[key]);
        }
    }

    function initFeeScheduleSelectedUsersFees() {
        if (!vm.lifeBaseFees.isSubscriptionOn) {
            vm.setFeeScheduleItemStatus('selectedUsersFees', false);
        }
    }

    function setFeeScheduleItemStatus(key, isSelected, isDisabled) {
        const disabled = isDisabled !== undefined ? isDisabled : !isSelected;
        vm.lifeBaseFees.feeSchedule[key].selected = isSelected;
        vm.lifeBaseFees.feeSchedule[key].disabled = disabled;
    }

    function checkIsChildEntity() {
        if (vm.lifeBaseFees?.parentEntityID) {
            vm.isChildEntity = true;
            vm.lifeBaseFees.isSubscriptionOn = true;
            vm.lifeBaseFees.feeSchedule.selectedUsersFees.text = 'Scholarship Users';
            vm.lifeBaseFees.usersFees.forEach(usersFee => usersFee.name = 'Scholarship Users');
            vm.setFeeScheduleItemStatus('subscriptionManagementModule', true, true);
        }
    }

    function setInitialLifeBaseFees() {
        vm.initialLifeBaseFees = angular.copy(vm.lifeBaseFees, {});
    }

    function setUsersFees() {
        vm.usersFees = { userVolumeTiers: vm.lifeBaseFees.userVolumeTiers };
        vm.setUsersFeesConditionProp('selectedUsersFees', 'usersFees');
        vm.setUsersFeesConditionProp('activeCalendarModule', 'activeCalendarUsers');
        vm.setUsersFeesConditionProp('nutritionModule', 'userVolumeTiers');
        vm.setUsersFeesConditionProp('scholasticModule', 'scholasticUsers');
    }

    function setUsersFeesConditionProp(feeScheduleKey, prop) {
        if (vm.lifeBaseFees.feeSchedule[feeScheduleKey].selected) {
            vm.usersFees[prop] = vm.lifeBaseFees[prop];
        } else {
            delete vm.usersFees[prop];
        }
    }

    function setCoachSelector() {
        const { nutritionModule, activeCalendarModule, scholasticModule } =
            vm.lifeBaseFees.feeSchedule;
        
        const isAnyBaseModuleSelected =
          nutritionModule.selected ||
          activeCalendarModule.selected ||
          scholasticModule.selected;
      
        vm.setFeeScheduleItemStatus('nutritionCoach', isAnyBaseModuleSelected, true);
      }

    function onCheckboxChange(key) {
        // used on entity profile to add default value when it not exist
        if (key === 'selectedUsersFees' && vm.lifeBaseFees.feeSchedule[key].selected && !vm.lifeBaseFees.usersFees.length) {
            vm.lifeBaseFees.usersFees = vm.defaultLifeBaseFees.usersFees.slice();
            vm.checkIsChildEntity();
        }
        if (key === 'activeCalendarModule' && vm.lifeBaseFees.feeSchedule[key].selected  && !vm.lifeBaseFees.activeCalendarUsers.length) {
            vm.lifeBaseFees.activeCalendarUsers = vm.defaultLifeBaseFees.activeCalendarUsers.slice();
        }
        if (key === 'scholasticModule' && vm.lifeBaseFees.feeSchedule[key].selected  && !vm.lifeBaseFees.scholasticUsers.length) {
            vm.lifeBaseFees.scholasticUsers = vm.defaultLifeBaseFees.scholasticUsers.slice();
        }
        vm.setUsersFees();
        vm.setCoachSelector();
        vm.lifeBaseFees = angular.copy(vm.lifeBaseFees, {});
        vm.setIsOnlyEnabledAC();
    }

    function setOneTimePaymentTotal() {
        vm.oneTimePaymentTotal = Object.values(vm.lifeBaseFees.oneTimePayment)
          .filter(item => typeof item !== 'string')
          .reduce((accumulator, currentValue) => {
              return accumulator + currentValue;
          })
          .toFixed(2);
    }

    function showCorporatePlansInfo() {
        ModalService.getCorporatePlansInfo(vm.isChildEntity);
    }

    function getIsNeedTrash(key, index) {
        return vm.lifeBaseFees[key].length > 1 && vm.lifeBaseFees[key].length - 1 === index && !vm.isEditDisabled;
    }

    function removeBundle(name, index) {
        vm.lifeBaseFees[name].splice(index, 1);
        vm.setUsersCountToValidity(name, index - 1);
    }

    function addBundle(key) {
        const newBundle = {};
        switch (key) {
            case 'certificationForNutritionCoaches':
            case 'certificationForSupplementCoaches':
                newBundle.name = 'Bundle Name ' + (vm.lifeBaseFees[key].length + 1);
                break;
        }
        vm.lifeBaseFees[key].push(newBundle);
    }

    function onCancelEdit() {
        if (vm.getIsInitialLifeBaseFeesChanged()) {
            ModalService.confirm('Unsaved changes', vm.saveLifeBaseFeesChanges, null,
              'You have unsaved changes. Do you want to save?', 'Cancel', 'Go', vm.resetLifeBaseFees);
        } else {
            vm.switchEdit();
        }
    }

    function getIsInitialLifeBaseFeesChanged() {
        return !angular.equals(vm.lifeBaseFees, vm.initialLifeBaseFees);
    }

    function resetLifeBaseFees() {
        vm.lifeBaseFees = angular.copy(vm.initialLifeBaseFees, {});
        vm.switchEdit();
    }

    function saveLifeBaseFeesChanges() {
        const requestData = vm.getRequestData();
        vm.spinner.active = true;
        EntityService.savePaymentBundle(vm.entityId, requestData)
          .then(res => {
              if (res.status === 200 && !vm.isEditDisabled) {
                  vm.setInitialLifeBaseFees();
                  vm.switchEdit();
              }
          })
          .finally(() => vm.spinner.active = false);
    }

    function getRequestData() {
        return {...vm.lifeBaseFees, ...vm.usersFees};
    }

    function onSubmit(needAddCard) {
        vm.needAddCard = needAddCard;
        vm.setInbodyTypeAndPaymentBundle().then(() => {
            vm.processCard();
        });
    }

    function processCard() {
        if (vm.needAddCard) {
            vm.pay();
        } else {
            ModalService.getInfoMessage('You can add a card in the Profile later', $scope.next);
        }
    }

    function pay() {
        ModalService.showStripe(vm.entityId, $scope.next);
    }

    function setInbodyTypeAndPaymentBundle() {
        return new Promise(resolve => {
            const requestData = vm.getRequestData();
            Object.keys(requestData.feeSchedule).forEach(key => delete requestData.feeSchedule[key].disabled);
            vm.spinner.active = true;
            EntityService.setInbodyTypeAndPaymentBundle(vm.entityId, requestData)
              .then(res => {
                  if (res.status === 200) {
                      resolve(true);
                  }
              })
              .finally(() => vm.spinner.active = false);
        });
    }

    function changeCreditCardDetails() {
        StripeService.updateEntityCardDetails(vm.entityId);
    }

    async function retryExistingCard() {
        const resultMessage = await vm.payManually(vm.entityId);
        $scope.$apply();

        ModalService.getInfoMessage(resultMessage);
    }
}
