import { makeAutoObservable } from 'mobx';

import { resetAllStore } from '../../shared/stores/resetAllStore';
import { ReportInfo } from '../interfaces/ReportInfo';
import { reportService } from '../services/reportService';
import { DownloadedFileExtension } from '../../shared/constants/file/FILE_EXTENSIONS';

const defaultSelectedReport: ReportInfo = {
  title: 'default',
  description: 'default',
  reportType: 'default',
  settings: [],
};

class ReportStore {
  reports: ReportInfo[] = [];
  isShowDownloadModal: boolean = false;
  selectedReport: ReportInfo = defaultSelectedReport;
  selectedFileExtension: DownloadedFileExtension | null = null;

  constructor() {
    this.setDefaultValues();
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.reports = [];
    this.isShowDownloadModal = false;
    this.selectedReport = defaultSelectedReport;
    this.selectedFileExtension = null;
  }

  async getReports() {
    const { reports } = await reportService.getReportsList();

    this.setReports(reports);
  }

  openDownloadModal(
    selectedReport: ReportInfo,
    selectedFileExtension: DownloadedFileExtension,
  ) {
    this.setIsShowDownloadModal(true);
    this.setSelectedReport(selectedReport);
    this.setFileExtension(selectedFileExtension);
  }

  closeDownloadModal() {
    this.setIsShowDownloadModal(false);
    this.setSelectedReport(defaultSelectedReport);
    this.setFileExtension(null);
  }

  private setReports(reports: ReportInfo[]) {
    this.reports = reports;
  }

  private setIsShowDownloadModal(isShowDownloadModal: boolean) {
    this.isShowDownloadModal = isShowDownloadModal;
  }

  private setSelectedReport(selectedReport: ReportInfo) {
    this.selectedReport = selectedReport;
  }

  private setFileExtension(selectedFileExtension: DownloadedFileExtension | null) {
    this.selectedFileExtension = selectedFileExtension;
  }
}

const reportStore = new ReportStore();

resetAllStore.addResetMethod(reportStore.setDefaultValues);

export { reportStore, ReportStore };
