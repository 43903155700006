import React from 'react';

import { useForm } from 'react-hook-form';

import { LbModal } from '../../../../shared/components/modals';
import { LbNumericFormatInput } from '../../../../shared/components/form-components';

type SetUpTaxFromValues = {
  taxRate: number;
};

type SetUpTaxModalProps = {
  onClose: () => void;
  onSubmit: (taxRate: number) => void;
};

const SetUpTaxModal = ({ onClose, onSubmit }: SetUpTaxModalProps) => {
  const { control, handleSubmit } = useForm<SetUpTaxFromValues>();

  const handleFromSubmit = handleSubmit(({ taxRate }) => {
    onSubmit(taxRate);
    onClose();
  });

  const instructions = `Please input the sales tax amounts, 
    as per the regulations in entity's state/city/county. 
    If your state, city or county doesn't have any Sales taxes, enter 0`;

  return (
    <LbModal
      open
      title="Set up sales tax"
      text={instructions}
      primaryBtnConfig={{ text: 'Confirm', onClick: handleFromSubmit }}
      secondaryBtnConfig={{ text: 'Cancel', onClick: onClose }}
    >
      <form>
        <LbNumericFormatInput
          control={control}
          name="taxRate"
          label="Tax"
          rules={{
            required: 'Tax is required',
            max: {
              value: 100,
              message: 'Tax must be less than 100%',
            },
          }}
          size="small"
          suffix="%"
          allowNegative={false}
          decimalScale={2}
        />
      </form>
    </LbModal>
  );
};

export { SetUpTaxModal };
