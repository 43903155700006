/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { useController } from 'react-hook-form';
import AutosizeInput from 'react-18-input-autosize';

import classes from './DoubleInput.module.scss';
import { inputPropsTypes, inputValidation } from './DoubleInput.setting';

const DoubleInput = ({
  control,
  firstInputProps,
  secondInputProps,
  label,
  endAdornment,
}) => {
  const firstInputRef = useRef({});

  const {
    field: fieldFirstInput,
    fieldState: { error: errorFirstInput },
  } = useController({
    control,
    name: firstInputProps.name,
    rules: { ...inputValidation, ...firstInputProps.rules },
    defaultValue: firstInputProps.defaultValue || '',
  });

  const {
    field: fieldSecondInput,
    fieldState: { error: errorSecondInput },
  } = useController({
    control,
    name: secondInputProps.name,
    rules: { ...inputValidation, ...secondInputProps.rules },
    defaultValue: secondInputProps.defaultValue || '',
  });

  const error = !!errorFirstInput || !!errorSecondInput;
  const errorMessage = errorFirstInput?.message || errorSecondInput?.message;

  return (
    <div className={classes.doubleInputBox} tabIndex={0} data-error={error}>
      {label && <div className={classes.label}>{label} </div>}
      <div className={classes.inputsRow} onClick={() => firstInputRef.current.focus()}>
        <form className={classes.inputsContainer}>
          <AutosizeInput
            {...fieldFirstInput}
            ref={(el) => {
              firstInputRef.current = el;
              fieldFirstInput.ref(el);
            }}
            pattern="[0-9]*"
            placeholder="actual"
            onChange={(event) =>
              event.target.validity.valid && fieldFirstInput.onChange(event)
            }
          />
          <span> / </span>
          <AutosizeInput
            {...fieldSecondInput}
            pattern="[0-9]*"
            placeholder="in bottle"
            onClick={(event) => event.stopPropagation()}
            disabled={secondInputProps.disabled ?? true}
            onChange={(event) => {
              if (event.target.validity.valid) {
                fieldSecondInput.onChange(event);
              }
            }}
          />
        </form>
        <p className={classes.endAdornment}>{endAdornment}</p>
      </div>
      <span className={classes.errorMessage}>{errorMessage}</span>
    </div>
  );
};

DoubleInput.propTypes = {
  control: PropTypes.object.isRequired,
  firstInputProps: PropTypes.shape(inputPropsTypes),
  secondInputProps: PropTypes.shape(inputPropsTypes),
  label: PropTypes.string,
  endAdornment: PropTypes.any,
};

export { DoubleInput };
