import React from 'react';
import { Radio, RadioGroup } from '@mui/material';

import classes from './SubscriptionsRadioButtons.module.scss';
import { LbFormControlLabel } from '../../../../../../../shared/components/form-components';
import {
  SubscriptionsRadioButtonsValue,
  SubscriptionsRadioButtonsValues,
} from '../SubscriptionsBox.settings';

type SubscriptionsRadioButtonsProps = {
  value: SubscriptionsRadioButtonsValue;
  onChange: (value: SubscriptionsRadioButtonsValue) => void;
};

const SubscriptionsRadioButtons = ({
  value,
  onChange,
}: SubscriptionsRadioButtonsProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value as SubscriptionsRadioButtonsValue);
  };

  return (
    <RadioGroup
      className={classes.container}
      onChange={handleChange}
      value={value}
      defaultValue={SubscriptionsRadioButtonsValues.ALL}
    >
      <LbFormControlLabel
        control={
          <Radio
            value={SubscriptionsRadioButtonsValues.ALL}
            size="medium"
            color="primary"
          />
        }
        label="All Subscriptions (this will apply to all current and future subscriptions)"
      />
      <LbFormControlLabel
        control={
          <Radio
            value={SubscriptionsRadioButtonsValues.SPECIFIC}
            size="medium"
            color="primary"
          />
        }
        label="Selected Subscriptions:"
      />
    </RadioGroup>
  );
};

export { SubscriptionsRadioButtons };
