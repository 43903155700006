import React from 'react';
import PropTypes from 'prop-types';

import { useFormContext } from 'react-hook-form';
import { Paper } from '@mui/material';
import { observer } from 'mobx-react';

import classes from './UserProfileStep.module.scss';
import { registrationStepperStore } from '../../../../stores/registrationStepperStore';
import { ButtonRow } from '../../components/ButtonRow/ButtonRow';
import { useModalToggle } from '../../../../../shared/hooks/useModalToggle';
import { AddWaterGoalModal } from '../../../../components/AddWaterGoalModal/AddWaterGoalModal';
import { medicalTestsStore } from '../../../../stores/medicalTestsStore';
import { userProfileService } from '../../../../services/userProfileService';
import { prepDataToSubmit } from './UserProfileStep.settings';
import { waterGoalService } from '../../../../services/waterGoalService';
import { apiStatusStore } from '../../../../../shared/stores/apiStatusStore';
import { UserProfileInputs } from '../../../../components/UserProfileInputs/UserProfileInputs';
import { openConfirmationModal } from '../../../../../shared/features/confirmation';

const waterGoalFieldName = 'waterGoal';

const UserProfileStep = observer(({ fieldName }) => {
  const {
    goToNextStep,
    skipStep,
    setUserId,
    setIsWaterGoalSent,
    userId,
    isMedicalTestsSend,
    isWaterGoalSent,
  } = registrationStepperStore;

  const { isTestsDisabled } = medicalTestsStore;

  const { isLoading } = apiStatusStore;

  const [isWaterGoalModalOpen, waterGoalModalHandler] = useModalToggle();

  const {
    handleSubmit,
    control,
    trigger,
    setValue,
    formState: { isValid },
  } = useFormContext();

  const onSubmit = async (data) => {
    const userData = prepDataToSubmit(data[fieldName]);

    const { user } = await userProfileService.sendUser(userData);

    setUserId(user.userId);
    setValue(`${fieldName}.user.userId`, user.userId);

    if (isWaterGoalSent) {
      goNextWithoutWaterGoal();
    } else {
      waterGoalModalHandler();
    }
  };

  const handleShowLabTestStep = () => {
    openConfirmationModal({
      title: 'Do you want to record Lab Tests for this client?',
      confirmBtnConfig: { text: 'Yes', onClick: goToNextStep },
      cancelBtnConfig: { text: 'Skip for now', onClick: skipStep },
    });
  };

  const goNextWithoutWaterGoal = () => {
    if (isTestsDisabled || isMedicalTestsSend) {
      goToNextStep();
    } else {
      handleShowLabTestStep();
    }
  };

  const onWaterGoalSubmit = async (data) => {
    const submitDate = {
      targetGoal: data[waterGoalFieldName],
      customerID: userId,
    };

    await waterGoalService.sendGoal(submitDate);

    setIsWaterGoalSent();
    waterGoalModalHandler();

    if (!isTestsDisabled) {
      handleShowLabTestStep();
    } else {
      goToNextStep();
    }
  };

  const cancelWaterGoalModalHandler = () => {
    waterGoalModalHandler();
    trigger(fieldName);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper variant="box" className={classes.userProfileStep}>
          <UserProfileInputs control={control} fieldName={fieldName} />
        </Paper>
        <ButtonRow handlerNext={handleSubmit(onSubmit)} isDisabled={!isValid} />
      </form>
      <AddWaterGoalModal
        control={control}
        name={waterGoalFieldName}
        open={isWaterGoalModalOpen}
        yesHandler={handleSubmit(onWaterGoalSubmit)}
        noHandler={cancelWaterGoalModalHandler}
        isLoading={isLoading}
      />
    </>
  );
});

UserProfileStep.propTypes = {
  fieldName: PropTypes.string,
};

export { UserProfileStep };
