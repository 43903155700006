import React from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';

import { NoInbodyWidget } from './NoInbodyWidget/NoInbodyWidget';
import { InbodyWidget } from './InbodyWidget/InbodyWidget';
import { inbodyDataService } from '../../services/inbodyDataService';
import { getInbodyConfigFormValue } from './utils/getInbodyConfigFormValue';
import { EntityInbodyDataFormValue } from './EntityInbodyDataConfig.settings';

type EntityInbodyDataConfigProps = {
  entityId: number;
  formId?: string;
  onSuccessSubmit?: () => void;
};
/**
 * This component is used to setup inbody data for entity and edit it.
 * Also it is used to show inbody data for entity, if logged in user is not super admin all fields will be disabled.
 *
 * To trigger submit form connect submit button used formId.
 */

// rename to EntityInbodyDataConfig // TODO if need update add different behaviors depend of page refactor this component use context
const EntityInbodyDataConfig = ({
  entityId,
  formId,
  onSuccessSubmit = () => {},
}: EntityInbodyDataConfigProps) => {
  const formMethods = useForm<EntityInbodyDataFormValue>({
    shouldUnregister: true,
    defaultValues: async () => getInbodyConfigFormValue(entityId),
  });

  const { handleSubmit } = formMethods;

  const handleFromSubmit = handleSubmit(async (inbodyData) => {
    const { allowInbodyUsers, allowNonInbodyUsers } = inbodyData;
    const oneOptionIsSelected = allowInbodyUsers || allowNonInbodyUsers;

    if (!oneOptionIsSelected) {
      enqueueSnackbar('Please select at least one option', { variant: 'error' });
      return;
    }

    await inbodyDataService.setupEntityInbodyData(entityId!, inbodyData);

    onSuccessSubmit();
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleFromSubmit} id={formId} noValidate>
        <InbodyWidget entityId={entityId} />
        <NoInbodyWidget />
      </form>
    </FormProvider>
  );
};

export { EntityInbodyDataConfig };
