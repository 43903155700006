import React from 'react';

import { angularize } from 'react-in-angularjs';

import { SystemProviders } from '../../../../shared/providers';
import { DetailedRevenue } from '../../../containers/DetailedRevenue/DetailedRevenue';

type NgzDetailedRevenueTabProps = {
  entityId: number;
  entityRegistrationDate: string | null; // oldDateFormat
};

const NgzDetailedRevenueTab = ({
  entityId,
  entityRegistrationDate,
}: NgzDetailedRevenueTabProps) => {
  return (
    <SystemProviders>
      <DetailedRevenue
        entityId={entityId}
        entityRegistrationDate={entityRegistrationDate}
      />
    </SystemProviders>
  );
};

angularize(
  NgzDetailedRevenueTab,
  'ngzDetailedRevenueTab',
  angular.module('app.payment'),
  {
    entityId: '<',
    entityRegistrationDate: '<',
  },
);

export { NgzDetailedRevenueTab };
