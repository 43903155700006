import { apiService } from '../../shared/services/apiService';
import { MealSchedule } from '../interfaces/MealTimes';

export const mealScheduleService = {
  async getMealSchedule(userId: number) {
    const response = await apiService.get<MealSchedule>(
      `/api/coach/user/${userId}/meal-schedule`,
    );

    return response.data;
  },

  async updateMealSchedule(userId: number, mealSchedule: MealSchedule) {
    const response = await apiService.put<MealSchedule>(
      `/api/coach/user/${userId}/meal-schedule`,
      mealSchedule,
    );

    return response.data;
  },
};
