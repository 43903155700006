import React, { useEffect, useState } from 'react';

import { angularize } from 'react-in-angularjs';
import { useForm } from 'react-hook-form';
import { Paper } from '@mui/material';

import classes from './NgzUserProfileTab.module.scss';
import { SystemProviders } from '../../../../../../shared/providers';
import { HeadersRow } from './HeadersRow/HeadersRow';
import { LbModal } from '../../../../../../shared/components/modals/LbModal/LbModal';
import { useModalToggle } from '../../../../../../shared/hooks/useModalToggle';
import { UserProfileInputs } from '../../../../../components/UserProfileInputs/UserProfileInputs';
import { userProfileService } from '../../../../../services/userProfileService';
import { getRouteParam } from '../../../../../../shared/utils/angularUtils';
import { YesNoBtnRow } from '../../../../../../shared/components/buttons';

const { getCustomerInfo, getUserInfo, sendUser } = userProfileService;

const NgzUserProfileTab = () => {
  const [userName, setUserName] = useState('');
  const [isSavedModalOpen, savedModalHandler] = useModalToggle();
  const [isUserActive, setIsUserActive] = useState();

  const customerId = getRouteParam('id');

  const { reset, control, formState, handleSubmit } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const getUserData = async () => {
    const [userData, customer] = await Promise.all([
      getUserInfo(customerId),
      getCustomerInfo(customerId),
    ]);

    const { active, ...user } = userData;

    setUserName(`${userData.firstName} ${userData.lastName}`);
    setIsUserActive(active);
    reset({ user, customer });
  };

  useEffect(() => {
    getUserData();
  }, []);

  const onSubmit = async (data) => {
    await sendUser(data);

    savedModalHandler();
  };

  return (
    <SystemProviders>
      <Paper variant="roundedBox" className={classes.box}>
        <HeadersRow
          isUserActive={isUserActive}
          setIsUserActive={setIsUserActive}
          userName={userName}
        />
        <form className={classes.formBox}>
          <UserProfileInputs control={control} />
        </form>
      </Paper>
      <YesNoBtnRow
        yesText="Save"
        yesDisabled={!formState.isValid}
        yesHandler={handleSubmit(onSubmit)}
      />
      <LbModal
        open={isSavedModalOpen}
        title="Changes have been saved successfully"
        onClose={savedModalHandler}
        primaryBtnConfig={{ text: 'Ok', onClick: savedModalHandler }}
      />
    </SystemProviders>
  );
};

angularize(
  NgzUserProfileTab,
  'ngzUserProfileTab',
  angular.module('app.active-calendar'),
  {},
);
