import React from 'react';

import { enqueueSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import classes from './InbodyKeyRow.module.scss';
import {
  GridTableCell,
  GridTableRow,
} from '../../../../../../shared/components/table-components';
import { InbodyKeyInfo } from '../../../../../interfaces/inbodyKey';
import { openConfirmationModal } from '../../../../../../shared/features/confirmation';
import { copyTextToClipboard } from '../../../../../../shared/utils/apiUtils';
import { TextTruncate } from '../../../../../../shared/components/ui-components';

type InbodyKeyRowProps = {
  inbodyKeyInfo: InbodyKeyInfo;
  onDelete: (id: number) => void;
};

const InbodyKeyRow = ({ inbodyKeyInfo, onDelete }: InbodyKeyRowProps) => {
  const { inbodyKey, accountName, description, id } = inbodyKeyInfo;

  const handleDeleteInbodyKey = () => {
    openConfirmationModal({
      title: 'Are you sure you want to delete this InBody Key?',
      confirmBtnConfig: {
        text: 'Delete',
        onClick: () => onDelete(id),
      },
    });
  };

  const handleCopyInbodyKey = () => {
    copyTextToClipboard(inbodyKey);
    enqueueSnackbar('InBody Key copied', { variant: 'success', autoHideDuration: 2000 });
  };

  return (
    <GridTableRow variant="subRow">
      <GridTableCell className={classes.inbodyKeyCell}>
        <TextTruncate text={inbodyKey} />
        <IconButton color="tertiary" size="medium" onClick={handleCopyInbodyKey}>
          <ContentCopyIcon />
        </IconButton>
      </GridTableCell>
      <GridTableCell>{accountName}</GridTableCell>
      <GridTableCell>{description}</GridTableCell>
      <GridTableCell>
        <IconButton color="secondary" size="medium" onClick={handleDeleteInbodyKey}>
          <DeleteOutlineRoundedIcon />
        </IconButton>
      </GridTableCell>
    </GridTableRow>
  );
};

export { InbodyKeyRow };
