/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

import classes from './HistoryOrdersTab.module.scss';
import { HeaderRow } from './HeaderRow/HeaderRow';
import { OrderRow } from './OrderRow/OrderRow';

const HistoryOrdersTab = ({ ordersHistory }) => {
  const isOrderHistoryNotExist = !ordersHistory.length;

  if (isOrderHistoryNotExist) {
    return <p className={classes.info}>Order history does not exist yet</p>;
  }

  return (
    <div className={classes.historyBox}>
      <HeaderRow />
      {ordersHistory.map(([date, orders]) =>
        orders.map((order, index) => (
          <OrderRow key={index} date={date} order={order} isShowDate={index === 0} />
        )),
      )}
    </div>
  );
};

HistoryOrdersTab.propTypes = {
  ordersHistory: PropTypes.array,
};

export { HistoryOrdersTab };
