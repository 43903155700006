import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
import classes from './FreeAccessWidget.module.scss';
import { InfoBox, LbLinearProgress } from '../../../shared/components/ui-components';
import { TitleWithDescription } from '../../../shared/components/text-formation-components';
import { getPercentages } from '../../../shared/utils/mathUtils';
import { loggedInUserDataStore } from '../../../shared/stores/loggedInUserDataStore';
import { ManageSeatsButton } from './ManageSeatsButton/ManageSeatsButton';
import { ManageMembersButton } from './ManageMembersButton/ManageMembersButton';
import { freeAccessStore } from '../../stores/freeAccessStore';

type FreeAccessWidgetProps = {
  entityId: number;
};

const FreeAccessWidget = observer(({ entityId }: FreeAccessWidgetProps) => {
  const { isSuperAdmin } = loggedInUserDataStore;
  const { freeSeatsInfo, getFreeAccessInfo } = freeAccessStore;

  const { freeSeatsCount, freeSeatsUsed } = freeSeatsInfo;

  useEffect(() => {
    getFreeAccessInfo(entityId);
  }, []);

  const Title = (
    <TitleWithDescription
      title="Free access"
      description="Allow users to have a free access to the LifeBase Solutions"
    />
  );

  const progressOfUsedSeats =
    freeSeatsCount > 0 ? getPercentages(freeSeatsUsed, freeSeatsCount) : 0;

  const manageButton = isSuperAdmin ? (
    <ManageSeatsButton entityId={entityId} />
  ) : (
    <ManageMembersButton entityId={entityId} />
  );

  return (
    <InfoBox title={Title} icon={manageButton}>
      <div className={classes.infoRow}>
        <span>Remaining Seats: {freeSeatsCount - freeSeatsUsed}</span>
        <span>
          {freeSeatsUsed} / {freeSeatsCount} Free Seats used
        </span>
      </div>
      <LbLinearProgress value={progressOfUsedSeats} color="primary" />
    </InfoBox>
  );
});

export { FreeAccessWidget };
