import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useFormContext } from 'react-hook-form';

import { ButtonRow } from '../../components/ButtonRow/ButtonRow';
import { CongratulationsModal } from '../../components/CongratulationsModal/CongratulationsModal';
import { useModalToggle } from '../../../../../shared/hooks/useModalToggle';
import { orderSupplementsService } from '../../../../services/orderService';
import { registrationStepperStore } from '../../../../stores/registrationStepperStore';
import { OrderInventoryTable } from '../../components/OrderInventoryTable/OrderInventoryTable';
import { goTo } from '../../../../../shared/utils/angularUtils';

const InitialOrderStep = ({ fieldName }) => {
  const [orderInventory, setOrderInventory] = useState([]);

  const { userId, setDefaultValues } = registrationStepperStore;

  const { handleSubmit, resetField, getValues } = useFormContext();

  const [modalToggle, modalToggleHandler] = useModalToggle();

  const initDataForRender = async () => {
    const inventory = await orderSupplementsService.getOrderInventory(userId);
    setUniqueField(inventory);
    setOrderInventory(inventory);
  };

  const setUniqueField = (inventory) => {
    const newObj = {};
    const formObj = getValues(fieldName);

    inventory.forEach(({ supplementJson: { supplementId } }) => {
      if (formObj[supplementId]) {
        newObj[supplementId] = formObj[supplementId];
      }
    });

    resetField(fieldName, { defaultValue: newObj });
  };

  useEffect(() => {
    initDataForRender();
  }, []);

  const onSubmit = async (data) => {
    const dataArr = Object.values(data[fieldName]);
    await orderSupplementsService.createOrder(dataArr, userId);
    modalToggleHandler();
  };

  const onCloseModalHandler = () => {
    modalToggleHandler();
    setDefaultValues();

    goTo('/');
  };

  return (
    <>
      <OrderInventoryTable orderInventory={orderInventory} nameIndex={fieldName} />
      <ButtonRow submitBtnName="Finish" handlerNext={handleSubmit(onSubmit)} />
      <CongratulationsModal open={modalToggle} onCloseHandler={onCloseModalHandler} />
    </>
  );
};

InitialOrderStep.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

export { InitialOrderStep };
