const autoNgTemplateLoaderTemplate1 = require('./invoices-and-prices.template.html');

(function () {
  'use strict';

  InvoicesAndPricingController.$inject = ['$scope', 'InvoicesAndPricingService', 'localStorageService','ModalService'];

  function InvoicesAndPricingController($scope, InvoicesAndPricingService, localStorageService, ModalService) {
    const vm = this;
    vm.spinner = false;
    vm.selectedPayment = null;
    const moduleBillingTitles = {
      baseSystemPrice: 'Base System Price',
      marketingModule: 'Marketing Module',
      nutritionModule: 'Nutrition Module',
      activeCalenderModule: 'Active Calendar Module',
      supplementMemberModule: 'Supplement Member Module',
      subscriptionManagementModule: 'Subscription Management Module',
      totalMonthlyModuleBilling: 'Total Monthly Module Billing'
    };

    $scope.$on('paymentSelect', (event, { selectedPayment }) => {
      if (!vm.selectedPayment || selectedPayment.paymentID !== vm.selectedPayment.paymentID) {
        vm.onSelectedPaymentChange(selectedPayment);
      }
    });

    vm.onSelectedPaymentChange = (selectedPayment) => {
      vm.selectedPayment = selectedPayment;
      vm.isCurrent = false;
      vm.getBillingPeriodById();
    }

    vm.$onInit = () => {
      vm.getCurrentBillingPeriod();
    }

    vm.getCurrentBillingPeriod = () => {
      vm.spinner = true;
      InvoicesAndPricingService.getCurrentBillingPeriod(vm.entityId)
        .then(res => {
          if (res.status === 200) {
            vm.isCurrent = true;
            vm.processBillingPeriodData(res.data);
          }
        })
        .finally(() => vm.spinner = false);
    }

    vm.processBillingPeriodData = (data) => {
      vm.invoices = data;
      vm.processInvoices();
    }

    vm.processInvoices = () => {
      vm.invoices.moduleBilling = Object.keys(vm.invoices.moduleBilling)
        .map(key => ({ title: moduleBillingTitles[key], price: vm.invoices.moduleBilling[key] }));
      vm.invoices.userBilling.elements.push({userType: 'Total Monthly Module Billing',
        totalPrice: vm.invoices.userBilling.totalMonthlyUserBilling});
    }

    vm.getBillingPeriodById = () => {
      vm.spinner = true;
      InvoicesAndPricingService.getBillingPeriodById(vm.selectedPayment.paymentID)
        .then(res => {
          if (res.status === 200) {
            vm.processBillingPeriodData(res.data);
          }
        })
        .finally(() => vm.spinner = false);
    }

    vm.backToCurrent = () => {
      localStorageService.remove('selectedPayment');
      vm.selectedPayment = null;
      vm.getCurrentBillingPeriod();
    }

    vm.openInvoiceCalendarPanel = () => {
      InvoicesAndPricingService.openInvoiceCalendarPanel(vm.entityId);
    }

    vm.$onDestroy = () => {
      localStorageService.remove('invoiceCalendarData', 'selectedPayment');
    }

    vm.showBillingInfo = () => {
      const title = 'Billing info'
      const info = `Monthly billing is based on a prorated basis for users as well as monthly fees. 
      If a user was “active” for 10 days out of a 30-day month, LifeBase charges will reflect the customer’s monthly user rate * (10/30). 
      Therefore, your billing statement may look slightly different from your Price Sheet.
      For example, if your Per User rate is $10.00/month and your user was “active” for 10 days out of the month, 
      this particular client fees would result in a $3.00 charge for the month.`

      ModalService.getSubscriptionInfo({title, info});
    }
  }

  angular.module('app.entityProfile').component('invoicesAndPricing', {
    templateUrl: autoNgTemplateLoaderTemplate1,
    controller: InvoicesAndPricingController,
    controllerAs: 'vm',
    bindings: {
      entityId: '<',
      tax: '<',
    }
  });

})();
