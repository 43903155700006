import { AccessParams } from '../../../interfaces/AccessParams';
import { getRouteParam } from '../../angularUtils';

type GetAccessParamsFromUrlReturn =
  | {
      accessParams: Partial<AccessParams>;
      allParamsExist: false;
    }
  | {
      accessParams: AccessParams;
      allParamsExist: true;
    };

export const getAccessParamsFromUrl = (): GetAccessParamsFromUrlReturn => {
  const hash = getRouteParam('sub1');
  const timestamp = getRouteParam('sub2');
  const token = getRouteParam('sub3');

  const accessParams: AccessParams = { token, timestamp, hash };

  const allParamsExist = token && timestamp && hash;

  return { accessParams, allParamsExist };
};
