import React from 'react';

import { useForm } from 'react-hook-form';

import classes from './AddInBodyKeyModal.module.scss';
import { LbInput, TextArea } from '../../../../../../shared/components/form-components';
import { LbModal } from '../../../../../../shared/components/modals';
import { EntityInbodyKeyFormValue } from '../../../../../interfaces/inbodyKey';

type AddInBodyKeyModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: EntityInbodyKeyFormValue) => void;
};

const AddInBodyKeyModal = ({ open, onClose, onSubmit }: AddInBodyKeyModalProps) => {
  const { control, handleSubmit } = useForm<EntityInbodyKeyFormValue>({
    shouldUnregister: true,
  });

  const generalInputProps = { control, variant: 'outlined', size: 'small' } as const;

  const onSubmitForm = handleSubmit((data: EntityInbodyKeyFormValue) => {
    onSubmit(data);
    onClose();
  });

  return (
    <LbModal
      open={open}
      title="Add InBody Key"
      primaryBtnConfig={{ onClick: onSubmitForm, text: 'Confirm' }}
      secondaryBtnConfig={{ onClick: onClose }}
    >
      <div className={classes.inputBox}>
        <LbInput
          {...generalInputProps}
          name="inbodyKey"
          label="InBody Key"
          rules={{ required: 'InBody Key is required' }}
        />
        <LbInput
          {...generalInputProps}
          name="accountName"
          label="Webhooks ID"
          rules={{ required: 'Webhooks ID is required' }}
        />
        <TextArea
          {...generalInputProps}
          name="description"
          label="Description"
          rows={8}
        />
      </div>
    </LbModal>
  );
};

export { AddInBodyKeyModal };
