import React from 'react';

import { useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react';

import { ComboBox } from '../../../../../../../shared/components/form-components';
import { ReportSetting } from '../../../../../../interfaces/ReportSetting';
import { useReportSelector } from './hooks/useReportSelector';
import { getRequiredRules } from '../../utils/getRequiredRules';

type ReportSelectorProps = {
  selectorSettings: ReportSetting;
  parentSelectorSettings?: ReportSetting;
};

const ReportSelector = observer(
  ({ selectorSettings, parentSelectorSettings }: ReportSelectorProps) => {
    const { selectorLabel, parameterKey, selectAllAvailable } = selectorSettings;

    const { control } = useFormContext();
    const { options, defaultValue, isLoading, isDisabled } = useReportSelector({
      selectorSettings,
      parentSelectorSettings,
    });

    const rules = getRequiredRules(selectAllAvailable, selectorLabel!);

    return (
      <ComboBox
        control={control}
        variant="outlined"
        size="small"
        name={parameterKey!}
        label={selectorLabel}
        options={options}
        defaultValue={defaultValue}
        disabled={isDisabled}
        loading={isLoading}
        rules={rules}
      />
    );
  },
);

export { ReportSelector };
