import { makeAutoObservable } from 'mobx';
import { resetAllStore } from '../../shared/stores/resetAllStore';

import { preCheckInService } from '../services/preCheckInService';

class ChildInformationStore {
  constructor() {
    this.setDefaultValues();
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.childInformation = {
      connectedAccountID: '',
      fullName: '',
      dob: '',
      kidEmail: '',
      phone: '',
      stripePublicKey: '',
      subscriptions: {
        stripe: false,
        subscriptions: [],
        tax: 0,
      },
    };
    this.paymentMethodId = '';
    this.isEntityWithStripe = '';
  }

  async loadChildInfo() {
    const childInfo = await preCheckInService.getChildInfo();
    this.onSetChildInformation(childInfo);
    this.setIsEntityWithStripe();
  }

  onSetChildInformation(child) {
    this.childInformation = child;
  }

  savePaymentMethodId(paymentMethodId) {
    this.paymentMethodId = paymentMethodId;
  }

  setIsEntityWithStripe() {
    this.isEntityWithStripe = this.childInformation.connectedAccountID;
  }
}
const childInformationStore = new ChildInformationStore();

resetAllStore.addResetMethod(childInformationStore.setDefaultValues);

export { childInformationStore, ChildInformationStore };
