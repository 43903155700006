class ResetAllStore {
  constructor() {
    this.setDefaultValues();
  }

  setDefaultValues() {
    this.resetMethods = [];
  }

  addResetMethod(resetMethod) {
    this.resetMethods.push(resetMethod);
  }

  reset() {
    this.resetMethods.forEach((resetMethod) => {
      resetMethod();
    });
  }
}

const resetAllStore = new ResetAllStore();

export { resetAllStore, ResetAllStore };
