'use strict';

angular
    .module('app')
    .directive('infiniteScroll', infiniteScroll);

infiniteScroll.$inject = [];

function infiniteScroll() {
    return {
        restrict: 'A',
        scope:{
            infiniteScroll:'&infiniteScroll'
        },
        link: function(scope, elm) {
            var raw = elm[0];
            elm.bind('scroll', function() {
                if (Math.ceil(raw.scrollTop) + raw.offsetHeight >= raw.scrollHeight) {
                    scope.$apply(scope.infiniteScroll());
                }
            });
        },
    };
}
