'use strict';

angular.module('app').factory('InbodyKeysService', InbodyKeysService);

InbodyKeysService.$inject = ['ApiService', 'ModalService'];

function InbodyKeysService() {
    return {
        // TODO: Delete service when inbody scans logic will be understood
    }
}
