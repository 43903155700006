import { TAX_RATE_TYPE, TaxRateType } from '../../../../constants/tax';

export const getTaxDescription = (taxRateType?: TaxRateType | null): string => {
  switch (taxRateType) {
    case TAX_RATE_TYPE.ENTITY_TAX_RATE:
      return 'sales tax incl';
    case TAX_RATE_TYPE.APPLICATION_TAX_RATE:
      return 'sales tax (SaaS incl)';
    default:
      return 'no tax set';
  }
};
