import { apiService } from '../../shared/services/apiService';

type GetEntityLoginIdResponse = { loginId: string };
type UpdateEntityLoginIdResponse = { loginId: string };

export const entityLoginIdService = {
  async getEntityLoginId(entityId: number) {
    const resp = await apiService.get<GetEntityLoginIdResponse>(
      `/api/admin/${entityId}/loginId`,
    );

    return resp.data;
  },

  // create entity login id and send email with magic link to set password
  async setEntityLoginId(entityId: number, loginId: string) {
    const params = { loginId };

    const resp = await apiService.post<undefined>(
      `/api/super/entity/${entityId}/login-id`,
      undefined,
      { params },
    );

    return resp.data;
  },

  async updateEntityLoginId(entityId: number, loginId: string) {
    const params = { loginId };

    const resp = await apiService.patch<UpdateEntityLoginIdResponse>(
      `/api/admin/${entityId}/loginId`,
      undefined,
      { params },
    );

    return resp.data;
  },
};
