import React, { ReactNode } from 'react';

import classes from './InputBox.module.scss';

type InputBoxProps = {
  title: string;
  children: ReactNode;
  className?: string;
};

const InputBox = ({ title, children, className }: InputBoxProps) => {
  return (
    <div className={className}>
      <h6 className={classes.title}>{title}</h6>
      {children}
    </div>
  );
};

export { InputBox };
