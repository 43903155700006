const autoNgTemplateLoaderTemplate1 = require('./entity-setup-step-4.template.html');

'use strict';

angular
    .module('app')
    .directive('entitySetupStep4', entitySetupStep4);

entitySetupStep4.$inject = [];

function entitySetupStep4() {
    return {
        restrict: 'E',
        scope: {
            createEntity: '=',
            spinner: '=',
            next: '&onNext'
        },
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: 'EntitySetupStep4Controller',
        controllerAs: 'vm'
    };
}
