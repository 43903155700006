import { getService } from 'react-in-angularjs';
import { unleashClient } from '../unleashClient/unleashClient';

export const watchFlagAngular = ({ flagName, fieldName, $scope, vm } = {}) => {
  const utilService = getService('utilService');

  if (!flagName || !fieldName || !$scope || !vm) {
    throw new Error('watchFlagAngular one of required field not exist');
  }

  function handelSetFlag() {
    const enabled = unleashClient.isEnabled(flagName);

    if (enabled !== vm[fieldName]) {
      vm[fieldName] = enabled;
      utilService.applyAsyncFunc($scope);
    }
  }

  handelSetFlag();
  unleashClient.on('update', handelSetFlag);
  unleashClient.on('ready', handelSetFlag);

  $scope.$on('$destroy', () => {
    unleashClient.off('update', handelSetFlag);
    unleashClient.off('ready', handelSetFlag);
  });
};
