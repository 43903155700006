import React from 'react';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';

import { salesReportStore } from '../../../../../stores/salesReportStore';
import { FILE_EXTENSION } from '../../../../../../shared/constants/file/FILE_EXTENSIONS';
import { DownloadReportButtons } from '../../../../../components/DownloadReportButtons/DownloadReportButtons';
import {
  GridTableCell,
  GridTableRow,
} from '../../../../../../shared/components/table-components';

const { PDF, CSV } = FILE_EXTENSION;

const ReportRow = observer(({ report }) => {
  const { openDownloadModal } = salesReportStore;

  return (
    <GridTableRow variant="row" dataTestId="report-row">
      <GridTableCell variant="title">{report.title}</GridTableCell>
      <GridTableCell>{report.description}</GridTableCell>
      <GridTableCell>
        <DownloadReportButtons
          onPdfDownload={() => openDownloadModal(report, PDF)}
          onCsvDownload={() => openDownloadModal(report, CSV)}
        />
      </GridTableCell>
    </GridTableRow>
  );
});

ReportRow.propTypes = {
  report: PropTypes.shape({
    title: PropTypes.string,
    reportType: PropTypes.string,
    description: PropTypes.string,
    settings: PropTypes.arrayOf(
      PropTypes.shape({
        paymentType: PropTypes.string,
        selectAllAvailable: PropTypes.bool,
        selectorType: PropTypes.string,
        subSettings: PropTypes.array,
      }),
    ),
  }),
};

export { ReportRow };
