import React from 'react';

import { angularize } from 'react-in-angularjs';
import { Container } from '@mui/material';

import { SystemProviders } from '../../../shared/providers';
import { PageTitle } from '../../../shared/components/ui-components/PageTitle/PageTitle';
import { ReportTable } from './containers/ReportTable/ReportTable';
import { ReportDownloadModal } from './containers/ReportDownloadModal/ReportDownloadModal';

const NgzCoachReportsList = () => {
  return (
    <SystemProviders>
      <Container>
        <PageTitle title="Reports" />
        <ReportTable />
        <ReportDownloadModal />
      </Container>
    </SystemProviders>
  );
};

angularize(NgzCoachReportsList, 'ngzCoachReportsList', angular.module('app.reports'), {});

export { NgzCoachReportsList };
