import React from 'react';

import classes from './CompanySubLogo.module.scss';

type CompanySubLogoProps = {
  logoUrl: string;
};

const CompanySubLogo = ({ logoUrl }: CompanySubLogoProps) => {
  return (
    <div className={classes.box}>
      <img src={logoUrl} alt="logo" className={classes.entityLogo} />
      <div className={classes.separatorLine} />
      <div>
        <div className={classes.description}>Powered by</div>
        <div className={classes.logo}>
          <span>LifeBase</span>
          <span className={classes.primary}>Solutions</span>
        </div>
      </div>
    </div>
  );
};

export { CompanySubLogo };
