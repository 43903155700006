import React from 'react';

import { SortButtons } from '../../../../../../shared/components/buttons';
import { GridTableRow } from '../../../../../../shared/components/table-components';
import { SortMethods } from '../../../../../../shared/hooks/useSort';

type SchoolsHeaderRowProps = {
  sortMethods: SortMethods<'name' | 'amountOfCustomers'>;
};

const SchoolsHeaderRow = ({ sortMethods }: SchoolsHeaderRowProps) => {
  const { getIsSortedBy, handleSort } = sortMethods;

  return (
    <GridTableRow variant="header">
      <SortButtons fieldName="name" onSort={handleSort} getIsActive={getIsSortedBy}>
        Name
      </SortButtons>
      <SortButtons
        fieldName="amountOfCustomers"
        onSort={handleSort}
        getIsActive={getIsSortedBy}
      >
        Amount Of Students
      </SortButtons>
    </GridTableRow>
  );
};

export { SchoolsHeaderRow };
