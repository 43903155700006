import React from 'react';
import PropTypes from 'prop-types';

import {
  GridTableCell,
  GridTableRow,
} from '../../../../shared/components/table-components';
import { NutrientValues } from '../../NutrientValues/NutrientValues';

const DailyMealInfoRow = ({ titleText, mealInfo, withPoint = true }) => {
  return (
    <GridTableRow variant="subRow">
      <GridTableCell variant="title">{titleText}</GridTableCell>
      <NutrientValues content={mealInfo} iconShown={withPoint} coloredText={!withPoint} />
    </GridTableRow>
  );
};

export { DailyMealInfoRow };

DailyMealInfoRow.propTypes = {
  mealInfo: PropTypes.object.isRequired,
  titleText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  withPoint: PropTypes.bool,
};
