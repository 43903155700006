import React from 'react';
import PropTypes from 'prop-types';

import { TabPanel, TabsList, Tabs, Tab } from '@mui/base';

import classes from './CalendarsWithTabs.module.scss';
import { Calendar } from '../Calendar/Calendar';

// rewrite this component to use lb tabs
const CalendarsWithTabs = ({ calendar, calendarForTomorrow }) => {
  return (
    <Tabs defaultValue={0}>
      <TabsList className={classes.tabBox}>
        <Tab>Today</Tab>
        <Tab>Tomorrow</Tab>
      </TabsList>
      <TabPanel value={0}>
        <Calendar activeCalendar={calendar} isTab />
      </TabPanel>
      <TabPanel value={1}>
        <Calendar activeCalendar={calendarForTomorrow} isTab />
      </TabPanel>
    </Tabs>
  );
};

CalendarsWithTabs.propTypes = {
  calendar: PropTypes.array.isRequired,
  calendarForTomorrow: PropTypes.array.isRequired,
};

export { CalendarsWithTabs };
