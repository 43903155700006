import React from 'react';

import { observer } from 'mobx-react';
import { useUnmount } from 'react-use';
import { FormProvider, useForm } from 'react-hook-form';

import { SubscriptionSettingsFormValues } from '../../interfaces/subscriptionSettings';
import { SubscriptionSettingsFrom } from './SubscriptionSettingsFrom/SubscriptionSettingsFrom';
import { useMode } from './hooks/useMode';
import { EditButtonBlock } from './EditButtonBlock/EditButtonBlock';
import { loggedInUserDataStore } from '../../../shared/stores/loggedInUserDataStore';
import { subscriptionSettingsStore } from '../../stores/subscriptionSettingsStore';
import { processSettingsFormValues } from './utils/processSettingsFormValues';
import { taxStore } from '../../stores/taxStore';
import { useGetNecessaryData } from '../../../shared/hooks/useGetNecessaryData';
import { Mode } from '../../../general-types';
import { InfoBox } from '../../../shared/components/ui-components';

type SubscriptionSettingsWidgetProps = {
  entityId: number;
  defaultMode?: Mode;
  onSuccessSubmit?: () => void;
};

const SubscriptionSettingsWidget = observer(
  ({
    entityId,
    defaultMode,
    onSuccessSubmit = () => {},
  }: SubscriptionSettingsWidgetProps) => {
    const { isSuperAdmin } = loggedInUserDataStore;
    const { settings, submitSettings } = subscriptionSettingsStore;
    const { appliedTaxRateType, getAppliedTaxRate } = taxStore;
    const { mode, setEdit, setView } = useMode(isSuperAdmin ? defaultMode : 'VIEW');

    const formMethods = useForm<SubscriptionSettingsFormValues>({
      values: processSettingsFormValues(settings),
    });
    const { handleSubmit, reset, formState } = formMethods;

    useGetNecessaryData(async () => {
      await Promise.all([
        subscriptionSettingsStore.getSettings(entityId),
        taxStore.getTaxInfo(entityId),
      ]);
    }, [entityId]);
    useUnmount(() => {
      subscriptionSettingsStore.setDefaultValues();
      taxStore.setDefaultValues();
    });

    const handleFormSubmit = handleSubmit(async (data) => {
      await submitSettings(data);
      await getAppliedTaxRate();
      onSuccessSubmit();

      setView();
    });

    const handleCancel = () => {
      reset();
      setView();
    };

    return (
      <FormProvider {...formMethods}>
        <InfoBox
          title="Fee Terms"
          icon={
            isSuperAdmin && (
              <EditButtonBlock
                mode={mode}
                onEdit={setEdit}
                onCancel={handleCancel}
                onSubmit={handleFormSubmit}
                disabledSubmit={!formState.isDirty}
              />
            )
          }
          customContentPadding
        >
          <SubscriptionSettingsFrom
            mode={mode}
            isSaasTaxApplied={appliedTaxRateType.APPLICATION_TAX_RATE}
          />
        </InfoBox>
      </FormProvider>
    );
  },
);

export { SubscriptionSettingsWidget };
