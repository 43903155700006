const autoNgTemplateLoaderTemplate1 = require('./operator-profile.template.html');

'use strict';

(function () {
    angular
        .module('app.operatorProfile', [])
        .config(operatorProfileConfig);

    operatorProfileConfig.$inject = ['$routeProvider'];

    function operatorProfileConfig($routeProvider) {
        $routeProvider
            .when('/operator-profile/:type', {
                disableCache: true,
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'OperatorProfileController',
                controllerAs: 'vm',
                resolve: {
                    loginRequired: ['AuthService', function (AuthService) {
                        return AuthService.loginRequired();
                    }]
                }
            })
    }
})();
