'use strict';

import { getLoginRequiredResolveObj } from '../shared/utils/angularUtils';

export const inbodyModule = angular
  .module('app.inbody', [])
  .config(inbodyConfig);

inbodyConfig.$inject = ['$routeProvider'];

function inbodyConfig($routeProvider) {
  $routeProvider
    .when('/inbody-keys', {
      template: '<ngz-inbody-keys></ngz-inbody-keys>',
      resolve: getLoginRequiredResolveObj(),
    })
}
