import { makeAutoObservable } from 'mobx';

import { foodScoringService } from '../services/foodScoringService';
import { TAB_VALUES } from '../constants/TAB_VALUES';
import { resetAllStore } from '../../shared/stores/resetAllStore';
import { getFoodsWithRoundedNutrients } from '../utils';

const { WORKING_TABLE_TAB } = TAB_VALUES;

class FoodScoreStore {
  constructor() {
    this.setDefaultValues();
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.activeTab = WORKING_TABLE_TAB;
    this.workingTableData = {};
    this.workingTableTotal = 0;
    this.finalTableData = {};
    this.finalTableTotal = 0;
    this.foodScoreTypes = [];
  }

  setActiveTab(activeTab) {
    this.activeTab = activeTab;
  }

  async getWorkingTableData(page) {
    const workingTableData = await foodScoringService.getWorkingTable(page);
    this.setWorkingTableData(workingTableData);
    this.setWorkingTableTotal(workingTableData.totalElements);
  }

  setWorkingTableData(data) {
    const workingTableData = { ...data };
    workingTableData.content = getFoodsWithRoundedNutrients(data.content);
    this.workingTableData = workingTableData;
  }

  setWorkingTableTotal(workingTableTotal) {
    this.workingTableTotal = workingTableTotal;
  }

  async getFinalTableData(page) {
    const finalTableData = await foodScoringService.getFinalTable(page);
    this.setFinalTableData(finalTableData);
    this.setFinalTableTotal(finalTableData.totalElements);
  }

  setFinalTableData(data) {
    const finalTableData = { ...data };
    finalTableData.content = getFoodsWithRoundedNutrients(data.content);
    this.finalTableData = finalTableData;
  }

  setFinalTableTotal(finalTableTotal) {
    this.finalTableTotal = finalTableTotal;
  }

  async getFoodScoreTypes() {
    const foodScoreTypes = await foodScoringService.getFoodScoreTypes();
    this.setFoodScoreTypes(foodScoreTypes);
  }

  setFoodScoreTypes(foodScoreTypes) {
    this.foodScoreTypes = foodScoreTypes;
  }

  async deleteFood(foodId) {
    await foodScoringService.deleteFood(foodId);

    this.deleteFoodFromTableData(foodId);
  }

  async saveFoodScore(foodId, scoreResult) {
    await foodScoringService.saveFoodScoring(foodId, scoreResult);

    this.deleteFoodFromTableData(foodId);
  }

  deleteFoodFromTableData(foodId) {
    const { tableData, setTableData } = this.getActiveTableDataConfig();

    const newFoods = tableData.content.filter((food) => food.foodId !== foodId);
    const newTableData = { ...tableData, content: newFoods };

    setTableData(newTableData);
  }

  getActiveTableDataConfig() {
    if (this.activeTab === WORKING_TABLE_TAB) {
      return { tableData: this.workingTableData, setTableData: this.setWorkingTableData };
    } else {
      return { tableData: this.finalTableData, setTableData: this.setFinalTableData };
    }
  }
}

const foodScoreStore = new FoodScoreStore();

resetAllStore.addResetMethod(foodScoreStore.setDefaultValues);

export { foodScoreStore, FoodScoreStore };
