import {
  INBODY_TYPES,
  InbodyTypeKey,
  InbodyType,
} from '../../../../shared/constants/inbody-info/InbodyType';
import { getHumanizeInbodyType } from '../../../../shared/utils/inbodyUtils';

const renameConfig: Record<InbodyTypeKey, InbodyType> = {
  inbody_270: INBODY_TYPES.INBODY_270,
  inbody_370: INBODY_TYPES.INBODY_370,
  inbody_380H: INBODY_TYPES.INBODY_380H,
  inbody_570: INBODY_TYPES.INBODY_570,
  inbody_580H: INBODY_TYPES.INBODY_580H,
  inbody_770: INBODY_TYPES.INBODY_770,
  inbody_970: INBODY_TYPES.INBODY_970,
};

export const processInbodyTypes = (
  supportedInbodyTypes?: Record<InbodyTypeKey, boolean>,
  allowNonInbodyUsers?: boolean,
): string[] => {
  if (!supportedInbodyTypes) {
    return [];
  }

  const humanizeInbodyTypes = Object.entries(supportedInbodyTypes)
    .map(([inbodyTypeKey, isSupported]) => {
      const inbodyType = renameConfig[inbodyTypeKey as InbodyTypeKey];

      return isSupported && getHumanizeInbodyType(inbodyType);
    })
    .filter(Boolean) as string[];

  if (allowNonInbodyUsers) {
    const humanizeNonInbody = getHumanizeInbodyType(INBODY_TYPES.NON_INBODY);

    humanizeInbodyTypes.push(humanizeNonInbody);
  }

  return humanizeInbodyTypes;
};
