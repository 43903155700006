import React from 'react';

import classes from './PrivacyPolicy.module.scss';

const PrivacyPolicy = () => (
  <div className={classes.privacyPolicy}>
    <h3>Introduction</h3>
    <p>
      At LifeBase Solutions, we believe that transparency is the key to any healthy
      relationship. We appreciate that you trust us with information that is important to
      you, and we want to be transparent about how we use it.
    </p>
    <p>
      Here we describe the privacy practices for our application(s), macro exchange
      tracker app, coaching platform, software, websites, APIs, products, and services
      (the “Services”). You will learn about the data we collect, how we use it, the
      controls we give you over your information, and the measures we take to keep it
      safe.
    </p>
    <h3 className="title">Privacy Highlights</h3>
    <p>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      These "Privacy Highlights" provide an overview of some core components of our data
      handling practices.
    </p>
    <h4 className="subtitle">Information We Collect</h4>
    <p>We generally collect the following information:</p>
    <h4 className="paragraph">
      Information you share directly with us or authorize when you use our services.
    </h4>
    <p>
      We collect and process your information in many ways starting when you place an
      order or create an account on our Services, such as your login and password, name,
      email address, date of birth, gender, height, weight and mobile telephone number and
      other related identifiable information. When you access or use our Services, we
      receive certain usage data that you share. This includes information about your
      interaction with the Services such as your food logs, sleep habits, water and fluid
      consumption, or other personal health information. You may also choose to provide
      other types of information, such as a profile photo, biography, geographic details
      or community username. You may choose to communicate on the platform with coaches
      and/or other members. You may choose to connect or enable any number of biofeedback
      devices including body composition analyzers or health device monitors or trackers,
      complete research surveys, share demographic psychographic or activity interests or
      data or post on our Forums or use other messaging features. If you contact us or
      participate in a survey, contest, or promotion, we collect the information you
      submit such as your name, contact information, message and other identifiable
      information. We also collect data about the devices and computers you use to access
      the Services, including IP addresses, browser type, language, operating system,
      mobile device information (including device and application identifiers), the
      referring web page, pages visited, location (depending on the permissions you have
      granted us), and cookie information. This information can generally be categorized
      as Registration Information, Self-Reported Information, Personal Contact Information
      (PCI), and/or User Content as defined in our full Privacy Statement.
    </p>
    <h4 className="paragraph">Information from the Coaching services.</h4>
    <p>
      Our platform enables one to one coaching services. You will receive automated
      coaching from the platform and the platform will enable you to communicate with a
      live health, fitness, or wellness coach (“Coaching Services”). Your Coach will
      typically be someone with which you have contracted for his or her services.
      However, Coaches may be provided by third parties, such as your employer or
      insurance company, or by our third-party coaching service providers. If you use the
      Coaching Services, we collect information about such use, including the plan, goals,
      and actions you record with your coach, food entries and results, your calendar
      events, communications with your coach, notes your coach records about you, and
      other information submitted by you or your coach. This information also can
      generally be categorized as Registration Information, Self-Reported Information,
      and/or User Content as defined in our full Privacy Statement.
    </p>
    <h4 className="paragraph">Your Payment and card information.</h4>
    <p>
      If you sign up for services through our platform, you provide your payment
      information, including your name, credit or debit card number, card expiration date,
      CVV code, and billing address. We do not store the credit card payment information,
      but do store and retain the other related details. Note that third-party payment
      processors may retain this information in accordance with their own privacy policies
      and terms.
    </p>
    <h4 className="paragraph">Information we receive from third parties.</h4>
    <p>
      Your account will be connected to a Medical grade bio-electrical impedance body
      composition analysis device that will provide your body specific data to the
      platform. In addition, if you choose to connect your account on our Services to your
      account on another service, we may receive information from the other service. For
      example, if you connect to Facebook or Google, we may receive information like your
      name, profile picture, age range, language, email address, and friend list. You may
      also choose to grant us access to your exercise or activity data from another
      service or device like a movement tracking or body monitoring device. You can stop
      sharing the information from the other service with us by removing our access to
      that other service. We may partner with third parties, such as employers and
      insurance companies that offer our Services to their employees and customers. In
      such cases, those companies may provide us with your name, email address, or similar
      information (like a telephone number or subscriber ID) so that we can invite you to
      participate or determine your eligibility for particular benefits, such as discounts
      or free services.
    </p>
    <h4 className="paragraph">Information we receive through tracking technologies.</h4>
    <p>
      We collect Web-Behavior Information via cookies and other similar tracking
      technologies when you use and access our Services (our website, mobile apps,
      products, software and other services or websites).
    </p>
    <h4 className="paragraph">Other information we collect.</h4>
    <p>
      To the extent that information we collect is health data or another special category
      of personal data subject to the European Union’s General Data Protection Regulation
      (“GDPR”), we ask for your explicit consent to process the data. We obtain this
      consent separately when you take actions leading to our obtaining the data, for
      example, when you sign up for our Service, grant us access to your exercise or
      activity data from another service. You can use your account settings and tools to
      withdraw your consent at any time, including by stopping the use of the Service,
      removing our access to a third-party service, or deleting your non de-identified
      data or your account.
    </p>
    <h4 className="subtitle">How We Use Information</h4>
    <p>We generally process Personal Information for the following reasons:</p>
    <h4 className="paragraph">To provide, maintain improve our Services.</h4>
    <p>
      Using the information we collect, we are able to deliver the Services to you and
      honor our Terms of Service contract with you. This includes processing payments,
      shipping to customers, creating customer accounts and authenticating logins, and
      delivering results and powering tools like meal planning, nutrition analysis and
      coaching through the platform. We use your information to connect you and your
      coach, allowing you to communicate with them through our Services, and help you
      achieve your goals to lead a healthier life.
    </p>
    <h4 className="paragraph">
      To analyze, improve personalize and develop our Services.
    </h4>
    <p>
      We also use the information we collect to improve and personalize the Services and
      to develop new ones. For example, we are constantly working to improve our ability
      to grade and evaluate your nutrition and maximize the granularity of our results. We
      may also need to fix bugs or issues, analyze the use of our website to improve the
      customer experience or assess our marketing campaigns. We use the information to
      troubleshoot and protect against errors; perform data analysis and testing; conduct
      research and surveys. We also use your information to make inferences and show you
      more relevant content. We may personalize advice based upon your goals, results,
      performance or interests.
    </p>
    <h4 className="paragraph">To communicate with you.</h4>
    <p>
      We use your information to communicate with you in a number of different ways. It is
      used when needed to send you Service notifications and respond to you when you
      contact us. We use it to allow you and your coach and the company to communicate in
      one-to-one and groups (public and private) messages and chat via in-app messaging,
      SMS text messages, push notifications and email. The platform may allow users to
      send direct messages to other users in the application and feature chat history. The
      in-app messaging will feature encryption including AES-256 (data at rest) and
      SSL/TLS (data in transit) to encrypt and protect, stored information. We also use
      your information to promote new features or products that we think you would be
      interested in. You can control marketing communications and most Service
      notifications by using your notification preferences in account settings or via the
      “Unsubscribe” link in an email. In part, when we or your nutrition coach
      communicates with you your first name, last name, avatar (if any), device ID, email
      address, and phone number which are referred to as you Personal Contact Information
      (PCI) will be shared with LifeBase and Applozic (the messaging service software) for
      the delivery of Services. Upon login the PCI will be shared with Applozic to enable
      messages to be sent by both LifeBase and the nutrition coach. The PCI will not be
      retained or used by Applozic for any other purpose.
    </p>
    <h4 className="paragraph">For external processing.</h4>
    <p>
      We transfer information to our service providers, and other partners who process it
      for us, based on our instructions, and in compliance with this policy and any other
      appropriate confidentiality and security measures. These partners provide us with
      services including for customer support, information technology, payments, sales,
      marketing, data analysis, research, and surveys.
    </p>
    <h4 className="paragraph">For promotion of safety and security.</h4>
    <p>
      We use the information we collect to promote the safety and security of the
      Services, our users, and other parties. For example, we may use the information to
      authenticate users, facilitate secure payments, protect against fraud and abuse,
      conduct audits, and enforce our terms and policies. We may preserve or disclose
      information about you to comply with a law, regulation, legal process, or
      governmental request; to assert legal rights or defend against legal claims; or to
      prevent, detect, or investigate illegal activity, violations of our terms, or
      threats to the security of the Services or the physical safety of any person.
    </p>
    <h4 className="paragraph">For Nutrition Research.</h4>
    <p>
      We constantly work to improve and incorporate the latest nutrition research and
      knowledge available. Researchers and partners can utilize and include your
      de-identified Information and Self-Reported Information in a pool of customer data
      for analyses aimed at making scientific discoveries aimed at improving the Services
      or the population in general.
    </p>
    <p className="sub-paragraph">
      You have the choice to participate in non de-identified Nutrition Research by
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      providing your consent. "non de-identified Nutrition Research" refers to research
      aimed at publication in peer-reviewed journals and other research funded by the
      federal government (such as the National Institutes of Health - NIH) conducted by
      us.
    </p>
    <p className="sub-paragraph">
      We may be sponsored by, conducted on behalf of, or in collaboration with third
      parties, such as non-profit foundations, academic institutions or pharmaceutical
      companies. We may study a specific group or population, identify potential areas or
      targets for therapeutics development, conduct or support the development of drugs,
      diagnostics or devices to diagnose, predict or treat medical or other health
      conditions, work with public, private and/or non-profit entities on research
      initiatives, or otherwise create, commercialize, and apply this new knowledge to
      improve health care. Our Research uses Aggregate and/or Individual-level Information
      and Self-Reported Information as specified in the appropriate Consent Document(s).
    </p>
    <p className="sub-paragraph">
      Your Non De-identified and Self-Reported Information may be used for our Nutrition
      Research only if you have consented to this use by completing a Consent Document.
    </p>
    <h4 className="paragraph">For communication and marketing.</h4>
    <p>
      We use cookies and similar technologies for the purposes described above. We may
      share non-personal information that is aggregated or de-identified so that it cannot
      reasonably be used to identify an individual. We may disclose such information
      publicly and to third parties, for example, in public reports about nutrition data
      or trends, to partners under agreement with us, or as part of the community
      benchmarking information we provide to third parties.
    </p>
    <h4 className="paragraph">To Enable Referral information and sharing.</h4>
    <p>
      When you refer a person to us or choose to share your results with another person,
      we will ask for that person&apos;s email address. We will use their email address
      solely, as applicable, to make a referral or to communicate your sharing request to
      them, and we will let your contact know that you requested the communication. By
      participating in a referral program or by choosing to share information with another
      person, you confirm that the person has given you consent for us to communicate
      (e.g., via email) with him or her. The person you referred may contact us at{' '}
      <a href="mailto:privacy@lifebase.solutions">privacy@lifebase.solutions</a>
      to request that we remove this information from our database. For more information
      on our referral program, see our terms and conditions.
    </p>
    <h4 className="paragraph">To Conduct Surveys Or Polls, And Obtain Testimonials</h4>
    <p className="sub-paragraph">
      We value your feedback and may send you surveys, polls, or requests for testimonials
      to improve and optimize our Services. You are in control of the information you
      would like to share with us. If you do not wish to receive these requests, you can
      manage them in your Account Settings.
    </p>
    <p className="sub-paragraph">
      For individuals located in the Designated Countries: Our legal basis for processing
      your Personal Information for the purpose described above is based on our legitimate
      interest. We think it is important to continue improving our Services to ensure your
      continued enjoyment.
    </p>
    <h4 className="paragraph">For GDPR uses.</h4>
    <p>
      For personal data subject to the GDPR, we rely on several legal bases to process the
      data. These include when you have given your consent, which you may withdraw at any
      time using your account settings and other tools; when the processing is necessary
      to perform a contract with you, like the Terms of Service; and our legitimate
      business interests, such as in improving, personalizing, and developing the
      Services, marketing new features or products that may be of interest, and promoting
      safety and security as described above.
    </p>
    <h4 className="subtitle">
      Control: Your Choices, Rights To Access Your Personal Data
    </h4>
    <p>
      We give you account settings and tools to access and control your personal data, as
      described below, regardless of where you live. If you live in the European Economic
      Area, United Kingdom, and Switzerland (the “Designated Countries”), you have a
      number of legal rights with respect to your information, which your account settings
      and tools allow you to exercise, as outlined below. In addition, we give you the
      ability to share information in a variety of ways you choose.
    </p>
    <h4 className="paragraph">Accessing and Exporting Data.</h4>
    <p>
      By logging into your account, you can access much of your personal information,
      including your dashboard with your statistics. Using your account settings, you can
      also download information in a commonly used file format.
    </p>
    <h4 className="paragraph">With whom you share your information.</h4>
    <p>
      You may choose when and with whom you share your information or you may direct us to
      disclose your information to others, including friends, family members, health care
      professionals, or other individuals outside our Services, including through third
      party services that accept our data and social networks. If you choose to
      participate in a challenge or contest, information like your profile photo, posted
      messages, personal statistics, and achievements, is not governed by your privacy
      preferences and will be visible to all other participants.
    </p>
    <h4 className="paragraph">Editing and Deleting Data.</h4>
    <p>
      Your account settings let you change and delete your personal non-de identified
      information. For instance, you can edit or delete the profile data you provide and
      delete your account if you wish. If you choose to delete your account, it may take
      up to 90 days to delete data stored in our backup systems. This is due to the size
      and complexity of the systems we use to store data. We may also preserve data for
      legal reasons or to prevent harm, including as described in the How We Use
      Information section.
    </p>
    <h4 className="paragraph">Objecting to Data Use.</h4>
    <p>
      We give you account settings and tools to control our data use. For example, through
      your privacy settings, you can limit how your information is visible to other users
      of the Services; using your notification settings, you can limit the notifications
      you receive from us; and under your application settings, you can revoke the access
      of third-party applications that you previously connected to your account. If you
      live in a Designated Country, in certain circumstances, you can object to our
      processing of your information based on our legitimate interests, including as
      described in the How We Use Information section. You have a general right to object
      to the use of your information for direct marketing purposes. Please see your
      notification settings to control our marketing communications to you about other
      products. In addition to the various controls that we offer, if you reside in a
      Designated Country, you can seek to restrict our processing of your data in certain
      circumstances. Please note that you can always delete your account at any time. If
      you need further assistance regarding your rights, please contact us at
      data-protection-office@lifebase.solutions, and we will consider your request in
      accordance with applicable laws. If you reside in a Designated Country, you also
      have a right to lodge a complaint with your local data protection authority.
    </p>
    <h4 className="subtitle">Data Retention</h4>
    <p>
      We keep your account Registration Information, such as your name, email address, and
      password, for as long as your account is in existence because we need it to operate
      your account. We keep other information, like your nutrition data, until you use
      your account settings or tools to delete your account because we use this data to
      provide you with your personal statistics and other aspects of the Services. We keep
      other information, like de-identified data indefinitely. We also keep information
      about you and your use of the Services for as long as necessary for our legitimate
      business interests, for legal reasons, and to prevent harm, including as described
      in the How We Use Information and How Information Is Shared sections.
    </p>
    <h4 className="subtitle">Analytics And Advertising Services Provided By Others</h4>
    <p>
      We work with partners who provide us with analytics and advertising services. This
      includes helping us understand how users interact with the Services, serving ads on
      our behalf across the internet, and measuring the performance of those ads. These
      companies may use cookies and similar technologies to collect information about your
      interactions with the Services and other websites and applications.
    </p>
    <h4 className="paragraph">Google Analytics.</h4>
    <p>
      Google Analytics is used to perform many of the tasks listed above. We use the
      User-ID feature of Google Analytics to combine behavioral information across devices
      and sessions (including authenticated and unauthenticated sessions). We have enabled
      the following Google Analytics Advertising features: Remarketing, Google Display
      Network Impression Reporting, Google Analytics Demographics and Interest Reporting,
      and DoubleClick Campaign Manager integration. We do not merge information collected
      through any Google advertising product with individual-level information collected
      elsewhere by our Service. Learn more about how Google collects and uses data here.
      To opt out of Google Analytics Advertising Features please use Google Ad Settings.
      To opt out of Google Analytics entirely please use this link.
    </p>
    <h4 className="subtitle">Our Policies For Children</h4>
    <p>
      While nutrition is applicable to all ages, the usability of the platform is
      generally tailored to adults 18 and over. Persons under the age of 14 are not
      allowed to access the platform in any event. However we allow parents to set up
      accounts for their children (14 to 18) to use the Service with coaches. Parents or
      guardians must consent to the use of their child’s data in accordance with the
      Privacy Policy for any persons between the ages of 14 and 18. Persons between the
      ages of 14 and 18, are not permitted to create accounts unless their parent has
      consented in accordance with applicable law. If we learn that we have collected the
      personal information of a child under the relevant minimum age without parental
      consent, we will take steps to delete the information as soon as possible. Parents
      who believe that their child has submitted personal information to us and would like
      to have it deleted, may contact us at
      <a href="mailto:privacy@lifebase.solutions">privacy@lifebase.solutions</a>.
    </p>
    <h4 className="subtitle">How We Secure Information</h4>
    <p>
      We implement measures and systems to ensure confidentiality, integrity, and
      availability of our data. We work hard to keep your data safe. We use a combination
      of technical, administrative, and physical controls to maintain the security of your
      data. No method of transmitting or storing data is completely secure, however. If
      you have a security-related concern, please contact Customer Support.
    </p>
    <h4 className="paragraph">
      De-identification/Pseudonymization, encryption, and data segmentation.
    </h4>
    <p>
      Registration Information is stripped from Sensitive Information. This data is then
      assigned a random ID so the person who provided the data cannot reasonably be
      identified. We use industry standard security measures to encrypt sensitive personal
      data both when it is stored (data-at-rest) and when it is being transmitted
      (data-in-flight). Additionally, data are segmented across logical database systems
      to further prevent re-identifiability.
    </p>
    <h4 className="paragraph">Limiting access to essential personnel.</h4>
    <p>
      We limit access of information to authorized personnel, based on job function and
      role. Our access controls include multi-factor authentication, single sign-on, and a
      strict least-privileged authorization policy.
    </p>
    <h4 className="paragraph">Detecting threats and managing vulnerabilities.</h4>
    <p>
      We use state of the art intrusion detection and prevention measures to stop any
      potential attacks against its networks. We have integrated continuous vulnerability
      scanning in our build pipeline and regularly engage third-party security experts to
      conduct penetration tests.
    </p>
    <h4 className="subtitle">Risks and Considerations</h4>
    <p>
      There may be some consequences of using the Services that you haven&apos;t
      considered.
    </p>
    <h4 className="paragraph">
      While highly unlikely, you may discover things about yourself that may be upsetting
      or cause anxiety and that you may not have the ability to control or change.
    </h4>
    <h4 className="paragraph">
      In the event of a data breach it is possible that your data could be associated with
      your identity, which could be used against your interests.
    </h4>
    <h4 className="subtitle">Business Transactions</h4>
    <p>
      In the event that we go through a business transition such as a merger, acquisition
      by another company, or sale of all or a portion of the assets, your Personal
      Information will likely be among the assets transferred. In such a case, your
      information would remain subject to the promises made in any pre-existing Privacy
      Statement.
    </p>
    <h4 className="subtitle">Key Definitions</h4>
    <p>These definitions add to the descriptions above.</p>
    <h4 className="paragraph">
      Aggregate Information: information that has been combined with that of other users
      and analyzed or evaluated as a whole, such that no specific individual may be
      reasonably identified.
    </h4>
    <h4 className="paragraph">
      De-identified Information: information that has been stripped of your Registration
      Information (e.g., your name and contact information) and other identifying data
      such that you cannot reasonably be identified as an individual, also known as
      pseudonymized information.
    </h4>
    <h4 className="paragraph">
      Individual-level Information: information about a single individual&apos;s
      Nutrition, Body Composition Analysis or other traits/characteristics, but which is
      not necessarily tied to Registration Information.
    </h4>
    <h4 className="paragraph">
      Personal Information: information that can be used to identify you, either alone or
      in combination with other information. We collect and store Personal Information:
    </h4>
    <p className="sub-paragraph">
      Registration Information: includes information you provide about yourself when
      registering for and/or purchasing our Services (e.g. name, email, address, user ID
      and password, and payment information).
    </p>
    <p className="sub-paragraph">
      Blood Work Information: information regarding your blood work, generated through
      processing of your blood by us or by its contractors, successors, or assignees; or
      otherwise processed by and/or contributed to us.
    </p>
    <p className="sub-paragraph">
      Self-Reported Information: includes information you provide directly to us,
      including your disease conditions, other health-related information, personal
      traits, ethnicity, family history, and other information that you enter into
      surveys, forms, or features while signed in to your account.
    </p>
    <p className="sub-paragraph">
      Sensitive Information: includes information about your health, nutrition, and
      certain Self-Reported Information such as racial and ethnic origin, sexual
      orientation, and political affiliation.
    </p>
    <p className="sub-paragraph">
      User Content: includes all information, data, text, software, music, audio,
      photographs, graphics, video, messages, or other materials - other than Blood
      Testing, Body Composition or Nutrition Information and Self-Reported
      Information-generated by users of our Services and transmitted, whether publicly or
      privately, to or through us.
    </p>
    <p className="sub-paragraph">
      Web-Behavior Information: includes information on how you use our Services collected
      through log files, cookies, web beacons, and similar technologies, (e.g., browser
      type, domains, page views).
    </p>
    <h4 className="subtitle">Changes to this Privacy Statement</h4>
    <p>
      Whenever this Privacy Statement is changed in a material way, a notice will be
      posted as part of this Privacy Statement and on our website for 30 days. After 30
      days the changes will become effective should you wish to continue to use the
      Services. We may provide additional &quot;just-in-time&quot; disclosures or
      additional information about the data collection, use and sharing practices of
      specific Services. Such notices may supplement or clarify our privacy practices or
      may provide you with additional choices about how we process your Personal
      Information.
    </p>
    <h4 className="subtitle">Contact Information</h4>
    <p>
      If you have questions about this Privacy Statement, or wish to submit a complaint,
      please email our Privacy Administrator at{' '}
      <a href="mailto:privacy@lifebase.solutions">privacy@lifebase.solutions</a>, or send
      a letter to:
    </p>
    <p>Privacy Administrator</p>
    <p>LifeBase Solutions LLC</p>
    <p>4800 S. Louise Ave, #308</p>
    <p>Sioux Falls, SD 57106</p>
    <p className="notate">
      *This Privacy Statement was last updated on September 17, 2024.
    </p>
  </div>
);

export { PrivacyPolicy };
