import { useEffect } from 'react';

import { debounce } from 'lodash';
import { FieldValues, Path, UseFormWatch } from 'react-hook-form';

export type UseFormChangeProps<TFieldValues extends FieldValues> = {
  watch: UseFormWatch<TFieldValues>;
  onChange: (data: TFieldValues) => void;
  ignoreFields?: Path<TFieldValues>[];
};
/**
 * Required set defaultValues in useForm.
 * Can not use shouldUnregister because watch is not working properly, to match changes in form.
 * Call onChange even when the form is not valid.
 */
export const useFormChange = <TFieldValues extends FieldValues>({
  onChange,
  watch,
  ignoreFields = [],
}: UseFormChangeProps<TFieldValues>) => {
  useEffect(() => {
    const debouncedOnChange = debounce(onChange, 800);

    const subscription = watch((value, { name }) => {
      const isIgnoreField = ignoreFields.includes(name!);

      if (isIgnoreField) {
        return;
      }

      debouncedOnChange(value as TFieldValues);
    });
    return () => subscription.unsubscribe();
  }, [watch]);
};
