const autoNgTemplateLoaderTemplate1 = require('./entity-stats.template.html');

'use strict';

(function () {
    angular
        .module('app.entityStats', [])
        .config(entityStatsConfig);

    entityStatsConfig.$inject = ['$routeProvider'];

    function entityStatsConfig($routeProvider) {
        $routeProvider
            .when('/entity-stats', {
                disableCache: true,
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'EntityStatsController',
                controllerAs: 'vm',
                resolve: {
                    loginRequired: ['AuthService', function (AuthService) {
                        return AuthService.loginRequired();
                    }]
                }
            })
    }
})();
