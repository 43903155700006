import { apiService } from './apiService';

type ValidateZipCodeOptions = {
  ignoreErrorCodes?: number[];
  onError?: (message: string) => void;
  isRunGlobalSpinner?: boolean;
};

export const validationService = {
  // for all users, logged in and not logged in
  async validateZipCode(
    zipCode: string,
    state: string,
    {
      ignoreErrorCodes = [409], // 409 is the default error code for zip code validation
      isRunGlobalSpinner,
      onError,
    }: ValidateZipCodeOptions = {},
  ) {
    const params = { state, zipCode };

    try {
      await apiService.get<undefined>('/api/sign-up/validate-zip-code', {
        params,
        ignoreErrorCodes,
        isRunGlobalSpinner,
      });
    } catch (error: any) {
      if (onError) {
        const message =
          error.response?.data?.message || 'Invalid Zip Code for the provided state';
        onError(message);
      }

      throw error;
    }
  },
};
