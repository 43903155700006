import { apiService } from './apiService';

const getAvailableModules = async (entityType) => {
  const params = { entity_type: entityType };

  const resp = await apiService.get('api/paid-service/available-types', { params });

  return resp.data;
};

const getEnabledModules = async (entityId) => {
  const resp = await apiService.get(
    `api/paid-service/entity/${entityId}/enabled_modules`,
  );

  return resp.data;
};

const getCustomerAvailableModules = async (customerId) => {
  const resp = await apiService.get(
    `api/paid-service/customer/${customerId}/available_modules`,
  );

  return resp.data;
};

export const paidModulesService = {
  getEnabledModules,
  getAvailableModules,
  getCustomerAvailableModules,
};
