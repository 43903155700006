import React from 'react';

import classes from './InfoCell.module.scss';

type InfoCellProps = {
  title: string;
  info: string | number;
  adornment?: string;
};

const InfoCell = ({ title, info, adornment }: InfoCellProps) => {
  return (
    <div className={classes.cell}>
      <h6 className={classes.title}>{title}</h6>
      <p className={classes.info}>
        {info}&nbsp;
        <span className={classes.adornment}>{adornment}</span>
      </p>
    </div>
  );
};

export { InfoCell };
