import React from 'react';

import { useForm } from 'react-hook-form';

import classes from './FilterRow.module.scss';
import { LbInput } from '../../../../../../../shared/components/form-components/LbInput/LbInput';
import { complianceStore } from '../../../../../../stores/complianceStore';
import { GridTableRow } from '../../../../../../../shared/components/table-components';
import { ApplyAndRestBtn } from '../../../../../../../shared/components/buttons';

const FilterRow = () => {
  const { control, handleSubmit, reset } = useForm();

  const { filterMethod } = complianceStore;
  const { applyFilters } = filterMethod;

  const handleResetForm = () => {
    reset();
    applyFilters({});
  };

  return (
    <GridTableRow variant="filter" component="form">
      <LbInput
        control={control}
        variant="outlined"
        size="small"
        name="name"
        label="Full name"
        helperText={null}
        dataTestId="name-input"
      />
      <ApplyAndRestBtn
        onSubmit={handleSubmit(applyFilters)}
        onReset={handleResetForm}
        className={classes.submitBtn}
      />
    </GridTableRow>
  );
};

export { FilterRow };
