import React, { forwardRef } from 'react';

import { CustomContentProps, closeSnackbar } from 'notistack';
import { LbAlert } from '../../../components/ui-components';

const LbSnackbarAlert = forwardRef<HTMLDivElement, CustomContentProps>(
  ({ variant, message, id }, ref) => {
    return (
      <LbAlert ref={ref} severity={variant} onClose={() => closeSnackbar(id)}>
        {message}
      </LbAlert>
    );
  },
);

export { LbSnackbarAlert };
