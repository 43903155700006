(function () {
    'use strict';

    angular.module('app.supplementList').controller('SupplementListController', SupplementListController);


    SupplementListController.$inject = ['$rootScope', 'utilService', 'SupplementService', 'ModalService', 'constants'];

    function SupplementListController($rootScope, utilService, SupplementService, ModalService, constants) {
        var vm = this;

        vm.loggedInUser = utilService.getLoggedInUserData();
        vm.usersList = [];
        vm.userIds = [];
        vm.checkedUsers = [];
        vm.spinner = {active: false};
        vm.currentDate = new Date();
        vm.minDate = new Date(moment(new Date()).subtract(+100, 'years'));
        vm.startInFilterIsOpen = false;
        vm.pagination = {};
        vm.pagesCount = 0;
        vm.statusList = [
            {id: 'all', name: 'All'},
            {id: 'incomplete', name: 'Incomplete'},
            {id: 'canceled', name: 'Canceled'},
            {id: 'active', name: 'Active'},
            {id: 'payment_issue', name: 'Payment issue'}
        ];
        vm.isChildEntity = utilService.getIsChildEntity();

        vm.init = init;
        vm.changeUserContactState = changeUserContactState;
        vm.setFilterStartDate = setFilterStartDate;
        vm.fieldOrder = fieldOrder;
        vm.checkedAllUsers = checkedAllUsers;
        vm.showContactDialog = showContactDialog;
        vm.goTo = utilService.goTo;
        vm.getAllSupplements = getAllSupplements;
        vm.displayCheckedUsers = displayCheckedUsers;
        vm.findInArray = findInArray;
        vm.displayReassignCoachesDialog = displayReassignCoachesDialog;
        vm.isActiveSort = isActiveSort;
        vm.savePageParam = savePageParam;

        vm.init();

        function init() {
            var savedParams = utilService.getCurrentPage(
                constants.pages.supplementUserList,
                constants.filters.supplementUserList
            );
            var page = savedParams[0];
            var filters = savedParams[1];

            vm.filterFields = page && filters ? filters : {
                by_name: '', email: '', by_coache_name: '',
                by_phone: '', by_register_date_start: '',
                city: '', order: '', order_field: '', pStatus: vm.statusList[0], limit: 20, page: 0
            };

            vm.getAllSupplements(page ? page : 0, filters === null, page !== null);
        }

        function savePageParam() {
            localStorage.setItem(constants.pages.supplementUserList, angular.copy(vm.filterFields.page));
            localStorage.setItem(constants.filters.supplementUserList, JSON.stringify(angular.copy(vm.filterFields)));
        }

        function changeUserContactState(index) {
            if (!vm.usersList[index].checked) {
                vm.checkedUsers.push(vm.usersList[index].id);
            } else {
                vm.checkedUsers.splice(vm.checkedUsers.indexOf(vm.usersList[index].id), 1);
            }
        }

        function setFilterStartDate() {
            vm.startInFilterIsOpen = false;
            vm.filterFields.startDateFormat = moment(vm.filterFields.by_register_date_start).format('M.D.YYYY');
        }

        function fieldOrder(field, order) {
            vm.filterFields.order_field = field;
            vm.filterFields.order = order;
            vm.getAllSupplements(0, false);
        }

        function checkedAllUsers() {
            if (vm.checkedUsers.length === vm.userIds.length) {
                for (var i = 0; i < vm.usersList.length; i++) {
                    vm.usersList[i].checked = false;
                }
                vm.checkedUsers = [];
            } else {
                for (var j = 0; j < vm.usersList.length; j++) {
                    vm.usersList[j].checked = true;
                }
                vm.checkedUsers = angular.copy(vm.userIds);
            }
        }

        function findInArray(array, value) {
            for (var i = 0; i < array.length; i++) {
                if (array[i] === value) {
                    return i;
                }
            }
            return -1;
        }

        function displayCheckedUsers() {
            vm.usersList.map(function (item) {
                item.checked = vm.findInArray(vm.checkedUsers, item.id) > -1;
            });
        }

        function showContactDialog(event) {
            event.preventDefault();
            ModalService.sendMessage({via: 'email', userIds: angular.copy(vm.checkedUsers)}, vm.userIds, null, false);
        }

        function getAllSupplements(page, initialList, hasPageParam) {
            vm.isInitialList = initialList;
            vm.filterFields.page = page;

            var prms = angular.copy(vm.filterFields);
            if (vm.filterFields.by_register_date_start) {
                prms.by_register_date_start = moment(vm.filterFields.by_register_date_start).format('MM-DD-YYYY');
            }

            delete prms.startDateFormat;
            var statusFilter = prms.pStatus.id;
            prms['pStatus'] = statusFilter;

            vm.spinner.active = true;

            SupplementService.getAllSupplements(prms).then(function (res) {
                vm.spinner.active = false;

                if (res.status === 200) {
                    vm.usersList = res.data.users;
                    vm.userIds = res.data.ids;
                    vm.displayCheckedUsers();
                    vm.pagesCount = Math.ceil(vm.userIds.length / 20);
                    if (hasPageParam) {
                        $rootScope.$broadcast('changePage', {page: page})
                    } else if (prms.page === 0) {
                        vm.pagination.reset(vm.pagesCount);
                    }
                }
            });
        }

        function displayReassignCoachesDialog() {
            ModalService.reassignCoaches(vm.usersList, vm.getAllSupplements);
        }

        function isActiveSort(order, field) {
            return vm.filterFields.order === order && vm.filterFields.order_field === field;
        }
    }
})();
