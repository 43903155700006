import { ValueOfKey } from '../../../utils-types';

export const FILE_EXTENSION = {
  PDF: 'pdf',
  CSV: 'csv',
  DOC: 'doc',
  JPG: 'jpg',
  PNG: 'png',
  TXT: 'txt',
  XLS: 'xls',
} as const;

export type FileExtensionType = ValueOfKey<typeof FILE_EXTENSION>;
export type DownloadedFileExtension = Extract<FileExtensionType, 'pdf' | 'csv'>;
