import { useEffect, useState } from 'react';

import { getRouteParam } from '../../../../../../shared/utils/angularUtils';
import { getFormattedToday } from '../../../../../../shared/utils/dateUtils';
import { reportService } from '../../../../../services/reportService';

const useMealReportsTabData = () => {
  const [mealReportsInfo, setMealReportsInfo] = useState(null);
  const userId = getRouteParam('id');

  useEffect(() => {
    const todayDate = getFormattedToday();
    getAdaptedMealReports(todayDate);
  }, []);

  const getAdaptedMealReports = async (date) => {
    const mealReports = await reportService.getMealReports(userId, date);

    setMealReportsInfo(mealReports);
  };

  return { mealReportsInfo, getAdaptedMealReports };
};

export default useMealReportsTabData;
