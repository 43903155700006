import React from 'react';
import { complianceMealTimesConfig } from '../../constants/complianceMealTimesConfig';
import { GoalTitleCell } from './GoalTitleCell/GoalTitleCell';

const ComplianceTitleCells = () => {
  return (
    <>
      {complianceMealTimesConfig.map((meal) => (
        <p key={meal.id}>{meal.title}</p>
      ))}
      <GoalTitleCell goalTitle="Water" />
      <GoalTitleCell goalTitle="Sodium" />
    </>
  );
};

export { ComplianceTitleCells };
