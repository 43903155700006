import React from 'react';
import PropTypes from 'prop-types';

import classes from '../SubscriptionBlock/SubscriptionBlock.module.scss';
import { subscriptionProps } from '../Subscription.setting';

const SubscriptionBlockBody = ({ subscription }) => {
  const { initialSubscriptionAmount, renewalSubscriptionAmount } = subscription;
  const subscriptionDetails = [
    {
      id: 1,
      title: 'Initial Membership subscription *',
      value: `$ ${initialSubscriptionAmount}`,
    },
    { id: 2, title: 'Monthly auto renew fee *', value: `$ ${renewalSubscriptionAmount}` },
    {
      id: 3,
      title: 'Total amount due by user',
      value: `$ ${initialSubscriptionAmount} + sales tax`,
    },
  ];

  return (
    <div className={classes.contentContainer}>
      {subscriptionDetails.map((detail) => (
        <div key={detail.id}>
          <div className={classes.contentInfoTitle}>{detail.title}</div>
          <div className={classes.contentInfoData}>{detail.value}</div>
        </div>
      ))}
    </div>
  );
};

SubscriptionBlockBody.propTypes = {
  subscription: PropTypes.shape(subscriptionProps),
};

export { SubscriptionBlockBody };
