import { Option } from '../../general-types';
import { ValueOfKey } from '../../utils-types';

export const BODY_FAT_CALC_METHOD = {
  BEST_GUESS: 'BEST_GUESS',
  CALIPERS: 'CALIPERS',
  BIO_IMPEDANCE_DEVICE: 'BIO_IMPEDANCE_DEVICE',
  BOD_POD: 'BOD_POD',
  DEXA_MACHINE: 'DEXA_MACHINE',
  OTHER: 'OTHER',
} as const;

export type BodyFatCalcMethodType = ValueOfKey<typeof BODY_FAT_CALC_METHOD>;

export const bodyFatCalcMethodOptions: Option<BodyFatCalcMethodType>[] = [
  { value: 'BEST_GUESS', label: 'Best Guess' },
  { value: 'CALIPERS', label: 'Calipers' },
  { value: 'BIO_IMPEDANCE_DEVICE', label: 'Bio-Impedance Device' },
  { value: 'BOD_POD', label: 'Bod Pod' },
  { value: 'DEXA_MACHINE', label: 'Dexa Machine' },
  { value: 'OTHER', label: 'Other' },
];
