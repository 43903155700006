import React from 'react';
import PropTypes from 'prop-types';

import { Link } from '@mui/material';

import classes from './CustomerInfoBox.module.scss';

const CustomerInfoBox = ({ customerName, customerEmail, customerPhone, customerId }) => {
  return (
    <div className={classes.userInfoBox}>
      <Link href={`#!/customer-profile/${customerId}`} className={classes.userInfo}>
        <h6>{customerName}</h6>
        <p>{customerEmail}</p>
      </Link>
      <p>{customerPhone}</p>
    </div>
  );
};

CustomerInfoBox.propTypes = {
  customerName: PropTypes.string,
  customerEmail: PropTypes.string,
  customerPhone: PropTypes.string,
  customerId: PropTypes.number,
};

export { CustomerInfoBox };
