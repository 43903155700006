import {
  localDataFormat,
  oldDateFormat,
} from '../../../../../shared/constants/apiDateFormat';
import { getFormattedDate } from '../../../../../shared/utils/dateUtils';

export const getStartDayInfo = (subscriptionStart: string | null) => {
  if (subscriptionStart) {
    return getFormattedDate(subscriptionStart, localDataFormat, oldDateFormat);
  }

  return 'Not set up';
};
