export const dateTimeFormatWithTimezone = 'YYYY-MM-DDTHH:mm:ss.SSSZ'; // 2024-09-09T19:12:18.000Z or 2024-08-01T00:00:00.000+03:00

export const dateTimeFormat = 'YYYY-MM-DDTHH:mm:ss'; // 2020-01-01T00:00:00
export const dateFormat = 'YYYY-MM-DD';
export const dateMonthFormat = 'YYYY-MM';
export const timeFormat = 'HH:mm';

export const oldDateFormat = 'MM.DD.YYYY';

export const localTimeFormat = 'hh:mm a';
export const localDataFormat = 'MMM DD YYYY';
export const localMonthFormat = 'MMM YYYY';
export const localDateTimeFormat = 'MMM DD YYYY hh:mm a';
