(function () {
    'use strict';

    angular.module('app.customersDashboard').controller('CustomersDashboardController', CustomersDashboardController);

    CustomersDashboardController.$inject = ['$scope', 'utilService', 'constants', 'PaidModulesUtil'];
    // TODO add test for new setSelectedTab, initSelectedTab, processStorageSetItemEvent

    function CustomersDashboardController($scope, utilService, constants, PaidModulesUtil) {
        var vm = this;
        vm.loggedInUser = utilService.getLoggedInUserData();
        vm.enabledModules = {};
        vm.tabNames = {
            NUTRITION: 'NUTRITION',
            SCHOLASTIC: 'SCHOLASTIC',
            ACTIVE: 'ACTIVE',
            INVENTORY: 'INVENTORY',
            COMPLIANCE: 'COMPLIANCE',
            VIDEOS: 'VIDEOS',
        };
        vm.selectedTab = null;

        vm.init = init;
        vm.setEnabledModules = setEnabledModules;
        vm.initSelectedTab = initSelectedTab;
        vm.setSelectedTab = setSelectedTab;
        vm.processStorageSetItemEvent = processStorageSetItemEvent;

        $scope.$on('LocalStorageModule.notification.setitem', vm.processStorageSetItemEvent);


        init();

        function init() {
            if (vm.loggedInUser.role === 'AGENT') {
                vm.setEnabledModules();
            }
        }

        function processStorageSetItemEvent(event, data) {
            const enabledModulesKey = constants.enums.storageKeys.ENABLED_MODULES;

            if (data.key === enabledModulesKey) {
                vm.setEnabledModules()
            }
        }

        function setEnabledModules() {
            vm.enabledModules = PaidModulesUtil.getEnabledModules();

            vm.initSelectedTab();

            utilService.applyAsyncFunc($scope);
        }

        function initSelectedTab() {
            if (vm.enabledModules.NUTRITION) {
                vm.setSelectedTab(vm.tabNames.NUTRITION);
            } else if (vm.enabledModules.ACTIVE_CALENDAR) {
                vm.setSelectedTab(vm.tabNames.ACTIVE);
            } else if (vm.enabledModules.SCHOLASTIC) {
                vm.setSelectedTab(vm.tabNames.SCHOLASTIC);
            }
        }

        function setSelectedTab(selectedTabName) {
            vm.selectedTab = selectedTabName;
        }
    }
})();
