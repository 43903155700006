import { useEffect, useMemo, useState } from 'react';
import { activityService } from '../services/activityService';
import { ActivityInfo } from '../interfaces/activities';
import { ActivityType } from '../constants/activityType';

type UseActivitiesListReturn = {
  workouts: ActivityInfo<'WORKOUT'>[];
  sports: ActivityInfo<'SPORT'>[];
};

export const useActivitiesList = (): UseActivitiesListReturn => {
  const [activitiesList, setActivitiesList] = useState<ActivityInfo[]>([]);

  const getActivitiesList = async () => {
    const { activities } = await activityService.getActivitiesList();

    setActivitiesList(activities);
  };

  useEffect(() => {
    getActivitiesList();
  }, []);

  const filterActivities = <TType extends ActivityType>(activityType: TType) =>
    activitiesList.filter(
      (activity) => activity.activityType === activityType,
    ) as ActivityInfo<TType>[];

  const workouts = useMemo(() => filterActivities('WORKOUT'), [activitiesList]);

  const sports = useMemo(() => filterActivities('SPORT'), [activitiesList]);

  return { workouts, sports };
};
