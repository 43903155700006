import React from 'react';

import classes from './HeaderRow.module.scss';
import { GridTableRow } from '../../../../../../shared/components/table-components';

const HeaderRow = () => {
  return (
    <GridTableRow variant="subRow" className={classes.titleRow}>
      <div>InBody Key (API Key)</div>
      <div>Webhooks ID</div>
      <div>Description</div>
    </GridTableRow>
  );
};

export { HeaderRow };
