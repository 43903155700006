import React from 'react';
import {
  GridStickyGroup,
  GridTableCell,
  GridTableRow,
} from '../../../../../shared/components/table-components';
import { SortButtons } from '../../../../../shared/components/buttons';
import { SortMethods } from '../../../../../shared/hooks/useSort';

type HeaderRowProps = SortMethods<'name'>;

const HeaderRow = ({ handleSort, getIsSortedBy }: HeaderRowProps) => {
  return (
    <GridTableRow variant="header">
      <GridStickyGroup>
        <GridTableCell>
          <SortButtons fieldName="name" onSort={handleSort} getIsActive={getIsSortedBy}>
            Name
          </SortButtons>
        </GridTableCell>
      </GridStickyGroup>
      <GridTableCell>Code</GridTableCell>
      <GridTableCell>Discount</GridTableCell>
      <GridTableCell>Usage Limit</GridTableCell>
      <GridTableCell>Activations</GridTableCell>
      <GridTableCell>Subscription Type</GridTableCell>
      <GridTableCell>End Date</GridTableCell>
      <GridTableCell>Status</GridTableCell>
      <GridTableCell>Active</GridTableCell>
    </GridTableRow>
  );
};

export { HeaderRow };
