'use strict';

angular.module('app').factory('PosService', PosService);

PosService.$inject = ['ApiService'];

function PosService(ApiService) {
    return {
        getSyncStatus: function (entityId) {
            var url = entityId ? 'api/super/pos/status/' + entityId : 'api/admin/pos/status';
            return ApiService.get(url).then(function (res) {
                    localStorage.setItem('posStatus', res.data.status);
                    localStorage.setItem('posErrors', JSON.stringify(res.data.errors[0]));
                    return res;
                }, function (err) {
                    return err
                }
            )
        },
        connectManuallyByUser: function (userRole, error) {
            return ApiService.post('api/' + userRole + '/pos/user-sync/' + error.userId).then(function (res) {
                    return res;
                }, function (err) {
                    return err;
                }
            );
        },
        connectEntityToLightspeedPosSystem: function (tempToken) {
            return ApiService.post('api/admin/pos/connect-lightspeed/' + tempToken).then(function (res) {
                    return res;
                }, function (err) {
                    return err;
                }
            );
        },
        unlinkLightspeedPosAccountFromEntity: function () {
            return ApiService.post('api/admin/pos/unlink-lightspeed').then(function (res) {
                    return res;
                }, function (err) {
                    return err;
                }
            );
        },
        getListOfCustomerTypesFromPosSystem: function () {
            return ApiService.get('api/admin/pos/retrieve-customer-types').then(function (res) {
                    return res;
                }, function (err) {
                    return err;
                }
            );
        },
        connectManually: function (entityId) {
            var url = entityId ? 'api/super/pos/manual-sync/' + entityId : 'api/admin/pos/manual-sync';
            return ApiService.post(url).then(function (result) {
                    return result;
                }, function (err) {
                    return err;
                }
            );
        },
    };
}
