import React, { useState } from 'react';

import { angularize } from 'react-in-angularjs';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import classes from './NgzPerformanceMetricsTab.module.scss';
import { SystemProviders } from '../../../../../shared/providers';
import { PageTitle } from '../../../../../shared/components/ui-components';
import { DateRangePicker } from '../../../../../shared/components/form-components';
import { getFormattedDate } from '../../../../../shared/utils/dateUtils';
import { dateFormat, oldDateFormat } from '../../../../../shared/constants/apiDateFormat';
import { MealStatisticWidget } from './MealStatisticWidget/MealStatisticWidget';
import { AvgPerformanceScoreWidget } from './AvgPerformanceScoreWidget/AvgPerformanceScoreWidget';
import { WaterConsumptionWidget } from './WaterConsumptionWidget/WaterConsumptionWidget';
import { PerformanceMetricsParams } from '../../../../interfaces/performance-metrics/PerformanceMetricsParams';
import { getDefaultDateRange } from './utils/getDefaultDateRange';
import { NutrientMetricsBlock } from './NutrientMetricsBlock/NutrientMetricsBlock';
import { useAutoSubmitForm } from '../../../../../shared/hooks/useAutoSubmitForm';

type NgzPerformanceMetricsTabProps = {
  customerId: number;
  registrationDate: string; // 'MM.DD.YYYY'
};

const NgzPerformanceMetricsTab = ({
  customerId,
  registrationDate,
}: NgzPerformanceMetricsTabProps) => {
  const customerRegistrationDate = getFormattedDate(
    registrationDate,
    dateFormat,
    oldDateFormat,
  );
  const defaultDateRange = getDefaultDateRange(customerRegistrationDate);

  const [params, setParams] = useState<PerformanceMetricsParams>(defaultDateRange);
  const { control, watch, handleSubmit } = useForm<PerformanceMetricsParams>({
    defaultValues: defaultDateRange,
  });

  useAutoSubmitForm(handleSubmit(setParams), watch);

  return (
    <SystemProviders>
      <PageTitle title="Performance Metrics">
        <DateRangePicker
          control={control}
          size="medium"
          variant="outlined"
          startPickerProps={{
            name: 'dateFrom',
            label: 'Start Date',
            minDate: moment(customerRegistrationDate),
            rules: { required: 'Start date is required' },
          }}
          endPickerProps={{
            name: 'dateTo',
            label: 'End Date',
            disableFuture: true,
            rules: { required: 'End date is required' },
          }}
          dataTestId="date-range-picker"
        />
      </PageTitle>
      <div className={classes.row}>
        <MealStatisticWidget customerId={customerId} params={params} />
        <AvgPerformanceScoreWidget customerId={customerId} params={params} />
      </div>
      <NutrientMetricsBlock customerId={customerId} params={params} />
      <WaterConsumptionWidget customerId={customerId} params={params} />
    </SystemProviders>
  );
};

angularize(
  NgzPerformanceMetricsTab,
  'ngzPerformanceMetricsTab',
  angular.module('app.nutrition'),
  { customerId: '<', registrationDate: '<' },
);

export { NgzPerformanceMetricsTab };
