import { userRoles } from '../../../constants/userRoles';

export const getHumanizeUserRole = (userRole) => {
  const userRolesMap = {
    [userRoles.SUPERUSER]: 'Super Admin',
    [userRoles.ADMIN]: 'Entity Admin',
    [userRoles.AGENT]: 'Coach',
    [userRoles.CUSTOMER]: 'User',
    [userRoles.FOOD_ADMIN]: 'Food Admin',
  };

  return userRolesMap[userRole];
};
