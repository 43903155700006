import React from 'react';
import PropTypes from 'prop-types';

import classes from './MarkRow.module.scss';

const MarkRow = ({ text }) => {
  return <div className={classes.markRow}>{text}</div>;
};

MarkRow.propTypes = {
  text: PropTypes.string,
};

export { MarkRow };
