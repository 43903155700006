export const addValidateRules = (rules, validate) => {
  if (rules?.validate) {
    rules.validate = { ...rules.validate, ...validate };
    return rules;
  } else if (rules) {
    rules.validate = validate;
    return rules;
  } else {
    return {
      validate,
    };
  }
};
