import React from 'react';
import PropTypes from 'prop-types';

import { FileExtensionImage } from '../../../../../../../../shared/components/ui-components/FileExtensionImage/FileExtensionImage';
import classes from './CertificatePreview.module.scss';

const CertificatePreview = ({ preview, fileExtension, fileUrl }) => {
  return (
    <a className={classes.certificatePreview} href={fileUrl} download>
      {preview ? (
        <img src={preview} alt="certificatePreview" />
      ) : (
        <div className={classes.fileExtensionBox}>
          <FileExtensionImage fileExtension={fileExtension} />
        </div>
      )}
    </a>
  );
};

CertificatePreview.propTypes = {
  preview: PropTypes.string,
  fileExtension: PropTypes.string.isRequired,
  fileUrl: PropTypes.string.isRequired,
};

export { CertificatePreview };
