import { apiService } from '../../shared/services/apiService';
import { GroupInfo } from '../interfaces/GroupInfo';

type GetGroupListResponse = {
  groups: GroupInfo[];
};

export const customerGroupService = {
  async getGroupList(isRunGlobalSpinner?: boolean) {
    const resp = await apiService.get<GetGroupListResponse>(
      '/api/coach/groups/get-all-customer-groups',
      { isRunGlobalSpinner },
    );

    return resp.data;
  },

  async createGroup(groupName: string) {
    const resp = await apiService.post<GroupInfo>('api/coach/groups/create', {
      name: groupName,
    });

    return resp.data;
  },

  async assignCustomersToGroup(groupId: number, customerIds: number[]) {
    const resp = await apiService.put<GroupInfo>(`api/coach/groups/${groupId}/assign`, {
      ids: customerIds,
    });

    return resp.data;
  },
};
