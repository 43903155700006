import React, { useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { Button } from '@mui/material';
import { useAsyncFn } from 'react-use';

import { observer } from 'mobx-react';
import classes from './ManageMembersModal.module.scss';
import { LbBigModal } from '../../../../../shared/components/modals';
import { LbInput } from '../../../../../shared/components/form-components';
import { MembersTable } from './MembersTable/MembersTable';
import { validateEmail } from '../../../../../shared/utils/reactHookFormUtils';
import { freeAccessStore } from '../../../../stores/freeAccessStore';

type ManageMembersModalProps = {
  open: boolean;
  onClose: () => void;
  entityId: number;
  freeSeatsCount: number;
  freeSeatsUsed: number;
};

type ManageMembersFormValues = {
  email: string;
};

const ManageMembersModal = observer(
  ({
    open,
    onClose,
    entityId,
    freeSeatsCount,
    freeSeatsUsed,
  }: ManageMembersModalProps) => {
    const {
      freeAccessMembers,
      getFreeAccessMembers,
      inviteFreeAccessMember,
      deleteFreeAccessMember,
    } = freeAccessStore;

    const { control, handleSubmit, reset } = useForm<ManageMembersFormValues>();

    useEffect(() => {
      getFreeAccessMembers(entityId);
    }, []);

    const [submitState, handleFromSubmit] = useAsyncFn(
      handleSubmit(async ({ email }) => {
        await inviteFreeAccessMember(entityId, email);

        reset();
      }),
    );

    const [deleteState, handleDelete] = useAsyncFn(async (memberId: number) => {
      await deleteFreeAccessMember(entityId, memberId);
    });

    const Description = (
      <p>
        <span className={classes.seatsCount}>
          {freeSeatsUsed}/{freeSeatsCount} Free seats.
        </span>
        &nbsp;
        <span>
          Please enter email and then have user complete the normal <br /> registration
          process
        </span>
      </p>
    );

    return (
      <LbBigModal
        title="Manage members"
        open={open}
        isLoading={submitState.loading || deleteState.loading}
        onClose={onClose}
        description={Description}
        buttonOptions={{
          noHandler: onClose,
          noText: 'Close',
          yesVisible: false,
        }}
      >
        <div className={classes.container}>
          <div className={classes.formContainer}>
            <LbInput
              control={control}
              name="email"
              type="email"
              label="Enter email"
              rules={{
                required: 'Email is required',
                validate: { validateEmail },
              }}
              variant="outlined"
              size="small"
            />
            <Button
              variant="contained"
              type="submit"
              onClick={handleFromSubmit}
              size="small"
            >
              Add
            </Button>
          </div>
          <MembersTable members={freeAccessMembers} onDelete={handleDelete} />
        </div>
      </LbBigModal>
    );
  },
);

export { ManageMembersModal };
