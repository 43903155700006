import React from 'react';
import PropTypes from 'prop-types';

import { Box, Button } from '@mui/material';

import classes from './ButtonRow.module.scss';

const ButtonRow = ({
  handlerNext = () => {},
  isDisabled = false,
  submitBtnName = 'Next',
  skipHandler = () => {},
  showSubmit = true,
  cancelBtnName,
  children,
}) => {
  return (
    <Box className={classes.box}>
      {children}
      <div className={classes.submitRow}>
        {cancelBtnName && (
          <Button variant="outlined" onClick={skipHandler}>
            {cancelBtnName}
          </Button>
        )}
        {showSubmit && (
          <Button
            variant="contained"
            type="submit"
            onClick={handlerNext}
            disabled={isDisabled}
          >
            {submitBtnName}
          </Button>
        )}
      </div>
    </Box>
  );
};

ButtonRow.propTypes = {
  handlerNext: PropTypes.func,
  isDisabled: PropTypes.bool,
  submitBtnName: PropTypes.string,
  skipHandler: PropTypes.func,
  showSubmit: PropTypes.bool,
  cancelBtnName: PropTypes.string,
  children: PropTypes.any,
};

export { ButtonRow };
