import React from 'react';

import { useFormContext } from 'react-hook-form';
import { Button } from '@mui/material';

import { ComboBox, LbSelect } from '../../../../../../../components/form-components';
import { usePositionOptions } from './hooks/usePositionOptions';
import { CustomFilterFormValues } from '../../AddCustomFilterForm.settings';
import { useClearFilter } from '../../hooks/useClearFilter';
import { ModalInputBox, ModalInputGroup } from '../../../../../../../components/modals';
import { sportOptions } from '../../../../../../../constants/user-info/sport';

const ShoolFilters = () => {
  const { control, register } = useFormContext<CustomFilterFormValues>();
  const positionOptions = usePositionOptions();
  const { disabled, handleClearFields } = useClearFilter('SCHOOL', {
    position: '',
    sport: null,
  });

  return (
    <ModalInputBox
      title="School Filters"
      adornment={
        <Button
          size="small"
          variant="text"
          color="secondary"
          onClick={handleClearFields}
          disabled={disabled}
        >
          Clear
        </Button>
      }
    >
      <input {...register('filters.SCHOOL.filterType', { value: 'SCHOOL' })} hidden />
      <ModalInputGroup>
        <ComboBox
          control={control}
          size="small"
          variant="outlined"
          name="filters.SCHOOL.sport"
          label="Sport"
          options={sportOptions}
        />
        <LbSelect
          control={control}
          size="small"
          variant="outlined"
          name="filters.SCHOOL.position"
          label="Position"
          options={positionOptions}
          disabled={!positionOptions.length}
        />
      </ModalInputGroup>
    </ModalInputBox>
  );
};

export { ShoolFilters };
