import React from 'react';

import { Button, Tooltip } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import classes from './PaymentRecordRow.module.scss';
import { PaymentHistoryRecord } from '../../../../interfaces/paymentHistoryList';
import {
  GridStickyGroup,
  GridTableCell,
  GridTableRow,
  UserInfoCell,
} from '../../../../../shared/components/table-components';
import { PriceCell } from './PriceCell/PriceCell';
import { DiscountChip } from '../../../../components/DiscountChip/DiscountChip';
import { DISCOUNT_TYPE } from '../../../../constants/discountType';
import { getFormattedDate } from '../../../../../shared/utils/dateUtils';
import { localDataFormat } from '../../../../../shared/constants/apiDateFormat';
import { StatusCell } from './StatusCell/StatusCell';
import { getFlagsObject } from '../../../../../shared/utils/sortingAndGroupingUtil';
import { CUSTOMER_STATUS } from '../../../../../nutrition';

type PaymentRecordRowProps = {
  paymentRecord: PaymentHistoryRecord;
};

const PaymentRecordRow = ({ paymentRecord }: PaymentRecordRowProps) => {
  const { customer, payment, promocode, subscription } = paymentRecord;

  const customerStatusFlag = getFlagsObject(CUSTOMER_STATUS, [customer.status]);
  // null is hide tooltip
  const tooltipText = customerStatusFlag.PRE_REGISTERED
    ? 'The customer is in the pre-registered status. Please ask the coach to finalize a setup.'
    : null;

  return (
    <GridTableRow variant="subRow" className={classes.row}>
      <GridStickyGroup>
        <GridTableCell>
          <Tooltip title={tooltipText} placement="right">
            {/* span is needed for tooltip to work https://mui.com/material-ui/react-tooltip/#disabled-elements */}
            <span>
              <UserInfoCell
                firstName={customer.firstName}
                lastName={customer.lastName}
                email={customer.email}
                href={`#!/customer-profile/${customer.id}`}
                disabled={customerStatusFlag.PRE_REGISTERED}
              />
            </span>
          </Tooltip>
        </GridTableCell>
      </GridStickyGroup>
      <GridTableCell>
        <span>{subscription.name}</span>
        <DiscountChip
          show={subscription.discountType === DISCOUNT_TYPE.PROMOCODE}
          discountPercent={promocode?.discountPercent}
          className={classes.discountChip}
        />
      </GridTableCell>
      <PriceCell price={payment.totalAmount} />
      <GridTableCell>
        <StatusCell status={payment.status} />
      </GridTableCell>
      <PriceCell price={payment.applicationFee} />
      <PriceCell price={payment.stripeFee} />
      <PriceCell price={payment.entityDeposit} />
      <PriceCell price={payment.salesTax} />
      <PriceCell price={payment.saasTax} />
      <PriceCell price={payment.entityNET} />
      <GridTableCell>
        {getFormattedDate(payment.paymentDate, localDataFormat)}
      </GridTableCell>
      <GridTableCell justify="center">
        <Button
          variant="text"
          href={payment.invoicePdfUrl}
          startIcon={<FileDownloadOutlinedIcon />}
          size="small"
        >
          PDF
        </Button>
      </GridTableCell>
    </GridTableRow>
  );
};

export { PaymentRecordRow, PaymentRecordRowProps };
