import { ValueOfKey } from '../../../../../../utils-types';

export const SubscriptionsRadioButtonsValues = {
  ALL: 'all',
  SPECIFIC: 'specific',
} as const;

export type SubscriptionsRadioButtonsValue = ValueOfKey<
  typeof SubscriptionsRadioButtonsValues
>;
