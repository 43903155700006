import { Option } from '../../../general-types';
import { ValueOfKey } from '../../../utils-types';

export const GENDER = {
  MALE: 'Male',
  FEMALE: 'Female',
} as const;

export type GenderType = ValueOfKey<typeof GENDER>;

export const genderOptions: Option<GenderType>[] = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
];

export const genderOptionsWithBoth: Option[] = [
  ...genderOptions,
  { label: 'Both', value: '' },
];
