import React from 'react';

import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { Components } from '@mui/material';

export const MuiAutocompleteTheme: Components['MuiAutocomplete'] = {
  defaultProps: {
    popupIcon: <ArrowDropDownRoundedIcon fontSize="large" />,
  },
  styleOverrides: {
    root: {
      '& button': {
        minWidth: '0',
        height: 'auto',
      },
    },
    popper: {
      '&.general-style': {
        minWidth: '200px',
        '& .MuiPaper-root': {
          borderRadius: '7px !important',
          backgroundColor: 'var(--white)',
          padding: '8px',
          boxShadow: 'var(--panel-shadow)',
        },
        '& .MuiAutocomplete-listbox': {
          maxHeight: '283px',
          overflowY: 'auto',
          padding: '0 8px 0 0',
        },
        '& .MuiAutocomplete-listbox::-webkit-scrollbar': {
          width: '4px',
        },
        '& .MuiAutocomplete-listbox::-webkit-scrollbar-thumb': {
          backgroundColor: 'var(--natural-300)',
          borderRadius: '4px',
        },
      },
      '&.variant-select': {
        '& .MuiAutocomplete-option': {
          padding: '10px',
          font: 'var(--body-14-medium)',
        },
        '& .MuiAutocomplete-option:hover': {
          backgroundColor: 'var(--warning-50)',
          borderRadius: '4px',
        },
        '& .MuiAutocomplete-option[aria-selected="true"]': {
          color: 'var(--primary-500)',
          backgroundColor: 'inherit',
        },
        '& .MuiAutocomplete-option[aria-selected="true"]:hover': {
          backgroundColor: 'var(--warning-50)',
        },
      },
      '&.variant-multiselect': {
        '& .MuiAutocomplete-option': {
          padding: '6px 10px',
          gap: '8px',
          font: 'var(--body-14-medium)',
        },
        '& .MuiAutocomplete-option:hover': {
          backgroundColor: 'var(--warning-50)',
          borderRadius: '4px',

          '.MuiSvgIcon-root': {
            fill: 'currentColor',
          },
        },
        '& .MuiAutocomplete-option[aria-selected="true"]': {
          backgroundColor: 'transparent',
        },
        '& .MuiAutocomplete-option[aria-selected="true"]:hover': {
          backgroundColor: 'var(--warning-50)',
        },
      },
    },
  },
};
