import { Moment } from 'moment';
import { localMonthFormat } from '../../../../../../shared/constants/apiDateFormat';
import { getFormattedDate } from '../../../../../../shared/utils/dateUtils';
import { PerformanceSummaryGraphGroupBy } from '../../../../../interfaces/performanceSummary';

const dateFormatConfig: Record<PerformanceSummaryGraphGroupBy, string> = {
  DAY: 'MMM DD',
  MONTH: localMonthFormat,
};

export const getDateLabel = (
  date: Moment | string,
  groupBy: PerformanceSummaryGraphGroupBy,
) => {
  return getFormattedDate(date, dateFormatConfig[groupBy]);
};
