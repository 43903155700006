const autoNgTemplateLoaderTemplate1 = require('./supplement-profile.template.html');

'use strict';

(function () {
    angular
        .module('app.supplementProfile', [])
        .config(supplementProfileConfig);

    supplementProfileConfig.$inject = ['$routeProvider'];

    function supplementProfileConfig($routeProvider) {
        $routeProvider
            .when('/supplement-profile/:id', {
                disableCache: true,
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'SupplementProfileController',
                controllerAs: 'vm',
                resolve: {
                    loginRequired: ['AuthService', function (AuthService) {
                        return AuthService.loginRequired();
                    }]
                }
            })
    }
})();
