import React from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';
import { angularize } from 'react-in-angularjs';

import { LbInput } from '../../../shared/components/form-components';
import classes from './NgzSendViaChatForm.module.scss';
import { YesNoBtnRow } from '../../../shared/components/buttons';
import { SystemProviders } from '../../../shared/providers';
import { closeNgModal } from '../../../shared/utils/angularUtils';
import { UploadFiles } from '../../../shared/components/form-components/UploadFiles/UploadFiles';
import { useSendViaChatForm } from './NgzSendViaChatForm.hook';

const NgzSendViaChatForm = ({ userIdList, openSendingMessageModal }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({ mode: 'onBlur' });

  const { files, setFiles, onSubmit } = useSendViaChatForm(
    userIdList,
    openSendingMessageModal,
  );

  return (
    <SystemProviders>
      <form>
        <LbInput
          label="Your message"
          name="message"
          control={control}
          variant="standard"
          required
          rules={{ required: 'Message is required' }}
        />
        <UploadFiles register={register} files={files} setFiles={setFiles} />
        <YesNoBtnRow
          className={classes.btnRow}
          noText="Cancel"
          noHandler={closeNgModal}
          yesText="Send"
          yesHandler={handleSubmit(onSubmit)}
          yesDisabled={!isValid}
        />
      </form>
    </SystemProviders>
  );
};

NgzSendViaChatForm.propTypes = {
  userIdList: PropTypes.array,
  openSendingMessageModal: PropTypes.func,
};

angularize(NgzSendViaChatForm, 'ngzSendViaChatForm', angular.module('app'), {
  userIdList: '<',
  openSendingMessageModal: '<',
});

NgzSendViaChatForm.propTypes = {
  userIdList: PropTypes.array,
  openSendingMessageModal: PropTypes.func,
};

export { NgzSendViaChatForm };
