import React, { useEffect } from 'react';

import { observer } from 'mobx-react';

import classes from './InbodyKeysTable.module.scss';
import { HeadRow } from './HeadRow/HeadRow';
import { inbodyKeysStore } from '../../../../stores/inbodyKeysStore';
import { InbodyKeysRow } from './InbodyKeysRow/InbodyKeysRow';
import { useSort } from '../../../../../shared/hooks/useSort';

const InbodyKeysTable = observer(() => {
  const { inbodyKeys, constructPageWithInbodyKeys, setDefaultValues } = inbodyKeysStore;

  const { sortedData: sortedInbodyKey, sortMethods } = useSort({
    data: inbodyKeys,
    sortTypeConfig: { entityName: 'STRING' },
    defaultSortInfo: { fieldName: 'entityName', direction: 'ASC' },
  });

  useEffect(() => {
    constructPageWithInbodyKeys();
    return setDefaultValues;
  }, []);

  return (
    <div className={classes.inbodyKeysTable}>
      <HeadRow sortMethods={sortMethods} />
      {sortedInbodyKey.map((inbodyKeyInfo, index) => (
        <InbodyKeysRow
          key={inbodyKeyInfo.id}
          inbodyKeyInfo={inbodyKeyInfo}
          index={index}
        />
      ))}
    </div>
  );
});

export { InbodyKeysTable };
