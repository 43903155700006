import React, { MouseEventHandler } from 'react';

import classes from './ButtonsRow.module.scss';
import { YesNoBtnRow } from '../../../../../shared/components/buttons';

type ButtonsRowProps = {
  onNext?: MouseEventHandler<HTMLButtonElement>;
  formId?: string;
  yesDisabled?: boolean;
  nextText?: string;
};

const ButtonsRow = ({
  onNext,
  formId,
  yesDisabled,
  nextText = 'Next',
}: ButtonsRowProps) => {
  return (
    <YesNoBtnRow
      yesText={nextText}
      className={classes.buttonRow}
      yesHandler={onNext}
      yesDisabled={yesDisabled}
      formId={formId}
    />
  );
};

export { ButtonsRow };
