import { useEffect, useState } from 'react';

import { SchoolInfo } from '../../../../../interfaces/SchoolInfo';
import { schoolService } from '../../../../../services/schoolService';

export const useSchoolList = (entityId: number) => {
  const [schoolList, setShoolList] = useState<SchoolInfo[]>([]);

  const getShoolList = async () => {
    const { customerSchoolGroups } =
      await schoolService.getSchoolListForSelfSetup(entityId);
    setShoolList(customerSchoolGroups);
  };

  useEffect(() => {
    getShoolList();
  }, []);

  return { schoolList };
};
