import React, { useEffect } from 'react';

import { observer } from 'mobx-react';

import { FoodScoreTable } from '../../components/FoodScoreTable/FoodScoreTable';
import { foodScoreStore } from '../../../../stores/foodScoreStore';

const FinalTable = observer(() => {
  const { finalTableData, getFinalTableData } = foodScoreStore;

  useEffect(() => {
    getFinalTableData();
  }, []);

  const isShowFinalTable = !!finalTableData?.content;

  return (
    isShowFinalTable && (
      <FoodScoreTable tableData={finalTableData} getTableData={getFinalTableData} />
    )
  );
});

export { FinalTable };
