const autoNgTemplateLoaderTemplate1 = require('./pin-code.template.html');

'use strict';

(function () {
    angular
        .module('app.pinCode', [])
        .config(pinCodeConfig);

    pinCodeConfig.$inject = ['$routeProvider'];

    function pinCodeConfig($routeProvider) {
        $routeProvider
            .when('/pin-code', {
                disableCache: true,
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'PinCodeController',
                controllerAs: 'vm',
                resolve: {
                    pinCodeRequired: ['AuthService', function (AuthService) {
                        return AuthService.pinCodeRequired();
                    }]
                }
            })
    }
})();
