import React from 'react';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';

import { IconButton } from '@mui/material';

import classes from './StatusCell.module.scss';
import { Status } from '../../../../../../../shared/components/ui-components';
import { FreeAccessMemberStatus } from '../../../../../../interfaces/freeAccess';

type StatusCellProps = {
  status: FreeAccessMemberStatus;
  onDeleteClick: () => void;
};

const StatusCell = ({ status, onDeleteClick }: StatusCellProps) => {
  let statusLabel;
  let icon;

  switch (status) {
    case 'ACTIVE':
      statusLabel = 'Active';
      icon = <CheckCircleRoundedIcon fontSize="large" color="success" />;
      break;
    case 'INVITED':
      statusLabel = 'Registration Pending';
      icon = <RefreshRoundedIcon fontSize="large" color="primary" />;
      break;
    case 'CANCELLED':
      statusLabel = 'Canceled';
      icon = <CancelRoundedIcon fontSize="large" color="error" />;
      break;
    default:
      statusLabel = 'Unknown';
      break;
  }

  return (
    <td>
      <div className={classes.container}>
        <Status icon={icon}>{statusLabel}</Status>
        <IconButton color="inherit" onClick={onDeleteClick}>
          <DeleteOutlineIcon fontSize="medium" />
        </IconButton>
      </div>
    </td>
  );
};

export { StatusCell };
