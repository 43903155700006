import { uploadFileTypes } from '../../shared/constants/uploadFileTypes';
import { apiService } from '../../shared/services/apiService';
import { fileService } from '../../shared/services/fileService';

const getTests = async (customerID) => {
  const resp = await apiService(`api/customer-medical-test`, {
    params: {
      customerID,
    },
  });
  return resp.data;
};

const sendTests = async (customerID, tests) => {
  const params = { customerID };

  const resp = await apiService.post(`api/customer-medical-test/batch`, tests, {
    params,
  });
  return resp.data;
};

const sendOneTest = async (customerID, test) => {
  const params = { customerID };

  const resp = await apiService.put('api/customer-medical-test', test, {
    params,
  });

  return resp.data;
};

const deleteTestResult = async (resultId) => {
  const resp = await apiService.delete(`api/customer-medical-test/${resultId}`);
  return resp;
};

const deleteTest = async (customerId, testId) => {
  const resp = await apiService.delete(
    `api/customer-medical-test/customer/${customerId}/entity-medical-test/${testId}`,
  );
  return resp;
};

const uploadTestFile = async (file) => {
  const resp = await fileService.uploadFile(uploadFileTypes.MEDICAL_TEST, file);
  return resp;
};

export const customerTestsService = {
  getTests,
  sendTests,
  sendOneTest,
  uploadTestFile,
  deleteTestResult,
  deleteTest,
};
