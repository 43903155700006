import { apiService } from '../../shared/services/apiService';
import { ScholasticCustomerData } from '../interfaces/ScholasticCustomerData';
import {
  ScholasticUserDataFormValue,
  ScholasticUserProfileFormValue,
} from '../interfaces/ScholasticUserProfileFormValue';

export type ScholasticSelfSetupData = {
  entityId: number;
  token: string;
} & ScholasticUserDataFormValue;

export const scholasticCustomerService = {
  async sendUser(customerData: ScholasticUserProfileFormValue) {
    const resp = await apiService.post<ScholasticCustomerData>(
      '/api/coach/customer/scholastic',
      customerData,
    );

    return resp.data;
  },

  async selfCustomerSetup(customerData: ScholasticSelfSetupData) {
    const resp = await apiService.post<ScholasticCustomerData>(
      '/api/sign-up/scholastic',
      customerData,
    );

    return resp.data;
  },

  async uploadUsersInfo(file: File) {
    const formData = new FormData();
    formData.append('file', file, file.name);

    const resp = await apiService.post<ScholasticCustomerData>(
      '/api/coach/customer/scholastic/csv',
      formData,
    );

    return resp.data;
  },

  async getUserData(userId: number) {
    const resp = await apiService.get<ScholasticCustomerData>(
      `api/coach/customer/scholastic/user/${userId}`,
    );

    return resp.data;
  },
};
