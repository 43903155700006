(function () {
    'use strict';

    angular.module('app.operatorProfile').controller('OperatorProfileController', OperatorProfileController);
    OperatorProfileController.$inject = [
        '$location',
        'utilService',
        'GeneralService',
        'OperatorService',
        'constants',
    ];

    function OperatorProfileController($location, utilService, GeneralService, OperatorService, constants) {
        var vm = this;
        vm.loggedInUser = utilService.getLoggedInUserData();
        vm.patterns = constants.enums.patterns;

        vm.operatorAccountSettings = {};
        vm.operatorPassword = {
            currentPassword: 'qwertyqwerty',
            password: '',
            repeatPassword: ''
        };
        vm.tabName = '';

        vm.goTo = utilService.goTo;
        vm.checkPassword = checkPassword;
        vm.init = init;
        vm.getOperatorAccountSettings = getOperatorAccountSettings;
        vm.updateOperatorAccountSettings = updateOperatorAccountSettings;
        vm.updatePassword = updatePassword;

        vm.init();
        vm.getOperatorAccountSettings();

        function checkPassword(form, password, repeatPassword) {
            GeneralService.checkPassword(form, password, repeatPassword, vm.operatorPassword);
        }

        function init() {
            switch ($location.path()) {
                case '/operator-profile/settings':
                    vm.tabName = 'settings';
                    break;
                case '/operator-profile/password':
                    vm.tabName = 'password';
                    break;
            }
        }

        function getOperatorAccountSettings() {
            OperatorService.getOperatorAccountSettings(vm.loggedInUser.id).then(function (res) {
                if (res.status === 200) {
                    vm.operatorAccountSettings = res.data;
                }
            });
        }

        function updateOperatorAccountSettings() {
            OperatorService.updateOperatorAccountSettings(vm.loggedInUser.id, vm.operatorAccountSettings).then(function (res) {
                if (res.status === 200) {
                    vm.goTo('/operator-profile/settings');
                }
            });
        }

        function updatePassword() {
            GeneralService.updatePassword(vm.operatorPassword).then(function (res) {
                if (res.status === 200) {
                    vm.goTo('/operator-profile/password');
                }
            });
        }
    }
})();
