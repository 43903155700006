import React, { useEffect } from 'react';
import { Control, Path, useController, useWatch } from 'react-hook-form';
import { InputView } from '../../../../shared/components/form-components';
import { AddScanManualFormValues } from '../AddScanManualForm.settings';
import { KeysOfType } from '../../../../utils-types';
import { isObject } from '../../../../shared/utils/jsUtils';

type CalculatedMetricInputProps<TName extends Path<AddScanManualFormValues>> = {
  control: Control<AddScanManualFormValues>;
  name: TName;
  label: string;
  metric: string;
  deps: KeysOfType<AddScanManualFormValues, number | undefined>[];
  calcFunction: (...depends: (number | undefined)[]) => number | null;
};

const CalculatedMetricInput = <TName extends Path<AddScanManualFormValues>>({
  control,
  name,
  label,
  metric,
  deps,
  calcFunction,
}: CalculatedMetricInputProps<TName>) => {
  const {
    field: { value, onChange },
  } = useController({ control, name });

  if (isObject(value)) {
    throw new Error('Value should be a number or null');
  }

  const depends = useWatch({ control, name: deps });

  useEffect(() => {
    const info = calcFunction(...depends);

    onChange(info ?? 0);
  }, [depends]);

  return <InputView label={label} value={value ?? 0} endAdornment={metric} />;
};

export { CalculatedMetricInput };
