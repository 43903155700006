import React from 'react';
import PropTypes from 'prop-types';

import classes from './GapCell.module.scss';
import { Cell } from '../Cell/Cell';

const GapCell = ({ gap }) => {
  let gapClasses;
  let text;

  if (gap > 0) {
    text = `${gap} oz Over Goal`;
    gapClasses = classes.positive;
  } else if (gap < 0) {
    text = `${Math.abs(gap)} oz Short`;
    gapClasses = classes.negative;
  } else {
    text = 'Exactly';
    gapClasses = classes.positive;
  }

  return <Cell text={text} className={gapClasses} />;
};

GapCell.propTypes = {
  gap: PropTypes.number,
};

export { GapCell };
