import { apiService } from '../../shared/services/apiService';
import { ActivityInfo, CustomerActivities } from '../interfaces/activities';

type GetActivitiesListResponse = {
  activities: ActivityInfo[];
};

export const activityService = {
  async getActivitiesList() {
    const resp = await apiService.get<GetActivitiesListResponse>(`api/users/activities`);

    return resp.data;
  },

  // set activities and create activity index
  async setCustomerActivities(customerId: number, activities: CustomerActivities) {
    const resp = await apiService.post<undefined>(
      `api/coach/user/${customerId}/activity`,
      activities,
    );

    return resp.data;
  },
};
