const autoNgTemplateLoaderTemplate1 = require('./sales-deposits.template.html');

'use strict';

(function () {
    angular
        .module('app.salesDeposits', [])
        .config(salesDepositsConfig);

    salesDepositsConfig.$inject = ['$routeProvider'];

    function salesDepositsConfig($routeProvider) {
        $routeProvider
            .when('/sales-deposits/from/:from/to/:to', {
                disableCache: true,
                templateUrl: autoNgTemplateLoaderTemplate1,
                controller: 'SalesDepositsController',
                controllerAs: 'vm',
                resolve: {
                    loginRequired: ['AuthService', function (AuthService) {
                        return AuthService.loginRequired();
                    }]
                }
            })
    }
})();
