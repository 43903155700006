import React, { ReactNode } from 'react';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { StepButton } from '@mui/material';

export type StepOption = {
  label: string;
  step: ReactNode;
};

type LbStepperProps = {
  activeStep: number;
  stepsArr: StepOption[];
  goToStep?: (stepIndex: number) => void;
  className?: string;
};
/**
 * if goToStep props is not passed, the stepper will be disabled and the user will not be able to navigate back by steps
 */
const LbStepper = ({ activeStep, stepsArr, goToStep, className }: LbStepperProps) => {
  return (
    <>
      <Stepper activeStep={activeStep} className={className}>
        {stepsArr.map(({ label }, index) => (
          <Step key={label} disabled={!goToStep || undefined}>
            <StepButton onClick={() => goToStep && goToStep(index)} disableRipple>
              <StepLabel>{label}</StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
      {stepsArr[activeStep].step}
    </>
  );
};

export { LbStepper };
