import { Option } from '../../../../general-types';

export const addAllOption = (
  options: Option[],
  isAddToEmptyArray?: boolean,
): Option[] => {
  if (options.length || isAddToEmptyArray) {
    return [{ value: '', label: 'All' }, ...options];
  }
  return [];
};
