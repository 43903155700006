import { makeAutoObservable } from 'mobx';
import { userRoles } from '../constants/userRoles';
import { resetAllStore } from './resetAllStore';
import { LOCAL_STORAGE_KEYS } from '../constants/system/LOCAL_STORAGE_KEYS';
import { ENTITY_TYPES, EntityTypeFlags } from '../constants/user-info/ENTITY_TYPES';
import { LoggedInUser } from '../interfaces/LoggedInUser';
import { PaidModuleTypeFlags } from '../constants/paidModuleTypes';
import { getFlagsObject } from '../utils/sortingAndGroupingUtil';
import { PaymentFlowTypeFlags } from '../constants/user-info/paymentFlowType';

// TODO delete "as any" in AddCustomFilterForm after rewrite to ts
class LoggedInUserDataStore {
  loggedInUser: LoggedInUser | null = null;
  isCustomer: boolean = false;
  isCoach: boolean = false;
  isEntity: boolean = false;
  isSuperAdmin: boolean = false;
  isFoodAdmin: boolean = false;
  paymentFlowType: PaymentFlowTypeFlags = {};
  isComplianceCoach: boolean | null = null;
  isEntityHasChild: boolean = false;
  corporateSignInStatuses = {
    NOT_CORPORATE_USER: false,
    FINISHED_SING_IN: false,
    UNFINISHED_SING_IN: false,
  };
  entityType: EntityTypeFlags = {};
  enabledModules: PaidModuleTypeFlags = {};
  availableModules: PaidModuleTypeFlags = {};
  initUserInfoFinished: boolean = false;

  constructor() {
    this.setDefaultValues();
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaultValues() {
    this.loggedInUser = null;
    this.isCustomer = false;
    this.isCoach = false;
    this.isEntity = false;
    this.isSuperAdmin = false;
    this.isFoodAdmin = false;
    this.paymentFlowType = {};
    this.isComplianceCoach = null;
    this.isEntityHasChild = false;
    this.corporateSignInStatuses = {
      NOT_CORPORATE_USER: false,
      FINISHED_SING_IN: false,
      UNFINISHED_SING_IN: false,
    };
    this.entityType = {};
    this.enabledModules = {};
    this.availableModules = {};
    this.initUserInfoFinished = false;
  }

  initLoggedInUserStoreProps() {
    this.setLoggedInUser();
    this.setUserRole();
    this.setCorporateSignInStatus();
    this.setLoggedInUserEntityType();

    this.setInitUserInfoFinished(true);
  }

  setLoggedInUser() {
    this.loggedInUser = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)!,
    );
  }

  setUserRole() {
    const { CUSTOMER, AGENT, ADMIN, SUPERUSER, FOOD_ADMIN } = userRoles;
    const userRole = this.getUserRole();

    switch (userRole) {
      case CUSTOMER:
        this.isCustomer = true;
        break;
      case AGENT:
        this.isCoach = true;
        break;
      case ADMIN:
        this.isEntity = true;
        break;
      case SUPERUSER:
        this.isSuperAdmin = true;
        break;
      case FOOD_ADMIN:
        this.isFoodAdmin = true;
        break;
      default:
        break;
    }
  }

  setCorporateSignInStatus() {
    if (!this.loggedInUser) {
      return;
    }

    const isCorporateTokenNotExist = !this.loggedInUser.corporateAccountInfo?.token;

    if (isCorporateTokenNotExist) {
      this.corporateSignInStatuses.NOT_CORPORATE_USER = true;
    } else if (!this.loggedInUser.id) {
      this.corporateSignInStatuses.UNFINISHED_SING_IN = true;
    } else {
      this.corporateSignInStatuses.FINISHED_SING_IN = true;
    }
  }

  setLoggedInUserEntityType() {
    const entityTypeFlags = getFlagsObject(ENTITY_TYPES, [this.loggedInUser?.entityType]);

    this.entityType = entityTypeFlags;
  }

  getUserRole() {
    return this.getLoggedInUserDataField('role');
  }

  getEntityId() {
    return this.getLoggedInUserDataField('gymId');
  }

  getLoggedInUserDataField(field: keyof LoggedInUser) {
    if (this.loggedInUser) {
      return this.loggedInUser[field];
    } else {
      return this.getLoggedInUserDataFieldFromLocalStorage(field);
    }
  }

  getLoggedInUserDataFieldFromLocalStorage(field: keyof LoggedInUser) {
    const loggedInUser = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)!,
    );

    this.loggedInUser = loggedInUser;
    return loggedInUser ? loggedInUser[field] : null;
  }

  setInitUserInfoFinished(initUserInfoFinished: boolean) {
    this.initUserInfoFinished = initUserInfoFinished;
  }

  setIsComplianceCoach(isComplianceCoach: boolean) {
    this.isComplianceCoach = isComplianceCoach;
  }

  setEnabledModules(enabledModules: PaidModuleTypeFlags) {
    this.enabledModules = enabledModules;
  }

  setAvailableModules(availableModules: PaidModuleTypeFlags) {
    this.availableModules = availableModules;
  }

  setIsEntityHasChild(isEntityHasChild: boolean) {
    this.isEntityHasChild = isEntityHasChild;
  }

  setPaymentFlowType(paymentFlowType: PaymentFlowTypeFlags) {
    this.paymentFlowType = paymentFlowType;
  }
}

const loggedInUserDataStore = new LoggedInUserDataStore();

resetAllStore.addResetMethod(loggedInUserDataStore.setDefaultValues);

export { loggedInUserDataStore, LoggedInUserDataStore };
