import { useState } from 'react';

import {
  dateMonthFormat,
  dateTimeFormatWithTimezone,
} from '../../../../shared/constants/apiDateFormat';
import {
  RevenueDateRangeFilterValues,
  RevenueDateRangeFormValues,
} from '../DetailedRevenue.settings';
import { getMonthFirstDay, getMonthLastDay } from '../../../../shared/utils/dateUtils';

type UseRevenueDateRangeReturn = {
  dateRange: RevenueDateRangeFilterValues;
  applyDateRange: (date: RevenueDateRangeFormValues) => void;
};

export const useRevenueDateRange = (
  defaultRange: RevenueDateRangeFormValues,
): UseRevenueDateRangeReturn => {
  const processDateRange = ({ firstMonth, lastMonth }: RevenueDateRangeFormValues) => {
    const formattedDateFrom = getMonthFirstDay(firstMonth, dateMonthFormat).format(
      dateTimeFormatWithTimezone,
    );

    const formattedDateTo = getMonthLastDay(lastMonth, dateMonthFormat).format(
      dateTimeFormatWithTimezone,
    );

    return { dateFrom: formattedDateFrom, dateTo: formattedDateTo };
  };

  const [dateRange, setDateRange] = useState<RevenueDateRangeFilterValues>(
    processDateRange(defaultRange),
  );

  const applyDateRange = (date: RevenueDateRangeFormValues) => {
    setDateRange(processDateRange(date));
  };

  return { dateRange, applyDateRange };
};
