import React from 'react';

import {
  FieldValues,
  Path,
  PathValue,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { TextField, Autocomplete, TextFieldProps } from '@mui/material';
import { observer } from 'mobx-react';

import classes from './CustomFiltersSelector.module.scss';
import { customFilterStore } from '../../stores/customFilterStore';
import { AddCustomFilterModal } from '../AddCustomFilterModal/AddCustomFilterModal';
import { ListboxComponent } from './ListboxComponent/ListboxComponent';
import { FilterOption } from './FilterOption/FilterOption';
import { useAutocompleteOneValue } from '../../../../components/form-components/autocomplete-components/hooks/useAutocompleteOneValue';
import { AddNewFilterButton } from './AddNewFilterButton/AddNewFilterButton';

type LbAutocompleteProps<
  T extends FieldValues,
  TName extends Path<T>,
> = UseControllerProps<T, TName> &
  Pick<TextFieldProps, 'label' | 'variant' | 'size' | 'placeholder'>;

const CustomFiltersSelector = observer(
  <T extends FieldValues, TName extends Path<T>>({
    control,
    name,
    rules,
    defaultValue = null as PathValue<T, TName>,
    label,
    variant,
    size,
    placeholder = 'More Filters',
  }: LbAutocompleteProps<T, TName>) => {
    const { customFilters } = customFilterStore;

    const {
      field: { onChange, ...field },
      fieldState: { error },
    } = useController<any>({
      control,
      name,
      rules,
      defaultValue,
    });

    const { options, getOptionLabel } = useAutocompleteOneValue({
      userOptions: customFilters,
      valueKey: 'id',
      labelKey: 'name',
    });

    const isRequired = !!rules?.required;

    return (
      <>
        <Autocomplete
          {...field}
          onChange={(_, filterId) => onChange(filterId)}
          componentsProps={{
            popper: {
              className: 'general-style variant-select',
              placement: 'bottom-start',
              style: { minWidth: '300px' },
            },
            paper: {
              className: classes.selectorPaper,
            },
          }}
          size={size}
          fullWidth
          disableClearable
          options={options}
          ListboxComponent={ListboxComponent}
          getOptionLabel={getOptionLabel}
          noOptionsText={<AddNewFilterButton />}
          renderOption={(props, filterId, state) => (
            <FilterOption
              {...props}
              filterId={filterId}
              label={getOptionLabel(filterId)}
              state={state}
            />
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              helperText={error?.message}
              error={!!error}
              label={label}
              required={isRequired}
              variant={variant}
              placeholder={placeholder}
            />
          )}
          data-test-id="custom-filters-selector"
        />
        <AddCustomFilterModal />
      </>
    );
  },
);

export { CustomFiltersSelector };
