import React from 'react';

import { observer } from 'mobx-react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';

import classes from './MacroPlanStep.module.scss';
import { ButtonsRow } from '../../components/ButtonsRow/ButtonsRow';
import { customerSetupStore } from '../../../../stores/customerSetupStore';
import {
  LbTabPanel,
  LbTabs,
  LbTabsList,
  TabsConfig,
} from '../../../../../shared/components/tabs';
import { WorkoutMeals } from './WorkoutMeals/WorkoutMeals';
import { MealPlan } from './MealPlan/MealPlan';
import { MacroPlanStepFromValue } from './MacroPlanStep.settings';
import { mealPlanService } from '../../../../services/mealPlanService';

type MacroPlanStepProps = {
  customerId: number;
};

// TODO need refactor create meal table components, problem with types
const MacroPlanStep = observer(({ customerId }: MacroPlanStepProps) => {
  const { stepperMethods } = customerSetupStore;

  const formMethods = useForm<MacroPlanStepFromValue>({
    defaultValues: async () => mealPlanService.getMealPlansMacros(customerId),
  });
  const { control, handleSubmit } = formMethods;

  const { fields } = useFieldArray({ control, name: 'mealPlans' });

  const handleFormSubmit = handleSubmit(async (macroPlan) => {
    await mealPlanService.updateMealPlansMacros(customerId, {
      ...macroPlan,
      isImmediately: true,
    });

    stepperMethods.goToNextStep();
  });

  const workoutTabConfig: TabsConfig = {
    label: 'Pre/Post Workout',
    value: 0,
    panel: <WorkoutMeals />,
  };

  const mealPlanTabsConfig: TabsConfig[] = fields.map((mealPlan, index) => ({
    label: mealPlan.originName,
    value: index + 1, // +1 because for workout set value 0, (tabs defaultValue 1 show initially meal plan tab)
    panel: <MealPlan nameIndex={index} mealsPerDay={mealPlan.mealPlan} />,
  }));

  const tabsConfigs = [workoutTabConfig, ...mealPlanTabsConfig];

  return (
    <div>
      <FormProvider {...formMethods}>
        <LbTabs defaultValue={1} orientation="vertical" className={classes.tabsBox}>
          <LbTabsList tabsConfig={tabsConfigs} variant="vertical-page" />
          <LbTabPanel tabsConfig={tabsConfigs} />
        </LbTabs>
      </FormProvider>
      <ButtonsRow onNext={handleFormSubmit} />
    </div>
  );
});

export { MacroPlanStep };
