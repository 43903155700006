import React from 'react';

import { Tab, TabsList } from '@mui/base';
import classNames from 'classnames';

import classes from './LbTabsList.module.scss';
import { TabOption, TabsConfig } from '../types/TabsConfig';

type LbTabsListProps = {
  tabsConfig: TabOption[] | TabsConfig[];
  variant: 'underline' | 'toggle' | 'horizontal-page' | 'vertical-page';
  fullWidth?: boolean;
  className?: string;
};

const LbTabsList = ({ tabsConfig, variant, fullWidth, className }: LbTabsListProps) => {
  const listClasses = classNames(className, classes[variant], {
    [classes.fullWidth]: fullWidth,
  });

  return (
    <TabsList className={listClasses}>
      {tabsConfig.map(
        (tab) =>
          !tab.hide && (
            <Tab
              key={tab.value}
              value={tab.value}
              className={classes.tab}
              disabled={tab.disabled}
            >
              {!!tab.icon && <span className={classes.icon}>{tab.icon}</span>}
              <span>{tab.label}</span>
            </Tab>
          ),
      )}
    </TabsList>
  );
};

export { LbTabsList, LbTabsListProps };
