import { DependencyList, useEffect } from 'react';
import { useErrorBoundary } from 'react-error-boundary';

export const useGetNecessaryData = (
  func: () => Promise<any> | void,
  deps: DependencyList = [],
) => {
  const { showBoundary } = useErrorBoundary();

  const getData = async () => {
    try {
      await func();
    } catch (error) {
      showBoundary(error);
    }
  };

  useEffect(() => {
    getData();
  }, deps);
};
