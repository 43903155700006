'use strict';

angular.module('app').factory('TimeZoneService', TimeZoneService);

TimeZoneService.$inject = ['ApiService'];

function TimeZoneService(ApiService) {
    return {
        getTimezoneStandard: function () {
            var timezone_offset_min = new Date().getTimezoneOffset();
            var offset_hrs = parseInt(Math.abs(timezone_offset_min / 60));
            var offset_min = Math.abs(timezone_offset_min % 60);
            var timezone_standard;

            offset_hrs = '0' + offset_hrs;

            if (offset_min < 10)
                offset_min = '0' + offset_min;

            if (timezone_offset_min < 0)
                timezone_standard = '+' + offset_hrs + ':' + offset_min;
            else if (timezone_offset_min > 0)
                timezone_standard = '-' + offset_hrs + ':' + offset_min;
            else if (timezone_offset_min === 0)
                timezone_standard = 'Z';

            return timezone_standard;
        },
        setTimeZone: function () {
            return ApiService.post('api/timezone?timezone=' + new Date().toISOString().replace('Z', this.getTimezoneStandard()).replace('+', '%2B').toString()).then(function (res) {
                return res;
            }, function (err) {
                return err;
            });
        }
    };
}

