import React from 'react';

import { angularize } from 'react-in-angularjs';
import { observer } from 'mobx-react';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

import classes from './NgzCardExpiredInfo.module.scss';
import { SystemProviders } from '../../providers';
import { useCardExpiredInfo } from './hooks/useCardExpiredInfo';

const NgzCardExpiredInfo = observer(() => {
  const { isShowCardExpiredInfo, daysToCardExpiration } = useCardExpiredInfo();

  if (!isShowCardExpiredInfo) {
    return null;
  }

  return (
    <SystemProviders>
      <div className={classes.waringMessage}>
        <WarningAmberRoundedIcon fontSize="medium" />
        <p>Your credit card will be expired in {daysToCardExpiration} days.</p>
        <a href="#!/entity-profile" className={classes.link}>
          <EditRoundedIcon fontSize="medium" />
          <span>Edit Card</span>
        </a>
      </div>
    </SystemProviders>
  );
});

angularize(NgzCardExpiredInfo, 'ngzCardExpiredInfo', angular.module('app'), {});

export { NgzCardExpiredInfo };
