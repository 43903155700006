import React, { HTMLAttributes, forwardRef } from 'react';

import { Divider } from '@mui/material';

import classes from './ListboxComponent.module.scss';
import { AddNewFilterButton } from '../AddNewFilterButton/AddNewFilterButton';

const ListboxComponent = forwardRef<HTMLUListElement, HTMLAttributes<HTMLElement>>(
  ({ children, onMouseDown, ...props }, ref) => {
    return (
      <div onMouseDown={onMouseDown}>
        <ul {...props} ref={ref}>
          {children}
        </ul>
        <Divider className={classes.separatorLine} />
        <AddNewFilterButton />
      </div>
    );
  },
);

export { ListboxComponent };
