'use strict';

import { getLoginRequiredResolveObj } from '../shared/utils/angularUtils';

export const reportsModule = angular
  .module('app.reports', [])
  .config(reportsConfig);

reportsConfig.$inject = ['$routeProvider'];

function reportsConfig($routeProvider) {
  $routeProvider
    .when('/sales-reports', {
    template: '<ngz-sales-reports></ngz-sales-reports>',
    resolve: getLoginRequiredResolveObj(),
  })
    .when('/coach-reports', {
    template: '<ngz-coach-reports-list></ngz-coach-reports-list>',
    resolve: getLoginRequiredResolveObj(),
  });
}