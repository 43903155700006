import { uploadFileTypes } from '../constants/uploadFileTypes';
import { apiService } from './apiService';
import { fileService } from './fileService';

export const baseUserService = {
  async updateAvatar(avatarFile, userId) {
    const { id: avatarId } = await fileService.uploadFile(
      uploadFileTypes.AVATAR,
      avatarFile,
    );

    const params = { avatarId };
    const resp = await apiService.put(`api/users/${userId}/profile/avatar-update`, null, {
      params,
    });

    return resp.data;
  },
};
