'use strict';

angular.module('app').factory('DateTimeUtil', DateTimeUtil);

DateTimeUtil.$inject = [];

function DateTimeUtil() {
    return {
        now: function () {
            return new Date();
        },
        minusYears: function (date, years) {
            return new Date(moment(date).subtract(+years, 'years'));
        },
        plusYears: function (date, years) {
            return new Date(moment(date).add(years, 'years'));
        },
        minusDays: function (date, days) {
            return new Date(moment(date).subtract(+days, 'days'));
        },
        formatToDatetime: function (date, time) {
            return moment(date).format('YYYYMMDD') + moment(time).format('HHmmss');
        },
        formatDate: function (date, template) {
            return date ? moment(date, 'MM/DD/YYYY').format(template) : null;
        },
        convertDateFormat: function (date, initialFormat, finalFormat = 'YYYY-MM-DD') {
            return date ? moment(date, initialFormat).format(finalFormat) : null;
        },
        formatServerDate: function (date, template) {
            return date ? moment(date, 'YYYY-MM-DD', true).format(template) : null;
        },
        getUTCDate: function (dateStr) {
            var date = new Date(dateStr);
            return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
        },
        parseDate: function (str, letter) {
            var mdy = str.split(letter);
            return new Date(mdy[2], mdy[0] - 1, mdy[1]);
        },
        validateDOB: function (form, dob) {
            form["dob"].$setValidity("invalidDateValue",
                !(dob.getFullYear() < 1900 || (new Date().getTime() - dob.getTime()) / 31536000000 < 12));
        }
    }
}
