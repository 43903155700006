import React from 'react';
import PropTypes from 'prop-types';

import StarIcon from '@mui/icons-material/Star';

import classes from './DayRow.module.scss';
import { GapCell } from '../GapCell/GapCell';
import { Cell } from '../Cell/Cell';
import { Row } from '../Row/Row';
import { getDataToRender } from './DayRow.settings';

const DayRow = ({ day, targetGoal, id }) => {
  const { dayClasses, dayText, goal, gap, lastUpdateTime, score } = getDataToRender(
    day,
    targetGoal,
  );

  return (
    <Row className={dayClasses} id={id}>
      <Cell text={dayText} position="start" />
      <Cell text={goal} />
      <GapCell gap={gap} />
      <Cell text={lastUpdateTime} />
      <Cell
        text={score}
        adornment={<StarIcon fontSize="large" />}
        className={classes.score}
        position="end"
      />
    </Row>
  );
};

DayRow.propTypes = {
  day: PropTypes.object,
  targetGoal: PropTypes.number,
  id: PropTypes.string,
};

export { DayRow };
