import React, { MouseEventHandler, ReactNode } from 'react';

import Button, { ButtonProps } from '@mui/material/Button';
import classNames from 'classnames';

import classes from './YesNoBtnRow.module.scss';

export const testIds = { box: 'box' };

type YesNoBtnRowProps = {
  yesHandler?: MouseEventHandler<HTMLButtonElement>;
  yesVisible?: boolean;
  yesDisabled?: boolean;
  yesText?: string;
  className?: string;
  children?: ReactNode;
  noHandler?: MouseEventHandler<HTMLButtonElement>;
  noText?: string;
  formId?: string;
} & Pick<ButtonProps, 'size'>;

const YesNoBtnRow = ({
  yesHandler,
  noHandler,
  yesDisabled,
  yesVisible = true,
  yesText = 'OK',
  noText = 'Cancel',
  className,
  children,
  formId,
  size,
}: YesNoBtnRowProps) => {
  const containerClasses = classNames(classes.container, className);

  return (
    <div className={containerClasses} data-test-id={testIds.box}>
      <div>{children}</div>
      <div className={classes.btnContainer}>
        {!!noHandler && (
          <Button variant="outlined" onClick={noHandler} size={size}>
            {noText}
          </Button>
        )}
        {!!yesVisible && (
          <Button
            variant="contained"
            type="submit"
            onClick={yesHandler}
            disabled={yesDisabled}
            form={formId}
            size={size}
          >
            {yesText}
          </Button>
        )}
      </div>
    </div>
  );
};

export { YesNoBtnRow, YesNoBtnRowProps };
