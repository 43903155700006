import { ChangeEvent, useState } from 'react';

export const useUploadFiles = (files: File[], setFiles: (files: File[]) => void) => {
  const [error, setError] = useState('');

  const setFilesHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      processFiles(event.target.files);
    }
  };

  const processFiles = (attachedFiles: FileList) => {
    const updatedFiles = [...files, ...Array.from(attachedFiles)];
    const uniqueModifyDates = new Set(updatedFiles.map((file) => file.lastModified));
    const filteredFiles = updatedFiles.filter((file) => {
      return filterBySize(file) && filterDuplicates(uniqueModifyDates, file);
    });
    setFiles(filteredFiles);
  };

  const filterBySize = (file: File) => {
    const maxFileSizeMb = 20;
    const fileSizeMb = file.size / 1024 / 1024;
    const isFileSizeOk = fileSizeMb < maxFileSizeMb;

    if (!isFileSizeOk) {
      setError(
        `Some of selected files size is bigger than restriction in ${maxFileSizeMb} MB.`,
      );
    }

    return isFileSizeOk;
  };

  const filterDuplicates = (uniqueModifyDates: Set<number>, file: File) => {
    const deleteResult = uniqueModifyDates.delete(file.lastModified);
    if (!deleteResult) {
      setError(`Some of selected files are duplicates.`);
    }

    return deleteResult;
  };

  const deleteFileHandler = (index: number) => {
    const remainingFiles = [...files];
    remainingFiles.splice(index, 1);

    setFiles(remainingFiles);
  };

  const errorModalBtnHandler = () => setError('');

  return {
    error,
    setFilesHandler,
    deleteFileHandler,
    errorModalBtnHandler,
  };
};
