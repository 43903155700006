import moment from 'moment';

import { dateFormat } from '../../shared/constants/apiDateFormat';
import { apiService } from '../../shared/services/apiService';

const sendActiveCalendar = async (
  activeCalendar,
  customerId,
  isActiveCalendarSent = false,
) => {
  const params = {
    edit: isActiveCalendarSent,
  };

  const resp = await apiService.post(
    `api/coach/user/${customerId}/active-calendar`,
    activeCalendar,
    { params },
  );

  return resp.data;
};

const getSchedule = async (customerId) => {
  const params = { groupBy: 'TIME' };

  const resp = await apiService(`/api/active_calendar/scheduler/customer/${customerId}`, {
    params,
  });
  return resp.data;
};

const getScheduleByDay = async (customerId, date) => {
  const params = { date };

  const resp = await apiService(
    `api/customer/${customerId}/active-calendar/schedule-overview`,
    { params },
  );

  return resp.data;
};

const getCustomerSupplements = async (customerId, dateFrom, dateTo) => {
  const params = {
    dateFrom: dateFrom || moment().format(dateFormat),
    dateTo: dateTo || moment().add(10, 'days').format(dateFormat),
  };

  const resp = await apiService(
    `/api/active_calendar/supplements/customer/${customerId}`,
    { params },
  );
  return resp.data.supplementById;
};

const getCustomerScheduleForEdit = async (customerId) => {
  const resp = await apiService(`/api/active_calendar/customer/${customerId}`);
  return resp.data;
};

const getScheduleFile = async (customerId) => {
  const resp = await apiService.get(
    `/api/reports/active-calendar/customers/${customerId}`,
  );

  return resp.data;
};

export const activeCalendarService = {
  sendActiveCalendar,
  getSchedule,
  getScheduleByDay,
  getCustomerSupplements,
  getCustomerScheduleForEdit,
  getScheduleFile,
};
