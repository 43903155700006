export const activationStatusOption = [
  { value: '', label: 'All' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'DEACTIVATED', label: 'Deactivated' },
];

export const inventoryStatusOptions = [
  { value: '', label: 'All' },
  { value: 'SUFFICIENT_INVENTORY', label: 'Sufficient' },
  { value: 'LOW_INVENTORY', label: 'Low' },
  { value: 'NO_INFO', label: 'No info' },
];

export const labTestDateOptions = [
  { value: 'ALL', label: 'All' },
  { value: 'ONLY_URGENT', label: 'Only Urgent' },
  { value: 'ONLY_OVERDUE', label: 'Only Overdue' },
  { value: 'NO_TESTS', label: 'No Tests' },
];

export const appointmentOptions = [
  { value: '', label: 'All' },
  { value: true, label: 'Confirmed' },
  { value: false, label: 'Non-Confirmed' },
];

export const defaultInputProps = {
  variant: 'outlined',
};
