import moment from 'moment';
import {
  dateMonthFormat,
  oldDateFormat,
} from '../../../../shared/constants/apiDateFormat';
import {
  getFormattedDate,
  getFormattedToday,
  getMonthsAgo,
  isDataBefore,
} from '../../../../shared/utils/dateUtils';
import { RevenueDateRangeFormValues } from '../DetailedRevenue.settings';

export const getDefaultDateRange = (
  entityRegistrationDate: string | null, // oldDateFormat
): RevenueDateRangeFormValues => {
  const dayOneMonthAgo = getMonthsAgo(1);

  if (entityRegistrationDate) {
    const isRegisteredBeforeStartDay = isDataBefore(
      moment(entityRegistrationDate, oldDateFormat),
      dayOneMonthAgo,
    );

    const startDate = isRegisteredBeforeStartDay
      ? dayOneMonthAgo
      : moment(entityRegistrationDate, oldDateFormat);

    return {
      firstMonth: getFormattedDate(startDate, dateMonthFormat),
      lastMonth: getFormattedToday(dateMonthFormat),
    };
  } else {
    return {
      firstMonth: getFormattedDate(dayOneMonthAgo, dateMonthFormat),
      lastMonth: getFormattedToday(dateMonthFormat),
    };
  }
};
