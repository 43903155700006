import React from 'react';

import classes from './OrderDetailsBlock.module.scss';
import { LogoLink } from '../../../../shared/page-structure/NgzHeader/LogoLink/LogoLink';
import { TimerBlock } from './TimerBlock/TimerBlock';
import { PriceInfo } from './PriceInfo/PriceInfo';
import { DetailsTable } from './DetailsTable/DetailsTable';
import { Footer } from './Footer/Footer';

const OrderDetailsBlock = () => {
  return (
    <div className={classes.container}>
      <div>
        <LogoLink />
        <div className={classes.content}>
          <TimerBlock />
          <PriceInfo />
          <DetailsTable />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export { OrderDetailsBlock };
