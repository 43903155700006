const autoNgTemplateLoaderTemplate1 = require('./entity-inbody.template.html');

'use strict';

angular
  .module('app')
  .directive('entityInbody', entityInbody);

entityInbody.$inject = [];

function entityInbody() {
  return {
    restrict: 'E',
    scope: {
      createEntity: '=',
      next: '&onNext',
      entityId: '<?'
    },
    templateUrl: autoNgTemplateLoaderTemplate1,
    controller: 'EntityInbodyController',
    controllerAs: 'vm'
  };
}
